import { Routes, Route } from 'react-router-dom';
import Contracts from './Contracts/Contracts';
import PartyOverview from './PartyOverview';
import CreateParty from './CreateParty';
import User from './Users/User';
import UpdateParty from './updateParty'

const Party = props => (
    <Routes>
        <Route path="/" element={<PartyOverview />} />
        <Route path="/create" element={<CreateParty />} />
        <Route path="/contracts" element={<Contracts />} />
        <Route path="/user" element={<User />} />
        <Route path="/update" element={<UpdateParty />} />
    </Routes>
)

export default Party;