import React from 'react';
import ContainerReport from './ContainerReport';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import OverdueCommissionReport from './OverdueCommissionReport';
import CommissionReport from './CommissionReport';
import M from 'materialize-css';
import ContainerByWeekReportOld from './ContainerByWeekReportOld';
import SalesRecap from './SalesRecap';
import ActiveBookingsReport from "./ActiveBookingsReport";
import ActiveShippingInstructionsReport from './ActiveShippingInstructionsReport';
import PendingRequestsReport from './PendingRequestsReport';
import BookingsCreatedReport from "./BookingsCreatedReport";
import ProofAndCloseoutReport from './ProofAndCloseoutReport';
import { withRouter } from '../../helperFunctions';

class ReportRouter extends React.Component {
    constructor(props) { super(props); this.state = this.initState() }

    initState = () => ({
        routes: [
            { url: '/report', label: 'Choose a Report' },
            { url: '/report/containersbyweek', label: 'Containers By Week' },
            { url: '/report/carriercommission', label: 'Carrier Commission' },
            { url: '/report/oceanfreightreport', label: "Ocean Freight" },
            { url: '/report/containersbyweekold', label: "Booked Containers By Week" },
            { url: '/report/salesrecap', label: 'Sales Recap' },
            { url: '/report/activebookings', label: 'Active Bookings' },
            { url: '/report/activesis', label: 'Active Shipping Instructions' },
            { url: "/report/pendingrequests", label: "Pending Requests" },
            { url: "/report/bookingscreated", label: "Bookings/Shipping Instructions/Invoice Created" },
            { url: "/report/proofandcloseout", label: "Proof And Closeout" }
        ],
        routeSelector: '',
        ...this.props.state
    })

    componentDidMount = () => {
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    routeChange = e => {
        e.persist();
        const { name } = e.target;
        const elem = e.target.options[e.target.selectedIndex];
        const url = elem.getAttribute('data-url');
        const routeSelector = elem.getAttribute('value');

        this.setState({ [name]: routeSelector }, () => {
            this.props.navigate(url, { state: { routeSelector } })
        })
    }

    onChange = (name, value) => this.setState({ [name]: value })

    render = () => (
        <div>
            <div className="col s12 m6" style={{ position: 'relative' }}>
                <select className="browser-default" name="routeSelector" onChange={this.routeChange} value={this.state.routeSelector}>
                    {this.state.routes.map((route, index) => <option key={index} value={route.label} data-url={route.url}>{route.label}</option>)}
                </select>
            </div>
            <div>
                <Routes>
                    <Route path="/containersbyweek" element={<ContainerReport />} />
                    <Route path="/carriercommission" element={<OverdueCommissionReport />} />
                    <Route path="/oceanfreightreport" element={<CommissionReport />} />
                    <Route path="/containersbyweekold" element={<ContainerByWeekReportOld />} />
                    <Route path='/salesrecap' element={<SalesRecap />} />
                    <Route path='/activebookings' element={<ActiveBookingsReport />} />
                    <Route path='/activesis' element={<ActiveShippingInstructionsReport />} />
                    <Route path="/pendingrequests" element={<PendingRequestsReport />} />
                    <Route path="/bookingscreated" element={<BookingsCreatedReport />} />
                    <Route path="/proofandcloseout" element={<ProofAndCloseoutReport />} />
                </Routes>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { urls, index } = state;
    return { state: urls[index].state }
}

export default connect(mapStateToProps)(withRouter(ReportRouter));