import { useEffect } from "react";
import { useState } from "react";
import DisplayFileTree from "./DisplayFileTree";
import { auth, logout } from "../../../helperFunctions";
import Axios from 'axios';

const FileTree = props => {
    const {
        booking,
        folders,
        getBooking,
        directory
    } = props;

    const [tree, setTree] = useState();
    const [openFolderIdList, setOpenFolderIdList] = useState([]);
    const [elements, setElements] = useState([])
    const [newFolder, setNewFolder] = useState(null);
    const [rename, setRename] = useState(null);



    const insertFolder = (obj, folder, path) => {
        path = path ? path : folder.path?.split('/').filter(x => x);
        const currentPath = path.shift();

        if (currentPath === 'root') {
            if (!path.length) {
                obj.id = folder.id;
                obj.files = folder.files;
                return;
            }
            else
                return insertFolder(obj, folder, path);
        }
        else {
            let currentFolder = obj.folders.find(folder => folder.name === currentPath);

            if (!currentFolder) {
                obj.folders.push({ name: currentPath, folders: [] })
                currentFolder = obj.folders.find(folder => folder.name === currentPath);
            }

            if (!path.length) {
                currentFolder.id = folder.id;
                currentFolder.bookingId = folder.bookingId;
                currentFolder.path = folder.path;
                currentFolder.files = folder.files;
                currentFolder.isNewFolder = folder.isNewFolder || false;
                currentFolder.isRename = folder.isRename || false;
                return;
            } else
                return insertFolder(currentFolder, folder, path);
        }
    }

    const openFoldersAlongPath = (path, folders) => {
        const arr = [];

        const pathArray = path?.split('/');
        for (let i = 1; i < pathArray.length; i++) {
            const partialPath = pathArray.slice(0, i).join('/');
            const index = folders.findIndex(f => f.path === partialPath);

            if (index !== -1) {
                const id = folders[index].id;
                openFolderIdList.indexOf(id) === -1 ? arr.push(id) : null;
            }
        }
        setOpenFolderIdList(p => [...p, ...arr])
    }

    const generateTree = (folders, newFolder, rename) => {
        folders = folders ? folders : [];

        const obj = { name: 'root', folders: [] }
        const arr = JSON.parse(JSON.stringify(folders));
        if (newFolder) {
            arr.push(newFolder);
            openFoldersAlongPath(newFolder.path, folders);
        }

        if (rename) {
            if (rename.type === 'folder')
                arr[arr.findIndex(f => f.id === rename.id)].isRename = true;
            else {
                const folderIndex = arr.findIndex(f => f.id === rename.folderId);
                const fileIndex = arr[folderIndex].files.findIndex(f => f.id === rename.id);
                arr[folderIndex].files[fileIndex].isRename = true;
            }
        }

        arr.forEach(folder => insertFolder(obj, folder))
        setTree({ ...obj });
    }

    const getOpenFoldersAndFiles = (obj, arr, depth) => {
        obj.folders.forEach(folder => {
            arr.push({
                label: folder.name,
                depth,
                isOpen: openFolderIdList.includes(folder.id),
                type: folder.isNewFolder ? 'new folder' : folder.isRename ? 'rename' : 'folder',
                key: JSON.stringify(folder),
                ...folder
            });

            if (openFolderIdList.includes(folder.id))
                getOpenFoldersAndFiles(folder, arr, depth + 1)
        })

        if (depth === 1 || openFolderIdList.includes(obj.id))
            obj.files?.forEach(file => arr.push({
                label: `${file.filename}.${file.fileType}`,
                type: file.isRename ? 'rename' : 'file',
                depth,
                key: JSON.stringify(file),
                ...file
            }));
    }

    const getArrayOfObjects = (tree) => {
        if (!tree) { return }
        const arr = [];
        getOpenFoldersAndFiles(tree, arr, 1);
        setElements(arr)
    }

    const toggleFolder = id => {
        const index = openFolderIdList.indexOf(id);

        if (index !== -1)
            openFolderIdList.splice(index, 1);
        else
            openFolderIdList.push(id);

        setOpenFolderIdList([...openFolderIdList])
    }

    useEffect(() => {
        folders?.forEach(folder => folder.files?.sort((a, b) => new Date(a.dateTimeUploaded) - new Date(b.dateTimeUploaded)))
        generateTree(folders, newFolder, rename);
    }, [folders, newFolder, rename])

    useEffect(() => { getArrayOfObjects(tree); }, [openFolderIdList, tree])

    useEffect(() => {
        if (!folders?.find(f => f.path === '/root'))
            Axios.post('/api/v2/folder/create/one/root', {
                ...auth.getAuthData(),
                bookingId: booking.id
            })
                .then(getBooking)
                .catch(logout)
    }, [folders])

    return (
        <DisplayFileTree
            booking={booking}
            getBooking={getBooking}
            elements={elements}
            toggleFolder={toggleFolder}
            setNewFolder={setNewFolder}
            setRename={setRename}
            directory={directory}
            folders={folders}
        />
    )

}

export default FileTree;