import Axios from 'axios';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { flags } from '../../constants';
import { auth, logout } from '../../helperFunctions';
import Form from '../NewComponents';
import styles from './sidebar.module.css';
import { globalToastActions } from '../../Redux/actions'
import { useNavigate } from 'react-router-dom';
const componentId = '_' + crypto.randomUUID();

const GlobalSearch = props => {
    const [globalSearch, setGlobalSearch] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onChange = (name, value) => setGlobalSearch(value);

    const search = e => {
        const { key, type } = e;
        const { globalSearch } = ref.current;

        if (type && type === 'paste') {
            const text = e.clipboardData.getData('Text').trim();
            if (text) { searchDb(text) }
        }
        else if (key && key === 'Enter') {
            if (globalSearch) { searchDb(globalSearch) }
        }
    }

    const searchDb = (queryString) => {
        ref.current.abortController?.abort();
        ref.current.abortController = new AbortController();

        Axios.get('/api/v2/booking/read/globalsearch', {
            params: {
                ...auth.getAuthData(),
                queryString
            },
            signal: ref.current.abortController.signal
        })
            .then(result => handleRedirect(result.data))
            .catch(logout)
    }

    const handleRedirect = data => {
        if (!data || !data.length)
            dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Results', type: 'warning' } })
        else {
            let url;
            let state = {};

            switch (data[0].type) {
                case 'booking':
                    url = `/shipment/create/${data[0].data}`;
                    state = { searchTerm: data[0].data }
                    break;
                case 'shipping':
                    url = '/shipment/instructions';
                    state = { searchTerm: data[0].data }
                    break;
                case 'forecast':
                    if (data.length > 1) {
                        const uniqueWeeks = [];
                        data.map(row => row.data).forEach(wk => {
                            if (uniqueWeeks.indexOf(wk) === -1)
                                uniqueWeeks.push(wk)
                        })

                        dispatch({
                            type: globalToastActions.UPDATE_MSG, payload: {
                                msg: `Multiple results on the following weeks: ${uniqueWeeks.join(', ')}`,
                                type: 'warning',
                                displayLength: 5000
                            }
                        })
                    }

                    url = '/forecast';
                    state = {
                        filters: {
                            bookingNumber: ref.current.globalSearch,
                            forecastAssignedTo: 0,
                            bookingAssignedTo: 0,
                            week: parseInt(data[0].data)
                        }
                    }
                    break;
                default:
                    return;
            }

            navigate(url, { state })
        }
    }

    const selectContents = e => e.target.select();

    const focusGlobalSearch = e => {
        const { ctrlKey, keyCode } = e;
        if (ctrlKey && keyCode === 69) {
            e.preventDefault();
            document.querySelector(`#global-search-${componentId}`).select();
        }
    }

    const ref = useRef({
        globalSearch,
        abortController: new AbortController()
    })

    useEffect(() => {
        ref.current.globalSearch = globalSearch.trim()
    }, [globalSearch]);

    useEffect(() => {
        const id = `#global-search-${componentId}`;
        const component = document.querySelector(id);

        const initListeners = () => {
            component?.addEventListener('keyup', search);
            component?.addEventListener('paste', search);
            component?.addEventListener('click', selectContents);
            document.addEventListener('keydown', focusGlobalSearch);
        }

        initListeners();

        if (component) { component.select() }

        return () => ref.current.abortController.abort();
    }, [])

    useLayoutEffect(() => {
        const id = `#global-search-${componentId}`
        const component = document.querySelector(id);

        const removeListeners = () => {
            component?.removeEventListener('keyup', search);
            component?.removeEventListener('paste', search);
            component?.removeEventListener('click', selectContents);
            document.removeEventListener('keydown', focusGlobalSearch);
        }

        return removeListeners;
    }, [])

    return (
        <Form.TextInput
            label="Search"
            labelStyle={styles.label}
            col="collection-item s12"
            name={`global-search-${componentId}`}
            placeholder="Booking / L-Number"
            onChange={onChange}
            value={globalSearch}
            flags={[flags.ALLOW_EMPTY]}
        />
    )
}

export default GlobalSearch;