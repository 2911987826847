import React, { Component } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { connect } from "react-redux";
import M from 'materialize-css';
import { auth } from "../../../helperFunctions";


class Changelog extends Component {
    state = {
        bookingNumber: '',
        changes: []
    }

    close = () => {
        M.Modal.getInstance(document.getElementById('logModal')).close();
    }

    getChanges = () => {
        if (!this.props.bookingNumber) {
            return;
        }
        let bookingNumber = this.props.bookingNumber
        axios.get("/api/v1/changelog/", { params: { ...auth.getAuthData(), bookingNumber } }).then((result) => {

            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return
            } else if (result.data === "No Changes Found") {
                return;
            }

            for (let i = 0; i < result.data.length; i++) {
                if (result.data[i].field === "CARGO CUT DATE" || result.data[i].field === "DOC CUT DATE") {
                    result.data[i].new = moment.utc(result.data[i].new).clone().tz(result.data[i].newTimezone).format("MM/DD/YY HH:mm");
                    result.data[i].old = moment.utc(result.data[i].old).clone().tz(result.data[i].newTimezone).format("MM/DD/YY HH:mm");
                } else if (result.data[i].field === "ETD DATE" || result.data[i].field === "ETA DATE" || result.data[i].field === "ERD DATE") {
                    if (result.data[i].new === null) {
                        result.data[i].old = moment.utc(result.data[i].old).clone().tz(result.data[i].newTimezone).format("MM/DD/YY");
                    } else if (result.data[i].old === null) {
                        result.data[i].new = moment.utc(result.data[i].new).clone().tz(result.data[i].newTimezone).format("MM/DD/YY");
                    } else {
                        result.data[i].new = moment.utc(result.data[i].new).clone().tz(result.data[i].newTimezone).format("MM/DD/YY");
                        result.data[i].old = moment.utc(result.data[i].old).clone().tz(result.data[i].newTimezone).format("MM/DD/YY");
                    }
                } else if (result.data[i].field === "TIME PRINTED" || result.data[i].field === "TIME EMAIL SENT") {
                    if (result.data[i].new === null) {
                        result.data[i].old = moment.utc(result.data[i].old).local().format("MM/DD/YY HH:mm")
                    } else if (result.data[i].old === null) {
                        result.data[i].new = moment.utc(result.data[i].new).local().format("MM/DD/YY HH:mm")
                    } else {
                        result.data[i].new = moment.utc(result.data[i].new).local().format("MM/DD/YY HH:mm")
                        result.data[i].old = moment.utc(result.data[i].old).local().format("MM/DD/YY HH:mm")
                    }
                }
                result.data[i].time = moment.utc(result.data[i].time).local().format("MM/DD/YY HH:mm")
            }
            this.setState({ changes: result.data })
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.bookingNumber !== this.state.bookingNumber) {
            this.setState({ bookingNumber: this.props.bookingNumber })
        }
        if (prevProps.changeLogOpenSwitch !== this.props.changeLogOpenSwitch) {
            this.getChanges();
        }
    }

    shouldComponentUpdate = prevState => {
        return prevState.changes !== this.state.changes;
    }

    componentDidMount = () => {
        this.setState({ bookingNumber: this.props.bookingNumber })
    }

    render() {
        return (
            <div className="modal" id="logModal">
                <div className="modal-content row">
                    <span><i className="material-icons right" onClick={this.close}>cancel</i></span>

                    <div className="modalTableWrapper" style={{ textAlign: "center" }}>
                        <h5 style={{ paddingBottom: "20px" }}><b>Changes for Booking {Array.isArray(this.state.bookingNumber) ? this.state.bookingNumber.join(" / ") : this.state.bookingNumber}</b></h5>
                        {this.state.changes.length ?
                            <table className="left changeLogDisplay">
                                <thead>
                                    <tr>
                                        <th className="changeLogTableHeader">User</th>
                                        <th className="changeLogTableHeader">Changes</th>
                                        <th className="changeLogTableHeader">NEW</th>
                                        <th className="changeLogTableHeader"></th>
                                        <th className="changeLogTableHeader">OLD</th>
                                        <th className="changeLogTableHeader">Time</th>
                                        <th className="changeLogTableHeader">Changed From</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.changes.length ? this.state.changes.map(change => (
                                        <tr key={change.id} className={`${change.css}`}>
                                            <td className="s">{change.user}</td>
                                            <td className="s">{change.field}</td>
                                            <td className="s newValues"><b>{change.new}</b></td>
                                            <td className=""><i className="material-icons">keyboard_backspace</i></td>
                                            <td className="s">{change.old}</td>
                                            <td className="">{change.time}</td>
                                            <td className="" >
                                                {change.lastModifiedFrom && this.state.bookingNumber ?
                                                    change.lastModifiedFrom === this.state.bookingNumber[0] ?
                                                        "This Booking" : change.lastModifiedFrom : "This Booking"}</td>
                                        </tr>
                                    )) :
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            :
                            <h5>This Booking Has No Changes Yet</h5>
                        }
                    </div>
                </div>
            </div>
        )

    }
}

const mapStateToProps = state => {
    const { urls, index } = state;
    const url = urls[index];
    return { url };
};

export default connect(mapStateToProps)(Changelog);