import React, { Component } from 'react';
import { connect } from "react-redux";
import Form from "../../../Components/NewComponents";
import axios from "axios";
import { flags } from '../../../constants';
import { globalToastActions } from '../../../Redux/actions';
import { auth } from "../../../helperFunctions";

class SpecialRestrictionsDisplay extends Component {
    state = {
        specialRestrictionOriginId: this.props.origins.length ? this.props.origins[0].id : 0,
        specialRestrictionDestId: this.props.destinations.length? this.props.destinations[0].id : 0,
        specialRestrictionDate: "",
        specialRestrictionNote: "",
        editSpecialRestrictionOriginId: "",
        editSpecialRestrictionDestId: "", 
        editSpecialRestrictionDate: "",
        editSpecialRestrictionNote: "",
        showAddRestriction: false,
        editRowIndex: undefined,
        editRowTable: undefined
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    addSpecialRestriction = () => {
        let specialRestriction = {
            contractId: this.props.contract.contractDetails.id,
            originId: this.state.specialRestrictionOriginId,
            destId: this.state.specialRestrictionDestId,
            expirationDate: this.state.specialRestrictionDate,
            note: this.state.specialRestrictionNote
        };

        axios.post("/api/v1/contract/specialrestriction", { ...auth.getAuthData(), specialRestriction }).then( result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return;
            } else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Restriction Added" } });
                this.props.getContract();
            }

        })
    };

    deleteSpecialRestriction = (id) => {
        let contractId = this.props.contract.contractDetails.id;
        axios.post("/api/v1/contract/specialrestriction/delete", { ...auth.getAuthData(), id, contractId }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return;
            } else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Restriction Deleted" } });
                this.props.getContract();
            }

        })
    }

    makeActive = (index) => {

        let specialRestriction = this.props.contract.specialRestrictions[index];

        this.setState({ 
            editRowIndex: index,
            editSpecialRestrictionOriginId: specialRestriction.originId,
            editSpecialRestrictionDestId: specialRestriction.destId,
            editSpecialRestrictionDate: specialRestriction.expirationDate,
            editSpecialRestrictionNote: specialRestriction.note
        });

    }

    makeInactiveAndSave = (index) => {

        this.setState({ editRowIndex: null })

        let specialRestriction = {
            id: this.props.contract.specialRestrictions[index].id,
            originId: this.state.editSpecialRestrictionOriginId,
            destId: this.state.editSpecialRestrictionDestId,
            expirationDate: this.state.editSpecialRestrictionDate,
            note: this.state.editSpecialRestrictionNote
        }

        axios.post("/api/v1/contract/specialrestriction/edit", { ...auth.getAuthData(), specialRestriction }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return;
            } else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Restriction Saved" } });
                await this.props.getContract();
                this.setState({ 
                    editRowIndex: undefined,
                    editSpecialRestrictionOriginId: "",
                    editSpecialRestrictionDestId: "",
                    editSpecialRestrictionDate: "",
                    editSpecialRestrictionNote: ""
                });
            }
        })
    }

    render = () => {
        return (
            <div>
                <div className="row" >
                    <div className="col s12">

                        <h5 style={{ marginTop: "0px" }}>Special Restrictions</h5>
                        <table className="fixedSize">
                            <thead>
                                <tr className="tableHeaderLeftAlign">
                                    <th>Origin</th>
                                    <th>Destination</th>
                                    <th>Expiration Date</th>
                                    <th>Restriction</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="edit-specialRestrictions">
                                {this.props.contract.specialRestrictions.map((sr, index) => (
                                        this.state.editRowIndex === index ?
                                            <tr key={sr.id}>
                                                <td>
                                                    <Form.Select className="dynamicForm" name={`editSpecialRestrictionOriginId`} value={this.state.editSpecialRestrictionOriginId} onChange={this.onChange} list={this.props.origins} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                                </td>
                                                <td>
                                                    <Form.Select className="dynamicForm" name={`editSpecialRestrictionDestId`} value={this.state.editSpecialRestrictionDestId} onChange={this.onChange} list={this.props.destinations} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]}  />
                                                </td>
                                                <td>
                                                    <Form.DateInput stylingClass="dynamicForm" name={`editSpecialRestrictionDate`} value={this.state.editSpecialRestrictionDate} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]}  />
                                                </td>
                                                <td>
                                                    <Form.TextInput stylingClass="dynamicForm" name={`editSpecialRestrictionNote`} value={this.state.editSpecialRestrictionNote} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]}  />
                                                </td>
                                                <td>
                                                    <i className={`material-icons sr-form-${index} modalSaveIcon`} onClick={() => this.makeInactiveAndSave(index)}>save</i>
                                                </td>
                                            </tr>
                                            :
                                            <tr key={sr.id}>
                                                <td>{sr.originName}</td>
                                                <td>{sr.destName}</td>
                                                <td>{sr.expirationDate}</td>
                                                <td>{sr.note}</td>
                                                <td>
                                                    <i className={`material-icons sr-text-${index} editIcons col s6`} onClick={() => this.makeActive(index)}>edit</i>
                                                    <i className={`material-icons sr-text-${index} editIcons col s5`} onClick={() => this.deleteSpecialRestriction(sr.id)}>cancel</i>
                                                </td>
                                            </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row" style={{marginTop: "-20px"}}>
                    {!this.state.showAddRestriction ?
                        <div className="col s12 formDropdown" onClick={() => this.setState({ showAddRestriction: !this.state.showAddRestriction })} >
                            <i className="material-icons">keyboard_arrow_down</i><span>Add Restriction</span>
                        </div>
                        :
                        <div className="col s12">
                            <div className="row">
                                <div className="col s12 formDropdown" onClick={() => this.setState({ showAddRestriction: !this.state.showAddRestriction })} >
                                    <i className="material-icons">keyboard_arrow_up</i><span>Hide Form</span>
                                </div>
                            </div>
                            <div className="row">
                                <Form.Select col="col s2" name="specialRestrictionOriginId" value={this.state.specialRestrictionOriginId} label="Origin" list={this.props.origins} filter={{ label: "name", value: "id" }} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]}  />
                                <Form.Select col="col s2" name="specialRestrictionDestId" value={this.state.specialRestrictionDestId} label="Destination" list={this.props.destinations} filter={{ label: "name", value: "id" }} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]}  />
                                <Form.DateInput col="col s2" name="specialRestrictionDate" value={this.state.specialRestrictionDate} label="Date" onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="col s4" name="specialRestrictionNote" value={this.state.specialRestrictionNote} label="Note" onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Button col="col s2" label="Add" icon="add" type="outline" color="blue" onClick={this.addSpecialRestriction} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { index, urls, location, carrier, shipper } = state;
    const url = urls[index];
    return { url, location, shipper, carrier };
};

export default connect(mapStateToProps)(SpecialRestrictionsDisplay);