import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import Form from '../../../Components/NewComponents';
import { globalToastActions } from '../../../Redux/actions';
import { flags } from '../../../constants';
import { auth, withRouter } from "../../../helperFunctions";


class BookingNumberEditor extends React.Component {
    constructor(props) { super(props); this.state = { ...this.initState() }; }

    goBack = () => {

        let reduxState = this.props.location?.state;

        if (this.state.bookingNumbersArray.indexOf(this.props.location?.state?.searchTerm === -1)) {
            reduxState.searchTerm = this.state.bookingNumbersArray[0].bookingNumber
        } else {
            reduxState.searchTerm = this.props.location?.state?.searchTerm
        }

        this.props.navigate(`/shipment/create/${this.props.location?.state?.searchTerm}`, { state: reduxState })
    }

    initState = () => ({
        bookingId: this.props.location?.state?.bookingId,
        bookingNumbersArray: null,
        newBookingNumber: '',
        allowActions: true
    });

    initListeners = () => {
        document.getElementById('newBookingNumber').addEventListener('keydown', this.enterListener);
    }

    removeListeners = () => {
        document.getElementById('newBookingNumber').removeEventListener('keydown', this.enterListener);
    }

    enterListener = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.add();
        }
    }

    componentDidMount = () => {

        this.initListeners();
        document.getElementById("newBookingNumber").focus();

        if (this.state.bookingId)
            this.getBookingNumbers();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    onChange = (name, value) => this.setState({ [name]: value });

    getBookingNumbers = () => {
        return new Promise((resolve, reject) => {
            Axios.get('/api/v1/booking/bookingnumbers/byid',
                {
                    params: {
                        bookingId: this.state.bookingId,
                        ...auth.getAuthData()
                    }
                }
            ).then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return;
                }

                if (result.data.errno)
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'NO BOOKING NUMBERS FOUND', type: 'error' } })
                else
                    this.setState({ bookingNumbersArray: result.data });
                resolve(this.setState({ allowActions: true }))
            });
        })

    }

    toggleDisplay = index => {
        if (!this.state.allowActions)
            return;

        const bookingNumber = JSON.parse(JSON.stringify(this.state.bookingNumbersArray[index]));

        let sum = 0;
        this.state.bookingNumbersArray.forEach(bn => sum += parseInt(bn.isDisplayed));

        if (bookingNumber.isDisplayed && sum <= 1)
            return;

        this.setState({ allowActions: false })
        Axios.post('/api/v1/booking/bookingnumbers/display/update', {
            ...auth.getAuthData(),
            bookingNumberId: bookingNumber.id
        })
            .then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return;
                }

                if (result.data.errno)
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Error. Record not updated', type: 'error' } });
                else
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Updated Booking Number Id: ${result.insertId}`, type: 'success' } })

                this.getBookingNumbers();
            })

    }

    add = () => {
        if (!this.state.newBookingNumber.length || !this.state.newBookingNumber.trim().length) {
            return;
        } else if (this.state.bookingNumbersArray.map(bn => bn.bookingNumber).join(" / ").length > 120) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Exceeded Character Limit: Too many booking numbers.', type: 'error' } });
            return;
        }
        Axios.post('/api/v1/booking/bookingnumbers/add', {
            ...auth.getAuthData(),
            bookingNumber: {
                bookingId: this.state.bookingId,
                bookingNumber: this.state.newBookingNumber.trim().toUpperCase()
            }
        })
            .then(async result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return;
                }

                if (result.data.errno) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Error. Record not updated', type: 'error' } });
                } else {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Added Booking Number Id: ${result.data.insertId}`, type: 'success' } })
                    await this.getBookingNumbers();
                    this.goBack();
                }

            })
    }

    delete = (bookingNumberId, index) => {
        if (!this.state.allowActions)
            return;

        let displayedSum = 0;
        this.state.bookingNumbersArray.forEach(bn => displayedSum += parseInt(bn.isDisplayed))


        if (this.state.bookingNumbersArray.length > 1 && displayedSum > 0) {

            if (displayedSum === 1 && this.state.bookingNumbersArray[index].isDisplayed)
                return;

            this.setState({ allowActions: false })

            Axios.post('/api/v1/booking/bookingnumbers/delete', {
                ...auth.getAuthData(),
                bookingNumberId
            })
                .then(result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' })
                        return;
                    }

                    if (result.data.errno)
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Error. Record not updated', type: 'error' } });
                    else
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Deleted Booking Number Id: ${result.data.insertId}`, type: 'success' } })

                    this.getBookingNumbers();
                })
        }
    }
    render = () => (
        <div>
            <Form.Section label="Edit Booking Number">
                <div className="row">
                    <div className="col s4 row">
                        <Form.TextInput col="s8" name="newBookingNumber" label="New Booking Number" value={this.state.newBookingNumber} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} maxLength="20" />
                        <Form.Button col="s4" type="outline" label="Add" color="blue" onClick={this.add} />
                    </div>
                    <div className="col s8 row">
                        {this.state.bookingNumbersArray ? this.state.bookingNumbersArray.map((bn, index) => (
                            <div key={`${bn.bookingNumber}-checkbox`} className="row" style={{ borderBottom: 'gray 1px solid' }}>
                                <p style={{ fontSize: '1.4em', textAlign: "center" }} className="col s6">{bn.bookingNumber}</p>
                                <Form.Button col={`col s3 isDisplayedButton`} type="outline" color={bn.isDisplayed ? 'blue' : 'gray'} label={bn.isDisplayed ? 'DISPLAY: ON' : 'DISPLAY: OFF'} onClick={() => this.toggleDisplay(index)} />
                                <Form.Button col="col s3" type="outline" color="red" label="DELETE FOREVER" onClick={() => this.delete(bn.id, index)} />
                            </div>
                        )) : null}
                    </div>
                </div>
            </Form.Section>
            <div style={{ marginTop: '20px' }} className="row">
                <Form.Button col="s2" color="blue" label="Done" type="outline" onClick={this.goBack} />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { navs, urls, index } = state;
    const url = urls[index];
    return { url, navs }
}

export default connect(mapStateToProps)(withRouter(BookingNumberEditor));