import { Routes, Route } from 'react-router-dom';
import ContractOverview from './ContractOverview';
import ContractEdit from './ContractEdit/ContractEdit.js';

const Contracts = props => (
    <Routes>
        <Route path="/" element={<ContractOverview />} />
        <Route path="/edit" element={<ContractEdit />} />
    </Routes>
)

export default Contracts;