import React, { Component } from "react";
import axios from "axios";
import Form from "../../Components/NewComponents";
import styles from "./location.module.css";
import { flags } from "../../constants";
import { connect } from "react-redux";
import { init } from "../../Redux/actions";
import moment from "moment";
import { auth } from "../../helperFunctions";

class CreateLocation extends Component {
  state = {
    name: "",
    code: "",
    locationTypes: [],
    locationType: 1,
    portCode: '',
    country: 1,
    state: "",
    printableAddress: "",
    timeZone: "",
    timeZoneList: []
  };

  onChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  clearForms = () => {
    this.setState({
      name: "",
      code: "",
      locationTypes: [],
      locationType: 1,
      portCode: '',
      country: 1,
      state: "",
      printableAddress: "",
      timeZone: ""
    });
    this.grabTypes();
  };

  submitForms = async () => {
    try {
      let locationBlock = await {
        name: this.state.name.trim(),
        code: this.state.code.trim(),
        country_id: this.state.country,
        state: this.state.state ? this.state.state.trim() : this.state.state,
        time_zone: this.state.timeZone,
        type_id: this.state.locationType,
        port_code: this.state.portCode,
        printable_address: this.state.printableAddress ? this.state.printableAddress.trim() : this.state.printableAddress
      };
      if (
        locationBlock.name &&
        locationBlock.country_id &&
        locationBlock.type_id
      ) {
        if (
          locationBlock.name.length < 61 &&
          locationBlock.code.length < 5 &&
          locationBlock.time_zone.length < 31
        ) {
          axios
            .post("/api/v1/location", { ...auth.getAuthData(), locationBlock })
            .then(async result => {
              try {
                //double check if this await is doing anything. result.data is not a promise

                let answer = await result.data;
                if (result.data === "NOT AUTHENTICATED") {
                  localStorage.clear();
                  this.props.dispatch({
                    type: "SCORCHED_EARTH"
                  });
                  return;
                } else if (answer.fail) {
                  this.props.dispatch({
                    type: "UPDATE_MSG",
                    payload: { msg: result.data.msg, type: "error" }
                  });
                } else {
                  this.props.dispatch({
                    type: "UPDATE_MSG",
                    payload: { msg: "Location Created", type: "success" }
                  });
                  this.props.dispatch({
                    type: "UPDATE_URL",
                    payload: { url: "/location", state: {} }
                  })
                }
              } catch (err) {
                console.log(err);
              }
            });
        } else if (!locationBlock.state && locationBlock.country_id === "1") {
          this.props.dispatch({
            type: "UPDATE_MSG",
            payload: { msg: "State Field Is Required", type: "error" }
          });
        } else if (
          locationBlock.state.length > 2 &&
          locationBlock.country_id === "1"
        ) {
          this.props.dispatch({
            type: "UPDATE_MSG",
            payload: { msg: "State Field Too Long", type: "error" }
          });
        } else {
          this.props.dispatch({
            type: "UPDATE_MSG",
            payload: { msg: "Field(s) Too Long", type: "error" }
          });
        }
      } else {
        this.props.dispatch({
          type: "UPDATE_MSG",
          payload: { msg: "Missing Required Field(s)", type: "error" }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  grabTypes = () => {
    axios
      .get("/api/v1/locationtype", { params: auth.getAuthData() })
      .then(result => {
        if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({
            type: "SCORCHED_EARTH"
          });
          return;
        }
        this.setState({
          locationTypes: result.data
        });
      });
  };

  makeTimeZoneList = () => {

    let momentList = moment.tz.names();
    let namedList = []

    for (let i = 0; i < moment.tz.names().length; i++) {
      namedList.push({
        name: momentList[i],
        value: momentList[i]
      })
    }

    this.setState({
      timeZoneList: namedList
    })
  }

  componentDidMount = () => {
    this.makeTimeZoneList();
    this.grabTypes();
    document.getElementById('name').focus();
  };

  componentWillUnmount = () => {
    axios.get("/api/v1/location", { params: auth.getAuthData() }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({
          type: "SCORCHED_EARTH"
        });
        return;
      } else if (Array.isArray(result.data))
        this.props.dispatch({
          type: init.INITIALIZE_STORE,
          payload: { listName: "location", list: result.data }
        });
    });
  };
  render() {
    return (
      <div>
        <div className="row">
          <Form.TextInput name="name" label="Name" value={this.state.name} onChange={this.onChange} col="s4" flags={[flags.MAX_LENGTH + 60]} />
        </div>
        <div className="row">
          <Form.TextInput name="code" label="Code" value={this.state.code} onChange={this.onChange} col="s4" flags={[flags.MAX_LENGTH + 5]} />
          <p className={styles.notes}>Must be less than 4 characters</p>
        </div>
        <div className="row">
          <Form.Select name="locationType" label="Location Type" onChange={this.onChange} value={this.state.locationType} list={this.state.locationTypes} col="s2" filter={{ label: "type", value: "id" }} />
          <Form.TextInput name="portCode" label="AES Port Code" onChange={this.onChange} value={this.state.portCode} col="s2" placeholder="Ex. 3001" flags={[flags.ALLOW_EMPTY]} />
        </div>
        <div className="row">
          <Form.Select name="country" label="Country" onChange={this.onChange} value={this.state.country} list={this.props.country} col="s2" filter={{ label: "name", value: "id" }} />
          <Form.TextInput name="state" label="State" onChange={this.onChange} value={this.state.state} col="s2" flags={[flags.EXACT_LENGTH + 2, flags.ALLOW_EMPTY]} />
          <p className={styles.notes}>Must be two letters</p>
        </div>
        <div className="row">
          <Form.Select name="timeZone" label="Time Zone" value={this.state.timeZone} list={this.state.timeZoneList} onChange={this.onChange} col="s4" filter={{ label: "name", value: "value" }} />
          <p className={styles.notes}>
            Ex. America / Denver
            <a target="_blank" rel="noopener noreferrer" href="https://momentjs.com/timezone/">[?]</a>
          </p>
        </div>
        <div className="row">
          <Form.TextArea name="printableAddress" label="Address" value={this.state.printableAddress} onChange={this.onChange} col="s4" height="180px" />
        </div>
        <div className="row">
          <Form.Button col="s2" className="" type="outline" onClick={this.submitForms} label="Submit" icon="save" color="blue" />
          <Form.Button col="s2" className="" type="outline" color="red" onClick={this.clearForms} label="Clear" icon="cancel" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { country, urls, index } = state;
  const url = urls[index];
  return { country, url, index };
};

export default connect(mapStateToProps)(CreateLocation);
