import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from "moment-timezone";
import axios from 'axios';
import { auth, withRouter } from "../../helperFunctions";
import Form from "../../Components/NewComponents";
import StandingListModal from "./StandingListModal";
import { flags } from "../../constants";
import "./Forecast.css";
import styles from "./Forecast.module.css";
import M from "materialize-css";
import { globalToastActions } from '../../Redux/actions';
import DoubleWeekDisplay from './DoubleWeekDisplay';
import SingleWeekDisplay from './SingleWeekDisplay';

class ForecastOverview extends Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({
        filters: {
            forecastAssignedTo: this.props.user.id,
            bookingAssignedTo: this.props.user.id,
            bookingNumber: "",
            week: moment().isoWeek(),
            shipperName: "",
            carrierName: "",
            destName: "",
            originName: "",
            vesselName: "",
            voyageNumber: "",
            status: "ALL",
            user: this.props.user.id,
            tz: moment.tz.guess(),
            currentSort: "shipperName",
            reverse: 1,
            currentSort2: "carrierName",
            reverse2: 1,
            currentSort3: "destName",
            reverse3: 1,
            ...this.props.location?.state?.filters
        },
        standingListFilters: {
            currentSort: "shipperName",
            reverse: 1,
            currentSort2: "carrierName",
            reverse2: 1,
            currentSort3: "destName",
            reverse3: 1,
            user: this.props.user.id
        },
        showComparison: false,
        week: moment().isoWeek(),
        weeks: [...Array(moment().isoWeeksInYear()).keys()].map(x => { return { week: ++x } }),
        standingList: [],
        standingListCount: 0,
        weekList: [],
        previousWeekList: [],
        weekListCount: 0,
        previousWeekListCount: 0,
        vessels: [],
        origins: [],
        destinations: [],
        filterStatusList: [
            { label: "ALL" },
            { label: "NO STATUS" },
            { label: "NEW" },
            { label: "REQUESTED" },
            { label: "CONFIRMED" },
            { label: "DECLINED" },
            { label: "N.V.A" },
            { label: "DELETED" }
        ],
        statusList: [
            { label: "NEW", className: "NEW" },
            { label: "REQUESTED", className: "REQUESTED" },
            { label: "CONFIRMED", className: "CONFIRMED" },
            { label: "DECLINED", className: "DECLINED" },
            { label: "N.V.A", className: "NVA" }
        ],
        editRequest: {
            assignedTo: 0,
            userName: 0,
            shipperId: 0,
            carrierId: 0,
            containerCount: 0,
            originId: 0,
            destId: 0,
            bookingNumber: "",
            vesselId: 0,
            vesselName: "",
            voyageNumber: "",
            status: "REQUESTED",
            user: this.props.user.id
        },
        editNotesId: undefined,
        editNotesText: "",
        openListOptions: false,
        assignedTo: this.props.user.id,
        editRowIndex: undefined,
        outlineRequestIndex: undefined,
        outlineRequestTable: "right",
        showStatusMenu: false,
        showAssigneeMenu: false,
        isMouseDown: false,
        e: undefined,
        y: undefined,
    })

    initListeners = () => {
        document.querySelectorAll('.search-field').forEach(elem => elem.addEventListener('keydown', this.searchListener));
        document.addEventListener('mousedown', this.setMouseStatus);
        document.addEventListener('mouseup', this.setMouseStatus);
        document.addEventListener("keydown", this.editClickListener);
        document.addEventListener("keydown", this.saveRowListener);
        document.addEventListener("keydown", this.arrowKeyListener);
        document.addEventListener("keydown", this.masterHotkeyMap);
    }

    removeListeners = () => {
        document.querySelectorAll('.search-field').forEach(elem => elem.removeEventListener("keydown", this.searchListener));
        document.removeEventListener("mousedown", this.setMouseStatus);
        document.removeEventListener("mouseup", this.setMouseStatus);
        document.removeEventListener("keydown", this.editClickListener);
        document.removeEventListener("keydown", this.saveRowListener);
        document.removeEventListener("keydown", this.arrowKeyListener);
        document.removeEventListener("keydown", this.masterHotkeyMap);
    }

    initModal = () => {
        M.Modal.init(document.getElementById("standingListModal"));
        M.Modal.init(document.getElementById("notesModal"));
    }

    openModal = () => {
        M.Modal.getInstance(document.getElementById("standingListModal")).open();
    }

    closeModal = () => {
        M.Modal.getInstance(document.getElementById("standingListModal")).close();
    }

    openNotesModal = (id, text) => {
        this.setState({ editNotesId: id, editNotesText: text });
        M.Modal.getInstance(document.getElementById("notesModal")).open();
        document.getElementById("editNotesText").focus();

    }

    closeNotesModal = (e, id) => {
        this.setState({ editNotesId: '', editNotesText: '' })
        M.Modal.getInstance(document.getElementById("notesModal")).close();
    }

    onChange = (name, value) => {
        if (name === "showComparison") {
            this.setState({ editRowIndex: undefined, [name]: value });
        } else {
            this.setState({ [name]: value });
        }
    }

    requestOnChange = (name, value) => {
        this.setState({ editRequest: { ...this.state.editRequest, [name]: value } });
    }

    filterOnChange = (name, value) => {
        this.setState({ filters: { ...this.state.filters, [name]: value } });
    }

    filterOnChangePlus = (name, value) => {
        this.setState({ filters: { ...this.state.filters, [name]: value } }, () => {
            this.getWeekList();
        });
    }

    vesselInput = (name, value, vessel) => {
        vessel ?
            this.setState({ editRequest: { ...this.state.editRequest, vesselName: vessel.name, vesselId: vessel.id } }) :
            this.setState({ editRequest: { ...this.state.editRequest, vesselName: value, vesselId: 0 } })
    }

    searchListener = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            let activeField = document.getElementById(`${e.target.id}`);
            if (activeField) {
                activeField.setAttribute('autocomplete', 'new-password');
                activeField.blur();
                activeField.focus();
                activeField.setAttribute('autocomplete', 'on');
            };
            this.getWeekList();
        }
    }

    masterHotkeyMap = (e) => {
        if (e.keyCode === 27) {
            this.escListener(e);
        } else if (e.key === "Enter") {
            this.enterListener(e);
        }
        //  else if (e.ctrlKey && e.key === "c") {
        //     this.copyListener(e);
        // } else if (e.ctrlKey && e.key === "v") {
        //     this.pasteListener(e);
        // }
    }

    enterListener = (e) => {
        if (document.activeElement.tagName === "TR") {
            document.activeElement.children[7].click();
        } else {
            return;
        }
    }

    escListener = (e) => {
        e.preventDefault();
        document.querySelectorAll(".menu-selected").forEach(el => el.classList.remove("menu-selected"));
        let weekList = this.state.weekList;
        if (!this.state.editRequest.id && this.state.editRowIndex) {
            this.setState({ editRowIndex: undefined, weekList: this.state.weekList.filter(w => !!w.id) }, () => {
                document.getElementById(`week-list-${this.state.weekList.length - 1}`).focus();
            })
        } else if (!this.state.editRowIndex) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    assignedTo: this.props.user.id,
                    week: moment(new Date()).isoWeek(),
                    shipperName: "",
                    carrierName: "",
                    destName: "",
                    originName: "",
                    vesselName: "",
                    voyageNumber: "",
                    status: "ALL",
                    user: this.props.user.id,
                    tz: moment.tz.guess(),
                    currentSort: "shipperName",
                    reverse: ''
                },
                weekList,
                editRowIndex: undefined,
                outlineRequestIndex: undefined,
                showStatusMenu: false,
                showAssigneeMenu: false
            }, () => {
                this.getWeekListAndStandingList();
            })

        } else {
            this.setState({ editRowIndex: undefined }, () => {
                let table = document.getElementById(this.state.outlineRequestTable);
                table.querySelector(`#week-list-${this.state.outlineRequestIndex}`).focus();
            });
        }
    }

    editClickListener = (e) => {
        if (document.activeElement.tagName !== "I") {
            return;
        } else if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            document.activeElement.click();
        }
    }

    autoCompleteListener = (e) => {
        if (e.suggestion !== null) {
            this.setState({ openListOptions: true });
        } else {
            setTimeout(() => {
                this.setState({ openListOptions: false })
            }, 100)
        }

    }

    saveRowListener = (e) => {
        if (this.state.editRowIndex === null || this.state.editRowIndex === undefined) {
            return;
        } else if (e.ctrlKey && e.key.toUpperCase() === "S") {
            e.preventDefault();
            let chosenTable = e.target.closest("table[data-location]").id;
            this.makeInactiveAndSave(chosenTable);
            this.setState({ editRowIndex: false });
        } else if (e.key === "Enter" && !this.state.openListOptions && document.activeElement.tagName !== "I") {
            e.preventDefault();
            let acceptedIds = ['assignedTo', 'shipperId', 'carrierId', 'containerTypeId', 'containerCount', 'originId', 'destId', 'serviceRoute', 'bookingNumber', 'vesselName', 'voyageNumber', 'status'];
            if (!acceptedIds.includes(document.activeElement.id)) {
                return;
            } else {
                let chosenTable = e.target.closest("table[data-location]").id;
                this.state.editRequest.id ? this.makeInactiveAndSave(chosenTable) : this.saveUnique(chosenTable);
                this.setState({ editRowIndex: false });
            }
        }
    }

    arrowKeyListener = (e) => {
        let tag = document.activeElement.tagName;
        if (tag !== "TR") {
            return;
        } else if (((e.key === "Tab" && e.shiftKey) || e.key === "ArrowUp")) {
            if (this.state.outlineRequestTable === "left") {
                if (this.state.outlineRequestIndex === 0) {
                    return e.preventDefault();
                } else {
                    e.preventDefault();
                    this.setState({ outlineRequestIndex: this.state.outlineRequestIndex - 1 }, () => {
                        this.getTableAndFocus();
                    });
                }
            } else {
                if (this.state.outlineRequestIndex === 0) {
                    e.preventDefault();
                    this.setState({
                        outlineRequestTable: "left",
                        outlineRequestIndex: this.state.previousWeekList.length - 1
                    }, () => {
                        this.getTableAndFocus();
                    })
                } else {
                    e.preventDefault();
                    this.setState({ outlineRequestIndex: this.state.outlineRequestIndex - 1 }, () => {
                        this.getTableAndFocus();
                    });
                }
            }
        } else if (e.key === "ArrowDown" || e.key === "Tab") {
            if (this.state.outlineRequestTable === "left") {
                if (this.state.outlineRequestIndex === this.state.previousWeekList.length - 1) {
                    e.preventDefault();
                    this.setState({
                        outlineRequestTable: "right",
                        outlineRequestIndex: 0
                    }, () => {
                        this.getTableAndFocus();
                    })
                } else {
                    e.preventDefault();
                    this.setState({ outlineRequestIndex: this.state.outlineRequestIndex + 1 }, () => {
                        this.getTableAndFocus();
                    })
                }
            } else {
                if (this.state.outlineRequestIndex !== this.state.weekList.length - 1) {
                    e.preventDefault();
                    this.setState({ outlineRequestIndex: this.state.outlineRequestIndex + 1 }, () => {
                        this.getTableAndFocus();
                    })
                } else {
                    e.preventDefault();
                    return;
                }
            }
        } else if (e.key === "ArrowLeft") {
            e.preventDefault();
            if (this.state.outlineRequestTable === "left") {
                return;
            } else {
                if (this.state.outlineRequestIndex > this.state.previousWeekList.length - 1) {
                    return;
                } else {
                    this.setState({ outlineRequestTable: "left" }, () => {
                        this.getTableAndFocus();
                    });
                }
            }
        } else if (e.key === "ArrowRight") {
            e.preventDefault();
            if (this.state.outlineRequestTable === "right") {
                return;
            } else {
                if (this.state.outlineRequestIndex > this.state.weekList.length - 1) {
                    return;
                } else {
                    this.setState({ outlineRequestTable: "right" }, () => {
                        this.getTableAndFocus();
                    });
                }
            }
        } else {
            return e.preventDefault();
        }
    }

    getTableAndFocus = () => {
        let table = document.getElementById(this.state.outlineRequestTable);
        let row = table.querySelector(`#week-list-${this.state.outlineRequestIndex}`);
        if (row) {
            row.focus();
        }
    }

    // copyListener = (e) => {
    //     if (this.state.editRowIndex) {
    //         return;
    //     } else if (document.querySelectorAll(".menu-selected").length) {
    //         if (document.querySelectorAll(".menu-selected").length === 1) {
    //             e.preventDefault();
    //             let chosenTable = e.target.closest("table[data-location]").id;
    //             let list = chosenTable === "left" ? this.state.previousWeekList : this.state.weekList;
    //             let rowIndex = document.querySelector(".menu-selected").dataset.index;
    //             navigator.clipboard.writeText(`${this.state.weekList[rowIndex].vesselName}&&${this.state.weekList[rowIndex].voyageNumber}`);
    //             document.querySelectorAll(".menu-selected").forEach(el => el.classList.remove("menu-selected"))
    //             return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Vessel and Voyage Copied" } });
    //         } else {
    //             return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "warning", msg: "Too Many Rows Selected" } })
    //         }
    //     } else if (this.state.outlineRequestIndex) {
    //         e.preventDefault();
    //         navigator.clipboard.writeText(`${this.state.weekList[this.state.outlineRequestIndex].vesselName}&&${this.state.weekList[this.state.outlineRequestIndex].voyageNumber}`);
    //         return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Vessel and Voyage Copied" } });
    //     } else {
    //         return;
    //     }
    // }

    // pasteListener = async (e) => {
    //     if (this.state.editRowIndex) {
    //         return;
    //     } else if (document.querySelectorAll(".menu-selected").length) {
    //         e.preventDefault();
    //         let idList = [];
    //         let rows = document.querySelectorAll(".menu-selected");
    //         let text = await navigator.clipboard.readText();
    //         for (let i = 0; i < rows.length; i++) {
    //             idList.push(rows[i].dataset.id);

    //         }

    //         return axios.post("/api/v1/forecast/paste", { ...auth.getAuthData(), idList, text }).then(result => {
    //             if (result.data === "NOT AUTHENTICATED") {
    //                 localStorage.clear();
    //                 return this.props.dispatch({ type: "SCORCHED_EARTH" });
    //             } else if (result.data.err) {
    //                 return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Error" } });
    //             } else {
    //                 document.querySelectorAll(".menu-selected").forEach(el => el.classList.remove("menu-selected"))
    //                 return this.getWeekList();
    //             }
    //         });

    //     } else if (this.state.outlineRequestIndex) {
    //         e.preventDefault();
    //         let text = await navigator.clipboard.readText();
    //         let idList = [this.state.weekList[this.state.outlineRequestIndex].id];

    //         return axios.post("/api/v1/forecast/paste", { ...auth.getAuthData(), idList, text }).then(result => {
    //             if (result.data === "NOT AUTHENTICATED") {
    //                 localStorage.clear();
    //                 return this.props.dispatch({ type: "SCORCHED_EARTH" });
    //             } else if (result.data.err) {
    //                 return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Error" } });
    //             } else {
    //                 document.querySelectorAll(".menu-selected").forEach(el => el.classList.remove("menu-selected"))
    //                 return this.getWeekList();
    //             }
    //         });
    //     } else {
    //         return;
    //     }
    // }

    saveNotes = () => {

        this.closeNotesModal();

        axios.post("/api/v1/forecast/notes", { ...auth.getAuthData(), text: this.state.editNotesText, id: this.state.editNotesId }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.getWeekList();
                this.setState({ editNotesText: "" });
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Notes Saved", type: "success" } })
            }
        })
    }

    clearNotes = () => {
        this.setState({ editNotesText: "" })
    }

    onFocusReset = () => {
        this.setState({ outlineRequestIndex: 0 })
    }

    setMouseStatus = (e) => {
        if (e.ctrlKey && e.buttons === 1) {
            e.preventDefault();
            return this.setState({ isMouseDown: !this.state.isMouseDown });
        } else if (this.state.isMouseDown) {
            this.setState({ isMouseDown: false });
        } else {
            return;
        }
    }

    handleMouseOver = (e) => {
        if (this.state.isMouseDown && e.target.parentElement.tagName === "TR") {
            e.target.parentElement.classList.add('menu-selected');
            this.setState({ editRowIndex: undefined });
        }
    }

    handleMouseDown = (e) => {
        if (e.ctrlKey && e.buttons === 1 && e.target.parentElement.tagName === "TR") {
            this.setState({ editRowIndex: undefined });
            let list = [];
            e.target.parentElement.classList.forEach(c => list.push(c));
            if (list.includes("menu-selected")) {
                e.target.parentElement.classList.remove("menu-selected");
            } else {
                e.target.parentElement.classList.add("menu-selected");
            }
        } else if (e.ctrlKey && e.buttons === 1 && e.target.parentElement.tagName === "TD") {
            this.setState({ editRowIndex: undefined });
            let list = [];
            e.target.parentElement.parentElement.classList.forEach(c => list.push(c));
            if (list.includes('menu-selected')) {
                e.target.parentElement.parentElement.classList.remove('menu-selected');
            } else {
                e.target.parentElement.parentElement.clasList.add('menu-selected');
            }
        } else {
            return;
        }
    }

    handleStatusMenu = (e) => {
        e.preventDefault();
        if (document.querySelectorAll(".menu-selected").length) {
            this.setState({ showStatusMenu: true, showAssigneeMenu: false, x: e.pageX, y: e.pageY, })
        } else {
            document.querySelectorAll(".create-menu-highlight").forEach(el => el.classList.remove("create-menu-highlight"));
            e.target.parentElement.classList.add("create-menu-highlight")
            this.setState({ showCreateMenu: true, showStatusMenu: false, showAssigneeMenu: false, x: e.pageX, y: e.pageY });
        }
        document.addEventListener("click", this.clickListener);
    }

    handleAssigneeMenu = (e) => {
        e.preventDefault();
        if (document.querySelectorAll(".menu-selected").length) {
            this.setState({ showStatusMenu: false, showAssigneeMenu: true, x: e.pageX, y: e.pageY });
        } else {
            this.setState({ showCreateMenu: true, showStatusMenu: false, showAssigneeMenu: false, x: e.pageX, y: e.pageY });
            document.addEventListener("click", this.clickListener);
        }
    }

    clickListener = () => {
        document.querySelectorAll(".create-menu-highlight").forEach(el => el.classList.remove("create-menu-highlight"));
        this.setState({ showStatusMenu: false, showAssigneeMenu: false, showCreateMenu: false }, () => { document.removeEventListener("click", this.clickListener) })
    }

    getWeekListAndStandingList = () => {
        document.querySelectorAll(".menu-selected").forEach(el => el.classList.remove("menu-selected"));
        axios.get("/api/v1/forecast/", { params: { ...auth.getAuthData(), filters: this.state.filters } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                return this.setState({
                    week: this.state.filters.week,
                    weekList: result.data.weekList,
                    weekListCount: result.data.weekListCount,
                    previousWeekList: result.data.previousWeekList,
                    previousWeekListCount: result.data.previousWeekListCount,
                    standingList: result.data.standingList,
                    standingListCount: result.data.standingListCount,
                    outlineRequestIndex: undefined
                });
            }
        })
    }

    getWeekList = () => {
        document.querySelectorAll(".menu-selected").forEach(el => el.classList.remove("menu-selected"));
        axios.get("/api/v1/forecast/weeklist", { params: { ...auth.getAuthData(), filters: this.state.filters } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else if (!result.data.weekList.length && !result.data.previousWeekList.length) {
                this.setState({ weekList: [], previousWeekList: [], week: this.state.filters.week });
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "warning", msg: "No Results" } })
            } else {
                return this.setState({
                    week: this.state.filters.week,
                    weekList: result.data.weekList,
                    weekListCount: result.data.weekListCount,
                    previousWeekList: result.data.previousWeekList,
                    previousWeekListCount: result.data.previousWeekListCount,
                    editRowIndex: undefined
                }, () => {

                    if (document.getElementById(`week-list-${this.state.outlineRequestIndex}`)) {
                        document.getElementById(`week-list-${this.state.outlineRequestIndex}`).focus()
                    }
                });
            }
        })
    }

    getStandingList = () => {
        document.querySelectorAll(".menu-selected").forEach(el => el.classList.remove("menu-selected"));
        axios.get("/api/v1/forecast/standing", { params: { ...auth.getAuthData(), filters: { ...this.state.standingListFilters, tz: moment.tz.guess() } } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                return this.setState({ standingList: result.data.standingList, standingListCount: result.data.standingListCount, outlineRequestIndex: undefined });
            }
        })
    }

    getVessels = () => {
        return new Promise((resolve, reject) => {
            axios.get(`/api/v1/vessel/`, { params: auth.getAuthData() }).then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' });
                    reject();
                    return;
                }

                this.setState({ vessels: result.data }, () => resolve())
            });
        }).catch((err) => console.log(err))
    }

    createNewRequest = (e) => {
        if (!e.target) {
            e.target = e;
        }
        let chosenTable = e.target.closest('table[data-location]').id;
        let list = chosenTable === "left" ? this.state.previousWeekList : this.state.weekList;
        let listName = chosenTable === "left" ? "previousWeekList" : "weekList"
        let reqIndex = document.querySelector(".create-menu-highlight").dataset.index;
        let newRequest = {
            ...list[reqIndex],
            id: null,
            datetimeLastRequested: null,
            lastRequestedBy: null,
            bookingNumber: "",
            status: " "
        };
        let weekList = list;
        weekList.push(newRequest);
        this.setState({ [listName]: list }, () => {
            this.makeActive(e, weekList.length - 1, ".editBookingNumber > input")
        });

    }

    duplicateRequest = (e) => {
        let reqId = document.querySelector(".create-menu-highlight").dataset.id;
        axios.post("/api/v1/forecast/weeklist/duplicate", { ...auth.getAuthData(), reqId, week: moment().isoWeek() }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.getWeekList();
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Saved" } })
            }
        })
    }

    changeStatus = (status) => {
        let idList = [];
        document.querySelectorAll(".menu-selected").forEach(el => idList.push(el.dataset.id));
        axios.post("/api/v1/forecast/weeklist/status", { ...auth.getAuthData(), idList, status }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                document.querySelectorAll(".menu-selected").forEach(e => e.classList.remove("menu-selected"));
                this.setState({ showStatusMenu: false });
                return this.getWeekList();
            }
        })
    }

    changeAssignee = () => {
        let idList = [];
        document.querySelectorAll(".menu-selected").forEach(el => idList.push(el.dataset.id));
        axios.post("/api/v1/forecast/weeklist/assignee", { ...auth.getAuthData(), idList, assignedTo: this.state.assignedTo }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                document.querySelectorAll(".menu-selected").forEach(e => e.classList.remove("menu-selected"));
                this.setState({ showAssigneeMenu: false });
                return this.getWeekList();
            }
        })
    }

    makeActive = async (e, index, selector) => {

        if (e.ctrlKey) {
            return;
        } else {

            let chosenTable = e.target.closest("table[data-location]").id;
            let list = chosenTable === "left" ? this.state.previousWeekList : this.state.weekList;

            document.querySelectorAll(".menu-selected").forEach(el => el.classList.remove("menu-selected"));

            let r = list[index];

            this.setState({
                chosenTable,
                outlineRequestIndex: index,
                outlineRequestTable: chosenTable,
                editRowIndex: index,
                editRequest: {
                    ...this.state.editRequest,
                    ...r,
                    status: r.status ? r.status : "REQUESTED"
                }
            }, () => {
                return document.querySelector(selector).focus();
            });
            return;
        }
    }

    saveUnique = (chosenTable) => {
        let { editRequest } = this.state;
        let week = chosenTable === "left" ? this.state.filters.week - 1 : this.state.filters.week;

        for (let i = 0; i < Object.entries(editRequest).length; i++) {
            if (typeof Object.values(editRequest)[i] === "string") {
                if (Object.values(editRequest)[i].trim()) {
                    editRequest[Object.keys(editRequest)[i]] = Object.values(editRequest)[i].toUpperCase();
                } else {
                    editRequest[Object.keys(editRequest)[i]] = null;
                }
            }
        }

        axios.post("/api/v1/forecast/weeklist/unique", { ...auth.getAuthData(), editRequest, week }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.setState({ editRowIndex: null }, () => {
                    this.getWeekList();
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Saved" } })
                })
                return;
            }
        })

        return;
    }

    makeInactiveAndSave = (chosenTable) => {
        let list = chosenTable === "left" ? this.state.previousWeekList : this.state.weekList
        let outlineRequestIndex = list[this.state.editRowIndex + 1] ? this.state.editRowIndex + 1 : this.state.editRowIndex;
        let { editRequest } = this.state;

        for (let i = 0; i < Object.entries(editRequest).length; i++) {
            if (typeof Object.values(editRequest)[i] === "string") {
                editRequest[Object.keys(editRequest)[i]] = Object.values(editRequest)[i].toUpperCase();
            }
        }

        axios.post("/api/v1/forecast/weeklist", { ...auth.getAuthData(), editRequest }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.setState({ outlineRequestIndex, editRowIndex: null }, () => {
                    this.getWeekList();
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Saved" } })
                })
                return;
            }
        })

        return;
    }

    deleteWeekListItems = (e) => {
        let idList = [];
        document.querySelectorAll(".menu-selected").forEach(el => idList.push(el.dataset.id));

        axios.get("/api/v1/forecast/weeklist/delete", { params: { ...auth.getAuthData(), idList } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                document.querySelectorAll(".menu-selected").forEach(e => e.classList.remove("menu-selected"));
                this.setState({ showStatusMenu: false });
                return this.getWeekList();
            }
        })
    }

    deleteSingleWeekListItem = (e, id) => {
        axios.get("/api/v1/forecast/weeklist/delete", { params: { ...auth.getAuthData(), idList: [id] } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.getWeekList();
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Item Removed" } });
            }
        })
    }

    getOriginsAndDestinations = () => {
        let origins = this.props.locations.filter(o => (o.countryId === 1 || o.countryId === 17) && o.typeId !== 3);
        let destinations = this.props.locations.filter(d => d.countryId !== 1 && d.countryId !== 17 && d.typeId !== 3);
        this.setState({ origins, destinations, originId: origins[0].id, destId: destinations[0].id });
    }

    goToServiceSchedule = (e, index, selector) => {
        let list = e.target.closest("table[data-location]").id === "left" ? this.state.previousWeekList : this.state.weekList;
        let request = list[index];
        if (!request.serviceRoute || !request.serviceRoute.trim()) {
            return this.makeActive(e, index, selector)
        }
        let originList = ["PORTLAND", "SEATTLE", "TACOMA", "LONG BEACH", "LOS ANGELES", "OAKLAND"]
        if (!originList.includes(request.originName)) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Cannot Search For Chosen Origin" } })
        }
        return window.open(`/service/${request.serviceRoute}/${request.carrierId}/${request.originName}`, "_blank")
    }

    goToOverview = (e, index) => {
        let list = e.target.closest("table[data-location").id === "left" ? this.state.previousWeekList : this.state.weekList;
        let request = list[index];
        let { currentSort, currentSort2, currentSort3, reverse, reverse2, reverse3 } = this.state.filters;
        return this.props.navigate("/shipment", {
            state: {
                origin: "/forecast",
                forecastSorting: {
                    currentSort,
                    currentSort2,
                    currentSort3,
                    reverse,
                    reverse2,
                    reverse3
                },
                filters: {
                    ...this.state.filters,
                    currentSort: 'cargoCutDate',
                    reverse: -1,
                    currentSort2: "shipperName",
                    reverse2: 1,
                    currentSort3: "carrierName",
                    reverse3: 1
                },
                filterList: [
                    { id: 1, searchTerm: "ACTIVE", type: "status" },
                    { id: 3, searchTerm: request.shipperName, type: "shipperName" },
                    { id: 4, searchTerm: request.carrierName, type: "carrierName" },
                    { id: 5, searchTerm: request.originName, type: "originCode" },
                    { id: 6, searchTerm: request.destName, type: "destCode" },
                ]
            }
        })
    }

    sort = e => {
        if (e.ctrlKey) {
            e.preventDefault();
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort2 : e.target.getAttribute('data-sort');
            const reverse2 = this.state.filters.currentSort2 === value ? (-1 * this.state.filters.reverse2) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort2: value, reverse2 } }, () => {
                this.getWeekList();
            })

        } else if (e.altKey) {

            e.preventDefault();
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort3 : e.target.getAttribute('data-sort');
            const reverse3 = this.state.filters.currentSort3 === value ? (-1 * this.state.filters.reverse3) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort3: value, reverse3 } }, () => {
                this.getWeekList();
            })
        } else {
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort : e.target.getAttribute('data-sort');
            const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => {
                this.getWeekList();
            })
        }
    }

    standingListSort = e => {
        if (e.ctrlKey) {
            const value = typeof e === "string" ? this.props.location?.state?.standingListFilters?.currentSort2 : e.target.getAttribute('data-sort');
            const reverse2 = this.state.standingListFilters.currentSort2 === value ? (-1 * this.state.standingListFilters.reverse2) : 1;
            this.setState({ standingListFilters: { ...this.state.standingListFilters, currentSort2: value, reverse2 } }, () => {
                this.getStandingList();
            })

        } else if (e.altKey) {

            e.preventDefault();
            const value = typeof e === "string" ? this.props.location?.state?.standingListFilters?.currentSort3 : e.target.getAttribute('data-sort');
            const reverse3 = this.state.standingListFilters.currentSort3 === value ? (-1 * this.state.filters.reverse3) : 1;
            this.setState({ standingListFilters: { ...this.state.standingListFilters, currentSort3: value, reverse3 } }, () => {
                this.getStandingList();
            })

        } else {
            const value = typeof e === "string" ? this.props.location?.state?.standingListFilters?.currentSort : e.target.getAttribute('data-sort');
            const reverse = this.state.standingListFilters.currentSort === value ? (-1 * this.state.standingListFilters.reverse) : 1;
            this.setState({ standingListFilters: { ...this.state.standingListFilters, currentSort: value, reverse } }, () => {
                this.getStandingList();
            })
        }
    }

    componentDidMount = () => {
        this.initListeners();
        this.getWeekListAndStandingList();
        this.getOriginsAndDestinations();
        this.initModal();
        this.getVessels();

        setTimeout(() => {
            if (this.state.weekList.length) {
                let table = document.getElementById(this.state.outlineRequestTable);
                table.querySelector(`#week-list-0`).focus();
            }
        }, 500)
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => {
        return (
            <div>
                <div id="bkg-ov-search-fields">
                    <div className="row customMarginRemove">
                        <Form.Select col="col s2 search-field" placeholder="ALL" name="forecastAssignedTo" label="Fcst Assignee" onChange={this.filterOnChangePlus} value={this.state.filters.forecastAssignedTo} list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select col="col s2 search-field" placeholder="ALL" name="bookingAssignedTo" label="Bkg Assignee" onChange={this.filterOnChangePlus} value={this.state.filters.bookingAssignedTo} list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="shipperName" label="Shipper" onChange={this.filterOnChange} value={this.state.filters.shipperName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="carrierName" label="Carrier" onChange={this.filterOnChange} value={this.state.filters.carrierName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="originName" label="Origin" onChange={this.filterOnChange} value={this.state.filters.originName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="destName" label="Dest" onChange={this.filterOnChange} value={this.state.filters.destName} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                    <div className="row customMarginRemove">
                        <Form.Select col="col s1 search-field" name="week" label="Week" onChange={this.filterOnChangePlus} value={this.state.filters.week} list={this.state.weeks} filter={{ label: "week", value: "week" }} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Switch col={`col s1 ${styles.comparisonSwitch}`} name="showComparison" label="Show Comparison" onChange={this.onChange} value={this.state.showComparison} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 search-field" name="bookingNumber" label="Booking #" onChange={this.filterOnChange} value={this.state.filters.bookingNumber} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 search-field" name="vesselName" label="Vessel" onChange={this.filterOnChange} value={this.state.filters.vesselName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="voyageNumber" label="Voyage #" onChange={this.filterOnChange} value={this.state.filters.voyageNumber} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select col="col s2 search-field" name="status" label="Status" onChange={this.filterOnChangePlus} value={this.state.filters.status} list={this.state.filterStatusList} filter={{ label: "label", value: "label" }} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Button col="col s1" color="green" type="outline" label="Search" icon="search" onClick={this.getWeekList} />
                        <Form.Button col="col s1" color="blue" type="outline" label="Standing List" icon="list" onClick={this.openModal} />
                    </div>
                    {this.state.showComparison ?
                        <DoubleWeekDisplay
                            filters={this.state.filters}
                            sort={this.sort}
                            editRowIndex={this.state.editRowIndex}
                            editRequest={this.state.editRequest}
                            chosenTable={this.state.chosenTable}
                            outlineRequestIndex={this.state.outlineRequestIndex}
                            outlineRequestTable={this.state.outlineRequestTable}
                            onFocusReset={this.onFocusReset}
                            handleMouseDown={this.handleMouseDown}
                            handleMouseOver={this.handleMouseOver}
                            makeActive={this.makeActive}
                            makeInactiveAndSave={this.makeInactiveAndSave}
                            goToServiceSchedule={this.goToServiceSchedule}
                            goToOverview={this.goToOverview}
                            handleStatusMenu={this.handleStatusMenu}
                            handleAssigneeMenu={this.handleAssigneeMenu}
                            deleteSingleWeekListItem={this.deleteSingleWeekListItem}
                            onChange={this.onChange}
                            requestOnChange={this.requestOnChange}
                            shipper={this.props.shipper}
                            carrier={this.props.carrier}
                            portalUser={this.props.portalUser}
                            origins={this.state.origins}
                            destinations={this.state.destinations}
                            containerType={this.props.containerType}
                            vesselInput={this.vesselInput}
                            vessels={this.state.vessels}
                            statusList={this.state.statusList}
                            weekList={this.state.weekList}
                            previousWeekList={this.state.previousWeekList}
                            week={this.state.week}
                            saveUnique={this.saveUnique}
                            autoCompleteListener={this.autoCompleteListener}
                            openNotesModal={this.openNotesModal}
                            closeNotesModal={this.closeNotesModal}
                        />
                        :
                        <SingleWeekDisplay
                            filters={this.state.filters}
                            sort={this.sort}
                            editRowIndex={this.state.editRowIndex}
                            editRequest={this.state.editRequest}
                            chosenTable={this.state.chosenTable}
                            outlineRequestIndex={this.state.outlineRequestIndex}
                            outlineRequestTable={this.state.outlineRequestTable}
                            onFocusReset={this.onFocusReset}
                            handleMouseDown={this.handleMouseDown}
                            handleMouseOver={this.handleMouseOver}
                            makeActive={this.makeActive}
                            makeInactiveAndSave={this.makeInactiveAndSave}
                            goToServiceSchedule={this.goToServiceSchedule}
                            goToOverview={this.goToOverview}
                            handleStatusMenu={this.handleStatusMenu}
                            handleAssigneeMenu={this.handleAssigneeMenu}
                            deleteSingleWeekListItem={this.deleteSingleWeekListItem}
                            onChange={this.onChange}
                            requestOnChange={this.requestOnChange}
                            shipper={this.props.shipper}
                            carrier={this.props.carrier}
                            portalUser={this.props.portalUser}
                            origins={this.state.origins}
                            destinations={this.state.destinations}
                            containerType={this.props.containerType}
                            vesselInput={this.vesselInput}
                            vessels={this.state.vessels}
                            statusList={this.state.statusList}
                            weekList={this.state.weekList}
                            saveUnique={this.saveUnique}
                            autoCompleteListener={this.autoCompleteListener}
                            week={this.state.week}
                            openNotesModal={this.openNotesModal}
                            closeNotesModal={this.closeNotesModal}
                        />}

                    <b className={styles.weekListCount}>Total Results: {this.state.weekListCount.count}</b>

                    <div style={{ position: 'fixed', top: this.state.y, left: this.state.x }} className="lf-shipment-context-menu">
                        <ul className="collection">
                            {/* {this.state.showCreateMenu && <li onClick={this.duplicateRequest} className={`collection-item ${styles.createOption}`}>DUPLICATE</li>} */}
                            {this.state.showCreateMenu && <li onClick={() => this.createNewRequest(document.querySelector(".create-menu-highlight"))} className={`collection-item ${styles.createOption}`}>CREATE NEW</li>}
                            {/* {this.state.showContextMenu && <li className="collection-item blue-text" onClick={this.toggleFlag}>TOGGLE FLAG</li>} */}
                            {this.state.showStatusMenu && <li onClick={() => this.changeStatus('REQUESTED')} className={`collection-item ${styles.REQUESTED}`}>REQUESTED</li>}
                            {this.state.showStatusMenu && <li onClick={() => this.changeStatus('CONFIRMED')} className={`collection-item ${styles.CONFIRMED}`}>CONFIRMED</li>}
                            {this.state.showStatusMenu && <li onClick={() => this.changeStatus('DECLINED')} className={`collection-item ${styles.DECLINED}`}>DECLINED</li>}
                            {this.state.showStatusMenu && <li onClick={() => this.changeStatus('N.V.A')} className={`collection-item ${styles.NVA}`}>N.V.A</li>}
                            {this.state.showStatusMenu && <li onClick={(e) => this.deleteWeekListItems(e)} className={`collection-item ${styles.DELETED}`} >DELETE</li>}
                            {this.state.showAssigneeMenu && <li className="collection-item black-text contextList">
                                Assign Req To:&nbsp;
                                <Form.Select col="s2 search-field contextMenuSelect" className="contextMenuSelect" name="assignedTo" onChange={this.onChange} value={this.state.assignedTo} list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                <button className="assignButton" onClick={() => this.changeAssignee()}>Assign</button>
                            </li>}
                            {this.state.showAssigneeMenu && <li className="collection-item black-text material-icons cancelOption" onClick={() => this.setState({ showAssigneeMenu: false })}>
                                <span className="cancelWords"><i className="material-icons">cancel</i></span></li>}
                        </ul>
                    </div>

                </div>
                <StandingListModal
                    getWeekList={this.getWeekList}
                    getStandingList={this.getStandingList}
                    getWeekListAndStandingList={this.getWeekListAndStandingList}
                    standingListSort={this.standingListSort}
                    standingList={this.state.standingList}
                    standingListCount={this.state.standingListCount}
                    filters={this.state.filters}
                    standingListFilters={this.state.standingListFilters}
                    closeModal={this.closeModal}
                    sort={this.sort}
                    origins={this.state.origins}
                    destinations={this.state.destinations}
                    vessels={this.state.vessels}
                    week={this.state.filters.week}
                    weeks={this.state.weeks}
                    filterOnChangePlus={this.filterOnChangePlus}
                    saveUnique={this.saveUnique}
                />
                <div className="modal" id="notesModal">
                    <div className="modal-content">
                        <div className="row">
                            <Form.TextArea id="editNotesText" col="col s12" name="editNotesText" value={this.state.editNotesText} label="Notes" onChange={this.onChange} maxLength="240" />
                        </div>
                        <div className="row customMarginRemove">
                            <Form.Button col="s6" onClick={this.saveNotes} label="Save" type="outline" color="blue" />
                            <Form.Button col="s6" onClick={this.clearNotes} label="Clear" type="outline" color="red" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


const mapStateToProps = state => {
    const { urls, index, user, shipper, carrier, location, portalUser, containerType } = state;
    return { url: urls[index], user, shipper, carrier, locations: location, portalUser, containerType };
};

export default connect(mapStateToProps)(withRouter(ForecastOverview))