import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Form from '../../../Components/NewComponents'
import { globalToastActions } from '../../../Redux/actions';
import DescriptionOfGoods from './TabComponents';

const TabsContainer = (props) => {
    const calculateDescriptions = () => {
        let siData = JSON.parse(JSON.stringify(props.regContainerData));
        let ciData = JSON.parse(JSON.stringify(props.regContainerData));
        let cooData = JSON.parse(JSON.stringify(props.plContainerData));
        let bseData = JSON.parse(JSON.stringify(props.regContainerData));
        parseSI(siData);
        parsePL(props.plContainerData);
        parseCI(ciData);
        parseCOO(cooData);
        parseBSE(bseData);
        props.onChange('shouldRecalculate', false);
    }

    const parseSI = (arr) => {
        let contArr = arr;
        let containerCount = 0;
        let grossWeight = 0;
        let unitCount = 0;
        let totalCubicMeters = 0;
        let dog = [];
        let hsCode = [];

        contArr.forEach(row => {

            containerCount += parseInt(row.containerCount);
            unitCount += row.unitCount ? parseInt(row.unitCount) : 0;
            if (/[,]/.test(row.groupedCubicMeters)) {
                totalCubicMeters += row.groupedCubicMeters ? parseFloat(row.groupedCubicMeters.split(',').join('')) : 0;
            } else {
                totalCubicMeters += row.groupedCubicMeters ? parseFloat(row.groupedCubicMeters) : 0
            }

            if (dog.indexOf(row.description) === -1 && row.description)
                dog.push(row.description);

            if (hsCode.indexOf(row.hsCode) === -1 && row.hsCode)
                hsCode.push(row.hsCode);

            if (row.unitOfMeasurement === 'MT' &&
                /[,]/.test(row.groupedWeight)) {
                grossWeight += row.groupedWeight ? (parseFloat(row.groupedWeight.split(',').join('')) * 1000) : 0;
            } else if (row.unitOfMeasurement === 'MT') {
                grossWeight += row.groupedWeight ? (parseFloat(row.groupedWeight) * 1000) : 0;
            }
            else if (row.unitOfMeasurement === "LBS" &&
                /[,]/.test(row.groupedWeight)) {
                grossWeight += row.groupedWeight ? (parseFloat(row.groupedWeight.split(',').join('')) * 0.453592) : 0;
            }
            else if (row.unitOfMeasurement === 'LBS')
                grossWeight += row.groupedWeight ? (parseFloat(row.groupedWeight) * 0.453592) : 0;
            else if (row.unitOfMeasurement === "KG" &&
                /[,]/.test(row.groupedWeight)) {
                grossWeight += row.groupedWeight ? parseFloat(row.groupedWeight.split(',').join('')) : 0;
            }
            else if (row.unitOfMeasurement === "KG") {
                grossWeight += row.groupedWeight ? parseFloat(row.groupedWeight) : 0;
            }
        })

        let description = 'CONTAINERS SLAC: ' + (unitCount ? unitCount : '') + ' ' + (contArr[0].unit ? arr[0].unit : '') + ', ' + ((props.customTotal ? props.customTotal : grossWeight) / 1000).toFixed(3) + ' MT\n';
        description += dog.join(', ') + '\n';
        description += 'HS CODE: ' + hsCode.join(', ');

        if (props.customTotal) { grossWeight = parseInt(props.customTotal) };

        props.conParseChange('shippingInstructions', {
            marksAndNumbers: '',
            numberOfPackages: `${containerCount} - ${contArr[0].containerType}`,
            description,
            weight: grossWeight.toFixed(0),
            measurement: totalCubicMeters.toFixed(3)
        })

    }

    const parsePL = (arr) => {

        let dog = [];
        let hsCode = [];

        let plArr = arr;
        if (!Array.isArray(plArr))
            return '';

        let sum = 0;

        plArr.forEach((row, index) => {
            if (row.groupedWeight) {


                if (dog.indexOf(row.description) === -1 && row.description)
                    dog.push(row.description);

                if (hsCode.indexOf(row.hsCode) === -1 && row.hsCode)
                    hsCode.push(row.hsCode);

                if (row.unitOfMeasurement === 'KG') {
                    if (typeof row.groupedWeight === 'string') {
                        plArr[index].groupedWeight = (parseFloat((row.groupedWeight.split(',').join(''))) / 1000).toFixed(3);
                        sum += parseFloat(plArr[index].groupedWeight);
                    } else {
                        plArr[index].groupedWeight = parseFloat(row.groupedWeight).toFixed(3);
                        sum += parseFloat(plArr[index].groupedWeight);
                    }
                }
                else if (row.unitOfMeasurement === 'LBS') {
                    if (typeof row.groupedWeight === 'string') {
                        plArr[index].groupedWeight = (parseFloat(row.groupedWeight.split(',').join('')) * 0.000453592).toFixed(3);
                        sum += parseFloat(plArr[index].groupedWeight);
                    } else {
                        plArr[index].groupedWeight = parseFloat(row.groupedWeight).toFixed(3);
                        sum += parseFloat(plArr[index].groupedWeight);
                    }
                }
                else if (row.unitOfMeasurement === 'MT') {
                    if (typeof row.groupedWeight === 'string') {
                        plArr[index].groupedWeight = parseFloat(row.groupedWeight.split(',').join('')).toFixed(3);
                        sum += parseFloat(row.groupedWeight.split(',').join(''));
                    } else {
                        plArr[index].groupedWeight = parseFloat(row.groupedWeight).toFixed(3);
                        sum += parseFloat(row.groupedWeight);
                    }
                }
            }
        });

        let str = '';
        plArr.forEach(row => {
            let unit = ' MT\n';
            str += row.containerCount + ' - ' + row.containerType + '\n';
            str += 'CONTAINERS SLAC: ' + row.unitCount + ' ' + row.unit + ', ' + row.groupedWeight + `${unit}`;
            str += row.description + '\n';
            str += row.hsCode ? 'HS CODE: ' + row.hsCode + '\n\n' : '\n';
        });


        props.conParseChange('packingList', {
            description: str,
            totalGrossWeight: parseFloat(sum).toFixed(3),
            totalNetWeight: parseFloat(sum).toFixed(3),
            marksAndNumbers: ''
        })

    }


    const parseCI = (arr) => {

        let ciArr = arr;

        if (Array.isArray(ciArr)) {
            const data = ciArr;
            data.forEach((row, index) => {



                if (row.groupedWeight) {
                    if (row.unitOfMeasurement === 'KG') {
                        if (typeof row.groupedWeight === 'string') {
                            data[index].groupedWeight = parseFloat((row.groupedWeight.split(',').join(''))) / 1000;
                        } else {
                            data[index].groupedWeight = parseFloat(row.groupedWeight)
                        }
                    }
                    else if (row.unitOfMeasurement === 'LBS') {
                        if (typeof row.groupedWeight === 'string') {
                            data[index].groupedWeight = parseFloat(row.groupedWeight.split(',').join('')) * 0.000453592;
                        } else {
                            data[index].groupedWeight = parseFloat(row.groupedWeight);
                        }
                    }
                    else if (row.unitOfMeasurement === 'MT') {
                        if (typeof row.groupedWeight === 'string') {
                            data[index].groupedWeight = parseFloat(row.groupedWeight.split(',').join(''));
                        } else {
                            data[index].groupedWeight = parseFloat(row.groupedWeight);
                        }

                    }
                }


                let str = '';
                str += row.containerCount + ' - ' + row.containerType + '\n';
                str += 'CONTAINERS SLAC: ' + row.unitCount + ' ' + row.unit + ', ' + data[index].groupedWeight.toFixed(3) + ' MT\n';
                str += row.description + '\n';
                str += row.hsCode ? 'HS CODE: ' + row.hsCode + '\n' : '';

                row.description = str;
                row.groupedWeight = (data[index].groupedWeight).toFixed(3) + ' MT';
            })
            ciArr[0].termsOfPrice = "C AND F";
            ciArr[0].termsOfDelivery = props.deliveryCity;

        }

        props.conParseChange('commercialInvoice', ciArr)
    }

    const parseCOO = (arr) => {

        if(!props.originalSupplierName && !!props.id){
            props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Could Not Generate COO Doc Info. Please Make Sure All Parties Are Selected", type: "error" }});
            return;
        }
        const descriptionOfCommodities = [];
        const hsCode = [];
        const data = arr

        data.forEach((row, index) => {

            if (row.description && descriptionOfCommodities.indexOf(row.description) === -1)
                descriptionOfCommodities.push(row.description);

            if (row.hsCode && hsCode.indexOf(row.hsCode) === -1)
                hsCode.push(row.hsCode);

            if (row.groupedWeight) {
                if (row.unitOfMeasurement === 'KG') {
                    if (typeof row.groupedWeight === 'string') {
                        data[index].groupedWeight = (parseFloat((row.groupedWeight.split(',').join(''))) / 1000).toFixed(3);
                    } else {
                        data[index].groupedWeight = parseFloat(row.groupedWeight).toFixed(3)
                    }
                }
                else if (row.unitOfMeasurement === 'LBS') {
                    if (typeof row.groupedWeight === 'string') {
                        data[index].groupedWeight = (parseFloat(row.groupedWeight.split(',').join('')) * 0.000453592).toFixed(3);
                    } else {
                        data[index].groupedWeight = parseFloat(row.groupedWeight).toFixed(3);
                    }
                }
                else if (row.unitOfMeasurement === 'MT') {
                    if (typeof row.groupedWeight === 'string') {
                        data[index].groupedWeight = parseFloat(row.groupedWeight.split(',').join('')).toFixed(3);
                    } else {
                        data[index].groupedWeight = parseFloat(row.groupedWeight).toFixed(3);
                    }

                }
            }
        })

        data.description = descriptionOfCommodities;
        data.hsCode = hsCode;
        let country = props.supplierSameAsShipper ? props.party.filter(p => p.name === props.originalExporterName)[0].countryId
            : props.party.filter(p => p.name === props.originalSupplierName)[0].countryId;
        data[0].countryOfOrigin = props.country.filter(c => c.id === country)[0].name;
        data[0].countryOfOrigin = country === 1 ? "U.S.A." : data[0].countryOfOrigin;

        props.conParseChange('certificateOfOrigin', data);
    }

    const parseBSE = (arr) => {
        let i = 0;
        let product = []
        let grossWeight = 0;
        while (i < arr.length) {
            product.push(arr[i].product);
            if (arr[i].unitOfMeasurement === "KG") {
                if (typeof arr[i].groupedWeight === 'string') {
                    grossWeight += arr[i].groupedWeight ? (parseFloat(arr[i].groupedWeight.split(',').join('')) / 1000) : 0.00;

                } else {
                    grossWeight += arr[i].groupedWeight ? arr[i].groupedWeight : 0.00;

                }
            } else if (arr[i].unitOfMeasurement === "LBS") {
                if (typeof arr[i].groupedWeight === 'string') {
                    grossWeight += arr[i].groupedWeight ? (parseFloat(arr[i].groupedWeight.split(',').join('')) * 0.000453592) : 0.00;
                } else {
                    grossWeight += arr[i].groupedWeight ? arr[i].groupedWeight : 0.00;
                }
            } else if (arr[i].unitOfMeasurement === "MT") {
                if (typeof arr[i].groupedWeight === 'string') {
                    grossWeight += arr[i].groupedWeight ? parseFloat(arr[i].groupedWeight.split(',').join('')) : 0.00
                } else {
                    grossWeight += arr[i].groupedWeight ? arr[i].groupedWeight : 0.00;

                }
            }
            i++
        };


        let description = `${props.shipper} certifies that the product listed below is free from animal origin ingredients. This is to certify that ${product.join(", ")} does not contain materials derived from or exposed to animals affected by or under quarantine for B.S.E.`;
        let stateOfNotary = "Arizona";
        let countyOfNotary = "Maricopa";
        let cityOfNotary = "Mesa";

        if (props.customTotal) { grossWeight = parseInt(props.customTotal) / 1000 };

        props.conParseChange('bse', {
            description,
            stateOfNotary,
            countyOfNotary,
            cityOfNotary,
            product: product.join(", "),
            grossWeight: grossWeight.toFixed(3)
        })
    }

    useEffect(() => {
        if (props.shouldRecalculate) {
            calculateDescriptions();
        }
    })

    return (

        <Form.Section label="Description of Goods" col="descriptionOfGoods">
            <div className="row">

                <div className="col s12">
                    <DescriptionOfGoods.Tabs setState={props.onChange} />
                </div>
            </div>
            <div className="row">

                {(() => {
                    switch (props.dogPage) {
                        case 'shippingInstructions':
                            return <DescriptionOfGoods.SI onChange={props.dogOnChange}
                                marksAndNumbers={props.dogObj.shippingInstructions.marksAndNumbers} description={props.dogObj.shippingInstructions.description}
                                numberOfPackages={props.dogObj.shippingInstructions.numberOfPackages} weight={props.dogObj.shippingInstructions.weight}
                                measurement={props.dogObj.shippingInstructions.measurement} />
                        case 'packingList':
                            return <DescriptionOfGoods.PL onChange={props.dogOnChange} description={props.dogObj.packingList.description}
                                totalGrossWeight={props.dogObj.packingList.totalGrossWeight} totalNetWeight={props.dogObj.packingList.totalNetWeight}
                                marksAndNumbers={props.dogObj.packingList.marksAndNumbers} />
                        case 'commercialInvoice':
                            return <DescriptionOfGoods.CI onChange={props.arrayOnChange} commercialInvoice={props.dogObj.commercialInvoice}
                                showCiLineItemModal={props.showCiLineItemModal} shippingId={props.shippingId} />

                        case 'certificateOfOrigin':
                            return <DescriptionOfGoods.COO onChange={props.arrayOnChange} certificateOfOrigin={props.dogObj.certificateOfOrigin} preferenceCode={props.dogObj.preferenceCode} />
                        case 'bse':
                            return <DescriptionOfGoods.BSE onChange={props.dogOnChange} description={props.dogObj.bse.description} stateOfNotary={props.dogObj.bse.stateOfNotary}
                                countyOfNotary={props.dogObj.bse.countyOfNotary} cityOfNotary={props.dogObj.bse.cityOfNotary} product={props.dogObj.bse.product} grossWeight={props.dogObj.bse.grossWeight} />
                        default:
                            return <DescriptionOfGoods.SI onChange={props.dogOnChange}
                                marksAndNumbers={props.dogObj.shippingInstructions.marksAndNumbers} description={props.dogObj.shippingInstructions.description}
                                numberOfPackages={props.dogObj.shippingInstructions.numberOfPackages} weight={props.dogObj.shippingInstructions.weight}
                                measurement={props.dogObj.shippingInstructions.measurement} />
                    }
                })()}
            </div>
        </Form.Section>

    )

}

const mapStateToProps = state => {
    const { urls, index, party, country, location } = state;
    const url = urls[index]
    return { url, party, country, location }
}

export default connect(mapStateToProps)(TabsContainer);
