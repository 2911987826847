import { Routes, Route } from 'react-router-dom';
import CarrierOverview from './CarrierOverview';
import Alliance from './Alliance/Alliance';
import Vessel from './Vessel/Vessel';
import ServiceRoute from './ServiceRoute/ServiceRoute';
import UpdateCarrier from "./UpdateCarrier";

const Carriers = props => (
    <Routes>
        <Route path="/" element={<CarrierOverview />} />
        <Route path="/create" element={<UpdateCarrier />} />
        <Route path="/create/:id" element={<UpdateCarrier />} />
        <Route path="/alliance" element={<Alliance />} />
        <Route path="/vessel" element={<Vessel />} />
        <Route path="/serviceroute" element={<ServiceRoute />} />
    </Routes>
)


export default Carriers