import Axios from "axios";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate, useParams, useLocation, use } from "react-router-dom";
import { auth, logout } from '../../helperFunctions';
import BookingTab from './BookingTab/BookingTab';
import FilesTab from './FilesTab/FilesTab';
import M from 'materialize-css';
const pageId = '_' + crypto.randomUUID();

const UpdateShipment = props => {
    const { lNumber } = useParams();
    const navigate = useNavigate();

    const ref = useRef({ abortController: new AbortController() });
    const [booking, setBooking] = useState(null);
    const [carrierList, setCarrierList] = useState(null);
    const [partyList, setPartyList] = useState(null);
    const [containerTypeList, setContainerTypeList] = useState(null);
    const [locationList, setLocationList] = useState(null);
    const [employeeList, setEmployeeList] = useState(null);

    const getBooking = () => new Promise((resolve, reject) => {
        ref.current.abortController?.abort();
        ref.current.abortController = new AbortController();

        Axios.get('/api/v2/booking/read/one/deep/by/lnumber', {
            params: {
                ...auth.getAuthData(),
                lNumber
            },
            signal: ref.current.abortController.signal
        })
            .then(result => {
                setBooking(result.data)
                resolve();
            })
            .catch(err => logout(err, reject))
    })

    const getCarriers = () => {
        Axios.get('/api/v2/carrier/read/all/carriers', {
            params: auth.getAuthData(),
            signal: ref.current.abortController.signal
        })
            .then(result => setCarrierList(result.data))
            .catch(logout)
    }

    const getParties = () => {
        Axios.get('/api/v2/party/read/all', {
            params: auth.getAuthData(),
            signal: ref.current.abortController.signal
        })
            .then(result => setPartyList(result.data))
            .catch(logout)
    }

    const getContainerTypes = () => {
        Axios.get('/api/v2/container/read/all/containertypes', {
            params: auth.getAuthData(),
            signal: ref.current.abortController.signal
        })
            .then(result => setContainerTypeList(result.data))
            .catch(logout)
    }

    const getLocations = () => {
        Axios.get('/api/v2/location/read/all', {
            params: auth.getAuthData(),
            signal: ref.current.abortController.signal
        })
            .then(result => setLocationList(result.data))
            .catch(logout)
    }

    const getEmployees = () => {
        Axios.get('/api/v2/user/read/all/employees', {
            params: auth.getAuthData(),
            signal: ref.current.abortController.signal
        })
            .then(result => setEmployeeList(result.data))
            .catch(logout)
    }

    useEffect(() => {
        getBooking(lNumber)
        getCarriers();
        getParties();
        getContainerTypes();
        getLocations();
        getEmployees();
    }, [lNumber])

    useLayoutEffect(() => {
        const onKeyDown = e => {
            const { keyCode } = e;

            let openModal = false;

            if (keyCode === 27) {
                document.querySelectorAll('.modal').forEach(el => {
                    const modal = M.Modal.getInstance(el);
                    if (modal.isOpen)
                        openModal = true;
                })

                if (!openModal)
                    navigate(-1);
            }
            else if (keyCode === 116) {
                e.preventDefault();
                document.querySelector(`#${window.location.pathname.split('/').pop().toLowerCase() === 'booking' ? 'shippinginstructions' : 'booking'}-${pageId}`)?.click();
            }
        }


        document.addEventListener('keydown', onKeyDown)
        M.Tabs.init(document.querySelector(`#tabs-${pageId}`));
        document.querySelector(`#${window.location.pathname.split('/').pop().toLowerCase()}-${pageId}`)?.click();

        return () => {
            ref.current.abortController?.abort();
            document.removeEventListener('keydown', onKeyDown);
            document.querySelectorAll('.tabs').forEach(el => M.Tabs.getInstance(el)?.destroy())
        }
    }, [])

    const tabNavigate = e => {
        e?.preventDefault();
        navigate(`/new/updateshipment/${lNumber}/${e.target.id.split('-')[0]}`, { replace: true, });
    }

    return (
        <div style={{ height: '96%' }}>
            <div className="row">
                <div className="col s12">
                    <ul id={`tabs-${pageId}`} className="tabs">
                        <li className={`tab col s3 ${pageId}`}><a id={`booking-${pageId}`} href="#" className="white" onClick={tabNavigate}>Booking</a></li>
                        <li className={`tab col s3 ${pageId}`}><a id={`files-${pageId}`} href="#" className="white" onClick={tabNavigate}>Files ({booking?.folders?.reduce((p, c) => p + (c.files?.length || 0), 0) || 0})</a></li>
                        <li className={`tab col s3 ${pageId}`}><a id={`shippinginstructions-${pageId}`} href="#" className="white" onClick={tabNavigate}>Shipping Instructions</a></li>
                    </ul>
                </div>
            </div>
            {booking ?
                <Routes>
                    <Route path="booking" element={
                        <BookingTab
                            getBooking={getBooking}
                            booking={booking}
                            carrierList={carrierList}
                            partyList={partyList}
                            containerTypeList={containerTypeList}
                            locationList={locationList}
                            employeeList={employeeList}
                        />
                    }
                    />
                    <Route path="files" element={
                        <FilesTab
                            getBooking={getBooking}
                            booking={booking.booking}
                            folders={booking.folders}
                        />
                    }
                    />
                </Routes>
                :
                <div style={{ height: '10px', overflow: 'hidden' }}>
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                </div>
            }
        </div>
    )
}

export default UpdateShipment;