import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { auth, withRouter } from "../../../helperFunctions";

class RatesResultsDisplay extends Component {
    state = {
        showContextMenu: false,
        deleteBkg: null
    }

    initListeners = () => {
        document.addEventListener("keyup", this.bookingNumberSelect);

    }

    removeListeners = () => {
        document.removeEventListener("keyup", this.bookingNumberSelect);
    }

    handleEdit = async (e, contract) => {

        Axios.get("/api/v1/contract/carriers", { params: { ...auth.getAuthData(), shipperId: contract.shipperId, contractId: contract.id } }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH " });
            }

            let contracts = result.data.length ? result.data : [{ carrierName: "NO OTHER CONTRACTS" }];
            let carrierIds = new Array(...contracts.map(c => c.carrierId))

            let duplicates = [];
            let uniques = [];
            carrierIds.forEach((c) => {
                if (!uniques.includes(c)) {
                    uniques.push(c)
                } else {
                    duplicates.push(c)
                }
            })

            this.props.navigate('/contract/edit', {
                state: {
                    origin: "contractOverview",
                    contractSearchTerm: contract.id,
                    shipperId: contract.shipperId,
                    shipperName: contract.shipperName,
                    filters: this.props.filters,
                    status: contract.status,
                    carrierId: contract.carrierId,
                    carrierName: contract.carrierName,
                    contracts, duplicates
                }
            })
        })
    }

    refresh = () => {
        if (this.props.loading) {
            return;
        } else {
            this.props.getRates()
        }
    }
    componentDidMount = () => { this.refresh(); this.initListeners(); }
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.setState({ loading: false })
        }
    }
    componentWillUnmount = () => { this.removeListeners(); }

    render = () => {
        return (
            <Fragment>
                {this.props.loading ?
                    <tr>
                        <td>
                            <div className="preloader-wrapper big active overviewLoader">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    :
                    this.props.rates.map((r, index) => (
                        <tr className={`${r.expiredContract ? 'red-text' : ''} resultsDisplay`} id={`bkg-tr-${index}`} key={index} >
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>{r.status}</td>
                            <td className="bookingNum" onClick={(e) => this.handleEdit(e, r)}>{r.shipperName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>{r.carrierName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>{r.originName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>{r.destName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>${r.baseFreight}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>${r.totalPerContainerCharges}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>${r.totalRate}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>{r.destCountryName}</td>
                            <td className="refNum unselectable" onClick={(e) => this.handleEdit(e, r)}>{r.contractNumber}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>{r.commission}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>{r.effectiveDate}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, r)}>{r.expirationDate}</td>
                        </tr>
                    ))}
            </Fragment>
        )
    }
}


const mapStateToProps = state => {
    const { location, user, urls, index } = state;
    const url = urls[index];
    return { location, user, url }
}

export default connect(mapStateToProps)(withRouter(RatesResultsDisplay)) 