import { useRef } from "react";
import { useEffect, useLayoutEffect, useState } from "react";
import TableRow from './TableRow';
const componentId = '_' + crypto.randomUUID();

const Table = props => {
    const {
        bookingList,
        navigateToUpdateShipmentPage,
        urlLNumber
    } = props;


    const ref = useRef({ abridgedList: null, selectedLNumber: null })
    const [abridgedList, setAbridgedList] = useState(null);
    const [selectedLNumber, setSelectedLNumber] = useState(null);

    useEffect(() => {
        setAbridgedList(bookingList?.slice(0, 100));
    }, [bookingList])

    useEffect(() => setSelectedLNumber(urlLNumber), [urlLNumber]);

    const scrollListener = e => {
        const { offsetTop: childOffsetTop } = document.querySelector(`#tbody-${componentId}>tr:last-child`);
        const { scrollTop: containerScrollTop, clientHeight: containerHeight } = document.querySelector('#master-content');

        if (containerScrollTop + containerHeight > childOffsetTop)
            setAbridgedList(p => bookingList?.slice(0, p?.length + 100));
    }

    useLayoutEffect(() => {
        const tableFunctionListener = e => {
            const { keyCode } = e;
            const { selectedLNumber } = ref.current;

            if ([38, 40].includes(keyCode)) {
                const { abridgedList } = ref.current;

                if (!selectedLNumber)
                    setSelectedLNumber(abridgedList[0].booking.lNumber)
                else {
                    const index = abridgedList.findIndex(bkg => bkg.booking.lNumber === selectedLNumber);
                    let lNumber = abridgedList[index].booking.lNumber;

                    if (keyCode === 38 && index)
                        lNumber = abridgedList[index - 1].booking.lNumber
                    else if (keyCode === 40 && index < abridgedList.length)
                        lNumber = abridgedList[index + 1].booking.lNumber

                    const tr = document.querySelector(`#${lNumber}-${componentId}`);
                    const content = document.querySelector('#master-content');
                    const top = tr.getBoundingClientRect().top;
                    
                    if(top < 40 || top > content.clientHeight - 40)
                        tr.scrollIntoView();
                    
                    setSelectedLNumber(lNumber);
                }
            }
            else if (keyCode === 13 && selectedLNumber)
                navigateToUpdateShipmentPage(selectedLNumber, 'booking');
        }


        document.getElementById('master-content')?.addEventListener('scroll', scrollListener);
        document.addEventListener('keydown', tableFunctionListener);

        return () => {
            document.getElementById('master-content')?.removeEventListener('scroll', scrollListener);
            document.removeEventListener('keydown', tableFunctionListener);
        }
    }, [abridgedList])

    useEffect(() => {
        ref.current.abridgedList = abridgedList;
        ref.current.selectedLNumber = selectedLNumber;
    }, [abridgedList, selectedLNumber])

    return (
        <table style={{ overflowX: 'auto', fontSize: '.8em' }}>
            <thead>
                <tr style={{ borderBottom: '2px solid black' }}>
                    <th colSpan={3} className="grey-text" style={{ padding: '4px', textAlign: 'left', fontWeight: '500' }}>{bookingList ? bookingList.length + ' results' : ''}</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Booking #</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>L #</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Ref #</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Shipper</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Carrier</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>FCL</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Origin</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>POL</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Dest</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Vessel</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Voyage</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Service</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>ERD</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Cut</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Doc</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>ETD</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>ETA</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Pickup</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>Return</th>
                    <th style={{ padding: '4px', textAlign: 'left' }}>SI</th>
                </tr>
            </thead>
            <tbody id={`tbody-${componentId}`}>
                {abridgedList?.map(booking => (
                    <TableRow
                        key={booking.booking.id}
                        booking={booking}
                        selectedLNumber={selectedLNumber}
                        navigateToUpdateShipmentPage={navigateToUpdateShipmentPage}
                        componentId={componentId}
                    />
                ))}
            </tbody>
        </table >
    )
}

export default Table;