import { Routes, Route } from 'react-router-dom';
import CreateInvoice from './CreateInvoice';
import InvoiceOverview from './InvoiceOverview';

const Invoice = props => (
    <Routes>
        <Route path="/overview" element={<InvoiceOverview />} />
        <Route path="/create" element={<CreateInvoice />} />
    </Routes>
)

export default Invoice;