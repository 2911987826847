import { useState } from "react"
import Form from '../../Components/NewComponents';
import MaskedInput from "react-text-mask";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import BubbleRow from './BubbleRow';
import moment from "moment";
const componentId = '_' + crypto.randomUUID();

const Search = props => {
    const {
        search,
        urlParams
    } = props;

    const initValues = () => ({
        queueSearch: false,
        bookingNumber: '',
        shipperReferenceNumber: '',
        consigneeName: '',
        lNumber: '',
        billOfLadingNumber: '',
        pickupName: '',
        returnName: '',
        destinationCountryName: '',
        dateType: 'CUT',
        portOfLoad: '',
        startDate: '',
        portOfLoadName: '',
        vesselName: '',
        voyageNumber: '',
        serviceRoute: '',
        status: 'ACTIVE',
        dummyType: 'ALL',
        shipperName: '',
        carrierName: '',
        originName: '',
        destinationName: '',
    })


    const ref = useRef({ values: initValues(), bubbles: {} })
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [values, setValues] = useState(initValues());
    const [bubbles, setBubbles] = useState({});
    const [searchIsQueued, setSearchIsQueued] = useState(false);

    useEffect(() => {
        if (urlParams === 'NO HASH')
            initiateSearch();
        else if (urlParams) {
            search(urlParams);
            setValues(p => ({
                ...p,
                dateType: urlParams.dateType,
                startDate: urlParams.startDate,
                endDate: urlParams.endDate,
                status: urlParams.status,
                dummyType: urlParams.dummyType
            }))

            const [newValues, newBubbles] = prepareBubbles({ ...urlParams, selectedLNumber: '' }, {})
            setBubbles({ ...newBubbles })
        }
    }, [urlParams])

    const onChange = ({ target: { id, value } }) => {
        setValues(p => ({ ...p, [id]: value }))
        if (['dateType', 'status', 'dummyType'].includes(id))
            setSearchIsQueued(true);
    }

    const clear = () => setValues(initValues());

    useEffect(() => { ref.current.values = values; ref.current.bubbles = bubbles }, [values, bubbles])

    const initiateSearch = () => {
        const [newValues, newBubbles] = prepareBubbles(values, bubbles);

        search(newValues);
        setBubbles({ ...newBubbles });

        setValues(p => ({
            ...initValues(),
            dateType: p.dateType,
            status: p.status,
            dummyType: p.dummyType,
            startDate: p.startDate,
            endDate: p.endDate,
            queueSearch: false
        }))
    }

    const prepareBubbles = (values, bubbles) => {
        const skipValues = ['dateType', 'status', 'dummyType', 'startDate', 'endDate', 'queueSearch'];

        Object.keys(values).forEach(key => {
            if (!skipValues.includes(key) && values[key]) {
                if (!bubbles[key])
                    bubbles[key] = []

                bubbles[key] = [
                    ...bubbles[key],
                    ...values[key]
                        .split(',')
                        .map(x => x.trim())
                        .filter(x => x
                            && !bubbles[key].includes(x.trim())
                            && !bubbles[key].includes(x.substring(4, x.length).trim()))
                ];
            }
        })

        const newValues = { ...values };

        Object.keys(bubbles).forEach(key => {
            newValues[key] = bubbles[key].join(',');
        })

        return [newValues, bubbles]
    }

    useLayoutEffect(() => {
        const enterListener = ({ keyCode }) => keyCode === 13 ? initiateSearch() : null;
        const escapeListener = ({ keyCode }) => {
            if(keyCode === 27){
                setBubbles({});
                setValues(initValues());
                ref.current.bubbles = {};
                ref.current.values = initValues();
                initiateSearch();
            }
        }

        document.addEventListener('keydown', escapeListener);
        document.querySelectorAll('.lf-input>input[type="text"]').forEach(el => {
            el?.addEventListener('keydown', enterListener);
        })

        M.Collapsible.init(document.querySelectorAll(`#collapsible-${componentId}`), {
            onOpenStart: () => setShowAdvancedOptions(true),
            onCloseStart: () => setShowAdvancedOptions(false),
        })

        return () => {
            document.removeEventListener('keydown', escapeListener);
            document.querySelectorAll('.lf-input>input[type="text"]').forEach(el => {
                el?.removeEventListener('keydown', enterListener);
            })

            document.querySelectorAll(`#collapsible-${componentId}`).forEach(el => {
                M.Collapsible.getInstance(el)?.destroy();
            })
        }
    }, [showAdvancedOptions])

    const popBubble = id => {
        const [key, index] = id.split('-');

        const bubbles = JSON.parse(JSON.stringify(ref.current.bubbles));
        const arr = bubbles[key];
        arr.splice(index, 1)
        bubbles[key] = arr;

        const [newValues, newBubbles] = prepareBubbles(ref.current.values, bubbles)
        search(newValues)
        setBubbles({ ...newBubbles })
    }

    useEffect(() => {
        if (searchIsQueued) {
            initiateSearch();
            setSearchIsQueued(false)
        }
    }, [searchIsQueued])

    const changeDateAndSearch = type => {
        switch (type) {
            case 'today':
                const today = moment().format('MM/DD/YY');
                setValues(p => ({ ...p, startDate: today, endDate: today, queueSearch: true }))
                break;
            case 'this week':
                setValues(p => ({
                    ...p,
                    startDate: moment().startOf('week').format('MM/DD/YY'),
                    endDate: moment().endOf('week').format('MM/DD/YY'),
                    queueSearch: true
                }))
                break;
            case 'next week':
                setValues(p => ({
                    ...p,
                    startDate: moment(p.startDate, 'MM/DD/YY').add(7, 'days').format('MM/DD/YY'),
                    endDate: moment(p.endDate, 'MM/DD/YY').add(7, 'days').format('MM/DD/YY'),
                    queueSearch: true
                }))
                break;
            case 'prev week':
                setValues(p => ({
                    ...p,
                    startDate: moment(p.startDate, 'MM/DD/YY').subtract(7, 'days').format('MM/DD/YY'),
                    endDate: moment(p.endDate, 'MM/DD/YY').subtract(7, 'days').format('MM/DD/YY'),
                    queueSearch: true
                }))
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className="row">
                <ul id={`collapsible-${componentId}`} className="collapsible" style={{ marginBottom: '0px' }}>
                    <li >
                        <div className="collapsible-header" style={{ padding: '2px 10px' }}><i className="material-icons">{showAdvancedOptions ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>Advanced Search</div>
                        <div className="collapsible-body" style={{ padding: '10px 20px' }}>
                            <div className="row" style={{ marginBottom: '0px' }}>
                                <div className="lf-input col s4">
                                    <input id="bookingNumber" className="browser-default" type="text" value={values.bookingNumber} onChange={onChange} />
                                    <label htmlFor="bookingNumber">Booking #</label>
                                </div>
                                <div className="lf-input col s2">
                                    <input id="shipperReferenceNumber" className="browser-default" type="text" value={values.shipperReferenceNumber} onChange={onChange} />
                                    <label htmlFor="shipperReferenceNumber">Ref #</label>
                                </div>
                                <div className="lf-input col s2">
                                    <input id="consigneeName" className="browser-default" type="text" value={values.consigneeName} onChange={onChange} />
                                    <label htmlFor="consigneeName">Consignee</label>
                                </div>
                                <div className="lf-input col s4">
                                    <input id="lNumber" className="browser-default" type="text" value={values.lNumber} onChange={onChange} />
                                    <label htmlFor="lNumber">L #</label>
                                </div>
                                <div className="lf-input col s2">
                                    <input id="billOfLadingNumber" className="browser-default" type="text" value={values.billOfLadingNumber} onChange={onChange} />
                                    <label htmlFor="billOfLadingNumber">Bill of Lading #</label>
                                </div>
                                <div className="lf-input col s2">
                                    <input id="pickupName" className="browser-default" type="text" value={values.pickupName} onChange={onChange} />
                                    <label htmlFor="pickupName">Pickup</label>
                                </div>
                                <div className="lf-input col s2">
                                    <input id="returnName" className="browser-default" type="text" value={values.returnName} onChange={onChange} />
                                    <label htmlFor="returnName">Return</label>
                                </div>
                                <div className="lf-input col s2">
                                    <input id="destinationCountryName" className="browser-default" type="text" value={values.destinationCountryName} onChange={onChange} />
                                    <label htmlFor="destinationCountryName">Dest Country</label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="row" style={{ marginBottom: '0px' }}>
                <div className="lf-input col s2 xl1">
                    <select id="dateType" className="browser-default" value={values.dateType} onChange={onChange}>
                        <option value="CUT">CUT</option>
                        <option value="DOC">DOC</option>
                        <option value="ETD">ETD</option>
                        <option value="ETA">ETA</option>
                    </select>
                    <label htmlFor="dateType">Date Type</label>
                </div>
                <div className="col s2 m3 l3 xl1 row dateSearchButtonContainer">
                    <Form.Button col="s3 dateSearchButtons" icon="today" iconClass="tiny" type="outline" color="black" onClick={() => changeDateAndSearch('today')} />
                    <Form.Button col="s3 dateSearchButtons" icon="view_week" iconClass="tiny" type="outline" color="black" onClick={() => changeDateAndSearch('this week')} />
                    <Form.Button col="s3 dateSearchButtonsArrowBack" icon="arrow_backward" iconClass="tiny" type="outline" color="black" onClick={() => changeDateAndSearch('prev week')} />
                    <Form.Button col="s3 dateSearchButtons dateSearchButtonsRight" icon="arrow_forward" iconClass="tiny" type="outline" color="black" onClick={() => changeDateAndSearch('next week')} />
                </div>
                <div className="lf-input col s2 xl1">
                    <MaskedInput id="startDate" className="browser-default" type="text" value={values.startDate} onChange={onChange} placeholder="mm/dd/yy" mask={[/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]} />
                    <label htmlFor="startDate">Start Date</label>
                </div>
                <div className="lf-input col s2 xl1">
                    <MaskedInput id="endDate" className="browser-default" type="text" value={values.endDate} onChange={onChange} placeholder="mm/dd/yy" mask={[/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]} />
                    <label htmlFor="endDate">End Date</label>
                </div>
                <div className="lf-input col s3 xl1">
                    <input id="portOfLoadName" type="text" className="browser-default" value={values.portOfLoadName} onChange={onChange} />
                    <label htmlFor="portOfLoadName">POL</label>
                </div>
                <div className="lf-input col s3 xl2">
                    <input id="vesselName" type="text" className="browser-default" value={values.vesselName} onChange={onChange} />
                    <label htmlFor="vesselName">Vessel</label>
                </div>
                <div className="lf-input col s3 xl2">
                    <input id="voyageNumber" type="text" className="browser-default" value={values.voyageNumber} onChange={onChange} />
                    <label htmlFor="voyageNumber">Voyage</label>
                </div>
                <div className="lf-input col s2 xl1">
                    <input id="serviceRoute" type="text" className="browser-default" value={values.serviceRoute} onChange={onChange} />
                    <label htmlFor="serviceRoute">Service</label>
                </div>
                <div className="lf-input col s2 xl1">
                    <button data-color="blue" className="waves-effect waves-light" onClick={initiateSearch}>Search <i className="material-icons">search</i></button>
                </div>
                <div className="lf-input col s2 xl1">
                    <button data-color="red" className="waves-effect waves-light" onClick={clear}>Clear<i className="material-icons">close</i></button>
                </div>
                <div className="lf-input col s1">
                    <select id="status" className="browser-default" value={values.status} onChange={onChange}>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="CLOSED">CLOSED</option>
                        <option value="CANCELLED">CANCELLED</option>
                        <option value="ARCHIVED">ARCHIVED</option>
                    </select>
                    <label htmlFor="status">Status</label>
                </div>
                <div className="lf-input col s1">
                    <select id="dummyType" className="browser-default" value={values.dummyType} onChange={onChange}>
                        <option value="ALL">ALL</option>
                        <option value="NO BKG DUMMIES">NO BKG DUMMIES</option>
                        <option value="BKG DUMMIES ONLY">BKG DUMMIES ONLY</option>
                        <option value="SI DUMMIES ONLY">SI DUMMIES ONLY</option>
                    </select>
                    <label htmlFor="dummyType">Dummy</label>
                </div>
                <div className="lf-input col s2">
                    <input id="shipperName" className="browser-default" type="text" value={values.shipperName} onChange={onChange} />
                    <label htmlFor="shipperName">Shipper</label>
                </div>
                <div className="lf-input col s2">
                    <input id="carrierName" className="browser-default" type="text" value={values.carrierName} onChange={onChange} />
                    <label htmlFor="carrierName">Carrier</label>
                </div>
                <div className="lf-input col s2">
                    <input id="originName" className="browser-default" type="text" value={values.originName} onChange={onChange} />
                    <label htmlFor="originName">Origin</label>
                </div>
                <div className="lf-input col s2">
                    <input id="destinationName" className="browser-default" type="text" value={values.destinationName} onChange={onChange} />
                    <label htmlFor="destinationName">Destination</label>
                </div>
            </div>
            <BubbleRow
                bubbles={bubbles}
                popBubble={popBubble}
            />
        </>
    )
}

export default Search;