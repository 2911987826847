import React from 'react';
import Axios from 'axios';
import Form from '../../Components/NewComponents';
import { flags } from '../../constants';
import M from 'materialize-css';
import { connect } from 'react-redux';
import { globalToastActions } from '../../Redux/actions';
import moment from 'moment';
import InvoiceResultsDisplay from './InvoiceResultsDisplay';
import PageCountDisplay from '../Shipments/PageCountDisplay/PageCountDisplay';
import { auth, withRouter } from "../../helperFunctions";
import { CSVLink } from "react-csv";
import TableHeader from '../../Components/TableHeader/TableHeader';


class InvoiceOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.initState(),
            filters: {
                ...this.initState().filters,
                ...this.props.location?.state?.filters
            }
        }
    }

    initState = () => ({
        shipments: [],
        docType: 'shippermonthlyinvoice',
        totalAmountReceived: 0,
        showTotal: false,
        filters: {
            filterByInvoice: "true",
            shipperId: '',
            carrierId: '',
            placeOfRecieptId: '',
            placeOfDeliveryId: '',
            startDate: '',
            endDate: '',
            action: 'CREATED',
            employeeId: '',
            status: 'NOT PAID/PARTIALS',
            shipperName: '',
            carrierName: '',
            checkNumber: '',
            transactionNumber: '',
            bookingNumber: '',
            invoiceNumber: '',
            additionalInfo: '',
            lNumber: '',
            blNumber: '',
            minValue: '',
            maxValue: '',
            daysOverdue: '',
            currentSort: '',
            reverse: "",
            currentSort2: "",
            reverse2: "",
            currentSort3: "",
            reverse3: "",
            pageSize: 50,
            page: 0,
            count: [],
            resultsCount: null,
            showCount: false
        },
        invoiceCSVHeaders: [
            { label: "Status", key: "invoiceStatus" },
            { label: "L # / Invoice #", key: "invoiceOrLNumber" },
            { label: "B/L #", key: "billOfLadingNumber" },
            { label: "Shipper", key: "shipperName" },
            { label: "Carrier", key: "carrierName" },
            { label: "LoB Date", key: "formattedLadenOnBoardDate" },
            { label: "Invoice Date", key: "formattedInvoiceDateCreated" },
            { label: "Due Date", key: "formattedDateDue" },
            { label: "Past Due", key: "invoicePastDue" },
            { label: "Entered Date", key: "invoiceEnteredDate" },
            { label: "Total", key: "invoiceTotal" },
            { label: "Balance Due", key: "balanceDue" },
        ],
        commissionCSVHeaders: [
            { label: "Status", key: "commissionStatus" },
            { label: "L # / Invoice #", key: "invoiceOrLNumber" },
            { label: "B/L #", key: "billOfLadingNumber" },
            { label: "Shipper", key: "shipperName" },
            { label: "Carrier", key: "carrierName" },
            { label: "LoB Date", key: "formattedLadenOnBoardDate" },
            { label: "Due Date", key: "formattedDateDue" },
            { label: "Past Due", key: "commissionPastDue" },
            { label: "Entered Date", key: "commissionEnteredDate" },
            { label: "Total", key: "commissionTotal" },
            { label: "Balance Due", key: "comBalanceDue" }
        ],
        excelData: [],
    });

    initModal = () => M.Modal.init(document.querySelectorAll('.modal'));
    deleteModal = () => {
        const modals = document.querySelectorAll('.modal');
        modals.forEach(m => M.Modal.getInstance(m).destroy());
    }

    openPrintModal = () => {
        const filters = JSON.parse(JSON.stringify(this.state.filters));
        filters.startDate = '';
        filters.endDate = '';

        this.setState({ filters }, () => M.Modal.getInstance(document.querySelector('#print-modal')).open());
    }
    closePrintModal = () => M.Modal.getInstance(document.querySelector('#print-modal')).close();

    onChange = (name, value) => {
        if (name === 'docType') {
            if (value === 'salesrecap')
                return this.setState(p => ({
                    docType: 'salesrecap',
                    filters: {
                        ...p.filters,
                        carrierId: 9999,
                        shipperId: 9999,
                        placeOfRecieptId: 9999,
                        placeOfDeliveryId: 9999
                    }
                }))
        }


        this.setState({ [name]: value });
    }

    onChangePlus = (name, value) => this.setState({ [name]: value }, this.getShipments)

    filterChange = (name, value) => {
        const filters = JSON.parse(JSON.stringify(this.state.filters));
        filters[name] = value;
        this.setState({ filters });
    }

    filterChangePlus = (name, value) => {
        const filters = JSON.parse(JSON.stringify(this.state.filters));
        filters[name] = value;
        this.setState({ filters }, this.getShipments);
    }

    initListeners = () => {
        const invoiceFilters = document.querySelectorAll('.invoice-filter')
        invoiceFilters.forEach(elem => elem.addEventListener('keydown', this.filterListener));
        document.addEventListener("keydown", this.clearFilters);
    }

    removeListeners = () => {
        const invoiceFilters = document.querySelectorAll('.invoice-filter');
        invoiceFilters.forEach(elem => elem.removeEventListener('keydown', this.filterListener));
        document.removeEventListener("keydown", this.clearFilters);
    }

    filterListener = e => {
        if (e.keyCode === 13)
            this.getShipments();
    }

    clearFilters = (e) => {
        if (e.keyCode === 27) {
            this.setState(this.initState(), this.getShipments);
        }
    }

    initCollapsible = () => M.Collapsible.init(document.querySelector('.collapsible'));

    sort = e => {
        if (!e.target.getAttribute("data-sort")) {
            return;
        } else if (e.ctrlKey) {
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort2 : e.target.getAttribute('data-sort');
            const reverse2 = this.state.filters.currentSort2 === value ? (-1 * this.state.filters.reverse2) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort2: value, reverse2 } }, () => { this.getShipments(); })
        } else if (e.altKey) {
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort3 : e.target.getAttribute('data-sort');
            const reverse3 = this.state.filters.currentSort3 === value ? (-1 * this.state.filters.reverse3) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort3: value, reverse3 } }, () => { this.getShipments(); })
        } else {
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort : e.target.getAttribute('data-sort');
            const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => { this.getShipments(); })
        }
    }

    pageCheck = () => {
        if (this.props.location?.state?.filters) {
            if (this.props.location?.state?.filters?.page && this.props.location?.state?.filters?.page > 0) {
                this.setState({ filters: { ...this.state.filters, page: this.props.location?.state?.filters?.page } })
            }
        }
    }

    setPage = (page) => {
        return new Promise((resolve, reject) => {
            if (page === undefined) {
                page = 0
            }
            if (page < 0) {
                return
            }
            if (this.state.filters.count.length < 1) {
                page = 0
            } else if (this.state.filters.count.length > 0) {
                if (page === this.state.filters.count.length) {
                    return
                }
            }
            this.setState({ filters: { ...this.state.filters, page: page } })
            resolve(page);
            reject(new Error('Promise Rejected'))
        }).catch(e => {
            console.log(e)
        })
    }

    pageJump = (e) => {
        let choice = e.target.getAttribute("name");
        this.setPage((parseInt(choice) - 1)).then(() => {
            this.getShipments();
        })
    }

    singlePageTurn = async (e) => {
        let direction = e.target.getAttribute('name');
        let page = await this.state.filters.page;
        direction === 'back' ? page-- : page++;
        this.setPage(page).then(() => {
            this.getShipments();
        })
    }



    getShipmentsForExport = (type) => {

        return new Promise((resolve, reject) => {
            Axios.get('/api/v1/invoice/filtered', {
                params: {
                    ...auth.getAuthData(),
                    values: {
                        invoiceStatus: this.state.filters.status,
                        filterByInvoice: this.state.filters.filterByInvoice,
                        shipperName: this.state.filters.shipperName,
                        carrierName: this.state.filters.carrierName,
                        blNumber: this.state.filters.blNumber,
                        lNumber: this.state.filters.lNumber,
                        invoiceNumber: this.state.filters.invoiceNumber,
                        additionalInfo: this.state.filters.additionalInfo,
                        commissionMinValue: this.state.filters.filterByInvoice || this.state.filterByInvoice === "true" ? '' : this.state.filters.minValue,
                        commissionMaxValue: this.state.filters.filterByInvoice ? '' : this.state.filters.maxValue,
                        invoiceMinValue: this.state.filters.filterByInvoice ? this.state.filters.minValue : '',
                        invoiceMaxValue: this.state.filters.filterByInvoice ? this.state.filters.maxValue : '',
                        employeeId: this.state.filters.employeeId,
                        daysOverdue: this.state.filters.daysOverdue,
                        action: this.state.filters.action,
                        checkNumber: this.state.filters.checkNumber,
                        transactionNumber: this.state.filters.transactionNumber,
                        bookingNumber: this.state.filters.bookingNumber,
                        page: 0,
                        pageSize: 10000,
                        currentSort: this.state.filters.currentSort,
                        reverse: this.state.filters.reverse,
                        timezone: moment.tz.guess()
                    }
                }
            }).then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' });
                    return resolve();
                } else if (!result.data.result.length) {
                    this.props.dispatch({
                        type: globalToastActions.UPDATE_MSG,
                        payload: { type: "warning", msg: "No Results" }
                    });
                    return resolve();
                } else if (result.data === 'NOT ADMIN') {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'You must have administration rights to access this page' } });
                    this.props.navigate('/shipment', { state: {} });
                    return resolve();
                } else {
                    return resolve(this.setState({ excelData: result.data.result }))
                }
            });
        })

    }

    getShipments = (pageSize) => {
        Axios.get('/api/v1/invoice/filtered', {
            params: {
                ...auth.getAuthData(),
                values: {
                    invoiceStatus: this.state.filters.status,
                    filterByInvoice: this.state.filters.filterByInvoice,
                    shipperName: this.state.filters.shipperName,
                    carrierName: this.state.filters.carrierName,
                    blNumber: this.state.filters.blNumber,
                    lNumber: this.state.filters.lNumber,
                    invoiceNumber: this.state.filters.invoiceNumber,
                    additionalInfo: this.state.filters.additionalInfo,
                    commissionMinValue: this.state.filters.filterByInvoice || this.state.filterByInvoice === "true" ? '' : this.state.filters.minValue,
                    commissionMaxValue: this.state.filters.filterByInvoice ? '' : this.state.filters.maxValue,
                    invoiceMinValue: this.state.filters.filterByInvoice ? this.state.filters.minValue : '',
                    invoiceMaxValue: this.state.filters.filterByInvoice ? this.state.filters.maxValue : '',
                    employeeId: this.state.filters.employeeId,
                    daysOverdue: this.state.filters.daysOverdue,
                    action: this.state.filters.action,
                    checkNumber: this.state.filters.checkNumber,
                    transactionNumber: this.state.filters.transactionNumber,
                    bookingNumber: this.state.filters.bookingNumber,
                    page: this.state.filters.page,
                    pageSize: this.state.filters.pageSize,
                    currentSort: this.state.filters.currentSort,
                    reverse: this.state.filters.reverse,
                    currentSort2: this.state.filters.currentSort2,
                    reverse2: this.state.filters.reverse2,
                    currentSort3: this.state.filters.currentSort3,
                    reverse3: this.state.filters.reverse3,
                    timezone: moment.tz.guess()
                }
            }
        }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' });
                return;
            } else if (!result.data.result.length) {
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG,
                    payload: { type: "warning", msg: "No Results" }
                });
                this.setState({ shipments: result.data.result, filters: { ...this.state.filters } }, this.getShipmentsCount)
                return;
            } else if (result.data === 'NOT ADMIN') {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'You must have administration rights to access this page' } });
                this.props.navigate('/shipment', { state: {} });
            } else {
                this.setState({ shipments: result.data.result, totalAmountReceived: result.data.result[0].totalAmountReceived, filters: { ...this.state.filters } }, this.getShipmentsCount);
                if (this.state.filters.checkNumber || this.state.filters.transactionNumber) { this.setState({ showTotal: true }) } else { this.setState({ showTotal: false }) }
            }
        });
    }

    getShipmentsCount = async () => {
        let result = (await Axios.get('/api/v1/invoice/count', {
            params: {
                ...auth.getAuthData(),
                filters: {
                    invoiceStatus: this.state.filters.status,
                    filterByInvoice: this.state.filters.filterByInvoice,
                    shipperName: this.state.filters.shipperName,
                    carrierName: this.state.filters.carrierName,
                    blNumber: this.state.filters.blNumber,
                    lNumber: this.state.filters.lNumber,
                    invoiceNumber: this.state.filters.invoiceNumber,
                    additionalInfo: this.state.filters.additionalInfo,
                    commissionMinValue: this.state.filters.filterByInvoice || this.state.filterByInvoice === "true" ? '' : this.state.filters.minValue,
                    commissionMaxValue: this.state.filters.filterByInvoice ? '' : this.state.filters.maxValue,
                    invoiceMinValue: this.state.filters.filterByInvoice ? this.state.filters.minValue : '',
                    invoiceMaxValue: this.state.filters.filterByInvoice ? this.state.filters.maxValue : '',
                    employeeId: this.state.filters.employeeId,
                    daysOverdue: this.state.filters.daysOverdue,
                    action: this.state.filters.action,
                    checkNumber: this.state.filters.checkNumber,
                    transactionNumber: this.state.filters.transactionNumber,
                    bookingNumber: this.state.filters.bookingNumber,
                    page: this.state.filters.page,
                    pageSize: this.state.filters.pageSize,

                }
            }
        })).data
        if (result.result[0] === undefined) { return };
        let showCount = result.showCount;
        let amount = result.result[0].count;
        let list = Math.ceil(amount / this.state.filters.pageSize);
        let count = [];
        for (list; list > 0; list--) {
            count.push(list)
        };
        this.setState({ filters: { ...this.state.filters, count: count.reverse(), resultsCount: amount, showCount: showCount } })
    }

    // getAmountReceived = (results) => {
    //     let totalAmountReceived = 0;
    //     if (this.state.filters.filterByInvoice === 'true') {
    //         results.forEach(r => {
    //             totalAmountReceived = totalAmountReceived += r.invoiceAmountReceived;
    //         });
    //     } else {
    //         results.forEach(r => {
    //             totalAmountReceived = totalAmountReceived += r.commissionAmountReceived;
    //         });
    //     };
    //     this.setState({ totalAmountReceived, showTotal: true })
    // }

    getEmployeeList = () => {
        let employees = [{ first: "ANY", id: "ANY" }];
        let users = this.props.portalUser.filter(u => u.isArchived !== 1);
        let i = 0;
        while (i < users.length) {
            employees.push({ first: users[i].first, id: users[i].id });
            i++
        }
        this.setState({ employees })
    }

    getStatusList = () => ([{ label: 'NOT PAID/PARTIALS' }, { label: 'NOT PAID' }, { label: 'PAID' }, { label: 'PARTIAL' }, { label: 'NO COMMISSION DUE' }, { label: 'ARCHIVED' }, { label: "ALL" }]);

    getViewList = () => ([{ label: 'SHIPPER INVOICE', value: true }, { label: 'CARRIER COMMISSION', value: false }]);

    getActionList = () => ([{ label: 'CREATED' }, { label: 'MODIFIED' }, { label: 'PRINTED' }]);



    getYearList = () => {
        const arr = [];
        for (let i = 2019; i <= moment().get('year'); i++) {
            arr.push({ number: i })
        }

        return arr;
    }

    getMonthList = () => {
        const arr = [];
        for (let i = 1; i <= 12; i++)
            arr.push({
                name: moment(`2000-${i}-01`).format('MMMM'),
                id: i
            })
        return arr;
    }

    getShipperList = () => this.props.party.filter(p => p.role !== 'CONSIGNEE');

    getShipperOptions = () => {
        const arr = [];
        const names = [];
        if (this.props.party) {
            this.props.party.forEach(shipper => {
                if ((shipper.role === 'SHIPPER' || shipper.role === 'BOTH') && names.indexOf(shipper.name) === -1) {
                    if (shipper.id === 1) {
                        arr.unshift(shipper);
                        names.unshift(shipper.name);
                    }
                    else {
                        arr.push(shipper)
                        names.push(shipper.name);
                    }
                }
            });

            if (this.state.docType === 'salesrecap') {
                arr.unshift({ name: 'All', id: 9999 });
            } else {
                arr.unshift({ name: "SELECT A SHIPPER", id: 0 })
            }

        }

        return arr;
    }

    getCarrierOptions = () => {
        let carriers = this.props.carrier;
        if (this.state.docType === 'salesrecap') {
            const arr = JSON.parse(JSON.stringify(this.props.carrier));
            arr.unshift({ displayName: 'All', id: 9999 })
            return arr
        } else if (!carriers.filter(c => c.id === 0)[0]) {
            carriers.filter(c => c.id === parseInt(0));
            carriers.unshift({ id: 0, displayName: "SELECT A CARRIER" });
        }
        return carriers;
    }

    getLocationOptions = () => {
        let arr = [];
        arr = this.props.locations.filter(l => (l.typeId === 1 || l.typeId === 2));
        arr.unshift({ code: 'ALL', id: 9999 });
        return arr;
    }

    print = () => {
        Axios.post(`/api/v1/pages/${this.state.docType}`, {
            ...auth.getAuthData(),
            filters: this.state.filters
        }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }

            const win = window.open('', '', 'width=960 height=1080');
            win.document.write(result.data);
        })
    }

    goToCreationPage = e => {
        e.preventDefault();
        this.props.navigate('/invoice/create', {
            state: {
                origin: "invoiceOverview",
                filters: this.state.filters,
                tabIndex: this.state.filterByInvoice === "true" ? 1 : 0
            }
        })
    }

    componentDidMount = () => { this.getShipments(); this.initListeners(); this.initCollapsible(); this.getEmployeeList(); this.pageCheck(); this.initModal(); }

    componentWillUnmount = () => { this.removeListeners(); this.deleteModal(); }

    done = () => {
        return true
    }

    render = () => (
        <div>
            <div className="row" style={{ marginBottom: "10px" }}>
                <Form.Select col="s2" name="filterByInvoice" label="View" value={this.state.filters.filterByInvoice} onChange={this.filterChangePlus} list={this.getViewList()} filter={{ label: 'label', value: 'value' }} />
                <Form.Select col="s2" name="status" label="Pmt. Status" value={this.state.filters.status} onChange={this.filterChangePlus} list={this.getStatusList()} filter={{ label: 'label', value: 'label' }} />
                <Form.TextInput col="s2 invoice-filter" name="blNumber" label="B/L #" value={this.state.filters.blNumber} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput col="s2 invoice-filter" name="lNumber" label="L #" value={this.state.filters.lNumber} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput col="s2 invoice-filter" name="bookingNumber" label="Bkg #" value={this.state.filters.bookingNumber} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                {this.state.showTotal ? <div className="col s2">
                    <span>Total Received: $ {this.state.totalAmountReceived}</span>
                </div> : <div className="col s2"></div>}

            </div>
            <div className="row" style={{ marginBottom: "10px" }}>
                <Form.TextInput col="s2 invoice-filter" name="shipperName" label="Shipper" value={this.state.filters.shipperName} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput col="s2 invoice-filter" name="carrierName" label="Carrier Name" value={this.state.filters.carrierName} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput col="s2 invoice-filter" name="checkNumber" label="Check Number" value={this.state.filters.checkNumber} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput col="s2 invoice-filter" name="transactionNumber" label="Transaction Number" value={this.state.filters.transactionNumber} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.Button col="col s2" label="Print Report" type="outline" color="blue" icon="print" onClick={this.openPrintModal} />
                {this.state.filters.filterByInvoice === "true" ?
                    <div>
                        <Form.Button col="col s2" label="Export" type="outline" color="black" onClick={() => { this.getShipmentsForExport().then(() => document.getElementById('invoiceCsv').click()) }} icon="file_download" />
                        <CSVLink id={`invoiceCsv`} filename={`${'Invoice_list.csv'}`} headers={this.state.invoiceCSVHeaders} data={this.state.excelData} target="_blank">
                        </CSVLink>
                    </div>
                    :
                    <div>
                        <Form.Button col="col s2" label="Export" type="outline" color="black" onClick={() => { this.getShipmentsForExport().then(() => document.getElementById('commissionCsv').click()) }} icon="file_download" />
                        <CSVLink id="commissionCsv" filename={`${'Commission_list.csv'}`} headers={this.state.commissionCSVHeaders} data={this.state.excelData} target="_blank">
                        </CSVLink>
                    </div>
                }
            </div>
            <ul className="collapsible" style={{ padding: '12px' }}>
                <li>
                    <div className="collapsible-header" style={{ margin: '0px', padding: '0px', maxHeight: '12px', border: 'none' }}>
                        <p style={{ position: 'relative', bottom: '4px' }}>Advanced Search</p>
                    </div>
                    <div className="collapsible-body" style={{ margin: '0px', padding: '0px', border: 'none' }}>
                        <div className="row">
                            <Form.NumberInput float col="s2 invoice-filter" name="minValue" label="Min. Amount" value={this.state.filters.minValue} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                            <Form.NumberInput float col="s2 invoice-filter" name="maxValue" label="Max Amount" value={this.state.filters.maxValue} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                            <Form.Select col="s2" name="action" label="Action Type" value={this.state.filters.action} onChange={this.filterChangePlus} list={this.getActionList()} filter={{ label: 'label', value: 'label' }} />
                            <Form.Select col="s2" name="employeeId" label="Employee" value={this.state.filters.employeeId} onChange={this.filterChangePlus} list={this.state.employees} filter={{ label: 'first', value: 'id' }} />
                        </div>
                        <div className="row">
                            <Form.NumberInput col="s2 invoice-filter" name="daysOverdue" label="Days Overdue" value={this.state.filters.daysOverdue} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="s2 invoice-filter" name="invoiceNumber" label="Invoice Number" value={this.state.filters.invoiceNumber} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="s2 invoice-filter" name="additionalInfo" label="Additional Info" value={this.state.filters.additionalInfo} onChange={this.filterChange} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                    </div>
                </li>
            </ul>
            <table className="lf-portal-table">
                <TableHeader
                    headers={this.state.filters.filterByInvoice ?
                        [{ label: "Status", attribute: "invoiceStatus" },
                        { label: "L# / Invoice", attribute: "lNumber" },
                        { label: "B/L #", attribute: "billOfLadingNumber" },
                        { label: "Shipper", attribute: "shipperName" },
                        { label: "Carrier", attribute: "carrierName" },
                        { label: "LoB Date", attribute: "ladenOnBoardDate" },
                        { label: "Invoice Date", attribute: "invoiceDateCreated" },
                        { label: "Due Date", attribute: "dateDue" },
                        { label: "Days Past Due", attribute: "invoicePastDue" },
                        { label: "Pmt. Entered On", attribute: "invoiceEnteredDate" },
                        { label: "Total", attribute: "invoiceTotal" },
                        { label: "Balance Due", attribute: "invoiceBalanceDue" }]
                        :
                        [{ label: "Status", attribute: "commissionStatus" },
                        { label: "L# / Invoice", attribute: "lNumber" },
                        { label: "B/L #", attribute: "billOfLadingNumber" },
                        { label: "Shipper", attribute: "shipperName" },
                        { label: "Carrier", attribute: "carrierName" },
                        { label: "LoB Date", attribute: "ladenOnBoardDate" },
                        { label: "Invoice Date", attribute: "invoiceDateCreated" },
                        { label: "Due Date", attribute: "dateDue" },
                        { label: "Days Past Due", attribute: "commissionPastDue" },
                        { label: "Pmt. Entered On", attribute: "commissionEnteredDate" },
                        { label: "Total", attribute: "commissionTotal" },
                        { label: "Balance Due", attribute: "commissionBalanceDue" }]}
                    sort={this.sort}
                    filters={this.state.filters}
                />
                <tbody className="lf-portal-tbody">
                    <InvoiceResultsDisplay
                        shipments={this.state.shipments}
                        getShipments={this.getShipments}
                        filters={this.state.filters}
                    />
                </tbody>
            </table>
            <PageCountDisplay
                url={this.props.url.url}
                pageJump={this.pageJump}
                singlePageTurn={this.singlePageTurn}
                count={this.state.filters.count}
                page={this.state.filters.page}
                pageReset={this.props.location?.state?.origin ? true : false}
            />
            {this.state.filters.showCount ? <b className="resultsCount">Total Results: {this.state.filters.resultsCount}</b> : <b></b>}
            <div id="print-modal" className="modal">
                <div className="modal-content">
                    <h5>Print Report</h5>
                    <div className="row">
                        <Form.Radio name="docType" value="shippermonthlyinvoice" label="Shipper Monthly Invoice" onChange={this.onChange} checked={this.state.docType === 'shippermonthlyinvoice'} />
                        <Form.Radio name="docType" value="standaloneshippermonthlyinvoice" label="Standalone Shipper Monthly Invoice" onChange={this.onChange} checked={this.state.docType === 'standaloneshippermonthlyinvoice'} />
                        <Form.Radio name="docType" value="carriermonthlyinvoice" label="Carrier Monthly Invoice" onChange={this.onChange} />
                        {/* <Form.Radio name="docType" value="salesrecap" label="Sales Recap" onChange={this.onChange} /> */}
                    </div>

                    <div className="row">
                        {this.state.docType === "carriermonthlyinvoice" ?
                            <Form.Select col="s6"
                                name="carrierId" label="Carrier" onChange={this.filterChange}
                                value={this.state.filters.carrierId} list={this.getCarrierOptions()}
                                filter={{ label: 'displayName', value: 'id' }} />
                            :
                            <Form.Select col="s6"
                                name="shipperId" label="Shipper" onChange={this.filterChange}
                                value={this.state.filters.shipperId} list={this.getShipperOptions()}
                                filter={{ label: 'name', value: 'id' }} />
                        }
                    </div>
                    <div className="row">
                        <Form.DateInput col="s3" name="startDate" label="LOB Start"
                            onChange={this.filterChange} value={this.state.filters.startDate}
                            flags={[flags.ALLOW_EMPTY]} />
                        <Form.DateInput col="s3" name="endDate" label="LOB End"
                            onChange={this.filterChange} value={this.state.filters.endDate}
                            flags={[flags.ALLOW_EMPTY]} />
                        {this.state.docType === 'salesrecap' && <Form.Select col="s3"
                            name="placeOfRecieptId" label="PLR" onChange={this.filterChange}
                            value={this.state.filters.placeOfRecieptId} list={this.getLocationOptions()}
                            filter={{ label: 'code', value: 'id' }} />}
                        {this.state.docType === 'salesrecap' && <Form.Select col="s3"
                            name="placeOfDeliveryId" label="PLD" onChange={this.filterChange}
                            value={this.state.filters.placeOfDeliveryId} list={this.getLocationOptions()}
                            filter={{ label: 'code', value: 'id' }} />}
                    </div>

                    <div className="row">
                        <Form.Button col="s2" type="outline" label="Print" icon="print" color="blue" onClick={this.print} />
                    </div>
                </div>
            </div>
            {/* <div className="fixed-action-btn">
                <a href="/" className="btn-floating blue" onClick={this.goToCreationPage}>
                    <i className="material-icons">add</i>
                </a>
            </div> */}
        </div>
    )
}

const mapStateToProps = state => {
    const { party, carrier, urls, index, portalUser, location } = state;
    const url = urls[index]
    return { party, carrier, url, portalUser, locations: location };
}

export default connect(mapStateToProps)(withRouter(InvoiceOverview));