import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import moment from 'moment-timezone';
import { auth } from "../../helperFunctions";
import PageCountDisplay from "../Shipments/PageCountDisplay/PageCountDisplay";

class GeneralLog extends Component {
    state = {
        changes: [],
        filters: {
            currentSort: "",
            reverse: "",
            page: 0,
            pageSize: 50,
            count: []
        }
    }

    getLogs = () => {
        let timezone = moment.tz.guess();
        let filters = this.state.filters;
        axios.get('/api/v1/changelog/global', { params: { ...auth.getAuthData(), timezone, filters } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARCH" });
                return;
            }
            this.setState({ changes: result.data }, () => {
                this.getLogsPageCount();
            })
        })
    }

    getLogsPageCount = async () => {
        let result = (await axios.get('/api/v1/changelog/globalcount', { params: { ...auth.getAuthData(), timezone: moment.tz.guess(), filters: this.state.filters } })).data
        let amount = result.result[0].count;
        let list = Math.ceil(amount / this.state.filters.pageSize);
        let count = [];
        for (list; list > 0; list--) {
            count.push(list)
        }
        this.setState({ filters: { ...this.state.filters, count: count.reverse(), resultsCount: amount } })
    }

    pageCheck = () => {
        if (this.props.location?.state?.filters) {
            if (this.props.location?.state?.filters?.page && this.props.location?.state?.filters?.page > 0) {
                this.setState({ filters: { ...this.state.filters, page: this.props.location?.state?.filters?.page } })
            }
        }
    }

    setPage = (page) => {
        return new Promise((resolve, reject) => {
            if (page === undefined) {
                page = 0
            }
            if (page < 0) {
                return
            }
            if (this.state.filters.count.length < 1) {
                page = 0
            } else if (this.state.filters.count.length > 0) {
                if (page === this.state.filters.count.length) {
                    return
                }
            }
            this.setState({ filters: { ...this.state.filters, page: page } })
            resolve(page);
            reject(new Error('Promise Rejected'))
        }).catch(e => {
            console.log(e)
        })
    }

    singlePageTurn = async (e) => {
        let direction = e.target.getAttribute('name')
        let page = await this.state.filters.page;
        direction === 'back' ? page-- : page++;
        this.setPage(page).then(() => {
            this.getLogs();
        })
    }

    pageJump = (e) => {
        let choice = e.target.getAttribute("name");
        this.setPage((parseInt(choice) - 1)).then(() => {
            this.getLogs();
        })
    }

    componentDidMount = () => {
        this.getLogs();
    }

    render = () => {
        return (
            <div>
                <table className="lf-portal-table">
                    <thead className={`lf-portal-thead`}>
                        <tr>

                            <th data-sort="eventDatetime" onClick={this.sort}> Time&nbsp;
                                {this.state.filters.currentSort === "eventDatetime" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="user" onClick={this.sort}> User &nbsp;
                                {this.state.filters.currentSort === "user" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                                <th data-sort="partyName" onClick={this.sort}> Party &nbsp;
                                {this.state.filters.currentSort === "partyName" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="page" onClick={this.sort}>Page &nbsp;
                                {this.state.filters.currentSort === "page" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""} <span /></th>
                            <th data-sort="event" onClick={this.sort}>Event &nbsp;
                                {this.state.filters.currentSort === "event" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""} <span /></th>
                            <th data-sort="referenceNumber" onClick={this.sort}> Reference Number &nbsp;
                                {this.state.filters.currentSort === "referenceNumber" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                        </tr>
                    </thead>
                    <tbody className={`lf-portal-tbody`}>
                        {this.state.changes.map(c => (
                            <tr key={c.id}>
                                <td className="unselectable">{c.eventDatetime}</td>
                                <td className="unselectable">{c.user}</td>
                                <td className="unselectable">{c.partyName}</td>
                                <td className="unselectable">{c.page}</td>
                                <td className="unselectable">{c.event}</td>
                                <td className="unselectable">{c.referenceNumber}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <PageCountDisplay
                    pageJump={this.pageJump}
                    singlePageTurn={this.singlePageTurn}
                    count={this.state.filters.count}
                    page={this.state.filters.page}
                    pageReset={false}
                />
            </div>

        )
    }
}


const mapStateToProps = state => {
    const { location, user, urls, index, portalUser } = state;
    const url = urls[index];
    return { location, user, url, portalUser }
}

export default connect(mapStateToProps)(GeneralLog)