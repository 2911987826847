import { Routes, Route, Link } from 'react-router-dom';
import './content.css';
import Parties from '../../Pages/Parties/Party';
import Bookings from '../../Pages/Bookings/Bookings';
import Carrier from '../../Pages/Carriers/Carrier';
import Shipments from '../../Pages/Shipments/Shipments';
import Invoice from '../../Pages/Invoices/Invoice';
import Location from '../../Pages/Location/location'
import Settings from '../../Pages/Settings/Settings';
import DocumentationOverview from '../../Pages/Documentation/DocumentationOverview';
import ReportRouter from '../../Pages/Reports/ReportRouter';
import CustomerOverview from "../../Pages/Customer/CustomerOverview";
import Contracts from '../../Pages/Contracts/Contracts';
import GeneralLog from "../../Pages/GeneralLog/GeneralLog";
import FileUpload from '../FileUpload/FileUpload';
import UserOverview from "../../Pages/Parties/Users/UserOverview"
import RequestOverview from "../../Pages/Requests/RequestOverview";
import ServiceOverview from "../../Pages/ServiceRoute/ServiceRouteOverview";
import ForecastOverview from "../../Pages/Forecast/ForecastOverview";
import NewRouter from '../../newPages/newRouter';

const Content = props => (
    <div id="master-content" className="content">
        <Routes>
            <Route path="party/*" element={<Parties />} />
            <Route path="booking/*" element={<Bookings />} />
            <Route path="/carrier/*" element={<Carrier />} />
            <Route path="shipment/*" element={props.user.isEmployee ? <Shipments /> : <CustomerOverview />} />
            <Route path="invoice/*" element={<Invoice />} />
            <Route path="location/*" element={<Location />} />
            <Route path="settings/*" element={<Settings />} />
            <Route path="documentation/*" element={<DocumentationOverview />} />
            <Route path="report/*" element={<ReportRouter />} />
            <Route path="customer/*" element={<CustomerOverview />} />
            <Route path="contract/*" element={<Contracts />} />
            <Route path='log/*' element={<GeneralLog />} />
            <Route path='files/*' element={<FileUpload />} />
            <Route path='useroverview/*' element={<UserOverview />} />
            <Route path="request/*" element={<RequestOverview />} />
            <Route path="service/*" element={<ServiceOverview />} />
            <Route path="forecast/*" element={<ForecastOverview />} />
            <Route path="new/*" element={<NewRouter />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </div>
)

const NotFound = () => (
    < div >
        <h2> Error 404. Sorry, there's nothing here. </h2>
        <Link to="/shipment/overview" replace={true}>Return Home</Link>
    </div >
)

export default Content;