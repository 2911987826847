import React from 'react';
import { connect } from 'react-redux';
import Form from '../../Components/NewComponents';
import { globalToastActions } from '../../Redux/actions';
import axios from 'axios';
import { flags } from '../../constants';
import moment from 'moment';
import { auth } from "../../helperFunctions";
class OverdueCommissionReport extends React.Component {
    constructor(props) { super(props); this.state = this.initState() }

    initState = () => ({
        resultList: null,
        carrierList: null,
        shipperId: '',
        carrierId: '',
        lobStartDate: '',
        lobEndDate: '',
        daysOverdue: '',
        status: 'ALL'
    })

    onChange = (name, value) => this.setState({ [name]: value })

    getStatuses = () => ([
        { value: 'ALL' },
        { value: 'NOT PAID' },
        { value: 'PAID' },
        { value: 'PARTIAL' },
        { value: 'NO COMMISSION DUE' }
    ])

    generateReport = () => {
        const { shipperId, carrierId, lobStartDate, lobEndDate, daysOverdue, status } = this.state;
        const values = {
            shipperId,
            carrierId,
            lobStartDate: lobStartDate ? moment(lobStartDate, 'MM/DD/YY').format('YYYY-MM-DD') : '',
            lobEndDate: lobEndDate ? moment(lobEndDate, 'MM/DD/YY').format('YYYY-MM-DD') : '',
            daysOverdue,
            status
        };
        axios.get('/api/v1/report/overduecarriercommission', { params: { ...values, ...auth.getAuthData() } })
            .then(result => {
                if (result.data.length)
                    this.setState({ resultList: result.data })
                else
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Results', type: 'info' } })
            })
            .catch(err => this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'An Error Occurred', type: 'error' } }))
    }

    render = () => (
        <div>
            <div>&nbsp;</div>
            <div className="row">
                <Form.Select col="col s3" label="Shipper" onChange={this.onChange} placeholder="Select a Shipper" name="shipperId" value={this.state.shipperId} list={this.props.shipper} filter={{ label: 'name', value: 'id' }} flags={[flags.ALLOW_EMPTY]} />
                <Form.Select col="col s3" label="Carrier" onChange={this.onChange} placeholder="Select a Carrier" name="carrierId" value={this.state.carrierId} list={this.props.carrier} filter={{ label: 'code', value: 'id' }} flags={[flags.ALLOW_EMPTY]} />
                <Form.DateInput col="col s3" label="LOB Start Date" onChange={this.onChange} name="lobStartDate" value={this.state.lobStartDate} flags={[flags.ALLOW_EMPTY]} />
                <Form.DateInput col="col s3" label="LOB End Date" onChange={this.onChange} name="lobEndDate" value={this.state.lobEndDate} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
                <Form.NumberInput col="col s3" label="Days Overdue" onChange={this.onChange} name="daysOverdue" value={this.state.daysOverdue} flags={[flags.ALLOW_EMPTY]} />
                <Form.Select col="col s3" label="Pmt Status" onChange={this.onChange} name="status" value={this.state.status} list={this.getStatuses()} filter={{ label: 'value', value: 'value' }} flags={[flags.ALLOW_EMPTY]} />
                <Form.Button col="col s2" onClick={this.generateReport} label="Generate Report" type="outline" color="blue" />
            </div>
            <div>
                <table className="responsive">
                    <thead>
                        <tr>
                            <th>Shipper</th>
                            <th>Carrier</th>
                            <th>LOB Date</th>
                            <th>BOL</th>
                            <th>L #</th>
                            <th>Rate</th>
                            <th>Comm %</th>
                            <th>Status</th>
                            <th>Past Due</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.resultList && this.state.resultList.map(row => (
                            <tr key={`result-table-${row.id}`}>
                                <td>{row.shipperName}</td>
                                <td>{row.carrierCode}</td>
                                <td>{moment(row.ladenOnBoardDate, 'YYYY-MM-DD').format('MM/DD/YY')}</td>
                                <td>{row.billOfLadingNumber}</td>
                                <td>{row.lNumber}</td>
                                <td>{row.rate}</td>
                                <td>{row.percentage}</td>
                                <td>{row.status}</td>
                                <td>{row.daysOverdue}</td>
                                <td>{row.totalCommission}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    let { shipper, carrier } = state;
    const ids = [];
    const arr = [];

    shipper.forEach(s => {
        if (ids.indexOf(s.id) === -1) {
            ids.push(s.id);
            arr.push(s);
        }
    })

    return { shipper: arr, carrier }
}

export default connect(mapStateToProps)(OverdueCommissionReport);