import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Form from "../../../Components/NewComponents";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { flags } from "../../../constants"
import { auth } from "../../../helperFunctions";

class ModalContractDetails extends Component {
    state = {
        chargeType: 1,
        chargeName: "",
        chargeAmount: "",
        chargeLocation: "ALL",
        countries: [],
        editRowIndex: undefined,
        editRowTable: undefined,
        commodities: []
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    makeActive = (index, table) => {
        this.setState({ editRowIndex: index, editRowTable: table }, () => {
            let targetRow = document.querySelectorAll(`#${table}>tr`)[index].querySelectorAll("td:not(.material-icons)>div>input, td:not(.material-icons)>div>select");
            targetRow.forEach(r => {
                r.style.color = "blue"
            });
            targetRow[0].focus();
        })
    }

    makeInactive = (index, table) => {
        this.setState({ editRowIndex: null, editRowTable: null }, () => {
            let targetRow = document.querySelectorAll(`#${table}>tr`)[index].querySelectorAll("td:not(.material-icons)");
            targetRow.forEach(r => {
                r.style.color = "black"
            });
            if (document.querySelectorAll(`#${table}>tr`)[index + 1]) {
                document.querySelectorAll(`#${table}>tr`)[index + 1].children[3].children[0].focus()
            }
        })
    }

    getCommodities = () => {
        axios.get("/api/v1/contract/commodity", { params: { ...auth.getAuthData() } }).then(result => {
            this.setState({ commodities: result.data })
        });
    }

    componentDidMount = () => {
        this.getCommodities();
    }

    ExampleCustomInput = ({ value, onClick }) => {
        return <button className="example-custom-input" onClick={onClick}>
            {value}
        </button>
    }

    render = () => (
        <div>

            <div className="row">
                <div className="col s4">
                    <div className="row">
                        <Form.Select col="col s6" label="Shipper" name="shipperId" placeholder="Select A Shipper" value={this.props.contract.contractDetails.shipperId} onChange={this.props.onChange} list={this.props.shipper} filter={{ label: "name", value: "id" }} onFocus={this.props.markAsReviewed} />
                        <Form.Select col="col s6" label="Carrier" name="carrierId" placeholder="Select A Carrier" value={this.props.contract.contractDetails.carrierId} onChange={this.props.onChange} list={this.props.carrier} filter={{ label: "displayName", value: "id" }} onFocus={this.props.markAsReviewed} />                </div>
                    <div className="row">
                        <Form.Radio col="col s12" name="manageType" value="lfManaged" label="LF Managed" onChange={this.props.onChange} checked={this.props.contract.contractDetails.manageType === "lfManaged"} />
                        <p className="col s12 lfManagedDetails">Lindsey Forwarders manages this contract. We help negotiate rates, routes, and freetime. We interact directly with the sales reps in order to provide a quality service to the shippers.</p>
                        {this.props.contract.contractDetails.manageType === 'lfManaged' ?
                            <div className="row" style={{ paddingTop: "15px" }}>
                                <Form.CheckBox col="col s4" name="isSigned" value={this.props.contract.contractDetails.manageType === "shipperManaged" ? false : this.props.contract.contractDetails.isSigned} label="LF Signed" onChange={this.props.onChange} />
                                <div className="col s8">
                                    <p >In addition, Lindsey Forwarders signs all contracts on behalf of the shipper.</p>
                                </div>
                            </div> :
                            null
                        }
                    </div>
                    <div className="row">
                        <Form.Radio col="col s12" name="manageType" value="shipperManaged" label="Shipper Managed" onChange={this.props.onChange} />
                        <p className="col s12">The shipper manages their own contract. All rates and routes are their responsibility. We may be required to interface with their sales reps, but in general it is not within the scope of our work.</p>
                    </div>

                </div>
                <div className="col s8">
                    <div className="row">
                        <Form.TextInput col="col s4" label="Contract Number" name="contractNumber" value={this.props.contract.contractDetails.contractNumber} onChange={this.props.onChange} maxLength="40" onFocus={this.props.markAsReviewed} />
                        <Form.Select col="col s4" label="Commodity" name="commodityId" value={this.props.contract.contractDetails.commodityId} onChange={this.props.onChange} onFocus={this.props.markAsReviewed} list={this.state.commodities} filter={{ label: "name", value: "id" }} />
                        <Form.Select col="col s4" label="Status" name="status" value={this.props.contract.contractDetails.status} onChange={this.props.onChange} list={[{ status: "ACTIVE" }, { status: "PENDING REVIEW" }, { status: "UPCOMING" }, { status: "PENDING UPCOMING" }]} filter={{ label: "status", value: "status" }} />
                    </div>
                    <div className="row">
                        <Form.TextInput col="col s2" label="MQC" name="minimumQuantityCommitment" value={this.props.contract.contractDetails.minimumQuantityCommitment} onChange={this.props.onChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select col="col s2" label="Container Type" name="containerTypeId" value={this.props.contract.contractDetails.containerTypeId} onChange={this.props.onChange} list={this.props.containerType} filter={{ label: "code", value: "id" }} onFocus={this.props.markAsReviewed} />
                        <Form.TextInput col="col s2" label="Commission" value={this.props.contract.contractDetails.commissionAmount} onChange={this.props.onChange} name="commissionAmount" onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                        <Form.CheckBox col="col s2" label="Use Percentage" value={this.props.contract.contractDetails.commissionUsePercentage} onChange={this.props.onChange} name="commissionUsePercentage" onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                        <Form.DateInput col="col s2" label="Effective Date" name="effectiveDate" value={this.props.contract.contractDetails.effectiveDate} onChange={this.props.onChange} onFocus={this.props.markAsReviewed} />
                        <Form.DateInput col="col s2" label="Expiration Date" name="expirationDate" value={this.props.contract.contractDetails.expirationDate} onChange={this.props.onChange} onFocus={this.props.markAsReviewed} />
                    </div>
                    <div className="row" style={{ marginTop: "20px", borderTop: "1px solid lightgrey" }}>
                        <h5>Add Charges</h5>
                        <Form.Select col="col s2" label="Type" name="chargeType" value={this.state.chargeType} list={[{ label: "Per Cont.", value: 1 }, { label: "Addl. Charge", value: 0 }]} filter={{ label: "label", value: "value" }} onChange={this.onChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select col="col s2" label="Location" name="chargeLocation" value={this.state.chargeCountryId} list={this.props.allLocations} filter={{ label: "name", value: "name" }} onChange={this.onChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                        <Form.AutoComplete col={`col s4`} label="Charge Name" name="chargeName" value={this.state.chargeName} onChange={this.onChange} onFocus={this.props.markAsReviewed} data={this.props.carrierCharges ? this.props.carrierCharges : this.props.contract.carrierChargeList ? this.props.contract.carrierChargeList : []} filterValue="name" flags={[flags.ALLOW_EMPTY]} />
                        <Form.NumberInput col="col s2" label="Amount" name="chargeAmount" value={this.state.chargeAmount} onChange={this.onChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Button col="col s2" label="Add" icon="add" type="outline" color="blue" onClick={() => {
                            this.props.addCharge({
                                perContainerCharge: parseInt(this.state.chargeType),
                                locationName: this.state.chargeLocation,
                                name: this.state.chargeName,
                                amount: this.state.chargeAmount
                            })
                        }} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                    <div className="row">
                        <h6>Per Container Charges</h6>
                        <table>
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>Name</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id='perContainerCharges'>
                                {this.props.contract.charges.perContainerCharges.map((f, index) => (
                                    <Fragment key={index}>
                                        {this.state.editRowIndex === index && this.state.editRowTable === "perContainerCharges" ?
                                            <tr key={index}>
                                                <td>
                                                    <Form.Select label="Location" name={`countryId:${index}`} value={f.countryId} onChange={(name, value) => this.props.chargesEditOnChange(name, value, "perContainerCharges")} placeholder="ALL" list={this.props.country} filter={{ label: "code", value: "id" }} onFocus={this.props.markAsReviewed} />
                                                </td>
                                                <td>
                                                    <Form.TextInput label="Name" name={`name:${index}`} value={f.name} onChange={(name, value) => this.props.chargesEditOnChange(name, value, "perContainerCharges")} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                                </td>
                                                <td>
                                                    <Form.NumberInput label="Amount" name={`amount:${index}`} value={f.amount} onChange={(name, value) => this.props.chargesEditOnChange(name, value, "perContainerCharges")} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                                </td>
                                                <td className="material-icons modalSaveIcon">
                                                    <i tabIndex="0" className="material-icons" onClick={() => this.makeInactive(index, "perContainerCharges")}>save</i>
                                                </td>
                                            </tr>
                                            :
                                            <tr key={index}>
                                                <td>{!f.locationName ? "ALL" : f.locationName}</td>
                                                <td>{f.name}</td>
                                                <td>{f.amount}</td>
                                                <td className="material-icons editAndDeleteIcons">
                                                    <i tabIndex="0" className="material-icons" onClick={() => this.makeActive(index, "perContainerCharges")}>edit</i>
                                                    <i tabIndex="0" className="material-icons" onClick={() => this.props.removeCharge(f, index)}>cancel</i>
                                                </td>
                                            </tr>
                                        }
                                    </Fragment>
                                )
                                )}
                            </tbody>
                        </table>
                        <h6>Additional Charges</h6>
                        <table>
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>Name</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="additionalCharges">
                                {this.props.contract.charges.additionalCharges.map((f, index) => (
                                    <Fragment key={index}>
                                        {this.state.editRowIndex === index && this.state.editRowTable === "additionalCharges" ?
                                            <tr key={index}>
                                                <td>
                                                    <Form.Select label="Country" name={`countryId:${index}`} value={f.countryId} onChange={(name, value) => this.props.chargesEditOnChange(name, value, "additionalCharges")} placeholder="ALL" list={this.props.country} filter={{ label: "code", value: "id" }} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                                </td>
                                                <td>
                                                    <Form.TextInput label="Name" name={`name:${index}`} value={f.name} onChange={(name, value) => this.props.chargesEditOnChange(name, value, "additionalCharges")} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                                </td>
                                                <td>
                                                    <Form.NumberInput label="Amount" name={`amount:${index}`} value={f.amount} onChange={(name, value) => this.props.chargesEditOnChange(name, value, "additionalCharges")} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                                </td>
                                                <td className="material-icons modalSaveIcon">
                                                    <i tabIndex="0" className="material-icons" onClick={() => this.makeInactive(index, 'additionalCharges')}>save</i>
                                                </td>
                                            </tr>
                                            :
                                            <tr key={f.id} >
                                                <td>{!f.locationName ? "ALL" : f.locationName}</td>
                                                <td>{f.name}</td>
                                                <td>{f.amount}</td>
                                                <td className="material-icons editAndDeleteIcons">
                                                    <i tabIndex="0" className="material-icons" onClick={() => this.makeActive(index, "additionalCharges")}>edit</i>
                                                    <i tabIndex="0" className="material-icons" onClick={() => this.props.removeCharge(f, index)}>cancel</i>
                                                </td>
                                            </tr>
                                        }
                                    </Fragment>
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <Form.TextArea className="col s12" name="note" height="60px" value={this.props.contract.contractDetails.note} onChange={this.props.onChange} label="Note" flags={[flags.ALLOW_EMPTY]} />
            </div>
        </div>
    )

}

const mapStateToProps = state => {
    const { urls, index, carrier, shipper, containerType, country } = state;
    const url = urls[index];
    return { url, carrier, shipper, containerType, country }
}


export default connect(mapStateToProps)(ModalContractDetails);