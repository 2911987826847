import { Routes, Route } from 'react-router-dom';
import ShipmentOverview from './ShipmentOverview';
import CreateBooking from './CreateBooking/CreateBooking';
import ShipmentInstructions from './ShipmentInstructions';
import Split from './CreateBooking/Split';
import BookingNumberEditor from './CreateBooking/BookingNumberEditor';

const Shipments = props => (
    <Routes>
        <Route path="/overview" element={<ShipmentOverview />} />
        <Route path="create" element={<CreateBooking />} />
        <Route path="create/:bookingNumber" element={<CreateBooking />} />
        <Route path="instructions" element={<ShipmentInstructions />} />
        <Route path="split" element={<Split />} />
        <Route path="bookingnumber/edit" element={<BookingNumberEditor />} />
    </Routes>
)


export default Shipments
