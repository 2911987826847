import React from 'react';
import Form from '../../../../Components/NewComponents';
import { flags } from '../../../../constants';

const COO = (props) => {

    return (
        <div>
            <div className="row" style={{ paddingRight: "20px", paddingLeft: "20px", marginBottom: "0px", marginTop: "-20px" }}>
                {Array.isArray(props.certificateOfOrigin) ? props.certificateOfOrigin.map((row, index) => (
                    <div key={`COO^${index}`} className="row noMargin">
                        <div className="col s4">
                            <Form.TextArea tabIndex="2" name={`description:${index}`} label="Description of Commodities" value={props.certificateOfOrigin[index].description} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                        <Form.TextInput tabIndex="2" col="s4" name={`groupedWeight:${index}`} label="Quantities & Units" value={props.certificateOfOrigin[index].groupedWeight === null ? "" : props.certificateOfOrigin[index].groupedWeight} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput tabIndex="2" col="s4" name={`hsCode:${index}`} label="HS No." value={props.certificateOfOrigin[index].hsCode === null ? "" : props.certificateOfOrigin[index].hsCode} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                )) :
                    <div key={`COO^`} className="row noMargin">
                        <div className="col s4">
                            <Form.TextArea tabIndex="2" disabled name={`description`} label="Description of Commodities" value={props.certificateOfOrigin.description} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                        <Form.TextInput tabIndex="2" disabled col="s4" name={`groupedWeight`} label="Quantities & Units" value={props.certificateOfOrigin.groupedWeight === null ? "" : props.certificateOfOrigin.groupedWeight} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput tabIndex="2" disabled col="s4" name={`hsCode`} label="HS No." value={props.certificateOfOrigin.hsCode === null ? "" : props.certificateOfOrigin.hsCode} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                    </div>

                }
                {Array.isArray(props.certificateOfOrigin) ? (props.certificateOfOrigin.slice(0, 1)).map((row, index) => (
                    <div key={index}>
                        <Form.TextInput tabIndex="2" col="s2 cooMarginTop " name="countryOfOrigin:0" label="Country of Origin"
                            value={props.certificateOfOrigin[0].countryOfOrigin} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput tabIndex="2" col="s2 cooMarginTop" name="preferenceCode:0" label="Preference Criterion"
                            value={props.certificateOfOrigin[0].preferenceCode} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                )) :
                    <div className="row">
                        <Form.TextInput tabIndex="2" col="s2 cooMarginTop" disabled name="countryOfOrigin:0" label="Country of Origin"
                            value={props.certificateOfOrigin.countryOfOrigin} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput tabIndex="2" col="s2 cooMarginTop" disabled name="preferenceCode:0" label="Preference Criterion"
                            value={props.certificateOfOrigin.preferenceCode} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                }
            </div>
        </div>
    )
}

export default COO;