import AutoComplete from './AutoComplete';
import AutoCompleteSmall from "./AutoCompleteSmall";
import Button from './Button';
import CheckBox from './CheckBox';
import CheckBoxSmall from './CheckBoxSmall';
import DateInput from './Date';
import NumberInput from './NumberInput';
import NumberInputSmall from "./NumberInputSmall"
import Select from './Select';
import SelectSmall from './SelectSmall';
import TextArea from './TextArea';
import TextInput from './TextInput';
import TextInputSmall from './TextInputSmall';
import TimeInput from './Time';
import Phone from './Phone';
import Email from './Email';
import DateRange from './DateRange';
import Section from './Section';
import Password from './Password';
import Radio from './Radio';
import MaskInput from './MaskInput';
import Switch from './Switch';
import EmailAutoComplete from './EmailAutoComplete';
import DatePicker from './DatePicker';
import RawSelectSmall from "./RawSelectSmall";
import RawTextInputSmall from "./RawTextInputSmall";
import RawAutoCompleteSmall from './RawAutoCompleteSmall';
import RawNumberInputSmall from './RawNumberInputSmall';

const Form = {
    AutoComplete,
    AutoCompleteSmall,
    RawAutoCompleteSmall,
    Button,
    CheckBox,
    CheckBoxSmall,
    DateInput,
    NumberInput,
    NumberInputSmall,
    RawNumberInputSmall,
    Select,
    SelectSmall,
    RawSelectSmall,
    TextArea,
    TextInput,
    TextInputSmall,
    RawTextInputSmall,
    TimeInput,
    Phone,
    Email,
    DateRange,
    Section,
    Password,
    Radio,
    MaskInput,
    Switch,
    EmailAutoComplete,
    DatePicker
}

export default Form;