import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Form from '../../Components/NewComponents';
import styles from './shipments.module.css';
import { flags } from '../../constants';
import { globalToastActions } from '../../Redux/actions';
import { CSVLink } from "react-csv";
import { auth } from "../../helperFunctions";
class Containers extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({
        containers: [],
        csvContainers: [],
        changeCheckContainers: [],
        msg: { color: '', text: '' },
        addContainerCount: '',
        selectedContainers: [],
        byContainerTotal: 0,
        customTotal: 0,
        csvHeaders: [
            { label: "Container Number", key: "containerNumber" },
            { label: "Seal #", key: "sealNumber" },
            { label: "Weight", key: "weight" },
            { label: "U/M", key: "unitOfMeasurement" },
            { label: "Count", key: "count" },
            { label: "Unit", key: "unit" },
            { label: "CBM", key: "cubicMeters" },
            { label: "HS Code", key: "hsCode" },
            { label: "Description", key: "description" },
            { label: "Price", key: "price" }
        ]
    });

    getContainers = id => axios.get(`/api/v1/container/booking/${id}`, { params: auth.getAuthData() }).then(result => {

        return new Promise((resolve, reject) => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }

            let changeCheckContainers = this.state.changeCheckContainers.length ? this.state.changeCheckContainers : result.data;

            let csvContainers = this.getContainersAsCSV(result.data);

            this.setState({ containers: result.data, changeCheckContainers, csvContainers }, async () => {
                await this.calculateWeights();
                resolve(this.sendUpUserWeights());
            })
        })


    })

    generateContainers = () => axios.post('/api/v1/container/generateblanks', { ...this.getPostData(), ...auth.getAuthData() }).then(result => {
        if (result.data === 'NOT AUTHENTICATED') {
            localStorage.clear();
            this.props.dispatch({ type: 'SCORCHED_EARTH' })
            return;
        }

        this.setState({ containers: result.data })
    })
    getPostData = () => ({
        ...auth.getAuthData(),
        bookingId: this.props.booking.id,
        containerCount: this.props.booking.containerCount,
        containerTypeId: this.props.booking.containerTypeId,
    });
    onChange = (name, value) => {
        const arr = name.split(':');
        const containers = JSON.parse(JSON.stringify(this.state.containers));
        containers[arr[1]][arr[0]] = value;
        this.setState({ containers }, this.calculateWeights);
    }
    weightOnChange = (name, value) => {
        let containers = JSON.parse(JSON.stringify(this.state.containers))
        containers.forEach(c => {
            c.unitOfMeasurement = value;
        })
        this.setState({ containers }, this.calculateWeights);
    }
    changeTotals = (name, value) => this.setState({ [name]: value });
    getUnitsOfMeasurement = () => ([{ label: 'MT' }, { label: 'KG' }, { label: 'LBS' }]);

    componentDidUpdate = async (prevProps) => {
        if (prevProps.booking.id !== this.props.booking.id) {
            await this.getContainers(this.props.booking.id);
        }
    }

    componentDidMount = () => {
        this.getContainers(this.props.booking.id)
    }

    checkForSameWeights = () => {
        return new Promise((resolve, reject) => {

            let difWeights = [...new Set(this.state.containers.map(c => c.unitOfMeasurement))];

            if (difWeights.length > 1) {
                resolve(true)
            } else {
                resolve(false)
            }
        })
    }

    saveContainers = async () => {

        let difWeights = await this.checkForSameWeights();
        if (difWeights) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG, payload: {
                    msg: "Weights Must Be The Same", type: "warning"
                }
            })
            return;
        }

        if (!this.state.customTotal) {
            this.calculateWeights();
        } else {
            this.sendUpUserWeights();
        }

        let count = 0;
        this.state.containers.forEach(c => c.containerNumber.length ? count++ : count + 0)

        this.props.setState("numOfContainers", count)
        await this.props.saveAndGenerate(this.props.page, this.state.containers, this.state.changeCheckContainers, this.props.booking.id);
        await this.getContainers(this.props.booking.id);
        this.setState({ changeCheckContainers: this.state.containers })

    }

    calculateWeights = async () => {

        let total = 0;
        let customTotal = 0;
        let totalThenConvertMT = 0;
        let totalThenConvertLBS = 0;
        let totalThenConvertKG = 0;

        let cont = this.state.containers

        cont.forEach(row => {

            if (row.weight) {

                if (row.unitOfMeasurement === "MT") {
                    totalThenConvertMT += (parseFloat(row.weight));
                    total += parseInt((parseFloat(row.weight) * 1000).toFixed(0));

                } else if (row.unitOfMeasurement === "LBS") {
                    totalThenConvertLBS += (parseFloat(row.weight));
                    total += parseInt((parseFloat(row.weight) * 0.453592).toFixed(0));

                } else if (row.unitOfMeasurement === "KG") {
                    totalThenConvertKG += (parseFloat(row.weight));
                    total += parseInt((parseFloat(row.weight)).toFixed(0));

                }
            }
        });

        customTotal = (totalThenConvertKG + (totalThenConvertLBS * 0.453592) + (totalThenConvertMT * 1000)).toFixed(0)

        this.setState({ byContainerTotal: total, customTotal });
        await this.props.setState('byContainerTotal', total);

    }

    sendUpUserWeights = () => {
        let userAdjustedTotal = this.state.customTotal;
        this.setState({ customTotal: userAdjustedTotal });
        this.props.setState('customTotal', userAdjustedTotal);
    }

    getBales = () => { let sum = 0; this.state.containers.forEach(c => sum += c.count); return sum; }
    cascadeInputs = () => {
        const first = this.state.containers[0];
        if (!first.unit || !first.hsCode || !first.description)
            return;

        let bool = true;
        for (let i = 1; i < this.state.containers.length; i++) {
            const c = this.state.containers[i];
            if (c.count || c.unit || c.cubicMeters || c.hsCode || c.description || c.price)
                bool = false;
        }
        if (bool) {
            const containers = JSON.parse(JSON.stringify(this.state.containers));
            for (let i = 1; i < this.state.containers.length; i++) {
                let source = this.state.containers[0];
                let c = this.state.containers[i];
                c.unitOfMeasurement = source.unitOfMeasurement;
                c.count = source.count;
                c.unit = source.unit;
                c.cubicMeters = source.cubicMeters;
                c.hsCode = source.hsCode;
                c.description = source.description;
                c.price = source.price;
                containers[i] = c;
            }
            this.setState({ containers })
        }
    }

    deleteContainer = () => {
        const shouldDelete = window.confirm('Delete Containers?');
        if (shouldDelete) {
            for (let i = 0; i < this.state.selectedContainers.length; i++) {
                document.getElementById(`container-${this.state.selectedContainers[i]}`).classList.remove(styles.containerSelected)
            }
            axios.post(`/api/v1/container/delete`, { containers: this.state.selectedContainers, ...auth.getAuthData() })
                .then(result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' })
                        return;
                    }
                    this.setState({ selectedContainers: [] })
                    this.getContainers(this.props.booking.id)
                });
        }
    }

    addContainers = () => {
        if (!this.state.addContainerCount)
            return;

        const containerCount = this.state.addContainerCount;
        if (containerCount > (99 - this.state.containers.length))
            return;

        axios.post('/api/v1/container/generateblanks', { ...this.getPostData(), ...auth.getAuthData(), containerCount }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }

            this.setState({ containers: result.data })
        })
    }

    copyContainerNumbers = (e) => {
        e.preventDefault();
        if (this.state.selectedContainers.length) {
            let containerNumbers = [];
            this.state.containers.forEach(container => {
                if (this.state.selectedContainers.includes(container.id)) {
                    containerNumbers.push(container.containerNumber);
                }
            });
            navigator.clipboard.writeText(containerNumbers.join('\n'));
        } else {
            let containerNumbers = this.state.containers.map(c => c.containerNumber).join('\n');
            navigator.clipboard.writeText(containerNumbers);
        }
        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Copied To Clipboard" } });
    }

    toggleSelectedContainer = (e, id) => {
        e.preventDefault();
        const container = document.getElementById(`container-${id}`);
        let selectedContainers = this.state.selectedContainers;
        const isSelected = container.classList.contains(styles.containerSelected);

        if (isSelected) {
            container.classList.remove(styles.containerSelected);
            selectedContainers.splice(selectedContainers.indexOf(id), 1);
            this.setState({ selectedContainers });
        }
        else {
            container.classList.add(styles.containerSelected);
            selectedContainers.push(id);
            this.setState({ selectedContainers })
        }
    }

    getContainersAsCSV = (cont) => {
        if (cont === undefined) {
            return;
        }
        let containers = JSON.parse(JSON.stringify(cont));
        containers.forEach(c => {
            c.sealNumber = `=""${c.sealNumber}""`;
            c.unitOfMeasurement = 'KG';
            c.weight = c.weight * 1000;
        });
        return containers;
    }

    render = () => {
        if (!this.state.containers.length)
            return (
                <Form.Button round type="outline" tabIndex="2" label="Input Containers" color="blue" onClick={this.generateContainers} />
            )

        return (
            <div className="containerRow">
                <div className="row containerRow">
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>Booking #</th>
                                <th>Container #</th>
                                <th>Seal #</th>
                                <th>Weight</th>
                                <th>U/M</th>
                                <th>Count</th>
                                <th>Unit</th>
                                <th>CBM</th>
                                <th>HS Code</th>
                                <th>Description</th>
                                <th>Price</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.containers.map((c, index) => (
                                <tr key={index} id={`container-${c.id}`} className={styles.tr} onContextMenu={e => this.toggleSelectedContainer(e, c.id)}>
                                    <td className={styles.bookingNumber}>{this.props.booking.bookingNumber}</td>
                                    <td className={styles.containerNumber}><Form.TextInput tabIndex="2" name={`containerNumber:${index}`} value={this.state.containers[index].containerNumber} onChange={this.onChange} maxLength="11" flags={[flags.ALLOW_EMPTY]} /></td>
                                    <td className={styles.sealNumber}><Form.TextInput tabIndex="2" name={`sealNumber:${index}`} value={this.state.containers[index].sealNumber} onChange={this.onChange} maxLength="30" flags={[flags.ALLOW_EMPTY]} /></td>
                                    <td className={styles.weight}><Form.NumberInput tabIndex="2" float name={`weight:${index}`} value={this.state.containers[index].weight} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} /></td>
                                    <td className={styles.unitOfMeasurement}><Form.Select tabIndex="2" name={`unitOfMeasurement:${index}`} value={this.state.containers[index].unitOfMeasurement} onChange={this.weightOnChange} list={this.getUnitsOfMeasurement()} filter={{ label: 'label', value: 'label' }} /></td>
                                    <td className={styles.count}><Form.NumberInput tabIndex="2" name={`count:${index}`} value={this.state.containers[index].count} onChange={this.onChange} maxLength="11" /></td>
                                    <td className={styles.unit}><Form.TextInput tabIndex="2" name={`unit:${index}`} value={this.state.containers[index].unit} onChange={this.onChange} maxLength="12" flags={[flags.ALLOW_EMPTY]} /></td>
                                    <td className={styles.cubicMeters}><Form.NumberInput tabIndex="2" float name={`cubicMeters:${index}`} value={this.state.containers[index].cubicMeters} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} /></td>
                                    <td className={styles.hsCode}><Form.TextInput tabIndex="2" name={`hsCode:${index}`} value={this.state.containers[index].hsCode} onChange={this.onChange} maxLength="14" flags={[flags.ALLOW_EMPTY]} /></td>
                                    <td className={styles.description}><Form.TextInput tabIndex="2" name={`description:${index}`} value={this.state.containers[index].description} onChange={this.onChange} maxLength="75" flags={[flags.ALLOW_EMPTY]} /></td>
                                    <td onBlur={index === 0 ? this.cascadeInputs : () => { }} className={styles.price}><Form.NumberInput tabIndex="2" float name={`price:${index}`} value={this.state.containers[index].price} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} /></td>
                                </tr>
                            ))}
                            <tr className="exportButtonRow">
                                <td style={{ position: "absolute" }}>
                                    <Form.Button tabIndex="2" col="col s2 containerButton" color="blue" label="Save Containers" type="outline" onClick={this.saveContainers} />
                                </td>
                                <td></td>
                                <td></td>
                                <td>
                                    <Form.NumberInput tabIndex="2" stylingClass="customTotal" label="Total (KG)" onChange={(name, value) => this.setState({ [name]: value })} name="customTotal" value={this.state.customTotal} flags={[flags.ALLOW_EMPTY]} />
                                </td>
                                <td>
                                    <Form.TextInput tabIndex="2" col={`col byContainerTotal`} stylingClass={`byContainerTotalInput ${this.state.byContainerTotal !== parseInt(this.state.customTotal) ? 'misMatched' : ""}`} label="By Ct. Total (KG)" onChange={(name, value) => this.setState({ [name]: value })} name="byContainerTotal" value={this.state.byContainerTotal} flags={[flags.ALLOW_EMPTY]} disabled readonly />
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <Form.NumberInput tabIndex="2" col="s1 containerCountInput" name="addContainerCount" label="Count" value={this.state.addContainerCount} onChange={(name, value) => this.setState({ [name]: value })} flags={[flags.ALLOW_EMPTY, flags.MAX_VALUE + (99 - this.state.containers.length)]} />
                                </td>
                                <td>
                                    <Form.Button tabIndex="2" col="col s3containerButton" color="white" label="Add Containers" type="outline" onClick={this.addContainers} />
                                </td>
                                <td className="buttonSection">
                                    <div className='invisibleButtonSection delayedTooltip'>
                                        <Form.Button col="copyContainersButton" onClick={this.copyContainerNumbers} color="black" type="outline" icon="content_copy" inlineStyle={{ padding: "5px" }} iconClass="copyContainers" tabIndex="-1" />
                                        <span className="tooltiptext">Copy Container Numbers</span>
                                    </div>
                                    <div className="csvButton right delayedTooltip" tabIndex="-1">
                                        <span className="tooltiptext">Export As CSV</span>
                                        {this.state.containers.length ?
                                            <CSVLink filename={`${this.props?.booking?.bookingNumber ? this.props.booking.bookingNumber[0] : 'ERROR'}_containers.csv`} headers={this.state.csvHeaders} tabIndex="-1" data={this.state.csvContainers} target="_blank">
                                                <Form.Button col="downloadCsvButton" color="black" type="outline" icon="file_download" inlineStyle={{ padding: "5px" }} iconClass="csvDownload" tabIndex="-1" />
                                            </CSVLink>
                                            : null}
                                    </div>
                                </td>
                            </tr>
                            {this.state.selectedContainers.length ? (
                                <tr className="exportButtonRow">
                                    <Form.Button col="col s2 containerButton deleteButton" color="red" type="outline" icon="delete" label="Delete" onClick={this.deleteContainer} />
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    const { user } = state;
    return { user };
}

export default connect(mapStateToProps)(Containers);