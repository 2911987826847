import React from 'react';
import Form from '../../../../Components/NewComponents';
import { flags } from '../../../../constants';
import CILineItemModal from './CILineItemModal';

const CI = (props) => {

    return (
        <div style={{ paddingLeft: "20px", paddingRight: "20px" }} className="noMargin">
            <div className="row" style={{ marginTop: "-20px" }}>
                <b className='col s2'>Marks & Numbers</b>
                <b className="col s6">Description of Goods</b>
                <b className="col s2">Quantity</b>
                <b className="col s1">Unit Price</b>
                <b className="col s1">Amount</b>
            </div>
            <div className="row noMargin">
                {Array.isArray(props.commercialInvoice) ? props.commercialInvoice.map((row, index) => (
                    <div key={`CI^${index}`} className="row">
                        <Form.TextInput tabIndex="2" col="s2" name={`marksAndNumbers:${index}`} value={props.commercialInvoice[index].marksAndNumbers} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <div className="col s6">
                            <Form.TextArea tabIndex="2" name={`description:${index}`} value={props.commercialInvoice[index].description} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} height="136px" />
                        </div>
                        <Form.TextInput tabIndex="2" col="s2" name={`groupedWeight:${index}`} value={props.commercialInvoice[index].groupedWeight === null ? "" : props.commercialInvoice[index].groupedWeight} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput tabIndex="2" col="s1" name={`unitPrice:${index}`} value={props.commercialInvoice[index].unitPrice === null ? "" : props.commercialInvoice[index].unitPrice} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput tabIndex="2" col="s1" name={`amount:${index}`} value={props.commercialInvoice[index].amount === null ? "" : props.commercialInvoice[index].amount} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                )) : 
                    <div key={`CI^`} className="row">
                        <Form.TextInput tabIndex="2" col="s2" name={`marksAndNumbers`} disabled value={props.commercialInvoice.marksAndNumbers} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <div className="col s6">
                            <Form.TextArea tabIndex="2" name={`description`} disabled value={props.commercialInvoice.description} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} height="136px" />
                        </div>
                        <Form.TextInput tabIndex="2" col="s2" name={`groupedWeight`} disabled value={props.commercialInvoice.groupedWeight === null ? "" : props.commercialInvoice.groupedWeight} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput tabIndex="2" col="s1" name={`unitPrice`} disabled value={props.commercialInvoice.unitPrice === null ? "" : props.commercialInvoice.unitPrice} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput tabIndex="2" col="s1" name={`amount`} disabled value={props.commercialInvoice.amount === null ? "" : props.commercialInvoice.amount} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                    </div>}
            </div>
            <div className="row">
                <Form.TextInput tabIndex="2" col="s4 " name="termsOfPrice:0" label="Terms/Price" disabled={Array.isArray(props.commercialInvoice) ? false : true} onChange={props.onChange} value={props.commercialInvoice[0] !== undefined ? props.commercialInvoice[0].termsOfPrice : ""} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput tabIndex="2" col="s4 " name="termsOfDelivery:0" label="Terms/Delivery" disabled={Array.isArray(props.commercialInvoice) ? false : true} onChange={props.onChange} value={props.commercialInvoice[0] !== undefined ? props.commercialInvoice[0].termsOfDelivery : ""} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row noMargin">
                <Form.Button tabIndex="2" round type="outline" col="col s2" styling="commercialInvoiceButtons" color="blue" label="Add Line Item" onClick={props.showCiLineItemModal} />
            </div>
            <CILineItemModal
                id="ci-line-item"
                show={props.showCiLineItemModal}
                shippingId={props.shippingId}
            />
        </div>
    )
}

export default CI;