import React, { Component } from "react";
import Form from "../../../Components/NewComponents";
import axios from "axios";
import { globalToastActions } from "../../../Redux/actions";
import { flags } from "../../../constants";
import { auth } from "../../../helperFunctions";

class ModalSalesRepDisplay extends Component {
    state = {
        salesRep: "",
        carrierSalesReps: [],
        salesRepName: "",
        salesRepRole: "SALES REP",
        salesRepLocation: "",
        salesRepOfficePhone: "",
        salesRepMobilePhone: "",
        salesRepEmail: "",
        showAddSalesRep: false,
        editSalesRepName: "",
        editSalesRepRole: "",
        editSalesRepLocation: "",
        editSalesRepOfficePhone: "",
        editSalesRepMobilePhone: "",
        editSalesRepEmail: ""
    }

    initListeners = () => {
        document.querySelectorAll('.salesRepDropdown').forEach(el => el.addEventListener('keydown', this.openSalesRepFormListener));
    }

    removeListeners = () => {
        document.querySelectorAll(".salesRepDropdown").forEach(el => el.removeEventListener('keydown', this.openSalesRepFormListener));
    }

    openSalesRepFormListener = (e) => {
        if (e.key === "Enter") {
            document.activeElement.click();
        }
    }

    openSalesRepForm = () => {
        this.setState({ showAddSalesRep: !this.state.showAddSalesRep }, () => {
            if (this.state.showAddSalesRep) {
                document.getElementById("salesRepName").focus();
            } else {
                document.querySelector(".salesRepDropdown").focus();
            }
        })
    }

    onChange = (name, value) => {
        this.setState({ [name]: value })
    }

    makeActive = (index) => {

        let csr = this.props.contract.contractSalesReps[index];

        this.setState({
            editSalesRepName: csr.name,
            editSalesRepRole: csr.role,
            editSalesRepLocation: csr.location,
            editSalesRepOfficePhone: csr.officePhone,
            editSalesRepMobilePhone: csr.mobilePhone,
            editSalesRepEmail: csr.email,
            editRowIndex: index
        }, () => {
            let targetRow = document.querySelectorAll("td:not(.material-icons)>div>input, td:not(.material-icons)>div>select");
            targetRow.forEach(r => {
                r.style.color = "blue"
            });
            targetRow[0].focus();
        })
    }

    makeInactive = (index) => {

        let { editSalesRepName, editSalesRepRole, editSalesRepLocation, editSalesRepOfficePhone, editSalesRepMobilePhone, editSalesRepEmail } = this.state;

        let salesRep = {
            name: editSalesRepName,
            role: editSalesRepRole,
            location: editSalesRepLocation,
            officePhone: editSalesRepOfficePhone,
            mobilePhone: editSalesRepMobilePhone,
            email: editSalesRepEmail
        }

        this.props.salesRepSpliceEdit(salesRep, index);


        this.setState({
            editSalesRepName: "",
            editSalesRepRole: "",
            editSalesRepLocation: "",
            editSalesRepOfficePhone: "",
            editSalesRepMobilePhone: "",
            editSalesRepEmail: "",
            editRowIndex: null
        }, () => {
            let targetRow = document.querySelectorAll(`#salesReps`)[index].querySelectorAll("td:not(.material-icons)");
            targetRow.forEach(r => {
                r.style.color = "black"
            });
            if (document.querySelectorAll(`#modal-sales-rep-body>tr`)[index + 1]) {
                document.querySelectorAll(`#modal-sales-rep-body>tr`)[index + 1].children[6].children[0].focus();
            }
        })
    }

    createAndAdd = (repInfo) => {
        let salesRep = {
            name: repInfo.salesRepName,
            role: repInfo.salesRepRole,
            location: repInfo.salesRepLocation,
            officePhone: repInfo.salesRepOfficePhone,
            mobilePhone: repInfo.salesRepMobilePhone,
            email: repInfo.salesRepEmail,
            newRep: true
        }
        let salesReps = this.state.carrierSalesReps;
        salesReps.push(salesRep);
        this.props.addSalesRep(salesRep);
        document.getElementById("salesRepName").select();
        this.setState({
            salesRepName: "",
            salesRepRole: "SALES REP",
            salesRepLocation: "",
            salesRepOfficePhone: "",
            salesRepMobilePhone: "",
            salesRepEmail: "",
        })
    }

    getSalesReps = () => {
        axios.get("/api/v1/contract/salesrep", { params: { ...auth.getAuthData(), carrierId: this.props.contract.contractDetails.carrierId } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `${result.code}`, type: "error" } })
            }

            this.setState({ carrierSalesReps: result.data })
        })
    }

    componentDidMount = () => {
        this.getSalesReps();
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => (
        <div>
            <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col s12">
                    <div className="row">
                        <h5 style={{ marginTop: "0px", marginBottom: "20px" }}>Sales Representatives</h5>
                        <Form.Select col="col s10" placeholder="Add Sales Rep" label="Choose Sales Rep" name="salesRep" value={this.state.salesRep} list={this.state.carrierSalesReps} filter={{ label: "name", value: "name" }} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Button col="col s2" label="Add" icon="add" type="outline" color="blue" onClick={() => this.props.addSalesRep(this.state.carrierSalesReps.filter(r => r.name === this.state.salesRep)[0])} />
                    </div>
                    <div className="row">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Office #</th>
                                    <th>Mobile #</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Location</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="modal-sales-rep-body">
                                {this.props.contract.contractSalesReps.map((s, index) => (
                                    this.state.editRowIndex === index ?
                                        <tr key={index}>
                                            <td>
                                                <Form.TextInput name={`editSalesRepName`} label="Name" onChange={this.onChange} value={this.state.editSalesRepName} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <Form.TextInput name={`editSalesRepOfficePhone`} label="Office Phone" onChange={this.onChange} value={this.state.editSalesRepOfficePhone} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <Form.TextInput name={`editSalesRepMobilePhone`} label="Mobile Phone" onChange={this.onChange} value={this.state.editSalesRepMobilePhone} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <Form.TextInput name={`editSalesRepEmail`} label="Email" onChange={this.onChange} value={this.state.editSalesRepEmail} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <Form.Select name={`editSalesRepRole`} label="Role" onChange={this.onChange} value={this.state.editSalesRepRole} onFocus={this.props.markAsReviewed} list={[{ label: "SALES REP" }, { label: "INSIDE SALES" }, { label: "MANAGER" }]} filter={{ label: "label", value: "label" }} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <Form.TextInput name={`editSalesRepLocation`} placeholder="Phoenix, AZ" label="Location" onChange={this.onChange} value={this.state.editSalesRepLocation} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td className="keep-black modalSaveIcon">
                                                <i tabIndex="0" className="material-icons" onClick={() => this.makeInactive(index)}>save</i>
                                            </td>
                                        </tr>
                                        :
                                        <tr key={index} id="salesReps">
                                            <td>{s.name}</td>
                                            <td>{s.officePhone}</td>
                                            <td>{s.mobilePhone}</td>
                                            <td>{s.email}</td>
                                            <td>{s.role}</td>
                                            <td>{s.location}</td>
                                            <td className="keep-black editAndDeleteIcons">
                                                <i tabIndex="0" className="material-icons" onClick={() => this.makeActive(index)}>edit</i>
                                                <i tabIndex="0" className="material-icons" onClick={() => this.props.removeSalesRep(index)}>cancel</i>
                                            </td>
                                        </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col s12">
                    {!this.state.showAddSalesRep ?
                        <div className="col s12 formDropdown salesRepDropdown" tabIndex="0" onClick={this.openSalesRepForm} style={{ marginBottom: "20px" }} >
                            <i className="material-icons">keyboard_arrow_down</i><span>Add Sales Rep</span>
                        </div>
                        :
                        <div className="col s12">
                            <div className="row">
                                <div className="col s12 formDropdown salesRepDropdown" tabIndex="0" onClick={this.openSalesRepForm} >
                                    <i className="material-icons">keyboard_arrow_up</i><span>Hide Form</span>
                                </div>
                            </div>
                            <div className="row">
                                <h6>Add New Sales Rep</h6>
                            </div>
                            <div className="row">
                                <Form.TextInput col="col s6" label="Name" name="salesRepName" value={this.state.salesRepName} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="col s6" label="Email" name="salesRepEmail" value={this.state.salesRepEmail} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                            </div>
                            <div className="row">
                                <Form.Select col="col s6" label="Type" name="salesRepRole" value={this.state.salesRepRole} onChange={this.onChange} list={[{ label: "SALES REP" }, { label: "INSIDE SALES" }, { label: "MANAGER" }]} filter={{ label: "label", value: "label" }} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="col s6" label="Location" name="salesRepLocation" value={this.state.salesRepLocation} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                            </div>
                            <div className="row">
                                <Form.TextInput col="col s6" label="Office Number" name="salesRepOfficePhone" value={this.state.salesRepOfficePhone} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="col s6" label="Mobile Number" name="salesRepMobilePhone" value={this.state.salesRepMobilePhone} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                            </div>
                            <div className="row">
                                <Form.Button col="col s3" label="Create and Add Rep" type="outline" color="blue" onClick={() => this.createAndAdd(this.state)} />
                            </div>
                        </div>}
                </div>

            </div>
        </div>
    )
}




export default ModalSalesRepDisplay