
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import styles from "./Requests.module.css";
import Form from "../../Components/NewComponents"
import { globalToastActions } from "../../Redux/actions";
import moment from "moment";
import EmployeeSection from "./EmployeeSection";
import StatusEditSection from "./StatusEditSection";
import { flags } from '../../constants';
import { auth } from "../../helperFunctions";
import M from 'materialize-css';
class RequestModal extends Component {
    state = {
        bookingRequestNumber: "",
        bookingRequestComments: ["", ""],
        comment: "",
        tz: moment.tz.guess(),
        statusMenu: false,
    }



    initListeners = () => {
        if (this.props.employee) {
            document.getElementById("comment").addEventListener("keydown", this.commentListener);
        }
    }

    removeListeners = () => {
        if (this.props.employee) {
            document.getElementById("comment").removeEventListener("keydown", this.commentListener);
        }
    }

    commentListener = (e) => {
        if (e.code === "Enter" && e.shiftKey) {
            return;
        }
        else if (e.code === "Enter") {
            e.preventDefault();
            this.postComment();
        }
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    statusOnChange = (name, value) => {
        switch (value) {
            case 'NEW':
                this.setState({ statusProgress: '25%', statusMessage: "Booking Received", [name]: value });
                break;
            case 'PENDING':
                this.setState({ statusProgress: '40%', statusMessage: 'Booking Requested', [name]: value });
                break;
            case 'REQUESTED':
                this.setState({ statusProgress: '50%', statusMessage: "Requested Vessel Creation", [name]: value });
                break;
            case 'UPDATING':
                this.setState({ statusProgress: '60%', statusMessage: "Processing Booking", [name]: value });
                break;
            case 'DECLINED':
                this.setState({ statusProgress: '100%', statusMessage: 'Booking Declined By Carrier', [name]: value });
                break;
            case 'COMPLETED':
                this.setState({ statusProgress: '100%', statusMessage: 'Request Completed', [name]: value });
                break;
            case 'CLOSED':
                this.setState({ statusProgress: '100%', statusMessage: 'Request Closed', [name]: value });
                break;
            default:
                this.setState({ statusProgress: '25%', statusMessage: "Booking Received", [name]: value });
                break;
        }
    }

    statusMemoOnChange = (name, value) => {
        this.setState({ [name]: value }, () => {
            axios.post("/api/v1/request/e/statusmemo", { ...auth.getAuthData(), statusMemo: value, id: this.state.id }).then(result => {
                if (result === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    return this.props.dispatch({ type: "SCORCHED_EARTH" });
                } else {
                    this.props.getBookingRequests();
                    this.props.emitUpdateTrigger([this.state.shipperId])
                }
            })
        })
    }

    assignedToOnChange = (name, value) => {
        if (parseInt(value) === 0) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Assignee Cannot Be Empty", type: "error" } })
            return;
        }
        this.setState({ [name]: value }, () => {
            let emailInfo = {
                userEmail: this.props.portalUser.filter(pu => pu.id === parseInt(value))[0].emailAddress,
                ...this.state
            };
            axios.post("/api/v1/request/e/assignedto", { ...auth.getAuthData(), user: value, emailInfo, id: this.state.id }).then(result => {
                if (result === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    return this.props.dispatch({ type: "SCORCHED_EARTH" });
                } else {
                    this.props.getBookingRequests();
                    this.props.emitUpdateTrigger([this.state.shipperId])
                }
            })
        })
    }

    setComment = (name, value) => {
        this.setState({ [name]: value })
    }

    saveReminder = (e) => {

        e.preventDefault();

        if (!this.state.reminderRecipient || !this.state.reminderDateFull || !this.state.reminderTime) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Please Complete Reminder Information", type: "warning" } });
            return;
        }

        let combinedTime = moment(`${this.state.reminderDateFull} ${this.state.reminderTime}`, "MM/DD/YY HH:mm").format("MM/DD/YY HH:mm");
        let today = moment().format("MM/DD/YY HH:mm");

        if (moment(combinedTime, "MM/DD/YY HH:mm").isBefore(moment(today, "MM/DD/YY HH:mm"))) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Cannot set reminder in past" } })
        }

        let { id, reminderNote, reminderDateFull, reminderTime, reminderRecipient } = this.state;
        axios.post("/api/v1/request/e/reminder", { ...auth.getAuthData(), id, reminderNote, reminderDateFull, reminderTime, reminderRecipient }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.props.getBookingRequests();
                this.props.emitUpdateTrigger([this.state.shipperId])
            }
        })
    }

    openReminderSection = () => {
        let reminderTime = this.state.reminderTime;
        let reminderRecipient = this.state.reminderRecipient;
        this.setState({ reminderTime: reminderTime ? reminderTime : "09:30", reminderRecipient: reminderRecipient ? reminderRecipient : this.props.user.id }, () => {
            this.props.setReminderSectionAsOpen();
        })

    }

    deleteReminder = () => {
        axios.post("/api/v1/request/e/reminder/delete", { ...auth.getAuthData(), id: this.state.id }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.setState({ reminderNote: "", reminderRecipient: 0, reminderDate: "", reminderTime: "" }, () => {
                    this.props.getBookingRequests();
                    this.props.emitUpdateTrigger([this.state.shipperId])

                });
            }
        })
    }

    resizeTextArea = (id) => {
        let textArea = document.getElementById(id);
        let heightLimit = 900;

        textArea.style.height = "";
        textArea.style.minHeight = "";
        textArea.style.maxHeight = "";
        textArea.style.height = Math.min(textArea.scrollHeight, heightLimit) + "px";
        textArea.style.maxHeight = Math.min(textArea.scrollHeight, heightLimit) + "px";
        textArea.style.minHeight = Math.min(textArea.scrollHeight, heightLimit) + "px";
    }

    postRequestEdit = () => {
        if ((!this.state.isDateRange && !this.state.startDate) || (this.state.isDateRange && !this.state.startDate && !this.state.endDate)) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Select Date(s)", type: "error" } });
        } else if (!this.state.containerCount) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Enter Container Count", type: "error" } });
        }
        axios.post(`/api/v1/request/${this.props.employee ? 'e' : 'c'}/edit`, { ...auth.getAuthData(), ...this.state }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                localStorage.clear();
                return;
            } else {

                await this.props.getBookingRequests();
                this.props.emitUpdateTrigger([this.state.shipperId]);

                if (!this.props.employee) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "An email has been sent to notify us of your change!", type: "success" } });
                }

                if (this.props.contracts.length) {
                    this.props.getAllContracts(this.state.originState, this.state.originId, this.state.destId, this.state.shipperId)
                }
            }
        })
    }

    saveBookingRequestNumber = (e) => {
        if (e) {
            e.preventDefault()
        }
        let { id, bookingRequestNumber, bookingRequestNumberId } = this.state;
        bookingRequestNumber = bookingRequestNumber.trim().toUpperCase();
        if (this.state.bookingRequestNumber.trim() === this.state.bookingRequestNumberCopy.trim()) {
            return;
        }

        axios.post("/api/v1/request/e/requestnumber", { ...auth.getAuthData(), bookingRequestId: id, bookingRequestNumber, id: bookingRequestNumberId }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                localStorage.clear();
                return;
            } else {
                this.props.getBookingRequests();
                this.props.emitUpdateTrigger([this.state.shipperId]);
            }
        })
    }

    changeTabOrder = () => {
        document.getElementById('comment').focus();
        this.saveBookingRequestNumber();
    }

    postComment = () => {
        if (!this.state.comment) {
            return;
        }
        axios.post("/api/v1/request/e/comment", { ...auth.getAuthData(), id: this.state.id, comment: this.state.comment }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                localStorage.clear();
                return;
            } else {
                this.setState({ comment: "" }, () => {
                    this.props.getBookingRequests();
                    this.props.emitUpdateTrigger();
                });
            }
        })
    }

    removeAttentionFlag = () => {
        axios.post("/api/v1/request/e/removeattention", { ...auth.getAuthData(), id: this.state.id }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else {
                this.props.closeModal();
                this.props.getBookingRequests();
                this.props.emitUpdateTrigger([this.state.shipperId])
            }
        })
    }

    getComments = () => {
        axios.get("/api/v1/request/e/comment", { params: { ...auth.getAuthData(), id: this.state.id, tz: this.state.tz } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                localStorage.clear();
                return;
            } else {
                this.setState({ bookingRequestComments: result.data });
            }
        })
    }

    componentDidUpdate = (prevState, prevProps) => {
        if ((prevProps.bookingRequest !== this.props.bookingRequest && this.props.bookingRequest && this.state.id !== this.props.bookingRequest.id) || this.props.updateModalInfo) {
            this.setState({ ...this.props.bookingRequest, comment: "" }, this.props.user.isEmployee || this.props.user.is_employee ? () => this.getComments() : null);
            this.props.resetUpdateModalInfo();
        }
    }

    shouldComponentUpdate = () => {
        if (M.Modal.getInstance(document.querySelector(".bookingRequestModal")).isOpen) {
            return true;
        } else {
            return false;
        }
    }

    componentDidMount = () => {
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    renderEditFields = () => {

        return <div>
            <div className={styles.bookingRequestModalBody}>
                <div className="row">
                    <Form.Select col="col s2" label="Origin" name="originId" value={this.state.originId} onChange={this.onChange} list={this.props.origins} filter={{ label: 'name', value: 'id' }} />
                    <Form.Select col="col s2" label="Dest" name="destId" value={this.state.destId} onChange={this.onChange} list={this.props.destinations} filter={{ label: 'name', value: 'id' }} />
                    <Form.Select col="col s1" label="Date Type" name="dateType" value={this.state.dateType} onChange={this.onChange} list={[{ label: "ETA" }, { label: "ETD" }, { label: "CUT" }]} filter={{ label: 'label', value: 'label' }} />
                    {!this.state.isDateRange ?
                        <Form.DateInput col="col s3" label="Date" name="startDate" value={this.state.startDate} onChange={this.onChange} /> :
                        <Form.DateRange col="s3" label="Shipment Date" onChange={this.onChange} names={{ start: 'startDate', end: 'endDate' }} />
                    }
                    <Form.NumberInput col="col s1" label="Cont. Count" name="containerCount" value={this.state.containerCount} onChange={this.onChange} maxLength="2" />
                    <Form.Select col="col s1" label="Cont. Type" name="containerTypeId" value={this.state.containerTypeId} onChange={this.onChange} filter={{ label: "code", value: "id" }} list={this.props.containerType} />
                    <Form.Select col="s2" name="carrierId" label="Carrier" required placeholder="Best Fit" onChange={this.onChange} value={this.state.carrierId} list={this.props.carriers} filter={{ label: 'displayName', value: 'id' }} flags={[flags.ALLOW_EMPTY]} />
                </div>
                <div className="row">
                    <Form.TextInput col="s3" label="Shipper Ref." name="shipperReferenceNumber" value={this.state.shipperReferenceNumber} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                    <Form.AutoComplete col="s2" name="consigneeName" label="Consignee" onChange={this.onChange} value={this.state.consigneeName} data={this.props.editConsignees} filterValue="name" flags={[flags.ALLOW_EMPTY]} />
                    <Form.TextInput col="s3" label="Product Desc." name="productDescription" value={this.state.productDescription} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                    <div className={`col s3 ${styles.contractInfo}`}>
                        <span className={`${styles.contractNumber}`}>{this.state.contractNumber} {this.state.contractRate}</span>
                    </div>
                </div>
                <div className="row">
                    <Form.TextArea col="col s12" label="Note" name="note" value={this.state.note} onChange={this.onChange} />
                </div>
                <div className={`row ${styles.requestDates} ${styles.smallMarginBottom}`}>
                    <div className={`col s4 ${styles.datetimeRequestedDiv}`}>
                        <label htmlFor="datetimeRequested" className={styles.datetimeRequestedLabel}>Date Requested</label>
                        <span id="datetimeRequested" className={`${styles.datetimeRequestedSpan} ${styles.shipperViewText}`}>{this.state.datetimeRequested}</span>
                    </div>
                    <div className={`col s4 ${styles.datetimeAdvancedDiv}`}>
                        <label htmlFor="datetimeAdvanced" className={styles.datetimeAdvancedLabel}>{this.state.datetimeAdvancedLabel}</label>
                        <span id="datetimeAdvanced" className={styles.datetimeAdvancedSpan}>{this.state.datetimeAdvanced}</span>
                    </div>
                    <div className={`col s4 ${styles.datetimeCompletedDiv}`}>
                        <label htmlFor="datetimeCompleted" className={styles.datetimeCompletedLabel}>Date Completed</label>
                        <span id="datetimeCompleted" className={`${styles.datetimeCompletedSpan} ${styles.shipperViewText}`}>{this.state.datetimeCompleted}</span>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: "0px" }}>
                    <div className="col s12">

                        <div className={`progress col s12 ${styles.requestProgressContainer} ${styles[`${this.state.status}determinateContainer`]}`}>
                            <div className={`${styles[`${this.state.status}determinate`]} determinate ${styles.requestProgress}`} style={{ width: this.state.statusProgress }}>
                                <span className={styles.statusMessage}>{this.state.statusMessage}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.specialRestrictionNote ?
                <div className={`row ${styles.specialRestrictionNote} ${styles.smallMarginBottom}`}>
                    <span className={` ${styles.specialRestrictionLabel}`}>SPECIAL RESTRICTION: </span>{this.state.specialRestrictionNote}
                </div>
                :
                null
            }

        </div>
    }

    renderDisplayFields = () => {
        return <div>
            <div className={styles.bookingRequestModalBody}>
                <div className={styles.centerTable}>
                    <div>
                        <table className={styles.loveTables}>
                            <tbody>
                                <tr className={styles.shipperViewText}>
                                    <td>{this.state.originCode}/{this.state.destCode}</td>
                                    <td>{this.state.dateType} {this.state.startDate} {this.state.endDate ? `- ${this.state.endDate}` : ''}</td>
                                    <td>{this.state.containerCount}x{this.state.containerType}</td>
                                    <td>{this.state.carrierCode}</td>
                                </tr>
                                <tr>
                                    <td className={styles.shipperViewText}>{this.state.shipperReferenceNumber}</td>
                                    <td className={styles.shipperViewText}>{this.state.consigneeName}</td>
                                    <td className={styles.shipperViewText}>{this.state.productDescription}</td>
                                    <td><span className={`${styles.contractNumber}`}>{this.state.contractNumber} {this.state.contractRate}</span></td>
                                </tr>
                                {this.state.note ?
                                    <tr>
                                        <td className={styles.shipperViewText} colSpan="4">{this.state.note}</td>
                                    </tr> :
                                    null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={` ${styles.requestDates} ${styles.smallMarginBottom} row`}>
                    <div className={`col s4 ${styles.datetimeRequestedDiv} ${styles.shipperViewText}`}>
                        <label htmlFor="datetimeRequested" className={styles.datetimeRequestedLabel}>Date Requested</label>
                        <span id="datetimeRequested" className={styles.datetimeRequestedSpan}>{this.state.datetimeRequested}</span>
                    </div>
                    <div className={`col s4 ${styles.datetimeAdvancedDiv}`}>
                        <label htmlFor="datetimeAdvanced" className={styles.datetimeAdvancedLabel}>{this.state.datetimeAdvancedLabel}</label>
                        <span id="datetimeAdvanced" className={styles.datetimeAdvancedSpan}>{this.state.datetimeAdvanced}</span>
                    </div>
                    <div className={`col s4 ${styles.datetimeCompletedDiv} ${styles.shipperViewText}`}>
                        <label htmlFor="datetimeCompleted" className={styles.datetimeCompletedLabel}>Date Completed</label>
                        <span id="datetimeCompleted" className={styles.datetimeCompletedSpan}>{this.state.datetimeCompleted}</span>
                    </div>
                </div>
                <div className={`row ${styles.noMarginBottom}`}>
                    <div className={`progress col s12 ${styles.requestProgressContainer} ${styles[`${this.state.status}determinateContainer`]}`}>
                        <div className={`${styles[`${this.state.status}determinate`]} determinate ${styles.requestProgress}`} style={{ width: this.state.statusProgress }}>
                            <span className={styles.statusMessage}>{this.state.statusMessage}</span>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.specialRestrictionNote ?
                <div className={`row ${styles.specialRestrictionNote} ${styles.smallMarginBottom}`}>
                    <span className={` ${styles.specialRestrictionLabel}`}>SPECIAL RESTRICTION: </span>{this.state.specialRestrictionNote}
                </div>
                :
                null
            }
        </div>
    }

    renderShowContractsButtonAndList = () => {
        return <div className="row">
            {!this.props.contracts.length ?
                <p className={styles.showAllContractsRow} onClick={() => this.props.getAllContracts(this.state.originState, this.state.originId, this.state.destId, this.state.shipperId)}>Show Contract Rates</p> :
                <div>
                    <p style={{ fontStyle: "italic" }}>(Please note this contract information only reflects current rates in place for the selected origin and destination.)</p>
                    <table style={{ display: "inline-block" }}>
                        <thead>
                            <tr>
                                <th>Carrier</th>
                                <th>Contract #</th>
                                <th>Origin</th>
                                <th>Dest</th>
                                <th>Rate</th>
                                <th>Eff. Date</th>
                                <th>Exp. Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.contracts.map(c => (
                                <tr>
                                    <td>{c.carrierCode}</td>
                                    <td>{c.contractNumber}</td>
                                    <td>{c.originCode}</td>
                                    <td>{c.destCode}</td>
                                    <td>{c.freightRate}</td>
                                    <td>{c.effectiveDate}</td>
                                    <td>{c.expirationDate}</td>
                                    <td>{c.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    }

    renderToolTipSpan = () => {
        if (!this.props.employee) {
            if (this.state.status !== "NEW") {
                return <span className={styles.disabledButtonToolTipText}>Request can only be ammended when NEW. If you would like to make edits, please contact us.</span>
            }
        }
    }

    render = () => {
        return (
            <div className="modal bookingRequestModal" id={styles.bookingRequestModal}>
                <div className="modal-content row" style={{ marginBottom: "0px" }}>
                    <div className={`row ${styles.requestModalHeader} ${styles.smallMarginBottom}`}>
                        {this.props.editing ?
                            <Form.Button id="editButton" col="col s2" label="Save" type="outline" color="green" onClick={this.postRequestEdit} buttonClass={styles.saveAndEditButton} />
                            :
                            <div className={styles.disabledButtonToolTip}>
                                <Form.Button id="editButton" col={`col s2 ${!this.props.employee ? this.state.status === "NEW" ? "" : styles.disabledStyle : ""}`} label="Edit Request" type="outline" disabled={this.state.status !== 'NEW' && !this.props.employee ? true : false} color="green" onClick={() => this.props.setEditingTrue(this.state.shipperId)} buttonClass={styles.saveAndEditButton} />
                                {this.renderToolTipSpan()}
                            </div>
                        }
                        <div className="col s8">
                            <h5 className={styles.modalHeader}>{this.state.shipperName} Booking Request </h5>
                        </div>
                        <div className="col s2">
                            <span className={styles.closeIcon}><i className="material-icons" onClick={this.props.closeModal}>close</i></span>
                        </div>
                    </div>
                    <div className={`row ${styles.noMarginBottom}`}>
                        {this.props.editing ?
                            this.renderEditFields() :
                            this.renderDisplayFields()
                        }
                    </div>
                    <StatusEditSection
                        {...this.state}
                        employee={this.props.employee}
                        settingReminder={this.props.settingReminder}
                        onChange={this.onChange}
                        statusMemoOnChange={this.statusMemoOnChange}
                        assignedToOnChange={this.assignedToOnChange}
                        handleStatusMenu={this.props.handleStatusMenu}
                        saveBookingRequestNumber={this.saveBookingRequestNumber}
                        statusMemoList={this.props.statusMemoList}
                        openReminderSection={this.openReminderSection}
                        saveReminder={this.saveReminder}
                        deleteReminder={this.deleteReminder}
                        portalUser={this.props.portalUser}
                        changeTabOrder={this.changeTabOrder}
                        reminderDate={this.state.reminderDate || moment().format('MM/DD')}
                        reminderDateFull={this.state.reminderDateFull || moment().format('MM/DD/YY')}
                    />
                    {this.props.user.isEmployee || this.props.user.is_employee ?
                        <EmployeeSection
                            bookingRequestComments={this.state.bookingRequestComments}
                            removeAttentionFlag={this.removeAttentionFlag}
                            requiresAttention={this.state.requiresAttention}
                            postComment={this.postComment}
                            resizeTextArea={this.resizeTextArea}
                            onChange={this.onChange}
                            setComment={this.setComment}
                            comment={this.state.comment}
                        /> :
                        null
                    }
                    {this.props.user.isEmployee || this.props.user.is_employee ?
                        this.renderShowContractsButtonAndList() :
                        null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { user, urls, index, party } = state;
    const url = urls[index];
    return { user, url, party }
}

export default connect(mapStateToProps)(RequestModal)