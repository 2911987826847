import { FaFolder, FaFolderOpen, FaRegFolder, FaRegFolderOpen } from 'react-icons/fa'

const Folder = props => {
    const {
        folder,
        toggleFolder,
        setContext,
        setFileContext,
        onDragOver,
        onDrop,
        onDrag,
        highlightedElement
    } = props;

    const onContextMenu = e => {
        e.preventDefault();
        setFileContext({ show: false, file: null })
        const { clientX, clientY } = e;

        setContext({
            folder,
            clientX,
            clientY,
            show: true
        })
    }

    const getIcon = () => {
        if (folder.files || folder?.folders?.length) {
            if (folder.isOpen)
                return <FaFolderOpen />
            else
                return <FaFolder />
        }
        else {
            if (folder.isOpen)
                return <FaRegFolderOpen />
            else
                return <FaRegFolder />
        }

    }

    const getStyle = () => {
        if (highlightedElement) {
            const tr = highlightedElement.closest('tr');
            const obj = tr ? JSON.parse(tr.getAttribute('data-object')) : null;
            return obj && (obj.id === folder?.id || obj.folderId === folder?.id) ? { color: '#2196f3' } : null;
        }
    }

    return (
        <tr
            onClick={() => toggleFolder(folder.id)}
            onContextMenu={onContextMenu}
            onDragOver={onDragOver}
            onDrop={onDrop}
            draggable={true}
            onDrag={onDrag}
            data-object={JSON.stringify(folder)}
            style={getStyle()}
        >
            <td colSpan={3} style={{ padding: `0px 0px 0px ${(folder.depth - 1) * 18}px` }}>
                <span style={{ fontSize: '20px', position: 'relative', top: '4px', marginRight: '4px' }}>{getIcon()}</span>
                {folder.label}
            </td>
        </tr>
    )
}

export default Folder;