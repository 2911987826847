import { useEffect, useState, useLayoutEffect } from "react";
import M from 'materialize-css';
import { useDispatch } from "react-redux";
import { globalToastActions } from "../../../Redux/actions";
import Axios from "axios";
import { auth, logout } from "../../../helperFunctions";

const BookingNumbersModal = props => {
    const {
        getBooking,
        booking,
        componentId
    } = props;
    const { id: bookingId, bookingNumbers } = booking.booking;


    const dispatch = useDispatch();

    const [bookingNumber, setBookingNumber] = useState('');
    const [values, setValues] = useState(bookingNumbers || {});
    const [updatingId, setUpdatingId] = useState(null);

    const close = () => M.Modal.getInstance(document.querySelector(`#booking-numbers-modal-${componentId}`))?.close();

    useLayoutEffect(() => {
        const enterListener = e => { if (e.keyCode === 13) { saveBookingNumber() } }

        document.querySelector('#bookingNumber')?.addEventListener('keydown', enterListener);
        M.Modal.init(document.querySelectorAll(`#booking-numbers-modal-${componentId}`));

        return () => {
            document.querySelector('#bookingNumber')?.removeEventListener('keydown', enterListener);
            M.Modal.getInstance(document.querySelector(`#booking-numbers-modal-${componentId}`))?.destroy();
        }
    }, [])

    const onChange = ({ target: { value } }) => setBookingNumber(value);

    const toggleDisplay = bookingNumberId => {
        if (updatingId)
            return dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Please wait, update in progress.', type: 'info' } })

        if (bookingNumbers.filter(bn => bn.isDisplayed).length < 2 && bookingNumbers.find(bn => bn.id === bookingNumberId)?.isDisplayed)
            return dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'You must have at least 1 booking number displayed', type: 'warning' } })

        setUpdatingId(bookingNumberId);
        Axios.post('/api/v2/booking/update/bookingnumber/toggledisplay', {
            ...auth.getAuthData(),
            bookingNumberId
        })
            .then(async result => {
                dispatch(result.data);
                await getBooking();
            })
            .catch(logout)
            .finally(() => setUpdatingId(null))

    }

    const deleteBookingNumber = bookingNumber => {
        if (bookingNumbers.length < 2 || bookingNumbers.filter(bn => bn.isDisplayed).length < 2)
            return dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'You must have at least 1 displayed Booking Number', type: 'warning' } })

        if (window.confirm(`Delete Booking #: ${bookingNumber.bookingNumber}?`)) {
            setUpdatingId(bookingNumber.id);
            Axios.post('/api/v2/booking/delete/one/bookingnumber', {
                ...auth.getAuthData(),
                bookingNumberId: bookingNumber.id
            })
                .then(async result => {
                    dispatch(result.data);
                    await getBooking();
                })
                .catch(logout)
                .finally(() => setUpdatingId(null))
        }
    }

    const saveBookingNumber = e => {
        e?.preventDefault();
        Axios.post('/api/v2/booking/create/bookingNumber', {
            ...auth.getAuthData(),
            bookingId,
            bookingNumber: bookingNumber?.trim()
        })
            .then(async result => {
                dispatch(result.data);
                await getBooking()
                setBookingNumber('');
                close();
            })
            .catch(logout)
    }

    useEffect(() => {
        setValues(bookingNumbers || {})
    }, [bookingNumbers])

    return (
        <div id={`booking-numbers-modal-${componentId}`} className="modal">
            <div className="modal-content">
                <div className="row">
                    <i className="material-icons right black-text" onClick={close}>clear</i>
                </div>
                <div className="row">
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th>Booking Number</th>
                                <th>Display</th>
                                <th style={{ textAlign: 'right' }}>Delete Forever</th>
                            </tr>
                        </thead>
                        <tbody>
                            {values?.map(bn => {
                                if (bn.id === updatingId)
                                    return (
                                        <tr key={bn.id}>
                                            <td>{bn.bookingNumber}</td>
                                            <td colSpan={2} className="blue-text">Updating...</td>
                                        </tr>
                                    )
                                else
                                    return (
                                        <tr key={bn.id}>
                                            <td>{bn.bookingNumber}</td>
                                            <td>
                                                <p>
                                                    <label>
                                                        <input type="checkbox" className="filled-in" checked={bn.isDisplayed} onChange={() => toggleDisplay(bn.id)} />
                                                        <span></span>
                                                    </label>
                                                </p>
                                            </td>
                                            <td style={{ textAlign: 'right' }}><i className="material-icons red-text" onClick={() => deleteBookingNumber(bn)}>delete</i></td>
                                        </tr>
                                    )
                            })}
                            <tr>
                                <td>
                                    <div className="lf-input">
                                        <input id="bookingNumber" className="browser-default" type="text" value={bookingNumber} onChange={onChange} placeholder="New Booking #" />
                                    </div>
                                </td>
                                <td colSpan={2}>
                                    <a href="/" style={{ maxHeight: '28px', lineHeight: '28px', margin: '0px', position: 'relative', bottom: '7px' }} className="btn-small white blue-text waves-effect waves-light col s12" onClick={saveBookingNumber}>Add</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default BookingNumbersModal;