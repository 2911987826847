import React from 'react';
import styles from './textinput.module.css';
import { types } from '../../constants';
import { inputValidation } from '../../helperFunctions';



class Email extends React.Component {
    constructor(props) {
        super(props); this.state = this.initState()
    }

    initState = () => ({ value: '', err: null })
    onChange = e => {
        const value = e.target.value;
        this.setState({ value }, () => this.props.onChange(this.props.name, value));
    }

    getDivClassName = () => {
        return this.props.col ? `col ${this.props.col} ${styles.div}` : styles.div;
    }

    genInputClass = () => {
        return `browser-default ${styles.input} ${this.state.err ? styles.err : ''}`;
    }

    getInputErrInfo = () => ([{ name: 'value', type: types.EMAIL, value: this.props.value, flags: this.props.flags }])

    onBlur = () => inputValidation(this.getInputErrInfo(), this.success, this.failure);
    success = value => this.setState({ err: null }, this.props.onBlur);
    failure = value => this.setState({ err: { msg: value.value.msg } }, this.props.onBlur);

    render = () => (
        <div className={this.getDivClassName()}>
            <input
                type="text"
                className={this.genInputClass()}
                value={this.props.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                placeholder="person@place.com"
            />
            <label className={styles.label}>{this.props.label} {this.props.required ? <span style={{color: "red"}}>*</span> : ''}</label>
            {this.state.err ? <span className={styles.msg}>{this.state.err.msg}</span> : null}
        </div>
    )
}

export default Email;