import React from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import Form from '../../Components/NewComponents';
import EmailForm from '../Shipments/EmailForm/EmailForm';
import { flags } from '../../constants';
import Axios from 'axios';
import moment from 'moment';
import styles from './invoices.module.css';
import { globalToastActions, updateActions } from '../../Redux/actions';
import axios from 'axios';
import { fileUploader } from '../../helperFunctions';
import FileUpload from "../../Components/FileUpload/FileUpload";
import { auth, withRouter } from "../../helperFunctions";
import { Buffer } from 'buffer';
import DocChecklist from './createInvoice/docChecklist';

class Invoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({
        searchTerm: '',
        searchField: "",
        shipment: {},
        invoiceParty: 0,
        partyList: [],
        tabIndex: 0,
        descriptions: [],
        containerCount: '',
        commissionId: null,
        commissionRate: '',
        commissionPercentage: 0,
        commissionAmountReceived: 0,
        totalCommission: '',
        commissionStatus: 'NO COMMISSION DUE',
        totalFreight: '',
        terms: 'NET 30',
        lineItems: [],
        newDescription: 1,
        newQty: '',
        newRate: '',
        newCost: '',
        invoiceId: null,
        invoiceNumber: "",
        additionalInfo: "",
        invoiceNote: 'Thank you for your business!',
        internalNote: '',
        status: 'NOT PAID',
        invoiceCheckNumber: '',
        invoiceTransactionNumber: '',
        amountReceived: 0.00,
        invoicePaymentDate: '',
        commissionPaymentDate: '',
        commissionCheckNumber: '',
        commissionTransactionNumber: '',
        createdBy: '',
        dateCreated: '',
        lastModifiedBy: '',
        dateTimeModified: '',
        lastPrintedBy: '',
        dateTimePrinted: '',
        usePercentage: true,
        enterCommission: false,
        newCommission: true,
        saving: false,
        generatePdf: false,
        emails: [],
        emailSubject: '',
        fileUploadTabOpen: false,
        shipperConsigneePairs: [
            { shipperId: 36, carrierId: 2 },
            { shipperId: 87, carrierId: 2 },
            { shipperId: 84, carrierId: 2 },
            { shipperId: 120, carrierId: 2 },
            { shipperId: 134, carrierId: 2 },
            { shipperId: 154, carrierId: 2 },
            { shipperId: 2, carrierId: 2 },
            { shipperId: 26, carrierId: 2 },
            { shipperId: 29, carrierId: 2 },
            { shipperId: 96, carrierId: 2 },
            { shipperId: 139, carrierId: 2 },
            { shipperId: 27, carrierId: 2 },
            { shipperId: 25, carrierId: 2 },
            { shipperId: 207, carrierId: 2 },
            { shipperId: 87, carrierId: 3 },
            { shipperId: 49, carrierId: 5 },
            { shipperId: 75, carrierId: 5 },
            { shipperId: 139, carrierId: 5 },
            { shipperId: 43, carrierId: 5 },
            { shipperId: 154, carrierId: 5 },
            { shipperId: 119, carrierId: 6 },
            { shipperId: 207, carrierId: 6 },
            { shipperId: 49, carrierId: 7 },
            { shipperId: 36, carrierId: 7 },
            { shipperId: 87, carrierId: 7 },
            { shipperId: 84, carrierId: 7 },
            { shipperId: 120, carrierId: 7 },
            { shipperId: 134, carrierId: 7 },
            { shipperId: 154, carrierId: 7 },
            { shipperId: 2, carrierId: 7 },
            { shipperId: 71, carrierId: 7 },
            { shipperId: 29, carrierId: 7 },
            { shipperId: 96, carrierId: 7 },
            { shipperId: 139, carrierId: 7 },
            { shipperId: 27, carrierId: 7 },
            { shipperId: 119, carrierId: 7 },
            { shipperId: 25, carrierId: 7 },
            { shipperId: 43, carrierId: 7 },
            { shipperId: 49, carrierId: 8 },
            { shipperId: 36, carrierId: 8 },
            { shipperId: 87, carrierId: 8 },
            { shipperId: 84, carrierId: 8 },
            { shipperId: 120, carrierId: 8 },
            { shipperId: 134, carrierId: 8 },
            { shipperId: 2, carrierId: 8 },
            { shipperId: 71, carrierId: 8 },
            { shipperId: 29, carrierId: 8 },
            { shipperId: 96, carrierId: 8 },
            { shipperId: 139, carrierId: 8 },
            { shipperId: 119, carrierId: 8 },
            { shipperId: 25, carrierId: 8 },
            { shipperId: 43, carrierId: 8 },
            { shipperId: 36, carrierId: 9 },
            { shipperId: 87, carrierId: 9 },
            { shipperId: 84, carrierId: 9 },
            { shipperId: 134, carrierId: 9 },
            { shipperId: 26, carrierId: 9 },
            { shipperId: 27, carrierId: 9 },
            { shipperId: 207, carrierId: 10 },
            { shipperId: 36, carrierId: 10 },
            { shipperId: 2, carrierId: 10 },
            { shipperId: 29, carrierId: 10 },
            { shipperId: 27, carrierId: 10 },
            { shipperId: 25, carrierId: 10 },
            { shipperId: 87, carrierId: 10 },
            { shipperId: 94, carrierId: 10 },
            { shipperId: 96, carrierId: 10 },
            { shipperId: 119, carrierId: 10 },
            { shipperId: 134, carrierId: 10 },
            { shipperId: 19, carrierId: 10 },
            { shipperId: 49, carrierId: 10 },
            { shipperId: 43, carrierId: 10 },
            { shipperId: 154, carrierId: 10 },
            { shipperId: 26, carrierId: 10 },
            { shipperId: 89, carrierId: 11 },
            { shipperId: 36, carrierId: 14 },
            { shipperId: 87, carrierId: 14 },
            { shipperId: 84, carrierId: 14 },
            { shipperId: 96, carrierId: 14 },
            { shipperId: 119, carrierId: 14 },
            { shipperId: 25, carrierId: 14 },
            { shipperId: 119, carrierId: 15 },
            { shipperId: 96, carrierId: 15 },
            { shipperId: 36, carrierId: 15 },
            { shipperId: 25, carrierId: 15 },
            { shipperId: 87, carrierId: 15 },
            { shipperId: 84, carrierId: 15 },
            { shipperId: 19, carrierId: 15 },
            { shipperId: 134, carrierId: 15 },
            { shipperId: 2, carrierId: 15 },
            { shipperId: 29, carrierId: 15 },
            { shipperId: 49, carrierId: 15 },
            { shipperId: 43, carrierId: 15 },
            { shipperId: 139, carrierId: 15 },
            { shipperId: 154, carrierId: 15 }
        ],
        ...this.props.location?.state
    })

    clearState = () => ({
        ...this.initState(),
        searchTerm: '',
        descriptions: this.state.descriptions,
        tabIndex: this.state.tabIndex
    })

    initModal = () => {
        M.Modal.init(document.querySelectorAll('.modal'));
    }

    initListeners = () => {
        if (!this.state.containerCount) { document.querySelector('#searchField').addEventListener('keyup', this.searchListener); };
        document.addEventListener("keydown", this.escListener);
        document.addEventListener("keydown", this.saveListener);
        document.addEventListener("keydown", this.printListener);
        document.addEventListener("keydown", this.emailListener);
    }

    removeListeners = () => {
        if (!this.state.containerCount) { document.querySelector('#searchField').removeEventListener('keyup', this.searchListener); };
        document.removeEventListener("keydown", this.escListener);
        document.removeEventListener("keydown", this.saveListener);
        document.removeEventListener("keydown", this.printListener);
        document.removeEventListener("keydown", this.emailListener);
    }

    escListener = (e) => {
        if (e.keyCode === 27) {
            if (this.props.location?.state?.origin === "shippingInstructions") {
                this.props.navigate('/shipment/instructions', {
                    state: {
                        searchTerm: this.state.searchTerm,
                        invoiceNumber: this.state.invoiceNumber,
                        filters: this.props.location?.state?.filters,
                        origin: "invoiceCreation"
                    }
                })
            } else if (this.props.user.isAdmin || this.props.user.is_admin || this.props.user.isBookkeeper || this.props.user.is_bookkeeper) {
                this.props.navigate("/invoice", {
                    state: {
                        filters: this.props.location?.state?.filters,
                        origin: "invoiceCreation"
                    }
                })
            } else if (!this.state.containerCount) {
                this.props.navigate('/shipment', {
                    state: {
                        origin: "invoiceCreation"
                    }
                })
            }
        }
    }

    saveListener = (e) => {
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault();
            if (this.state.saving) {
                return;
            } else if (this.state.tabIndex === 1) {
                this.saveInvoice();
            } else if (this.state.tabIndex === 0) {
                this.saveCommission();
            }
        }
    }

    printListener = (e) => {
        if (e.ctrlKey && e.keyCode === 80) {
            e.preventDefault();
            if (this.state.saving) {
                return;
            } else if (this.state.tabIndex === 1) {
                this.saveAndPrintInvoice();
            } else if (this.state.tabIndex === 0) {
                return;
            }
        }
    }

    emailListener = (e) => {
        if (e.ctrlKey && e.key.toLowerCase() === "m") {
            this.saveAndEmail();
        }
    }

    searchListener = e => {
        if (e.keyCode === 13)
            this.getInvoiceDetails();
    }

    setInvoicePaymentNumbers = () => {

        let commissionPaymentDate = this.props.sessionVariables.paymentDates ? this.props.sessionVariables.paymentDates.commissionPaymentDate : "";
        let commissionCheckNumber = this.props.sessionVariables.checkNumbers ? this.props.sessionVariables.checkNumbers.commissionCheckNumber : "";
        let commissionTransactionNumber = this.props.sessionVariables.transactionNumbers ? this.props.sessionVariables.transactionNumbers.commissionTransactionNumber : "";
        let paymentDates = {
            commissionPaymentDate,
            invoicePaymentDate: this.state.invoicePaymentDate
        }
        let checkNumbers = {
            commissionCheckNumber,
            invoiceCheckNumber: this.state.invoiceCheckNumber,
        };
        let transactionNumbers = {
            commissionTransactionNumber,
            invoiceTransactionNumber: this.state.invoiceTransactionNumber
        }
        this.props.dispatch({
            type: updateActions.UPDATE_VAR, payload: {
                paymentDates,
                checkNumbers,
                transactionNumbers
            }
        });
    }

    setCommissionPaymentNumbers = () => {

        let invoicePaymentDate = this.props.sessionVariables.paymentDates ? this.props.sessionVariables.paymentDates.invoicePaymentDate : "";
        let invoiceCheckNumber = this.props.sessionVariables.checkNumbers ? this.props.sessionVariables.checkNumbers.invoiceCheckNumber : "";
        let invoiceTransactionNumber = this.props.sessionVariables.transactionNumbers ? this.props.sessionVariables.transactionNumbers.invoiceTransactionNumber : "";
        let paymentDates = {
            commissionPaymentDate: this.state.commissionPaymentDate,
            invoicePaymentDate
        }
        let checkNumbers = {
            commissionCheckNumber: this.state.commissionCheckNumber,
            invoiceCheckNumber
        };
        let transactionNumbers = {
            commissionTransactionNumber: this.state.commissionTransactionNumber,
            invoiceTransactionNumber
        }
        this.props.dispatch({
            type: updateActions.UPDATE_VAR, payload: {
                paymentDates,
                checkNumbers,
                transactionNumbers
            }
        });
    }

    checkForRepeatedPaymentInfo = (tab, field) => {
        if (!this.props.sessionVariables.checkNumbers) {
            return false;
        } else if (tab === "invoice" && this.state.invoice) {
            switch (field) {
                case 'checkNumber':
                    if (!this.state.invoice.invoiceCheckNumber &&
                        this.state.invoiceCheckNumber === this.props.sessionVariables.checkNumbers.invoiceCheckNumber) {
                        return true;
                    } else {
                        return false;
                    }
                case 'transactionNumber':
                    if (!this.state.invoice.transactionNumber &&
                        this.state.invoiceTransactionNumber === this.props.sessionVariables.transactionNumbers.invoiceTransactionNumber) {
                        return true;
                    } else {
                        return false;
                    };
                case 'paymentDate':
                    if (!this.state.invoice.paymentDate &&
                        this.state.invoicePaymentDate === this.props.sessionVariables.paymentDates.invoicePaymentDate) {
                        return true;
                    } else {
                        return false;
                    };
                default:
                    break;
            }
        } else if (tab === "commission" && this.state.commission) {
            switch (field) {
                case 'checkNumber':
                    if (!this.state.commission.commissionCheckNumber &&
                        this.state.commissionCheckNumber === this.props.sessionVariables.checkNumbers.commissionCheckNumber) {
                        return true;
                    } else {
                        return false;
                    };
                case 'transactionNumber':
                    if (!this.state.commission.commissionTransactionNumber &&
                        this.state.commissionTransactionNumber === this.props.sessionVariables.transactionNumbers.commissionTransactionNumbers) {
                        return true;
                    } else {
                        return false;
                    }
                case 'paymentDate':
                    if (!this.state.commission.commissionPaymentDate &&
                        this.state.commissionPaymentDate === this.props.sessionVariables.paymentDates.commissionPaymentDate) {
                        return true;
                    } else {
                        return false;
                    }
                default:
                    return false;
            }
        } else {
            return false;
        }
    }

    showEmailModal = async () => {
        let { lNumber, bookingNumber } = this.state.shipment;
        if (lNumber && bookingNumber) {
            this.setState({ generatePdf: true, emailSubject: `LF Invoice ${bookingNumber} - ${lNumber}` });
        } else {
            this.setState({ generatePdf: true, emailSubject: `LF Invoice #${this.state.invoiceNumber}` });
        }
        M.Modal.getInstance(document.querySelector("#emailModal")).open();
        document.querySelector("#recipient").focus();
    }

    hideEmailModal = () => {
        M.Modal.getInstance(document.querySelector("#emailModal")).close();
    }

    componentDidMount = async () => {
        this.getDescriptionList();
        this.initListeners();
        this.initModal();

        if (this.props.location?.state?.searchTerm)
            this.setState({ searchTerm: this.props.location.state.searchTerm }, () => this.getInvoice());
        else if (this.props.location?.state?.invoiceNumber)
            this.getInvoiceDetails();
        else
            this.setState({ tabIndex: 1 }, () => {
                document.querySelector('#invoiceParty')?.focus()
            })


    }



    componentWillUnmount = () => {
        this.removeListeners();
    }

    getDescriptionList = () => Axios.get('/api/v1/invoice/descriptions', { params: auth.getAuthData() }).then(result => {
        if (result.data === 'NOT AUTHENTICATED') {
            localStorage.clear();
            this.props.dispatch({ type: 'SCORCHED_EARTH' });
            return;
        }
        this.setState({ descriptions: result.data });
    })

    getParties = () => {
        const arr = [];
        const names = [];
        if (this.props.party)
            this.props.party.forEach(shipper => {
                if (names.indexOf(shipper.name) === -1) {
                    if (shipper.id === 1) {
                        arr.unshift(shipper);
                        names.unshift(shipper.name);
                    }
                    else {
                        arr.push(shipper)
                        names.push(shipper.name);
                    }
                }
            });
        arr.sort((a, b) => a.name.localeCompare(b.name));
        // this.setState({ partyList: arr })
        return arr;
    };

    getEmailsPromise = partyId => new Promise((resolve, reject) => {
        axios.get('/api/v1/contacts', {
            params: {
                ...auth.getAuthData(),
                partyId,
                role: 'INVOICES'
            }
        })
            .then(result => {

                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return;
                }
                else
                    resolve(this.setState({ emails: result.data }))
            })
            .catch(err => {
                reject()
            })
    })


    getEmails = (partyId) => {
        let role = 'INVOICES';
        axios.get("/api/v1/contacts", { params: { ...auth.getAuthData(), partyId, role } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }

            this.setState({ emails: result.data });
        })
    }


    getInvoice = () => {
        return new Promise((resolve, reject) => {
            let searchTerm = this.state.searchTerm;
            this.setState(p => ({ ...this.clearState(), searchTerm }));
            Axios.post('/api/v1/invoice/shipmentdetails', { ...auth.getAuthData(), searchTerm: this.state.searchTerm })
                .then(async result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' });
                        reject(result.data)
                        return;
                    }

                    if (!result.data) {
                        this.setState({ tabIndex: 0 });
                        resolve();
                    }

                    if (!result.data.id) {
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Results', type: 'warning' } });
                        resolve();
                        return;
                    }

                    if (result.data) {
                        this.getCommissionDetails();
                        this.getEmails(result.data.shipperId);
                        await this.getInvoiceDetails();
                    }

                    this.setState({
                        shipment: result.data,
                        containerCount: result.data.containerCount,
                        isAdmin: result.data.isAdmin
                    }, () => { resolve(); });

                });
        }).catch(err => console.log(err))

    }

    getCommissionDetails = () => Axios.get('/api/v1/invoice/commission', {
        params: {
            ...auth.getAuthData(),
            lNumber: this.state.searchTerm,
            tz: moment.tz.guess()
        }
    }).then(result => {
        if (result.data === 'NOT AUTHENTICATED') {
            localStorage.clear();
            this.props.dispatch({ type: 'SCORCHED_EARTH' });
            return;
        }
        if (result.data) {
            let checkNum = result.data.commissionCheckNumber;
            let date = result.data.commissionPaymentDate;
            let transactionNumber = result.data.commissionTransactionNumber;
            let reduxHasCheckInfo = this.props.sessionVariables.checkNumbers;
            let reduxHasTransactionInfo = this.props.sessionVariables.transactionNumbers;

            this.setState({
                ...result.data,
                enterCommission: result.data.commissionPercentage ? true : false,
                newCommission: false,
                commission: result.data,
                commissionTransactionNumber: !transactionNumber && reduxHasTransactionInfo ? this.props.sessionVariables.transactionNumbers.commissionTransactionNumber : result.data.commissionTransactionNumber,
                commissionCheckNumber: !checkNum && reduxHasCheckInfo ? this.props.sessionVariables.checkNumbers.commissionCheckNumber : result.data.commissionCheckNumber,
                commissionPaymentDate: !date && reduxHasCheckInfo ? this.props.sessionVariables.paymentDates.commissionPaymentDate : result.data.commissionPaymentDate
            })
        }


    })

    getInvoiceDetails = async () => {
        return new Promise((resolve, reject) => {
            if (this.state.invoiceNumber) {
                let searchField = this.state.searchField;
                this.setState(p => ({ ...this.clearState(), invoiceNumber: p.invoiceNumber, searchField }));
            }
            let tz = moment.tz.guess();
            Axios.get('/api/v1/invoice/invoiceDetails', {
                params: {
                    ...auth.getAuthData(),
                    lNumber: this.state.searchTerm ? this.state.searchTerm : null,
                    invoiceNumber: this.state.searchField ? this.state.searchField : this.state.invoiceNumber,
                    tz
                }
            }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    reject(result.data);
                    return;
                } else if (result.data.errno) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Error Retrieving Invoice: ${result.data.errno}`, type: "error" } });
                    reject(result.data.err);
                    return;
                } else if (result.data.emptyRes && this.state.searchField) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Results", type: "warning" } });
                    this.setState({ searchField: "" });
                    resolve();
                } else if (result.data) {
                    let checkNum = result.data.invoice.invoiceCheckNumber;
                    let date = result.data.invoice.invoicePaymentDate;
                    let transactionNumber = result.data.invoice.invoiceTransactionNumber;
                    let reduxHasCheckInfo = this.props.sessionVariables.checkNumbers;
                    let reduxHasTransactionInfo = this.props.sessionVariables.transactionNumbers;
                    let fallbackTransactionNumber = this.props.sessionVariables.transactionNumbers.invoiceTransactionNumber ? this.props.sessionVariables.transactionNumbers.invoiceTransactionNumber : "";
                    let fallbackCheckNumber = this.props.sessionVariables.checkNumbers.invoiceCheckNumber ? this.props.sessionVariables.checkNumbers.invoiceCheckNumber : "";
                    result.data.lineItems.forEach(row => Object.keys(row).forEach(key => { if (!row[key]) { row[key] = 0; } }));
                    this.setState({
                        searchField: "",
                        ...result.data.invoice,
                        lineItems: result.data.lineItems,
                        invoice: result.data.invoice,
                        invoiceTransactionNumber: !transactionNumber && reduxHasTransactionInfo ? fallbackTransactionNumber : result.data.invoice.invoiceTransactionNumber,
                        invoiceCheckNumber: !checkNum && reduxHasCheckInfo ? fallbackCheckNumber : result.data.invoice.invoiceCheckNumber,
                        invoicePaymentDate: !date && reduxHasCheckInfo ? this.props.sessionVariables.paymentDates.invoicePaymentDate : result.data.invoice.invoicePaymentDate,
                        invoiceParty: result.data.invoice.invoiceParty
                    }, () => resolve())
                }
            })
        }).catch(err => console.log(err))
    };


    onChange = (name, value) => this.setState({ [name]: value });

    showCommission = () => this.setState({ tabIndex: 0, fileUploadTabOpen: false })
    showInvoicing = () => {
        this.state.newCommission ?
            alert("Please Save Your Commission Information Before Continuing")
            :
            this.setState({ tabIndex: 1, fileUploadTabOpen: false }, () => {
                document.querySelector('#invoiceParty')?.focus()
            })
    }

    showFileUpload = () => {
        this.setState({ tabIndex: 2, fileUploadTabOpen: true });
    }


    getTotalFreight = () => {
        const { containerCount, commissionRate } = this.state;
        return (containerCount && commissionRate) ? containerCount * commissionRate : 0;
    }

    getTotalCommission = () => {
        const totalFreight = this.getTotalFreight();
        const { commissionPercentage, usePercentage, containerCount } = this.state;
        if (totalFreight && commissionPercentage && usePercentage) {
            return (totalFreight * (commissionPercentage / 100)).toFixed(2)
        } else if (totalFreight && commissionPercentage && !usePercentage) {
            return (commissionPercentage * containerCount)
        } else {
            return 0
        }
    }

    getStatusTypes = () => ([{ label: 'NOT PAID' }, { label: 'PAID' }, { label: 'PARTIAL' }])
    getCommissionStatusTypes = () => ([{ label: 'NOT PAID' }, { label: 'PAID' }, { label: 'PARTIAL' }, { label: 'NO COMMISSION DUE' }])
    getTerms = () => ([{ label: 'NET 30' }, { label: 'NET 60' }, { label: 'DUE ON RECEIPT' }])
    getDueDate = () => {
        if (!this.state.dateDue) {
            switch (this.state.terms) {
                case 'NET 30':
                    return moment().add(30, "days").format('DD MMM YYYY');
                case 'NET 60':
                    return moment().add(60, 'days').format('DD MMM YYYY');
                case 'DUE ON RECEIPT':
                    return moment().format('DD MMM YYYY');
                default:
                    return '';
            }
        } else {
            return moment.utc(this.state.dateDue).format('DD MMM YYYY')
        }

    }

    addLineItem = () => {
        if (this.state.newQty <= 0) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Quantity Cannot Be Zero", type: "error" } });
        } else if (parseInt(this.state.newRate, 10) === 0) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Rate Cannot Be Zero", type: "error" } });
        } else {
            const { lineItems, descriptions } = JSON.parse(JSON.stringify(this.state));
            lineItems.push({
                descriptionId: this.state.newDescription,
                quantity: this.state.newQty || 0,
                rate: this.state.newRate || 0,
                cost: this.state.newCost || 0,
            })
            let moved = descriptions.splice(descriptions.findIndex(i => i.id === parseInt(this.state.newDescription)), 1)
            descriptions.push(moved[0])

            this.setState({ lineItems, newQty: '', newRate: '', newCost: '', descriptions }, () => this.setState({ newDescription: descriptions[0].id }, this.focusNewDescription))
        }
    }

    checkForContainerCommissionFee = () => {
        return new Promise((resolve, reject) => {
            let shipperConsigneePairs = this.state.shipperConsigneePairs;
            let shipperId = this.state.shipment.shipperId;
            let carrierId = this.state.shipment.carrierId;
            if (shipperConsigneePairs.filter(sc => sc.carrierId === carrierId && sc.shipperId === shipperId)[0]) {
                let lineItems = this.state.lineItems;
                //THIS NEEDS TO BE FIXED -> NEED A DYNAMIC WAY OF CHECKING FOR THESE FEES
                if (lineItems.filter(li => parseInt(li.descriptionId) === 7)[0]) {
                    let lineItem = lineItems.filter(li => parseInt(li.descriptionId) === 7)[0];
                    let quantity = this.state.shipment.containerCount;
                    if (parseInt(lineItem.quantity) === quantity) {
                        resolve(true);
                    } else {
                        if (window.confirm("The listed Container Commission Replacement Fee quantity does not equal the booking container count. Are you sure you would like to save?")) {
                            resolve(true)
                        } else {
                            resolve(false);
                        }
                    }
                } else {
                    if (window.confirm("There is no Container Commission Replacement Fee attached to this Invoice. Are you sure you would like to save?")) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            } else {
                resolve(true);
            }
        })
    }

    getTotalInvoiceAmount = () => {
        let sum = 0;
        this.state.lineItems.forEach(i => {
            let amt = (i.quantity * i.rate);
            sum += amt;
        })
        return sum.toFixed(2);
    }

    focusNewDescription = () => setTimeout(() => document.getElementById('newDescription').focus(), 50);

    saveAndEmail = async () => {
        if (!this.state.invoiceParty || this.state.invoiceParty === '0')
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'You must select a Bill To party', type: 'error' } })

        const saveResult = await this.saveInvoice();
        await this.getEmailsPromise(this.state.invoiceParty);
        if (saveResult.errno || saveResult === "NOT AUTHENTICATED") {
            return;
        }
        this.showEmailModal();
    }

    saveCommission = () => {
        this.setState({ saving: true }, () => {
            Axios.post('/api/v1/invoice/commission', {
                ...auth.getAuthData(),
                id: this.state.invoiceId,
                shipmentId: this.state.shipment.id,
                rate: this.state.commissionRate,
                percentage: this.state.commissionPercentage,
                status: this.state.commissionPercentage === 0 || this.state.commissionPercentage === "0" || !this.state.commissionPercentage ? 'NO COMMISSION DUE'
                    : this.state.commissionStatus,
                checkNumber: this.state.commissionCheckNumber,
                transactionNumber: this.state.commissionTransactionNumber,
                paymentDate: this.state.commissionPaymentDate,
                commissionAmountReceived: (this.state.commissionAmountReceived.toString()).split(",").join(),
                usePercentage: this.state.usePercentage,
                archive: (this.state.commissionStatus === "PAID" || this.state.commissionStatus === "NO COMMISSION DUE") && this.state.status === "PAID" ? 1 : 0
            })
                .then(async result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' });
                        return;
                    }

                    if (result.data.errno) {
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `An error ocurred. Record not saved: ${result.data.errno}`, type: 'error' } });
                        this.setState({ saving: false });
                    } else {
                        this.setCommissionPaymentNumbers();
                        await this.getInvoice();
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Commission saved to database. ID: ${result.data.insertId}`, type: 'success' } })
                        this.setState({ tabIndex: 1, newCommission: false, saving: false }, () => document.querySelector('#invoiceParty')?.focus());
                    }
                })
                .catch(err => console.log(err));
        })
    }

    saveInvoice = async () => new Promise((resolve, reject) => {
        if (!this.state.invoiceParty || this.state.invoiceParty === '0')
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'You must select a Bill To party.', type: 'error' } })

        this.setState({ saving: true }, async () => {

            let continueSave = await this.checkForContainerCommissionFee().catch(err => { if (err) { console.log(err) } });

            if (!continueSave) {
                this.setState({ saving: false });
                return;
            }

            let saveResult = await new Promise((resolve, reject) => {
                Axios.post('/api/v1/invoice', {
                    ...auth.getAuthData(),
                    id: parseInt(this.state.invoiceId),
                    shipmentId: this.state.shipment.id,
                    invoiceParty: this.state.invoiceParty,
                    invoiceNumber: this.state.invoiceNumber,
                    additionalInfo: this.state.additionalInfo,
                    terms: this.state.terms,
                    noteToShipper: this.state.invoiceNote,
                    internalNote: this.state.internalNote,
                    status: this.state.status,
                    checkNumber: this.state.invoiceCheckNumber,
                    transactionNumber: this.state.invoiceTransactionNumber,
                    amountReceived: (this.state.amountReceived.toString()).split(",").join(""),
                    lineItems: this.state.lineItems,
                    paymentDate: this.state.invoicePaymentDate,
                    archive: (this.state.commissionStatus === "PAID" || this.state.commissionStatus === "NO COMMISSION DUE") && this.state.status === "PAID" ? 1 : 0
                })
                    .then(async result => {
                        if (result.data === 'NOT AUTHENTICATED') {
                            localStorage.clear();
                            this.props.dispatch({ type: 'SCORCHED_EARTH' });
                            return;
                        }
                        if (result.data.errno) {
                            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `An error ocurred. Record not saved: ${result.data.errno}`, type: 'error' } });
                            // this.setState({ saving: false });
                            reject(result.data, this.setState({ saving: false }))
                        } else {
                            this.setInvoicePaymentNumbers();
                            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Invoice saved to database`, type: 'success' } })
                            this.setState({ tabIndex: 1, invoiceId: result.data.invoiceId, invoiceNumber: result.data.invoiceNumber, saving: false }, async () => {
                                document.querySelector('#invoiceParty')?.focus()
                                this.state.containerCount ? await this.getInvoice() : await this.getInvoiceDetails();
                                resolve(result.data);
                            })
                        }
                    });
            }).catch(err => resolve(err));
            resolve(saveResult);
        })

    })

    printCommission = () => this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'PSYCH! This button does nothing!' } })

    printInvoice = () => Axios.post('/api/v1/pages/singleinvoice', {
        ...auth.getAuthData(),
        datetime: moment(new Date()).format('MMDDYY_HHmmss'),
        fileType: 'singleInvoice',
        bookingId: this.state.shipment.bookingId,
        lNumber: this.state.searchTerm ? this.state.searchTerm : null,
        invoiceNumber: this.state.invoiceNumber,
        user: this.props.user.id
    }).then(result => {
        if (result.data === 'NOT AUTHENTICATED') {
            localStorage.clear();
            this.props.dispatch({ type: 'SCORCHED_EARTH' })
            return;
        }

        if (!result.data.emptyRes) {
            const win = window.open('', '', 'width=960 height=1080');
            if (win === null) {
                return;
            }
            win.document.write(result.data);
        } else {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG, payload: { msg: "Cannot Print Empty Invoice", type: "error" }
            })
        }
        if (!this.state.lNumber) {
            this.getInvoiceDetails()
        }
        else {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Document Filed.', type: "success" } })
            this.getInvoice();
        }
    })



    saveAndPrintInvoice = async () => {
        if (!this.state.invoiceParty || this.state.invoiceParty === '0')
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'You must select a Bill To party', type: 'error' } })

        this.setState({ saving: true }, async () => {

            let continueSave = await this.checkForContainerCommissionFee().catch(err => { if (err) { console.log(err) } });

            if (!continueSave) {
                this.setState({ saving: false });
                return;
            }

            await Axios.post('/api/v1/invoice', {
                ...auth.getAuthData(),
                id: parseInt(this.state.invoiceId),
                shipmentId: this.state.shipment.id,
                invoiceParty: this.state.invoiceParty,
                invoiceNumber: this.state.invoiceNumber,
                additionalInfo: this.state.additionalInfo,
                terms: this.state.terms,
                noteToShipper: this.state.invoiceNote,
                internalNote: this.state.internalNote,
                status: this.state.status,
                checkNumber: this.state.invoiceCheckNumber,
                transactionNumber: this.state.invoiceTransactionNumber,
                amountReceived: (this.state.amountReceived.toString()).split(",").join(),
                lineItems: this.state.lineItems,
                paymentDate: this.state.invoicePaymentDate,
                archive: (this.state.commissionStatus === "PAID" || this.state.commissionStatus === "NO COMMISSION DUE") && this.state.status === "PAID" ? 1 : 0
            })
                .then(result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' });
                        return;
                    }
                    if (result.data.errno) {
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `An error ocurred. Record not saved: ${result.data.errno}`, type: 'error' } });
                        this.setState({ saving: false })
                    }
                    else {
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Invoice saved to database`, type: 'success' } })
                        this.setState({ tabIndex: 1, invoiceId: result.data.invoiceId, invoiceNumber: result.data.invoiceNumber, saving: false }, () => {
                            document.querySelector('#invoiceParty')?.focus()
                            this.state.containerCount ? this.getInvoice() : this.getInvoiceDetails(); this.printInvoice();
                        })
                    }
                });
        })

    }

    saveAndPrintCommission = () => {
        Axios.post('/api/v1/invoice/commission', {
            ...auth.getAuthData(),
            id: (this.state.commissionAmountReceived.toString()).split(",").join(),
            shipmentId: this.state.shipment.id,
            rate: this.state.commissionRate,
            percentage: this.state.commissionPercentage,
            status: this.state.commissionPercentage === 0 || this.state.commissionPercentage === "0" || !this.state.commissionPercentage ? 'NO COMMISSION DUE'
                : this.state.commissionStatus,
            checkNumber: this.state.commissionCheckNumber,
            transactionNumber: this.state.commissionTransactionNumber,
            commissionAmountReceived: (this.state.commissionAmountReceived.toString()).split(",").join(),
            paymentDate: this.state.commissionPaymentDate,
            usePercentage: this.state.usePercentage,
            archive: (this.state.commissionStatus === "PAID" || this.state.commissionStatus === "NO COMMISSION DUE") && this.state.status === "PAID" ? 1 : 0
        })
            .then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' });
                    return;
                }

                if (result.data.errno)
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `An error ocurred. Record not saved: ${result.data.errno}`, type: 'error' } })
                else {
                    this.getInvoice();
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Commission saved to database. ID: ${result.data.insertId}`, type: 'success' } })
                    this.setState({ tabIndex: 1 }, () => document.querySelector('#invoiceParty')?.focus());
                    this.printCommission();
                }

            });
    }

    sendEmail = (emailInfoFromState, fileInfo) => {


        let buffer = new Buffer.from(emailInfoFromState.pdf, 'base64');
        let datetime = moment(new Date()).format('MMDDYY_HHmmss');
        let userName = `${this.props.user.first.charAt(0)}${this.props.user.first.substring(1).toLowerCase()} ${this.props.user.last.charAt(0)}${this.props.user.last.substring(1).toLowerCase()}`;
        delete emailInfoFromState.pdf;
        this.hideEmailModal();

        Axios.post("/api/v1/email/", {
            ...auth.getAuthData(),
            buffer,
            fileInfo,
            datetime,
            email: emailInfoFromState,
            userName,
            usePdf: true
        }).then(async (result) => {

            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear()
                this.props.dispatch({
                    type: "SCORCHED EARTH"
                })
                return;
            }
            if (result.data.messageSent) {
                this.setState({ generatePdf: false, emailSubject: "" });
                if (this.state.shipment.lNumber) {
                    await this.getInvoice();
                } else {
                    await this.getInvoiceDetails();
                }

                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "success", msg: "E-Mail Sent"
                    }
                });
                if (!this.state.invoiceNumber) {
                    let datetime = moment(new Date()).format('MMDDYY_HHmmss');
                    await fileUploader.getUploadURLBuffer('singleInvoice', datetime, buffer, this.state.shipment.lNumber, '', this.state.shipment.bookingId, auth.getAuthData());
                }

            } else {
                this.setState({ generatePdf: false });
                window.alert("Error: Email was not sent.");
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "error", msg: "E-Mail Was Not Sent"
                    }
                });
            }
        })
    }

    deleteInvoice = () => {
        Axios.post("/api/v1/invoice/delete", {
            ...auth.getAuthData(),
            invoiceId: this.state.invoiceId
        })
            .then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                } else if (result.data) {
                    this.props.dispatch({
                        type: globalToastActions.UPDATE_MSG, payload: {
                            msg: "Invoice Deleted", type: "success"
                        }
                    });
                    this.setState({ ...this.clearState() });
                } else {
                    this.props.dispatch({
                        type: globalToastActions.UPDATE_MSG, payload: {
                            msg: "Error Deleting Invoice", type: "error"
                        }
                    })
                }
            })
    }

    toggleCommissionFields = () => {
        this.setState({
            enterCommission: !this.state.enterCommission,
            commissionPercentage: 0
        }, () => this.setState({ commissionStatus: this.state.enterCommission ? "NOT PAID" : "NO COMMISSION DUE" }));
    }


    deleteLineItem = index => {
        const lineItems = JSON.parse(JSON.stringify(this.state.lineItems));
        lineItems.splice(index, 1);
        this.setState({ lineItems })
    }

    getDescription = id => {
        const description = this.state.descriptions.filter(d => d.id === parseInt(id))[0];
        return description ? description.description : 'ERROR LOADING DESCRIPTION'
    }

    closeShipment = () => Axios.post('/api/v1/booking/updatestatus', {
        ...auth.getAuthData(),
        status: 'CLOSED',
        id: this.state.shipment.bookingId
    }).then(result => {
        if (result.data === 'NOT AUTHENTICATED') {
            localStorage.clear();
            this.props.dispatch({ type: 'SCORCHED_EARTH' });
            return;
        }

        if (result.data.errno)
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `An error occurred: ${result.data.errno}`, type: 'error' } })
        else {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Shipment closed.' } })
            this.getInvoice();
        }
    })

    reopenShipment = () => Axios.post('/api/v1/booking/updatestatus', {
        ...auth.getAuthData(),
        status: 'ACTIVE',
        id: this.state.shipment.bookingId
    }).then(result => {
        if (result.data === 'NOT AUTHENTICATED') {
            localStorage.clear();
            this.props.dispatch({ type: 'SCORCHED_EARTH' });
            return;
        }

        if (result.data.errno)
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `An error occurred: ${result.data.errno}`, type: 'error' } })
        else {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Shipment reopened.' } })
            this.getInvoice();
        }
    })

    renderTabSwitch = () => {
        switch (this.state.tabIndex) {
            case 0:
                return <div>
                    <div className="row ">
                        {this.state.commissionStatus === "PAID" ? <div className="cancelStamp" unselectable="on">PAID</div> : null}
                        <Form.NumberInput col="s2" name="containerCount" label="Container Count" value={this.state.containerCount} onChange={this.onChange} />
                        <Form.NumberInput float col="s2" name="commissionRate" label="Freight Rate per Can" value={this.state.commissionRate} onChange={this.onChange} />
                        <Form.TextInput col="s2" name="totalFreight" label="Total Freight" value={this.getTotalFreight()} onChange={this.onChange} disabled />
                    </div>
                    <div className={`row ${this.state.enterCommission ? 'customMarginRemove' : null}`}>
                        <Form.Button col="col s2" icon={this.state.enterCommission ? `expand_less` : `expand_more`} label="Enter Commission" onClick={this.toggleCommissionFields} type="outline" color={this.state.enterCommission ? `specialGray` : `filledGray`} />
                        {this.state.enterCommission ?
                            <Form.Switch col="s2" name="usePercentage" label="Use Percentage" value={this.state.usePercentage} onChange={this.onChange} />
                            : null}
                    </div>
                    {this.state.enterCommission ?

                        <div className="row">
                            {this.state.usePercentage ?
                                <div className="row">
                                    <Form.NumberInput float col="s2" name="commissionPercentage" label="Commission %" value={this.state.commissionPercentage} onChange={this.onChange} />
                                    <Form.TextInput col="s2" label="Total Commission" value={this.getTotalCommission()} disabled />
                                </div>
                                :
                                <div className="row">
                                    <Form.NumberInput float col="s2" name="commissionPercentage" label="Commission Amount" value={this.state.commissionPercentage} onChange={this.onChange} />
                                    <Form.TextInput col="s2" label="Total Commission" value={this.getTotalCommission()} disabled />
                                </div>
                            }
                            <Form.Section noShadow label="Payment Details" col="commissionSection">
                                <div className="row">
                                    <Form.TextInput col="s2" name="commissionCheckNumber" label="Check #" value={this.state.commissionCheckNumber} onChange={this.onChange} stylingClass={`${this.checkForRepeatedPaymentInfo('commission', 'checkNumber') ? "checkInRedux" : ""}`} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="s2" name="commissionTransactionNumber" label="Transaction #" value={this.state.commissionTransactionNumber} onChange={this.onChange} stylingClass={`${this.checkForRepeatedPaymentInfo('commission', 'transactionNumber') ? "checkInRedux" : ""}`} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.NumberInput float col="s2" name="commissionAmountReceived" label="Amount Received" value={this.state.commissionAmountReceived} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.DateInput col="s2" name="commissionPaymentDate" label="Payment Date" value={this.state.commissionPaymentDate} onChange={this.onChange} stylingClass={`${this.checkForRepeatedPaymentInfo('commission', 'paymentDate') ? "checkInRedux" : ""}`} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.Select col="s2" name="commissionStatus" label="Payment Status" value={this.state.commissionStatus} onChange={this.onChange} list={this.getCommissionStatusTypes()} filter={{ label: 'label', value: 'label' }} />
                                </div>
                            </Form.Section>


                        </div>

                        :
                        null
                    }
                    <div className="row">

                    </div>
                    <div className="row">
                        {this.state.shipment.id && <Form.Button col="col s1" color="filledGreen" label="Save" icon="save" type="outline" onClick={this.saveCommission} />}
                        {this.state.shipment.id && <Form.Button color="blue" label="Print" icon="print" type="outline" onClick={this.printCommission} />}
                        {this.state.shipment.id && <Form.Button color="blue" type="outline" label="Save & Print" icon="print" onClick={this.saveAndPrintCommission} />}
                    </div>

                </div>
            case 1:
                return <div>
                    {this.state.status === "PAID" ? <div className="cancelStamp" unselectable="on">PAID</div> : null}
                    <div className="row">
                        <p className="col s6">Bill To</p>
                        <p className="col s3">Terms: </p>
                        <Form.Select col="s3" name="terms" value={this.state.terms} onChange={this.onChange} list={this.getTerms()} filter={{ label: 'label', value: 'label' }} />
                        {/* {!this.state.shipment.id ?
                            this.state.invoiceNumber && parseInt(this.state.invoiceParty) !== 0 ?
                                <b className="col s6">
                                    {this.props.party.filter(p => p.id === parseInt(this.state.invoiceParty))[0].name}
                                </b> : */}
                        <Form.Select col="col s6" name="invoiceParty" placeholder="Please Select Party" value={this.state.invoiceParty} onChange={this.onChange} list={this.getParties()} filter={{ label: 'name', value: 'id' }} />
                        {/* <b className="col s6">{this.state.shipment.shipperName}</b>} */}
                        <p className="col s3">Due Date:</p>
                        <b className="col s3">{this.getDueDate()}</b>
                    </div>
                    <div className="row">
                        <table>
                            <thead className={styles.thead}>
                                <tr>
                                    <th>#</th>
                                    <th style={{ width: '60%' }}>Item & Description</th>
                                    <th>Qty</th>
                                    <th>Rate</th>
                                    <th>Cost</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.lineItems.length ? this.state.lineItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{this.getDescription(item.descriptionId)}</td>
                                        <td style={{ textAlign: "center" }}>{parseFloat(item.quantity).toFixed(3)}</td>
                                        <td style={{ textAlign: "center" }}>{parseFloat(item.rate).toFixed(4)}</td>
                                        <td style={{ textAlign: "center", color: "red" }}>{parseFloat(item.cost).toFixed(3)}</td>
                                        <td style={{ textAlign: "center" }}>{(item.quantity * item.rate).toFixed(2)}</td>
                                        <td><i className="material-icons red-text" onClick={() => this.deleteLineItem(index)}>cancel</i></td>
                                    </tr>
                                )) : null}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ textAlign: "center" }}><b>{this.state.lineItems.length ? this.getTotalInvoiceAmount() : ''}</b></td>
                                </tr>
                                <tr style={{ border: 'none', marginBottom: '9px' }}>
                                    <td></td>
                                    <td><Form.Select id="newDescription" name="newDescription" value={this.state.newDescription} onChange={this.onChange} list={this.state.descriptions} filter={{ label: 'description', value: 'id' }} /></td>
                                    <td><Form.NumberInput float name="newQty" value={this.state.newQty} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} /></td>
                                    <td><Form.NumberInput float name="newRate" value={this.state.newRate} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} /></td>
                                    <td><Form.NumberInput float name="newCost" value={this.state.newCost} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} /></td>
                                    <td style={{ position: 'relative', top: '6px' }}><Form.Button col="s12" color="blue" label="Add" type="outline" round onClick={this.addLineItem} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="row">
                            <Form.TextInput col="s12" name="invoiceNote" label="Note to Shipper" value={this.state.invoiceNote} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                        <div style={{ marginTop: '20px', borderTop: '1px dotted gray', paddingTop: '20px' }} className="row">
                            <Form.TextInput col="s8" name="internalNote" label="Internal Note" value={this.state.internalNote} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                        <Form.Section label="Payment Details">
                            <div className="row">
                                <Form.TextInput col="s2" name="invoiceCheckNumber" label="Check #" value={this.state.invoiceCheckNumber} onChange={this.onChange} stylingClass={`${this.checkForRepeatedPaymentInfo('invoice', 'checkNumber') ? "checkInRedux" : ""}`} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2" name="invoiceTransactionNumber" label="Transaction #" value={this.state.invoiceTransactionNumber} onChange={this.onChange} stylingClass={`${this.checkForRepeatedPaymentInfo('invoice', 'transactionNumber') ? "checkInRedux" : ""}`} flags={[flags.ALLOW_EMPTY]} />
                                <Form.DateInput col="s2" name="invoicePaymentDate" label="Payment Date" value={this.state.invoicePaymentDate} onChange={this.onChange} stylingClass={`${this.checkForRepeatedPaymentInfo('invoice', 'paymentDate') ? "checkInRedux" : ""}`} flags={[flags.ALLOW_EMPTY]} />
                            </div>
                            <div className="row">
                                <Form.NumberInput float col="s3" name="amountReceived" label="Amount Received" value={this.state.amountReceived} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Select col="s2" name="status" label="Payment Status" value={this.state.status} onChange={this.onChange} list={this.getStatusTypes()} filter={{ label: 'label', value: 'label' }} />
                            </div>
                        </Form.Section>
                        <div className="row">
                            <div style={{ marginTop: '40px' }} className="row">
                                <Form.Button color="green" type="outline" label="Save" icon="save" disabled={this.state.saving ? true : false} onClick={this.saveInvoice} />
                                <Form.Button color="filledBlue" type="outline" label="Save & Email" disabled={this.state.saving ? true : false} icon="email" onClick={this.saveAndEmail} />
                                <Form.Button color="blue" type="outline" label="Print" icon="print" onClick={this.printInvoice} />
                                <Form.Button color="blue" type="outline" label="Save & Print" disabled={this.state.saving ? true : false} icon="print" onClick={this.saveAndPrintInvoice} />
                                {this.state.invoiceNumber ? <Form.Button color="red" type="outline" label="Delete Invoice" icon="delete" onClick={this.deleteInvoice} /> : null}
                            </div>
                            {this.state.shipment.id ?
                                <div className="row">
                                    {this.state.shipment.status !== 'CLOSED' ? <Form.Button col="col s3" color="filledBlack" label="Close Shipment" icon="archive" type="outline" onClick={this.closeShipment} /> : null}
                                    {this.state.shipment.status === 'CLOSED' ? <Form.Button col="col s3" color="black" label="Reopen Shipment" icon="unarchive" type="outline" onClick={this.reopenShipment} /> : null}
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            case 2:
                return <div id="fileUpload">
                    {this.state.shipment.id ?
                        <FileUpload docsEmails={this.state.emails} fileUploadTabOpen={this.state.fileUploadTabOpen} directory="SHIPMENTS/" bookingId={this.state.shipment.bookingId} lNumber={this.state.shipment.lNumber} bookingNumber={this.state.shipment.bookingNumber} isDisplayed={[true]} />
                        :
                        null}
                </div>
            default:
                break;
        }
    }



    render = () => (
        <Form.Section label={`${this.state.containerCount ? 'Invoicing' : 'Standalone Invoicing'}`}>
            <div className="row">
                {this.state.containerCount ? null : <Form.TextInput col="s2 m2" name="searchField" label="Search" value={this.state.searchField} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />}
                {/* <Form.Button col="col s3" name="extendInvoice" label="Extend Invoice" /> */}
                {/* {this.state.shipment.id ? <h6 id="searchTerm">{this.state.shipment.lNumber}</h6> : null */}
                {/* //  <h5 id="searchTerm">{this.state.id}</h5> */}
                {/* } */}
            </div>
            <Form.Section label={`${this.state.shipment.lNumber ? `Shipment Details - ${this.state.shipment.lNumber}` : `Invoice No. ${this.state.invoiceNumber}`}`}>


                {this.state.containerCount ?
                    <div>

                        <div className="row">
                            <div className="col s2">
                                <p className="col s12">{this.state.shipment.bookingNumber}</p><br></br>
                                <p className="col s12">{this.state.shipment.exporterName}</p>
                                <p className="col s12">{this.state.shipment.carrierName}</p>
                                <p className="col s12">{this.state.shipment.consigneeName}</p>
                            </div>
                            <div className="col s2">
                                <p className="col s12">{this.state.shipment.containerCount}</p>
                                <p className="col s12">{this.state.shipment.plr}</p>
                                <p className="col s12">{this.state.shipment.pld}</p>
                                {this.state.shipment.id ? <p className="col s12">  LOB: {this.state.shipment.ladenOnBoardDate ? moment.utc(this.state.shipment.ladenOnBoardDate).format('MM/DD') : ''}</p> : <p className="col s3"></p>}
                            </div>
                            <div className={`col s4 ${styles.commissionDateAndUsers}`}>
                                <p className="col s12"><strong>C:</strong> {this.state.comCreatedBy} - {this.state.comDateCreated}</p>
                                <p className="col s12"><strong>M:</strong> {this.state.comLastModifiedBy} - {this.state.comDateTimeModified} </p>
                            </div>
                            <div className={`col s4 ${styles.invoiceDateAndUsers}`}>
                                <p className="col s12"><strong>C:</strong> {this.state.createdBy} - {this.state.dateCreated}</p>
                                <p className="col s12"><strong>M:</strong> {this.state.lastModifiedBy} - {this.state.dateTimeModified} </p>
                                <p className="col s12"><strong>P:</strong> {this.state.lastPrintedBy} - {this.state.dateTimePrinted}</p>
                                <p className="col s12"><strong>E:</strong> {this.state.lastSentBy} - {this.state.dateTimeSent}</p>
                            </div>

                        </div>
                        <DocChecklist />
                    </div>

                    :
                    <div className="row">
                        <div className="col s7">
                            <Form.TextArea label="Additional Invoice Information" name="additionalInfo" value={this.state.additionalInfo} col="col s12" height="75px" onChange={this.onChange} />
                        </div>
                        <div className="col s5">
                            <p className="col s12">Created By: {this.state.createdBy} --- {this.state.dateCreated}</p>
                            <p className="col s12">Modified by: {this.state.lastModifiedBy} --- {this.state.dateTimeModified} </p>
                            <p className="col s12">Printed by: {this.state.lastPrintedBy} --- {this.state.dateTimePrinted}</p>
                            <p className="col s12">Emailed by: {this.state.lastSentBy} --- {this.state.dateTimeSent}</p>
                        </div>
                    </div>
                }
            </Form.Section>
            <div className="row">
                <div className="col s12" style={{ marginBottom: '20px' }}>
                    <ul className="tabs">
                        {this.state.shipment.lNumber ? <li className={`tab col s2 ${this.state.tabIndex === 0 ? styles.commissionHighlightedTab : styles.commissionInactiveTab}`} data-location="/invoice/carrier" onClick={this.showCommission}>
                            {/* <button className={!this.state.tabIndex?styles.highlightedTab:styles.inactiveTab}></button> */}
                            Commission</li> : null}

                        <li className={`tab col s2 ${this.state.tabIndex === 1 ? styles.invoiceHighlightedTab : styles.invoiceInactiveTab}`} data-location="/invoice/shipper" onClick={this.showInvoicing}>
                            {/* <button className={this.state.tabIndex?styles.highlightedTab:styles.inactiveTab}></button> */}
                            Invoicing</li>
                        {this.state.shipment.lNumber ?
                            <li id='fileUploadTab' className={`tab col s2 ${this.state.tabIndex === 2 ? styles.filesHighlightedTab : styles.filesInactiveTab}`} onClick={() => this.showFileUpload()}>
                                Files{" "}
                                {this.state.loading && this.props.location?.state?.searchTerm ?
                                    <div className="preloader-wrapper small active fileCountSpinner">
                                        <div className="spinner-layer spinner-green-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div><div className="gap-patch">
                                                <div className="circle"></div>
                                            </div><div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    this.state.shipment.bookingFilesCount
                                }
                            </li> :
                            null}

                    </ul>
                </div>
                {/* {this.getInfoHeader()} */}
                <div>
                    {
                        this.renderTabSwitch()
                    }
                </div>
                <EmailForm
                    onChange={this.onChange}
                    subject={this.state.emailSubject}
                    emails={this.state.emails}
                    generatePdf={this.state.generatePdf}
                    fileType='singleInvoice'
                    fileInfo={{
                        id: this.state.id,
                        fileType: 'singleInvoice',
                        invoiceNumber: this.state.invoiceNumber,
                        lNumber: this.state.shipment.lNumber
                    }}
                    showEmailModal={this.showEmailModal}
                    hideEmailModal={this.hideEmailModal}
                    send={this.sendEmail}
                />
            </div>
        </Form.Section >
    )
}

const mapStateToProps = state => {
    const { urls, index, user, portalUser, party, navs, sessionVariables, shipper } = state;
    const url = urls[index];
    return { url, user, portalUser, party, navs, sessionVariables, shipper }
}

export default connect(mapStateToProps)(withRouter(Invoices));