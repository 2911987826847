import React from 'react';
import Form from "../../Components/NewComponents";
import { flags } from "../../constants";
import "./Forecast.css";
import styles from "./Forecast.module.css";
import TableHeader from "../../Components/TableHeader/TableHeader";

const SingleWeekDisplay = (props) => {
    return (
        <div className={styles.resultsContainer}>

            <div className={styles.weekListContainer}>
                <span className={`col s6 ${styles.weekNumber}`}>Week {props.week}</span>
                <table id="right" className={`${styles.weekListTable}`} data-location="right">
                    <TableHeader
                        trClassName="tableHeaderLeftAlign"
                        headers={[
                            { label: "Assn.", attribute: "assignedTo" },
                            { label: "Shipper", attribute: "shipperName" },
                            { label: "Car.", attribute: "carrierName" },
                            { label: "Cont.", attribute: "containerCount" },
                            { label: "Origin", attribute: "originName" },
                            { label: "Dest", attribute: "destName" },
                            { label: "Service", attribute: "serviceRoute" },
                            { label: "BKG #", attribute: "bookingNumber" },
                            { label: "Vessel", attribute: "vesselName" },
                            { label: "Voy. #", attribute: "voyageNumber" },
                            { label: "Req.", attribute: "lastRequested" },
                            { label: "Bkg Assn.", attribute: "bookingAssignee" },
                            { label: "Status", attribute: "status" },
                            { label: "", attribute: "" }
                        ]}
                        sort={props.sort}
                        filters={props.filters}
                    />
                    <tbody className={`${styles.weekListTbody} week-list-tbody`}>
                        {props.weekList.map((i, index) => (
                            props.editRowIndex === index && props.chosenTable === "right" ?
                                <tr key={i.id} id={`edit-request-${index}`} className={styles.editTableRow} onContextMenu={e => { return }}>
                                    <td>
                                        <Form.RawSelectSmall name={`assignedTo`} col="col s12 weekListAssignedTo" value={props.editRequest.assignedTo} onChange={props.requestOnChange} list={props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.RawSelectSmall name={`shipperId`} col="col s12 editShipperCode" value={props.editRequest.shipperId} onChange={props.requestOnChange} list={props.shipper} filter={{ label: "code", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td >
                                        <Form.RawSelectSmall col="col s9 editCarrierCode" name={`carrierId`} value={props.editRequest.carrierId} onChange={props.requestOnChange} list={props.carrier} filter={{ label: "code", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td className={styles.containerCountAndTypeCol}>
                                        <Form.RawNumberInputSmall col="editContainer" name={`containerCount`} className={styles.containerCountField} value={props.editRequest.containerCount} onChange={props.requestOnChange} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.RawSelectSmall name={`containerTypeId`} col={styles.containerTypeField} value={props.editRequest.containerTypeId} onChange={props.requestOnChange} list={props.containerType} filter={{ label: "code", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.RawSelectSmall name={`originId`} col="col s12 editOriginName" value={props.editRequest.originId} onChange={props.requestOnChange} list={props.origins} filter={{ label: "code", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.RawSelectSmall name={`destId`} col="col s12 editDestName" value={props.editRequest.destId} onChange={props.requestOnChange} list={props.destinations} filter={{ label: "code", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.RawTextInputSmall name={`serviceRoute`} col="col s12 editServiceRoute" value={props.editRequest.serviceRoute} onChange={props.requestOnChange} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.RawTextInputSmall name="bookingNumber" col="col s12 editBookingNumber" value={props.editRequest.bookingNumber} onChange={props.requestOnChange} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.RawAutoCompleteSmall id="vessel-add-autocomplete" add={false} col="s12 editVesselName" name="vesselName" onChange={props.vesselInput} handleSuggestionHighlight={props.autoCompleteListener} value={props.editRequest.vesselName} filterValue="name" data={props.vessels.filter(v => { return v.carrier_id === props.editRequest.carrierId && props.chosenTable === "right" })} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.RawTextInputSmall col="col s12 editVoyageNumber" name={`voyageNumber`} value={props.editRequest.voyageNumber} onChange={props.requestOnChange} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>{i.datetimeLastRequested} {i.lastRequestedBy}</td>
                                    <td>{i.bookingAssignee}</td>
                                    <td>
                                        <Form.RawSelectSmall name={`status`} placeholder={!props.editRequest.id ? " " : null} className={styles[props.editRequest.status.split(".").join("")]} col="col s12 editStatus" value={props.editRequest.status} optionClassName={"statusOption"} onChange={props.requestOnChange} list={props.statusList} filter={{ label: "label", value: "label", className: "className" }} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td className={styles.iconColumn}>
                                        <div className="iconContainer">
                                            <i tabIndex="0" className={`material-icons saveIconButton ${styles.saveIcon}`} onClick={() => props.editRequest.id ? props.makeInactiveAndSave("right") : props.saveUnique(index)}>save</i>
                                        </div>
                                    </td>
                                </tr>
                                :
                                <tr
                                    key={i.id}
                                    tabIndex="0"
                                    data-id={i.id}
                                    data-index={index}
                                    id={`week-list-${index}`}
                                    className={index === props.outlineRequestIndex ? styles.outlineRequestIndex : ""}
                                    onFocus={index === 0 ? props.onFocusReset : function () { }}

                                    onMouseOver={props.handleMouseOver}
                                    onMouseDown={(e) => props.handleMouseDown(e)}>
                                    <td onClick={e => props.makeActive(e, index, ".weekListAssignedTo > select")} onContextMenu={e => { props.handleAssigneeMenu(e) }}>{i.userInitials}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editShipperCode > select")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.shipperCode}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editCarrierCode > select")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.carrierCode}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editContainer > input")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.containerCount}x{i.containerType}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editOriginName > select")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.originCode}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editDestName > select")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.destCode}</td>
                                    <td onClick={(e) => props.goToServiceSchedule(e, index, ".editServiceRoute > input")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.serviceRoute}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editBookingNumber > input")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.bookingNumber}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editVesselName > div > input")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.vesselName}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editVoyageNumber > input")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.voyageNumber}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editBookingNumber > input")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.datetimeLastRequested} {i.lastRequestedBy}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editBookingNumber > input")} onContextMenu={e => { props.handleStatusMenu(e) }}>{i.bookingAssignee}</td>
                                    <td onClick={e => props.makeActive(e, index, ".editStatus > select")} onContextMenu={e => { props.handleStatusMenu(e) }} className={i.status ? styles[i.status.split(".").join("")] : null}>{i.status}</td>
                                    <td onContextMenu={e => { props.handleStatusMenu(e) }} className={styles.iconColumn}>
                                        <div className={`${styles.iconContainer}`}>
                                            <div className={'tooltip'}>
                                                {i.note ? <span className="tooltiptextleft">{i.note}</span> : null}
                                                <i tabIndex="0" className={`material-icons editIcons`} onClick={() => props.openNotesModal(i.id, i.note)}>{i.note.length ? 'chat_bubble' : 'chat_bubble_outline'}</i>
                                            </div>
                                            <i tabIndex="0" className={`material-icons fr-text-${index} editIcons col s6`} onClick={(e) => props.goToOverview(e, index)}>call_made</i>&nbsp;
                                            <i tabIndex="0" style={{ marginTop: "5px" }} className={`material-icons fr-text-${index} editIcons col s5`} onClick={(e) => props.deleteSingleWeekListItem(e, i.id)}>cancel</i>
                                        </div>
                                    </td>
                                </tr>
                        ))}
                    </tbody>
                </table>


            </div>



        </div>
    )
}

export default SingleWeekDisplay;