import React from 'react';
import AutoSuggest from 'react-autosuggest';
import styles from './RawAutoCompleteSmall.module.css';
import { types } from '../../constants';
import { inputValidation } from '../../helperFunctions';

class AutoCompleteSmall extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({ suggestions: [], searchTerm: '', dataInstance: null, err: false, new: false });

    getSuggestions = searchTerm => {
        const data = JSON.parse(JSON.stringify(this.props.data));
        return this.filterData(searchTerm, data);
    }

    filterData = (searchTerm, data) => {
        const inputValue = searchTerm.trim().toLowerCase();
        const inputLength = searchTerm.length;
        if (inputLength < 1)
            return [];

        const arr = data.filter(obj => obj[this.props.filterValue].toLowerCase().slice(0, inputLength) === inputValue);
        return arr;

    }

    getSuggestionValue = suggestion => suggestion[this.props.filterValue];
    renderSuggestion = suggestion => (<div className="auto-complete-suggestion">{suggestion[this.props.filterValue]}</div>)
    handleChange = (e, { newValue }) => {
        const eventType = e.type;
        const eventKey = e.key;

        const selected = this.props.data.filter(v => v[this.props.filterValue] === newValue)[0];
        if (selected) {
            if (eventType === 'click' || eventKey === 'Enter') {
                this.props.onChange(this.props.name, selected[this.props.filterValue], selected);
            }
            return;
        }

        this.props.onChange(this.props.name, newValue);
    }

    handleBlur = (event, suggestion) => {
        const obj = suggestion.highlightedSuggestion;
        const value = obj ? obj[this.props.filterValue] : event.target.value;

        const add = (!obj && event.target.value);

        this.setState({ new: add ? true : false }, () => {
            if (this.state.new && this.props.add)
                document.querySelector(`#${this.props.id}-addnew`).focus();

            this.props.onChange(this.props.name, (typeof value === 'string' ? value.trim().toUpperCase() : value), obj);
            this.verifyInput();
        })
    }

    fetchSuggestions = ({ value }) => this.setState({ suggestions: this.getSuggestions(value) });
    clearSuggestions = () => this.setState({ suggestions: [] });

    getinputProps = () => ({
        value: this.props.value || '',
        onChange: this.handleChange,
        onBlur: this.handleBlur,
        onClick: this.focusHandler,
        id: this.props.name,
        disabled: this.props.disabled,
        placeholder: this.props.placeholder
    })

    getTheme = () => ({
        input: `browser-default ${this.state.err ? styles.err : ''} ${this.props.smallForm ? styles.smallFormInput : ''} ${styles.input} `,
        container: styles.container,
        suggestionsContainerOpen: styles.open,
        suggestionsList: `${styles.list} ${this.props.smallForm ? styles.smallFormList : ''}`,
        suggestion: `${styles.item} ${this.props.smallForm ? styles.smallFormList : ''}`,
        suggestionHighlighted: styles.highlighted
    })

    handleAdd = () => {
        this.props.add();
        this.setState({ new: false })
    }

    verifyInput = () => {
        const arr = [{ name: 'value', type: types.STRING, value: this.props.value, flags: this.props.flags }];
        const success = () => this.setState({ err: false, msg: '' });
        const failure = err => this.setState({ err: true, msg: err.value.msg })
        inputValidation(arr, success, failure)
    }

    render = () => (
        <div className={`${this.props.col ? `col ${this.props.col}` : ''} ${styles.div} `}>
            <AutoSuggest
                theme={this.getTheme()}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.fetchSuggestions}
                onSuggestionsClearRequested={this.clearSuggestions}
                onSuggestionSelected={this.props.handleSuggestionSelection ? this.props.handleSuggestionSelection : null}
                onSuggestionHighlighted={this.props.handleSuggestionHighlight ? this.props.handleSuggestionHighlight : null}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={this.getinputProps()}
                highlightFirstSuggestion={true}
                disabled={this.props.disabled}
                tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"}
                onFocus={this.props.onFocus ? this.props.onFocus : null}
            />
            {this.props.label ?
                <label className={`${styles.label} ${this.props.labelClassName ? this.props.labelClassName : ''}`}>{this.props.label} {this.props.required ? <span style={{ color: "red" }}>*</span> : ''}</label>
                : null}
            <span className={styles.msg}>{this.state.err ? this.state.msg : null}</span>
            {(this.state.new && this.props.add) ?
                <button id={`${this.props.id}-addnew`} onClick={this.handleAdd} tabIndex="0" className={`material-icons ${styles.add}`}>add_box</button>
                : null
            }

        </div>
    )
}

export default AutoCompleteSmall;