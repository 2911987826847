import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { flags } from "../../../constants";
import Form from "../../../Components/NewComponents";


class ModalRatesDisplay extends Component {
    state = {
        origins: [],
        destinations: [],
        portPairs: [],
        originId: "",
        destId: "",
        note: "",
        expirationDate: "",
        editRowIndex: undefined,
        editRowTable: undefined,
        editOriginId: "",
        editDestId: "",
        editNote: "",
        editExpirationDate: ""
    }

    onChange = (name, value) => {
        this.setState({ [name]: value })
    }

    splitLocations = () => {
        let origins = this.props.location.filter(l => (l.countryId === 1 || l.countryId === 17) && l.typeId !== 3);
        let destinations = this.props.location.filter(l => l.countryId !== 1 && l.countryId !== 17 && l.typeId !== 3);
        this.setState({ origins, destinations });
    }


    generatePairMatrix = () => {
        let portPairs = [];
        let origins = this.props.location.filter(l => (l.countryId === 1 || l.countryId === 17) && l.typeId !== 3);
        let destinations = this.props.location.filter(l => (l.country_id !== 1 || l.countryId !== 17) && l.typeId !== 3);
        origins.forEach((o, index) => {
            destinations.forEach((d) => portPairs.push({
                originId: o.id,
                destId: d.id,
                amount: ""
            }))
        });
        this.setState({ portPairs })
    }

    makeActive = (index, table) => {

        let specRest = this.props.contract.specialRestrictions[index];

        this.setState({
            editOriginId: specRest.originId,
            editDestId: specRest.destId,
            editNote: specRest.note,
            editExpirationDate: specRest.expirationDate,
            editRowIndex: index, editRowTable: table
        }, () => {
            let targetRow = document.querySelectorAll(`#${table}>tr`)[index].querySelectorAll("td:not(.material-icons)>div>input, td:not(.material-icons)>div>select");
            targetRow.forEach(r => {
                r.style.color = "blue"
            });
            targetRow[0].focus();
        })
    }

    makeInactive = (index, table) => {

        let specialRestriction = {
            originId: this.state.editOriginId,
            destId: this.state.editDestId,
            note: this.state.editNote,
            originName: this.props.location.filter(l => l.id === parseInt(this.state.editOriginId))[0].name,
            destName: this.props.location.filter(l => l.id === parseInt(this.state.editDestId))[0].name,
            expirationDate: this.state.editExpirationDate
        }

        this.props.specialRestrictionSpliceEdit(specialRestriction, index);

        this.setState({
            editOriginId: "",
            editDestId: "",
            editNote: "",
            editExpirationDate: "",
            editRowIndex: null,
            editRowTable: null
        }, () => {
            let targetRow = document.querySelectorAll(`#${table}>tr`)[index].querySelectorAll("td:not(.material-icons)");
            targetRow.forEach(r => {
                r.style.color = "black"
            });
            if (document.querySelectorAll(`#${table}>tr`)[index + 1]) {
                document.querySelectorAll(`#${table}>tr`)[index + 1].children[4].children[0].focus();
            }
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.origins.length && !this.state.originId) {
            if (this.state.originId !== this.props.origins[0].id) {
                this.setState({ originId: this.props.origins[0].id })
            }
        }
        if (this.props.destinations.length && !this.state.destId) {
            if (this.state.destId !== this.props.destinations[0].id) {
                this.setState({ destId: this.props.destinations[0].id })
            }
        }
    }

    componentDidMount = () => {
        this.splitLocations();
        this.generatePairMatrix();
        if (document.querySelector(".originDropdown")) {
            document.querySelector(".originDropdown").focus();
        }
    }

    componentWillUnmount = () => {
        this.props.checkRatesForNeededFreetime();
    }

    render = () => (
        <div>
            <div className="row">

                <div className={`col s9`}>
                    <h5 style={{ margin: "0px" }}>Rates</h5>
                    <table>
                        <thead>
                            <tr style={{ textAlign: "left" }}>
                                <th></th>
                                {this.props.origins.length ?
                                    this.props.origins.map((o, index) => (
                                        <th key={o.id} style={{ textAlign: "left" }}>{o.name}</th>
                                    ))
                                    :
                                    <th>&nbsp;</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.destinations.map((d, dindex) => (
                                <tr key={d.id}>
                                    <td className="keep-black">{d.name}</td>
                                    {this.props.contract.rates.filter(fr => fr.destId === d.id).map((r, rindex) => (
                                        <td key={rindex}>
                                            <Form.TextInput tabIndex={`2`} name={`amount:${this.props.contract.rates.indexOf(r)}`} value={r.amount} onChange={this.props.ratesOnChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className={`col s3`}>
                        <div className="row modalAddOriginRow">
                            <Form.Select tabIndex="1" col="col s9" label="Origins" className="originDropdown" placeholder="Choose . . ." name="originId" value={this.props.contract.originId} list={this.state.origins} onChange={this.props.onChange} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                            <Form.Button tabIndex="1" col="col s3" label="Add" icon="add" type="outline" color="blue" onClick={() => this.props.addLocation('origins', parseInt(this.props.contract.originId))} />
                        </div>
                </div>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey", height: "53px" }}>
                <div className="col s3">
                        <div className="row">
                            <Form.Select tabIndex="1" col="col s9" label="Destinations" className="destDropdown" placeholder="Choose . . ." name="destId" value={this.props.contract.destId} list={this.state.destinations} onChange={this.props.onChange} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                            <Form.Button tabIndex="1" col="col s3" label="Add" icon="add" type="outline" color="blue" onClick={() => this.props.addLocation('destinations', parseInt(this.props.contract.destId))} />
                        </div>
                </div>
            </div>
            <div className="row">
                <h6>Special Restrictions</h6>
                <Form.Select tabIndex={`3`} col="col s2" label="Origin" name="originId" value={this.state.originId} onChange={this.onChange} list={this.props.location.filter(l => this.props.contract.rates.map(r => r.originId).includes(l.id))} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                <Form.Select tabIndex={`3`} col="col s2" label="Destination" name="destId" value={this.state.destId} onChange={this.onChange} list={this.props.location.filter(l => this.props.contract.rates.map(r => r.destId).includes(l.id))} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput tabIndex={`3`} col="col s4" label="Note" name="note" value={this.state.note} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.DateInput tabIndex={`3`} col="col s2" label="Expiration Date" name="expirationDate" value={this.state.expirationDate} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.Button tabIndex={`3`} col="col s2" label="Add" type="outline" color="blue" onClick={() => this.props.addSpecialRestriction(this.state)} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
                <div className="col s12">
                    <table>
                        <thead>
                            <tr style={{ textAlign: "left" }}>
                                <th>Origin</th>
                                <th>Destination</th>
                                <th>Note</th>
                                <th>Expiration Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody id="specialRestrictions">
                            {this.props.contract.specialRestrictions.map((sr, index) => (
                                <Fragment key={index}>
                                    {this.state.editRowIndex === index ?
                                        <tr key={index}>
                                            <td className="specialRestrictionCol">
                                                <Form.Select tabIndex={`3`} label="Origin" name={`editOriginId`} value={this.state.editOriginId} onChange={this.onChange} list={this.props.location.filter(l => this.props.contract.rates.map(r => r.originId).includes(l.id))} filter={{ label: "name", value: "id" }} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <Form.Select tabIndex={`3`} label="Destination" name={`editDestId`} value={this.state.editDestId} onChange={this.onChange} list={this.props.location.filter(l => this.props.contract.rates.map(r => r.destId).includes(l.id))} filter={{ label: "name", value: "id" }} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <Form.TextInput tabIndex={`3`} label="Note" name={`editNote`} value={this.state.editNote} onChange={this.onChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <Form.DateInput tabIndex={`3`} label="Expiration Date" name={`editExpirationDate`} value={this.state.editExpirationDate} onChange={this.onChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td className="keep-black modalSaveIcon">
                                                <i tabIndex="3" className="material-icons" onClick={() => this.makeInactive(index, "specialRestrictions")}>save</i>
                                            </td>
                                        </tr>
                                        :
                                        <tr key={sr.id}>
                                            <td>{sr.originName}</td>
                                            <td>{sr.destName}</td>
                                            <td className="specialRestrictionCol">{sr.note}</td>
                                            <td>{sr.expirationDate}</td>
                                            <td className="keep-black editAndDeleteIcons">
                                                <i tabIndex={`3`} className="material-icons" onClick={() => this.makeActive(index, "specialRestrictions")}>edit</i>
                                                <i tabIndex={`3`} className="material-icons" onClick={() => this.props.removeSpecialRestriction(index)}>cancel</i></td>
                                        </tr>
                                    }
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    const { urls, index, location } = state;
    const url = urls[index];
    return { url, location }
}


export default connect(mapStateToProps)(ModalRatesDisplay)