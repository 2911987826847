import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../helperFunctions";
import Axios from "axios";
import "./sidebar.css"
import SideBarTab from "./SideBarTab";
import { auth } from "../../helperFunctions";
import GlobalSearch from "./GlobalSearch";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
      carrierList: this.props.url.url.split("/") === "contract" ? this.props.location.state.carrierList : []
    };
  }


  focusMasterSearch = e => {
    if (e.ctrlKey && e.keyCode === 69) {
      e.preventDefault();
      const masterSearch = document.querySelector("#masterSearch");
      masterSearch.select();
    }
  };

  focusMasterSearchClick = e => {
    e.preventDefault();
    const masterSearch = document.querySelector("#masterSearch");
    masterSearch.select();
  };

  getNav = () => this.props.sidebar.navs[this.props.sidebar.index];

  getCustNav = () => this.props.SideBar.custNavs[this.props.sidebar.index];

  adminCheck = () => {
    if (!this.props.user) {
      return;
    } else if (this.props.user.isEmployee || this.props.user.is_employee) {
      if (this.props.user.is_admin || this.props.user.isAdmin) {
        this.setState({
          links: this.getNav().links
        })
      } else if (this.props.user.is_bookkeeper || this.props.user.isBookkeeper) {
        let notAdminLinks = this.getNav().links.filter(link => link.url !== "report");
        this.setState({
          links: notAdminLinks
        })
      } else {
        let notAdminLinks = this.getNav().links.filter(link => link.url !== "invoice" && link.url !== "report");
        this.setState({
          links: notAdminLinks
        })
      }
    } else {
      if ([84, 1011, 319].includes(parseInt(this.props.user.partyId ? this.props.user.partyId : this.props.user.party_id, 10))) {
        this.setState({
          links: this.getCustNav().links
        })
      } else {
        let customerLinks = this.getCustNav().links.filter(link => link.url !== "request")
        this.setState({
          links: customerLinks
        })
      }
    }
  }

  activeLinkSwitch = () => {
    let urlArr = this.props.location.pathname.split("/");
    switch (urlArr[1]) {
      case 'shipment':
        if (urlArr[2]) {
          if (urlArr[2] === 'split') {
            this.setState({ activeLink: '/shipment/split' })
          } else {
            this.setState({ activeLink: '/shipment/create/ ' });
          }
        } else {
          this.setState({ activeLink: '/shipment' });
        }
        break
      case 'party':
        this.setState({ activeLink: "/party" })
        break;
      case 'carrier':
        this.setState({ activeLink: "/carrier" });
        break;
      case 'location':
        this.setState({ activeLink: "/location" });
        break;
      case 'contract':
        this.setState({ activeLink: "/contract" });
        break;
      case 'service':
        this.setState({ activeLink: "/service/" });
        break;
      case 'report':
        this.setState({ activeLink: "/report" });
        break;
      case 'forecast':
        this.setState({ activeLink: this.props.location.pathname })
        break;
      default:
        this.setState({ activeLink: this.props.location.pathname });
        break;
    }
  }

  componentDidMount = () => {
    this.adminCheck();
    this.activeLinkSwitch();
  };

  componentDidUpdate = (prevProps) => {

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.activeLinkSwitch();
    }

    if (this.props.location?.state?.searchTerm !== prevProps.location?.state?.searchTerm && this.props.location?.state?.searchTerm) {
      this.setState({ masterSearch: this.props.location?.state?.searchTerm })
    }

    if (prevProps.user !== this.props.user) {

      this.adminCheck();
    }
  }

  goToCarrierContract = (c, state) => {

    if (c.carrierName === "NO OTHER CONTRACTS") {
      return;
    }

    Axios.get("/api/v1/contract/carriers", { params: { ...auth.getAuthData(), shipperId: state.shipperId, carrierId: c.carrierId, contractId: c.id, commodityId: c.commodityId, containerTypeId: c.containerTypeId } }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      }
      let contracts = result.data.length ? result.data : [{ carrierName: "NO OTHER CONTRACTS" }];

      this.props.navigate("/contract/edit", {
        state: {
          origin: "contractOverview",
          contractSearchTerm: c.id,
          shipperId: state.shipperId,
          shipperName: state.shipperName,
          filters: this.props.filters,
          carrierId: c.carrierId,
          carrierName: c.carrierDisplayName,
          contracts
        }
      })
    })
  }

  render = () => {
    return this.props.hideSideBar ?
      null
      :
      <div id="lf-sidebar-nav" className={`sidebar ${this.props.hideSideBar ? 'hide' : ''}`}>
        <ul className="collection with-header sidebar-collection" style={{ overflow: 'auto' }}>
          <div className="search-wrapper row">
            {this.props.user && (this.props.user.is_employee || this.props.user.isEmployee) ? <GlobalSearch /> : null}
          </div>
          {this.state.links?.map(link => <SideBarTab key={link.url} linkName={link.name} url={link.url} />)}
          {this.props.location?.state?.contracts ?
            <ul style={{ overflowY: "auto", maxHeight: "175px" }}>{this.props.location?.state?.contracts[0].shipperCode} Contracts:
              {this.props.location?.state?.contracts?.map((c, index) => (

                <li
                  key={index}
                  className="carrierListTabs"
                  style={c.activeLineage ? { color: "white", backgroundColor: "#33679e" } : {}}
                  onClick={c.activeLineage ? null : () => this.goToCarrierContract(c, this.props.location.state)}
                >
                  {c.carrierName} {c.displayCommodityType ? ` - ${c.commodity}` : ""} {c.displayContainerType ? ` - ${c.containerType}` : ""}
                  &nbsp;{c.overlapContracts > 1 ? `(${c.overlapContracts})` : ``}
                </li>
              ))}
            </ul>
            : null}
        </ul>
      </div>
  }
}

const mapStateToProps = state => {
  const { navs, index, sidebars, urls, user } = state;
  const url = urls[index];
  return { sidebar: sidebars[index], url, user, navs };
};

export default connect(mapStateToProps)(withRouter(SideBar));
