import { Routes, Route } from 'react-router-dom';
import ShipmentOverview from './ShipmentOverview/Overview';
import UpdateShipment from './UpdateShipment/UpdateShipment';

const NewRouter = () => {
    return (
        <Routes>
            <Route path="shipmentoverview" element={<ShipmentOverview />} />
            <Route path="shipmentoverview/:hash" element={<ShipmentOverview />} />
            <Route path="updateshipment/:lNumber/*" element={<UpdateShipment />} />
        </Routes>
    )
}

export default NewRouter;