import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { globalToastActions } from "../../Redux/actions";
import moment from "moment-timezone";
import axios from "axios";
import { auth } from "../../helperFunctions";
import styles from "./Documentation.module.css";

class DocumentationResultsDisplay extends Component {
    state = {
        showContextMenu: false,
        deleteShipment: null,
        loading: true
    }

    onChange = (name, value) => {
        this.setState({ [name]: value })
    };

    textOnChange = (name, value) => {

        let shipments = this.props.shipments;

        let index = name.split(":")[1];
        let fieldName = name.split(":")[0];
        shipments[index][fieldName] = value;
        this.setState({ shipments: { ...this.props.shipments, shipments } })
    }

    checkBoxOnChange = (name, value) => {
        let shipments = this.props.shipments;
        let index = name.split(":")[1];
        let fieldName = name.split(":")[0];
        shipments[index][fieldName] = value;
        let updatedShipment = shipments[index];

        if (fieldName === "useNotes" && value === false) {
            return;
        }

        // CONVERT TO UTC WHEN BEING ENTERED INTO DB
        if (value === true && (fieldName === "aesProofed" || fieldName === "blProofed" || fieldName === "phytoOrdered" || fieldName === "phytoProofed" || fieldName === "ratedProofSent" || fieldName === "aesVerified")) {
            shipments[index][`${fieldName}User`] = this.props.user.id;
            shipments[index][`${fieldName}Date`] = moment(new Date()).format("YYYY-MM-DD h:mm:ss");
        } else if (value === false && (fieldName === "aesProofed" || fieldName === "blProofed" || fieldName === "phytoOrdered" || fieldName === "phytoProofed" || fieldName === "ratedProofSent" || fieldName === "aesVerified")) {
            shipments[index][`${fieldName}User`] = null
            shipments[index][`${fieldName}Date`] = null
        }

        updatedShipment.cust1Date = updatedShipment.cust1Date ? moment(updatedShipment.cust1Date, "MM/DD/YY").format("YYYY-MM-DD") : null;
        updatedShipment.cust2Date = updatedShipment.cust2Date ? moment(updatedShipment.cust2Date, "MM/DD/YY").format("YYYY-MM-DD") : null;
        updatedShipment.cust3Date = updatedShipment.cust3Date ? moment(updatedShipment.cust3Date, "MM/DD/YY").format("YYYY-MM-DD") : null;

        axios.post("/api/v1/documentation/checklist", { ...auth.getAuthData(), updatedShipment }).then(result => {
            this.props.getShipments();
        })
    }

    useNotes = () => {
    }

    saveStagesText = (index) => {
        let shipments = this.props.shipments;
        let updatedShipment = shipments[index];
        shipments[index].cust1Date = shipments[index].cust1Date ? moment(shipments[index].cust1Date, "MM/DD/YY").format("YYYY-MM-DD") : null;
        shipments[index].cust2Date = shipments[index].cust2Date ? moment(shipments[index].cust2Date, "MM/DD/YY").format("YYYY-MM-DD") : null;
        shipments[index].cust3Date = shipments[index].cust3Date ? moment(shipments[index].cust3Date, "MM/DD/YY").format("YYYY-MM-DD") : null;
        axios.post("/api/v1/documentation/checklist", { ...auth.getAuthData(), updatedShipment }).then(result => {
            this.props.getShipments();
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Text Saved", type: "success" } })
        })
    }

    initListeners = () => {
        document.addEventListener("keyup", this.bookingNumberSelect);
    }

    removeListeners = () => {
        document.removeEventListener("keyup", this.bookingNumberSelect);
    }

    handleMouseOver = (e) => {
        if (this.props.isMouseDown && e.target.parentElement.tagName === "TR") {
            e.target.parentElement.classList.add('menu-selected');
        }
    }

    handleMouseDown = (e) => {
        if (e.ctrlKey && e.buttons === 1 && e.target.parentElement.tagName === "TR") {
            let list = []
            e.target.parentElement.classList.forEach(c => list.push(c))
            if (list.includes("menu-selected")) {
                e.target.parentElement.classList.remove("menu-selected");
            } else {
                e.target.parentElement.classList.add("menu-selected");
            }
        } else if (e.ctrlKey && e.buttons === 1 && e.target.parentElement.tagName === "TD") {
            let list = []
            e.target.parentElement.parentElement.classList.forEach(c => list.push(c))
            if (list.includes("menu-selected")) {
                e.target.parentElement.parentElement.classList.remove("menu-selected");
            } else {
                e.target.parentElement.parentElement.classList.add("menu-selected");
            }
        } else {
            return;
        }
    }

    bookingNumberSelect = (e) => {
        if (e.key === "Shift" && document.getSelection().type === "Range") {
            let highlightedItems = [];
            let nodes = document.querySelectorAll(".bookingNum");
            nodes.forEach(n => {
                let word = n.textContent;
                let regex = new RegExp(word, 'gm');
                if (regex.test(document.getSelection().toString())) {
                    highlightedItems.push(n.textContent);
                }
            })
            navigator.clipboard.writeText(highlightedItems.join('\n'));
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Copied To Clipboard" } });
        }
    }


    goToBooking = (e, bkg) => {
        if (e.ctrlKey) {
            return;
        } else {
            this.props.navigate(`/shipment/create/${bkg.bookingNumber.split(' / ')[0]}`, {
                state: {
                    origin: "document",
                    searchTerm: bkg.bookingNumber.split(' / ')[0],
                    carrierId: bkg.carrierId,
                    shipperId: bkg.shipperId,
                    filters: this.props.filters,
                    status: bkg.status,
                    stage: {
                        unformattedDocCutDate: bkg.unformattedDocCutDate,
                        aes: bkg.aes,
                        datetimePrinted: bkg.datetimePrinted,
                        invoiceStatus: bkg.invoiceStatus,
                        isDummy: bkg.isDummy
                    }
                }
            })
        }
    }


    generateBadge = status => {
        switch (status) {
            case 'ACTIVE':
                return <span id="activeBadge" className="badge">ACTV</span>
            case 'CANCELLED':
                return <span id="cancelBadge" className="new red badge">CNCL</span>
            case 'CLOSED':
                return <span id="closedBadge" className="new badge">CLSD</span>
            case 'ARCHIVED':
                return <span id="archiveBadge" className="new black badge">ACVD</span>
            default:
                return;
        }
    }

    generateStage = (docCutDate, aes, SIPrinted, LOB, invoice, dummy, dummySI) => {

        let date = moment().format("YYYY-MM-DD HH:mm:ss");

        if (invoice !== null) {
            return <span className="material-icons" style={dummy ? { color: "#9c27b0" } : { "color": "green" }}>attach_money</span>
        }
        else if (SIPrinted !== null && SIPrinted !== undefined && SIPrinted.length) {
            if (dummySI) {
                return <span className="material-icons">assignment_ind</span>
            }
            return <span className="material-icons">assignment_turned_in</span>
        }
        else if (moment(date).isAfter(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"))) {
            return <span className="material-icons" style={dummy ? { color: "#9c27b0" } : { "color": "red" }}>assignment_late</span>
        }
        else if (moment(date).isSame(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"), "D")) {
            return <span className="material-icons">history</span>
        } else {
            return <span className="material-icons"></span>
        }
    }

    checkSubmission = (docCutDate, SIPrinted, dummySI) => {
        let date = moment().format("YYYY-MM-DD HH:mm:ss");

        if (SIPrinted !== null && SIPrinted !== undefined && SIPrinted.length) {
            if (dummySI) {
                return <span className="material-icons">assignment_ind</span>
            }
            return <span className="material-icons">assignment_turned_in</span>
        } else if (moment(date).isAfter(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"))) {
            return <span className="material-icons" style={dummySI ? { color: "#9c27b0" } : { "color": "red" }}>assignment_late</span>
        }
        else if (moment(date).isSame(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"), "D")) {
            return <span className="material-icons">history</span>
        }
    }

    refresh = () => { this.setState({ loading: true }) }

    componentDidMount = () => {
        this.refresh();
        this.initListeners();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.setState({ loading: false })
        }
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => {
        return (
            <Fragment>
                {this.state.loading ?
                    <tr>
                        <td>
                            <div className="preloader-wrapper big active overviewLoader">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    :
                    this.props.shipments.map((s, index) => (
                        <tr
                            className={`resultsDisplay docResults ${index === parseInt(this.props.rowHighlightIndex, 10) ? styles.rowHighlight : '-'}`}
                            id={`bkg-tr-${index}`}
                            tabIndex="0"
                            key={index}
                            data-index={index}
                            style={s.flaggedForRevision ? { color: "red", position: "relative" } : { position: "relative" }}
                            onMouseOver={this.handleMouseOver}
                            onMouseDown={(e) => this.handleMouseDown(e)}
                            onFocus={(e) => { this.props.setIndexOnFocus(e, index) }}
                        >
                            <td className="farLeft unselectable badgeAndIconColumn" onClick={(e) => this.props.handleEdit(e, s)}>
                                {this.generateBadge(s.status)}
                            </td>
                            <td className={`material-icons stageInitials unselectable`} onContextMenu={e => this.props.handleProofMenu(e, index, s)} onClick={(e) => this.props.handleEdit(e, s)}>
                                <span id="initialsBadge" className={`new ${s.proofed ? "proofed" : "notComplete"} badge`}>
                                    {s.proofAssignedTo}
                                </span>
                            </td>
                            <td className="bookingNum" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.bookingNumber}</td>
                            <td className="unselectable" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.shipperName}</td>
                            <td className="unselectable" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.consigneeName}</td>
                            <td className="unselectable" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.carrierName}</td>
                            <td className="unselectable" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.vessel}</td>
                            <td className="unselectable" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.voyageNumber}</td>
                            <td className="unselectable" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.portOfDelivery}</td>
                            <td className="unselectable" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.hsCode}</td>
                            <td className="unselectable" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.etd}</td>
                            <td className="unselectable" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.lobDate}</td>
                            <td className="unselectable" onClick={(e) => this.props.handleEdit(e, s)} onContextMenu={e => this.props.handleContextMenu(e, index, s)}>{s.eta}</td>
                            <td className="material-icons stageInitials unselectable" onContextMenu={e => this.props.handleCloseoutMenu(e, index, s)} onClick={() => this.props.handleEdit(s)}>
                                <span id="closeoutInitialsBadge" className={`new ${s.closedOut ? "proofed" : "notComplete"} badge`}>
                                    {s.closeoutAssignedTo}
                                </span>
                            </td>
                            <td className="badgeAndIconColumn">
                                <i className="material-icons bookingIcon unselectable iconColumn" onClick={(e) => this.props.goToInstructions(e, s)}>description</i>
                            </td>
                        </tr>
                    ))}


            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    const { location, user, urls, index } = state;
    const url = urls[index];
    return { location, user, url }
}

export default connect(mapStateToProps)(DocumentationResultsDisplay)