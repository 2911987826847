import { useEffect, useRef, useState } from "react";
import Axios from 'axios';
import { useLocation } from "react-router-dom";
import { auth, logout } from '../../../helperFunctions';

const DocChecklist = props => {
    const { searchTerm: lNumber } = useLocation()?.state;

    const ref = useRef({
        abortController: new AbortController()
    })

    const [checklist, setChecklist] = useState(null);


    const getCheckList = lNumber => {
        Axios.get('/api/v2/docchecklist/read/one/by/lnumber', {
            params: {
                ...auth.getAuthData(),
                lNumber
            },
            signal: ref.current.abortController.signal
        })
            .then(result => setChecklist(result.data))
            .catch(logout)
    }

    useEffect(() => {
        getCheckList(lNumber);

        return () => ref.current.abortController?.abort();
    }, [])

    return (

        <div className="row" style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            border: '1px solid black',
            padding: '10px',
            borderRadius: '3px',
            fontWeight: '500',
        }}>
            <p style={{ flex: 1 }}>BL: {checklist?.blNote}</p>
            <p style={{ flex: 1 }}>Phyto: {checklist?.phytoNote}</p>
            <p style={{ flex: 1 }}>Fedex: {checklist?.fedexNote}</p>
            {checklist?.fedexNoteTwo ? <p style={{ flex: 1 }}>Fedex(2): {checklist?.fedexNoteTwo}</p> : null}
            {checklist?.fedexNoteThree ? <p style={{ flex: 1 }}>Fedex(3): {checklist?.fedexNoteThree}</p> : null}
            <p style={{ flex: 1 }}>CC: {checklist?.containerCommissionNote}</p>
            {checklist?.bseNote ? <p style={{ flex: 1 }}>BSE: {checklist?.bseNote}</p> : null}
            {checklist?.marineInsuranceNote ? <p style={{ flex: 1 }}>Marine Ins.: {checklist?.marineInsuranceNote}</p> : null}
        </div>
    )
}

export default DocChecklist;