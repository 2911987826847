import { useState } from "react";
import { auth, logout } from '../../../helperFunctions';
import Axios from 'axios';
import M from 'materialize-css';
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import FileTree from "../../../newComponents/FileExplorer/FileTree/FileTree";
const componentId = '_' + crypto.randomUUID();


const FilesTab = props => {
    const {
        getBooking,
        booking,
        folders
    } = props;

    const dispatch = useDispatch();

    const initValues = () => ({
        lNumber: booking.lNumber || '',
        path: '/',
        currentFileName: '',
        rename: '',
        renameType: ''
    })

    const [selectedFiles, setSelectedFiles] = useState([])
    const [values, setValues] = useState(initValues());

    useLayoutEffect(() => {
        document.querySelectorAll(`[id$='${componentId}']`).forEach(el => {
            M.Modal.init(el, {
                onCloseStart: () => {
                    setSelectedFiles([])
                    setValues(initValues());
                }
            })
        })

        return () => {
            const fileModal = document.querySelector(`#move-file-modal-${componentId}`);
            if (fileModal)
                M.Modal.getInstance(fileModal)?.destroy();
        }
    }, [])

    const onChange = ({ target: { id, value } }) => setValues(p => ({ ...p, [id]: value }))

    const previewFile = data => {
        if (!data.folderName)
            Axios.get('/api/v2/file/download/one/by/id', {
                params: {
                    ...auth.getAuthData(),
                    fileId: data.id
                }
            })
                .then(result => {
                    const { buffer, contentType } = result.data;
                    const uint8Array = Uint8Array.from(buffer.data);
                    const blob = new Blob([uint8Array], { type: contentType })
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                })
                .catch(logout)
    }

    const downloadFile = data => {
        if (selectedFiles.length > 1)
            Axios.get('/api/v2/file/download/zip/many/by/id', {
                params: {
                    ...auth.getAuthData(),
                    ids: selectedFiles
                }
            })
                .then(result => {
                    const { filename, buffer } = result.data;
                    const uint8Array = Uint8Array.from(buffer.data);
                    const blob = new Blob([uint8Array], { type: 'application/zip' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.hidden = true;
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(logout);
        else
            Axios.get('/api/v2/file/download/one/by/id', {
                params: {
                    ...auth.getAuthData(),
                    fileId: data.id
                }
            })
                .then(result => {
                    const { filename, fileType, buffer, contentType } = result.data;
                    const uint8Array = Uint8Array.from(buffer.data);
                    const blob = new Blob([uint8Array], { type: contentType })
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.hidden = true;
                    link.href = url;
                    link.setAttribute('download', `${filename}.${fileType}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(logout)
    }

    const renameFile = e => {
        e?.preventDefault();

        const { rename } = values;
        Axios.post('/api/v2/file/update/rename/one/by/id', {
            ...auth.getAuthData(),
            fileId: selectedFiles[0],
            rename
        })
            .then(result => {
                M.Modal.getInstance(document.querySelector(`#rename-file-modal-${componentId}`))?.close();
                dispatch(result.data);
                getBooking();
            })
            .catch(logout)
    }

    const moveFiles = e => {
        e.preventDefault();

        const { lNumber, path } = values;
        Axios.post('/api/v2/file/update/move/many/by/id', {
            ...auth.getAuthData(),
            ids: selectedFiles,
            lNumber,
            path: path === '' || path === '/' ? 'root' : `root/${path.split('/').filter(x => x).join('/')}`
        })
            .then(result => {
                M.Modal.getInstance(document.querySelector(`#move-file-modal-${componentId}`))?.close();
                dispatch(result.data);
                getBooking();
            })
            .catch(logout)
    }

    return (
        <div style={{ height: '100%' }}>
            <div className="row">
                <p><b>{booking.bookingNumbers?.filter(bn => bn.isDisplayed).map(bn => bn.bookingNumber).join(' / ')} / {booking.lNumber}</b></p>
                <p><b>{booking.shipper.name}</b></p>
            </div>
            <div className="row" style={{ height: 'CALC(100% - 40px)' }}>
                <FileTree
                    getBooking={getBooking}
                    booking={booking}
                    folders={folders}
                    directory={'SHIPMENTS/'}
                />
            </div>
            <div id={`move-file-modal-${componentId}`} className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h5>Move {selectedFiles.length ? `${selectedFiles.length} Files` : '1 File'}</h5>
                    </div>
                    <div className="row">
                        <div className="lf-input col s12 m2">
                            <input className="browser-default" id="lNumber" type="text" value={values.lNumber} onChange={onChange} />
                            <label htmlFor="lNumber">L Number</label>
                        </div>
                        <div className="lf-input col s12 m6">
                            <input className="browser-default" id="path" type="text" value={values.path} onChange={onChange} />
                            <label htmlFor="path">Folder Path</label>
                        </div>
                        <div className="lf-input col s12 m2 offset-m2">
                            <a href="/" className="btn btn-small lf-button blue" onClick={moveFiles}>Move File(s)</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id={`rename-file-modal-${componentId}`} className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h5>{values.currentFileName}.{values.renameType}</h5>
                    </div>
                    <div className="row">
                        <div className="lf-input col s11 m6">
                            <input className="browser-default" id="rename" type="text" value={values.rename} onChange={onChange} />
                            <label htmlFor="rename">Rename File</label>
                        </div>
                        <span className="col s1 left" style={{ fontSize: '28px', position: 'relative', right: '16px', bottom: '2px' }}>.{values.renameType}</span>
                        <div className="lf-input col s12 m2 offset-m3">
                            <a href="/" className="btn btn-small lf-button blue" onClick={renameFile}>Rename File</a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default FilesTab;