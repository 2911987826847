import React from 'react';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import styles from './date.module.css';
import { flags } from '../../constants';
import M from 'materialize-css';

class Date extends React.Component {
    constructor(props) { super(props); this.state = { value: '' } }


    genDivClass = () => (`${this.props.col ? `col ${this.props.col}` : ''} ${styles.div} ${this.props.highlight ? 'tooltipped' : ''}`)
    onChange = e => {
        this.setState({ value: e.target.value },
            this.props.onChange(this.props.name, e.target.value));
    }
    onBlur = (e) => {
        const allowEmpty = this.props.flags ? this.props.flags.indexOf(flags.ALLOW_EMPTY) !== 1 : null;
        if (!this.props.value && allowEmpty) {
            this.setState({ err: false, msg: null });
        } else if (moment(this.props.value, 'MM/DD/YY').isValid()) {
            this.setState({ err: false, msg: null })
        } else {
            this.setState({ err: true, msg: 'Invalid Date' });
        }

        this.props.onChange(this.props.name, moment(e.target.value, 'MM/DD/YY').isValid() ? moment(e.target.value, 'MM/DD/YY').format('MM/DD/YY') : '');
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    initTooltip = () => M.Tooltip.init(document.getElementById(`${this.props.name}-outer-div`));
    removeTooltip = () => {
        const tooltip = M.Tooltip.getInstance(document.getElementById(`${this.props.name}-outer-div`))
        if (tooltip)
            tooltip.destroy();
    }

    componentDidUpdate = prevProps => {
        if (prevProps !== this.props) {
            if (this.props.tooltip) {
                this.initTooltip();
            } else {
                this.removeTooltip();
            }
        }
    }

    componentWillUnmount = () => {
        if (this.props.tooltip) {
            this.removeTooltip();
        }
    }

    render = () => (
        <div id={`${this.props.name}-outer-div`} className={this.genDivClass()} data-position="top" data-tooltip={this.props.tooltip}>
            <MaskedInput
                id={this.props.id}
                className={`browser-default ${this.props.stylingClass} ${styles.input} ${this.state.err ? styles.err : ''} ${this.props.highlight ? styles.highlight : ''}`}
                type="text"
                mask={[/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onFocus={this.props.onFocus}
                value={this.props.value}
                placeholder="mm/dd/yy"
                tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"}
                disabled={this.props.disabled}
            />
            <label className={`${styles.label}`}>{this.props.label} {this.props.required ? <span style={{ color: "red" }}>*</span> : ''}</label>
            <span className={styles.msg}>{this.state.msg}</span>
        </div>
    )
}

export default Date;