import React, { Component } from 'react';
import { connect } from 'react-redux'
import { globalToastActions } from "../../Redux/actions";
import Form from "../NewComponents"
import axios from 'axios';
import M from "materialize-css"
import EmailForm from '../../Pages/Shipments/EmailForm/EmailForm';
import { fileUploader } from '../../helperFunctions';
import moment from 'moment-timezone';
import MsgReader from '@kenjiuno/msgreader'
import { auth, withRouter } from "../../helperFunctions";

class FileUpload extends Component {

    state = {
        dragging: false,
        dragCounter: 0,
        files: [],
        file: {},
        inDropZone: false,
        uploading: false,
        showContextMenu: false,
        editRowIndex: undefined,
        editRowId: undefined,
        editing: false,
        oldFileName: '',
        newFileName: '',
        emails: [],
        selectedFiles: [],
        attachments: [],
        searchTermCopy: '',
        searchTermMove: '',
        bookingResultCopy: {},
        bookingResultMove: {},
        viewOldFiles: false,
        pageHeldFiles: [],
        moveFileList: [],
        copyFileList: [],
        displayFileSizeWarning: false,
        sortValue: 'fu.is_hidden',
        reverse: false,
        quotes: [
            'Patience is bitter, but its fruit is sweet.',
            'Genius is eternal patience.',
            'Have patience. All things are difficult before they become easy.',
            "With love and patience, nothing is impossible.",
            'Have patience with all things, but first of all with yourself.',
            'Be patient. Good things take time.'

        ],
        authors: [
            'Jean-Jacques Rousseau',
            'Michaelangelo',
            'Saadi',
            'Daisaku Ikeda',
            'Saint Francis de Sales',
            'Unknown'
        ]
    }

    initListeners = () => {
        document.addEventListener('keydown', this.clearUploadingListener);
    };

    removeListeners = () => {
        document.removeEventListener('keydown', this.clearUploadingListener);
    }

    initModal = () => {
        M.Modal.init(document.querySelectorAll('.modal'));
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    selectedFilesChange = (id) => {
        let selectedFiles = this.state.selectedFiles;
        if (selectedFiles.includes(id)) {
            selectedFiles = selectedFiles.filter(f => f !== id);
            this.setState({ selectedFiles });
        } else {
            selectedFiles.push(id);
            this.setState({ selectedFiles })
        }
    }

    browseFileAdd = (e) => {
        let files = [...e.target.files];
        this.setState({ pageHeldFiles: files }, () => {
            document.activeElement.blur();
        });
    }

    handleDragIn = (e) => {
        e.preventDefault()
        this.setState({ inDropZone: true })
    }

    handleDragOut = (e) => {
        e.preventDefault()
        this.setState({ inDropZone: false })
    }

    handleDrag = (e) => {
        e.preventDefault()
        if (this.state.inDropZone) {
            e.dataTransfer.dropEffect = 'copy';
        } else {
            e.dataTransfer.dropEffect = 'none';
        }
    }

    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (this.state.inDropZone) {
            if (this.state.uploading) {
                return;
            }
            let files = [...e.dataTransfer.files];
            let displayFileSizeWarning = files.some(f => f.size > 500000);
            let displayFileSizeAlert = files.some(f => f.size > 50000000);

            if (displayFileSizeAlert) {
                window.alert("50 Mb file size limit exceeded!");
                return this.setState({ inDropZone: false });
            } else if (files.length) {
                this.setState({ uploading: true, displayFileSizeWarning }, async () => {
                    await this.getUploadURL(files)
                })
            }
        }
        return;
    }

    handleContextMenu = (e, index, editRowId) => {
        e.preventDefault();
        this.setState({ showContextMenu: true, x: e.pageX, y: e.pageY, editRowIndex: index, editRowId }, () => {
            document.addEventListener('click', this.clickListener);
        })
    }

    clickListener = e => {
        this.setState({ showContextMenu: false }, () => { document.removeEventListener('click', this.clickListener) })
    }

    clearUploadingListener = e => {
        if (this.state.uploading) {
            if (e.keyCode === 8) {
                e.preventDefault();
                this.setState({ uploading: false, inDropZone: false });
            }
        }
    }

    openMoveModal = () => {
        M.Modal.getInstance(document.querySelector('.moverModal')).open()
    }

    closeMoveModal = () => {
        M.Modal.getInstance(document.querySelector('.moverModal')).close();
    }

    getBookingFiles = async () => {
        let tz = moment.tz.guess();
        let bookingId = this.props.bookingId;
        let sortValue = this.state.sortValue
        let reverse = this.state.reverse;
        await axios.get('/api/v1/upload/files', { params: { ...auth.getAuthData(), bookingId, tz, sortValue, reverse } }).then(result => {
            let files = this.state.files.map(f => f.id);
            let newFilesList = result.data.map(r => r.id);
            let addedFiles = newFilesList.filter(f => !files.includes(f));

            this.setState({ files: result.data, selectedFiles: [], editRowIndex: undefined, editRowId: undefined }, async () => {
                if (addedFiles.length && files.length) {
                    await addedFiles.forEach(f => {
                        document.getElementById(f.toString()).classList.add('newFile');
                    });
                    setTimeout(async () => {
                        await addedFiles.forEach(f => {
                            document.getElementById(f.toString()).classList.remove('newFile');
                        });
                    }, 2000)
                }
            })
        });
    }


    sort = (sortValue) => {
        this.setState({ reverse: !this.state.reverse, sortValue }, () => { this.getBookingFiles() });
    };

    getUploadURL = async (files) => {

        let fileNameList = this.state.files.map(file => file.fileName);

        if (fileNameList.some(fileName => files.map(f => f.name.split('.')[0]).includes(fileName))) {
            if (files.length > 1) {
                if (!window.confirm("One or more of the files being uploaded is already attached to this booking. Click OK to continue and overwrite the file(s).")) {
                    return this.setState({ inDropZone: false, uploading: false, pageHeldFiles: [] });
                }
            } else {
                if (!window.confirm("This file has already been attached to this booking. Click OK to continue and overwrite the file.")) {
                    return this.setState({ inDropZone: false, uploading: false, pageHeldFiles: [] });
                }
            }
        }

        fileUploader.getUploadURL(files, this.props.lNumber, this.props.bookingId, auth.getAuthData()).then(() => {
            this.setState({ uploading: false, inDropZone: false, pageHeldFiles: [] }, () => {
                this.getBookingFiles();
            })
        }).catch(err => {
            this.setState({ uploading: false, inDropZone: false, pageHeldFiles: [] });
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: err } })
        })

        // files.forEach( async file => {
        //     await axios.post("/api/v1/upload/puturl", {
        //         ...auth.getAuthData(),
        //         lNumber: this.props.lNumber,
        //         fileName: file.name,
        //         contentType: file.type,
        //         file
        //     }).then(result => {
        //         this.upload(result.data, file);
        //     })            
        // })
    }

    upload = async (url, file) => {
        axios.put(url, file, { headers: { 'Content-Type': `${file.type}` } }).then(result => {
            if (result.status === 200 || result.status === 304) {
                this.recordUpload(file)
            } else {
                return;
            }
        })
    }

    recordUpload = (file) => {
        axios.post("/api/v1/upload/recordput", {
            ...auth.getAuthData(),
            bookingId: this.props.bookingId,
            fileName: file.name,
            contentType: file.type,
            directory: "SHIPMENTS/"
        }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else {
                this.setState({ uploading: false, inDropZone: false, pageHeldFiles: [] }, () => {
                    this.getBookingFiles();
                })
            }
        })
    }

    emailFile = () => {

        if (this.state.selectedFiles.length) {
            let selectedFiles = this.state.selectedFiles;
            let attachments = [];
            selectedFiles.forEach(sf => {
                attachments.push(this.state.files.filter(file => file.id === sf)[0]);
            })
            this.setState({ attachments });
            M.Modal.getInstance(document.querySelector('#fileUploadEmailModal')).open();
            document.getElementById("recipient").focus();
            return;
        } else {
            let file = this.state.files.filter(file => file.id === this.state.editRowId);
            this.setState({ attachments: file });
            M.Modal.getInstance(document.querySelector('#fileUploadEmailModal')).open();
            document.getElementById("recipient").focus();
            return;
        }
    }

    unAttachFile = (index, callback, recipientList) => {
        let attachments = JSON.parse(JSON.stringify(this.state.attachments));
        attachments.splice(index, 1);
        this.setState({ attachments }, () => callback(recipientList));
    }

    renameFile = () => {
        let oldFileName = this.state.files[this.state.editRowIndex].fileName;
        this.setState({ editing: true, oldFileName, newFileName: oldFileName }, () => {
            document.getElementById('newFileName').focus();
            document.getElementById('newFileName').addEventListener('keydown', this.saveRenameListener);
        });
    }

    saveRenameListener = (e) => {
        if (e.keyCode === 13 || e.keyCode === 9) {
            e.preventDefault();
            document.getElementById('newFileName').removeEventListener('keydown', this.saveRenameListener);
            this.saveRename();
        } else {
            return;
        }
    }

    saveRename = () => {
        this.setState({ editing: false }, () => {
            let { oldFileName, newFileName, editRowIndex, files } = this.state;
            newFileName = newFileName.normalize("NFD").replace(/\p{Diacritic}/gu, "");
            if (oldFileName === newFileName) {
                return;
            } else if (!newFileName.trim()) {
                window.alert("File name cannot be empty.");
                return;
            } else if (this.state.files.filter(f => f.fileName === newFileName).length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Filename already exists.", type: 'error' } });
                return;
            }
            let objectInfo = {
                id: files[editRowIndex].id,
                lNumber: this.props.lNumber,
                oldFileName,
                newFileName,
                fileType: files[editRowIndex].fileType,
                contentType: files[editRowIndex].contentType,
                directory: this.props.directory
            };
            axios.post('/api/v1/upload/rename', { ...auth.getAuthData(), objectInfo }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                } else if (result.data.error || result.data.code === 403) {
                    this.getBookingFiles();
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Internal Server Error", type: "error" } });
                } else {
                    files[editRowIndex].fileName = newFileName;
                    this.setState({ files });
                }
            })
            return;
        });
    }

    deleteFile = () => {
        let lNumber = this.props.lNumber;
        let fileList;
        if (this.state.selectedFiles.length) {
            if (!window.confirm("Are you sure you would like to delete these files?")) {
                return;
            }
            fileList = this.state.files.filter(file => this.state.selectedFiles.includes(file.id));
        } else {
            if (!window.confirm("Are you sure you would like to delete this file?")) {
                return;
            }
            fileList = [this.state.files[this.state.editRowIndex]];
        }

        axios.post('/api/v1/upload/delete', { ...auth.getAuthData(), lNumber, fileList, directory: this.props.directory }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            };
            this.setState({
                files: this.state.files.filter(file => !this.state.selectedFiles.includes(file.id) && file.id !== this.state.editRowId),
                selectedFiles: [],
                editRowId: undefined,
                editRowIndex: undefined
            });
        })
    }

    moveFile = () => {
        let bookingId = this.state.bookingResultMove.id;
        let lNumber = this.props.lNumber;
        let moveLNumber = this.state.bookingResultMove.lNumber;
        let fileList;

        if (this.state.selectedFiles.length) {
            fileList = this.state.files.filter(file => this.state.selectedFiles.includes(file.id));
            if (this.state.copyFileList.some(f => { return (fileList.map(file => file.fileName)).indexOf(f.fileName) >= 0 })) {
                if (!window.confirm('At least one file being transferred exist on destination booking. Would you like to continue and overwrite these files?')) {
                    return;
                }
            }
        } else {

            fileList = [this.state.files[this.state.editRowIndex]];
            if (this.state.copyFileList.map(file => file.fileName).includes(fileList[0].fileName)) {
                if (!window.confirm("This file exists on the destination booking. Would you like to continue and overwrite the file?")) {
                    return;
                }
            }
        }

        axios.post('/api/v1/upload/move', { ...auth.getAuthData(), bookingId, lNumber, fileList, moveLNumber }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.data.error) {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Internal Server Error", type: 'error' } });
            } else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "File Moved!", type: 'success' } })
                this.getBookingFiles();
                M.Modal.getInstance(document.querySelector('.moverModal')).close();
            }
        });

    }

    copyFile = () => {
        let bookingId = this.state.bookingResultCopy.id;
        let lNumber = this.props.lNumber;
        let copyLNumber = this.state.bookingResultCopy.lNumber;
        let fileList;
        if (this.state.selectedFiles.length) {

            fileList = this.state.files.filter(file => this.state.selectedFiles.includes(file.id));
            if (this.state.copyFileList.some(f => { return (fileList.map(file => file.fileName)).indexOf(f.fileName) >= 0 })) {
                if (!window.confirm('At least one file being transferred exist on destination booking. Would you like to continue and overwrite these files?')) {
                    return;
                }
            }

        } else {

            fileList = [this.state.files[this.state.editRowIndex]];
            if (this.state.copyFileList.map(file => file.fileName).includes(fileList[0].fileName)) {
                if (!window.confirm("This file exists on the destination booking. Would you like to continue and overwrite the file?")) {
                    return;
                }
            }
        }

        axios.post('/api/v1/upload/copy', { ...auth.getAuthData(), bookingId, lNumber, fileList, copyLNumber }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.data.error) {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Internal Server Error", type: "error" } });
            } else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "File Copied!", type: 'success' } })
                this.getBookingFiles();
                M.Modal.getInstance(document.querySelector('.moverModal')).close();
            }
        });
    }

    hide = () => {
        let id;
        if (this.state.selectedFiles.length) {
            id = this.state.selectedFiles;
        } else {
            id = this.state.editRowId;
        }
        axios.get('/api/v1/upload/hide', { params: { ...auth.getAuthData(), id } }).then(result => {
            this.getBookingFiles();
        });
    }

    getPatienceQuote = () => {
        let randomIndex = (Math.floor(Math.random() * this.state.quotes.length) + 0);
        return <div className="quoteContainer">
            <span className="uploadQuote">{this.state.quotes[randomIndex]}</span><br />
            <span className="uploadQuote"> - {this.state.authors[randomIndex]}</span>
        </div>
    }


    searchBooking = (type) => {
        let searchTerm = this.state[`searchTerm${type}`].trim();
        if (this.props.bookingNumber.includes(searchTerm)) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Dest Must Be Different Booking", type: 'warning' } });
            let searchString = `searchTerm${type}`;
            this.setState({ [searchString]: '' });
            return;
        }
        axios.get('/api/v1/upload/search', { params: { ...auth.getAuthData(), searchTerm } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (!result.data.booking.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: 'warning', msg: "NO RESULTS" } })
            } else if (type === "Copy") {
                this.setState({ bookingResultCopy: result.data.booking[0], copyFileList: result.data.files, searchTermCopy: '' });
            } else {
                this.setState({ bookingResultMove: result.data.booking[0], moveFileList: result.data.files, searchTermMove: '' });
            }
        })
    }

    openFileUploadDialogueBox = () => {
        document.getElementById('uploadButton').click();
    }

    openFile = async (id) => {
        if (this.state.editing) {
            return;
        }
        let fileInfo = this.state.files.filter(f => f.id === id)[0];
        await axios.get("/api/v1/upload", {
            params: { ...auth.getAuthData(), fileInfo, lNumber: this.props.lNumber },
            headers: { responseType: 'arraybuffer' }
        }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else if (fileInfo.fileType === 'msg') {
                try {
                    let res = await axios.get(result.data, { responseType: 'arraybuffer' });
                    let msg = new MsgReader((res.data));
                    let msgInfo = msg.getFileData();
                    let win = window.open('', '');
                    let atts = [];
                    msgInfo.attachments.forEach(m => {
                        let att = msg.getAttachment(m);
                        let fileContent = new Blob([att.content])
                        atts.push(`<a download="${att.fileName}" href="${win.URL.createObjectURL(fileContent)}">${att.fileName}</a>`);
                    });

                    let html = (`<html>
                <body>
                    <div><b>Subject: </b><span>${msgInfo.subject}</span><br>
                        <b>Sent: </b><span>${msgInfo.clientSubmitTime}</span><br/>
                        <b>To: </b><span>${msgInfo.recipients.filter(r => r.recipType === 'to').map(r => r.smtpAddress).join(", ")}</span><br/>
                        <b>Cc: </b><span>${msgInfo.recipients.filter(r => r.recipType === 'cc').map(r => r.smtpAddress).join(", ")}</span><br/>
                        <b>Bcc: </b><span>${msgInfo.recipients.filter(r => r.recipType === 'bcc').map(r => r.smtpAddress).join(", ")}</span><br/>
                    </div>
                    <b>Body: </b><br/><div style="white-space: pre">${msgInfo.body ? msgInfo.body : '<span style="font-style: italic">(Empty email body.)</span>'}</div><br/>
                    <b>Download Email: </b><a href="${result.data}">download</a><br/><br/>
                    <b>Attachments: </b><br/>
                ${atts.join("</br>")}
                </body>
                </html>`);
                    win.document.write(html)
                    return;

                } catch (err) {
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Error Opening File", type: "error" } })
                }
            } else {
                window.open(result.data)
            }
        })
    }

    sendEmail = (email) => {

        let attachments = this.state.attachments;
        let bookingNumbers = this.props.bookingNumber;
        let isDisplayed = this.props.isDisplayed;
        let bookingNumber;

        if (this.props.location.pathname === '/shipment/instructions') {
            bookingNumber = bookingNumbers[this.props.bookingNumber.length - 1];
        } else {
            for (let i = bookingNumbers.length - 1; i >= 0; i--) {
                if (isDisplayed[i] === "1") {
                    bookingNumber = bookingNumbers[i];
                    break;
                }
            }
        }

        if (!attachments.length) {
            if (!window.confirm('This email has no attachments. Are you sure you would like to send?')) {
                return;
            } else {
                this.hideEmailFormModal();
            }
        } else {
            this.hideEmailFormModal();
        }

        axios.post('/api/v1/email/file', { ...auth.getAuthData(), attachments, email, bookingNumber }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' });
                return;
            }

            if (result.data.messageSent) {
                this.setState({ attachments: [], selectedFiles: [], emailSubject: "" });
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "success", msg: "E-Mail Sent"
                    }
                })
            } else {
                window.alert("Error: Email was not sent.");
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "error", msg: "E-Mail Was Not Sent"
                    }
                });
            }
        })
    }

    showAllFilesSorted = () => {
        if (!this.state.files.length) {
            return <div className='noFilePlaceholder'>
                No Files Have Been Uploaded For This Shipment
            </div>
        }
        let files = this.state.files;
        return <div className="fileListSection">
            <table id='fileTable'>
                <thead>
                    <tr>
                        <th></th>
                        <th onClick={() => this.sort('fileName')}>File</th>
                        <th onClick={() => this.sort('date')}>Datetime Created</th>
                        <th onClick={() => this.sort('user')}>Uploaded By</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map((m, index) => (
                        <tr key={m.id} id={m.id} className={m.isHidden ? 'hiddenFileRow' : 'activeFileRow'} onContextMenu={(e) => this.handleContextMenu(e, index, m.id)}>
                            <td >
                                <Form.CheckBox name="selectedFiles" value={this.state.selectedFiles.includes(m.id)} onChange={() => this.selectedFilesChange(m.id)} />
                            </td>
                            <td onClick={() => this.openFile(m.id)} >
                                {this.state.editRowIndex === index && this.state.editing ?
                                    <Form.TextInput name="newFileName" value={this.state.newFileName} onChange={this.onChange} onBlur={this.saveRename} noblurcap="true" /> :
                                    `${m.fileName}.${m.fileType}`
                                }
                            </td>
                            <td onClick={() => this.openFile(m.id)} >{m.datetimeUploaded}</td>
                            <td onClick={() => this.openFile(m.id)} >{m.uploadedBy}</td>
                        </tr>
                    ))}

                </tbody>
            </table>
            {files[0].hiddenFileCount ?
                <div className="hiddenCollapsible" style={{ textAlign: 'center', cursor: 'pointer', position: 'relative' }} onClick={() => { this.setState({ viewOldFiles: false }); }}>
                    <span><i className="material-icons arrowIcon">expand_less</i>Hidden Files ({files[0].hiddenFileCount})</span>
                </div>
                :
                null
            }
        </div>
    }

    showOnlyActiveFiles = () => {

        let files = this.state.files;
        let filteredFiles = files.filter(f => !f.isHidden);

        if (!this.state.files.length) {
            return <div className="noFilePlaceholder">
                No files have been uploaded for this shipment.
            </div>
        } else if (!filteredFiles.length) {
            return <div><div className="noFilePlaceholder">
                No active files for this shipment.
            </div>
                {this.state.files[0].hiddenFileCount > 0 ?
                    <div className="hiddenCollapsible" style={{ textAlign: 'center', cursor: 'pointer', position: 'relative' }} onClick={() => { this.setState({ viewOldFiles: true }); }}>
                        <span><i className="material-icons arrowIcon">expand_more</i>Hidden Files ({this.state.files[0].hiddenFileCount})</span>
                    </div>
                    :
                    null
                }
            </div>
        }

        return <div className="fileListSection">
            <table id='fileTable'>
                <thead>
                    <tr>
                        <th></th>
                        <th onClick={() => this.sort('fileName')}>File</th>
                        <th onClick={() => this.sort('date')}>Datetime Created</th>
                        <th onClick={() => this.sort('user')}>Uploaded By</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredFiles.map((m, index) => (
                        <tr key={m.id} id={m.id} className='untitled' onContextMenu={(e) => this.handleContextMenu(e, index, m.id)}>
                            <td >
                                <Form.CheckBox name="selectedFiles" value={this.state.selectedFiles.includes(m.id)} onChange={() => this.selectedFilesChange(m.id)} />
                            </td>
                            <td onClick={() => this.openFile(m.id)} >
                                {this.state.editRowIndex === index && this.state.editing ?
                                    <Form.TextInput name="newFileName" value={this.state.newFileName} onChange={this.onChange} onBlur={this.saveRename} noblurcap="true" /> :
                                    `${m.fileName}.${m.fileType}`
                                }
                            </td>
                            <td onClick={() => this.openFile(m.id)} >{m.datetimeUploaded}</td>
                            <td onClick={() => this.openFile(m.id)} >{m.uploadedBy}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {this.state.files[0].hiddenFileCount > 0 ?
                <div className="hiddenCollapsible" style={{ textAlign: 'center', cursor: 'pointer', position: 'relative' }} onClick={() => { this.setState({ viewOldFiles: true }); }}>
                    <span><i className="material-icons arrowIcon">expand_more</i>Hidden Files ({this.state.files[0].hiddenFileCount})</span>
                </div>
                :
                null
            }
        </div>
    }

    renderPlaceholder = () => {

        return <div
            onDragEnter={this.handleDragIn}
            onDragLeave={this.handleDragOut}
            onDragOver={this.handleDrag}
            onDrop={this.handleDrop}
            className={` ${this.state.inDropZone || this.state.uploading ? 'waitingForDrop' : 'notWaitingForDrop'}`}
        >
            {this.state.uploading ?
                <div className='fileUploadIconHolder'>
                    <div className="spinnerHolder">
                        <div className="preloader-wrapper big active">
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span>Uploading...</span>
                    {this.state.displayFileSizeWarning ?
                        <div>
                            <span className="fileSizeWarning">Uploading large files may take up to 30 seconds.</span><br />
                            {this.getPatienceQuote()}
                        </div>
                        : null}
                </div>
                :
                <div className="fileUploadIconHolder">
                    <i className="large material-icons">download</i>
                    <span>{this.state.inDropZone ? 'Drop the File to Upload!' : 'Click or Drag File to Upload'}</span>
                    {this.state.uploading ? <div className="uploadScreenTip">(Hit Backspace to clear this screen and navigate away while files are uploaded.)</div> : null}
                </div>
            }
        </div>
    }

    hideEmailFormModal = () => {
        M.Modal.getInstance(document.getElementById('fileUploadEmailModal')).close();
    }

    componentDidMount = () => {
        this.initListeners();
        this.initModal();
        if (this.props.fileUploadTabOpen) {
            this.getBookingFiles();
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!prevProps.fileUploadTabOpen && this.props.fileUploadTabOpen) {
            this.getBookingFiles();
        }
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => {
        return (
            <div id="fileUploadContainer" onDragEnter={this.handleDragIn} onDragOver={this.handleDrag}>
                {this.renderPlaceholder()}
                <div className="row">
                    {this.state.selectedFiles.length ?
                        <div className="row" style={{ marginBottom: "0px", marginTop: "10px" }}>
                            <Form.Button col="s3" label="Email" color="green" type="outline" onClick={this.emailFile} />
                            <Form.Button col="s3" label="Hide/Unhide" type="outline" onClick={this.hide} />
                            <Form.Button col="s3" label="Move/Copy" type="outline" onClick={this.openMoveModal} />
                            <Form.Button col="s3" label="Delete" color="red" type="outline" onClick={this.deleteFile} />
                        </div> :
                        <div className="row" style={{ marginBottom: "0px", marginTop: "10px", height: '42px', textAlign: 'left', fontWeight: 700 }}>

                        </div>
                    }
                    {this.state.viewOldFiles ? this.showAllFilesSorted() : this.showOnlyActiveFiles()}
                    {this.state.pageHeldFiles.length ?
                        <div className="row" style={{ marginTop: '10px' }}>
                            <Form.Button icon="upload" col="col s12" color="filledGreen" type="outline" label={`Upload ${this.state.pageHeldFiles.length} ${this.state.pageHeldFiles.length > 1 ? 'Files' : 'File'}`} onClick={() => { this.getUploadURL(this.state.pageHeldFiles) }} />
                        </div>
                        :
                        <div className="row" style={{ marginTop: '10px' }}>
                            <Form.Button icon="search" col="col s12" color='blue' type="outline" label="Browse" onClick={this.openFileUploadDialogueBox} />
                            <input multiple id='uploadButton' type='file' onChange={(e) => this.browseFileAdd(e)}></input>
                        </div>
                    }
                </div>
                <div className="modal moverModal" id="awsPdfViewer">
                    <div className="modal-content row">
                        <span style={{ float: "right" }}><i className="material-icons" onClick={this.closeMoveModal}>close</i></span>
                        <h5 className="col s12">Copy File(s) to . . .</h5>
                        <Form.TextInput col="s10" label="Booking Number" value={this.state.searchTermCopy} name="searchTermCopy" onChange={this.onChange} />
                        <Form.Button col="s2" label="Search" type="outline" color="green" onClick={() => this.searchBooking('Copy')} />
                        <div className="row">
                            <div className="col s6">
                                <span>Booking Number:</span> {this.state.bookingResultCopy.bookingNumber}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s6">
                                <span>Shipper:</span> {this.state.bookingResultCopy.shipperName}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s6">
                                <span>Carrier:</span> {this.state.bookingResultCopy.carrierName}
                            </div>
                        </div>
                        <div className="row">
                            <Form.Button col="col s6 offset-s3" type="outline" color="green" label="Copy" onClick={this.copyFile} />
                        </div>
                        <div className="lineBreak"></div>
                        <h5 className="col s12">Move File(s) to . . .</h5>
                        <Form.TextInput col="s10" label="Booking Number" value={this.state.searchTermMove} name="searchTermMove" onChange={this.onChange} />
                        <Form.Button col="s2" label="Search" type="outline" color="green" onClick={() => this.searchBooking('Move')} />
                        <div className="row">
                            <div className="col s6">
                                <span>Booking Number:</span> {this.state.bookingResultMove.bookingNumber}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s6">
                                <span>Shipper:</span> {this.state.bookingResultMove.shipperName}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s6">
                                <span>Carrier:</span> {this.state.bookingResultMove.carrierName}
                            </div>
                        </div>
                        <div className="row">
                            <Form.Button col="col s6 offset-s3" type="outline" color="green" label="Move" onClick={this.moveFile} />
                        </div>
                    </div>
                </div>

                <div style={{ position: 'fixed', top: this.state.y, left: this.state.x }} className="file-upload-context-menu">
                    <ul className="collection">
                        {this.state.showContextMenu && <li onClick={() => this.renameFile()} className="collection-item">Rename</li>}
                        {this.state.showContextMenu && <li onClick={() => this.openMoveModal()} className="collection-item">Move to..</li>}
                        {this.state.showContextMenu && <li onClick={() => this.hide()} className="collection-item">Hide/Unhide</li>}
                        {this.state.showContextMenu && <li onClick={() => this.emailFile()} className="collection-item">Email</li>}
                        {this.state.showContextMenu && <li onClick={() => this.deleteFile()} className="collection-item">Delete</li>}
                    </ul>
                </div>
                {this.props.fileUploadTabOpen ?
                    <EmailForm
                        id='fileUploadEmailModal'
                        onChange={this.onChange}
                        generatePdf={false}
                        saveAndEmail={this.emailFile}
                        emails={this.props.docsEmails}
                        shipperId={this.props.location?.state?.shipperId}
                        showEmailModal={this.emailFile}
                        hideEmailModal={this.hideEmailFormModal}
                        subject={this.props.bookingNumber}
                        send={this.sendEmail}
                        attachments={this.state.attachments}
                        unAttachFile={this.unAttachFile}
                        previewFile={this.openFile}
                    />
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { navs, urls, index, shipper, portalUser, user } = state;
    const shipperId = navs[index].shipperId;
    return { shipperId, shipper, portalUser, user };
}

export default connect(mapStateToProps)(withRouter(FileUpload));