import Tabs from './Tabs';
import SI from './SI';
import CI from './CI';
import BSE from './BSE';
import PL from './PL';
import COO from './COO';

export default {
    Tabs,
    SI,
    CI,
    BSE,
    PL,
    COO
}