export const init = {
  INITIALIZE_STORE: "INITIALIZE_STORE"
};

export const globalToastActions = {
  UPDATE_MSG: "UPDATE_MSG"
};

export const updateActions = {
  UPDATE_VAR: "UPDATE_VAR"
};