import React from 'react';
import axios from 'axios';
import M from 'materialize-css';
import TableHeader from "../../Components/TableHeader/TableHeader";
import Form from '../../Components/NewComponents';
import { auth } from "../../helperFunctions";
import { connect } from 'react-redux';
import { flags } from "../../constants";
import styles from "./Carrier.module.css";
import { withRouter } from '../../helperFunctions';

class CarrierOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({
        carriers: [],
        modalMessage: '',
        deleteId: 0,
        filters: {
            name: "",
            code: "",
            scacCode: "",
            displayName: "",
            currentSort: "name",
            reverse: 1,
            currentSort2: "",
            reverse2: "",
            currentSort3: "",
            reverse3: ""
        }
    });

    initListeners = () => {
        document.querySelectorAll(".search-field").forEach(el => { el.addEventListener('keydown', this.searchListener) });
    }

    removeListeners = () => {
        document.querySelectorAll(".search-field").forEach(el => { el.removeEventListener('keydown', this.searchListener) });

    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    filterOnChange = (name, value) => {
        this.setState({ filters: { ...this.state.filters, [name]: value } })
    }

    searchListener = (e) => {
        if (e.code === "Enter") {
            e.preventDefault();
            this.getCarriers();
        }
    }

    getCarriers = () => {
        axios.get('/api/v1/carrier/overview', { params: { ...auth.getAuthData(), filters: this.state.filters } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.setState({ carriers: result.data })
            }
        })
    }

    refreshData = () => this.getCarriers();


    handleEdit = (carrierId) => {
        this.props.navigate(`/carrier/create/${carrierId}`, { editMode: true });
    }

    handleDelete = () => {
        axios.post('/api/v1/carrier/archive', { id: this.state.deleteId })
            .then(() => this.refreshData())
            .catch(err => this.setState({ error: JSON.stringify(err) }));
    }

    createCarrier = (e) => {
        e.preventDefault();
        this.props.navigate("/carrier/create", {
            state: {
                currentSort: this.state.currentSort,
                reverse: this.state.reverse,
                filters: this.state.filters
            }
        })
    }

    showModal = () => M.Modal.getInstance(document.querySelector('#carrier-confirm-modal')).open();
    hideModal = () => M.Modal.getInstance(document.querySelector('#carrier-confirm-modal')).close();
    setModalMessage = carrier => this.setState({ modalMessage: `Are you sure you want to delete ${carrier.name}?`, deleteId: carrier.id }, () => this.showModal());

    sort = (e) => {
        if (e.ctrlKey) {
            e.preventDefault();
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort2 : e.target.getAttribute('data-sort');
            const reverse2 = this.state.filters.currentSort2 === value ? (-1 * this.state.filters.reverse2) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort2: value, reverse2 } }, () => {
                this.getCarriers();
            })

        } else if (e.altKey) {

            e.preventDefault();
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort3 : e.target.getAttribute('data-sort');
            const reverse3 = this.state.filters.currentSort3 === value ? (-1 * this.state.filters.reverse3) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort3: value, reverse3 } }, () => {
                this.getCarriers();
            })
        } else {
            const value = typeof e === "string" ? this.props.location.state?.filters?.currentSort : e.target.getAttribute('data-sort');
            const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => {
                this.getCarriers();
            })
        }
    }

    componentDidMount = () => {
        this.getCarriers();
        this.initListeners();
    };

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => (
        <div>
            <div id="bkg-ov-search-fields">
                <div className="row customMarginRemove">
                    <Form.TextInput col="col s3 search-field" label="Name" value={this.state.filters.name} name="name" onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                    <Form.TextInput col="col s3 search-field" label="Code" value={this.state.filters.code} name="code" onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                    <Form.TextInput col="col s3 search-field" label="SCAC" value={this.state.filters.scacCode} name="scacCode" onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                    <Form.TextInput col="col s3 search-field" label="Display Name" value={this.state.filters.displayName} name="displayName" onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />

                </div>
            </div>
            <table className={styles.carrierOverviewTable}>
                <TableHeader
                    headers={[
                        { label: "Name", attribute: 'name' },
                        { label: "Code", attribute: 'code' },
                        { label: "Display", attribute: 'displayName' },
                        { label: "SCAC", attribute: 'scacCode' },
                    ]}
                    sort={this.sort}
                    filters={this.state.filters}
                />
                <tbody>
                    {this.state.carriers ? this.state.carriers.map((car, index) => (
                        <tr key={index} onClick={() => this.handleEdit(car.id)}>
                            <td>{car.name}</td>
                            <td>{car.code}</td>
                            <td>{car.displayName}</td>
                            <td>{car.scacCode}</td>
                        </tr>
                    )) : null}
                </tbody>
            </table>
            <div id="carrier-confirm-modal" className="modal white">
                <div className="modal-content">
                    <h4>{this.state.modalMessage}</h4>
                </div>
                <div className="row">
                    <button className="btn-flat white blue-text" onClick={this.handleDelete}>Confirm</button>
                    <button className="btn-flat white red-text" onClick={this.hideModal}>Cancel</button>
                </div>
            </div>
            <div onClick={this.createCarrier} className="fixed-action-btn">
                <a href="/" className="btn-floating btn-large green">
                    <i className="large material-icons">add</i>
                </a>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { location, user, urls, index, portalUser } = state;
    const url = urls[index];
    return { location, user, url, portalUser };
}
export default connect(mapStateToProps)(withRouter(CarrierOverview));