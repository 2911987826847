import { useEffect, useLayoutEffect, useState } from "react";
import moment from "moment";
import M from 'materialize-css';
import { auth } from "../../../helperFunctions";
const componentId = '_' + crypto.randomUUID();

const EventList = props => {
    const { booking } = props;

    const [recentEvents, setRecentEvents] = useState(null);


    useEffect(() => {
        const { events } = booking;
        const recentEvents = { created: null, modified: null, emailed: null, printed: null, sent: null, reminderSent: null }

        events.forEach(ev => {
            const timeAsMoment = moment(ev.localTimeStamp, 'YYYY-MM-DD HH:mm:ss');

            if (!recentEvents[ev.eventType])
                recentEvents[ev.eventType] = { ...ev, timeAsMoment };
            else if (recentEvents[ev.eventType].timeAsMoment.isBefore(timeAsMoment, 'seconds'))
                recentEvents[ev.eventType] = { ...ev, timeAsMoment };
        })

        setRecentEvents(recentEvents);
    }, [booking.events])

    useLayoutEffect(() => {
        M.Modal.init(document.querySelector(`#modal-${componentId}`));

    }, [])

    const onClick = e => {
        e?.preventDefault();

        M.Modal.getInstance(document.querySelector(`#modal-${componentId}`)).open();
    }

    return (
        <>
            <div
                className="row"
                style={{
                    border: '1px solid #ccc',
                    boxShadow: '0px 2px 3px #999',
                    borderRadius: '6px',
                    padding: '4px 18px 4px 8px',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '0px'
                }}
                onClick={onClick}
            >
                <div style={{ borderBottom: 'dotted gray 1px' }}>
                    <p className="left"><b>C:</b> {recentEvents?.created?.employee.firstName[0]}. {recentEvents?.created?.employee.lastName}</p>
                    <p className="right">{recentEvents?.created?.timeAsMoment.format('MM/DD/YY HH:mm')}</p>
                </div>
                <div style={{ borderBottom: 'dotted gray 1px' }}>
                    <p className="left"><b>M:</b> {recentEvents?.modified?.employee.firstName[0]} {recentEvents?.modified?.employee.lastName}</p>
                    <p className="right">{recentEvents?.modified?.timeAsMoment.format('MM/DD/YY HH:mm')}</p>
                </div>
                <div style={{ borderBottom: 'dotted gray 1px' }}>
                    <p className="left"><b>P:</b> {recentEvents?.printed?.employee.firstName[0]} {recentEvents?.printed?.employee.lastName}</p>
                    <p className="right">{recentEvents?.printed?.timeAsMoment.format('MM/DD/YY HH:mm')}</p>
                </div>
                <div>
                    <p className="left"><b>E:</b> {recentEvents?.emailed?.employee.firstName[0]} {recentEvents?.emailed?.employee.lastName}</p>
                    <p className="right">{recentEvents?.emailed?.timeAsMoment.format('MM/DD/YY HH:mm')}</p>
                </div>
            </div>
            <div id={`modal-${componentId}`} className="modal">
                <div className="modal-content">
                    <div className="row" style={{ marginBottom: '0px' }}>
                        <h5 className="center">All Events</h5>
                    </div>
                    <div className="divider" />
                    <div className="row">
                        <table>
                            <thead>
                                <tr>
                                    <th>Event Type</th>
                                    <th>Employee Name</th>
                                    <th>Time Stamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {booking?.events?.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp)).map(ev => (
                                    <tr key={ev.id}>
                                        <td>{ev.eventType}</td>
                                        <td>{ev.employee.firstName} {ev.employee.lastName}</td>
                                        <td>{moment(ev.localTimeStamp, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventList;