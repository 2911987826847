import React, { Fragment } from 'react';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import styles from "./ServiceRoute.module.css";

const ServiceRouteResultsDisplay = (props) => {
    return (
        <Fragment>
            {props.loading ?
                <LoadingSpinner /> :
                props.services.map((service, index) => (
                    <tr className={`${styles.yangMingResultsDisplay} resultsDisplay service-tr-${index}`} id={`${service.id}`} key={index}>
                        <td>{service.carrierProvidedNewVoyageNumber}<br /><span className={styles.additionalText}>{service.additionalText}</span></td>
                        <td><a target="_blank" rel="noopener noreferrer" disabled={service.vesselLink ? true : false} href={service.vesselLink ? service.vesselLink : "#"}>{service.carrierProvidedVesselName}</a></td>
                        <td>{service.carrierProvidedVoyageNumber}</td>
                        <td>{service.carrierEtd}</td>
                        <td>{service.vvVesselName}</td>
                        <td>{service.vvVoyageNumber}</td>
                        <td>{service.etd}</td>
                        {props.serviceRouteLocations.map((l, index) => (
                            <td key={index} className={styles.locationCount}>{service.locationCounts.filter(sc => sc.plr === l.toUpperCase()).length ? service.locationCounts.filter(sc => sc.plr === l.toUpperCase())[0].containerCount : 0}</td>
                        ))}
                        {props.serviceRouteParties.map((s, index) => (
                            <td key={index} className={styles.shipperCount}>{service.shipperCounts.filter(sc => sc.shipperName === s.toUpperCase()).length ? service.shipperCounts.filter(sc => sc.shipperName === s.toUpperCase())[0].containerCount : 0}</td>
                        ))}
                        <td>{service.totalCans ? service.totalCans : 0}</td>
                    </tr>
                ))}
        </Fragment>
    )
}

export default ServiceRouteResultsDisplay;