import React from 'react';
import { get, post } from '../../../helperFunctions';
import Confirm from '../../../Components/Confirm/Confirm';
import OverviewTable from '../../../Components/OverviewTable/OverviewTable';

class VesselOverview extends React.Component {
    constructor(props) { super(props); this.state = this.initState() };

    initState = () => ({ vessels: null, showModal: false, vessel: null });
    getVessels = () => get('/vessel/populated', vessels => this.setState({ vessels }), this.handleFailure())
    handleFailure = err => this.setState({msg: {style: 'red-text', text: 'An unknown server-side error occurred'}})
    refresh = () => this.getVessels();
    showModal = vessel => this.setState({ showModal: true, vessel });
    handleDelete = () => post('vessel/archive', { id: this.state.vessel.id }, this.deleteSuccess, this.handleFailure());
    deleteSuccess = () => this.setState({ showModal: false}, () => this.refresh());
    handleEdit = vessel => this.props.history.replace('/carrier/vessel/create', { vessel, editMode: true });
    componentDidMount = () => this.refresh();

    generateFields = () => ([{ header: 'Vessel', prop: 'name' }])


    render = () => (
        <div>
            <h3 className="center blue-text text-darken-4">Vessels</h3>

            <OverviewTable
                fields={this.generateFields()}
                rows={this.state.vessels}
                showModal={this.showModal}
                handleEdit={this.handleEdit}
            />

            <Confirm
                id="vessel-confirm-modal"
                show={this.state.showModal}
                msg={`Delete Vessel ${this.state.vessel ? this.state.vessel.name : null}?`}
                confirm={this.handleDelete}
            />
        </div>
    )
}

export default VesselOverview;