import { useLayoutEffect, useRef } from "react";
const componentId = '_' + crypto.randomUUID();

const EmailModal = props => {
    const {
        openOnCtrlM
    } = props;

    const el = useRef();
    const modal = useRef();

    useLayoutEffect(() => {
        const onKeyDown = e => {
            const { ctrlKey, keyCode } = e;

            if (ctrlKey && keyCode === 77) {
                e.preventDefault();
                open();
            }
        }

        if (openOnCtrlM)
            document.addEventListener('keydown', onKeyDown);

        modal.current = M.Modal.init(el.current, { dismissible: true });

        return () => {
            document.removeEventListener('keydown', onKeyDown);
            modal.current?.destroy();
        }
    }, [])

    const open = () => modal.current?.open();

    return (
        <div ref={el} id={`email-modal-${componentId}`} className="modal">
            <div className="modal-content">
                <h4>Email modal not implemented yet</h4>
            </div>
        </div>
    )
}

export default EmailModal;