import React from 'react';

const OverviewTable = props => (
    <div className="row">
        <table>
            <thead>
                <tr>
                    {props.fields ? props.fields.map((field, index) => <th key={index}>{field.header}</th>) : null}
                    <th />
                    <th />
                </tr>
            </thead>
            <tbody>
                {props.rows ? props.rows.map((row, index) => (
                    <tr key={index}>
                        {props.fields ? props.fields.map((field, index) => <td key={`${field.name}-${index}`}>{row[field.prop]}</td>) : null}
                        <td><button className="btn-flat white orange-text" onClick={() => props.handleEdit(row)}>Edit</button></td>
                        <td><button className="btn-flat white red-text" onClick={() => props.showModal(row)}>Delete</button></td>
                    </tr>
                )) : null}
            </tbody>
        </table>
    </div>
)

export default OverviewTable;