import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Form from '../../../Components/NewComponents';

class CreateVessel extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); };

    initState = () => ({
        name: '',
        carrierId: 0,
        carriers: [],
        msg: { color: '', text: '' }
    })

    onChange = (name, value) => this.setState({ [name]: value });
    clear = () => this.setState(p => ({ ...this.initState(), carriers: p.carriers }));
    save = () => axios.post('/api/v1/vessel', { ...this.getVessel() }).then(result => {
        if (result.data.errno)
            this.setState({ msg: { color: 'red', text: `Error: ${result.data.sqlMessage}` } });
        else {
            this.clear();
            this.setState({ msg: { color: 'green', text: `Submitted ID: ${result.data.insertId}` } });
        }
    })

    getVessel = () => ({ vessel: { name: this.state.name, carrierId: this.state.carrierId } })

    render = () => (
        <div>
            <div className="row">
                <h5>Vessel</h5>
            </div>
            <div className="row">
                <Form.Select col="s4 l3" placeholder="...Choose" name="carrierId" label="Carrier" onChange={this.onChange} value={this.state.carrierId} list={this.props.carriers} filter={{ label: 'name', value: 'id' }} />
            </div>
            <div className="row">
                <Form.TextInput col="s6 l4" name="name" label="Name" onChange={this.onChange} value={this.state.name} />
            </div>
            <div className="row">
                <h6 className={`${this.state.msg.color}-text`}>{this.state.msg.text}</h6>
            </div>
            <div className="row">
                <Form.Button col="s3" color="green" icon="save" label="Save" round type="outline" onClick={this.save} />
                <Form.Button col="s3" color="red" icon="cancel" label="Clear" round type="outline" onClick={this.clear} />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { carriers } = state;
    return { carriers };
}

export default connect(mapStateToProps)(CreateVessel);