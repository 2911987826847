import { withRouter } from "../../helperFunctions";
import { Link } from 'react-router-dom';

const SideBarTab = props => {
  return (
    <div>
      <Link to={props.url} className={props.location.pathname === props.url ? 'white-text' : 'blue-text'}>
        <li
          style={{ cursor: "pointer", borderBottom: '1px solid lightgrey' }}
          className={`collection-item thing sideLink ${props.location.pathname === props.url ? "active-link" : "unactive-link blue-text"}`}>
          {props.linkName}
        </li>
      </Link>
    </div >
  )
}

export default withRouter(SideBarTab);