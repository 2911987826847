import { useEffect, useLayoutEffect, useRef } from "react";
import './sidebar.css';

const SideBarToggle = props => {
    const {
        hideSideBar,
        toggleSideBar
    } = props;

    const ref = useRef({ hideSideBar })


    const toggleListener = e => {
        const { ctrlKey, key } = e;

        if (ctrlKey) {
            if (key === '`') {
                e.preventDefault();
                toggleSideBar();
            }
            else if (key === 'e' && ref.current.hideSideBar) {
                e.preventDefault();
                toggleSideBar();
            }
        }
    }

    useEffect(() => { ref.current.hideSideBar = hideSideBar }, [hideSideBar])

    useEffect(() => {
        const initListeners = () => {
            document.addEventListener('keydown', toggleListener)
        }

        initListeners();
    }, [])

    useLayoutEffect(() => {
        const removeListeners = () => {
            document.removeEventListener('keydown', toggleListener);
        }

        return removeListeners;
    }, [])

    return (
        <button
            className="lf-sidebar-toggle btn-floating waves-effect waves-light"
            onClick={toggleSideBar}
        >
            <i className="material-icons">{`keyboard_arrow_${hideSideBar ? 'right' : 'left'}`}</i>
        </button>
    )
}

export default SideBarToggle;