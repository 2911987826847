export const initFunctions = {
  initializeList: (state, listName, list) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState[listName] = list;
    return { ...state, ...newState };
  }
};

export const tabFunctions = {
  createDefaultTab: () => ({
    index: 0,
    navs: [
      {
        title: "",
        isHome: true,
        path: "/",
        links: [
          { name: "Shipment Overview", url: "/shipment", isFiltered: false },
          { name: "Shipment", url: "/shipment/create", isFiltered: true },
          { name: "Request", url: "/request", isFiltered: false },
          { name: "Documentation Overview", url: "/documentation", isFiltered: false },
          { name: "Service Schedule", url: "/service", isFiltered: false },
          { name: "Forecast Tracking", url: "/forecast", isFiltered: false },
          { name: "Invoicing", url: "/invoice", isFiltered: false },
          { name: "Splits", url: "/shipment/split", isFiltered: false },
          { name: "Party Overview", url: "/party", isFiltered: false },
          { name: "Carrier Overview", url: "/carrier", isFiltered: false },
          { name: "Location Overview", url: "/location", isFiltered: false },
          { name: "Standalone Invoice", url: "/invoice/create", isFiltered: false },
          { name: "Contracts Overview", url: "/contract", isFiltered: false },
          { name: "Reports", url: "/report", isFiltered: false }
        ]
      }
    ],
    custNavs: [
      {
        title: "",
        isHome: true,
        path: "/",
        links: [
          { name: "Bookings List", url: "customer", isFiltered: false },
          { name: "Request", url: "request", isFiltered: false }
        ]
      }
    ]
  }),
};

export const urlFunctions = {
  //CREATES DEFAULT URL -- COULD HAVE FIGURED THAT ONE OUT PROBABLY...
  createDefaultUrl: () => ({ url: "/", state: {} }),

  //Testing for new reducer function, if state is the, update cannot be called to re-search
  refreshUrl: (state, payload) => {
    const { urls, index } = state;
    urls[index] = { url: payload.url, state: payload.state };
    return { ...state, urls };
  },

  //UPDATES URL IN URLS ARRAY
  updateUrl: (state, payload) => {
    const { urls, index } = state;

    urls[index] = payload.state
      ? { url: payload.url, state: payload.state }
      : { url: payload, state: {} };

    return { ...state, urls };
  }
};

export const navFunctions = {
  //CREATES DEFAULT NAV OBJECT
  createDefaultNav: () => ({
    shipperId: 1,
    filterByShipper: false
  }),

  //CHANGES SHIPPER ID
  changeId: (state, shipperId) => {
    const { navs, index } = state;
    const copy = JSON.parse(JSON.stringify(navs));
    copy[index].shipperId = shipperId;

    return { ...state, navs: copy };
  },

  //CHANGES THE CONTRACT NUMBER
  changeContract: (state, contractNumber) => {
    const { navs, index } = state;
    const copy = JSON.parse(JSON.stringify(navs));
    copy[index].contractNumber = contractNumber;

    return { ...state, navs: copy }

  },

  //SETS FILTER BY SHIPPER TO TRUE OR FALSE
  setFilter: (state, filterByShipper) => {
    const { navs, index } = state;
    const copy = JSON.parse(JSON.stringify(navs));
    copy[index].filterByShipper = filterByShipper;

    return { ...state, navs: copy };
  },

  //UPDATE SIDEBAR SEARCH VALUE
  setSearchTerm: (state, sidebarSearchTerm) => {
    const { navs, index } = state;
    const copy = JSON.parse(JSON.stringify(navs));
    copy[index].sidebarSearchTerm = sidebarSearchTerm;

    return { ...state, navs: copy }
  }

};

export const globalToastFunctions = {
  updateMsg: (state, globalToast) => {
    return { ...state, globalToast };
  }
};

export const updateFunctions = {
  updateVar: (state, sessionVariables) => {
    return { ...state, sessionVariables }
  }
}
