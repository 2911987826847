import React from 'react';
import styles from './RawTextInputSmall.module.css';
import { types } from '../../constants';
import { inputValidation } from '../../helperFunctions';



class RawNumberInputSmall extends React.Component {
    constructor(props) {
        super(props); this.state = this.initState()
    }

    initState = () => ({ err: null })
    onChange = e => {
        if (e.target.value === '-') {
            this.props.onChange(this.props.name, '-');
            return;
        }

        if (e.target.value === '') {
            this.props.onChange(this.props.name, '');
            return;
        }

        if (this.props.float && e.target.value[e.target.value.length - 1] === '.') {
            const value = e.target.value;
            this.props.onChange(this.props.name, value);
            return;
        }

        let value;
        let num = e.target.value.toString();

        if (this.props.float)
            value = parseFloat(e.target.value);
        else
            value = parseInt(e.target.value);

        if (isNaN(value))
            return;


        value = num[num.length - 1] === '0' ? num : value;


        this.props.onChange(this.props.name, value);
    }

    getDivClassName = () => {
        return this.props.col ? `col ${this.props.col} ${styles.div} ${this.props.className}` : styles.div;
    }

    genInputClass = () => {
        return `browser-default ${styles.input} ${this.props.stylingClass} ${this.state.err ? styles.err : ''} ${this.props.disabled ? styles.disabled : ''}`;
    }

    getInputErrInfo = () => ([{ name: 'value', type: types.INT, value: this.props.value, flags: this.props.flags }])

    onBlur = () => inputValidation(this.getInputErrInfo(), this.success, this.failure);
    success = value => this.setState({ err: null, msg: '' }, () => this.props.onBlur);
    failure = value => this.setState({ err: { msg: value.value.msg } }, this.props.onBlur);

    render = () => (
        <div className={this.getDivClassName()}>
            <input
                type="text"
                className={this.genInputClass()}
                value={this.props.value}
                onChange={this.onChange}
                onFocus={this.props.onFocus}
                onBlur={this.onBlur}
                disabled={this.props.disabled}
                maxLength={this.props.maxLength ? this.props.maxLength : ""}
                tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"}
            />
            {this.props.label ?
                <label className={styles.label}>{this.props.label} {this.props.required ? <span style={{ color: "red" }}>*</span> : ''}</label>
                : null}
            {this.state.err ? <span className={styles.msg}>{this.state.err.msg}</span> : null}
        </div>
    )
}

export default RawNumberInputSmall;