import { createStore } from "redux";
import {
  tabFunctions,
  urlFunctions,
  navFunctions,
  initFunctions,
  globalToastFunctions,
  updateFunctions
} from "./reducerFunctions";
import {
  init,
  globalToastActions,
  updateActions
} from "./actions";

const defaultStore = {
  index: 0,
  tabCount: 1,
  navs: [navFunctions.createDefaultNav()],
  urls: [urlFunctions.createDefaultUrl()],
  sidebars: [tabFunctions.createDefaultTab()],
  globalToast: {}
};

const reducer = (state = defaultStore, action) => {
  switch (action.type) {
    case "SCORCHED_EARTH":
      localStorage.clear()
      return {
        index: 0,
        tabCount: 1,
        navs: [navFunctions.createDefaultNav()],
        urls: [urlFunctions.createDefaultUrl()],
        sidebars: [tabFunctions.createDefaultTab()],
        globalToast: {},
        logout: true
      };

    case init.INITIALIZE_STORE:
      return initFunctions.initializeList(
        state,
        action.payload.listName,
        action.payload.list
      );

    //START GLOBALTOAST FUNCTIONS
    case globalToastActions.UPDATE_MSG:
      return globalToastFunctions.updateMsg(state, action.payload);

    case updateActions.UPDATE_VAR:
      return updateFunctions.updateVar(state, action.payload);

    default:
      return state;
  }
};

export default createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
