import React from 'react';
import styles from './textarea.module.css';

const TextArea = props => (
    <div className={`${styles.div} ${props.col}`}>
        <textarea
            id={props.id}
            style={{ height: props.height, cursor: props.cursor }}
            disabled={props.disabled}
            className={`browser-default ${styles.textarea} ${props.className}`}
            type="text"
            onInput={props.onInput}
            onChange={e => props.onChange(props.name, e.target.value)}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            value={props.value}
            tabIndex={props.tabIndex}
            maxLength={props.maxLength ? props.maxLength : null}
        />
        <label id={props.labelId} className={`${styles.label} ${props.labelClass}`}>{props.label} {props.required ? <span style={{ color: "red" }}>*</span> : ''}</label>
    </div>
)

export default TextArea;