import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {withRouter} from '../../helperFunctions';
class InvoiceResultsDisplay extends Component {
    state = {
        showContextMenu: false,
        deleteShipment: null,
        loading: true
    }

    handleEdit = (lNumber, invoiceNumber) => {
        this.props.navigate('/invoice/create', {
            state: {
                origin: "invoiceOverview",
                filters: this.props.filters,
                searchTerm: lNumber,
                invoiceNumber,
                tabIndex: this.props.filters.filterByInvoice === "true" ? 1 : 0
            }
        })
    }

    refresh = () => { this.setState({ loading: true }) }

    componentDidMount = () => {
        this.refresh();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.setState({ loading: false })
        }
    }

    render = () => {
        return (
            <Fragment>
                {this.state.loading ?
                    <tr>
                        <td>
                            <div className="preloader-wrapper big active overviewLoader">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    :
                    this.props.shipments.map((s, index) => (
                        <tr key={index} style={{ borderBottom: '1px dotted gray' }}>
                            <td className={`${(this.props.filters.filterByInvoice === 'true' && s.invoiceStatus === "PAID") || (this.props.filters.filterByInvoice !== 'true' && s.commissionStatus === "PAID") ? 'invoicePaid' : ''}`}
                                style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>{this.props.filters.filterByInvoice === 'true' ? s.invoiceStatus : s.commissionStatus}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>{s.lNumber ? s.lNumber : s.invoiceNumber}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>{s.billOfLadingNumber ? s.billOfLadingNumber : "-"}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>{s.shipperName}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>{s.carrierName ? s.carrierName : "-"}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>{s.ladenOnBoardDate !== "-" ? moment.utc(s.ladenOnBoardDate).format('MM/DD') : s.ladenOnBoardDate}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>{this.props.filters.filterByInvoice === "true" ? moment.utc(s.invoiceDateCreated).format('MM/DD') : '-'}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>{this.props.filters.filterByInvoice === "true" ? moment.utc(s.dateDue).format('MM/DD') : '-'}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>{this.props.filters.filterByInvoice === "true" ? s.invoicePastDue : s.commissionPastDue}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>{this.props.filters.filterByInvoice === "true" ? s.invoiceEnteredDate : s.commissionEnteredDate}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>${this.props.filters.filterByInvoice === "true" ? s.invoiceTotal : s.commissionTotal}</td>
                            <td style={{ textAlign: 'center' }} onClick={() => this.handleEdit(s.lNumber, s.invoiceNumber)}>${this.props.filters.filterByInvoice === "true" ? s.balanceDue : s.comBalanceDue}</td>
                        </tr>
                    ))}
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    const { location, user, urls, index } = state;
    const url = urls[index];
    return { location, user, url }
}

export default connect(mapStateToProps)(withRouter(InvoiceResultsDisplay));