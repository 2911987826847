import React from 'react';
import styles from './descriptionOfGoods.module.css';

class Tabs extends React.Component {
    constructor(props) { super(props); this.state = this.initState() }

    initState = () => ({

    })

    setSelection = (e, page) => {
        const dogTabList = document.querySelector('#dog-tab-list');
        dogTabList.childNodes.forEach(child => child.classList.remove(styles.active));
        e.target.classList.add(styles.active);


        this.props.setState('dogPage', page)
    };

    render = () => (
        <div className="row">
            <ul id="dog-tab-list">
                <button tabIndex="2" className={styles.active} style={{marginBottom: "0px"}} onClick={e => this.setSelection(e, 'shippingInstructions')}>Shipping Instructions</button>
                <button tabIndex="2" style={{marginBottom: "0px"}} onClick={e => this.setSelection(e, 'packingList')}>Packing List</button>
                <button tabIndex="2" style={{marginBottom: "0px"}}  onClick={e => this.setSelection(e, 'commercialInvoice')}>Commercial Invoice</button>
                <button tabIndex="2" style={{marginBottom: "0px"}}  onClick={e => this.setSelection(e, 'certificateOfOrigin')}>Certificate of Origin</button>
                <button tabIndex="2" style={{marginBottom: "0px"}}  onClick={e => this.setSelection(e, 'bse')}>BSE</button>
            </ul>
        </div>
    )

}

export default Tabs;