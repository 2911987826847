import React from 'react';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import M from 'materialize-css';
import styles from './time.module.css';

class Time extends React.Component {
    constructor(props) { super(props); this.state = { value: '' } }


    genDivClass = () => (`${this.props.col ? `col ${this.props.col}` : ''} ${styles.div} ${this.props.highlight ? 'tooltipped' : ''}`)
    initToolTip = () => {
        if (this.props.highlight)
            M.Tooltip.init(document.querySelector(`.tooltipped`), { enterDelay: 500 });
    }
    onChange = e => this.setState({ value: e.target.value }, this.props.onChange(this.props.name, e.target.value));
    onBlur = () => {
        if (moment(this.props.value, 'HH:mm').isValid())
            this.setState({ err: false, msg: null })
        else
            this.setState({ err: true, msg: 'Invalid Time' });
    }

    initTooltip = () => M.Tooltip.init(document.querySelector(`#${this.props.name}-outer-div`));
    removeTooltip = () => {
        const tooltip = M.Tooltip.getInstance(document.querySelector(`#${this.props.name}-outer-div`))
        if (tooltip)
            tooltip.destroy();
    }

        componentDidUpdate = prevProps => {
        if (prevProps !== this.props) {
            if (this.props.tooltip) {
                this.initTooltip();
            } else {
                this.removeTooltip();
            }
        }
    }

    render = () => (
        <div id={`${this.props.name}-outer-div`} className={this.genDivClass()} data-position="top" data-tooltip={this.props.tooltip}>
            <MaskedInput
                id={this.props.name}
                className={`browser-default ${styles.input} ${this.state.err ? styles.err : ''} ${this.props.highlight ? styles.highlight : ''}`}
                type="text"
                mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                onChange={this.onChange}
                onBlur={this.onBlur}
                value={this.props.value}
                placeholder="hh:mm"
            />
            <label className={styles.label}>{this.props.label} {this.props.required ? <span style={{color: "red"}}>*</span> : ''}</label>
            <span className={styles.msg}>{this.state.msg}</span>
        </div>
    )
}

export default Time;