import React from 'react';
import { connect } from 'react-redux';
import ChangePassword from './ChangePassword';
import CreateUser from "../Parties/Users/CreateUser";

class Settings extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({

    })

    render = () => (
        <div>
            <div className="row">
                <h4>{this.props.user.first} {this.props.user.last}</h4>
            </div>
            <div className="row">
                <div className="col s3">
                    <p style={{ fontSize: "20px" }}>Change Password</p>
                    <p>Password must be a minimum of 8 characters, must have at least one digit, and must have one special character, ie: !,@,#,$</p>
                </div>
                <div className="col s9">
                    <ChangePassword />
                </div>
                {this.props.user.is_admin || this.props.user.isAdmin ?
                    <div className="col s12">
                        <CreateUser />
                    </div>
                    : null}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { user } = state;
    return { user };
}

export default connect(mapStateToProps)(Settings);