import GlobalSearch from "../SideBar/GlobalSearch";
import { useLocation, Link } from "react-router-dom";
import './sidebar.css'
import { useEffect, useState } from "react";
import Axios from "axios";
import { auth, logout } from "../../helperFunctions";

const EmployeeSidenav = props => {
    const { hideSideBar } = props;
    const { pathname } = useLocation();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();

        Axios.get('/api/v2/user/read/one/by/token', {
            params: auth.getAuthData(),
            signal: abortController.signal
        })
            .then(result => setUser(result.data))
            .catch(logout)

        return () => abortController.abort();
    }, [])

    if (hideSideBar)
        return null;
    else
        return (
            <div id="lf-sidbar-nav" className={`sidebar ${hideSideBar ? 'hide' : ''}`}>
                <ul className="collection with-header sidebar-collection" style={{ overflow: 'auto' }}>
                    <div className="search-wrapper row">
                        <GlobalSearch />
                    </div>
                    <SideBarLink path={pathname} linkName="Shipment Overview" url="/shipment/overview" />
                    <SideBarLink path={pathname} linkName="Shipment" url="/shipment/create" />
                    <SideBarLink path={pathname} linkName="Request" url="/request" />
                    <SideBarLink path={pathname} linkName="Documentation Overview" url="/documentation" />
                    <SideBarLink path={pathname} linkName="Service Schedule" url="/service" />
                    <SideBarLink path={pathname} linkName="Forecast Tracking" url="/forecast" />
                    {user?.isAdmin || user?.isBookkeeper ?
                        <SideBarLink path={pathname} linkName="Invoicing" url="invoice/overview" />
                        : null}
                    <SideBarLink path={pathname} linkName="Splits" url="/shipment/split" />
                    <SideBarLink path={pathname} linkName="Party Overview" url="/party" />
                    <SideBarLink path={pathname} linkName="Carrier Overview" url="/carrier" />
                    <SideBarLink path={pathname} linkName="Location Overview" url="/location" />
                    <SideBarLink path={pathname} linkName="Standalone Invoice" url="/invoice/create" />
                    <SideBarLink path={pathname} linkName="Contracts Overview" url="/contract" />
                    {user?.isAdmin ?
                        <>
                            <SideBarLink path={pathname} linkName="Reports" url="/report" />
                            <SideBarLink path={pathname} linkName="New Shipment Overview" url="/new/shipmentoverview" />
                        </>
                        : null}
                </ul>
            </div>
        )
}

export default EmployeeSidenav;

const SideBarLink = props => (
    <div>
        <Link
            to={props.url}
            className={props.path?.includes(props.url) ? 'white-text' : 'blue-text'}
        >
            <li
                style={{ cursor: 'pointer', borderBottom: '1px solid lightgrey' }}
                className={`collection-item thing sideLink ${props.path?.includes(props.url) ? 'active-link' : 'unactive-link blue-text'}`}
            >
                {props.linkName}
            </li>
        </Link>
    </div>
)