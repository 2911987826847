import React, { Component } from "react";
import { connect } from "react-redux";
import { flags } from "../../../constants";
import Form from "../../../Components/NewComponents";
import { globalToastActions } from "../../../Redux/actions";


class ModalFreetimeDisplay extends Component {
    state = {
        countryId: 1,
        locationId: 0,
        demurrageDays: "",
        demurrageTimeType: "BUSINESS DAYS",
        additionalDemurrage: "",
        detentionDays: "",
        detentionTimeType: "BUSINESS DAYS",
        expDate: "",
        notes: "",
        editCountryId: "",
        editLocationId: "",
        editDemurrageDays: "",
        editDemurrageTimeType: "",
        editdetentionDays: "",
        editDetentionTimeType: "",
        editAdditionalDemurrage: "",
        editExpirationDate: "",
        editNotes: "",
        countryTime: 0,
        countries: [],
        ports: [],
        timeTypes: [
            { type: "CALENDAR DAYS" },
            { type: "BUSINESS DAYS" },
            { type: "COMBINED CALENDAR" },
            { type: "COMBINED BUSINESS" }
        ],
        editRowIndex: undefined,
        showCopyMenu: false,
        showPasteMenu: false,
        copiedFreetime: [],
        copiedFreetimeIndex: '',
        x: 0,
        y: 0
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    initListeners = () => {
        document.addEventListener("keydown", this.clearListener);
    }

    removeListeners = () => {
        document.removeEventListener('keydown', this.clearListener);
    }

    clickListener = e => {
        const list = document.querySelectorAll('#modal-freetime-tbody>tr');
        list.forEach(tr => tr.classList.remove('menu-selected'));
        this.setState({ showCopyMenu: false, showPasteMenu: false }, () => document.removeEventListener("click", this.clickListener))
    }

    handleCopyPasteMenu = (e, index, freetime) => {
        e.preventDefault();

        if (e.ctrlKey) {
            document.querySelector(`#modal-freetime-tr-${index}`).classList.contains('menu-selected') ?
                document.querySelector(`#modal-freetime-tr-${index}`).classList.remove('menu-selected') :
                document.querySelector(`#modal-freetime-tr-${index}`).classList.add('menu-selected')
        } else {
            this.setState({ showCopyMenu: true, showPasteMenu: true, copiedFreetimeIndex: index, x: e.pageX, y: e.pageY, freetime: freetime });
            document.addEventListener("click", this.clickListener);
        }
    }

    copyFreetime = () => {

        let copyIndex = this.state.copiedFreetimeIndex;
        let copiedFreetime = { ...this.props.contract.freetime[copyIndex] };

        this.setState({ copiedFreetime }, () => {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Freetime Copied" } })
        })
    }

    pasteFreetime = () => {
        let copiedFreetime = { ...this.state.copiedFreetime };
        let pasteRows = document.querySelectorAll('.menu-selected');
        let indexesForUpdate = [];

        if (!pasteRows.length) {
            indexesForUpdate.push(this.state.copiedFreetimeIndex);
        } else {
            pasteRows.forEach((r, index) => {
                indexesForUpdate.push(r.sectionRowIndex);
            });
        }

        indexesForUpdate.forEach(async i => {
            await this.props.pasteFreetime(i, 1, copiedFreetime);
        })
    }

    makeActive = (index) => {

        let freetime = this.props.contract.freetime[index];

        this.setState({
            editCountryId: freetime.countryId,
            editLocationId: freetime.locationId,
            editDemurrageDays: freetime.demurrageDays ? freetime.demurrageDays : "",
            editDemurrageTimeType: freetime.demurrageTimeType ? freetime.demurrageTimeType : "BUSINESS DAYS",
            editDetentionDays: freetime.detentionDays ? freetime.detentionDays : "",
            editDetentionTimeType: freetime.detentionTimeType ? freetime.detentionTimeType : "BUSINESS DAYS",
            editAdditionalDemurrage: freetime.additionalDemurrage ? freetime.additionalDemurrage : "",
            editExpirationDate: freetime.expirationDate ? freetime.expirationDate : "",
            editNotes: freetime.notes ? freetime.notes : "",
            editRowIndex: index
        }, () => {
            let targetRow = document.querySelectorAll("td:not(.material-icons)>div>input, td:not(.material-icons)>div>select");
            let row = document.getElementById(`modal-freetime-tr-${index}`);
            if (document.querySelector('.lastActiveFreetime')) {
                document.querySelector('.lastActiveFreetime').classList.remove('lastActiveFreetime');
            }
            row.classList.add('lastActiveFreetime');
            targetRow.forEach(r => {
                r.style.color = "blue"
            });
            targetRow[0].focus();
        })
    }

    makeInactive = (index) => {

        let freetime = {
            countryId: this.state.editCountryId,
            locationId: this.state.editLocationId,
            demurrageDays: this.state.editDemurrageDays ? this.state.editDemurrageDays : null,
            demurrageTimeType: this.state.editDemurrageDays ? this.state.editDemurrageTimeType : null,
            detentionDays: this.state.editDetentionDays ? this.state.editDetentionDays : null,
            detentionTimeType: this.state.editDetentionDays ? this.state.editDetentionTimeType : null,
            additionalDemurrage: this.state.editAdditionalDemurrage,
            expirationDate: this.state.editExpirationDate,
            notes: this.state.editNotes
        };

        this.props.freetimeSpliceEdit(freetime, index);

        this.setState({
            editCountryId: "",
            editLocationId: "",
            editDemurrageDays: "",
            editDemurrageTimeType: "",
            editDetentionDays: "",
            editDetentionTimeType: "",
            editAdditionalDemurrage: "",
            editExpirationDate: "",
            editNotes: "",
            editRowIndex: undefined
        }, () => {
            let targetRow = document.querySelector(`#modal-freetime-tr-${index}`).querySelectorAll("td:not(.material-icons)");
            targetRow.forEach(r => {
                r.style.color = "black"
            });
            if (document.querySelectorAll(`#modal-freetime-tbody>tr`)[index + 1]) {
                document.querySelectorAll(`#modal-freetime-tbody>tr`)[index + 1].children[7].children[0].focus();
            }
        })
    }

    grabPortsAndCountriesFromRates = async () => {
        let origins = await this.props.location.filter(l => this.props.contract.rates.map(r => r.originId).includes(l.id))
        let destinations = await this.props.location.filter(l => this.props.contract.rates.map(r => r.destId).includes(l.id))
        let ports = await origins.concat(destinations);
        let countries = await this.props.country.filter(c => ports.map(p => p.countryId).includes(c.id));
        let portId = ports.length ? ports[0].id : 0
        this.setState({ ports, portId, countries });
    }

    componentDidMount = () => {
        this.grabPortsAndCountriesFromRates();
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    componentDidUpdate = (prevProps) => {

        if (prevProps.contract.rates !== this.props.contract.rates) {
            this.grabPortsAndCountriesFromRates();
        }
    }


    render = () => (
        <div style={{ position: "relative" }}>
            <h5>Freetime</h5>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th>Country</th>
                            <th>Port</th>
                            <th>Demurrage</th>
                            <th>Addl. Demurrage</th>
                            <th>Detention</th>
                            <th>Notes</th>
                            <th>Exp. Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody id="modal-freetime-tbody">
                        {this.props.contract.freetime.map((f, index) => (
                            this.state.editRowIndex === index ?
                                <tr key={index} id={`modal-freetime-tr-${index}`}>
                                    <td>
                                        {this.props.country.filter(c => c.id === f.countryId)[0].name}
                                    </td>
                                    <td>
                                        {this.props.location.filter(l => l.id === parseInt(f.locationId))[0].name}
                                    </td>
                                    <td className="demurrageCombo" style={{ marginBottom: "15px" }}>
                                        <Form.NumberInput col="col s3" name={`editDemurrageDays`} value={this.state.editDemurrageDays} onChange={this.onChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.Select col="col s9" name={`editDemurrageTimeType`} value={this.state.editDemurrageTimeType} onChange={this.onChange} list={this.state.timeTypes} filter={{ label: "type", value: "type" }} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.TextInput name={`editAdditionalDemurrage`} value={this.state.editAdditionalDemurrage} onChange={this.onChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    {this.state.editDemurrageTimeType === "COMBINED BUSINESS" || this.state.editDemurrageTimeType === "COMBINED CALENDAR" || this.state.editDetentionTimeType === "COMBINED BUSINESS" || this.state.editDetentionTimeType === "COMBINED CALENDAR" ?
                                        <td> - </td>
                                        :
                                        <td className="demurrageCombo" style={{ marginTop: "6px" }}>
                                            <Form.NumberInput name={`editDetentionDays`} col="col s3" value={this.state.editDetentionDays} stylingClass="" onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.Select name={`editDetentionTimeType`} col="col s9" value={this.state.editDetentionTimeType} onChange={this.onChange} list={[{ label: "BUSINESS DAYS" }, { label: "CALENDAR DAYS" }]} filter={{ label: "label", value: "label" }} flags={[flags.ALLOW_EMPTY]} />
                                        </td>
                                    }
                                    <td>
                                        <Form.TextInput name={`editNotes`} value={this.state.editNotes} onChange={this.onChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.DateInput name={`editExpirationDate`} value={this.state.editExpirationDate} onChange={this.onChange} onFocus={this.props.markAsReviewed} flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td className="modalSaveIcon keep-black" style={{ marginTop: "12px" }}>
                                        <i tabIndex="0" className="material-icons" onClick={() => this.makeInactive(index)}>save</i>
                                    </td>
                                </tr>
                                :
                                <tr key={index} id={`modal-freetime-tr-${index}`} onContextMenu={e => this.handleCopyPasteMenu(e, index, f)}>
                                    <td>{this.props.country.filter(c => c.id === f.countryId)[0].name}</td>
                                    <td>{this.props.location.filter(l => l.id === parseInt(f.locationId))[0].name}</td>
                                    <td>{f.demurrageDays} {f.demurrageTimeType}</td>
                                    <td>{f.additionalDemurrage}</td>
                                    {f.demurrageTimeType === "COMBINED BUSINESS" || f.demurrageTimeType === "COMBINED CALENDAR" || f.detentionTimeType === "COMBINED BUSINESS" || f.detentionTimeType === "COMBINED CALENDAR" ?
                                        <td>-</td>
                                        :
                                        <td>
                                            {f.detentionDays}&nbsp;
                                            {f.detentionTimeType}
                                        </td>
                                    }
                                    <td>{f.notes}</td>
                                    <td>{f.expirationDate}</td>
                                    <td className="editAndDeleteIcons keep-black">
                                        <i tabIndex="0" className="material-icons" onClick={() => this.makeActive(index)}>edit</i>
                                        <i tabIndex="0" className="material-icons" onClick={() => this.props.removeFreetime(index)}>cancel</i>
                                    </td>
                                </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{ position: "fixed", top: (this.state.y) - 175, left: this.state.x }} className="lf-shipment-context-menu">
                <ul className="collection">
                    {this.state.showCopyMenu && <li className="collection-item orange-text" onClick={this.copyFreetime}>COPY FREETIME</li>}
                    {this.state.showPasteMenu && <li className="collection-item blue-text" onClick={this.pasteFreetime}>PASTE FREETIME</li>}
                </ul>
            </div>
        </div>
    )
}



const mapStateToProps = state => {
    const { urls, index, location, country } = state;
    const url = urls[index];
    return { url, location, country };
};

export default connect(mapStateToProps)(ModalFreetimeDisplay)