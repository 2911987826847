import React, { Fragment } from "react";
import { connect } from "react-redux";
import styles from "./Requests.module.css";

const CustomerRequestResultsDisplay = (props) => {
    return (
        <Fragment>
            {props.loading ?
                <tr>
                    <td>
                        <div className="preloader-wrapper big active overviewLoader">
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                :
                props.requests.map((req, index) => (
                    <tr
                        onClick={(e) => props.editRequest(e, req, index)}
                        tabIndex="0"
                        className={`resultsDisplay req-tr-${index} ${index === props.editRequestIndex ? styles.editRequestIndex : ""}`}
                        id={`${req.id}`}
                        key={index}
                        onContextMenu={(e) => props.handleHighlight(e, index, req)}
                        onFocus={index === 0 ? props.onFocusReset : function () { }}
                    >
                        <td className={styles[req.status]} onClick={(e) => props.editRequest(e, req, index)}>{req.status}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.statusMemo}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.datetimeRequestedOverview}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.assignedToFirstName}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.bookingRequestNumber}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.carrierCode}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.containerCount}x{req.containerType}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.originCode}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.destCode}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)} style={req.dateType !== "CUT" ? { color: "red" } : { color: 'black' }}>{req.overviewDateInfo}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.shipperReferenceNumber}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.consigneeName}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.productDescription}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.overviewNote}</td>
                    </tr>
                ))}
        </Fragment>
    )
}


const mapStateToProps = state => {
    const { location, user, urls, index } = state;
    const url = urls[index];
    return { location, user, url }
}

export default connect(mapStateToProps)(CustomerRequestResultsDisplay)