import React, { Component } from 'react';
import { connect } from "react-redux";
import Form from "../../../Components/NewComponents";
import axios from "axios";
import { globalToastActions } from '../../../Redux/actions';
import { auth } from "../../../helperFunctions";

class SalesRepDisplay extends Component {
    state = {
        contractSalesRep: this.props.carrierSalesReps.length ? this.props.carrierSalesReps[0].salesRepId : 0,
        salesRepName: "",
        salesRepEmail: "",
        salesRepRole: "SALES REP",
        salesRepLocation: "",
        salesRepOfficePhone: "",
        salesRepMobilePhone: "",
        editSalesRepName: "",
        editSalesRepEmail: "",
        editSalesRepRole: "",
        editSalesRepLocation: "",
        editSalesRepOfficePhone: "",
        editSalesRepMobilePhone: "",
        showAddSalesRep: false,
        loading: false
    }

    initListeners = () => {
        document.querySelectorAll(".salesRepDropdown").forEach( el => el.addEventListener('keydown', this.openSalesRepFormListener))
    }

    removeListeners = () => {
        document.querySelectorAll(".salesRepDropdown").forEach( el => el.removeEventListener('keydown', this.openSalesRepFormListener))
;    }

    openSalesRepFormListener = (e) => {
        if(e.key === "Enter"){
            document.activeElement.click();
        }
    }

    openSalesRepForm = () => {
        this.setState({ showAddSalesRep: !this.state.showAddSalesRep }, () => {
            if(this.state.showAddSalesRep){
                document.getElementById("salesRepName").focus();
            } else {
                document.querySelector(".salesRepDropdown").focus();
            }
        })
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    addSalesRep = () => {

        axios.post("/api/v1/contract/contractsalesrep", { ...auth.getAuthData(), salesRepId: this.state.contractSalesRep, contractId: this.props.contract.contractDetails.id }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return;
            } else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Sales Rep Added" } });
            }
            this.props.getContract();
        })
    }

    createAndAddSalesRep = (info) => {
        if(this.state.loading){
            return;
        }
        this.setState({ loading: true }, () => {
            let salesRep = {
                carrierId: this.props.contract.contractDetails.carrierId,
                name: info.salesRepName,
                role: info.salesRepType,
                location: info.salesRepLocation,
                officePhone: info.salesRepOfficePhone,
                mobilePhone: info.salesRepMobilePhone,
                email: info.salesRepEmail
            }
    
            axios.post("/api/v1/contract/salesrep", { ...auth.getAuthData(), salesRep, contractId: this.props.contract.contractDetails.id }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                } else if (result.errno) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                    return;
                } else {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Sales Rep Added" } });
                    this.setState({
                        salesRepName: "",
                        salesRepEmail: "",
                        salesRepRole: "SALES REP",
                        salesRepLocation: "",
                        salesRepOfficePhone: "",
                        salesRepMobilePhone: "",
                        loading: false
                    })
                    this.props.getContract();
                }
            });
        })
    };

    deleteContractSalesRep = (id) => {
        let contractId = this.props.contract.contractDetails.id;
        axios.post("/api/v1/contract/contractsalesrep/delete", { ...auth.getAuthData(), id, contractId }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return;
            } else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Sales Rep Deleted" } });
                this.props.getContract();
            }
        });
    }

    makeActive = (index) => {

        let salesRep = this.props.contract.contractSalesReps[index];

        this.setState({ 
            editRowIndex: index,
            editSalesRepName: salesRep.name,
            editSalesRepEmail: salesRep.email,
            editSalesRepRole: salesRep.role,
            editSalesRepLocation: salesRep.location,
            editSalesRepOfficePhone: salesRep.officePhone,
            editSalesRepMobilePhone: salesRep.mobilePhone
        });
    }

    makeInactiveAndSave = (index) => {

        let rep = this.state;

        let contractSalesRep = {
            id: this.props.contract.contractSalesReps[index].id,
            name: rep.editSalesRepName,
            role: rep.editSalesRepRole,
            location: rep.editSalesRepLocation,
            email: rep.editSalesRepEmail,
            officePhone: rep.editSalesRepOfficePhone,
            mobilePhone: rep.editSalesRepMobilePhone
        };
        let contractId = this.props.contract.contractDetails.id;

        axios.post("/api/v1/contract/salesrep/edit", { ...auth.getAuthData(), contractSalesRep, contractId }).then( async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return;
            } else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Sales Rep Updated" } });
                await this.props.getContract();
                this.setState({ 
                    editRowIndex: null,
                    editSalesRepName: "",
                    editSalesRepEmail: "",
                    editSalesRepRole: "",
                    editSalesRepLocation: "",
                    editSalesRepOfficePhone: "",
                    editSalesRepMobilePhone: ""           
                });
            }
        })
    }

    componentDidMount = () => {
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => {
        return (
            <div className="row">
                {/* <div className="row"> */}
                <div className="col s12">

                    <h5 style={{ marginTop: "0px" }}>Sales Reps</h5>
                    <div className="row">
                        <table className="displayTable">
                            <thead>
                                <tr className="tableHeaderLeftAlign">
                                    <th>Name</th>
                                    <th>Office Phone</th>
                                    <th>Mobile Phone</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Location</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.contract.contractSalesReps.map((c, index) => (
                                        this.state.editRowIndex === index ?
                                            <tr id={c.id} key={c.id}>
                                                <td>
                                                    <Form.TextInput col={`col s12`} onChange={this.onChange} value={this.state.editSalesRepName} name={`editSalesRepName`} />
                                                </td>
                                                <td>
                                                    <Form.TextInput col={`col s12`} onChange={this.onChange} value={this.state.editSalesRepOfficePhone} name={`editSalesRepOfficePhone`} />
                                                </td>
                                                <td>
                                                    <Form.TextInput col={`col s12`} onChange={this.onChange} value={this.state.editSalesRepMobilePhone} name={`editSalesRepMobilePhone`} />
                                                </td>
                                                <td>
                                                    <Form.TextInput col={`col s12`} onChange={this.onChange} value={this.state.editSalesRepEmail} name={`editSalesRepEmail`} />
                                                </td>
                                                <td>
                                                    <Form.Select col={`col s12`} onChange={this.onChange} value={this.state.editSalesRepRole} name={`editSalesRepRole`} list={[{ label: "SALES REP" }, { label: "INSIDE SALES" }, { label: "MANAGER" }]} filter={{ label: "label", value: "label" }} />
                                                </td>
                                                <td>
                                                    <Form.TextInput col={`col s12`} onChange={this.onChange} value={this.state.editSalesRepLocation} name={`editSalesRepLocation`} />
                                                </td>
                                                <td className="modalSaveIcon">
                                                    <i tabIndex="0" className={`material-icons`} onClick={() => this.makeInactiveAndSave(index)}>save</i>
                                                </td>
                                            </tr>
                                            :
                                            <tr id={c.id} key={c.id}>
                                                <td>{c.name}</td>
                                                <td>{c.officePhone}</td>
                                                <td>{c.mobilePhone}</td>
                                                <td>{c.email}</td>
                                                <td>{c.role}</td>
                                                <td>{c.location}</td>
                                                <td className="editAndDeleteIcons">
                                                    <i tabIndex="0" className={`material-icons editIcons`} onClick={() => this.makeActive(index)}>edit</i>
                                                    <i tabIndex="0" style={{ textAlign: "center" }} className={`material-icons editIcons`} onClick={() => this.deleteContractSalesRep(c.id)}>cancel</i>
                                                </td>
                                            </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row" style={{ marginBottom: "0px" }}>
                        <Form.Select col="col s10" label="Choose Sales Rep" name="contractSalesRep" onChange={this.onChange} value={this.state.contractSalesRep} list={this.props.carrierSalesReps} filter={{ label: "name", value: "salesRepId" }} />
                        <Form.Button col="col s2" label="Add" icon="add" type="outline" color="blue" onClick={this.addSalesRep} />
                    </div>
                </div>
                {!this.state.showAddSalesRep ?
                    <div className="col s12 formDropdown salesRepDropdown" tabIndex="0" onClick={this.openSalesRepForm}>
                        <i className="material-icons">keyboard_arrow_down</i><span>Create Sales Rep</span>
                    </div>
                    :
                    <div className="col s12">
                        <div className="row">
                            <div className="col s12 formDropdown salesRepDropdown" tabIndex="0" onClick={this.openSalesRepForm} >
                                <i className="material-icons">keyboard_arrow_up</i><span>Hide Form</span>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "0px" }}>
                            <h6>Create Sales Rep</h6>
                        </div>
                        <div className="row">
                            <Form.TextInput col="col s4" label="Name" name="salesRepName" value={this.state.salesRepName} onChange={this.onChange} />
                            <Form.TextInput col="col s4" label="Email" name="salesRepEmail" value={this.state.salesRepEmail} onChange={this.onChange} />
                            <Form.Select col="col s4" label="Type" name="salesRepRole" value={this.state.salesRepRole} onChange={this.onChange} list={[{ label: "SALES REP" }, { label: "INSIDE SALES" }, { label: "MANAGER" }]} filter={{ label: "label", value: "label" }} />
                        </div>
                        <div className="row">
                            <Form.TextInput col="col s4" placeholder="Phoenix, AZ" label="Location" name="salesRepLocation" value={this.state.salesRepLocation} onChange={this.onChange} />
                            <Form.TextInput col="col s4" label="Office Number" name="salesRepOfficePhone" value={this.state.salesRepOfficePhone} onChange={this.onChange} />
                            <Form.TextInput col="col s4" label="Mobile Number" name="salesRepMobilePhone" value={this.state.salesRepMobilePhone} onChange={this.onChange} />
                        </div>

                        <div className="row">
                            <Form.Button col="col s2" label="Create and Add Rep" type="outline" color="blue" onClick={() => this.createAndAddSalesRep(this.state)} />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { index, urls, location, carrier, shipper } = state;
    const url = urls[index];
    return { url, location, shipper, carrier };
};

export default connect(mapStateToProps)(SalesRepDisplay);