import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from "../../Components/NewComponents";
import axios from "axios";
import { globalToastActions } from '../../Redux/actions';
import { auth } from "../../helperFunctions";

class CommissionReport extends Component {
    state = {
        filters: {
            shipperId: "0",
            carrierId: "0",
            originId: "0",
            destinationId: "0",
            startDate: "",
            endDate: "",
            currentSort: "",
            reverse: ""
        },
        carrierList: [],
        originList: [],
        destinationList: [],
        commissionList: []
    }

    onChange = (name, value) => this.setState({ [name]: value });

    filterChange = (name, value) => this.setState({ filters: { ...this.state.filters, [name]: value } })

    getPortOptions = () => {
        let originList = [];
        let destinationList = [];

        this.props.location.forEach(l => {
            if (l.typeId !== 3) {
                if (l.countryId === 1 || l.countryId === 17) {
                    originList.push(l)
                } else {
                    destinationList.push(l)
                }
            }
        })
        this.setState({ originList, destinationList })
    }

    generateReport = async () => {

        if (!this.state.filters.endDate || !this.state.filters.startDate) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Date Range Required", type: "error" } });
            return;
        }

        let filters = this.state.filters;

        axios.get("/api/v1/report/commissionreport", { params: { ...auth.getAuthData(), filters } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({
                    type: "SCORCHED_EARTH"
                })
                return;
            }
            if (!result.data.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Results", type: "warning" } });
            }

            this.setState({ commissionList: result.data })
        })
    }

    sort = e => {
        const value = e.target.getAttribute('data-sort');
        const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
        this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => { this.generateReport()})
    }

    componentDidMount = () => {
        this.getPortOptions();
    }

    componentDidUpdate = () => { }

    render = () => {
        return (
            <div>
                <h4>Ocean Freight Report</h4>
                <div>&nbsp;</div>
                <div className="row">
                    <Form.Select col="col s2" label="Shipper" onChange={this.filterChange} placeholder="Select a Shipper" name="shipperId" value={this.state.filters.shipperId} list={this.props.shipper} filter={{ label: "name", value: "id" }} />
                    <Form.Select col=" col s2" label="Carrier" onChange={this.filterChange} placeholder="Select a Carrier" name="carrierId" value={this.state.filters.carrierId} list={this.props.carrier} filter={{ label: "name", value: "id" }} />
                    <Form.Select col="col s2" label="Origin" onChange={this.filterChange} placeholder="Select an Origin" name="originId" value={this.state.filters.originId} list={this.state.originList} filter={{ label: "name", value: "id" }} />
                    <Form.Select col="col s2" label="Destination" onChange={this.filterChange} placeholder="Select a Destination" name="destinationId" value={this.state.filters.destinationId} list={this.state.destinationList} filter={{ label: "name", value: "id" }} />
                    <Form.DateInput col="col s2" label="Start Date" onChange={this.filterChange} name="startDate" value={this.state.filters.startDate} />
                    <Form.DateInput col="col s2" label="End Date" onChange={this.filterChange} name="endDate" value={this.state.filters.endDate} />
                </div>
                <div className="row">
                    <Form.Button col="col s2" onClick={this.generateReport} label="Generate Report" type="outline" color="blue" />
                </div>
                <div className="row">
                    <table id="commissionReportTable" className="col s8">
                        <thead>
                            <tr>
                                <th data-sort="lNumber" onClick={this.sort}>L# &nbsp;
                        {this.state.filters.currentSort === "lNumber" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>                                
                                <th></th>
                                <th data-sort="shipperName" onClick={this.sort}>Shipper &nbsp;
                        {this.state.filters.currentSort === "shipperName" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                                <th></th>
                                <th data-sort="carrierName" onClick={this.sort}>Carrier &nbsp;
                        {this.state.filters.currentSort === "carrierName" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                                <th></th>
                                <th data-sort="cargoCutDate" onClick={this.sort}>Cargo Cut &nbsp;
                        {this.state.filters.currentSort === "cargoCutDate" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                                <th></th>
                                <th data-sort="originName" onClick={this.sort}>Origin &nbsp;
                        {this.state.filters.currentSort === "originName" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                                <th></th>
                                <th data-sort="destinationName" onClick={this.sort}>Destination &nbsp;
                        {this.state.filters.currentSort === "destinationName" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                                <th></th>
                                <th data-sort="commissionRate" onClick={this.sort}>Rate Per Container &nbsp;
                        {this.state.filters.currentSort === "commissionRate" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                                <th></th>
                                <th>Average Freight Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.commissionList.length ? this.state.commissionList.map((c, index) => (
                                <tr key={index}>
                                    <td>{c.lNumber}</td>
                                    <td></td>
                                    <td>{c.shipperName}</td>
                                    <td></td>
                                    <td>{c.carrierName}</td>
                                    <td></td>
                                    <td>{c.cargoCutDate}</td>
                                    <td></td>
                                    <td>{c.originName}</td>
                                    <td></td>
                                    <td>{c.destinationName}</td>
                                    <td></td>
                                    <td>{c.commissionRatePerContainer}</td>
                                    <td></td>
                                    <td className="commissionAverage">{c.averageRate}</td>
                                </tr>
                            )) :
                                null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { urls, index, party, shipper, carrier, location } = state;
    const url = urls[index];
    return { shipper, party, url, carrier, location }
}

export default connect(mapStateToProps)(CommissionReport);
