import React from 'react';
import { connect } from 'react-redux';
import logo from '../../images/lf_logo.png';
import './NavBar.css';
import M from 'materialize-css';
import UserMenu from './UserMenu';

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({ shipperList: this.props.redux.party, filterByShipper: this.props.redux.filterByShipper })

    initMaterialize = () => M.FormSelect.init(document.querySelectorAll('select'));
    onChange = (name, value) => this.setState({ [name]: value });

    componentDidMount = () => { this.initMaterialize(); }
    componentDidUpdate = () => { this.initMaterialize(); }

    render() {
        return (
            <nav>
                <div className="nav-wrapper white black-text row">
                    <div className="brand-logo black-text left"><img className="logo-img" src={logo} alt="Lindsey Forwarders Logo." />Lindsey Forwarders</div>
                    <div className="brand-logo blue-text right" style={{ fontSize: '16px', marginRight: "100px"}}><UserMenu /></div>
                </div>
            </nav>
        )
    }
}

const mapStateToProps = state => {
    const { navs, index, party, user } = state;
    const filterByShipper = navs[index].filterByShipper;
    const shipperId = navs[index].shipperId;
    return ({ redux: { filterByShipper, party, shipperId, user } });
}

export default connect(mapStateToProps)(NavBar);