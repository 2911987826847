import React from 'react';
import MaskedInput from 'react-text-mask';
import { inputValidation } from '../../helperFunctions';
import styles from './date.module.css';
import { types, flags } from '../../constants';

class Phone extends React.Component {
    constructor(props) { super(props); this.state = { value: '' } }


    genDivClass = () => (`${this.props.col ? `col ${this.props.col}` : ''} ${styles.div}`)
    onChange = e => this.setState({ value: e.target.value }, this.props.onChange(this.props.name, e.target.value));
    onBlur = () => inputValidation(this.getInput(), this.success, this.failure);
    getInput = () => ([{ name: 'value', type: types.STRING, value: this.props.value.replace(/\D/g, ''), flags: this.getFlags() }]);
    success = msg => this.setState({ err: false, msg: null })
    failure = msg => this.setState({ err: true, msg: 'Invalid Phone #' })
    getFlags = () => {
        if (this.props.flags)
            return [...this.props.flags, flags.EXACT_LENGTH + 10];
        else
            return [flags.EXACT_LENGTH + 10]
    }

    render = () => (
        <div className={this.genDivClass()}>
            <MaskedInput
                className={`browser-default ${styles.input} ${this.state.err ? styles.err : ''}`}
                type="text"
                mask={['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                onChange={this.onChange}
                onBlur={this.onBlur}
                value={this.props.value}
                placeholder="(555)123-4567"
                style={{fontSize: '1.08em'}}
            />
            <label className={styles.label}>{this.props.label}</label>
            <span className={styles.msg}>{this.state.msg}</span>
        </div>
    )
}

export default Phone;