import React from 'react';
import Form from '../../../../Components/NewComponents';
import { flags } from '../../../../constants';

const SI =(props)=>{

    return (
        <div>
            <div className="row" style={{ margin: "0px", marginTop: "-20px" }}>
                <Form.TextInput tabIndex="2" col="s2" name="marksAndNumbers" label="Marks & Numbers" value={props.marksAndNumbers || ''} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput tabIndex="2" col="s2" name="numberOfPackages" label="Number of Packages" value={props.numberOfPackages || ''} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                <div className="col s4">
                    <Form.TextArea tabIndex="2" name="description" label="Description" value={props.description} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} height="100px" />
                </div>
                <Form.TextInput tabIndex="2" col="s2" name="weight" label="Gross Weight (KGS)" value={props.weight || ''} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput tabIndex="2" col="s2" name="measurement" label="Measurement (CBM)" value={props.measurement || ''} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
            </div>
        </div>
    )


}

export default SI;