import { Routes, Route } from 'react-router-dom';
import VesselOverview from './VesselOverview';
import CreateVessel from './CreateVessel';


const Vessels = props => (
    <Routes>
        <Route path="/" element={<VesselOverview />} />
        <Route path="/create" element={<CreateVessel />} />
    </Routes>
)


export default Vessels;