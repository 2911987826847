import React from 'react';
import { connect } from 'react-redux';
import { flags } from '../../constants';
import Form from '../../Components/NewComponents';
import axios from 'axios'
import moment from 'moment';
import M from 'materialize-css';
import RequestResultsDisplay from './RequestResultsDisplay.js';
import CustomerRequestResultsDisplay from './CustomerRequestResultsDisplay.js';
import RequestModal from "./RequestModal.js";
import RequestForm from "./RequestForm";
import PageCountDisplay from '../Shipments/PageCountDisplay/PageCountDisplay';
import { globalToastActions } from '../../Redux/actions';
import styles from './Requests.module.css';
import "./Requests.css";
import { auth } from "../../helperFunctions";
import socket from "../../Socket/socket";
import TableHeader from '../../Components/TableHeader/TableHeader';

class RequestOverview extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({
        collapsibleIsOpen: this.props.user.isEmployee ? false : true,
        employee: this.props.user.isEmployee ? true : false,
        shipperId: this.props.user.isEmployee ? 0 : this.props.user.partyId,
        origins: [],
        editOrigins: [],
        destinations: [],
        editDestinations: [],
        carriers: [],
        editCarriers: [],
        allConsignees: [],
        editConsignees: [],
        templateList: [],
        editTemplateList: [],
        statusList: [
            { name: "NEW" },
            { name: "REQUESTED" },
            { name: "PENDING" },
            { name: "UPDATING" },
            { name: "DECLINED" },
            { name: "COMPLETED" },
            { name: "CLOSED" }
        ],
        filterStatusList: [
            { name: "NEW" },
            { name: "REQUESTED" },
            { name: "PENDING" },
            { name: "UPDATING" },
            { name: "DECLINED" },
            { name: "COMPLETED" },
            { name: "CLOSED" },
            { name: "DELETED" }
        ],
        statusMemoList: [
            { name: "Outside the booking window" },
            { name: "Pending vessel space" },
            { name: "Pending approval" },
            { name: "Declined due to vessel space" },
            { name: "Declined due to no rate" },
            { name: "Declined due to equipment" },
            { name: "Modifying booking to fit request" },
            { name: "Exploring alternative carriers" }
        ],
        requests: [],
        msg: { color: '', text: '' },
        loading: true,
        editRequestIndex: '',
        editRequestId: '',
        filters: {
            status: "ALL",
            datetimeRequestedStart: "",
            datetimeRequestedEnd: "",
            assignedTo: this.props.user.isEmployee ? this.props.user.id : 0,
            bookingRequestNumber: "",
            shipperName: "",
            carrierName: "",
            originName: "",
            destName: "",
            consigneeName: '',
            shipperReferenceNumber: "",
            currentSort: 'datetimeRequested',
            reverse: '-1',
            currentSort2: "",
            reverse2: 1,
            currentSort3: "",
            reverse3: 1,
            tz: moment.tz.guess(),
            pageSize: 50,
            page: 0,
            count: [],
            resultsCount: null,
            showCount: true
        },
        contracts: [],
        updateModalInfo: false
    })

    initListeners = () => {
        document.querySelectorAll('.search-field').forEach(elem => elem.addEventListener('keyup', this.searchListener));
        document.addEventListener("keydown", this.arrowKeyListener);
        document.addEventListener("keydown", this.enterListener);
        document.addEventListener("keydown", this.escListener);
    }

    removeListeners = () => {
        document.querySelectorAll('.search-field').forEach(elem => elem.removeEventListener('keyup', this.searchListener));
        document.removeEventListener("keydown", this.arrowKeyListener);
        document.removeEventListener("keydown", this.enterListener);
        document.removeEventListener("keydown", this.escListener);
    }

    searchListener = e => {
        if (e.keyCode === 13) this.getBookingRequests();
    }

    clickListener = () => {
        this.setState({ showStatusMenu: false }, () => {
            document.removeEventListener('click', this.clickListener)
        })
    }

    arrowKeyListener = (e) => {
        let tag = document.activeElement.tagName;
        if (tag !== "TR") {
            return
        } else if ((e.code === "Tab" && e.shiftKey) && this.state.editRequestIndex === 0) {
            return;
        } else if ((e.code === "ArrowUp" || (e.code === "Tab" && e.shiftKey)) && this.state.editRequestIndex !== 0) {
            e.preventDefault();
            this.setState({ editRequestIndex: this.state.editRequestIndex - 1 }, () => {
                let row = document.querySelector(`.req-tr-${this.state.editRequestIndex}`);
                if (row) {
                    row.focus();
                }
            });
        } else if ((e.code === "ArrowDown" || e.code === "Tab") && this.state.editRequestIndex !== this.state.requests.length + 1) {
            e.preventDefault();
            this.setState({ editRequestIndex: this.state.editRequestIndex + 1 }, () => {
                let row = document.querySelector(`.req-tr-${this.state.editRequestIndex}`);
                if (row) {
                    row.focus();
                }
            });
        } else {
            return;
        }
    }

    enterListener = (e) => {
        if (e.key === "Enter") {
            if (document.activeElement.tagName === "TR") {
                document.activeElement.click();
            }
        }
    }

    escListener = (e) => {
        let row = document.querySelector(`.req-tr-${this.state.editRequestIndex}`);
        if (this.state.editRequestIndex && e.code === "Escape" && !!row.length) {
            setTimeout(() => {
                document.querySelector(`.req-tr-${this.state.editRequestIndex}`).focus();
            }, 200)
        }
    }

    onFocusReset = () => {
        this.setState({ editRequestIndex: 0 })
    }

    refreshModalSettings = () => {
        this.setState({ contracts: [], settingReminder: false, editing: false })
    }

    setReminderSectionAsOpen = () => {
        this.setState({ settingReminder: true });
    }

    setEditingTrue = (shipperId) => {
        this.getEditShipperLists(shipperId);
        this.setState({ editing: true });
    }

    resetUpdateModalInfo = () => {
        this.setState({ updateModalInfo: false })
    }

    initModal = () => {

        M.Modal.init(document.querySelector(".templateModal"));
        M.Modal.init(document.querySelector(".bookingRequestModal"),
            {
                onOpenStart: () => { this.refreshModalSettings() },
                onCloseEnd: () => { this.focusEditRow() }
            }
        );

    }

    focusEditRow = () => {
        if (document.querySelector(`.req-tr-${this.state.editRequestIndex}`)) {
            document.querySelector(`.req-tr-${this.state.editRequestIndex}`).focus();
        } else {
            return;
        }
    }

    openModal = () => {
        M.Modal.getInstance(document.querySelector('.bookingRequestModal')).open();
        setTimeout(() => {
            document.getElementById("editButton").focus()
        }, 200)
    };


    closeModal = () => {
        M.Modal.getInstance(document.querySelector('.bookingRequestModal')).close();
    }

    clickListener = () => {
        this.setState({ showStatusMenu: false }, () => {
            document.removeEventListener('click', this.clickListener)
        })
    }

    handleStatusMenu = (e, editRequestId) => {
        if (!this.state.employee) {
            return;
        }
        e.preventDefault();
        this.setState({ editRequestId, showStatusMenu: true, x: e.pageX, y: e.pageY })
        document.addEventListener('click', this.clickListener);
    }

    handleMouseDownHighlight = (e) => {
        if (e.ctrlKey && e.buttons === 1 && e.target.parentElement.tagName === "TR") {
            let list = []
            e.target.parentElement.classList.forEach(c => list.push(c))
            if (list.includes("menu-selected")) {
                e.target.parentElement.classList.remove("menu-selected");
            } else {
                e.target.parentElement.classList.add("menu-selected");
            }
            this.setState({ rowsAreSelected: !!document.querySelectorAll('.menu-selected').length })
        }
    }

    markAsRead = (id) => {
        axios.post("/api/v1/request/e/read", { ...auth.getAuthData(), id }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.getBookingRequests();
                this.emitUpdateTrigger([this.state.requests.filter(r => r.id === id)[0].shipperId])
            }
        })
    }

    markAsRequiresAttention = () => {

        let idList = Array.from(document.querySelectorAll('.menu-selected'), row => parseInt(row.id));
        if (!idList.length) {
            return;
        }
        axios.post("/api/v1/request/e/attention", { ...auth.getAuthData(), idList }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.setState({ rowsAreSelected: false }, () => {
                    this.getBookingRequests();
                    this.emitUpdateTrigger([...new Set(this.state.requests.filter(r => idList.includes(r.id)).map(rl => rl.shipperId))])
                    document.querySelectorAll('.menu-selected').forEach(row => row.classList.remove("menu-selected"));
                });
            }
        })
    }

    changeStatus = (status) => {
        axios.post("/api/v1/request/e/status", { ...auth.getAuthData(), status, id: this.state.editRequestId }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            }
            this.getBookingRequests();
            this.emitUpdateTrigger([this.state.requests.filter(r => r.id === this.state.editRequestId)[0].shipperId]);
            document.querySelectorAll('.menu-selected').forEach(row => row.classList.remove("menu-selected"));
        })
    }

    onChange = (name, value) => {
        this.setState({ [name]: value })
    };

    multiRowOnChange = (name, value) => {
        let idList = Array.from(document.querySelectorAll('.menu-selected'), row => parseInt(row.id));
        let reqList = this.state.requests.filter(r => idList.includes(r.id));
        let type;
        switch (name) {
            case "rowAssignedTo":
                type = 'assignedto';
                break;
            case "rowStatusMemo":
                type = 'statusmemo';
                break;
            case 'rowStatus':
                type = 'status';
                break;
            default:
                break
        }

        axios.post(`/api/v1/request/e/multi${type}`, { ...auth.getAuthData(), idList, reqList, value: value }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.setState({ rowsAreSelected: false }, () => {
                    this.getBookingRequests();
                    this.emitUpdateTrigger([...new Set(reqList.map(r => r.shipperId))])
                    document.querySelectorAll('.menu-selected').forEach(row => row.classList.remove("menu-selected"));
                })
            }
        })
    }


    filterOnChange = (name, value) => this.setState({ filters: { ...this.state.filters, [name]: value } });

    filterSelectOnChange = (name, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        }, () => {
            this.getBookingRequests();
        });
    }

    emitUpdateTrigger = (relevantShippers) => {
        socket.emit('updateRequest', {
            date: Date(),
            user: {
                userId: this.props.user.id,
                partyId: this.props.user.partyId,
                isEmployee: this.props.user.isEmployee,
                userEmail: this.props.user.emailAddress
            },
            relevantShippers
        })
    };

    updateTriggerResponse = () => {
        socket.on('updateRequest', (msg) => {
            if (this.state.employee || msg.relevantShippers.map(id => parseInt(id, 10)).includes(this.props.user.partyId)) {
                this.getBookingRequests(true);
            } else {
                return;
            }
        })
    }

    getBookingRequests = (calledFromSubmit) => {
        return new Promise((resolve, reject) => {
            axios.get(`/api/v1/request/${this.state.employee ? 'e' : 'c'}`, { params: { ...auth.getAuthData(), filters: this.state.filters } }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' });
                    return;
                }
                if (calledFromSubmit) {
                    let requests = this.state.requests.map(r => r.id);
                    let newRequests = result.data.result.map(r => r.id);
                    let addedRequests = newRequests.filter(r => !requests.includes(r));
                    this.setState({ updateModalInfo: true, settingReminder: false, editing: false, requests: result.data.result, loading: false, editRequestIndex: null }, async () => {
                        if (addedRequests.length && requests.length) {
                            await addedRequests.forEach(r => {
                                document.getElementById(r.toString()).classList.add(this.state.employee ? 'newRequestEmployee' : 'newRequestCustomer');
                            });
                            setTimeout(async () => {
                                await addedRequests.forEach(r => {
                                    document.getElementById(r.toString()).classList.remove(this.state.employee ? 'newRequestEmployee' : 'newRequestCustomer');
                                });
                                resolve();
                            }, 2000);
                        } else {
                            resolve();
                        }
                    })
                } else {
                    if (document.querySelector('.bookingRequestModal')) {
                        let modalIsOpen = M.Modal.getInstance(document.querySelector('.bookingRequestModal')).isOpen;
                        this.setState({ updateModalInfo: true, settingReminder: false, editing: false, requests: result.data.result, loading: false, editRequestIndex: modalIsOpen ? this.state.editRequestIndex : null });
                    } else {
                        this.setState({ updateModalInfo: true, settingReminder: false, editing: false, requests: result.data.result, loading: false, editRequestIndex: null });
                    }
                }
                this.getRequestsCount();
                resolve();
            })
        })
    }

    getRequestsCount = async () => {
        let result = (await axios.get(`/api/v1/request/${this.state.employee ? 'e' : 'c'}/count`, { params: { ...auth.getAuthData(), filters: this.state.filters, filterList: this.state.filterList } })).data
        let showCount = result.showCount;
        let amount = result.result[0].count;
        let list = Math.ceil(amount / this.state.filters.pageSize);
        let count = [];
        for (list; list > 0; list--) {
            count.push(list)
        }
        this.setState({ filters: { ...this.state.filters, count: count.reverse(), resultsCount: amount, showCount: showCount } })
    }

    getEditShipperLists = (shipperId) => {
        axios.get("/api/v1/request/shipperlists", { params: { ...auth.getAuthData(), shipperId } }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else {
                let { origins, destinations, carriers, templates, consignees } = result.data;
                if (!origins.length) {
                    origins = [{ name: "No Contract Origins", id: 0 }]
                }
                if (!destinations.length) {
                    destinations = [{ name: "No Contract Destinations", id: 0 }]
                }
                return this.setState({
                    editOrigins: origins,
                    editOriginId: origins[0].id,
                    editDestinations: destinations,
                    editDestId: destinations[0].id,
                    editCarriers: carriers,
                    editConsignees: consignees,
                    editTemplateList: templates
                });
            }
        })
    }

    getAllContracts = (originState, originId, destId, shipperId) => {
        axios.get("/api/v1/contract/e/requestrates", { params: { ...auth.getAuthData(), shipperId, originState, originId, destId } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else if (!result.data.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "There are no rates for this requests combination of information." } })
            } else {
                this.setState({ contracts: result.data });
            }
        })
    }

    sort = e => {
        if (!e.target.getAttribute("data-sort")) {
            return;
        } else if (e.ctrlKey) {
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort2 : e.target.getAttribute('data-sort');
            const reverse2 = this.state.filters.currentSort2 === value ? (-1 * this.state.filters.reverse2) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort2: value, reverse2 } }, () => { this.getBookingRequests(); })
        } else if (e.altKey) {
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort3 : e.target.getAttribute('data-sort');
            const reverse3 = this.state.filters.currentSort3 === value ? (-1 * this.state.filters.reverse3) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort3: value, reverse3 } }, () => { this.getBookingRequests(); })
        } else {
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort : e.target.getAttribute('data-sort');
            const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => { this.getBookingRequests(); })
        }
    }

    deleteRequest = () => {

        let idList = Array.from(document.querySelectorAll('.menu-selected'), row => parseInt(row.id));
        let reqList = this.state.requests.filter(r => idList.includes(r.id));
        let isEmployee = this.state.employee ? 1 : 0;

        if (window.confirm("Are you sure you would like to delete the selected Booking Request(s)?")) {
            axios.post("/api/v1/request/delete", { ...auth.getAuthData(), idList, reqList, isEmployee }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                } else {
                    this.setState({ rowsAreSelected: false }, () => {
                        this.getBookingRequests();
                        this.emitUpdateTrigger([...new Set(reqList.map(r => r.shipperId))])
                        document.querySelectorAll('.menu-selected').forEach(row => row.classList.remove("menu-selected"));
                    });
                }
            })
        }
    }

    editRequest = (e, req, index) => {
        if (e.ctrlKey) {
            return;
        } else {
            if (!this.state.requests[index].hasBeenRead && this.state.employee && this.props.user.id === this.state.requests[index].assignedTo) {
                this.markAsRead(this.state.requests[index].id);
            }
            this.setState({ editRequestIndex: index }, () => { this.openModal(); });
        }
    };


    pageCheck = () => {
        if (this.props.location?.state?.filters) {
            if (this.props.location.state.filters.page && this.props.location.state.filters.page > 0) {
                this.setState({ filters: { ...this.state.filters, page: this.props.location.state.filters.page } })
            }
        }
    }

    setPage = (page) => {
        return new Promise((resolve, reject) => {
            if (page === undefined) {
                page = 0
            }
            if (page < 0) {
                return
            }
            if (this.state.filters.count.length < 1) {
                page = 0
            } else if (this.state.filters.count.length > 0) {
                if (page === this.state.filters.count.length) {
                    return
                }
            }
            this.setState({ filters: { ...this.state.filters, page: page } })
            resolve(page);
            reject(new Error('Promise Rejected'))
        }).catch(e => {
            console.log(e)
        })
    }

    singlePageTurn = async (e) => {
        let direction = e.target.getAttribute('name')
        let page = await this.state.filters.page;
        direction === 'back' ? page-- : page++;
        this.setPage(page).then(() => {
            this.getBookingRequests();
        })
    }

    pageJump = (e) => {
        let choice = e.target.getAttribute("name");
        this.setPage((parseInt(choice) - 1)).then(() => {
            this.getBookingRequests();
        })
    }

    componentDidMount = () => {
        this.getBookingRequests();
        this.initModal();
        this.initListeners();
        this.updateTriggerResponse();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => (
        <div>
            <RequestForm
                getBookingRequests={this.getBookingRequests}
                emitUpdateTrigger={this.emitUpdateTrigger}
            />
            {!this.state.employee ?
                <div>
                    {this.state.rowsAreSelected ?
                        <div className={`row ${styles.noMarginBottom} ${styles.buttonRow}`}>
                            <Form.Button col="col s3" label="Delete" type="outline" color="filledRed" onClick={this.deleteRequest} />
                        </div>
                        :
                        <div className={`row ${styles.noMarginBottom}`}>
                            <Form.Select col="col s2 search-field" label="Status" name="status" value={this.state.filters.status} onChange={this.filterSelectOnChange} placeholder="ALL" list={this.state.filterStatusList} filter={{ label: 'name', value: 'name' }} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="s2 search-field" label="Bkg Req #" name="bookingRequestNumber" value={this.state.filters.bookingRequestNumber} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 search-field" label="Carrier" onChange={this.filterOnChange} name="carrierName" value={this.state.filters.carrierName} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s1 search-field" label="Origin" name="originName" value={this.state.filters.originName} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s1 search-field" label="Dest" name="destName" value={this.state.filters.destName} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 search-field" label="Shipper Ref" name="shipperReferenceNumber" value={this.state.filters.shipperReferenceNumber} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 search-field" label="Consignee" name="consigneeName" value={this.state.filters.consigneeName} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                    }
                    <table className="lf-portal-table-requests">
                        <TableHeader
                            headers={[
                                { label: "Status", attribute: "status" },
                                { label: "Status Memo", attribute: "statusMemo", className: styles.statusMemoColumn },
                                { label: "Req.", attribute: "datetimeRequested" },
                                { label: "Assn.", attribute: "assignedTo" },
                                { label: "Booking #", attribute: "bookingRequestNumber" },
                                { label: "Carrier", attribute: "carrierName" },
                                { label: "FCL", attribute: "containerType" },
                                { label: "Origin", attribute: "originName" },
                                { label: "Dest", attribute: "destName" },
                                { label: "Date", attribute: "startDate" },
                                { label: "Ref. #", attribute: "shipperReferenceNumber" },
                                { label: "Consignee", attribute: "consigneeName" },
                                { label: "Product", attribute: "productDescription" },
                                { label: "Note", attribute: "note" }
                            ]}
                            sort={this.sort}
                            filters={this.state.filters}
                        />
                        <tbody>
                            <CustomerRequestResultsDisplay
                                requests={this.state.requests}
                                editRequest={this.editRequest}
                                editRequestIndex={this.state.editRequestIndex}
                                loading={this.state.loading}
                                handleMouseDownHighlight={this.handleMouseDownHighlight}
                                onFocusReset={this.onFocusReset}
                            />
                        </tbody>
                    </table>
                </div>
                :
                <div>
                    {this.state.rowsAreSelected ?
                        <div>
                            <div className={`row ${styles.noMarginBottom} ${styles.defaultEmptyRow}`}>
                            </div>
                            <div className={`row ${styles.noMarginBottom} ${styles.buttonRow}`}>
                                <Form.Select col="col s2" label="Status" name="rowStatus" value={this.state.rowStatus} onChange={this.multiRowOnChange} list={this.state.statusList} filter={{ label: 'name', value: 'name' }} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Select col="col s2" label="Status Memo" name="rowStatusMemo" value={this.state.rowStatusMemo} onChange={this.multiRowOnChange} placeholder="(NONE)" list={this.state.statusMemoList} filter={{ label: 'name', value: 'name' }} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Select col="col s2" label="Assigned To" name="rowAssignedTo" value={this.state.rowAssignedTo} onChange={this.multiRowOnChange} placeholder="Choose..." list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Button col="col s2" label="Toggle Flag" type="outline" color="filledBlue" onClick={this.markAsRequiresAttention} />
                                <Form.Button col="col s2" label="Delete" type="outline" color="filledRed" onClick={this.deleteRequest} />
                            </div>
                        </div>
                        :
                        <div>
                            <div className={`row ${styles.noMarginBottom}`}>
                                <Form.Select col="col s2 search-field" label="Status" name="status" value={this.state.filters.status} onChange={this.filterSelectOnChange} placeholder="ALL" list={this.state.filterStatusList} filter={{ label: 'name', value: 'name' }} flags={[flags.ALLOW_EMPTY]} />
                                <Form.DateInput col="s2 search-field" label="Date Req. Start" name="datetimeRequestedStart" value={this.state.filters.datetimeRequestedStart} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.DateInput col="s2 search-field" label="Date Req. End" name="datetimeRequestedEnd" value={this.state.filters.datetimeRequestedEnd} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" label="Bkg Req #" name="bookingRequestNumber" value={this.state.filters.bookingRequestNumber} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="col s2 search-field" label="Shipper" onChange={this.filterOnChange} name="shipperName" value={this.state.filters.shipperName} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="col s2 search-field" label="Carrier" onChange={this.filterOnChange} name="carrierName" value={this.state.filters.carrierName} flags={[flags.ALLOW_EMPTY]} />
                            </div>
                            <div className={`row ${styles.noMarginBottom}`}>
                                <Form.TextInput col="col s2 search-field" label="Origin" name="originName" value={this.state.filters.originName} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="col s2 search-field" label="Dest" name="destName" value={this.state.filters.destName} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Select col="col s2 search-field" label="Assn." placeholder="ALL" name="assignedTo" value={this.state.filters.assignedTo} onChange={this.filterSelectOnChange} list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="col s2 search-field" label="Shipper Ref" name="shipperReferenceNumber" value={this.state.filters.shipperReferenceNumber} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="col s4 search-field" label="Consignee" name="consigneeName" value={this.state.filters.consigneeName} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                            </div>
                        </div>
                    }
                    <table className="lf-portal-table-requests">
                        <thead className="lf-portal-thead">
                            <tr>
                                <th data-sort="hasReminder" onClick={this.sort}> &nbsp;
                                    <span data-sort="hasReminder" onClick={this.sort}>{this.state.filters.currentSort === "hasReminder" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="hasReminder" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "hasReminder" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="hasReminder" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "hasReminder" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="status" onClick={this.sort}>Status &nbsp;
                                    <span data-sort="status" onClick={this.sort}>{this.state.filters.currentSort === "status" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="status" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "status" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="status" onClick={this.sort} style={{ color: "lightcoral" }}> {this.state.filters.currentSort3 === "status" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="statusMemo" className={styles.statusMemoColumn} onClick={this.sort}>Status Memo &nbsp;
                                    <span data-sort="statusMemo" onClick={this.sort}>{this.state.filters.currentSort === "statusMemo" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="statusMemo" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "statusMemo" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="statusMemo" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "statusMemo" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="datetimeRequested" onClick={this.sort}>Req. &nbsp;
                                    <span data-sort="datetimeRequested" onClick={this.sort}>{this.state.filters.currentSort === "datetimeRequested" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="datetimeRequested" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "datetimeRequested" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="datetimeRequested" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "datetimeRequested" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="assignedTo" onClick={this.sort}>Assn. &nbsp;
                                    <span data-sort="assignedTo" onClick={this.sort}>{this.state.filters.currentSort === "assignedTo" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="assignedTo" onClick={this.sort} style={{ color: "lightblue" }}> {this.state.filters.currentSort2 === "assignedTo" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="assignedTo" onClick={this.sort} style={{ color: "lightcoral" }}> {this.state.filters.currentSort3 === "assignedTo" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="bookingRequestNumber" onClick={this.sort}>Booking # &nbsp;
                                    <span data-sort="bookingRequestNumber" onClick={this.sort}> {this.state.filters.currentSort === "bookingRequestNumber" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="bookingRequestNumber" onClick={this.sort} style={{ color: "lightblue" }}> {this.state.filters.currentSort2 === "bookingRequestNumber" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="bookingRequestNumber" onClick={this.sort} style={{ color: "lightcoral" }}> {this.state.filters.currentSort3 === "bookingRequestNumber" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="shipperName" onClick={this.sort}>Shipper &nbsp;
                                    <span data-sort="shipperName" onClick={this.sort}>{this.state.filters.currentSort === "shipperName" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="shipperName" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "shipperName" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="shipperName" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "shipperName" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="carrierName" onClick={this.sort}>Carrier &nbsp;
                                    <span data-sort="carrierName" onClick={this.sort}>{this.state.filters.currentSort === "carrierName" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="carrierName" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "carrierName" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="carrierName" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "carrierName" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="containerType" onClick={this.sort}>FCL &nbsp;
                                    <span data-sort="containerType" onClick={this.sort}>{this.state.filters.currentSort === "containerType" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="containerType" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "containerType" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="containerType" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "containerType" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="originName" onClick={this.sort}>Origin &nbsp;
                                    <span data-sort="originName" onClick={this.sort}>{this.state.filters.currentSort === "originName" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="originName" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "originName" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="originName" onClick={this.sort} style={{ color: "lightcoral" }}> {this.state.filters.currentSort3 === "originName" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="destName" onClick={this.sort}>Dest &nbsp;
                                    <span data-sort="destName" onClick={this.sort}>{this.state.filters.currentSort === "destName" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="destName" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "destName" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="destName" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "destName" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="startDate" onClick={this.sort}>Date &nbsp;
                                    <span data-sort="startDate" onClick={this.sort}>{this.state.filters.currentSort === "startDate" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="startDate" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "startDate" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="startDate" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "startDate" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="shipperReferenceNumber" onClick={this.sort}>Ref. # &nbsp;
                                    <span data-sort="shipperReferenceNumber" onClick={this.sort}>{this.state.filters.currentSort === "shipperReferenceNumber" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="shipperReferenceNumber" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "shipperReferenceNumber" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="shipperReferenceNumber" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "shipperReferenceNumber" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="consigneeName" onClick={this.sort}>Consignee &nbsp;
                                    <span data-sort="consigneeName" onClick={this.sort}>{this.state.filters.currentSort === "consigneeName" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="consigneeName" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "consigneeName" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="consigneeName" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "consigneeName" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="note" onClick={this.sort}>Note &nbsp;
                                    <span data-sort="note" onClick={this.sort}>{this.state.filters.currentSort === "note" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="note" onClick={this.sort} style={{ color: "lightblue" }}>{this.state.filters.currentSort2 === "note" ? this.state.filters.reverse2 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span data-sort="note" onClick={this.sort} style={{ color: "lightcoral" }}>{this.state.filters.currentSort3 === "note" ? this.state.filters.reverse3 === -1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <RequestResultsDisplay
                                requests={this.state.requests}
                                editRequest={this.editRequest}
                                editRequestIndex={this.state.editRequestIndex}
                                loading={this.state.loading}
                                handleMouseDownHighlight={this.handleMouseDownHighlight}
                                handleStatusMenu={this.handleStatusMenu}
                                onFocusReset={this.onFocusReset}
                            />
                        </tbody>
                    </table>
                </div>
            }
            <PageCountDisplay
                pageJump={this.pageJump}
                singlePageTurn={this.singlePageTurn}
                count={this.state.filters.count}
                page={this.state.filters.page}
                pageReset={false}
            />
            <RequestModal
                employee={this.state.employee}
                bookingRequest={this.state.requests[this.state.editRequestIndex]}
                closeModal={this.closeModal}
                origins={this.state.editOrigins}
                destinations={this.state.editDestinations}
                carriers={this.state.editCarriers}
                containerType={this.props.containerType}
                statusMemoList={this.state.statusMemoList}
                portalUser={this.props.portalUser}
                getBookingRequests={this.getBookingRequests}
                handleStatusMenu={this.handleStatusMenu}
                getAllContracts={this.getAllContracts}
                contracts={this.state.contracts}
                setReminderSectionAsOpen={this.setReminderSectionAsOpen}
                settingReminder={this.state.settingReminder}
                editing={this.state.editing}
                setEditingTrue={this.setEditingTrue}
                updateModalInfo={this.state.updateModalInfo}
                resetUpdateModalInfo={this.resetUpdateModalInfo}
                editConsignees={this.state.editConsignees}
                emitUpdateTrigger={this.emitUpdateTrigger}
            />
            <div style={{ position: 'fixed', top: this.state.y, left: this.state.x }} className={`lf-shipment-context-menu ${styles.statusContextMenu}`}>
                <ul id="contextMenuCollection" className="collection">
                    {this.state.showStatusMenu && <li onClick={() => this.changeStatus('NEW')} className={`collection-item ${styles.NEW}`}>NEW</li>}
                    {this.state.showStatusMenu && <li onClick={() => this.changeStatus('REQUESTED')} className={`collection-item ${styles.REQUESTED}`}>REQUESTED</li>}
                    {this.state.showStatusMenu && <li onClick={() => this.changeStatus('PENDING')} className={`collection-item ${styles.PENDING}`}>PENDING</li>}
                    {this.state.showStatusMenu && <li onClick={() => this.changeStatus('UPDATING')} className={`collection-item ${styles.UPDATING}`}>UPDATING</li>}
                    {this.state.showStatusMenu && <li onClick={() => this.changeStatus('DECLINED')} className={`collection-item ${styles.DECLINED}`}>DECLINED</li>}
                    {this.state.showStatusMenu && <li onClick={() => this.changeStatus('COMPLETED')} className={`collection-item ${styles.COMPLETED}`}>COMPLETED</li>}
                    {this.state.showStatusMenu && <li onClick={() => this.changeStatus('CLOSED')} className={`collection-item ${styles.CLOSED}`}>CLOSED</li>}
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { navs, index, carrier, location, containerType, party, portalUser, user, shipper } = state;
    let origins = location.filter(loc => ((loc.countryId === 1 || loc.countryId === 17) && loc.typeId !== 3));
    let destinations = location.filter(loc => ((loc.countryId !== 1 || loc.countryId !== 17) && loc.typeId !== 3))
    const shipperId = navs[index].shipperId;
    return { shipperId, carrier, location, containerType, party, portalUser, origins, destinations, user, shipper };
}

export default connect(mapStateToProps)(RequestOverview);