import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import { auth, logout } from "../../../helperFunctions";
import MaskedInput from "react-text-mask";
import moment from "moment";
import AddVessel from './AddVessel';
import { useDispatch } from "react-redux";
import { globalToastActions } from "../../../Redux/actions";

const VesselVoyage = props => {
    const {
        booking,
        shipperId,
        locationList,
        carrierId,
        setVesselVoyage: setParentVesselVoyage
    } = props;

    const ref = useRef({ abortController: new AbortController() })

    const initValues = () => ({
        vesselId: booking.booking.vesselVoyage.vessel?.id || 0,
        voyageNumber: booking.booking.vesselVoyage.voyageNumber || '',
        serviceRoute: booking.booking.serviceRoute?.serviceRoute || '',
        placeOfReceiptId: booking.booking.vesselVoyage.portPair.placeOfReceiptId || 0,
        portOfLoadId: booking.booking.vesselVoyage.portPair.portOfLoadId || 0,
        portOfDischargeId: booking.booking.vesselVoyage.portPair.portOfDischargeId || 0,
        placeOfDeliveryId: booking.booking.vesselVoyage.portPair.portOfDeliveryId || 0,
        pickupLocationId: booking.booking.pickupLocationId || 0,
        returnLocationId: booking.booking.returnLocationId || 0,
        cargoCutDate: '',
        cargoCutTime: '',
        docCutDate: '',
        docCutTime: '',
        eta: '',
        erd: '',
        etd: ''
    })

    const dispatch = useDispatch();

    const [values, setValues] = useState({ ...initValues(), originalValues: initValues() });
    const [domesticPorts, setDomesticPorts] = useState(null);
    const [foreignPorts, setForeignPorts] = useState(null);
    const [inStateContainerYards, setInStateContainerYards] = useState(null);
    const [outOfStateContainerYards, setOutOfStateContainerYards] = useState(null);
    const [vessel, setVessel] = useState(null);
    const [searching, setSearching] = useState(false);
    const [useInstateContainerYards, setUseInstateContainerYards] = useState(true);

    const filterContainerYards = locationList => {
        if (locationList) {
            const placeOfReceipt = locationList.find(loc => parseInt(loc.id) === parseInt(values.placeOfReceiptId))

            if (!placeOfReceipt || !placeOfReceipt.state) {
                setInStateContainerYards(locationList.filter(loc => loc.locationType.code.toUpperCase() === 'CY'))
                setOutOfStateContainerYards(null)
            }
            else {
                setInStateContainerYards(locationList.filter(loc => loc.state?.toLowerCase() === placeOfReceipt.state?.toLowerCase() && loc.locationType.code.toUpperCase() === 'CY'))
                setOutOfStateContainerYards(locationList.filter(loc => loc.state?.toLowerCase() !== placeOfReceipt.state?.toLowerCase() && loc.locationType.code.toUpperCase() === 'CY'))
            }
        }
    }

    useEffect(() => {
        if (locationList) {
            setDomesticPorts(locationList.filter(loc => ['USA', 'CAN'].indexOf(loc.country.code.toUpperCase()) !== -1 && loc.locationType.code.toUpperCase() !== 'CY'))
            setForeignPorts(locationList.filter(loc => ['USA', 'CAN'].indexOf(loc.country.code.toUpperCase()) === -1 && loc.locationType.code.toUpperCase() !== 'CY'))
            filterContainerYards(locationList);
        }
    }, [locationList])

    useEffect(() => {
        filterContainerYards(locationList)

        if (values.placeOfReceiptId !== booking.booking.vesselVoyage.portPair.placeOfReceiptId)
            setValues(p => ({ ...p, pickupLocationId: 0, returnLocationId: 0 }))
    }, [values.placeOfReceiptId])

    const onChange = ({ target: { id, value } }) => setValues(p => ({ ...p, [id]: typeof value === 'string' ? value.toUpperCase() : value }))

    const searchVesselVoyage = () => {
        const {
            placeOfReceiptId,
            portOfLoadId,
            portOfDischargeId,
            placeOfDeliveryId,
            voyageNumber,
            serviceRoute
        } = values;
        const { vesselId } = vessel;

        setSearching(true);
        setValues(p => ({ ...p, vesselId }))
        ref.current.abortController?.abort();
        ref.current.abortController = new AbortController();

        Axios.get('/api/v2/voyage/read/search', {
            params: {
                ...auth.getAuthData(),
                shipperId,
                vesselId,
                carrierId,
                placeOfDeliveryId,
                portOfLoadId,
                portOfDischargeId,
                placeOfReceiptId,
                voyageNumber,
                serviceRoute
            },
            signal: ref.current.abortController?.signal
        })
            .then(result => {
                if (Array.isArray(result.data) && result.data.length) {
                    const vv = result.data[0];

                    setValues(p => ({
                        ...p,
                        serviceRoute: vv.serviceRoute ? vv.serviceRoute.serviceRoute : p.serviceRoute,
                        cargoCutDate: moment(vv.cargoCutDate).format('MM/DD/YY'),
                        cargoCutTime: moment(vv.cargoCutDate).format('HH:mm'),
                        portOfLoadId: vv.portPair.portOfLoadId || 0,
                        docCutDate: moment(vv.docCutDate).format('MM/DD/YY'),
                        docCutTime: moment(vv.docCutDate).format('HH:mm'),
                        etd: moment(vv.etd).format('MM/DD/YY'),
                        eta: moment(vv.eta).format('MM/DD/YY'),
                        erd: vv.erd ? moment(vv.erd).format('MM/DD/YY') : '',
                    }))
                }
                else {
                    setValues(p => ({
                        ...p,
                        cargoCutDate: '',
                        cargoCutTime: '',
                        docCutDate: '',
                        docCutTime: '',
                        etd: '',
                        eta: '',
                        erd: ''
                    }))
                }
            })
            .catch(logout)
            .finally(() => setSearching(false))
    }

    useEffect(() => {
        if (!vessel || !values) { return }

        const { vesselId } = vessel;
        const {
            placeOfReceiptId,
            portOfLoadId,
            portOfDischargeId,
            placeOfDeliveryId,
            voyageNumber
        } = values;


        if (vesselId && carrierId && voyageNumber && placeOfReceiptId && portOfLoadId && portOfDischargeId && placeOfDeliveryId)
            searchVesselVoyage();
    }, [vessel,
        values?.voyageNumber,
        values?.placeOfReceiptId,
        values?.portOfLoadId,
        values?.portOfDischargeId,
        values?.placeOfDeliveryId,
        values?.serviceRoute,
        carrierId
    ])

    const toggleUseInstateContainerYards = e => {
        e.preventDefault();
        dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Showing ${useInstateContainerYards ? 'ALL' : 'IN-STATE'} Container Yards`, type: 'info' } })
        setUseInstateContainerYards(p => !p);
    }

    //PUSH VALUES UP TO PARENT
    useEffect(() => { setParentVesselVoyage({ ...values, vessel }) }, [values, vessel])

    return (
        <>
            <div className="row" style={{ marginBottom: '0px' }}>
                <AddVessel
                    carrierId={carrierId}
                    vessel={booking.booking.vesselVoyage.vessel}
                    setVessel={setVessel}
                />
                <div className="lf-input col s3">
                    <input id="voyageNumber" className="browser-default" type="text" value={values.voyageNumber} onChange={onChange} />
                    <label htmlFor="voyageNumber">Voyage</label>
                </div>
                <div className="lf-input col s3">
                    <input id="serviceRoute" className="browser-default" type="text" value={values.serviceRoute} onChange={onChange} />
                    <label htmlFor="serviceRoute">Service</label>
                </div>
            </div>
            <div className="row" style={{ marginBottom: '0px' }}>
                <div className="col s6">
                    <div className="row" style={{ marginBottom: '0px' }}>
                        <div className="lf-input col s10 offset-s2">
                            <select id="placeOfReceiptId" className="browser-default" value={values.placeOfReceiptId} onChange={onChange}>
                                <option value={0}></option>
                                {domesticPorts?.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                                <option disabled="disabled" className="red-text">-- End Domestic Ports --</option>
                                {foreignPorts?.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                            </select>
                            <label htmlFor="placeOfReceipt">PLR</label>
                        </div>
                    </div>
                    <div className="row" style={{ marginBottom: '0px' }}>
                        <div className="lf-input col s10 offset-s2">
                            <select id="portOfLoadId" className="browser-default" value={values.portOfLoadId} onChange={onChange}>
                                <option value={0}></option>
                                {domesticPorts?.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                                <option disabled="disabled" className="red-text">-- End Domestic Ports --</option>
                                {foreignPorts?.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                            </select>
                            <label htmlFor="portOfLoad">POL</label>
                        </div>
                    </div>
                    <div className="row" style={{ marginBottom: '0px' }}>
                        <div className="lf-input col s10 offset-s2">
                            <select id="portOfDischargeId" className="browser-default" value={values.portOfDischargeId} onChange={onChange}>
                                <option value={0}></option>
                                {foreignPorts?.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                                <option disabled="disabled" className="red-text">-- End Domestic Ports --</option>
                                {domesticPorts?.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                            </select>
                            <label htmlFor="portOfDischargeId">POD</label>
                        </div>
                    </div>
                    <div className="row" style={{ marginBottom: '0px' }}>
                        <div className="lf-input col s10 offset-s2">
                            <select id="placeOfDeliveryId" className="browser-default" value={values.placeOfDeliveryId} onChange={onChange}>
                                <option value={0}></option>
                                {foreignPorts?.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                                <option disabled="disabled" className="red-text">-- End Domestic Ports --</option>
                                {domesticPorts?.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                            </select>
                            <label htmlFor="placeOfDelivery">PLD</label>
                        </div>
                    </div>
                </div>
                <div className="col s6">
                    {searching ?
                        <div className="row">
                            <h6 className="center"><i>Searching Vessel Voyages</i></h6>
                            <div className="progress blue col s10 offset-s1" style={{ maxHeight: '4px' }}>
                                <div className="indeterminate" />
                            </div>
                        </div>
                        :
                        <>
                            <div className="row" style={{ marginBottom: '0px' }}>
                                <div className="lf-input col s4 offset-s2">
                                    <MaskedInput id="cargoCutDate" className="browser-default" type="text" value={values.cargoCutDate} onChange={onChange} placeholder="mm/dd/yy" mask={[/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]} />
                                    <label htmlFor="cargoCutDate">Cut</label>
                                </div>
                                <div className="lf-input col s4">
                                    <MaskedInput id="cargoCutTime" className="browser-default" type="text" value={values.cargoCutTime} onChange={onChange} placeholder="hh:mm" mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]} />
                                    <label htmlFor="cargoCutTime">Time</label>
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: '0px' }}>
                                <div className="lf-input col s4 offset-s2">
                                    <MaskedInput id="docCutDate" className="browser-default" type="text" value={values.docCutDate} onChange={onChange} placeholder="mm/dd/yy" mask={[/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]} />
                                    <label htmlFor="docCutDate">Doc</label>
                                </div>
                                <div className="lf-input col s4">
                                    <MaskedInput id="docCutTime" className="browser-default" type="text" value={values.docCutTime} onChange={onChange} placeholder="hh:mm" mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]} />
                                    <label htmlFor="docCutTime">Time</label>
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: '0px' }}>
                                <div className="lf-input col s4 offset-s2">
                                    <MaskedInput id="etd" className="browser-default" type="text" value={values.etd} onChange={onChange} placeholder="mm/dd/yy" mask={[/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]} />
                                    <label htmlFor="etd">ETD</label>
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: '0px' }}>
                                <div className="lf-input col s4 offset-s2">
                                    <MaskedInput id="eta" className="browser-default" type="text" value={values.eta} onChange={onChange} placeholder="mm/dd/yy" mask={[/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]} />
                                    <label htmlFor="eta">ETA</label>
                                </div>
                                <div className="lf-input col s4">
                                    <MaskedInput id="erd" className="browser-default" type="text" value={values.erd} onChange={onChange} placeholder="mm/dd/yy" mask={[/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]} />
                                    <label htmlFor="erd">ERD</label>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="row">
                <div className="lf-input col s6">
                    <select id="pickupLocationId" className="browser-default" value={values.pickupLocationId} onChange={onChange} onContextMenu={toggleUseInstateContainerYards}>
                        <option value={0}></option>
                        {(useInstateContainerYards ? inStateContainerYards : [...inStateContainerYards, ...outOfStateContainerYards])?.map(cy => <option key={cy.id} value={cy.id}>{cy.name}</option>)}
                    </select>
                    <label htmlFor="pickupLocationId">Pickup</label>
                </div>
                <div className="lf-input col s6">
                    <select id="returnLocationId" className="browser-default" value={values.returnLocationId} onChange={onChange} onContextMenu={toggleUseInstateContainerYards}>
                        <option value={0}></option>
                        {(useInstateContainerYards ? inStateContainerYards : [...inStateContainerYards, ...outOfStateContainerYards])?.map(cy => <option key={cy.id} value={cy.id}>{cy.name}</option>)}
                    </select>
                    <label htmlFor="returnLocationId">Return</label>
                </div>
            </div>
        </>
    )
}

export default VesselVoyage;