import React from 'react';
import styles from './checkboxSmall.module.css';

class CheckBoxSmall extends React.Component {

    onChange = e => {
        const value = e.target.checked;
        this.props.onChange(this.props.name, value);
    }

    render = () => (
        <div tabIndex="-1" className={`${this.props.col ? `col ${this.props.col}` : ''} ${styles.div} ${this.props.focusStyle}`}>
            <div tabIndex="-1" className={` ${styles.innerDiv} ${this.props.focusStyle}`}>
                <p className={`${styles.p} ${this.props.focusStyle}`}>
                    <label className={`${styles.label} ${this.props.focusStyle}`}>
                        <input tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"} type="checkbox" className={`${styles.input} checkbox filled-in ${this.props.className} ${this.props.focusStyle}` } onChange={this.onChange} checked={this.props.value} />
                        <span className={`${styles.span} ${this.props.focusStyle}`}>{this.props.label}</span>
                    </label>
                </p>
            </div>
        </div>
    )
}

export default CheckBoxSmall;