import { Routes, Route, Navigate } from 'react-router-dom';
import './content.css';
import CustomerOverview from "../../Pages/Customer/CustomerOverview";
import RequestOverview from "../../Pages/Requests/RequestOverview";


const NotFound = (props) => (
    <div>
        <h2> Error 404. Sorry, there's nothing here. </h2>
        <Navigate to="/customer" replace={true} />
    </div>
)


const CustomerContent = props => (
    <div id="master-content" className="content">
        <Routes>
            <Route path="shipment" element={<CustomerOverview />} />
            <Route path="customer" element={<CustomerOverview />} />
            <Route path="request" element={<RequestOverview />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </div>
)

export default CustomerContent;