import { useState } from "react";
import Axios from 'axios';
import M from 'materialize-css';
import { useLayoutEffect } from "react";
import { useRef } from "react";
import { auth, logout } from '../../../helperFunctions';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
const componentId = '_' + crypto.randomUUID();

const AddVessel = props => {
    const {
        carrierId,
        vessel,
        setVessel: setParentVessel
    } = props;

    const dispatch = useDispatch();
    const ref = useRef({ abortController: new AbortController() })

    const initValues = () => ({
        vesselName: vessel ? vessel.name : '',
        vesselId: 0,
    })

    const [values, setValues] = useState(initValues());
    const [vesselList, setVesselList] = useState(null);
    const [loadingVesselList, setLoadingVesselList] = useState(false);

    useLayoutEffect(() => {
        M.Autocomplete.init(document.querySelectorAll(`#vesselName.${componentId}`), {
            limit: 5,
            onAutocomplete: vesselName => setValues(p => ({ ...p, vesselName }))
        });
        // return () => M.Autocomplete.getInstance(document.querySelector(`#vesselName.${componentId}`))?.destroy();
    }, [])

    const getVesselsByCarrierId = () => {
        setLoadingVesselList(true);
        ref.current.abortController?.abort();
        ref.current.abortController = new AbortController();

        Axios.get('/api/v2/vessel/read/all/by/carrierid', {
            params: {
                ...auth.getAuthData(),
                carrierId
            },
            signal: ref.current.abortController.signal
        })
            .then(result => setVesselList(result.data))
            .catch(logout)
            .finally(() => setLoadingVesselList(false))
    }

    useEffect(getVesselsByCarrierId, [carrierId])

    const onChange = ({ target: { id, value } }) => setValues(p => ({ ...p, [id]: value }))

    useLayoutEffect(() => {
        if (vesselList) {
            const data = {};
            vesselList.forEach(v => data[v.name] = null);
            M.Autocomplete.getInstance(document.querySelector(`#vesselName.${componentId}`))?.updateData(data);
        }
    }, [vesselList])

    useEffect(() => {
        if (vesselList) {
            const vessel = vesselList.find(v => v.name.toLowerCase() === values.vesselName.toLowerCase());
            setValues(p => ({ ...p, vesselId: vessel ? vessel.id : 0 }))
        }
    }, [values.vesselName, vesselList])

    const addVessel = e => {
        e?.preventDefault();

        Axios.post('/api/v2/vessel/create', {
            ...auth.getAuthData(),
            carrierId,
            vesselName: values.vesselName
        })
            .then(result => {
                dispatch(result.data.msg);
                getVesselsByCarrierId();
                document.querySelector('#vesselName').focus();
            })
            .catch(logout)
    }

    //PUSH VALUES UP TO PARENT COMPONENT
    useEffect(() => { setParentVessel(values) }, [values])

    return (
        <div className="col s6">
            <div className="row" style={{ marginBottom: '0px' }}>
                <div className={`lf-input input-field col ${values.vesselId === 0 && values.vesselName.trim() ? 's10' : 's12'}`}>
                    <input id="vesselName" className={`autocomplete ${componentId}`} type="text" value={values.vesselName} onChange={onChange} />
                    <label htmlFor="vesselName" className={loadingVesselList ? 'blue-text' : ''}>{loadingVesselList ? 'Updating Vessel List' : 'Vessel'}</label>
                </div>
                {values.vesselId === 0 && values.vesselName.trim() ?
                    <div className="col s2">
                        <a
                            id="add-vessel-button-on-booking-update-page"
                            href="/"
                            onClick={addVessel}
                        >
                            <i className="material-icons blue white-text waves-effect waves-light">add</i>
                        </a>
                    </div>
                    : null}
            </div>
        </div>
    )
}

export default AddVessel;