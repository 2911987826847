import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../helperFunctions';
import { Link } from 'react-router-dom';

class UserMenu extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({
        showMenu: false
    })

    capatalizeFirstLetter = word => {
        if (word.split(" ").length > 1) {
            let companyNameArr = word.split(" ");
            let companyName = [];
            companyNameArr.forEach(n => {
                companyName.push(
                    (/[a-zA-Z]/.test(n) && n !== "LTD." && n !== "LTD" && n !== "LLC" && n !== "LLC."
                        ? (this.capatalizeFirstLetter(n)) : n)

                );
            })
            return companyName.join(" ")
        } else {
            if (!word) {
                return;
            }
            return `${word[0].toUpperCase()}${word.slice(1, word.length).toLowerCase()}`
        }
    }

    getUserCompany = () => {
        return this.capatalizeFirstLetter(this.props.user.partyName);
    }

    toggleUserMenu = e => {
        e.preventDefault();
        this.setState(p => ({ showMenu: !p.showMenu }))
    }

    logout = e => {
        e.preventDefault();
        // localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" })
        this.props.history.push("/")
    }

    goToUserSettings = e => {
        this.props.navigate('/settings', { state: {} })
    }

    goToLogPage = e => {
        this.props.navigate('/log', { state: {} })
    }

    getDropStyle = () => {
        const style = {
            position: 'relative',
            top: '-20px',
            backgroundColor: 'white',
            border: '1px black solid',
            borderRadius: '3px',
            zIndex: '2000',
            width: '40%',
            margin: '0px',
            padding: '0px',
            height: '80px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            textAlign: 'center'
        }
        return { ...style, display: this.state.showMenu ? 'inline-block' : 'none' }
    }

    getUserNameAndCompany = () => {

        if (this.props.user) {
            return `${this.capatalizeFirstLetter(this.props.user.first)} ${this.capatalizeFirstLetter(this.props.user.last)} - ${this.getUserCompany()}`;
        } else {
            return ""
        }

    }

    componentDidMount = () => {
        this.setState({ userInfo: this.getUserNameAndCompany() });
        document.querySelector('#app').addEventListener('click', this.clickAwayListener);
    }
    componentWillUnmount = () => document.querySelector('#app').addEventListener('click', this.clickAwayListener);
    clickAwayListener = e => {
        if (e.target.id !== 'user-menu-suicidal-lemming')
            this.setState({ showMenu: false })
    }

    render = () => (
        <div style={divStyle}>
            <a id="user-menu-suicidal-lemming" href="/" onClick={this.toggleUserMenu} className="blue-text">{this.state.userInfo}</a>
            <div style={this.getDropStyle()}>
                {this.props.user ?
                    <ul style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%', width: '100%' }}>
                        {this.props.user.is_admin || this.props.user.isAdmin ? <li><Link className="blue-text" style={{ lineHeight: '30px', fontSize: '14px' }} to='/log' onClick={this.goToLogPage}>General Log</Link></li> : null}
                        {this.props.user.isEmployee || this.props.user.is_employee ? <li><Link className="blue-text" to="/settings" style={{ lineHeight: '30px', fontSize: '14px' }} onClick={this.goToUserSettings}>Settings</Link></li> : null}
                        <li><Link className="blue-text" style={{ lineHeight: '30px', fontSize: '14px' }} to="/" onClick={this.logout}>Logout</Link></li>
                    </ul>
                    :
                    null}
            </div>
        </div>
    )
}

const divStyle = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    height: '44px',
    padding: '0px',
    margin: '0px',
    zIndex: '20000',
    width: 'max-content',
    textAlign: 'right',
}

const mapStateToProps = state => ({ user: state.user, party: state.party });

export default connect(mapStateToProps)(withRouter(UserMenu));