import React from 'react';
import { connect } from 'react-redux';
import { get, post } from '../../../helperFunctions';
import Form from '../../../Components/NewComponents';
import M from 'materialize-css';
import { globalToastActions } from '../../../Redux/actions';
import { flags } from "../../../constants";

class UserOverview extends React.Component {

    state = {
        users: [],
        filteredParties: [],
        changePassword: false,
        filters: {
            partyId: 0,
            isEmployee: "ALL",
            isAdmin: "ALL",
            isArchived: "NOT ARCHIVED",
            currentSort: 'first',
            reverse: false
        },
        id: '',
        accountId: '',
        first: '',
        middle: '',
        last: '',
        isEmployee: 0,
        isBookkeeper: 0,
        isAdmin: 0,
        partyId: 1,
        slackId: '',
        emailAddress: '',
        password: '',
        confirmPassword: '',
        msg: ''
    }

    initState = () => ({ users: null, showModal: false, user: null, msg: { style: '', text: '' } });
    initModal = () => { M.Modal.init(document.querySelectorAll('.modal')); }
    showModal = () => { M.Modal.getInstance(document.querySelector('.modal')).open() }
    hideModal = () => { M.Modal.getInstance(document.querySelector('.modal')).close() }
    onChange = (name, value) => {
        if (name === 'isEmployee' && value) {
            this.setState({ partyId: 1 })
        } else if (name === 'isEmployee' && !value) {
            this.setState({ slackId: '' });
        }
        this.setState({ [name]: value });
    };
    filterOnChange = (name, value) => { this.setState({ filters: { ...this.state.filters, [name]: value } }, () => this.refresh()) };
    sort = (e) => {
        if (e.target.attributes['data-sort'].value === this.state.filters.currentSort) {
            this.setState({ filters: { ...this.state.filters, reverse: !this.state.filters.reverse } }, () => this.refresh())
        } else {
            this.setState({ filters: { ...this.state.filters, currentSort: e.target.attributes['data-sort'].value, reverse: false } }, () => this.refresh())
        }
    }
    filterParties = async () => {
        let filteredParties = await this.props.party.filter((set => s => !set.has(s.partyId) && set.add(s.partyId))(new Set()));
        this.setState({ filteredParties });
    }
    getUsers = () => get(`users/`, { filters: this.state.filters }, users => this.setState({ users }), this.handleFailure);
    handleFailure = msg => this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: msg.code, type: 'error' } });
    refresh = () => this.getUsers();
    componentDidMount = () => { this.refresh(); this.initModal(); this.filterParties() }
    handleSave = () => {
        let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (this.state.isArchived) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Account Must First Be Resurrected', type: 'error' } });
            return;
        } else if (this.state.password !== this.state.confirmPassword) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Passwords Do Not Match', type: 'error' } });
            return;
        } else if (!emailRegex.test(this.state.emailAddress)) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Invalid Email", type: "error" } });
            return
        } else {
            if (this.verifyNewPassword() || (!this.state.password.length && !this.state.confirmPassword.length)) {
                this.setState({ msg: '' });
                let { id, first, middle, last, isEmployee, isBookkeeper, isAdmin, partyId, slackId, emailAddress, password } = this.state;
                let userInfo = {
                    id,
                    first,
                    middle,
                    last,
                    isEmployee,
                    isBookkeeper,
                    isAdmin,
                    partyId,
                    slackId,
                    emailAddress,
                    password
                }
                post('users/update', userInfo, this.handleUpdateSuccess, this.handleFailure);
            }
        }
    }
    handleUpdateSuccess = () => { this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'User Updated', type: 'success' } }); this.refresh(); this.hideModal(); }
    handleDelete = async () => {
        if (await window.confirm(`Are you sure you would like to archive user: ${this.state.first} ${this.state.last}? \n(This will not have an effect on existing Booking information)`)) {
            post('users/archive', { id: this.state.id }, this.DeleteSuccess, this.handleFailure);
        } else {
            return;
        }
    }
    DeleteSuccess = () => { this.hideModal(); this.refresh() };
    handleResurrect = () => {
        if (!this.state.accountId) {
            if (!this.state.emailAddress || !this.state.password || !this.state.confirmPassword) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Email/Password Required', type: 'error' } });
                return;
            } else if (this.state.password !== this.state.confirmPassword) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Passwords Do Not Match', type: 'error' } });
                return;
            } else {
                post('users/resurrect', { id: this.state.id, accountId: this.state.accountId, emailAddress: this.state.emailAddress, password: this.state.password, confirmPassword: this.state.confirmPassword }, this.resurrectSuccess, this.handleFailure);
            }
        } else {
            post('users/resurrect', { id: this.state.id, accountId: this.state.accountId, emailAddress: this.state.emailAddress, password: this.state.password, confirmPassword: this.state.confirmPassword }, this.resurrectSuccess, this.handleFailure);
        }
    }
    resurrectSuccess = () => { this.hideModal(); this.refresh() };
    handleEdit = index => {
        this.showModal();
        this.setState({
            ...this.state.users[index], password: '', confirmPassword: '', changePassword: false
        })
    };
    verifyNewPassword = () => {
        const { password, confirmPassword } = this.state;
        if (password.length < 8)
            return this.setState({ msg: 'Password must be a minimum of 8 characters' });
        if (!this.checkPasswordForDigits())
            return this.setState({ msg: 'Password must have at least one digit' });
        if (!this.checkPasswordForSymbol())
            return this.setState({ msg: 'Password must have one special character. ie: !,@,#,$' });
        if (password !== confirmPassword)
            return this.setState({ msg: 'Passwords do not match' });
        return true;
    }
    checkPasswordForDigits = () => {
        return /\d/.test(this.state.confirmPassword);
    }
    checkPasswordForSymbol = () => {
        return /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/g.test(this.state.confirmPassword);
    }

    render = () => (
        <div>
            <div className="row">
                <Form.Select col="col s3" label="Employee" name="isEmployee" value={this.state.filters.isEmployee} onChange={this.filterOnChange} list={[{ label: "ALL" }, { label: "EMPLOYEE" }, { label: "NOT EMPLOYEE" }]} filter={{ label: 'label', value: 'label' }} />
                <Form.Select col="col s3" label="Admin" name="isAdmin" value={this.state.filters.isAdmin} onChange={this.filterOnChange} list={[{ label: "ALL" }, { label: "ADMIN" }, { label: "NOT ADMIN" }]} filter={{ label: 'label', value: 'label' }} />
                <Form.Select col="col s3" label="Party" name="partyId" value={this.state.filters.partyId} onChange={this.filterOnChange} placeholder="ALL" list={this.state.filteredParties} filter={{ label: 'name', value: 'partyId' }} />
                <Form.Select col="col s3" label="Archived" name='isArchived' value={this.state.filters.isArchived} onChange={this.filterOnChange} list={[{ label: "ALL" }, { label: 'ARCHIVED' }, { label: 'NOT ARCHIVED' }]} filter={{ label: 'label', value: 'label' }} />
            </div>
            <table className="user-portal-table">
                <thead className="user-portal-thead">
                    <tr>
                        <th onClick={(e) => this.sort(e)} data-sort="first">First&nbsp;
                            {this.state.filters.currentSort === "first" ? !this.state.filters.reverse ? `⮝` : `⮟` : ""}<span /></th>
                        <th onClick={(e) => this.sort(e)} data-sort="middle">Middle&nbsp;
                            {this.state.filters.currentSort === "middle" ? !this.state.filters.reverse ? `⮝` : `⮟` : ""}<span /></th>
                        <th onClick={(e) => this.sort(e)} data-sort='last'>Last&nbsp;
                            {this.state.filters.currentSort === "last" ? !this.state.filters.reverse ? `⮝` : `⮟` : ""}<span /></th>
                        <th onClick={(e) => this.sort(e)} data-sort='name'>Party&nbsp;
                            {this.state.filters.currentSort === "name" ? !this.state.filters.reverse ? `⮝` : `⮟` : ""}<span /></th>
                        <th onClick={(e) => this.sort(e)} data-sort="email_address">Email&nbsp;
                            {this.state.filters.currentSort === "email_address" ? !this.state.filters.reverse ? `⮝` : `⮟` : ""}<span /></th>
                        <th onClick={(e) => this.sort(e)} data-sort='employee'>Employee&nbsp;
                            {this.state.filters.currentSort === "employee" ? !this.state.filters.reverse ? `⮝` : `⮟` : ""}<span /></th>
                        <th onClick={(e) => this.sort(e)} data-sort='is_bookkeeper'>Bookkeep&nbsp;
                            {this.state.filters.currentSort === "is_bookkeeper" ? !this.state.filters.reverse ? `⮝` : `⮟` : ""}<span /></th>
                        <th onClick={(e) => this.sort(e)} data-sort='is_admin'>Admin&nbsp;
                            {this.state.filters.currentSort === "is_admin" ? !this.state.filters.reverse ? `⮝` : `⮟` : ""}<span /></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.users.map((p, index) => (
                        <tr onClick={() => this.handleEdit(index)} style={p.isArchived ? { color: "lightgrey" } : { color: 'black' }} key={p.id}>
                            <td>{p.first}</td>
                            <td>{p.middle}</td>
                            <td>{p.last}</td>
                            <td>{p.partyName}</td>
                            <td>{!p.emailAddress ? <span style={{ fontStyle: "italic" }}>[ Deleted ]</span> : p.emailAddress}</td>
                            <td><i className="material-icons" style={{ color: p.isEmployee ? 'green' : 'red' }}>{p.isEmployee ? 'check' : 'close'}</i></td>
                            <td><i className="material-icons" style={{ color: p.isBookkeeper ? 'green' : 'red' }}>{p.isBookkeeper ? 'check' : 'close'}</i></td>
                            <td><i className="material-icons" style={{ color: p.isAdmin ? 'green' : 'red' }}>{p.isAdmin ? 'check' : 'close'}</i></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="modal" id="edit-user-modal">
                <div className="modal-content row">
                    <div className="row" style={{ marginBottom: "0px" }}>
                        <div className="col s1 offset-s11"><i className="material-icons" onClick={this.hideModal}>close</i></div>
                    </div>
                    <div className="row">
                        <h5 className="col s4 offset-s4" style={{ textAlign: "center", marginTop: "0px" }}>Modify User</h5>
                    </div>
                    <div className="row">
                        <Form.TextInput col="s4 l4" name="first" label="First Name" onChange={this.onChange} value={this.state.first} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="s4 l4" name="middle" label="Middle Name" onChange={this.onChange} value={this.state.middle} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="s4 l4" name="last" label="Last Name" onChange={this.onChange} value={this.state.last} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                    <div className="row">
                        <Form.CheckBox col="s2 userEditCheckbox" name="isEmployee" label="Employee" onChange={this.onChange} value={this.state.isEmployee} />
                        <Form.CheckBox col="s2 userEditCheckbox" name="isBookkeeper" label="Bookkeep" onChange={this.onChange} value={this.state.isBookkeeper} />
                        <Form.CheckBox col="s2 userEditCheckbox" name="isAdmin" label="Admin" onChange={this.onChange} value={this.state.isAdmin} />
                        <Form.Select col="s3" name="partyId" label="Party" onChange={this.onChange} value={this.state.partyId} list={this.props.party} filter={{ label: 'name', value: 'id' }} disabled={this.state.isEmployee ? true : false} />
                        <Form.TextInput col="s3" name="slackId" label="Slack ID" onChange={this.onChange} value={this.state.slackId} disabled={this.state.isEmployee ? false : true} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                    <div className="row">
                        <Form.TextInput col="s12" name="emailAddress" label="Email" onChange={this.onChange} value={this.state.emailAddress} />
                    </div>
                    {this.state.changePassword || !this.state.accountId ?
                        <div className="row">
                            <Form.Password col="s6" name="password" label="Password" onChange={this.onChange} value={this.state.password} flags={[flags.ALLOW_EMPTY]} />
                            <Form.Password col="s6" name="confirmPassword" label="Confirm Password" onChange={this.onChange} value={this.state.confirmPassword} flags={[flags.ALLOW_EMPTY]} />
                            {this.state.msg.length ?
                                <div className="col s12" style={{ marginTop: "10px", padding: "0px 12px 0px 0px" }}>
                                    <div style={{ border: "1px solid red", color: "red", textAlign: "center" }}>
                                        {this.state.msg}
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                        :
                        <div className="row" style={{ marginBottom: '0px' }}>
                            <Form.Button col='s6 offset-s3' label='Change Password' icon="vpn_key" type="outline" onClick={() => this.setState({ changePassword: true })} />
                        </div>
                    }
                    <div className="row" style={{ marginBottom: '0px' }}>
                        {this.state.isArchived ?
                            <Form.Button col="s6 offset-s3" label="Resurrect" icon="rotate_left" color='blue' type="outline" onClick={this.handleResurrect} />
                            :
                            <div>
                                <Form.Button id="closeReminder" styling="modalFormat" icon="save" col="col s4 offset-s2" color="green" type="outline" disabled={this.state.isArchived ? true : false} label="Save" onClick={this.handleSave} />
                                <Form.Button id="closeReminder" styling="modalFormat" icon="delete" col="col s4" color="red" type="outline" label="Archive" disabled={this.state.isArchived ? true : false} onClick={this.handleDelete} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { navs, index, portalUser, party } = state;
    const shipperId = navs[index].shipperId;
    return { shipperId, portalUser, party };
}

export default connect(mapStateToProps)(UserOverview);