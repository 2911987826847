import { AiOutlineFile, AiOutlineDatabase, AiOutlineFileImage, AiOutlineGif, AiOutlineFilePdf, AiOutlineFileExcel } from 'react-icons/ai';
import { BsFileEarmarkText } from 'react-icons/bs';
import { SiMicrosoftoutlook } from 'react-icons/si';
import M from 'materialize-css';
import moment from 'moment';
import { useLayoutEffect, useRef } from 'react';

const File = props => {
    const {
        file,
        setContext,
        setFolderContext,
        onDragOver,
        onDrop,
        onDrag,
        downloadFile
    } = props;

    const ref = useRef();
    const onContextMenu = e => {
        e.preventDefault();
        setFolderContext({ show: false, folder: null })
        const { clientX, clientY } = e;

        setContext({
            show: true,
            file,
            clientX,
            clientY
        })
    }

    const onClick = e => {
        e.preventDefault();
        downloadFile(null, true, file.id)
    }

    const getIconType = type => {
        if (type === 'pdf')
            return <AiOutlineFilePdf className="red-text" />
        else if (type === 'txt')
            return < BsFileEarmarkText className="black-text" />
        else if (type === 'sql')
            return < AiOutlineDatabase className="black-text" />
        else if (type === 'msg')
            return <SiMicrosoftoutlook className="blue-text" />
        else if (['jpg', 'jpeg', 'tiff', 'tif', 'bmp', 'png', 'raw', 'jfi', 'jfif'].includes(type))
            return <AiOutlineFileImage className="purple-text" />
        else if (['xls', 'xlsx'].includes(type))
            return <AiOutlineFileExcel className="green-text" />
        else if (type === 'gif')
            return < AiOutlineGif className="black-text" />
        else
            return <AiOutlineFile className="black-text" />
    }

    useLayoutEffect(() => {

        if (file.label.length > 60)
            M.Tooltip.init(ref.current, {
                position: 'top',
                html: `<p class="white-text" style="padding: 0px;">${file.label}</p>`,
                enterDelay: 500
            })

        return () => file.label.length > 60 ? M.Tooltip.getInstance(ref.current)?.destroy() : null;
    }, [])

    return (
        <tr
            onContextMenu={onContextMenu}
            onDragOver={onDragOver}
            onDrop={onDrop}
            draggable={true}
            onDrag={onDrag}
            data-object={JSON.stringify(file)}
            onClick={onClick}
        >
            <td
                ref={ref}
                style={{ padding: `0px 0px 0px ${(file.depth - 1) * 18}px` }}
            >
                <span style={{ fontSize: '20px', position: 'relative', top: '4px', marginRight: '4px' }}>{getIconType(file.fileType)}</span>
                {file.label.length > 60 ? file.label.substr(0, 59) + '...' : file.label}
            </td>
            <td>{moment(file.dateTimeUploaded).format('MM/DD/YY HH:mm:ss')}</td>
            <td>{file.uploadedBy.firstName} {file.uploadedBy.lastName[0]}.</td>
        </tr>
    )
}

export default File;