import React from 'react';
import Form from '../../../../Components/NewComponents';
import { flags } from '../../../../constants';

const PL = (props) => {

    return (
        <div>
            <div className="row" style={{ margin: "0px", marginTop: "-20px", marginBottom: "0px" }}>
                <div className=" col s9">
                    <Form.TextArea tabIndex="2" name="description" label="Description of Goods" value={props.description} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} height="140px" />
                </div>
                <div className="col s3">
                    <div className="row">
                        <Form.TextInput tabIndex="2" name="totalGrossWeight" label="Total Gross Weight(MT)" value={props.totalGrossWeight} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                    <div className="row">
                        <Form.TextInput tabIndex="2" name="totalNetWeight" label="Total Net Weight(MT)" value={props.totalNetWeight} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                    <div className='row'>
                        <Form.TextInput tabIndex="2" name="marksAndNumbers" label="Marks & Numbers" value={props.marksAndNumbers} onChange={props.onChange} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PL;