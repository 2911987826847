import React, { Component } from 'react';
import { connect } from "react-redux";
import Form from "../../../Components/NewComponents";
import axios from "axios";
import { flags } from "../../../constants";
import { globalToastActions } from '../../../Redux/actions';
import { auth } from "../../../helperFunctions"

class FreightChargesDisplay extends Component {
    state = {
        chargeType: 1,
        chargeName: "",
        chargeLocation: 0,
        chargeAmount: "",
        freightTerms: "PREPAID",
        showAddCharge: false,
        allLocations: [],
        perContainerCharges: [],
        perContainerTotals: [],
        additionalCharges: [],
        editRowIndex: undefined,
        editChargeLocation: "",
        editChargeName: "",
        editChargeAmount: ""
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    createLocationList = () => {
        let countries = this.props.country.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let destinations = this.props.destinations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let origins = this.props.origins.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let locations = this.props.location.filter(l => l.typeId === 3).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let allLocations = Array.prototype.concat.apply([], [countries, origins, destinations, locations]);
        if (allLocations[0].id !== 0) {
            allLocations.unshift({ id: 0, name: "ALL" });
        }
        this.setState({ allLocations });
    }

    getLocationClassName = () => {

        let optionElementList = document.querySelectorAll(".locationOptionClass");

        let countries = this.props.country.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let destinations = this.props.destinations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let origins = this.props.origins.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let locations = this.props.location.filter(l => l.typeId === 3).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

        optionElementList.forEach(el => {

            if ([...this.state.allLocations.map(loc => loc.name)].indexOf(el.value) <= countries.length) {
                el.style.backgroundColor = "lightblue"
            } else if ([...this.state.allLocations.map(loc => loc.name)].indexOf(el.value) <= ((origins.length) + (countries.length))) {
                el.style.backgroundColor = "lightred";
            } else if ([...this.state.allLocations.map(loc => loc.name)].indexOf(el.value) <= ((destinations.length) + (countries.length) + (origins.length))) {
                el.style.backgroundColor = "lightgreen";
            } else if ([...this.state.allLocations.map(loc => loc.name)].indexOf(el.value) <= ((locations.length) + (countries.length) + (origins.length) + (destinations.length))) {
                el.style.backgroundColor = "lightgrey"
            }
        })
    }

    addCharge = () => {

        let location = this.state.allLocations.filter(loc => loc.name === this.state.chargeLocation)[0];

        let charge = {
            contractId: this.props.contract.contractDetails.id,
            countryId: location !== undefined && location.id && location.countryId === undefined ? location.id : null,
            portId: location !== undefined && location.id && location.countryId !== undefined ? location.id : null,
            perContainerCharge: parseInt(this.state.chargeType),
            name: this.state.chargeName,
            amount: this.state.chargeAmount,
            freightTerms: this.state.freightTerms
        };

        axios.post("/api/v1/contract/containercharge", { ...auth.getAuthData(), charge }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return
            } else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Charge Added" } })
                this.props.getContract();
                document.getElementById("chargeName").select();
            }
        })
    }

    deleteCharge = (id) => {
        let contractId = this.props.contract.contractDetails.id
        axios.post("/api/v1/contract/containercharge/delete", { ...auth.getAuthData(), id, contractId }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return
            }
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Charge Deleted" } })
            this.props.getContract();
        })
    }

    perContainerMakeActive = (e, index) => {

        let editRowTable = e.currentTarget.parentNode.parentNode.id;

        let charges = this.props.contract.charges.perContainerCharges;

        this.setState({
            editRowIndex: index,
            editRowTable,
            editChargeLocation: charges[index].locationName,
            editChargeName: charges[index].name,
            editChargeAmount: charges[index].amount
        }, () => this.getLocationClassName());

    };

    additionalChargeMakeActive = (e, index) => {

        let editRowTable = e.currentTarget.parentNode.parentNode.id;

        let charges = this.props.contract.charges.additionalCharges;

        this.setState({
            editRowIndex: index,
            editRowTable,
            editChargeLocation: charges[index].locationName,
            editChargeName: charges[index].name,
            editChargeAmount: charges[index].amount
        }, () => this.getLocationClassName());
    };

    perContainerMakeInactive = (index) => {

        let charge = {};
        let chargeId = this.props.contract.charges["perContainerCharges"][index].id;

        if (this.state.editChargeLocation === "ALL") {
            charge = {
                id: chargeId,
                name: this.state.editChargeName,
                amount: this.state.editChargeAmount,
                perContainerCharge: true,
                countryId: null,
                portId: null,
                contractId: this.props.contract.contractDetails.id
            }
        } else {
            if (!this.state.allLocations.filter(l => l.name === this.state.editChargeLocation)[0]) {
                charge = {
                    id: chargeId,
                    name: this.state.editChargeName,
                    amount: this.state.editChargeAmount,
                    perContainerCharge: true,
                    countryId: null,
                    portId: null,
                    contractId: this.props.contract.contractDetails.id
                }
            } else {
                let useCountryId = this.state.allLocations.filter(l => l.name === this.state.editChargeLocation)[0].timeZone ? false : true;
                if (useCountryId) {
                    charge = {
                        id: chargeId,
                        name: this.state.editChargeName,
                        amount: this.state.editChargeAmount,
                        perContainerCharge: true,
                        countryId: this.state.allLocations.filter(loc => loc.name === this.state.editChargeLocation)[0].id,
                        portId: null,
                        contractId: this.props.contract.contractDetails.id
                    }
                } else {
                    charge = {
                        id: chargeId,
                        name: this.state.editChargeName,
                        amount: this.state.editChargeAmount,
                        perContainerCharge: true,
                        countryId: null,
                        portId: this.state.allLocations.filter(loc => loc.name === this.state.editChargeLocation)[0].id,
                        contractId: this.props.contract.contractDetails.id
                    }
                }
            }
        }

        axios.post("/api/v1/contract/containercharge/edit", { ...auth.getAuthData(), charge }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return
            }

            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Charge Saved" } })
            await this.props.getContract();
            this.setState({
                editRowIndex: undefined,
                editRowTable: undefined,
                editChargeCountryId: 0,
                editChargeName: "",
                editChargeAmount: ""
            });
        })
    }


    additionalChargeMakeInactive = (index) => {

        let chargeId = this.props.contract.charges["additionalCharges"][index].id;
        let charge = {};

        if (this.state.editChargeLocation === "ALL") {
            charge = {
                id: chargeId,
                name: this.state.editChargeName,
                amount: this.state.editChargeAmount,
                perContainerCharge: false,
                countryId: null,
                portId: null,
                contractId: this.props.contract.contractDetails.id
            }
        } else {

            let useCountryId = this.state.allLocations.filter(l => l.name === this.state.editChargeLocation)[0].timeZone ? false : true;

            if (useCountryId) {
                charge = {
                    id: chargeId,
                    name: this.state.editChargeName,
                    amount: this.state.editChargeAmount,
                    perContainerCharge: false,
                    portId: null,
                    countryId: this.state.allLocations.filter(loc => loc.name === this.state.editChargeLocation)[0].id,
                    contractId: this.props.contract.contractDetails.id
                }
            } else {
                charge = {
                    id: chargeId,
                    name: this.state.editChargeName,
                    amount: this.state.editChargeAmount,
                    perContainerCharge: false,
                    portId: this.state.allLocations.filter(loc => loc.name === this.state.editChargeLocation)[0].id,
                    countryId: null,
                    contractId: this.props.contract.contractDetails.id
                }
            }
        }

        axios.post("/api/v1/contract/containercharge/edit", { ...auth.getAuthData(), charge }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH " });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return
            }
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Charge Saved" } })
            await this.props.getContract();

            this.setState({
                editRowIndex: undefined,
                editRowTable: undefined,
                editChargeLocation: 0,
                editChargeName: "",
                editChargeAmount: ""
            });

        })
    }

    componentDidMount = () => {
        this.createLocationList();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.origins !== this.props.origins || prevProps.destinations !== this.props.destinations) {
            this.createLocationList();
        }
    }

    render = () => {
        return (
            <div>
                <div className="row">
                    <div className="col s12">
                        <h5>&nbsp;</h5>
                        <h6>Per Container Charges</h6>
                        <table className="fixedSize freightFeesTable">
                            <thead>
                                <tr className="tableHeaderLeftAlign">
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.contract.charges.perContainerCharges.map((f, index) => (
                                    this.state.editRowIndex === index && this.state.editRowTable === "edit-perContainerChargesTable" ?
                                        <tr key={f.id} id="edit-perContainerChargesTable">
                                            <td>
                                                <Form.Select name={`editChargeLocation`} value={this.state.editChargeLocation} optionClassName={"locationOptionClass"} onChange={this.onChange} list={this.state.allLocations} filter={{ label: "name", value: "name" }} />
                                            </td>
                                            <td>
                                                <Form.TextInput name={`editChargeName`} value={this.state.editChargeName} onChange={this.onChange} />
                                            </td>
                                            <td>
                                                <Form.TextInput name={`editChargeAmount`} value={this.state.editChargeAmount} onChange={this.onChange} />
                                            </td>
                                            <td>
                                                <i tabIndex="0" className={`material-icons `} style={{ fontSize: "18px", marginTop: "6px" }} onClick={() => this.perContainerMakeInactive(index)}>save</i>
                                            </td>
                                        </tr>
                                        :
                                        <tr key={f.id} id="edit-perContainerChargesTable">
                                            <td>{f.locationCode}</td>
                                            <td>{f.name}</td>
                                            <td>${f.amount}</td>
                                            <td>
                                                <i tabIndex="0" className={`material-icons  col s6`} style={{ fontSize: "18px", marginTop: "6px", paddingRight: "0px" }} onClick={(e) => this.perContainerMakeActive(e, index)}>edit</i>
                                                <i tabIndex="0" className={`material-icons  col s5`} style={{ fontSize: "18px", marginTop: "6px", paddingLeft: "5px" }} onClick={() => this.deleteCharge(f.id)}>cancel</i>
                                            </td>
                                        </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col s12">
                        <table className="fixedSize freightFeesTable" style={{ borderTop: "1px solid black" }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.contract.charges.perContainerChargesTotal.map((f, index) => (
                                    <tr key={f.id} className="freightFeesRow feeTotals">
                                        <td tabIndex="-1">
                                            {f.countryName} TOTAL
                                        </td>
                                        <td tabIndex="-1">
                                            ${f.chargeTotal}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col s12">
                        <h6>Addl. Charges</h6>
                        <table className="fixedSize freightFeesTable">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.contract.charges.additionalCharges.map((f, index) => (
                                    this.state.editRowIndex === index && this.state.editRowTable === "edit-additionalChargesTable" ?
                                        <tr key={f.id} id="edit-additionalChargesTable">
                                            <td>
                                                <Form.Select name={`editChargeLocation`} value={this.state.editChargeLocation} optionClassName={"locationOptionClass"} onChange={this.onChange} list={this.state.allLocations} filter={{ label: "name", value: "name" }} />
                                            </td>
                                            <td>
                                                <Form.TextInput name={`editChargeName`} value={this.state.editChargeName} onChange={this.onChange} />
                                            </td>
                                            <td>
                                                <Form.TextInput name={`editChargeAmount`} value={this.state.editChargeAmount} onChange={this.onChange} />
                                            </td>
                                            <td>
                                                <i tabIndex="0" className={`material-icons a-form-${index}`} style={{ fontSize: "18px", marginTop: "6px" }} onClick={() => this.additionalChargeMakeInactive(index)}>save</i>
                                            </td>
                                        </tr>
                                        :
                                        <tr key={f.id} id="edit-additionalChargesTable">
                                            <td>{f.locationCode}</td>
                                            <td>{f.name}</td>
                                            <td>${f.amount}</td>
                                            <td className="editAndDeleteIcons">
                                                <i tabIndex="0" className={`material-icons col s6 a-text-${index}`} style={{ fontSize: "18px", marginTop: "6px", paddingRight: "0px" }} onClick={(e) => this.additionalChargeMakeActive(e, index)}>edit</i>
                                                <i tabIndex="0" className={`material-icons col s5 right a-text-${index}`} style={{ fontSize: "18px", marginTop: "6px", paddingLeft: "5px" }} onClick={() => this.deleteCharge(f.id)}>cancel</i>
                                            </td>
                                        </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    {!this.state.showAddCharge ?
                        <div className="col s12 formDropdown" onClick={() => this.setState({ showAddCharge: !this.state.showAddCharge }, () => { this.getLocationClassName() })} >
                            <i className="material-icons">keyboard_arrow_down</i><span>Add Charge</span>
                        </div>
                        :
                        <div className="col s12 formDropdown">
                            <div className="row">
                                <div className="col s12" onClick={() => this.setState({ showAddCharge: !this.state.showAddCharge })} >
                                    <i className="material-icons">keyboard_arrow_up</i><span>Hide Form</span>
                                </div>
                            </div>
                            <div className="row">
                                <Form.Select col="col s4" labelClassName="smallFormLabel" className="smallForm" name="chargeType" value={this.state.chargeType} label="Type" onChange={this.onChange} list={[{ label: "PER CONT.", id: 1 }, { label: "ADDL. CHARGE", id: 0 }]} filter={{ label: "label", value: "id" }} />
                                <Form.Select col="col s4" labelClassName="smallFormLabel" className="smallForm" optionClassName={"locationOptionClass"} name={`chargeLocation`} value={this.state.chargeLocation} label="Location" onChange={this.onChange} list={this.state.allLocations} filter={{ label: "name", value: "name" }} />
                                <Form.Select col="col s4" labelClassName="smallFormLabel" className="smallForm" name="freightTerms" value={this.state.freightTerms} label="Freight Terms" onChange={this.onChange} list={[{ label: "PREPAID" }, { label: "COLLECT" }]} filter={{ label: "label", value: "label" }} />
                            </div>
                            <div className="row">
                                <Form.AutoComplete col="col s4" smallForm labelClassName="smallFormLabel" name="chargeName" value={this.state.chargeName} label="Charge Name" onChange={this.onChange} data={this.props.contract.carrierChargeList} filterValue="name" flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="col s4" labelClassName="smallFormLabel" stylingClass="smallForm" name="chargeAmount" value={this.state.chargeAmount} label="Amount" onChange={this.onChange} />
                                <Form.Button col="s4 feeAddButton" label="Add" icon="add" type="outline" color="blue" onClick={this.addCharge} iconClass="flagButtonIcon" />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { index, urls, location, carrier, shipper, country } = state;
    const url = urls[index];
    return { url, location, shipper, carrier, country };
};

export default connect(mapStateToProps)(FreightChargesDisplay);