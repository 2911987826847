import AllianceOverview from './AllianceOverview';
import CreateAlliance from './CreateAlliance';

const Alliance = props => (
    <Routes>
        <Route path='/' element={<AllianceOverview />} />
        <Route path='/create' element={<CreateAlliance />} />
    </Routes>
)

export default Alliance;