
import React from "react";
import styles from "./Requests.module.css";

const TemplateModal = (props) => {
    return (
        <div className="modal templateModal" id={styles.templateModal}>
            <div className="modal-content row" style={{ marginBottom: "0px" }}>
                <div className="row" style={{ marginBottom: "0px" }}>
                    <span className={styles.closeIcon}><i className="material-icons" onClick={props.closeTemplateModal}>close</i></span>
                </div>
                <div className="row" style={{ textAlign: "center" }}>
                    <h5 className={styles.modalHeader}>{props.shipperName} Booking  Templates </h5>
                </div >
                {props.templateList.map((template, index) => (
                    <div key={template.templateId} className={`row ${styles.templateListRow}`}>
                        <div className="col s11">{template.templateName}</div>
                        <span className="col s1" onClick={() => props.deleteTemplate(template.templateId, index)}><i className="material-icons">close</i></span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TemplateModal