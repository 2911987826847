import React from "react";
import styles from "./switch.module.css";



class Switch extends React.Component {

    onChange = e => {
        const value = e.target.checked;
        this.props.onChange(this.props.name, value);
    }

    render = () => (
        <div className={`col ${this.props.col} ${styles.switchStyle} ${this.props.className}`}>
            <div className={`switch ${this.props.className}`} >
                <label className={styles.labelContainer}>
                    <span className={styles.label}>{this.props.label}</span>
                    <input className={this.props.className} checked={this.props.value} name={this.props.name} onChange={this.onChange} type="checkbox" />
                    <span className={`lever ${this.props.className}`}></span>

                </label>
            </div>
        </div>

    )
}

export default Switch;