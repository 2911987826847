import React, { Component } from 'react';
import { connect } from "react-redux";
import { flags } from "../../../constants";
import Form from "../../../Components/NewComponents";
import axios from "axios";
import M from 'materialize-css';
import { globalToastActions } from '../../../Redux/actions';
import { auth } from "../../../helperFunctions";

class RatesDisplay extends Component {
    state = {
        addOriginId: "",
        addDestId: "",
        unusedOrigins: [],
        unusedDestinations: [],
        origins: [],
        destinations: [],
        flipSort: false,
        chargeSumView: false,
        flagCodes: [
            { id: 1, countryName: "UNITED STATES", countryCode: "us" },
            { id: 2, countryName: "BAHRAIN", countryCode: "bh" },
            { id: 3, countryName: "THAILAND", countryCode: "th" },
            { id: 4, countryName: "SOUTH KOREA", countryCode: "kr" },
            { id: 5, countryName: "CHINA", countryCode: "cn" },
            { id: 6, countryName: "SAUDI ARABIA", countryCode: "sa" },
            { id: 7, countryName: "JAPAN", countryCode: "jp" },
            { id: 8, countryName: "VIETNAM", countryCode: "vn" },
            { id: 9, countryName: "UNITED ARAB EMIRATES", countryCode: "ae" },
            { id: 10, countryName: " TAIWAN", countryCode: "tw" },
            { id: 11, countryName: " KUWAIT", countryCode: "kw" },
            { id: 12, countryName: " PHILIPPINES", countryCode: "ph" },
            { id: 13, countryName: " BRUNEI", countryCode: "bn" },
            { id: 14, countryName: " INDIA", countryCode: "in" },
            { id: 15, countryName: " MALAYSIA", countryCode: "my" },
            { id: 16, countryName: " SINGAPORE", countryCode: "sg" },
            { id: 17, countryName: " CANADA", countryCode: "ca" },
            { id: 18, countryName: " UNITED KINGDOM", countryCode: "uk" }
        ],
        sortByCountry: false,
        editRowIndex: undefined,
        editRowTable: undefined
    }

    initModal = () => {
        M.Modal.init(document.querySelector(".modal"));
    }

    initListeners = () => {
        document.addEventListener("keydown", this.rateSaveListener);
    }

    removeListeners = () => {
        document.removeEventListener("keydown", this.rateSaveListener);
    }

    rateSaveListener = (e) => {
        if (e.ctrlKey && e.keyCode === 83 && this.state.editRowTable === "rates") {
            e.preventDefault();
            this.makeInactiveAndSaveAll();
        }
    }

    onChange = (name, value) => {
        this.setState({ [name]: value })
    }

    makeActive = async (id) => {
        let textSpan = await document.getElementById('text-span-' + id);
        let formSpan = await document.getElementById('form-span-' + id);
        document.getElementById('r-edit-icon').style.display = "none";
        document.getElementById('r-save-icon').style.display = "inline";
        textSpan.style.display = "none";
        formSpan.style.display = "inline";
        document.getElementById("amount:" + id).focus();
    }

    makeActiveAll = async () => {
        this.setState({ editRowTable: "rates" });
    }

    makeInactiveAndSaveAll = async () => {

        this.setState({ editRowTable: undefined })

        let sortedRates = this.props.portPairs.reduce((result, element, i) => {
            if (!!element.amount) {
                result[0].push(element)
            } else if (!element.amount && !!element.id) {
                result[1].push(element);
            }
            return result;
        }, [[], []]);
        let contractId = this.props.contract.contractDetails.id

        if (!!sortedRates[0] || !!sortedRates[1]) {

            let allFreetime = await this.gatherFreetime(sortedRates[0]);

            if (allFreetime.length) {
                axios.post("/api/v1/contract/freetime", { ...auth.getAuthData(), allFreetime }).then(result => {
                    if (result.data === "NOT ATHENTICATED") {
                        localStorage.clear();
                        this.props.dispatch({ type: "SCORCHED_EARTH" });
                        return
                    } else if (result.errno) {
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `Freetime: ${result.code}` } });
                        return;
                    }
                    axios.post("/api/v1/contract/rate/edit/all", { ...auth.getAuthData(), sortedRates, contractId }).then(result => {
                        if (result.data === "NOT AUTHENTICED") {
                            localStorage.clear();
                            this.props.dispatch({ type: "SCORCHED_EARTH" });
                            return;
                        } else if (result.errno) {
                            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `Rates: ${result.code}` } });
                            return;
                        } else {
                            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: `Freetime and Rates Updated` } })
                            this.props.getContract();
                        }
                    })
                })
            } else {
                axios.post("/api/v1/contract/rate/edit/all", { ...auth.getAuthData(), sortedRates, contractId }).then(result => {
                    if (result.data === "NOT AUTHENTICATED") {
                        localStorage.clear();
                        this.props.dispatch({ type: "SCORCHED_EARTH" })
                        return;
                    } else if (result.errno) {
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `Rates: ${result.code}` } });
                        return;
                    } else {
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Rates Updated" } })
                        this.props.getContract();
                    }
                })
            }
        } else {
            return;
        }
    }

    gatherFreetime = async (newRates) => {

        let allFreetime = [];

        let rateDestinations = newRates.filter((set => s => !set.has(s.destId) && set.add(s.destId))(new Set()));
        let rateOrigins = newRates.filter((set => s => !set.has(s.originId) && set.add(s.originId))(new Set()));

        if (rateOrigins) {

            await rateOrigins.forEach(r => {

                let freetimeCheckOrigin = new Array(...this.props.contract.freetime.map(f => f.locationId)).includes(r.originId);

                if (!freetimeCheckOrigin) {
                    allFreetime.push({
                        contract_id: this.props.contract.contractDetails.id,
                        party_id: this.props.contract.contractDetails.shipperId,
                        carrier_id: this.props.contract.contractDetails.carrierId,
                        country_id: this.props.location.filter(l => l.id === r.originId)[0].countryId,
                        location_id: r.originId,
                        demurrage_days: null,
                        demurrage_time_type: null,
                        detention_days: null,
                        detention_time_type: null,
                        additional_demurrage: null,
                        expiration_date: null,
                        notes: ""
                    });
                }
            })
        }

        if (rateDestinations) {

            await rateDestinations.forEach(r => {

                let freetimeCheckDest = new Array(...this.props.contract.freetime.map(f => f.locationId)).includes(r.destId);

                if (!freetimeCheckDest) {
                    allFreetime.push({
                        contract_id: this.props.contract.contractDetails.id,
                        party_id: this.props.contract.contractDetails.shipperId,
                        carrier_id: this.props.contract.contractDetails.carrierId,
                        country_id: this.props.location.filter(l => l.id === r.destId)[0].countryId,
                        location_id: r.destId,
                        demurrage_days: null,
                        demurrage_time_type: null,
                        detention_days: null,
                        detention_time_type: null,
                        additional_demurrage: null,
                        expiration_date: null,
                        notes: ""
                    });
                }
            })
        }

        return allFreetime;
    }

    addLocation = async (type) => {

        if (type === "o") {
            await this.props.addOriginOrDest("o", parseInt(this.state.addOriginId));
            this.makeActiveAll();
            document.getElementById("addOriginId").focus();
        } else {
            await this.props.addOriginOrDest("d", parseInt(this.state.addDestId));
            this.makeActiveAll();
            document.getElementById("addDestId").focus();
        }
        this.getUnusedLocations();
    }

    getUnusedLocations = () => {
        let unusedOrigins = this.props.location.filter(l => l.typeId !== 3 && (l.countryId === 1 || l.countryId === 17) && !this.props.origins.map(o => o.id).includes(l.id));
        let unusedDestinations = this.props.location.filter(l => l.typeId !== 3 && (l.countryId !== 1 || l.countryId !== 17) && !this.props.destinations.map(o => o.id).includes(l.id));
        let addOriginId = unusedOrigins[0].id;
        let addDestId = unusedDestinations[0].id;

        this.setState({ unusedOrigins, unusedDestinations, addOriginId, addDestId })
    }

    getCountryFlag = (countryId) => {
        if (!countryId) {
            return;
        }
        let code = this.state.flagCodes.filter(fc => fc.id === countryId)[0].countryCode
        return <div className={`flag-icon flag-icon-${code}`}></div>
    }


    openLocationModal = async (type) => {
        await M.Modal.getInstance(document.querySelector("#location-modal")).open();
        document.getElementById(`add${type}Id`).focus();
    }

    closeLocationModal = () => {
        M.Modal.getInstance(document.querySelector("#location-modal")).close();
    }

    displaySaveWarning = async () => {
        let forms = document.querySelectorAll('.r-form-span')
        let answer = await forms.forEach(f => {
            if (f.style.display === "inline") {
                return true;
            }
        });
        return answer;
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.origins[0] !== prevProps.origins[0]) {
            this.setState({ origins: this.props.origins });
        }
        if (this.props.destinations !== prevProps.destinations) {
            this.setState({ destinations: this.props.destinations });
        }
    }

    componentDidMount = () => {
        this.initModal();
        this.initListeners();
        this.getUnusedLocations();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => {

        return (
            <div>
                <div className="row" style={{ marginBottom: "0px" }}>
                    <h5 className="col s1">Rates</h5>

                    {this.state.editRowTable === "rates" ?
                        <i className="material-icons col s1 r-form-span" id='r-save-icon' style={{ marginTop: "18px" }} onClick={this.makeInactiveAndSaveAll}>save</i>
                        :
                        <i className="material-icons col s1 r-text-span" id='r-edit-icon' style={{ marginTop: "18px" }} onClick={this.makeActiveAll}>edit</i>
                    }
                    <Form.Switch col="col s2 removeMargins chargeSumSwitch" label="Include Charges" name="chargeSumView" onChange={this.onChange} value={this.state.chargeSumView} />
                </div>
                <table className="ratesTable" style={{ position: "", marginBottom: "30px" }}>
                    <thead>
                        <tr style={{ textAlign: "left" }}>
                            <th>
                                <Form.Button col={`chartButton flagButton ${this.props.flipSort ? "chartButtonPressed" : ""}`} type="outline" color="black" icon="sort" onClick={this.props.sortByCountry} iconClass="flagButtonIcon" inlineStyle={{ padding: "5px" }} />
                            </th>
                            {this.props.origins.map((o, oIndex) => (
                                <th key={o.id} style={{ textAlign: "left" }} className={!o.hasFreetime ? 'freetime-origin-tooltip' : ''}>
                                    {!o.hasFreetime ?
                                        <span className="freetime-tooltiptext">No Freetime Exists For This Port</span>
                                        :
                                        null}
                                    {o.name}
                                </th>
                            ))}
                            <th style={{ position: "relative", marginTop: "-30px" }}>
                                <span className="originDest-tooltip" style={{ position: "absolute", right: "0", top: "0" }}>
                                    <span className="originDest-tooltiptext">Add Origin</span>
                                    <i className="material-icons addOriginIcon" onClick={() => this.openLocationModal("Origin")}>add</i>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="rates-edit">
                        {this.props.destinations.map((d, dindex, key) => (
                            <tr key={d.id} className="ratesTableRow" tabIndex="-1" >
                                <td tabIndex="-1" style={{ paddingRight: "20px", fontWeight: "700" }} className={!d.hasFreetime ? "freetime-tooltip" : ""}>
                                    {!d.hasFreetime ?
                                        <span className="freetime-tooltiptext">No Freetime Exists For This Port</span>
                                        : null}
                                    <span>{d.name}</span>
                                    {this.getCountryFlag(d.countryId)}
                                </td>
                                {this.props.portPairs.filter(pp => pp.destId === d.id).map((r, rindex) => (
                                    <td key={rindex} tabIndex="-1" className="tooltip">
                                        {r.amount && r.amount !== r.previousAmount ? <span className="tooltiptext">{r.previousAmount ? r.previousAmount : 0}</span> : null}
                                        {this.state.editRowTable === "rates" ?
                                            <span id={`form-span-${this.props.portPairs.indexOf(r)}`} className="r-form-span">
                                                <Form.TextInput stylingClass="ratesForm" name={`amount:${this.props.portPairs.indexOf(r)}`} value={r.amount} onChange={this.props.ratesOnChange} flags={[flags.ALLOW_EMPTY]} />
                                            </span>
                                            :
                                            <span id={`text-span-${this.props.portPairs.indexOf(r)}`} className={`r-text-span ${r.amount && r.amount !== r.previousAmount ? "highlightRate" : ""}`} style={{ display: "inline", position: "relative" }}>
                                                {!this.props.contract.specialRestrictions.filter(sp => sp.destId === r.destId && sp.originId === r.originId)[0] ? null :
                                                    <sup className="specRestSubscript">!</sup>}
                                                {r.contractId === this.props.contract.contractDetails.id ?
                                                    this.state.chargeSumView && r.amount ?
                                                        parseInt(r.chargeTotal) : r.amount : null}
                                            </span>
                                        }
                                    </td>
                                ))}
                                <td></td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <div style={{ marginTop: "-25px" }}>
                    <span className="originDest-tooltip">
                        <span className="originDest-tooltiptext">Add Destination</span>
                        <i className="material-icons" onClick={() => this.openLocationModal("Dest")}>add</i>
                    </span>
                </div>
                <div className={`modal`} id="location-modal">
                    <div className="modal-content">
                        <div className="row" style={{ marginBottom: "0px", marginTop: '-10px' }}>
                            <i className="material-icons right" onClick={this.closeLocationModal}>cancel</i>
                        </div>
                        <div className="row">
                            <div className="col s12" style={{ textAlign: "center" }}>
                                <h4>Add a Origin/Destination</h4>
                            </div>
                        </div>
                        <div className={`row`}>
                            <Form.Select col="col s6 offset-s2" label="Origins" name="addOriginId" value={this.state.addOriginId} list={this.state.unusedOrigins} filter={{ label: "name", value: "id" }} onChange={this.onChange} />
                            <Form.Button col="col s2" label="Add" icon="add" type="outline" color="green" onClick={() => this.addLocation("o")} />
                        </div>
                        <div className="row">
                            <Form.Select col="col s6 offset-s2" label="Destinations" name="addDestId" value={this.state.addDestId} list={this.state.unusedDestinations} filter={{ label: "name", value: "id" }} onChange={this.onChange} />
                            <Form.Button col="col s2" label="Add" icon="add" type="outline" color="green" onClick={() => this.addLocation("d")} />
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    const { index, urls, location, carrier, shipper } = state;
    const url = urls[index];
    return { url, location, shipper, carrier };
};

export default connect(mapStateToProps)(RatesDisplay);