import { Routes, Route } from 'react-router-dom';
import ServiceRouteOverview from './ServiceRouteOverview';
import CreateServiceRoute from './CreateServiceRoute';

const ServiceRoutes = props => 
    render = () => (
        <Routes>
            <Route path="/" element={<ServiceRouteOverview />} />
            <Route path='/create' element={<CreateServiceRoute />} />
        </Routes>
    )


export default ServiceRoutes;