import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { globalToastActions } from "../../../Redux/actions";
import moment from "moment-timezone";
import M from "materialize-css";
import Form from "../../../Components/NewComponents";
import Axios from "axios";
import EmailForm from '../EmailForm/EmailForm';
import { auth } from "../../../helperFunctions";
import { withRouter } from '../../../helperFunctions';

class ExdecResultsDisplay extends Component {
    state = {
        showContextMenu: false,
        deleteBkg: null,
        loading: true,
        notesArray: [],
        notesTextHolder: "",
        emails: [],
        subject: "",
        body: ""
    }

    onChange = (name, value) => {
        this.setState({ [name]: value })
    }

    notesOnChange = (name, value) => {

        let index = name.split(":")[1];
        let notesArray = [...this.state.notesArray];
        let note = { ...notesArray[index] };
        note.siInternalNotes = value;
        notesArray[index] = note;
        this.setState({ notesArray })

    }

    initModal = () => {
        M.Modal.init(document.querySelectorAll('.modal'));
    }

    initListeners = () => {
        // document.addEventListener("keyup", this.bookingNumberSelect);

    }

    removeListeners = () => {
        // document.removeEventListener("keyup", this.bookingNumberSelect);
    }

    handleEdit = (e, bkg) => {
        if (!e.ctrlKey && !e.shiftKey) {
            return this.props.navigate(`/shipment/create/${bkg.bookingNumber.split(' / ')[0]}`, {
                state: {
                    directory: 'SHIPMENTS/',
                    bookingId: bkg.id,
                    searchTerm: bkg.bookingNumber.split(' / ')[0],
                    carrierId: bkg.carrierId,
                    shipperId: bkg.shipperId,
                    filters: this.props.filters,
                    filterList: this.props.filterList,
                    status: bkg.status,
                    stage: {
                        unformattedDocCutDate: bkg.unformattedDocCutDate,
                        aes: bkg.aes,
                        setOrPrinted: bkg.sentOrPrinted,
                        invoiceStatus: bkg.invoiceStatus,
                        isDummy: bkg.isDummy,

                    }
                }
            })
        } else {
            e.preventDefault();
            return;
        }
    }

    handleMouseOver = (e) => {
        if (this.props.isMouseDown && e.target.parentElement.tagName === "TR") {
            e.target.parentElement.classList.add('menu-selected');
        }
    }

    handleMouseDown = (e) => {
        if (e.ctrlKey && e.buttons === 1 && e.target.parentElement.tagName === "TR") {
            let list = []
            e.target.parentElement.classList.forEach(c => list.push(c))
            if (list.includes("menu-selected")) {
                e.target.parentElement.classList.remove("menu-selected");
            } else {
                e.target.parentElement.classList.add("menu-selected");
            }
        } else if (e.ctrlKey && e.buttons === 1 && e.target.parentElement.tagName === "TD") {
            let list = []
            e.target.parentElement.parentElement.classList.forEach(c => list.push(c))
            if (list.includes("menu-selected")) {
                e.target.parentElement.parentElement.classList.remove("menu-selected");
            } else {
                e.target.parentElement.parentElement.classList.add("menu-selected");
            }
        } else {
            return;
        }
    }



    bookingNumberSelect = (e) => {
        if (e.key === "Shift" && document.getSelection().type === "Range") {
            let highlightedItems = [];
            let nodes = document.querySelectorAll(".bookingNum");
            nodes.forEach(n => {
                let word = n.textContent;
                let regex = new RegExp(word, "gm");
                if (regex.test(document.getSelection().toString())) {
                    highlightedItems.push(n.textContent);
                }
            })
            navigator.clipboard.writeText(highlightedItems.join('\n'));
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Copied To Clipboard" } });
        }
    }

    openNotes = (index) => {
        let notesTarget = document.querySelectorAll(".hiddenMenuTextArea")[index].childNodes[0];
        notesTarget.classList.remove("hideNotes");
        notesTarget.childNodes[0].focus();
    }

    closeNotes = (notes, index) => {

        let notesTarget = document.querySelectorAll(".hiddenMenuTextArea")[index].childNodes[0];
        let id = this.props.bookings[index].shipmentId;

        if (notes !== this.props.notesArray[index].siInternalNotes) {
            if (!id) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No SI Connected To This Booking" } });
                let notesArray = this.state.notesArray;
                notesArray[index] = "";
                this.setState({ notesArray })
                notesTarget.classList.add('hideNotes');
                return;
            }
            Axios.post("api/v1/instructions/editnotes", { ...auth.getAuthData(), id, notes }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                }
            })
            this.removeHideNotes();
        } else if (!notes) {
            notesTarget.classList.add('hideNotes');
        }
    }

    removeHideNotes = () => {
        let rows = document.querySelectorAll(".hiddenMenuTextArea");
        rows.forEach((r, index) => {
            if (this.state.notesArray[index].siInternalNotes) {
                r.childNodes[0].classList.remove("hideNotes")
            } else {
                r.childNodes[0].classList.add("hideNotes")
            }
        })
    }

    showEmailModal = async (bkg) => {
        let selectedBookings = [];
        document.querySelectorAll(".menu-selected").forEach(r => selectedBookings.push(this.props.bookings[r.rowIndex - 1]));
        let uniqueShippers = selectedBookings.filter((set => s => !set.has(s.shipperId) && set.add(s.shipperId))(new Set()));

        if (uniqueShippers.length > 1) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Reminders Must Share Shipper", type: "error" } });
            return;
        }

        let subject;
        let body;
        let firstName = `${this.props.user.first.charAt(0) + this.props.user.first.slice(1).toLowerCase()}`;
        let lastName = `${this.props.user.last.charAt(0) + this.props.user.last.slice(1).toLowerCase()}`;
        let finalName = `${firstName + " " + lastName}`
        if (document.querySelectorAll(".menu-selected").length) {
            await this.getEmails(selectedBookings[0]);

            subject = "Packing Lists Due";
            body = `<body>
                        <p>Hello,</p>
                        <p>Please be reminded to submit packing lists for these bookings:\n\r</p>`

            selectedBookings.forEach(booking => {
                body = `${body} \r\n${booking.bookingNumber} - ${booking.reminderDocCutDate}<br>`;
            });

            body = body + (`</p>\n\r
                    <p>(Please send all packing lists to packinglist@lindseyforwarders.com)\r\n</p>
                    <p>Thank you.</p>\r\n
                    <p>${finalName}\n<br>Lindsey Forwarders</p></body>`)
        } else {
            await this.getEmails(bkg);
            subject = `Packing List Due - ${bkg.bookingNumber}`;
            body = `<body>
                        <p>Hello,</p>
                        <p>Please be reminded to submit a packing list for ${bkg.bookingNumber}, doc cutoff ${bkg.reminderDocCutDate}.</p>\r\n
                        <p>(Please send all packing lists to packinglist@lindseyforwarders.com)</p>
                        <p>Thank you.
                        <p>${finalName}<br>
                        Lindsey Forwarders</p>`
        }

        let selectedBookingIds = selectedBookings.length ? selectedBookings.map(b => b.id) : [bkg.id];

        this.setState({ subject, body, selectedBookingIds, emailShipperId: bkg.shipperId }, () => {
            M.Modal.getInstance(document.querySelector("#emailModal")).open();
            document.querySelector("#recipient").focus();
        })

    }

    sendReminder = (email) => {

        let selectedBookingIds = this.state.selectedBookingIds;

        if (!selectedBookingIds.length || selectedBookingIds.includes(null)) {
            this.hideEmailModal();
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "No SI Exists" } });
            return;
        }

        this.hideEmailModal();
        const list = document.querySelectorAll('.lf-portal-tbody>tr');
        list.forEach(tr => tr.classList.remove('menu-selected'));
        Axios.post("/api/v1/email/reminder", { ...auth.getAuthData(), email, selectedBookingIds }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear()
                this.props.dispatch({
                    type: "SCORCHED EARTH"
                })
                return;
            }
            if (result.data.messageSent && result.data.err) {
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "warning", msg: "E-Mail Sent but Reminder Sent Not Updated"
                    }
                });
            } else if (result.data.messageSent) {
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "success", msg: "E-Mail Sent"
                    }
                });
                this.props.getBookings();
            } else if (!result.data.messageSent) {
                window.alert("Error: Email was not sent.");
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "error", msg: "E-Mail Was Not Sent"
                    }
                });
            }
        })

    }

    hideEmailModal = () => {
        M.Modal.getInstance(document.querySelector("#emailModal")).close();
    }


    goToInstructions = (e, bkg) => {
        if (e.ctrlKey) {
            return;
        } else {
            return this.props.navigate('/shipment/instructions', {
                state: {
                    directory: "SHIPMENTS/",
                    bookingId: bkg.id,
                    searchTerm: bkg.lNumber,
                    shipperId: bkg.shipperId,
                    filters: this.props.filters,
                    filterList: this.props.filterList,
                    status: bkg.status,
                    stage: {
                        unformattedDocCutDate: bkg.unformattedDocCutDate,
                        aes: bkg.aes,
                        sentOrPrinted: bkg.sentOrPrinted,
                        invoiceStatus: bkg.invoiceStatus
                    }
                }
            })
        }
    }

    generateBadge = status => {
        switch (status) {
            case 'ACTIVE':
                return <span id="activeBadge" className="badge">ACTV</span>
            case 'CANCELLED':
                return <span id="cancelBadge" className="new red badge">CNCL</span>
            case 'CLOSED':
                return <span id="closedBadge" className="new badge">CLSD</span>
            case 'ARCHIVED':
                return <span id="archiveBadge" className="new black badge">ACVD</span>
            default:
                return;
        }
    }

    generateStage = (docCutDate, aes, SIPrinted, LOB, invoice, dummy, dummySI, enteredContainers) => {

        let date = moment().format("YYYY-MM-DD HH:mm:ss");

        if (invoice !== null) {
            return <span className="material-icons" style={dummy ? { color: "#9c27b0" } : { "color": "green" }}>attach_money</span>
        }
        else if (SIPrinted !== null && SIPrinted !== undefined && SIPrinted) {
            if (dummySI || !enteredContainers) {
                return <span className="material-icons">assignment_ind</span>
            }
            return <span className="material-icons">assignment_turned_in</span>
        }
        else if (moment(date).isAfter(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"))) {
            return <span className="material-icons" style={dummy ? { color: "#9c27b0" } : { "color": "red" }}>assignment_late</span>
        }
        else if (moment(date).isSame(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"), "D")) {
            return <span className="material-icons">history</span>
        } else {
            return <span className="material-icons"></span>
        }
    }


    getEmails = async (bkg) => {
        let partyId = parseInt(bkg.shipperId);
        let role = 'P/L REMINDERS';
        await Axios.get("/api/v1/contacts", { params: { ...auth.getAuthData(), partyId, role } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
            this.setState({ emails: result.data });
        })
    }

    refresh = () => { this.setState({ loading: true }); this.props.getBookings() }
    componentDidMount = () => {

        this.refresh();
        this.initListeners();
        this.initModal();

    }
    componentDidUpdate = (prevProps, props) => {

        if (prevProps !== this.props) {
            this.setState({ loading: false });
        }

        if (this.props.notesArray !== prevProps.notesArray && this.props.filters.useNotes) {
            this.setState({ notesArray: this.props.notesArray }, this.removeHideNotes);
        }

        if (this.props.notesArray !== prevProps.notesArray) {
            this.setState({ notesArray: this.props.notesArray })
        }

    }

    shouldComponentUpdate = (nextProps, props) => {
        if (!props.length) {
            return true;
        }
    }


    componentWillUnmount = () => { this.removeListeners(); }

    render = () => {
        return (
            <Fragment>
                {this.props.loading || !this.props.filters.useNotes ?
                    <tr>
                        <td>
                            <div className="preloader-wrapper big active overviewLoader">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    :
                    this.props.bookings.map((bkg, index) => (
                        <tr
                            onFocus={(e) => { this.props.setIndexOnFocus(e, index) }}
                            className={`docsDueTableRow ${bkg.isDummy ? 'dummyPurple' : ''} resultsDisplay exdecResultsDisplay ${bkg.id}`}
                            id={`bkg-tr-${index}`}
                            key={index}
                            tabIndex="0"
                            onMouseOver={this.handleMouseOver}
                            onMouseDown={(e) => this.handleMouseDown(e)}
                        >
                            <td className="material-icons stageInitials unselectable tooltip" onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)} onClick={(e) => this.handleEdit(e, bkg)}>
                                <span className="tooltiptext">{bkg.submitUserFullName}</span>
                                <span id="submitInitialsBadge" className="">
                                    {bkg.submitAssignedTo}
                                </span>
                            </td>
                            <td className="farLeft unselectable" >{this.generateStage(bkg.unformattedDocCutDate, bkg.aes, bkg.sentOrPrinted, bkg.lobDate, bkg.invoiceStatus, bkg.isDummy, bkg.isDummySI, bkg.enteredContainerCount)}</td>
                            <td className="farLeft unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{this.generateBadge(bkg.status)}</td>
                            <td className="bookingNum" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.bookingNumber}</td>
                            <td className="refNum unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.shipperReferenceNumber}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.shipperName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.consigneeName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.carrierName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)} style={{ fontSize: '1.1em', fontWeight: '500' }}>{bkg.containerCount}/{bkg.enteredContainerCount ? bkg.enteredContainerCount : 0}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.origin}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)} style={{ fontWeight: "700" }}>{bkg.portOfLoad}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.dest}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.vesselName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.voyageNumber}</td>
                            <td className="unselectable hideOnHover" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.cargoCutDate}</td>
                            <td className="unselectable hideOnHover" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)} style={{ fontWeight: "700" }}>{bkg.exdecDocCutDate}</td>
                            <td className="unselectable hideOnHover" onClick={(e) => this.goToInstructions(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>
                                {bkg.aes ? <i className="material-icons">check</i> :
                                    null}
                            </td>
                            <td className="unselectable hideOnHover" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleAssigneeMenu(e, index, bkg)}>{bkg.etd}</td>
                            <td className="hiddenMenu">
                                <div className="hiddenMenuTextArea">
                                    <Form.TextArea label="Internal Notes" col={`col s12 hideNotes`} className="docsDueInternalNotes" labelClass="docsDueInternalNotesLabel" name={`siInteralNotes:${index}`} onChange={this.notesOnChange} value={this.state.notesArray[index] ? this.state.notesArray[index].siInternalNotes : ""} onBlur={() => this.closeNotes(this.state.notesArray[index].siInternalNotes, index)} />
                                </div>
                                <div className="hiddenMenuButtons row">
                                    <span className="col lastSentTime">
                                        {bkg.reminderLastSent
                                            ? moment(bkg.currentTime, "MM/DD/YY H:mm").diff(moment(bkg.reminderSentConverted, "MM/DD/YY H:mm"), 'hours') !== 0
                                                ? moment(bkg.currentTime, 'MM/DD/YY H:mm').diff(moment(bkg.reminderSentConverted, 'MM/DD/YY H:mm'), 'hours') > 72
                                                    ? `Over 72 hours ago`
                                                    : `${moment(bkg.currentTime, "MM/DD/YY H:mm").diff(moment(bkg.reminderSentConverted, "MM/DD/YY H:mm"), 'hours')} hour(s) ago`
                                                : `${moment(bkg.currentTime, "MM/DD/YY H:mm").diff(moment(bkg.reminderSentConverted, "MM/DD/YY H:mm"), 'minutes')} min(s) ago`
                                            : ""}
                                    </span>
                                    <div className="heightParent delayedTooltip">
                                        <span style={{ marginRight: "20px" }} className="tooltiptext">Add Notes</span>
                                        <Form.Button col="col hiddenMenuButton" iconSize="tiny" buttonClass="exdecHiddenButtonStyle" iconClass="siNotesButtonIcon" onClick={() => this.openNotes(index)} icon="create" color="green" type="outline" />
                                    </div>
                                    <div className="heightParent delayedTooltip">
                                        <span style={{ marginRight: "20px" }} className="tooltiptext">Send Email</span>
                                        <Form.Button col="col hiddenMenuButton" iconSize="tiny" buttonClass="exdecHiddenButtonStyle" iconClass="siNotesButtonIcon" onClick={() => this.showEmailModal(bkg)} icon="email" color="blue" type="outline" />
                                    </div>
                                    <div className="heightParent delayedTooltip">
                                        <span style={{ marginRight: "20px" }} className="tooltiptext">Go to SI</span>
                                        <Form.Button col="col hiddenMenuButton" iconSize="tiny" buttonClass="exdecHiddenButtonStyle" iconClass="siNotesButtonicon" onClick={(e) => this.goToInstructions(e, bkg)} icon="assignment_due" color="black" type="outline" />
                                    </div>
                                </div>

                            </td>
                        </tr>
                    ))}
                <EmailForm
                    shipperId={this.state.emailShipperId}
                    onChange={this.onChange}
                    generatePdf={false}
                    emails={this.state.emails}
                    party={this.props.party}
                    showEmailModal={this.showEmailModal}
                    hideEmailModal={this.hideEmailModal}
                    subject={this.state.subject}
                    body={this.state.body}
                    send={this.sendReminder}
                />
            </Fragment>
        )
    }
}


const mapStateToProps = state => {
    const { location, user, urls, index, portalUser, party } = state;
    const url = urls[index];
    return { location, user, url, portalUser, party }
}

export default connect(mapStateToProps)(withRouter(ExdecResultsDisplay))