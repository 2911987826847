import React from 'react';
import { get, post } from '../../../helperFunctions';
import Confirm from '../../../Components/Confirm/Confirm';
import OverviewTable from '../../../Components/OverviewTable/OverviewTable';

class ServiceRouteOverview extends React.Component {
    constructor(props) { super(props); this.state = this.initState() };

    initState = () => ({ serviceRoutes: null, showModal: false, serviceRoute: null });
    getServiceRoutes = () => get('/serviceroute', serviceRoutes => this.setState({ serviceRoutes }), this.handleFailure);
    handleFailure = err => this.setState({ msg: { style: 'red-text', text: 'An unknown server-side error occurred' } });
    refresh = () => this.getServiceRoutes();
    showModal = serviceRoute => this.setState({ showModal: true, serviceRoute });
    handleDelete = () => post('/serviceroute/archive', { id: this.state.serviceRoute.id }, this.deleteSuccess, this.handleFailure);
    deleteSuccess = () => this.setState({ showModal: false }, this.refresh());
    handleEdit = serviceRoute => this.props.history.replace('/carrier/serviceroute/create', { serviceRoute, editMode: true });
    componentDidMount = () => this.refresh();

    generateFields = () => ([{ header: 'Service Route', prop: 'name' }]);

    render = () => (
        <div>
            <h3 className="center blue-text text-darken-4">Service Routes</h3>

            <OverviewTable
                fields={this.generateFields()}
                rows={this.state.serviceRoutes}
                showModal={this.showModal}
                handleEdit={this.handleEdit}
            />

            <Confirm
                id="serviceroute-confirm-modal"
                show={this.state.showModal}
                msg={`Delete Vessel ${this.state.serviceRoute ? this.state.serviceRoute.name : null}?`}
                confirm={this.handleDelete}
            />
        </div>
    )

}

export default ServiceRouteOverview;