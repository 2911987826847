import { useEffect } from "react";
import { useState } from "react";

const BubbleRow = props => {
    const {
        bubbles,
        popBubble
    } = props;

    const [displayArray, setDisplayArray] = useState(null);

    useEffect(() => {
        const arr = [];

        Object.keys(bubbles).forEach(key => {
            bubbles[key].forEach((value, index) => {
                arr.push({
                    id: `${key}-${index}`,
                    value
                })
            })
        })

        setDisplayArray(arr)
    }, [bubbles])

    const divColors = {
        shipperName: '#0e3745',
        carrierName: '#b03510',
        originName: '#108bb0',
        destinationName: '#5f3a30',
        consigneeName: '#0e3745',
        vesselName: '#305496',
        voyageNumber: '#305496',
        serviceRoute: '#697f85',
        pickupName: '#008500',
        returnName: '#019d01',
        portOfLoad: '#40ba40'
    }

    return (
        <div className="row">
            {displayArray?.map(row =>
                <div
                    key={row.id}
                    style={{
                        display: 'inline-block',
                        height: '15px',
                        padding: '0px 10px 24px 8px',
                        marginTop: '2px',
                        marginRight: '8px',
                        color: '#fff',
                        borderRadius: '5px',
                        backgroundColor: divColors[row.id.split('-')[0]] || '#000'
                    }}
                >
                    {row.value}
                    <i
                        className="material-icons tiny"
                        style={{
                            position: 'relative',
                            left: '4px',
                            top: '2px'
                        }}
                        onClick={() => popBubble(row.id)}
                    >
                        cancel
                    </i>
                </div>
            )}
        </div>
    )
}

export default BubbleRow;