import { useEffect, useRef, useState } from 'react';
import Search from './Search';
import Table from './Table';
import Axios from 'axios';
import { auth, logout } from '../../helperFunctions';
import { useNavigate, useParams } from 'react-router-dom';
import md5 from 'md5';

const Overview = () => {
    const { hash } = useParams();
    const navigate = useNavigate();

    const ref = useRef({ searchAbortController: new AbortController() })
    const [bookingList, setBookingList] = useState(null);
    const [searching, setSearching] = useState(false);
    const [searchParams, setSearchParams] = useState(null);
    const [urlParams, setUrlParams] = useState(hash ? null : 'NO HASH');

    useEffect(() => {
        if (hash)
            Axios.get('/api/v2/url/read/one/hashedurl', {
                params: {
                    ...auth.getAuthData(),
                    md5Hash: hash
                }
            })
                .then(result => setUrlParams(JSON.parse(result.data.data)))
                .catch(logout)
    }, [hash])

    const search = values => {
        setSearchParams(values);
        ref.current.searchAbortController?.abort();
        ref.current.searchAbortController = new AbortController();

        setSearching(true)
        Axios.get('/api/v2/booking/read/search/shipmentoverviewsearch', {
            params: {
                ...auth.getAuthData(),
                ...values
            },
            signal: ref.current.searchAbortController.signal
        })
            .then(result => setBookingList(result.data))
            .catch(logout)
            .finally(() => setSearching(false))
    }

    const navigateToUpdateShipmentPage = (selectedLNumber, urlEndpoint) => {
        const data = JSON.stringify({ ...searchParams, selectedLNumber });
        const md5Hash = md5(data);

        createPageHash(data, md5Hash)
        navigate(`/new/shipmentoverview/${md5Hash}`, { replace: true });
        navigate(`/new/updateshipment/${selectedLNumber}/${urlEndpoint}`)
    }

    const createPageHash = (data, md5Hash) => {
        Axios.post('/api/v2/url/create/one/hashedurl', {
            ...auth.getAuthData(),
            data,
            md5Hash
        })
            .catch(logout)
    }

    return (
        <div>
            <Search search={search} urlParams={urlParams} />
            {searching ?
                <div className="row">
                    <div style={{ maxHeight: '2px' }} className='progress blue'>
                        <div className='indeterminate' />
                    </div>
                </div>
                :
                <Table
                    bookingList={bookingList}
                    urlLNumber={urlParams?.selectedLNumber}
                    navigateToUpdateShipmentPage={navigateToUpdateShipmentPage}
                />
            }
        </div>
    )
}

export default Overview;