import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "../../Components/NewComponents";
import axios from "axios";
import moment from "moment-timezone";
import { globalToastActions } from "../../Redux/actions";
import { flags } from "../../constants";
import StickyNote from "../../Components/StickyNote/StickyNote";
import M from "materialize-css";
import DocumentationResultsDisplay from "../Documentation/DocumentationResultsDisplay";
import PageCountDisplay from "../Shipments/PageCountDisplay/PageCountDisplay";
import { auth, withRouter } from "../../helperFunctions";
import { CSVLink } from "react-csv";
import TableHeader from "../../Components/TableHeader/TableHeader";
import { Buffer } from 'buffer';


class DocumentationOverview extends Component {
    state = {
        showContextMenu: false,
        showProofMenu: false,
        showCloseoutMenu: false,
        isMouseDown: false,
        highlightedShipments: [],
        shipments: [],
        employees: [],
        filters: {
            bookingNumber: "",
            shipperName: "",
            consigneeName: "",
            carrierName: "",
            shipperRef: "",
            lobDateOut: "",
            lobDateOutDate: "",
            proofedAndCloseoutStatus: "ALL",
            stage: "ANY",
            employee: this.props.location?.state?.employee || this.props.user.id,
            voyageNumber: "",
            vesselName: "",
            containerNumber: "",
            fileName: "",
            portOfDelivery: "",
            status: "ACTIVE",
            dateType: "ETD",
            startDate: "",
            endDate: "",
            proofed: false,
            closeout: false,
            submitAssignTo: "1",
            proofAssignTo: this.props.location?.state?.employee || this.props.user.id,
            closeoutAssignTo: this.props.location?.state?.employee || this.props.user.id,
            page: 0,
            pageSize: 20,
            count: [],
            resultsCount: null,
            showCount: false,
            currentSort: "",
            reverse: "",
            currentSort2: "",
            reverse2: "",
            currentSort3: "",
            reverse3: "",
            searchOpen: false,
            rowHighlightIndex: undefined
        },
        stickyBooking: {

        },
        fileBuffer: [],
        aesReportData: [],
        aesReportHeaders: [
            { label: "ITN", key: "aesNumber" },
            { label: "Shipment Reference Number", key: "shipmentReferenceNumber" },
            { label: "Transportation Reference Number", key: "shipmentReferenceNumber" },
            { label: "ISPPI EIN", key: "isppiEin" },
            { label: "Export Date", key: "exportDate" },
            { label: "Ultimate Consignee Company Name", key: "ultimateConsigneeCompanyName" },
            { label: "Ultimate Consignee Line 1", key: "ultimateConsigneeAddressLineOne" },
            { label: "Ultimate Consignee Line 2", key: "ultimateConsigneeAddressLineTwo" },
            { label: "Ultimate Consignee City", key: "ultimateConsigneeCity" },
            { label: "Ultimate Consignee Country", key: "ultimateConsigneeCountryCode" },
            { label: "U.S. State of Origin", key: "usStateOfOriginCode" },
            { label: "Country of Ultimate Destination Code", key: "countryOfUltimateDestinationCode" },
            { label: "Conveyance Name", key: "conveyanceName" },
            { label: "Port Of Export Code", key: "portOfExportCode" },
            { label: "Foreign Port of Unlading", key: "foreignPortOfUnlading" },
            { label: "Carrier SCAC/IATA Code", key: "carrierScacCode" },
            { label: "Commodity Description", key: "commodityDescription" },
            { label: "HTS Schedule B Number Full", key: "htsScheduleBNumberFull" },
            { label: "Commodity Line Gross Weight (KG)", key: "commodityLineGrossWeight" },
            { label: "Quantity 1", key: "quantity1" },
            { label: "Late File Indicator", key: "lateFileIndicator" },
            { label: "Shipment Status", key: "shipmentStatus" },
        ]
    }

    initialState = () => ({
        shipments: [],
        showContextMenu: false,
        showProofMenu: false,
        showCloseoutMenu: false,
        highlightedShipments: [],
        employees: [],
        filters: {
            bookingNumber: "",
            shipperName: "",
            consigneeName: "",
            carrierName: "",
            shipperRef: "",
            lobDateOut: "",
            lobDateOutDate: "",
            proofedAndCloseoutStatus: "ALL",
            stage: "ANY",
            employee: this.props.location?.state?.employee || this.props.user.id,
            voyageNumber: "",
            vesselName: "",
            containerNumber: "",
            fileName: "",
            portOfDelivery: "",
            status: "ACTIVE",
            dateType: "ETD",
            startDate: "",
            endDate: "",
            proofed: false,
            closeout: false,
            submitAssignTo: "1",
            proofAssignTo: this.props.location?.state?.filters?.employee || this.props.user.id,
            closeoutAssignTo: this.props.location?.state?.filters?.employee || this.props.user.id,
            page: 0,
            pageSize: 20,
            count: [],
            resultsCount: null,
            showCount: false,
            currentSort: "",
            reverse: "",
            searchOpen: false
        }
    })

    initListeners = () => {
        document.querySelectorAll('.search-field').forEach(elem => elem.addEventListener('keyup', this.searchListener));
        document.addEventListener('keydown', this.controlCListener);
        document.addEventListener('keydown', this.clearListener);
        document.addEventListener('mousedown', this.setMouseStatus);
        document.addEventListener('mouseup', this.setMouseStatus);
        document.addEventListener("keydown", this.arrowKeyListener);
        document.addEventListener("keydown", this.openNotesListener);

    }

    removeListeners = () => {
        document.querySelectorAll('.search-field').forEach(elem => elem.removeEventListener('keyup', this.searchListener));
        document.addEventListener('keydown', this.controlCListener);
        document.removeEventListener("keydown", this.clearListener);
        document.removeEventListener('mousedown', this.setMouseStatus)
        document.removeEventListener('mouseup', this.setMouseStatus);
        document.removeEventListener("keydown", this.arrowKeyListener);
        document.removeEventListener("keydown", this.openNotesListener);
    }

    clearListener = async e => {
        if (e.key === "Escape") {
            if (await this.checkForOpenModals()) {
                document.getElementById(`bkg-tr-${this.state.filters.rowHighlightIndex}`).focus();
                return
            } else {
                this.clear();
            }
        }
    }

    searchListener = e => { if (e.keyCode === 13) this.freshSearch(); }

    arrowKeyListener = (e) => {
        if (!document.activeElement) {
            return;
        } else {
            let tag = document.activeElement.tagName;
            if (tag !== "TR") {
                return
            } else if (((e.code === "Tab" && !e.shiftKey) || e.code === "ArrowDown") && this.state.filters.rowHighlightIndex === this.state.shipments.length - 1) {
                e.preventDefault();
                return;
            } else if ((e.code === "Tab" && e.shiftKey) && (this.state.filters.rowHighlightIndex === 0)) {
                e.preventDefault();
                return;
            } else if ((e.code === "ArrowUp" || (e.code === "Tab" && e.shiftKey)) && this.state.filters.rowHighlightIndex !== 0) {
                e.preventDefault();
                this.setState({ filters: { ...this.state.filters, rowHighlightIndex: this.state.filters.rowHighlightIndex - 1 } }, () => {
                    let row = document.querySelector(`#bkg-tr-${this.state.filters.rowHighlightIndex}`);
                    if (row) {
                        row.focus();
                    }
                });
            } else if ((e.code === "ArrowDown" || e.code === "Tab") && this.state.filters.rowHighlightIndex !== this.state.shipments.length + 1) {
                e.preventDefault();
                this.setState({ filters: { ...this.state.filters, rowHighlightIndex: this.state.filters.rowHighlightIndex + 1 } }, () => {
                    let row = document.querySelector(`#bkg-tr-${this.state.filters.rowHighlightIndex}`);
                    if (row) {
                        row.focus();
                    }
                });
            } else {
                return;
            }
        }
    }

    setIndexOnFocus = (e, index) => {
        e.preventDefault(e);
        if (e.ctrlKey) {
            return;
        } else {
            this.setState({ filters: { ...this.state.filters, rowHighlightIndex: index } });
        }
    }

    openNotesListener = (e) => {
        if (e.key === "Enter" && document.activeElement.tagName === "TR") {
            if (e.ctrlKey) {
                this.goToInstructions(e, this.state.shipments[this.state.filters.rowHighlightIndex])
            } else {
                document.activeElement.children[3].click();
            }
        }
    }

    focusHighlightRow = () => {
        let row = document.querySelector(`#bkg-tr-${this.state.filters.rowHighlightIndex}`);
        if (this.state.filters.rowHighlightIndex && !!row) {
            setTimeout(() => {
                row.focus();
            }, 100)
        } else {
            return;
        }
    }

    changeStatus = status => {
        const bkg = JSON.parse(JSON.stringify(this.state.editBkg));
        bkg.status = status;
        axios.post("/api/v1/booking/updatestatus", { id: bkg.bookingId, status, ...auth.getAuthData() }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }

            this.freshSearch();
        })
    }

    checkForOpenModals = async () => {
        let modals = document.querySelectorAll('.modal');
        let openModal = false;
        await modals.forEach(modal => {
            if (M.Modal.getInstance(modal).isOpen) {
                this.closeModal();
                return openModal = true;
            }
        });
        return openModal;
    }

    setMouseStatus = (e) => {
        if (e.ctrlKey && e.buttons === 1) {

            e.preventDefault();
            if (this.state.isMouseDown) {
                this.setState({ isMouseDown: false })
            } else {
                this.setState({ isMouseDown: true })
            }
        } else if (this.state.isMouseDown) {
            this.setState({ isMouseDown: false });
        } else {
            return;
        }
    }

    controlCListener = (e) => {
        if (e.ctrlKey && e.key === "c") {
            if (document.querySelectorAll(".menu-selected").length) {
                e.preventDefault();
                this.copyBookingNumbers();
            } else {
                return;
            }
        }
    }

    copyBookingNumbers = () => {
        let highlightedItems = document.querySelectorAll(".menu-selected>td.bookingNum");
        let bookingNumberList = [];
        highlightedItems.forEach(row => bookingNumberList.push(row.innerHTML));
        navigator.clipboard.writeText(bookingNumberList.join('\n'));
        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Copied To Clipboard" } });
        document.querySelectorAll(".menu-selected").forEach(i => i.classList.remove('menu-selected'));
    }

    handleEdit = (e, bkg) => {

        if (!e.ctrlKey && !e.shiftKey) {
            this.setState({ stickyBooking: bkg, filters: { ...this.state.filters, rowHighlightIndex: parseInt(e.target.parentElement.getAttribute('data-index'), 10) } }, () => {
                return this.openModal();
            });
        } else if (e.shiftKey) {
            this.goToInstructions(e, bkg)

        } else {
            e.preventDefault();
            return;
        }

    }

    goToInstructions = (e, bkg) => {
        e?.preventDefault();

        this.props.navigate('/shipment/instructions', {
            state: {
                origin: "document",
                searchTerm: bkg.lNumber,
                shipperId: bkg.shipperId,
                filters: this.state.filters,
                status: bkg.status,
                stage: {
                    unformattedDocCutDate: bkg.unformattedDocCutDate,
                    aes: bkg.aes,
                    datetimePrinted: bkg.datetimePrinted,
                    invoiceStatus: bkg.invoiceStatus
                }
            }
        })
    }

    handleDelete = () => {
        const bkg = JSON.parse(JSON.stringify(this.state.editBkg));
        if (window.confirm(`ARCHIVE BOOKING: ${bkg.bookingNumber}?`)) {
            axios.post('/api/v1/booking/updatestatus',
                {
                    ...auth.getAuthData(),
                    status: 'ARCHIVED',
                    id: bkg.bookingId,
                }).then(result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' })
                        return;
                    }
                    this.freshSearch();
                });
        }
        else {
            this.setState({ editBkg: null });
        }
    }


    handleContextMenu = (e, index, s, stage) => {
        e.preventDefault();


        //COMMENTED OUT TEMPORARILY -- LOGIC FOR MARKING AS CLOSEDOUT OR PROOFED
        if (stage === "p") {
            document.addEventListener('click', this.clickListener);
            this.setState({ showContextMenu: true, x: e.pageX, y: e.pageY, editBkg: s })


        } else if (stage === "c") {

            document.addEventListener('click', this.clickListener);
            this.setState({ showContextMenu: true, x: e.pageX, y: e.pageY, editBkg: s })
        }
        document.addEventListener('click', this.clickListener);
        this.setState({ showProofMenu: false, showCloseoutMenu: false, showContextMenu: true, showStatusMenu: false, x: e.pageX, y: e.pageY, editBkg: s })
    }

    handleProofMenu = (e, index, s) => {
        e.preventDefault();
        this.setState({ showProofMenu: true, showCloseoutMenu: false, showContextMenu: false, showStatusMenu: false, x: e.pageX, y: e.pageY, editBkg: s })

        document.addEventListener('click', this.clickListener)
        const list = document.querySelectorAll('.lf-portal-tbody>tr');
        list.forEach(tr => { tr.classList.remove('menu-selected') });
        document.querySelector(`#bkg-tr-${index}`).classList.add('menu-selected');
    }

    handleCloseoutMenu = (e, index, s) => {
        e.preventDefault();
        this.setState({ showCloseoutMenu: true, showProofMenu: false, showContextMenu: false, showStatusMenu: false, x: e.pageX - 150, y: e.pageY, editBkg: s })

        document.addEventListener('click', this.clickListener)
        const list = document.querySelectorAll('.lf-portal-tbody>tr');
        list.forEach(tr => { tr.classList.remove('menu-selected') });
        document.querySelector(`#bkg-tr-${index}`).classList.add('menu-selected');
    }

    handleStatusMenu = (e, index, s) => {
        e.preventDefault();
        this.setState({ showProofMenu: false, showCloseoutMenu: false, showStatusMenu: true, showContextMenu: false, x: e.pageX, y: e.pageY, editBkg: s })

        document.addEventListener('click', this.clickListener)
        const list = document.querySelectorAll('.lf-portal-tbody>tr');
        list.forEach(tr => { tr.classList.remove('menu-selected') });
        document.querySelector(`#bkg-tr-${index}`).classList.add('menu-selected');
    }



    clickListener = e => {
        const list = document.querySelectorAll(".lf-portal-tbody>tr");
        list.forEach(tr => tr.classList.remove("menu-selected"));
        this.setState({ showContextMenu: false, showStatusMenu: false, showProofMenu: false, showCloseoutMenu: false },
            () => document.removeEventListener('click', this.clickListener))
    }

    initModal = () => {
        M.Modal.init(document.getElementById("overviewStagesModal"), { inDuration: '100', outDuration: "100", onCloseEnd: this.focusHighlightRow });
    }

    openModal = () => {
        M.Modal.getInstance(document.getElementById("overviewStagesModal")).open();
    }

    closeModal = () => {
        M.Modal.getInstance(document.getElementById("overviewStagesModal")).close();
    }

    updateOverviewWithNoteChanges = (updatedShipment) => {
        let shipments = this.state.shipments;
        let index = this.state.shipments.map(s => s.id).indexOf(updatedShipment.id);
        shipments[index] = { ...updatedShipment };
        this.setState({ shipments })
    }

    changeAssignee = (type, value) => {

        let infoSet = [];
        if (document.querySelectorAll(".menu-selected").length) {
            const list = document.querySelectorAll(".menu-selected")

            list.forEach(row => {
                infoSet.push({
                    shipmentId: this.state.shipments[row.sectionRowIndex].id,
                    shipperId: this.state.shipments[row.sectionRowIndex].shipperId,
                    submitUser: type === "submitAssignTo" ? parseInt(this.state.filters.submitAssignTo) : this.state.shipments[row.sectionRowIndex].submitId,
                    proofUser: type === "proofAssignTo" ? parseInt(this.state.filters.proofAssignTo) : this.state.shipments[row.sectionRowIndex].proofId,
                    closeoutUser: type === "closeoutAssignTo" ? parseInt(this.state.filters.closeoutAssignTo) : this.state.shipments[row.sectionRowIndex].closeoutId,
                })
                row.classList.remove("menu-selected");

            });
        } else {
            infoSet.push({
                shipmentId: this.state.editBkg.id,
                shipperId: this.state.editBkg.shipperId,
                submitUser: type === "submitAssignTo" ? parseInt(this.state.filters.submitAssignTo) : this.state.editBkg.submitId,
                proofUser: type === "proofAssignTo" ? parseInt(this.state.filters.proofAssignTo) : this.state.editBkg.proofId,
                closeoutUser: type === "closeoutAssignTo" ? parseInt(this.state.filters.closeoutAssignTo) : this.state.editBkg.closeoutId
            });
        }
        this.setState({ showContextMenu: false })
        axios.post("api/v1/instructions/changeassignee", { ...auth.getAuthData(), infoSet }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' });
                return;
            }
            this.getShipments();
        });

    }

    requestRelease = (type) => {
        if (document.querySelectorAll(".menu-selected").length) {

            let selectedBookings = [];
            document.querySelectorAll(".menu-selected").forEach(r => selectedBookings.push(this.state.shipments[r.rowIndex - 1]));

            if (selectedBookings.filter(b => b.isDummySI).length) {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Cannot Request Releases for Dummies", type: "error" } });
            }

            let uniqueCarriers = [...new Set(selectedBookings.map(b => b.carrierId))];
            let uniqueShippers = [...new Set(selectedBookings.map(b => b.shipperId))];

            if (uniqueCarriers.length > 1) {
                this.setState({ showContextMenu: false });
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Same Carrier", type: "error" } });
            }
            if (parseInt(uniqueCarriers[0], 10) === 3 || parseInt(uniqueCarriers[0], 10) === 9 || parseInt(uniqueCarriers[0], 10) === 15) {
                document.querySelectorAll(".menu-selected").forEach(r => r.classList.remove("menu-selected"));
                this.setState({ showContextMenu: false });
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Cannot Auto-Email For Selected Carrier", type: "error" } });
            }

            let userEmail = `${this.props.user.emailAddress}`;
            let firstName = `${this.props.user.first.charAt(0) + this.props.user.first.slice(1).toLowerCase()}`;
            let lastName = `${this.props.user.last.charAt(0) + this.props.user.last.slice(1).toLowerCase()}`;
            let finalName = `${firstName} ${lastName}`;

            axios.get(`/api/v1/email/release`, { params: { ...auth.getAuthData(), selectedBookings: selectedBookings.map(b => { return { id: b.id, bookingNumber: b.bookingNumber, shipperId: b.shipperId, carrierId: b.carrierId } }), uniqueShippers, userName: finalName, userEmail, type } }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    return this.props.dispatch({ type: "SCORCHED_EARTH" });
                } else if (result.data.messageSent) {

                    document.querySelectorAll(".menu-selected").forEach(r => r.classList.remove("menu-selected"));
                    this.getShipments();
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "E-mail Sent", type: "success" } });

                } else {
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "E-mail Could Not Be Sent", type: "error" } });

                }
            })
            return this.setState({ showContextMenu: false });

        } else {
            this.setState({ showContextMenu: false });
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Please Select Bookings", type: "error" } })
        }
    }

    markAsProofed = () => {
        let idSet = [];
        if (document.querySelectorAll(".menu-selected").length) {
            const list = document.querySelectorAll(".menu-selected")

            list.forEach(row => {
                idSet.push({
                    shipmentId: this.state.shipments[row.sectionRowIndex].id,
                    shipperId: this.state.shipments[row.sectionRowIndex].shipperId,
                    proof: !this.state.shipments[row.sectionRowIndex].proofed,
                    closeout: this.state.shipments[row.sectionRowIndex].closedOut
                })
                row.classList.remove("menu-selected");

            });
        } else {
            idSet.push({
                shipmentId: this.state.editBkg.id,
                shipperId: this.state.editBkg.shipperId,
                proof: !this.state.editBkg.proofed,
                closeout: this.state.editBkg.closedOut
            });
        }
        this.setState({ showContextMenu: false })
        axios.post("api/v1/documentation/changestage", { ...auth.getAuthData(), idSet }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' });
                return;
            }
            this.freshSearch();
        });
    }

    markAsClosedOut = () => {
        let idSet = [];
        if (document.querySelectorAll(".menu-selected").length) {
            const list = document.querySelectorAll(".menu-selected")

            list.forEach(row => {

                let daysPastLobDate = this.state.shipments[row.sectionRowIndex].fullLobDate === "-" ||
                    this.state.shipments[row.sectionRowIndex].closedOut === 1 ? null :
                    moment().diff(moment(this.state.shipments[row.sectionRowIndex].fullLobDate, "MM/DD/YY"), "days")

                idSet.push({
                    shipmentId: this.state.shipments[row.sectionRowIndex].id,
                    shipperId: this.state.shipments[row.sectionRowIndex].shipperId,
                    proof: this.state.shipments[row.sectionRowIndex].proofed,
                    closeout: !this.state.shipments[row.sectionRowIndex].closedOut,
                    daysPastLobDate
                })
                row.classList.remove("menu-selected");

            });
        } else {

            let daysPastLobDate = this.state.editBkg.fullLobDate === "-" ||
                this.state.editBkg.closedOut === 1 ? null :
                moment().diff(moment(this.state.editBkg.fullLobDate, "MM/DD/YY"), "days")

            idSet.push({
                shipmentId: this.state.editBkg.id,
                shipperId: this.state.editBkg.shipperId,
                proof: this.state.editBkg.proofed,
                closeout: !this.state.editBkg.closedOut,
                daysPastLobDate
            });
        }
        this.setState({ showContextMenu: false })
        axios.post("api/v1/documentation/changestage", { ...auth.getAuthData(), idSet }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' });
                return;
            }
            this.freshSearch();
        });

    }

    printDoc = () => {

    }


    clear = () => this.setState(this.initialState(), () => this.getEmployees(), this.freshSearch());

    getDateTypes = () => ([{ label: "CUT" }, { label: "DOC" }, { label: "ETA" }, { label: "ETD" }, { label: "LOB" }]);

    getEmployees = () => {
        let employees = [];
        let reduxUsers = this.props.portalUser.filter(u => u.isArchived !== 1);
        let i = 0;
        while (i < reduxUsers.length) {
            employees.push({ name: reduxUsers[i].first + " " + reduxUsers[i].last, id: reduxUsers[i].id });
            i++
        }
        employees.unshift({ name: "ANY", id: 0 })
        this.setState({ employees })
    }

    openCol = () => {
        if (this.state.searchOpen) {
            this.setState({ searchOpen: false })
        } else {
            this.setState({ searchOpen: true })
        }
    }

    freshSearch = () => {
        this.setPage().then(() => {
            this.getShipments();
        })
    };

    getShipments = async () => {
        let tz = moment.tz.guess();
        let filters = this.state.filters;
        filters.tz = tz;
        axios.get("/api/v1/documentation/filter",
            { params: { ...auth.getAuthData(), filters: this.state.filters } }).then((result) => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return;
                }

                const shipments = result.data.result;
                const filters = result.data.filters;

                this.getShipmentsCount();
                this.setState({
                    shipments,
                    filters: { ...this.state.filters, currentSort: filters.currentSort, reverse: filters.reverse }
                }, () => {
                    this.focusHighlightRow();
                })

                if (!shipments.length) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Results', type: 'warning' } })
                }

            })
    }

    fetchBookingsForReport = async (e) => {
        e.preventDefault();
        if (!e.target.files[0]) {
            return;
        }
        let file = e.target.files[0];
        let fileBuffer = new Buffer.from(await file.arrayBuffer());
        this.setState({ fileBuffer }, () => {
            let authAndData = { ...auth.getAuthData(), fileBuffer: this.state.fileBuffer };
            axios({
                url: `/api/v1/excel/aes`,
                method: 'post',
                data: authAndData,
            }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    return this.props.dispatch({ type: 'SCORCHED_EARTH' });
                } else if (result.data.errno) {
                    document.getElementById('myFile').value = null;
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Error", type: 'error' } });
                }
                this.setState({ aesReportData: result.data }, () => {
                    setTimeout(() => {
                        document.getElementById('csvLink').click();

                    }, 1000)
                    document.getElementById('myFile').value = null;
                });
            })
        });
    }





    pageCheck = () => {
        if (this.props.location?.state?.filters) {
            if (this.props.location.state.filters.page && this.props.location.state.filters.page > 0) {
                this.setState({ filters: { ...this.state.filters, page: this.props.location.state.filters.page } })
            }
        }
    }

    setPage = (page) => {
        return new Promise((resolve, reject) => {
            if (page === undefined) {
                page = 0
            }
            if (page < 0) {
                return
            }
            if (this.state.filters.count.length < 1) {
                page = 0
            } else if (this.state.filters.count.length > 0) {
                if (page === this.state.filters.count.length) {
                    return
                }
            }
            this.setState({ filters: { ...this.state.filters, page: page } })
            resolve(page);
            reject(new Error('Promise Rejected'))
        }).catch(e => {
            console.log(e)
        })
    }

    getShipmentsCount = async () => {
        let result = (await axios.get('/api/v1/documentation/count', { params: { ...auth.getAuthData(), filters: this.state.filters } })).data
        if (result.result[0] === undefined) { return };
        let enteredContainerResultsCount = result.result[0].enteredContainerResultsCount;
        let containerResultsCount = result.result[0].containerResultsCount;
        let showCount = result.showCount;
        let amount = result.result[0].count;
        let list = Math.ceil(amount / this.state.filters.pageSize);
        let count = [];
        for (list; list > 0; list--) {
            count.push(list)
        }
        this.setState({ filters: { ...this.state.filters, count: count.reverse(), resultsCount: amount, enteredContainerResultsCount, containerResultsCount, showCount: showCount } })
    }

    singlePageTurn = async (e) => {
        let direction = e.target.getAttribute('name')
        let page = await this.state.filters.page;
        direction === 'back' ? page-- : page++;
        this.setPage(page).then(() => {
            this.getShipments();
        })
    }

    pageJump = (e) => {
        let choice = e.target.getAttribute("name");
        this.setPage((parseInt(choice) - 1)).then(() => {
            this.getShipments();
        })
    }


    sort = e => {
        if (!e.target.getAttribute("data-sort")) {
            return;
        } else if (e.ctrlKey) {
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort2 : e.target.getAttribute('data-sort');
            const reverse2 = this.state.filters.currentSort2 === value ? (-1 * this.state.filters.reverse2) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort2: value, reverse2 } }, () => { this.getShipments(); })
        } else if (e.altKey) {
            const value = typeof e === "string" ? this.props.location?.state?.filters?.currentSort3 : e.target.getAttribute('data-sort');
            const reverse3 = this.state.filters.currentSort3 === value ? (-1 * this.state.filters.reverse3) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort3: value, reverse3 } }, () => { this.getShipments(); })
        } else {
            const value = typeof e === "string" ? this.props.location?.state.filters?.currentSort : e.target.getAttribute('data-sort');
            const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => { this.getShipments(); })
        }
    }


    onChange = (name, value) => { this.setState({ [name]: value }); };

    filterChange = (name, value) => {
        const { filters } = this.state;
        filters[name] = value;
        this.setState({ filters });
    }

    filterChangePlus = (name, value) => {
        const { filters } = this.state;
        filters[name] = value;
        this.setState({ filters }, this.freshSearch);
    }

    componentDidMount = () => {
        this.freshSearch(); this.initListeners(); this.getEmployees(); this.initModal(); this.pageCheck(); M.Collapsible.init(document.querySelector('.collapsible'));
        if (this.props.location?.state?.filters) {
            this.setState({ filters: this.props.location.state.filters })
            if (this.props.location.state.filters.vesselName || this.props.location.state.filters.voyageNumber || this.props.location.state.filters.portOfDelivery ||
                this.props.location.state.filters.shipperRef || this.props.location.state.filters.proofedAndCloseoutStatus !== "ALL" || this.props.location.state.filters.dateType !== "ETD" ||
                this.props.location.state.filters.startDate || this.props.location.state.filters.endDate || this.props.location.state.filters.containerNumber || this.props.location.state.filters.fileName) {
                M.Collapsible.init(document.querySelector('.collapsible')).open();
            }
        }
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    componentDidUpdate = () => {
    }

    render() {
        return (
            <div>
                <div id="bkg-ov-search-fields">
                    <ul className="collapsible overviewCollapsible" style={{ padding: '12px' }}>
                        <li>
                            <div className="collapsible-header" onClick={this.openCol} style={{ margin: '0px', padding: '0px', maxHeight: '12px', border: 'none' }}>
                                <i className="material-icons searchOpenIcon">{!this.state.searchOpen ? "expand_more" : "expand_less"}</i><p style={{ position: "relative", bottom: "4px" }}>Advanced Search</p>
                            </div>
                            <div className="collapsible-body overviewCollapsibleBody" style={{ margin: '0px', border: 'none', paddingBottom: "0px" }}>
                                <div className="row customMarginRemove">
                                    <Form.TextInput col="s2 search-field" name="containerNumber" label="Container #" onChange={this.filterChange} value={this.state.filters.containerNumber} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="s2 search-field" name="fileName" label="Filename" onChange={this.filterChange} value={this.state.filters.fileName} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="s2 search-field" name="vesselName" label="Vessel" onChange={this.filterChange} value={this.state.filters.vesselName} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="s2 search-field" name="voyageNumber" label="Voyage Number" onChange={this.filterChange} value={this.state.filters.voyageNumber} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="s2 search-field" name="portOfDelivery" label="PLD" onChange={this.filterChange} value={this.state.filters.portOfDelivery} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.NumberInput col="s2 search-field" name="lobDateOut" label="LoB Out" onChange={this.filterChange} value={this.state.filters.lobDateOut} flags={[flags.ALLOW_EMPTY]} />
                                </div>
                                <div className="row customMarginRemove">
                                    <Form.TextInput col="s2 search-field" name="shipperRef" label="Ref #" onChange={this.filterChange} value={this.state.filters.shipperRef} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.Select col="s2 search-field" name="proofedAndCloseoutStatus" label="Proof/Closeout" onChange={this.filterChangePlus} value={this.state.filters.proofedAndCloseoutStatus} list={[{ status: "ALL" }, { status: "PROOFED" }, { status: "NOT PROOFED" }, { status: "CLOSED OUT" }, { status: "NOT CLOSED OUT" }]} filter={{ label: "status", value: "status" }} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.Select col="s2 search-field" name="dateType" label="Date Type" onChange={this.filterChange} value={this.state.filters.dateType} list={this.getDateTypes()} filter={{ label: "label", value: "label" }} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.DateInput col="s2 search-field" name="startDate" label="Start Date" onChange={this.filterChange} value={this.state.filters.startDate} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.DateInput col="s2 search-field" name="endDate" label="End Date" onChange={this.filterChange} value={this.state.filters.endDate} flags={[flags.ALLOW_EMPTY]} />
                                    <input className="col s2" onChange={(e) => this.fetchBookingsForReport(e)} type="file" id="myFile" name='excel'></input>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="row customMarginRemove">
                        <Form.TextInput col="s2 search-field" name="shipperName" label="Shipper" onChange={this.filterChange} value={this.state.filters.shipperName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="s2 search-field" name="carrierName" label="Carrier" onChange={this.filterChange} value={this.state.filters.carrierName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="s2 search-field" name="consigneeName" label="Consignee" onChange={this.filterChange} value={this.state.filters.consigneeName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="s2 search-field" name="bookingNumber" label="Bkg #" onChange={this.filterChange} value={this.state.filters.bookingNumber} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select col="s2 search-field" name="employee" label="Assigned To" onChange={this.filterChangePlus} value={this.state.filters.employee} list={this.state.employees} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select col="s1 search-field" name="stage" label="Stage" onChange={this.filterChangePlus} value={this.state.filters.stage} list={[{ label: "ANY" }, { label: "B/L RECIPIENT" }, { label: "CLOSEOUT" }]} filter={{ label: "label", value: "label" }} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select col="s1 search-field" name="status" label="Status" onChange={this.filterChangePlus} value={this.state.filters.status} list={[{ label: "NOT ARCHIVED" }, { label: "ACTIVE" }, { label: "CLOSED" }, { label: "CANCELLED" }, { label: "ARCHIVED" }]} filter={{ label: "label", value: "label" }} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                </div>
                <table className="lf-portal-table">
                    <TableHeader
                        headers={[
                            { label: "", attribute: "" },
                            { label: "Proof", attribute: "proof" },
                            { label: "BKG #", attribute: "bookingNumber" },
                            { label: "Shipper", attribute: "shipperName" },
                            { label: "Consignee", attribute: "consigneeName" },
                            { label: "Carrier", attribute: "carrierName" },
                            { label: "Vessel", attribute: "vessel" },
                            { label: "Voyage Number", attribute: "voyageNumber" },
                            { label: 'Dest', attribute: 'portOfDelivery' },
                            { label: "HS Code", attribute: "hsCode" },
                            { label: "ETD", attribute: "etd" },
                            { label: "LoB Date", attribute: "ladenOnBoardDate" },
                            { label: "ETA", attribute: "eta" },
                            { label: "Closeout", attribute: "closeout" },
                            { label: "", attribute: "" }
                        ]}
                        sort={this.sort}
                        filters={this.state.filters}
                    />
                    <tbody className={`lf-portal-tbody`}>
                        <DocumentationResultsDisplay
                            shipments={this.state.shipments}
                            getShipments={this.getShipments}
                            filters={this.state.filters}
                            handleContextMenu={this.handleContextMenu}
                            handleStatusMenu={this.handleStatusMenu}
                            handleProofMenu={this.handleProofMenu}
                            handleCloseoutMenu={this.handleCloseoutMenu}
                            handleDelete={this.handleDelete}
                            editBkg={this.state.editBkg}
                            setState={this.onChange}
                            isMouseDown={this.state.isMouseDown}
                            handleEdit={this.handleEdit}
                            goToInstructions={this.goToInstructions}
                            rowHighlightIndex={this.state.filters.rowHighlightIndex}
                            setIndexOnFocus={this.setIndexOnFocus}
                        />
                    </tbody>
                </table>
                <PageCountDisplay
                    url={this.props.url.url}
                    pageJump={this.pageJump}
                    singlePageTurn={this.singlePageTurn}
                    count={this.state.filters.count}
                    page={this.state.filters.page}
                    pageReset={this.props.location?.state?.origin ? true : false}
                />
                {/* {this.state.aesReportData.length ? */}
                <CSVLink id='csvLink' filename={`${'aes_booking_report.csv'}`} headers={this.state.aesReportHeaders} tabIndex="-1" data={this.state.aesReportData} target="_blank">
                    {/* <Form.Button col="downloadCsvButton" color="black" type="outline" icon="file_download" inlineStyle={{ padding: "5px" }} iconClass="csvDownload" tabIndex="-1" /> */}
                </CSVLink>
                {/* : null} */}
                {this.state.filters.showCount ? <b className="resultsCount">Total Results: {this.state.filters.resultsCount}</b> : <b></b>}
                <div style={{ position: 'fixed', top: this.state.y, left: this.state.x }} className='lf-shipment-context-menu'>
                    <ul className='collection'>
                        {this.state.showContextMenu && <span className="collection-item black-text material-icons cancelOption" onClick={() => this.setState({ showContextMenu: false })}>
                            <span className="cancelWords"><i className="material-icons">cancel</i></span></span>}
                        {this.state.showContextMenu && <li className="collection-item purple-text contextList">
                            Assign Proof to:&nbsp;
                            <Form.Select col="s2 search-field contextMenuSelect" className="contextMenuSelect" name="proofAssignTo" onChange={this.filterChange} value={this.state.filters.proofAssignTo} list={this.state.employees.filter(e => e.name !== "ANY")} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                            <button className="assignButton" onClick={() => this.changeAssignee("proofAssignTo")}>Assign</button>
                        </li>}
                        {this.state.showContextMenu && <li className="collection-item red-text contextList">
                            Assign Closeout to:&nbsp;
                            <Form.Select col="s2 search-field contextMenuSelect" className="contextMenuSelect" name="closeoutAssignTo" onChange={this.filterChange} value={this.state.filters.closeoutAssignTo} list={this.state.employees.filter(e => e.name !== "ANY")} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                            <button className="assignButton" onClick={() => this.changeAssignee("closeoutAssignTo")}>Assign</button>
                        </li>}

                        {this.state.showContextMenu && <li className="collection-item contextList">
                            <strong>REQUEST SWB RELEASE:&nbsp;</strong><br />
                            <button className="assignButton" onClick={() => { this.requestRelease("SWB") }}>Send Email</button>
                        </li>}

                        {this.state.showContextMenu && <li className="collection-item contextList">
                            <strong>REQUEST OBL RELEASE:&nbsp;</strong><br />
                            <button className="assignButton" onClick={() => { this.requestRelease("OBL") }}>Send Email</button>
                        </li>}

                        {this.state.showStatusMenu && <li onClick={() => this.changeStatus('ACTIVE')} className="collection-item green-text">ACTIVE</li>}
                        {this.state.showStatusMenu && <li onClick={() => this.changeStatus('CLOSED')} className="collection-item grey-text">CLOSED</li>}
                        {this.state.showStatusMenu && <li onClick={() => this.changeStatus('CANCELLED')} className="collection-item red-text">CANCELLED</li>}
                        {this.state.showStatusMenu && <li onClick={this.handleDelete} className="collection-item black-text">ARCHIVE</li>}
                        {this.state.showProofMenu && <li onClick={this.markAsProofed} className="collection-item black-text" style={{ cursor: "pointer" }}>Toggle Proofed</li>}
                        {this.state.showCloseoutMenu && <li onClick={this.markAsClosedOut} className="collection-item black-text" style={{ cursor: "pointer" }}>Toggle Closeout</li>}

                    </ul>
                </div>
                <StickyNote
                    id="overviewStagesModal"
                    shipment={this.state.stickyBooking}
                    closeModal={this.closeModal}
                    updateParent={this.updateOverviewWithNoteChanges}
                    overview={true}
                />
            </div>
        )
    }

}



const mapStateToProps = (state) => {
    const { urls, index, user, portalUser } = state;
    const url = urls[index];
    return { url, user, portalUser }
}

export default connect(mapStateToProps)(withRouter(DocumentationOverview));