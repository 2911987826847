import React from "react";
import styles from "./card.module.css";
import Form from "../../Components/NewComponents"

const Card = props => (
  <div className={`card ${props.col ? props.col : ""} `}>

    {props.addresses.map((card, index) => (
      <div key={index}>
        <div className={`card-body ${props.cardStyle ? styles[props] : styles.cardStyle} `} >
          
          <Form.TextArea label={card.addressLabel} cursor="pointer" value={card.printable ? card.printable : card.printableAddress} onChange={props.onChange} disabled={false} readonly />

          <div style={{ "float": "right" }}>
            <i onClick={() => props.showEditModal(index, card.pa_id)}
              className={`material-icons ${styles.buttons} ${styles.edit}`} > border_color</i>
            <i onClick={() => props.deleteAddress(index, card.pa_id)}
              className={`material-icons s1 ${styles.buttons} ${styles.delete}`}> delete_forever </i>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default Card;
