import Axios from "axios";
import { useEffect, useLayoutEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { auth, logout } from "../../../helperFunctions";
import File from "./File";
import Folder from "./Folder";
import { globalToastActions } from "../../../Redux/actions";
import MsgReader from "@kenjiuno/msgreader";
import MimeTypes from "../../../mimeTypes";
import { ZipReader, BlobReader, BlobWriter } from '@zip.js/zip.js';
const componentId = '_' + crypto.randomUUID();

const DisplayFileTree = props => {
    const {
        booking,
        folders,
        getBooking,
        elements,
        toggleFolder,
        setNewFolder,
        setRename,
        directory
    } = props;

    const dispatch = useDispatch();

    const folderMenu = useRef();
    const fileMenu = useRef();

    const initValues = () => ({
        newFolder: 'New Folder',
        rename: '',
    })

    const ref = useRef({ isDraggingOver: null })
    const [folderContext, setFolderContext] = useState({ show: false, folder: null, clientX: 0, clientY: 0 })
    const [fileContext, setFileContext] = useState({ show: false, file: null, clientX: 0, clientY: 0 })
    const [dragObject, setDragObject] = useState(null);
    const [values, setValues] = useState(initValues());
    const [isDraggingOver, setIsDraggingOver] = useState({ showHighlight: false, isTimedOut: false });

    const clickAwayListener = ({ target }) => {
        if (!folderMenu.current.contains(target))
            setFolderContext(p => ({ ...p, show: false }))

        if (!fileMenu.current.contains(target))
            setFileContext(p => ({ ...p, show: false }))

        if (target.id !== 'newFolder' && !folderMenu.current.contains(target))
            setNewFolder(null);

        if (target.id !== 'rename' && !folderMenu.current.contains(target) && !fileMenu.current.contains(target))
            setRename(null);

    }

    useLayoutEffect(() => {
        document.addEventListener('click', clickAwayListener);

        return () => {
            document.removeEventListener('click', clickAwayListener);
        }
    }, [])

    const toggleAddFolder = e => {
        e?.preventDefault();
        setValues(p => ({ ...p, newFolder: 'New Folder' }))
        setFolderContext(p => ({ ...p, show: false }))
        setNewFolder({
            path: `${folderContext?.folder?.path || '/root'}/${componentId}`,
            isNewFolder: true
        })

        setTimeout(() => {
            document.querySelector('#newFolder')?.select();
        }, 100);
    }

    const toggleRenameObject = (e, type) => {
        e?.preventDefault();

        setValues(p => ({ ...p, rename: type === 'folder' ? folderContext.folder.name : fileContext.file.filename }))
        setRename(type === 'folder' ? folderContext.folder : fileContext.file)
        setFolderContext(p => ({ ...p, show: false }))
        setFileContext(p => ({ ...p, show: false }))

        setTimeout(() => {
            document.querySelector('#rename')?.select();
        }, 100);
    }

    const createNewFolder = () => {
        if (!values.newFolder) { return }

        Axios.post('/api/v2/folder/create/one/folder', {
            ...auth.getAuthData(),
            bookingId: booking.id,
            path: (folderContext?.folder?.path || '/root') + '/' + values.newFolder
        })
            .then(result => {
                dispatch(result.data);
                getBooking();
                setNewFolder(null);
            })
            .catch(logout)
    }

    const renameObject = obj => {
        if (obj.folderId)
            Axios.post('/api/v2/file/update/rename/one/by/id', {
                ...auth.getAuthData(),
                fileId: obj.id,
                filename: values.rename
            })
                .then(result => {
                    dispatch(result.data)
                    setRename(null);
                    getBooking();
                })
                .catch(logout)
        else {
            const path = obj.path.split('/')
            path.splice(-1, 1, values.rename);

            Axios.post('/api/v2/folder/update/rename/one/by/id', {
                ...auth.getAuthData(),
                folderId: obj.id,
                path: path.join('/')
            })
                .then(result => {
                    dispatch(result.data)
                    setRename(null);
                    getBooking();
                })
                .catch(logout)
        }
    }

    const onDrop = async (e, folderId) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDraggingOver(null);

        const { dataTransfer: { files } } = e;

        if (!files.length) {
            const { type } = dragObject;
            let target = e.target.id === componentId ? null : JSON.parse(e.target.closest('tr')?.getAttribute('data-object'));
            target = target ? target : folders.find(folder => folder.path.toLocaleLowerCase() === '/root');

            if (type.toLocaleLowerCase() === 'file')
                Axios.post('/api/v2/file/update/move/one/by/id', {
                    ...auth.getAuthData(),
                    fileId: dragObject.id,
                    folderId: target?.folderId ? target?.folderId : target?.id,
                    createRoot: target ? false : true
                })
                    .then(result => {
                        result.data.type ? dispatch(result.data) : null;
                        getBooking();
                    })
                    .catch(logout)
            else if (type === 'folder')
                Axios.post('/api/v2/folder/update/move/one/by/id', {
                    ...auth.getAuthData(),
                    folderId: dragObject.id,
                    destinationFolderId: target?.folderId ? target?.folderId : target?.id,
                    createRoot: target ? false : true
                })
                    .then(result => {
                        result.data.type ? dispatch(result.data) : null;
                        getBooking();
                    })
                    .catch(logout)
            else
                dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Que Interesante!', type: 'error' } })
        }
        else {
            folderId = folderId ? folderId : folders.find(f => f.path === '/root').id;
            const arr = [];
            [...files].forEach(file => {
                const { name } = file;
                const fileType = name.split('.')[1];
                const reader = new FileReader();

                if (fileType.toLocaleLowerCase() === 'msg')
                    reader.readAsArrayBuffer(file);
                else
                    reader.readAsDataURL(file);

                reader.onloadend = () => {
                    arr.push({
                        filename: name,
                        data: reader.result,
                        contentType: file.type,
                        size: file.size
                    })
                }
            })

            uploadFiles(arr, files, folderId);
        }
    }

    const onDrag = (e, obj) => setDragObject(obj);

    const uploadFiles = async (arr, files, folderId) => {
        setTimeout(() => {
            if (arr.length !== files.length)
                uploadFiles(arr, files, folderId);
            else {
                arr.forEach(file => {
                    if (typeof file.data === 'string')
                        Axios.post('/api/v2/file/upload/one', {
                            ...auth.getAuthData(),
                            filename: file.filename,
                            folderId,
                            data: file.data,
                            contentType: file.contentType,
                            directory,
                            fileSize: file.size,
                            createRoot: folderId ? false : true,
                        })
                            .then(result => {
                                dispatch(result.data);
                                getBooking();
                                document.querySelector(`#file-input-${componentId}`).value = '';
                            })
                            .catch(logout)
                    else
                        uploadOutlookMessage(file);
                })
            }
        }, 500);
    }

    const uploadOutlookMessage = msg => {

        const reader = new MsgReader(msg.data);
        const parsedMessage = reader.getFileData();
        const attachments = [];

        const msgReader = new FileReader();
        msgReader.readAsDataURL(new Blob([msg.data]));
        msgReader.onloadend = () => {
            attachments.push({
                filename: `${parsedMessage.normalizedSubject}.msg`,
                data: msgReader.result,
                contentType: 'application/vnd.ms-outlook'
            })
        }

        for (let i = 0; i < parsedMessage.attachments.length; i++) {
            const { fileName, content } = reader.getAttachment(i);
            const blob = new Blob([content]);
            const fileReader = new FileReader();
            fileReader.readAsDataURL(blob);
            fileReader.onloadend = () => {
                attachments.push({
                    filename: fileName,
                    data: fileReader.result,
                    contentType: MimeTypes.getContentType(fileName.split('.')[fileName.split('.').length - 1]),
                    fileSize: content.byteLength
                })
            }
        }

        const uploadFiles = (arr, length) => {
            setTimeout(() => {
                if (arr.length < length)
                    uploadFiles(arr, length);
                else
                    Axios.post('/api/v2/file/upload/msg', {
                        ...auth.getAuthData(),
                        directory,
                        bookingId: booking.id,
                        lNumber: booking.lNumber,
                        message: {
                            sender: parsedMessage.senderSmtpAddress || parsedMessage.senderEmail,
                            subject: parsedMessage.normalizedSubject,
                            timeStamp: parsedMessage.messageDeliveryTime,
                            attachments,
                            body: parsedMessage.body
                        }
                    })
                        .then(result => {
                            dispatch(result.data);
                            getBooking();
                        })
                        .catch(logout)
            }, 250)
        }
        uploadFiles(attachments, parsedMessage.attachments.length + 1);
    }

    const downloadFile = (e, isPreview, id) => {
        e?.preventDefault();
        setFileContext(p => ({ ...p, show: false }))
        const fileId = id ? id : fileContext?.file?.id;

        Axios.get('/api/v2/file/download/one/by/id', {
            params: {
                ...auth.getAuthData(),
                fileId
            }
        })
            .then(result => {
                const { buffer, contentType, filename, fileType } = result.data;
                const uint8Array = Uint8Array.from(buffer.data);
                const blob = new Blob([uint8Array], { type: contentType })
                const url = window.URL.createObjectURL(blob);

                if (isPreview && ['pdf', 'txt', 'jpg', 'html', 'htm', 'mp3', 'mp4', 'jpeg', 'jpg', 'png', 'svg', 'webp', 'bmp'].indexOf(fileType) !== -1)
                    window.open(url, '_blank');
                else {
                    const link = document.createElement('a');
                    link.hidden = true;
                    link.href = url;
                    link.setAttribute('download', `${filename}.${fileType}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch(logout)
    }

    const copyFolder = e => {
        e?.preventDefault();

        const lNumber = window.prompt('Input the L-Number for the booking you would like to copy this folder to.')

        Axios.post('/api/v2/folder/update/copy/one/by/id', {
            ...auth.getAuthData(),
            lNumber,
            folderId: folderContext.folder.id
        })
            .then(result => dispatch(result.data))
            .catch(logout)
    }

    const deleteFile = e => {
        e?.preventDefault();
        setFileContext(p => ({ ...p, show: false }))
        const { id, label } = fileContext.file;

        if (window.confirm(`Are you sure you want to delete ${label}?`))
            Axios.post('/api/v2/file/delete/one/by/id', {
                ...auth.getAuthData(),
                fileId: id
            })
                .then(result => {
                    dispatch(result.data);
                    getBooking();
                })
                .catch(logout)
    }

    const copyFile = e => {
        e?.preventDefault();
        const { id, label } = fileContext.file;

        const lNumber = window.prompt(`Input the L-Number of the booking you would like to copy ${label} to:`);

        if (lNumber)
            Axios.post('/api/v2/file/update/copy/one/by/id', {
                ...auth.getAuthData(),
                fileId: id,
                lNumber
            })
                .then(result => dispatch(result.data))
                .catch(logout)
    }

    const deleteFolder = e => {
        e?.preventDefault();
        const { folder } = folderContext;
        setFolderContext(p => ({ ...p, show: false }))
        let hasFiles = false;

        const checkForFilesRecursively = fdr => {
            if (fdr.files?.length) {
                hasFiles = true;
                return;
            }
            else if (fdr.folders)
                fdr.folders.forEach(f => checkForFilesRecursively(f))
            else
                return;
        }

        checkForFilesRecursively(folder);
        const doIt = folderId => {
            Axios.post('/api/v2/folder/delete/one', {
                ...auth.getAuthData(),
                folderId
            })
                .then(result => {
                    dispatch(result.data);
                    getBooking();
                })
                .catch(logout)
        }

        if (hasFiles) {
            if (window.confirm(`Are you sure you want to delete ${folder.label} and all its files?`) && window.confirm(`Really though?`))
                doIt(folder.id)
        }
        else
            doIt(folder.id);

    }

    useEffect(() => {
        ref.current.isDraggingOver = isDraggingOver;
        if (isDraggingOver && isDraggingOver.isTimedOut)
            setTimeout(() => ref.current.isDraggingOver.isTimedOut ? setIsDraggingOver({ element: null, isTimedOut: false }) : null, 100);

    }, [isDraggingOver])

    const browseFiles = e => {
        e.preventDefault();
        const el = document.querySelector(`#file-input-${componentId}`)
        el.click();
    }



    const fileInputChange = e => {
        const fileInput = document.querySelector(`#file-input-${componentId}`);
        const files = fileInput.files;
        let folderId = folderContext.folder ? folderContext.folder?.id : fileContext.file?.folderId;
        folderId = folderId ? folderId : folders.find(f => f.path === '/root').id;

        const arr = [];
        [...files].forEach(file => {
            const { name } = file;
            const fileType = name.split('.')[1];
            const reader = new FileReader();

            if (fileType.toLocaleLowerCase() === 'msg')
                reader.readAsArrayBuffer(file);
            else
                reader.readAsDataURL(file);

            reader.onloadend = () => {
                arr.push({
                    filename: name,
                    data: reader.result,
                    contentType: file.type,
                    size: file.size
                })
            }
        })

        uploadFiles(arr, files, folderId);
    }

    const zipFolder = (e, type) => {
        e.preventDefault();
        const { folder: { id: folderId } } = folderContext;
        setFolderContext({ show: false })

        Axios.get('/api/v2/folder/download/zip', {
            params: {
                ...auth.getAuthData(),
                folderId
            }
        })
            .then(async result => {
                const { filename, buffer } = result.data;
                const uint8Array = Uint8Array.from(buffer.data)
                const blob = new Blob([uint8Array], { type: 'application/zip' })

                if (type === 'zip') {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.hidden = true;
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                }
                else if (type === 'files') {
                    const reader = new ZipReader(new BlobReader(blob));
                    const entries = await reader.getEntries({});

                    entries.forEach(async entry => {
                        if (entry.uncompressedSize) {
                            const blob = await entry.getData(new BlobWriter());
                            const url = window.URL.createObjectURL(blob, { type: MimeTypes.getContentType(entry.filename.split('.').pop()) });
                            const link = document.createElement('a');
                            link.hidden = true;
                            link.href = url;
                            link.setAttribute('download', entry.filename.split('/').pop());
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    })
                }
            })
            .catch(logout)
    }

    return (
        <div
            id={componentId}
            style={{ height: 'CALC(100% - 22px)', padding: '12px', borderRadius: '6px', border: isDraggingOver?.element?.id === componentId ? '3px dashed #2196f3' : '3px solid #ffffffff' }}
            onContextMenu={e => { e.preventDefault(); e.target.id === componentId ? setFolderContext({ show: true, folder: null, clientX: e.clientX, clientY: e.clientY }) : null }}
            onDragOver={e => { e.preventDefault(); setIsDraggingOver({ element: e.target, isTimedOut: false }) }}
            onDragLeave={() => setIsDraggingOver(p => ({ ...p, isTimedOut: true }))}
            onDrop={onDrop}
        >
            <div className="row">
                <input style={{ display: 'none' }} id={`file-input-${componentId}`} type="file" multiple onChange={fileInputChange} />
            </div>
            <table>
                <tbody>
                    {elements?.map(obj => {
                        switch (obj.type) {
                            case 'folder':
                                return <Folder
                                    key={obj.key}
                                    folder={obj}
                                    toggleFolder={toggleFolder}
                                    setContext={setFolderContext}
                                    setFileContext={setFileContext}
                                    uploadFiles={uploadFiles}
                                    onDragOver={e => e.preventDefault()}
                                    onDrop={e => onDrop(e, obj.id)}
                                    onDrag={e => onDrag(e, obj)}
                                    highlightedElement={isDraggingOver?.element}
                                />
                            case 'file':
                                return <File
                                    key={obj.key}
                                    file={obj}
                                    setContext={setFileContext}
                                    setFolderContext={setFolderContext}
                                    uploadFiles={uploadFiles}
                                    onDragOver={e => e.preventDefault()}
                                    onDrop={e => onDrop(e, obj.folderId)}
                                    onDrag={e => onDrag(e, obj)}
                                    downloadFile={downloadFile}
                                    highlightedElement={isDraggingOver?.element}
                                />
                            case 'new folder':
                                return <tr key="newfolder">
                                    <td style={{ paddingLeft: `${(obj.depth - 1) * 30}px` }}>
                                        <div className="lf-input col s12 m6 l3" style={{ position: 'relative', top: '4px' }}>
                                            <input
                                                className="browser-default"
                                                id="newFolder"
                                                type="text"
                                                onChange={({ target: { value } }) => setValues(p => ({ ...p, newFolder: value }))}
                                                onKeyDown={({ code }) => /enter|tab/.test(code.toLocaleLowerCase()) ? createNewFolder() : null}
                                                value={values.newFolder}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            case 'rename':
                                return <tr key="rename">
                                    <td style={{ paddingLeft: `${(obj.depth - 1) * 30}px` }}>
                                        <div className="lf-input col s12 m6 l3" style={{ position: 'relative', top: '4px' }}>
                                            <input
                                                className="browser-default"
                                                id="rename"
                                                type="text"
                                                onChange={({ target: { value } }) => setValues(p => ({ ...p, rename: value }))}
                                                onKeyDown={({ code }) => /enter|tab/.test(code.toLocaleLowerCase()) ? renameObject(obj) : null}
                                                value={values.rename}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            default:
                                return null;
                        }
                    })}
                </tbody>
            </table>
            <div
                ref={folderMenu}
                id={`folder-menu-${componentId}`}
                className={folderContext.show ? '' : 'hide'}
                style={{
                    zIndex: 100,
                    position: 'fixed',
                    top: folderContext.clientY,
                    left: folderContext.clientX,
                    border: '1px solid black',
                    backgroundColor: 'white',
                    boxShadow: '1px 1px 2px #ddd',
                    borderRadius: '1px',
                    padding: '8px'
                }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px', borderBottom: 'none' }}>
                    {folderContext.folder ?
                        <>
                            <li>
                                <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={e => toggleRenameObject(e, 'folder')}>Rename Folder</a>
                            </li>
                            <div className="divider" style={{ margin: '2px 0px' }} />
                        </>
                        : null}
                    <>
                        <li>
                            <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={browseFiles}>Upload File(s)</a>
                        </li>
                        <div className="divider" style={{ margin: '2px 0px' }} />
                        <li>
                            <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={toggleAddFolder}>Add Folder</a>
                        </li>
                    </>
                    {folderContext.folder ?
                        <>
                            <div className="divider" style={{ margin: '2px 0px' }} />
                            <li>
                                <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={e => zipFolder(e, 'files')}>Download Folder</a>
                            </li>
                            <div className="divider" style={{ margin: '2px 0px' }} />
                            <li>
                                <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={e => zipFolder(e, 'zip')}>Download Folder as Zip</a>
                            </li>
                            <div className="divider" style={{ margin: '2px 0px' }} />
                            <li>
                                <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={copyFolder}>Copy Folder</a>
                            </li>
                            <div className="divider" style={{ margin: '2px 0px' }} />
                            <li>
                                <a href="/" className="red-text" style={{ font: 'console', fontSize: '.8em' }} onClick={deleteFolder}>Delete Folder</a>
                            </li>
                        </>
                        : null}
                </ul>
            </div>
            <div
                ref={fileMenu}
                id={`file-menu-${componentId}`}
                className={fileContext.show ? '' : 'hide'}
                style={{
                    zIndex: 100,
                    position: 'fixed',
                    top: fileContext.clientY,
                    left: fileContext.clientX,
                    border: '1px solid black',
                    backgroundColor: 'white',
                    boxShadow: '1px 1px 2px #ddd',
                    borderRadius: '1px',
                    padding: '8px'
                }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px', borderBottom: 'none' }}>
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={e => toggleRenameObject(e, 'file')}>Rename File</a>
                    </li>
                    <div className="divider" style={{ margin: '2px 0px' }} />
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={e => downloadFile(e, false)}>Download File</a>
                    </li>
                    <div className="divider" style={{ margin: '2px 0px' }} />
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={browseFiles}>Upload File(s)</a>
                    </li>
                    <div className="divider" style={{ margin: '2px 0px' }} />
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={e => downloadFile(e, true)}>Preview File</a>
                    </li>
                    <div className="divider" style={{ margin: '2px 0px' }} />
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={copyFile}>Copy File</a>
                    </li>
                    <div className="divider" style={{ margin: '2px 0px' }} />
                    <li>
                        <a href="/" className="red-text" style={{ font: 'console', fontSize: '.8em' }} onClick={deleteFile}>Delete File</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default DisplayFileTree;