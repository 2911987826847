import moment from "moment";

const TableRow = props => {
    const {
        booking,
        selectedLNumber,
        navigateToUpdateShipmentPage,
        componentId
    } = props;

    const getStatusBadge = status => {
        switch (status) {
            case 'ACTIVE':
                return <span style={{ border: '1px solid #4CAF50 ' }} className="green-text badge">ACTV</span>
            case 'CANCELLED':
                return <span style={{ border: '1px solid #F44336 ' }} className="red-text badge">CNCL</span>
            case 'CLOSED':
                return <span style={{ border: '1px solid #757575' }} className="grey-text text-darken-1 badge">CLSD</span>
            case 'ARCHIVED':
                return <span style={{ border: '1px solid #000000' }} className="black-text badge">ACVD</span>
            default:
                return;
        }
    }

    const getStageIcon = booking => {
        if (booking.invoice?.id)
            return <span className={`material-icons ${booking.booking.isDummy ? 'purple-text' : 'green-text'}`}>attach_money</span>
        else if (booking.shippingInstructions?.datetimePrinted)
            if (booking.shippingInstructions.isDummy || !booking.containers?.filter(c => c.containerNumber).length)
                return <span className="material-icons">assignment_ind</span>
            else
                return <span className="material-icons">assignment_turned_in</span>
        else if (moment().isAfter(moment(booking.vesselVoyage.docCutDate).local()))
            return <span className={`material-icons ${booking.booking.isDummy ? 'purple-text' : 'red-text'}`}>assignment_late</span>
        else if (moment().isSame(moment(booking.vesselVoyage.docCutDate).local(), "d"))
            return <span className="material-icons">history</span>
        else
            return null
    }

    const getFlaggedForReviewStatus = booking => {
        if (booking.flaggedForReview && !booking.isBookingDummy)
            return <i className={`material-icons ${!booking.bookingDatetimePrinted && !booking.bookingDatetimeSent ? 'red-text' : ''}`}>date_range</i>
        else
            return null;
    }

    const navigate = ({ target }) => {
        if (target.classList.contains('material-icons') && target.innerHTML.toLowerCase() === 'description')
            navigateToUpdateShipmentPage(booking.booking.lNumber, 'shippinginstructions')
        else
            navigateToUpdateShipmentPage(booking.booking.lNumber, 'booking');

    }

    return (
        <tr
            id={booking.booking.lNumber + '-' + componentId}
            style={{
                backgroundColor: selectedLNumber === booking.booking.lNumber ? '#e3f2fd' : '',

            }}
            className={booking.booking.isDummy ? 'purple-text' : ''}
            onClick={navigate}
        >
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{getFlaggedForReviewStatus(booking.booking)}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{getStageIcon(booking)}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{getStatusBadge(booking.booking.status)}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.booking.displayBookingNumber}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.booking.lNumber}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.booking.shipperReferenceNumber}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.shipper.code}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.carrier.code}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.booking.containerCount || 0}/{booking.containers?.length || 0}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.origin.code}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.portOfLoad.code}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.destination.code}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.vessel.name}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.vesselVoyage.voyageNumber}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.service?.serviceRoute}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left', borderLeft: '1px solid black' }}>{booking.vesselVoyage.erd ? moment(booking.vesselVoyage.erd).format('MM/DD') : ''}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left', borderRight: '1px solid black' }}>{booking.vesselVoyage.cargoCutDate ? moment(booking.vesselVoyage.cargoCutDate).format('MM/DD') : ''}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.vesselVoyage.docCutDate ? moment(booking.vesselVoyage.docCutDate).format('MM/DD') : ''}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.vesselVoyage.etd ? moment(booking.vesselVoyage.etd).format('MM/DD') : ''}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.vesselVoyage.eta ? moment(booking.vesselVoyage.eta).format('MM/DD') : ''}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.pickup.code}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}>{booking.return.code}</td>
            <td style={{ padding: '0px 4px', borderRadius: '0px', textAlign: 'left' }}><i className="material-icons">description</i></td>
        </tr>
    )
}

export default TableRow;