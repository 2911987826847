import React from "react";
import { Route, Routes } from 'react-router-dom';
import { withRouter } from "./helperFunctions";
import { init } from "./Redux/actions";
import { connect } from 'react-redux';
import NavBar from './Components/NavBar/NavBar';
import SideBar from './Components/SideBar/SideBar';
import SideBarToggle from './Components/SideBar/SidebarToggle';
import Content from './Components/Content/Content';
import CustomerContent from './Components/Content/CustomerContent';
import Login from './Pages/Login/Login'
import GlobalToast from './Components/GlobalToast/GlobalToast';
import PasswordRecovery from './Pages/Login/PasswordRecovery/PasswordRecovery';
import './App.css';
import axios from "axios";
import { auth } from "./helperFunctions";
import EmployeeSidenav from './Components/NewSidenav/EmployeeSidenav';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
  }

  initState = () => ({
    isReady: false,
    checkingInit: false,
    hideSideBar: false
  })

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props && !this.state.isReady) {
      if (this.props.user !== undefined) {
        if (this.props.user.isEmployee || this.props.user.is_employee) {
          if (
            !this.props.logout &&
            this.props.party &&
            this.props.locations &&
            this.props.carrier &&
            this.props.containerType &&
            this.props.serviceRoute &&
            this.props.country &&
            this.props.user &&
            this.props.navs[this.props.index] &&
            this.props.urls[this.props.index] &&
            this.props.sidebars[this.props.index]
          ) {
            this.setState({ isReady: true });
          }
        } else if (
          !this.props.logout &&
          this.props.navs[this.props.index] &&
          this.props.urls[this.props.index] &&
          this.props.sidebars[this.props.index]) {
          this.setState({ isReady: true });
        }
      }
    }
    else if (this.props.url) {
      if (this.props.url.url === "/" && prevProps.url.url !== "/") {
        this.props.history.push("/login")
      }
    }

  }

  checkForInitialization = () => {
    return new Promise((resolve, reject) => {
      this.setState({ checkingInit: true })
      axios.post("/api/v1/login/token", auth.getAuthData())
        .then(async result => {
          if (result.data.id) {
            this.setState({ checkingInit: false })
            resolve(await this.getInitData(result.data));
          } else {
            this.setState({ checkingInit: false })
            resolve(
              <Routes>
                <Route path="/passwordrecovery" element={<PasswordRecovery />} />
                <Route path="*" element={<Login />} />
              </Routes>
            )
          }
        });
    });
  }

  getInitData = (user) => {
    return new Promise((resolve, reject) => {
      if (user.is_employee || user.isEmployee) {
        setTimeout(() => {
          axios.get('/api/v1/redux', { params: auth.getAuthData() }).then(result => {
            if (result.data && result.data !== "NOT AUTHENTICATED") {
              result.data.user = user;
              resolve(Object.keys(result.data).forEach(key => this.props.dispatch({ type: init.INITIALIZE_STORE, payload: { listName: [key], list: result.data[key] } })));
            }
          })
        }, auth.getAuthData()._user ? 100 : 600)
      } else {
        setTimeout(() => {
          axios.get('/api/v1/redux/customer', { params: auth.getAuthData() }).then(result => {
            if (result.data && result.data !== "NOT AUTHENTICATED") {
              result.data.user = user;
              resolve(Object.keys(result.data).forEach(key => this.props.dispatch({ type: init.INITIALIZE_STORE, payload: { listName: [key], list: result.data[key] } })));
            }
          })
        }, auth.getAuthData()._user ? 100 : 600)
      }
    })
  }

  shouldComponentUpdate = () => {
    if (this.state.checkingInit) {
      return false;
    } else {
      return true
    }
  }


  loginConfirmSwitch = (user) => {
    if (!user)
      return (
        <Routes>
          <Route path="/passwordrecovery" element={<PasswordRecovery />} />
          <Route path="*" element={<Login />}
          />
        </Routes>
      )

    else if (user.id)
      return this.employeeSwitch(this.props.user.isEmployee);
    else
      return this.checkForInitialization();
  }

  employeeSwitch = (isEmployee) => {
    switch (isEmployee) {
      case 1:
        return (
          <>
            <div id="app">
              <NavBar />
              <div className="body">
                <EmployeeSidenav hideSideBar={this.state.hideSideBar} />
                <SideBarToggle
                  hideSideBar={this.state.hideSideBar}
                  toggleSideBar={this.toggleSideBar}
                />
                <Content user={this.props.user} />
              </div>
              <GlobalToast />
            </div>
          </>
        )

      default:
        return (
          <>
            <div id="app">
              <NavBar />
              <div className="body">
                <SideBar hideSideBar={this.state.hideSideBar} />
                <SideBarToggle
                  hideSideBar={this.state.hideSideBar}
                  toggleSideBar={this.toggleSideBar}
                />
                <CustomerContent />
              </div>
              <GlobalToast />
            </div>
          </>
        )
    }
  }

  toggleSideBar = () => this.setState(p => ({ hideSideBar: !p.hideSideBar }))

  render = () => this.loginConfirmSwitch(this.props.user);
}

const mapStateToProps = state => {
  const { party, location, carrier, containerType, serviceRoute, country, user, navs, index, urls, sidebars, logout } = state;
  return { url: urls[index], party, locations: location, carrier, containerType, serviceRoute, country, user, navs, index, urls, sidebars, logout };
}

export default connect(mapStateToProps)(withRouter(App));