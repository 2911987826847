import { Routes, Route } from 'react-router-dom';
import UserOverview from './UserOverview';
import CreateUser from './CreateUser';

const Users = props => (
    <Routes>
        <Route path="/" element={<UserOverview />} />
        <Route path='/create' element={<CreateUser />} />
    </Routes>
)

export default Users;