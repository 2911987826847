import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { globalToastActions } from "../../../Redux/actions";
import moment from "moment-timezone";
import styles from "../shipments.module.css";
import M from "materialize-css";
import { withRouter } from '../../../helperFunctions';

class ResultsDisplay extends Component {
    state = {
        showContextMenu: false,
        deleteBkg: null,
        loading: true
    }

    initListeners = () => {
        // document.addEventListener("keyup", this.bookingNumberSelect);
    }

    initModal = () => {
        M.Modal.init(document.querySelectorAll(".modal"));
    }

    removeListeners = () => {
        // document.removeEventListener("keyup", this.bookingNumberSelect);
    }

    handleEdit = (e, bkg) => {
        if (!e.ctrlKey && !e.shiftKey) {

            return this.props.navigate(`/shipment/create/${bkg.bookingNumber.split(' / ')[0]}`, {
                state: {
                    directory: 'SHIPMENTS/',
                    bookingId: bkg.id,
                    searchTerm: bkg.bookingNumber.split(' / ')[0],
                    carrierId: bkg.carrierId,
                    shipperId: bkg.shipperId,
                    filters: this.props.filters,
                    filterList: this.props.filterList,
                    status: bkg.status,
                    stage: {
                        unformattedDocCutDate: bkg.unformattedDocCutDate,
                        aes: bkg.aes,
                        sentOrPrinted: bkg.sentOrPrinted,
                        invoiceStatus: bkg.invoiceStatus,
                        isDummy: bkg.isDummy
                    }
                }
            })
        } else {
            e.preventDefault();
            return;
        }
    }

    handleMouseOver = (e) => {
        if (this.props.isMouseDown && e.target.parentElement.tagName === "TR") {
            e.target.parentElement.classList.add('menu-selected');
        }
    }

    handleMouseDown = (e) => {
        if (e.ctrlKey && e.buttons === 1 && e.target.parentElement.tagName === "TR") {
            let list = []
            e.target.parentElement.classList.forEach(c => list.push(c))
            if (list.includes("menu-selected")) {
                e.target.parentElement.classList.remove("menu-selected");
            } else {
                e.target.parentElement.classList.add("menu-selected");
            }
        } else if (e.ctrlKey && e.buttons === 1 && e.target.parentElement.tagName === "TD") {
            let list = []
            e.target.parentElement.parentElement.classList.forEach(c => list.push(c))
            if (list.includes("menu-selected")) {
                e.target.parentElement.parentElement.classList.remove("menu-selected");
            } else {
                e.target.parentElement.parentElement.classList.add("menu-selected");
            }
        } else {
            return;
        }
    }

    bookingNumberSelect = (e) => {
        if (e.key === "Shift" && document.getSelection().type === "Range") {
            let highlightedItems = [];
            let nodes = document.querySelectorAll(".bookingNum");
            nodes.forEach(n => {
                let word = n.textContent;
                let regex = new RegExp(word, "gm");
                if (regex.test(document.getSelection().toString())) {
                    highlightedItems.push(n.textContent);
                }
            })
            navigator.clipboard.writeText(highlightedItems.join('\n'));
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Copied To Clipboard" } });
        }

    }

    goToInstructions = (e, bkg) => {
        if (e.ctrlKey) {
            return;
        } else {
            return this.props.navigate('/shipment/instructions', {
                state: {
                    directory: 'SHIPMENTS/',
                    bookingId: bkg.id,
                    searchTerm: bkg.lNumber,
                    shipperId: bkg.shipperId,
                    filters: this.props.filters,
                    filterList: this.props.filterList,
                    status: bkg.status,
                    stage: {
                        unformattedDocCutDate: bkg.unformattedDocCutDate,
                        aes: bkg.aes,
                        sentOrPrinted: bkg.sentOrPrinted,
                        invoiceStatus: bkg.invoiceStatus
                    }
                }
            })
        }
    }
    generateBadge = status => {
        switch (status) {
            case 'ACTIVE':
                return <span id="activeBadge" className="badge">ACTV</span>
            case 'CANCELLED':
                return <span id="cancelBadge" className="new red badge">CNCL</span>
            case 'CLOSED':
                return <span id="closedBadge" className="new badge">CLSD</span>
            case 'ARCHIVED':
                return <span id="archiveBadge" className="new black badge">ACVD</span>
            default:
                return;
        }
    }

    generateStage = (docCutDate, aes, SIPrinted, LOB, invoice, dummy, dummySI, enteredContainers) => {

        let date = moment().format("YYYY-MM-DD HH:mm:ss");

        if (invoice !== null) {
            return <span className="material-icons" style={dummy ? { color: "#9c27b0" } : { "color": "green" }}>attach_money</span>
        } else if (SIPrinted !== null && SIPrinted !== undefined && SIPrinted) {
            if (dummySI || !enteredContainers) {
                return <span className="material-icons">assignment_ind</span>
            }
            return <span className="material-icons">assignment_turned_in</span>
        } else if (moment(date).isAfter(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"))) {
            return <span className="material-icons" style={dummy ? { color: "#9c27b0" } : { "color": "red" }}>assignment_late</span>
        } else if (moment(date).isSame(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"), "d")) {
            return <span className="material-icons">history</span>
        } else {
            return <span className="material-icons"></span>
        }
    }

    refresh = () => { this.setState({ loading: true }); this.props.getBookings() }
    componentDidMount = () => { this.refresh(); this.initListeners(); this.initModal(); }
    componentWillUnmount = () => { this.removeListeners(); }

    render = () => {
        return (
            <Fragment>
                {this.props.loading ?
                    <tr>
                        <td>
                            <div className="preloader-wrapper big active overviewLoader">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    :
                    this.props.bookings.map((bkg, index) => (
                        <tr
                            onFocus={(e) => { this.props.setIndexOnFocus(e, index) }}
                            className={`${bkg.isDummy ? 'dummyPurple' : ''} resultsDisplay booking-id-${bkg.id}`}
                            id={`bkg-tr-${index}`}
                            key={index}
                            tabIndex="0"
                            onMouseOver={this.handleMouseOver}
                            onMouseDown={(e) => this.handleMouseDown(e)}
                        >
                            <td className="farLeft unselectable" onContextMenu={e => this.props.handleContextMenu(e, index, bkg)} onClick={(e) => this.handleEdit(e, bkg)}>
                                {(bkg.flaggedForReview && !bkg.isDummy) ? <i className={`material-icons ${!bkg.bkgAckSentOrPrinted ? 'red-text' : ""}`}>date_range</i> : null}
                            </td>
                            {this.props.showBookingsAssignedTo ?
                                <Fragment>
                                    <td className="material-icons stageInitials unselectable tooltip" onClick={(e) => this.handleEdit(e, bkg)}>
                                        <span className="tooltiptext">{bkg.bookingsUserFullName}</span>
                                        <span id="bookingsInitialsBadge" className="">
                                            {bkg.bookingsAssignedTo}
                                        </span>
                                    </td>
                                </Fragment> :
                                null
                            }
                            <td className="farLeft unselectable" >{this.generateStage(bkg.unformattedDocCutDate, bkg.aes, bkg.sentOrPrinted, bkg.lobDate, bkg.invoiceStatus, bkg.isDummy, bkg.isDummySI, bkg.enteredContainerCount)}</td>
                            <td className="farLeft unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleStatusMenu(e, index, bkg)}>{this.generateBadge(bkg.status)}</td>
                            <td className="bookingNum" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.bookingNumber}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.lNumber}</td>
                            <td className="refNum unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.shipperReferenceNumber}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.shipperName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.carrierName}</td>
                            <td className="unselectable containerCountTooltip" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)} style={{ fontSize: '1.1em', fontWeight: '500' }}>
                                <span className="containerCountTooltipText">{bkg.actualContainerCount}</span>
                                {bkg.containerCount}/{bkg.enteredContainerCount ? bkg.enteredContainerCount : 0}
                            </td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.origin}</td>
                            <td className="unselectable portOfLoadColumn" onClick={(e) => this.handleEdit(e, bkg)}>{bkg.portOfLoad}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.dest}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.vesselName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.voyageNumber}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.serviceRoute}</td>
                            <td className="unselectable dateBordersLeft" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.erd}</td>
                            <td className="unselectable dateBordersRight" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.cargoCutDate}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.docCutDate}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.etd}</td>
                            {this.props.showBookingsAssignedTo ?
                                null
                                :
                                <Fragment>
                                    <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.eta}</td>
                                </Fragment>
                            }
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.pickupLoc}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, bkg)} onContextMenu={e => this.props.handleMultipleBookingAckMenu(e, index, bkg)}>{bkg.returnLoc}</td>
                            <td className="unselectable"><i onClick={(e) => this.goToInstructions(e, bkg)} className={`material-icons ${styles.shipmentIcon}`}>description</i></td>
                            <td>
                                <div style={{ position: 'fixed', top: this.props.y, left: this.props.x }} className="lf-shipment-context-menu">
                                    <ul className="collection">
                                        {this.props.showEmailMenu && <li onClick={this.props.showEmailModal} className="collection-item black-text" >EMAIL</li>}
                                        {this.props.showEmailMenu && <li onClick={this.props.copyBookingNumbers} className="collection-item black-text" >COPY BKG NUM(S)</li>}
                                        {this.props.showEmailMenu && document.querySelectorAll(".menu-selected").length <= 1 && <li onClick={this.props.toggleFlag} className="collection-item black-text" >TOGGLE FLAG </li>}
                                        {this.props.showEmailMenu && document.querySelectorAll(".menu-selected").length > 1 && <li onClick={() => this.props.addOrRemoveFlag(false)} className="collection-item black-text" >TURN OFF FLAG</li>}
                                        {this.props.showEmailMenu && document.querySelectorAll(".menu-selected").length > 1 && <li onClick={() => this.props.addOrRemoveFlag(true)} className="collection-item black-text" >TURN ON FLAG</li>}
                                        {this.props.showEmailMenu && <li onClick={this.props.closeEmailMenu} className="collection-item black-text" >EXIT</li>}
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    ))}
            </Fragment>
        )
    }
}


const mapStateToProps = state => {
    const { location, user, urls, index } = state;
    const url = urls[index];
    return { location, user, url }
}

export default connect(mapStateToProps)(withRouter(ResultsDisplay))