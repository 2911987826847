import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from "../../Components/NewComponents";
import { globalToastActions } from "../../Redux/actions";
import moment from "moment-timezone";
import axios from "axios";
import { auth } from "../../helperFunctions";

class ContainerByWeekReportOld extends Component {
    state = {
        shipperId: "0",
        carrierId: "0",
        startDate: "",
        endDate: "",
        dummy: "ALL BOOKINGS",
        status: "ACTIVE",
        containerList: []
    }

    onChange = (name, value) => this.setState({ [name]: value });

    generateReport = async () => {
        if(parseInt(this.state.shipperId) === 0){
            this.props.dispatch( {type: globalToastActions.UPDATE_MSG, payload: {
                msg: "Please Select a Shipper", type: "error"
            } })
            return;
        }
        if(!this.state.startDate || !this.state.endDate){
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: {
                msg: "Please Select a Date Range", type: "error"
            }})
            return;
        }
        if(this.state.startDate > this.state.endDate){
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: {
                msg: "Starting Date Must Be Before Ending Date", type: "error"
            }})
            return;
        }
        const tz = await Intl.DateTimeFormat().resolvedOptions().timeZone;
        let reportData = {
            shipperId: parseInt(this.state.shipperId),
            carrierId: parseInt(this.state.carrierId),
            startDate: moment(this.state.startDate, "MM/DD/YY").format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(this.state.endDate, "MM/DD/YY").add(1, "day").format("YYYY-MM-DD HH:mm:ss"),
            dummy: this.state.dummy,
            status: this.state.status,
            userTimeZone: tz
        }
        axios.get("/api/v1/report/weeklycontainerreportold", { params: { ...auth.getAuthData(), reportData }}).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({
                    type: "SCORCHED_EARTH"
                })
                return;
            }
            this.setState({ containerList: result.data })
        })
    }

    componentDidUpdate = () => { }

    render = () => {
        return (
            <div>
                <h4>Booked Containers By Week</h4>
                <div>&nbsp;</div>
                <div className="row">
                    <Form.Select col="col s2" label="Shipper" onChange={this.onChange} placeholder="Select a Shipper" name="shipperId" value={this.state.shipperId} list={this.props.shipper} filter={{ label: "name", value: "id" }} />
                    <Form.Select col="col s2" label="Carrier" onChange={this.onChange} placeholder="Select a Carrier" name="carrierId" value={this.state.carrierId} list={this.props.carrier} filter={{ label: "name", value: "id" }} />
                    <Form.DateInput col="col s2" label="Start Date" onChange={this.onChange} name="startDate" value={this.state.startDate} />
                    <Form.DateInput col="col s2" label="End Date" onChange={this.onChange} name="endDate" value={this.state.endDate} />
                    <Form.Select col ="col s2" label="Status" onChange={this.onChange} name="status" value={this.state.status} list={[{ label: 'NOT ARCHIVED' }, { label: 'ACTIVE' }, { label: 'CLOSED' }, { label: 'CANCELLED' }, { label: 'ARCHIVED' }]} filter={{ label: "label", value: "label" }} />
                    <Form.Select col="col s2" label="Dummy" onChange={this.onChange} name="dummy" value={this.state.dummy} list={[{label: "ALL BOOKINGS"}, {label: "NO DUMMIES"}, {label: "DUMMIES ONLY"}]} filter={{ label: "label", value: "label"}} />
                </div>
                <div className="row">
                    <Form.Button col="col s2" onClick={this.generateReport} label="Generate Report" type="outline" color="blue" />
                </div>
                <div className="row">
                    <div className="col 4">
                        <table>
                            <thead>
                                <tr>
                                    <th>Week</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Containers</th>                                
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.containerList.length ? this.state.containerList.map(c => (
                                    <tr>
                                        <td>{c.start}</td>
                                        <td> -  </td>
                                        <td></td>
                                        <td>{c.end}</td>
                                        <td>{c.containerTally}</td>
                                    </tr>
                                )) :
                                null
                                }
                            </tbody>
                        </table>                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { urls, index, party, shipper, carrier } = state;
    const url = urls[index];
    return { shipper, party, url, carrier }
}

export default connect(mapStateToProps)(ContainerByWeekReportOld);