import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

class PageCountDisplay extends Component {
    state = {
        adjustedPageCount: [],
        pageReset: false
    }

    componentDidUpdate = (prevProps, prevState) => {

            if (this.props.count.length > 10) {
                let p = this.props.page;
                let c = this.props.count;
                if (p !== c[0] && p !== c[0] - 1 && p + 1 !== c[c.length - 1] && p + 2 !== c[c.length - 1]) {
                    if (prevProps.page !== p || this.state.pageReset) {
                        let newCount = c.slice(p - 2, p + 3);
                        this.setState({ adjustedPageCount: newCount, pageReset: false });
                    }
                }
                if (prevProps.count !== c || this.state.pageReset) {
                    if (p === 0 || p === 1) {
                        let newCount = c.slice(0, 5);
                        this.setState({ adjustedPageCount: newCount, pageReset: false });
                    }
                    if (p + 1 === c.length || p + 2 === c.length) {
                        let newCount = c.slice(c.length - 5, c.length);
                        this.setState({ adjustedPageCount: newCount, pageReset: false });
                    }
                }
            }
    }

    componentDidMount = () => {
        this.setState({pageReset: this.props.pageReset})
    }

    render = () => {
        return (
            <div>{
                this.props.count.length > 10 ?
                    <ul className="pagination">
                        {this.props.page > 2 ?
                            <Fragment>
                                <li name="1"
                                    onClick={this.props.pageJump}
                                    style={{ cursor: "pointer" }}
                                    className={`pageLink waves-effect`}
                                >{1}
                                </li>
                                <li style={{ marginTop: "3px", marginRight: "-8px" }}>.&nbsp;.&nbsp;.</li>
                            </Fragment>
                            : null}
                        <li className="waves-effect">
                            <i className="material-icons"
                                name="back"
                                onClick={this.props.singlePageTurn}>chevron_left</i></li>
                        {this.props.count ? (this.state.adjustedPageCount).map((pageNumber, index) => (
                            <li key={index}
                                style={{ cursor: "pointer" }}
                                name={pageNumber}
                                onClick={this.props.pageJump}
                                className={`pageLink waves-effect ${this.props.page === pageNumber - 1 ? 'activePage' : ''}`}>
                                &nbsp;&nbsp;{pageNumber}&nbsp;&nbsp;</li>
                        )) : null}
                        <li className="waves-effect">
                            <i className="material-icons"
                                name="forw"
                                onClick={this.props.count.length ? this.props.singlePageTurn : () => { }}>chevron_right</i></li>
                        {this.props.page < this.props.count[this.props.count.length - 4] ?
                            <Fragment>
                                <li style={{ marginTop: "3px", marginLeft: "-8px" }}>.&nbsp;.&nbsp;.</li>
                                <li name={this.props.count[this.props.count.length - 1]}
                                    onClick={this.props.pageJump}
                                    style={{ cursor: "pointer" }}
                                    className={`pageLink waves-effect`}
                                >{this.props.count.length}
                                </li>
                            </Fragment>
                            : null}
                    </ul>
                    :
                    <ul className="pagination">
                        <li className="waves-effect">
                            <i className="material-icons"
                                name="back"
                                onClick={this.props.singlePageTurn}>chevron_left</i></li>
                        {this.props.count ? (this.props.count).slice(0).map((pageNumber, index) => (
                            <li key={index}
                                style={{ cursor: "pointer" }}
                                name={pageNumber}
                                onClick={this.props.pageJump}
                                className={`pageLink waves-effect ${this.props.page === pageNumber - 1 ? 'activePage' : ''}`}>
                                &nbsp;&nbsp;{pageNumber}&nbsp;&nbsp;</li>
                        )) : null}
                        <li className="waves-effect">
                            <i className="material-icons"
                                name="forw"
                                onClick={this.props.count.length ? this.props.singlePageTurn : () => { }}>chevron_right</i></li>
                    </ul>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { urls, index } = state;
    const url = urls[index];
    return { url };
}

export default connect(mapStateToProps)(PageCountDisplay)