import React from "react";
import styles from "./select.module.css";
import { types } from "../../constants";
import { inputValidation } from '../../helperFunctions';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  validateArr = () => {
    if (Array.isArray(this.props.list)) return this.fromArray();
    else return this.fromObject();
  };

  fromArray = () => {
    let arr = [];
    if (this.props.list.length) {
      arr = this.props.list
    } else if (this.props.filter.label === this.props.filter.value && !this.props.placeholder) {
      arr.push({ [this.props.filter.label]: "..Loading" });
    } else if (!this.props.placeholder) {
      arr.push({ [this.props.filter.label]: "..Loading", [this.props.filter.value]: 0 });
    }
    return arr;
  };

  fromObject = () => {
    if (!this.props.list) return [{ [this.props.filter.label]: "...Loading", [this.props.filter.value]: null }];
    else {
      const arr = [this.props.list];
      return arr;
    }
  };

  genDivClass = () =>
    `${this.props.col ? `col ${this.props.col}` : ""} ${styles.div}`;

  onChange = e => {
    this.setState(
      { value: e.target.value },
      this.props.onChange(this.props.name, e.target.value)
    );
  }
  getInputErrInfo = () => ([{ name: 'value', type: types.STRING, value: this.props.value, flags: this.props.flags }]);

  onBlur = () => {
    inputValidation(this.getInputErrInfo(), this.success, this.failure);
    // if (!this.props.value || this.props.value === "0")
    //   this.setState({ err: true }, this.props.onBlur);
    // else this.setState({ err: false }, this.props.onBlur);
  };
  success = value => this.setState({ err: null }, this.props.onBlur);
  failure = value => this.setState({ err: { msg: value.value.msg } }, this.props.onBlur);

  render = () => (
    <div className={this.genDivClass()}>
      <select
        id={this.props.name}
        className={`browser-default ${styles.select} 
        ${this.props.className}
        ${this.state.err ? styles.err : ""}`}
        disabled={`${this.props.disabled ? "disabled" : ""}`}
        onChange={this.onChange}
        onClick={this.props.onClick}
        onFocus={this.props.onFocus}
        value={this.props.value}
        onBlur={this.onBlur}
        tabIndex={this.props.tabIndex ? this.props.tabIndex : 0}
        onContextMenu={this.props.onContextMenu}
      >
        {this.props.placeholder ? <option value={0}>{this.props.placeholder}</option> : null}
        {this.validateArr().map((o, index) => (
          !o.isArchived ?
            <option key={index} value={o[this.props.filter.value]} className={this.props.optionClassName}>
              {o[this.props.filter.label]}
            </option> :
            <option key={index} value={o[this.props.filter.value]} disabled hidden>{o[this.props.filter.label]}</option>
        ))}
      </select>
      {this.props.label ? 
      <label className={`${styles.label} ${this.props.labelClassName}`}>{this.props.label} {this.props.required ? <span style={{ color: "red" }}>*</span> : ''}</label>
      : null}
    </div>
  );
}

export default Select;
