import React from 'react';
import styles from "./LoadingSpinner.module.css";


const LoadingSpinner = (props) => {
    return (
        <div className={`${props.className} ${styles.spinnerWrapper}`}>
            <div className={`preloader-wrapper ${props.size ? props.size : 'small'} active`}>
                <div className={`spinner-layer ${props.color ? `spinner-${props.color}-only` : 'spinner-blue-only'}`}>
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div><div className="gap-patch">
                        <div className="circle"></div>
                    </div><div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>
            </div>
            {props.label ? props.label : ""}
        </div>)
}

export default LoadingSpinner;