import React from 'react';
import axios from 'axios';
import Form from '../../../Components/NewComponents';

class CreateAlliance extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); };

    initState = () => ({ name: '', msg: { color: '', text: '' } });
    onChange = (name, value) => this.setState({ [name]: value });
    save = () => {
        if (!this.state.name)
            this.setState({ msg: { color: 'red', text: 'Name cannot be blank.' } })
        else
            axios.post('/api/v1/alliance', { name: this.state.name }).then(result => {
                if (result.data.errno)
                    this.setState({ msg: { color: 'red', text: `Error: ${result.data.sqlMessage}` } });
                else
                    this.setState({ ...this.initState(), msg: { color: 'green', text: `Successfully submitted, ID: ${result.data.insertId}` } })
            });
    }

    clear = () => this.setState(this.initState());


    render = () => (
        <div>
            <div className="row">
                <h5>Alliance</h5>
            </div>
            <div className="row">
                <Form.TextInput col="s9 l6" name="name" label="Name" onChange={this.onChange} value={this.state.name} />
            </div>
            <div className="row">
                <h6 className={`${this.state.msg.color}-text`}>{this.state.msg.text}</h6>
            </div>
            <div className="row">
                <Form.Button color="green" round type="outline" label="Save" icon="save" onClick={this.save} />
                <Form.Button color="red" round type="outline" label="Clear" icon="cancel" onClick={this.clear} />
            </div>
        </div>
    )
}

export default CreateAlliance;