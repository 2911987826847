import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import Form from '../../../Components/NewComponents';
import ModalContractDetails from './ModalContractDetails';
import ModalRatesDisplay from './ModalRatesDisplay';
import ModalSalesRepDisplay from './ModalSalesRepDisplay';
import ModalFreetimeDisplay from './ModalFreetimeDisplay';
import { globalToastActions } from '../../../Redux/actions';
import axios from 'axios';
import styles from "../contracts.module.css";
import { auth, withRouter } from "../../../helperFunctions";

class NewContractModal extends Component {
    state = {
        contracts: [],
        allLocations: [],
        step: 1,
        contract: {
            contractDetails: {
                shipperId: this.props.contract ? this.props.contract.contractDetails.shipperId : "0",
                carrierId: this.props.contract ? this.props.contract.contractDetails.carrierId : "0",
                commodityId: 1,
                commissionUsePercentage: true,
                commissionAmount: "",
                minimumQuantityCommitment: "",
                contractNumber: "",
                containerTypeId: "1",
                isManaged: true,
                manageType: "lfManaged",
                isSigned: true,
                status: "PENDING REVIEW",
                effectiveDate: "",
                expirationDate: "",
                note: ""
            },
            charges: {
                perContainerCharges: [],
                additionalCharges: []
            },
            origins: [],
            destinations: [],
            rates: [],
            specialRestrictions: [],
            contractSalesReps: [],
            freetime: []
        },
        countries: [],
        origins: [],
        destinations: [],
        portPairs: [],
        onFocusBlack: false
    }

    datePickerOnChange = (date, name) => {
        this.setState({ contract: { ...this.state.contract, contractDetails: { ...this.state.contract.contractDetails, [name]: date } } });
    }

    onChange = (name, value) => this.setState({ [name]: value });

    modalOnChange = (name, value) => {
        this.setState({ contract: { ...this.state.contract, [name]: value } })
    }

    modalContractDetailsOnChange = (name, value) => {
        this.setState({ contract: { ...this.state.contract, contractDetails: { ...this.state.contract.contractDetails, [name]: value } } });
    }

    modalRowEditOnChange = (name, value, step) => {
        let index = name.split(":")[1];
        let trueName = name.split(":")[0];
        let sectionObject = this.state.contract[step];
        sectionObject[index][trueName] = value;

        this.setState({ contract: { ...this.state.contract, [step]: sectionObject } })
    }

    specialRestrictionSpliceEdit = (singleSpecRest, index) => {
        let specialRestrictions = this.state.contract.specialRestrictions;
        specialRestrictions.splice(index, 1, singleSpecRest);
        this.setState({ contract: { ...this.state.contract, specialRestrictions } })
    }

    freetimeSpliceEdit = (singleFreetime, index) => {
        let freetime = this.state.contract.freetime;
        freetime.splice(index, 1, singleFreetime);
        this.setState({ contract: { ...this.state.contract, freetime } });
    }

    salesRepSpliceEdit = (singleSalesRep, index) => {
        let contractSalesReps = this.state.contract.contractSalesReps;
        contractSalesReps.splice(index, 1, singleSalesRep);
        this.setState({ contract: { ...this.state.contract, contractSalesReps } });
    }

    chargesEditOnChange = (name, value, type) => {
        let index = name.split(":")[1];
        let trueName = name.split(":")[0];
        let sectionObject = this.state.contract.charges;
        sectionObject[type][index][trueName] = value;

        this.setState({ contract: { ...this.state.contract, charges: sectionObject } })
    }

    modalRatesOnchange = (name, value) => {
        let nameAndIndex = name.split(":");
        let index = nameAndIndex[1];
        let rates = this.state.contract.rates;
        rates[index].amount = value;
        this.setState({ contract: { ...this.state.contract, rates } });
    }

    initListeners = () => {
        document.addEventListener("keyup", this.clickIcon);
    }

    initModal = () => {
        M.Modal.init(document.querySelector('#contract-modal'));
    }

    initSlider = () => {
        M.Slider.init(document.querySelector('.slider'));
    }

    removeListeners = () => {
        document.removeEventListener("keyup", this.clickIcon);
    }

    clickIcon = (e) => {
        if (e.keyCode === 13 && document.activeElement.classList.contains("material-icons")) {
            e.preventDefault();
            document.activeElement.click();
        }
    }

    markAsReviewed = (event) => {

        if (!this.state.onFocusBlack) {
            return;
        }
        event.target.style = "black";
    }

    createRatesObject = () => {
        let portPairs = [];
        let origins = this.props.locations.filter(l => (l.countryId === 1 || l.countryId === 17) && l.typeId !== 3);
        let destinations = this.props.locations.filter(l => (l.country_id !== 1 || l.countryId !== 17) && l.typeId !== 3);
        origins.forEach((o, index) => {
            destinations.forEach((d) => portPairs.push({
                originId: o.id,
                destId: d.id,
                amount: ""
            }))

        });

        this.setState({ portPairs })
    }

    addLocation = (type, location) => {
        if (location === 0) {
            return;
        }
        if (this.state[type].filter(l => l.id === location).length) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Duplicate Locations", type: "error" } });
            return;
        }
        let newList = this.state[type];

        newList.push(this.props.locations.filter(l => l.id === location)[0]);
        newList[newList.length - 1].expirationDate = "";
        newList = newList.sort((a, b) => a.id - b.id);

        this.setState({ [type]: newList },
            () => {
                let rates = this.state.contract.rates;

                if (type === "destinations") {

                    this.state.origins.forEach(o => {
                        rates.push(this.state.portPairs.filter(p => {
                            return p.destId === location && p.originId === o.id
                        })[0]);
                    })
                    document.getElementById("destId").focus();
                } else {

                    this.state.destinations.forEach(d => {
                        rates.push(this.state.portPairs.filter(p => {
                            return p.originId === location && p.destId === d.id
                        })[0]);
                    });
                    document.getElementById("originId").focus();
                }

                rates = rates.sort((a, b) => a - b);
                this.setState({ contract: { ...this.state.contract, rates } })
            })
    }

    addSpecialRestriction = (info) => {
        let { originId, destId, note, expirationDate } = info;
        let specialRestrictions = this.state.contract.specialRestrictions;
        let originName = this.props.locations.filter(l => l.id === parseInt(originId))[0].name;
        let destName = this.props.locations.filter(l => l.id === parseInt(destId))[0].name;
        let specialRest = {
            originId,
            originName,
            destId,
            destName,
            note,
            expirationDate
        }
        specialRestrictions.push(specialRest);
        this.setState({ contract: { ...this.state.contract, specialRestrictions } });
    }

    removeSpecialRestriction = (index) => {
        let specialRestrictions = this.state.contract.specialRestrictions;
        specialRestrictions.splice(index, 1);

        this.setState({ contract: { ...this.state.contract, specialRestrictions } });
    }

    addCharge = (charge) => {
        let location = this.state.allLocations.filter(loc => loc.name === charge.locationName)[0];

        charge.portId = location.id && location.countryId !== undefined ? location.id : null;
        charge.countryId = location.id && location.countryId === undefined ? location.id : null;
        if (charge.perContainerCharge) {
            let perContainerCharges = [...this.state.contract.charges.perContainerCharges];
            perContainerCharges.push(charge);
            this.setState({ contract: { ...this.state.contract, charges: { ...this.state.contract.charges, perContainerCharges } } }, () => { document.getElementById("chargeName").select() });

        } else {
            let additionalCharges = [...this.state.contract.charges.additionalCharges];
            additionalCharges.push(charge);
            this.setState({ contract: { ...this.state.contract, charges: { ...this.state.contract.charges, additionalCharges } } }, () => { document.getElementById("chargeName").select() });
        }
    }

    removeCharge = (type, index) => {
        if (type.perContainerCharge) {
            let perContainerCharges = [...this.state.contract.charges.perContainerCharges];
            perContainerCharges.splice(index, 1);
            this.setState({ contract: { ...this.state.contract, charges: { ...this.state.contract.charges, perContainerCharges } } });

        } else {
            let additionalCharges = [...this.state.contract.charges.additionalCharges];
            additionalCharges.splice(index, 1);
            this.setState({ contract: { ...this.state.contract, charges: { ...this.state.contract.charges, additionalCharges } } });
        }
    }

    addSalesRep = (salesRep) => {
        if (!salesRep) {
            return;
        }
        let contractSalesReps = this.state.contract.contractSalesReps;
        contractSalesReps.push(salesRep);
        this.setState({ contract: { ...this.state.contract, contractSalesReps } });
    }

    removeSalesRep = (index) => {
        let contractSalesReps = this.state.contract.contractSalesReps;
        contractSalesReps.splice(index, 1);
        this.setState({ contract: { ...this.state.contract, contractSalesReps } });
    }

    removeFreetime = (index) => {
        let freetime = this.state.contract.freetime;
        freetime.splice(index, 1);
        this.setState({ contract: { ...this.state.contract, freetime } })
    }

    pasteFreetime = (index, amount, copiedFreetime) => {

        let currentCopy = { ...copiedFreetime };

        let freetime = this.state.contract.freetime;

        currentCopy.countryId = freetime[index].countryId;
        currentCopy.locationId = freetime[index].locationId;

        freetime.splice(index, amount, currentCopy);
        this.setState({ contract: { ...this.state.contract, freetime } })

    }

    nextStep = async () => {
        if (this.state.step === 4) {
            return
        }

        this.setState({ step: this.state.step + 1 }, () => {
            if (!!this.props.overWrite) {
                let modal = document.getElementById("contract-modal");
                let inputs = modal.querySelectorAll("tr>div>input, tr>div>select, td:not(.keep-black), tr>td>div>input");

                inputs.forEach(i => {
                    i.style.color = "blue";
                })
            }
        })
    }

    prevStep = () => {
        if (this.state.step === 1) {
            return;
        }
        this.setState({ step: this.state.step - 1 })
    }

    getStepTitle = (step) => {
        switch (step) {
            case 1:
                return "Contract Details";
            case 2:
                return "Routes And Rates";
            case 3:
                return "Freetime";
            case 4:
                return "Sales Reps";
            default:
                return;
        }
    }

    saveContract = () => {

        if (!this.state.contract.contractDetails.carrierId || !this.state.contract.contractDetails.shipperId) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Shipper and Carrier Required", type: "error" } })
            return;
        }
        if (!this.state.contract.contractDetails.effectiveDate) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Missing Effective Date", type: "error" } });
            return;
        }
        if (!this.state.contract.contractDetails.expirationDate) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Missing Expiration Date", type: "error" } });
            return;
        }

        const contract = this.state.contract;
        if (Object.values(contract.charges).length && (contract.charges).constructor === Object) {
            Object.values(contract.charges).forEach(chargeType => {
                chargeType.forEach(c => {
                    delete c.locationName
                })
            })
        }

        contract.isManaged = this.state.contract.contractDetails.manageType === "lfManaged" ? true : false;
        contract.isSigned = this.state.contract.contractDetails.manageType === "shipperSigned" ? false : contract.isSigned;
        axios.post("/api/v1/contract/", { ...auth.getAuthData(), contract }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else if (typeof result.data === "number") {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Contract Saved", type: "success" } });
                let contractId = result.data;

                let shipperId = contract.contractDetails.shipperId,
                    carrierId = contract.contractDetails.carrierId,
                    commodityId = contract.contractDetails.commodityId,
                    containerTypeId = contract.contractDetails.containerTypeId;

                await axios.get("/api/v1/contract/carriers", { params: { ...auth.getAuthData(), shipperId, carrierId, commodityId, containerTypeId, contractId: contract.id } }).then(async result => {
                    if (result === "NOT AUTHENTICATED") {
                        localStorage.clear();
                        this.props.dispatch({ type: "SCORCHED_EARTH " });
                    }

                    let contracts = result.data.length ? result.data : [{ carrierName: "NO OTHER CONTRACTS" }];

                    this.props.navigate('/contract/edit', {
                        state: {
                            origin: "contractOverview",
                            contractSearchTerm: contractId,
                            shipperId: contract.contractDetails.shipperId,
                            shipperName: this.props.party.filter(p => p.id === parseInt(contract.contractDetails.shipperId))[0].name,
                            filters: this.props.filters,
                            status: contract.contractDetails.status,
                            carrierId: contract.contractDetails.carrierId,
                            carrierName: this.props.carrier.filter(c => c.id === parseInt(contract.contractDetails.carrierId))[0].displayName,
                            contracts
                        }
                    })
                })

                M.Modal.getInstance(document.querySelector("#contract-modal")).close();

            } else {
                if (result.data.code === "ER_DUP_ENTRY") {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Duplicate Contract #`, type: "error" } })
                } else {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `${result.data.code}`, type: "error" } })

                }
            }

        })
    }

    switchFunction = (step) => {

        switch (step) {
            case 1:
                return <ModalContractDetails
                    onChange={this.modalContractDetailsOnChange}
                    chargesEditOnChange={this.chargesEditOnChange}
                    contract={this.state.contract}
                    addCharge={this.addCharge}
                    removeCharge={this.removeCharge}
                    datePickerOnChange={this.datePickerOnChange}
                    markAsReviewed={this.markAsReviewed}
                    allLocations={this.state.allLocations}
                    carrierCharges={this.props.carrierCharges} />;
            case 2:
                return <ModalRatesDisplay
                    onChange={this.modalOnChange}
                    modalRowEditOnChange={this.modalRowEditOnChange}
                    origins={this.state.origins}
                    destinations={this.state.destinations}
                    ratesOnChange={this.modalRatesOnchange}
                    contract={this.state.contract}
                    addLocation={this.addLocation}
                    addSpecialRestriction={this.addSpecialRestriction}
                    removeSpecialRestriction={this.removeSpecialRestriction}
                    expirationDateOnChange={this.modalExpirationDateOnChange}
                    markAsReviewed={this.markAsReviewed}
                    checkRatesForNeededFreetime={this.checkRatesForNeededFreetime}
                    specialRestrictionSpliceEdit={this.specialRestrictionSpliceEdit}
                />;
            case 3:
                return <ModalFreetimeDisplay
                    onChange={this.modalOnChange}
                    modalRowEditOnChange={this.modalRowEditOnChange}
                    contract={this.state.contract}
                    removeFreetime={this.removeFreetime}
                    markAsReviewed={this.markAsReviewed}
                    pasteFreetime={this.pasteFreetime}
                    freetimeSpliceEdit={this.freetimeSpliceEdit}
                />;
            case 4:
                return <ModalSalesRepDisplay
                    onChange={this.modalOnChange}
                    modalRowEditOnChange={this.modalRowEditOnChange}
                    contract={this.state.contract}
                    addSalesRep={this.addSalesRep}
                    removeSalesRep={this.removeSalesRep}
                    markAsReviewed={this.markAsReviewed}
                    salesRepSpliceEdit={this.salesRepSpliceEdit}
                />;
            default:
                return;
        }

    }

    overwriteContractInfo = async () => {

        await axios.get("/api/v1/contract/copy", { params: { ...auth.getAuthData(), contractId: this.props.contract.contractDetails.id } }).then(async result => {

            let contract = result.data;

            let origins = [...this.props.origins.filter(o => contract.rates.map(r => r.originId).includes(o.id))];
            let destinations = [...this.props.destinations.filter(d => contract.rates.map(r => r.destId).includes(d.id))];
            let portPairs = [];
            origins.forEach((o, index) => {
                destinations.forEach((d) => portPairs.push({
                    contractId: contract.contractDetails.id,
                    originId: o.id,
                    destId: d.id,
                    amount: ""
                }))
            });

            portPairs.forEach((p, index) => {
                contract.rates.forEach(r => {
                    if (r.originId === p.originId && r.destId === p.destId && p.contractId === r.contractId) {
                        portPairs.splice(index, 1, r);
                    }
                });
            });
            contract.rates = portPairs;

            await portPairs.forEach(pp => {
                delete pp.chargeTotal;
                delete pp.destName;
                delete pp.destCountryId;
                delete pp.originName;
                delete pp.originCountryId;
                delete pp.contractId;
                delete pp.previousAmount;
                delete pp.id;
            });

            let modal = document.getElementById("contract-modal");
            let inputs = modal.querySelectorAll("input:not(#chargeName):not(#chargeAmount), select:not(#chargeType):not(#chargeCountryId), #perContainerCharges, #additionalCharges");

            await inputs.forEach((i, index) => {
                i.style.color = "blue";
            });

            // contract.contractDetails.contractNumber = "";
            contract.contractDetails.effectiveDate = "";
            contract.contractDetails.expirationDate = "";

            this.setState({ origins, destinations, contract, onFocusBlack: true });

        })

    }


    checkRatesForNeededFreetime = () => {
        let rates = this.state.contract.rates;
        let freetime = this.state.contract.freetime;

        rates.forEach(r => {
            let freetimeCheckForOrigin = new Array(...freetime.map(f => f.locationId)).includes(r.originId);
            let freetimeCheckForDest = new Array(...freetime.map(f => f.locationId)).includes(r.destId);

            if (!freetimeCheckForOrigin) {
                freetime.push({
                    countryId: this.props.locations.filter(l => l.id === r.originId)[0].countryId,
                    locationId: r.originId,
                    demurrageDays: null,
                    demurrageTimeType: null,
                    detentionDays: null,
                    detentionTimeType: null,
                    additionalDemurrage: null,
                    expirationDate: null,
                    notes: ""
                })
            }
            if (!freetimeCheckForDest) {
                freetime.push({
                    countryId: this.props.locations.filter(l => l.id === r.destId)[0].countryId,
                    locationId: r.destId,
                    demurrageDays: null,
                    demurrageTimeType: null,
                    detentionDays: null,
                    detentionTimeType: null,
                    additionalDemurrage: null,
                    expirationDate: null,
                    notes: ""
                })
            }
        });

        this.setState({ contract: { ...this.state.contract, freetime: freetime.sort((a, b) => (a.countryId > b.countryId) ? 1 : -1) } });

    }

    getAllLocations = () => {
        if (!this.state.allLocations.length) {
            let countries = this.props.country;
            let locations = this.props.locations;
            let allLocations = countries.concat(locations);
            if (allLocations[0].id !== 0) {
                allLocations.unshift({ id: 0, name: "ALL" });
            }
            this.setState({ allLocations });
        } else {
            return;
        }
    }

    resetFocus = () => {
        if (this.state.step !== 2) {
            return;
        } else {
            document.querySelector("originDropdownIcon").focus();
        }
    }

    componentDidUpdate = (prevProps) => {


        if (prevProps.contract !== this.props.contract) {
            this.setState({ contract: { ...this.state.contract, contractDetails: { ...this.state.contract.contractDetails, carrierId: this.props.contract.contractDetails.carrierId, shipperId: this.props.contract.contractDetails.shipperId } } })
        }


        if (prevProps.overWrite !== this.props.overWrite && this.props.overWrite) {
            this.overwriteContractInfo();
        }

    }

    componentDidMount = () => {
        this.initListeners();
        this.initModal();
        this.getAllLocations();
        this.createRatesObject();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => {
        return (
            <div style={{ position: "relative" }}>
                <div className={`modal ${styles.contractModal}`} id="contract-modal">
                    <div className="modal-content" style={{ paddingTop: "12px" }}>
                        <div className="row" style={{ marginBottom: "12px" }}>
                            <div className="col s6">
                                <h4 style={{ marginBottom: "0px" }}>New Contract</h4>
                            </div>
                            <div className="col s6 stepStatus">
                                {`Step ${this.state.step} of 4`}
                            </div>
                        </div>
                        <div className="progress">
                            <div className="determinate" style={{ width: `${(this.state.step / 4) * 100}%` }}>
                                {this.getStepTitle(this.state.step)}
                            </div>
                        </div>
                        <div>
                            {this.switchFunction(this.state.step)}
                        </div>
                        <div className={`row ${styles.modalStepButtons}`}>
                            {this.state.step !== 1 ?
                                <Form.Button col={`col s3 ${styles.modalStepButtonsLeft}`} onClick={this.prevStep} label="Prev" tabIndex="-1" type="outline" color="blue" icon="arrow_back" /> :
                                null}
                            {this.state.step !== 4 ? <Form.Button tabIndex="100" col={`s3 offset-s8 ${styles.modalStepButtonsRight}`} onClick={this.nextStep} onBlur={this.resetFocus} label="Next" type="outline" color="blue" icon="arrow_forward" /> :
                                <Form.Button col={`col s3 offset-s8 ${styles.modalStepButtonsRight}`} onClick={this.saveContract} label="Save Contract" type="outline" color="blue" icon="save" />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    const { navs, urls, index, party, carrier, location, containerType, serviceRoute, country, portalUser, user } = state;
    const shipperId = navs[index].shipperId;
    const url = urls[index];
    return { shipperId, party, carrier, locations: location, containerType, serviceRoute, country, portalUser, url, user };
}

export default connect(mapStateToProps)(withRouter(NewContractModal));