import React, { Component } from "react";
import axios from "axios";
import Form from "../../Components/NewComponents";
import styles from "./party.module.css";
import { flags } from "../../constants";
import { connect } from "react-redux";
import M from "materialize-css";
import Card from "../../Components/NewComponents/Card";
import { globalToastActions, init } from "../../Redux/actions";
import { auth, withRouter } from "../../helperFunctions";

class CreateParty extends Component {
  state = {
    conDisabled: false,
    shipDisabled: false,
    editIndex: "",
    name: "",
    code: "",
    main_phone: "",
    main_fax: "",
    email: "",
    website: "",
    pointOfContact: "",
    companyCode: "",
    taxIdNumber: "",
    role: "SHIPPER",
    useAlternateSubjectLine: false,
    useIndividualVoyage: false,
    addresses: [],
    newAddress: {
      country: 1,
      addressLabel: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      printable: ""
    },
    editAddress: {
      editCountry: 1,
      editAddressLabel: "",
      editAddress1: "",
      editAddress2: "",
      editCity: "",
      editState: "",
      editZip: "",
      editPrintable: ""
    },
    consignees: [],
    consigneeChoice: "",
    shippers: [],
    shipperChoice: "",
    clients: [],
    client: "",
    clientAddListSelection: "List of Connections",
    clientsAdded: [
      {
        shipperId: "No Current Connections",
        consigneeId: "No Current Connections"
      }
    ],
    contactName: "",
    contactEmail: "",
    contactDefault: 0,
    contactField: "TO",
    contactFieldOptions: [{ label: "TO" }, { label: "CC" }, { label: "BCC" }],
    contactRole: "BOOKINGS",
    contacts: [],
    employees: [],
    requestsEmployee: 0,
    bookingsEmployee: 0,
    submitSiEmployee: 0,
    proofEmployee: 0,
    closeoutEmployee: 0,
    invoicingEmployee: 0,
    bookKeepingEmployee: 0,
    contractsEmployee: 0
  };

  clearForms = () => {
    this.setState({
      editIndex: "",
      name: "",
      code: "",
      main_phone: "",
      main_fax: "",
      email: "",
      website: "",
      pointOfContact: "",
      companyCode: "",
      taxIdNumber: "",
      role: "",
      addresses: [],
      newAddress: {
        country: 1,
        addressLabel: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        printable: ""
      },
      editAddress: {
        editCountry: 1,
        editAddressLabel: "",
        editAddress1: "",
        editAddress2: "",
        editCity: "",
        editState: "",
        editZip: "",
        editPrintable: ""
      },
      clients: [],
      client: "",
      clientAddListSelection: "List of Connections",
      clientsAdded: [
        {
          shipperId: "No Current Connections",
          consigneeId: "No Current Connections"
        }
      ]
    });
  };

  clearNewAddForm = () => {
    this.setState({
      newAddress: {
        country: 1,
        addressLabel: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        printable: ""
      }
    });
  };

  onChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  //Updates the logic for disabling shipper/consignee dropdown
  //upon role selection
  roleOnChange = (name, value) => {
    this.setState({
      [name]: value
    }, this.chooseList);
  };

  newAddOnChange = (name, value) => {
    const { newAddress } = this.state;
    newAddress[name] = value;
    this.setState({ newAddress });
  };

  editAddOnChange = (name, value) => {
    const { editAddress } = this.state;
    editAddress[name] = value;
    this.setState({ editAddress });
  };

  contactOnChange = (name, value) => {
    let tabChoice = value === "P/L REMINDERS" ? "PLREMINDERS" : value;
    this.setState({ [name]: value }, () => M.Tabs.getInstance(document.getElementById("tabs")).select(tabChoice))
  }

  changeTabFocus = (label) => {
    this.setState({ contactRole: label })
  }

  //Initializes modals
  initModal = () => M.Modal.init(document.querySelectorAll(".modal"));

  initTabs = () => M.Tabs.init(document.getElementById("tabs"));

  //This function is old for a use of the autocomplete method, needs to be refactored
  //to use its functionality to grey out a dropdown that doesn't apply to the user
  chooseList = () => {
    if (this.state.role === "CONSIGNEE") {
      this.setState({
        conDisabled: true,
        shipDisabled: false
      });
    } else if (this.state.role === "SHIPPER") {
      this.setState({
        shipDisabled: true,
        conDisabled: false
      });
    } else if (this.state.role === "BOTH") {
      this.setState({
        conDisabled: false,
        shipDisabled: false
      });
    }
  };

  //Saves whatever edit is done using the edit modal and replaces
  //its own instance within the addresses array
  saveEdit = () => {
    let index = this.state.editIndex;
    let addresses = this.state.addresses;
    let editAdd = this.state.editAddress;
    let newAdd = {
      id: editAdd.editId,
      address1: editAdd.editAddress1,
      address2: editAdd.editAddress2,
      addressLabel: editAdd.editAddressLabel,
      city: editAdd.editCity,
      country: editAdd.editCountry,
      state: editAdd.editState,
      zip: editAdd.editZip,
      printable: editAdd.editPrintable
    };
    addresses.splice(index, 1, newAdd);

    this.setState({
      addresses: addresses
    });
    this.hideEditModal();
  };

  //Opens modal specifically for editing address information,
  //uses index from the map to pull it out of the addresses array and place
  //into a staging area in the state.
  showEditModal = (index, id) => {
    M.Modal.getInstance(document.querySelector("#edit-address-modal")).open();

    let editAddress = {
      editId: id,
      editAddress1: this.state.addresses[index].address1,
      editAddress2: this.state.addresses[index].address2,
      editCountry: this.state.addresses[index].country,
      editAddressLabel: this.state.addresses[index].addressLabel,
      editCity: this.state.addresses[index].city,
      editState: this.state.addresses[index].state,
      editZip: this.state.addresses[index].zip,
      editPrintable: this.state.addresses[index].printable
    }

    this.setState({
      editIndex: index,
      editAddress
    });
  };

  //Close Edit Modal
  hideEditModal = () => {
    M.Modal.getInstance(document.querySelector("#edit-address-modal")).close();
    document.getElementById("submitButton").focus();
  };

  //Open Add Modal
  showAddModal = () => {
    this.clearNewAddForm();
    M.Modal.getInstance(document.querySelector("#add-address-modal")).open();
  };

  //Close Add Modal
  hideAddModal = () => {
    M.Modal.getInstance(document.querySelector("#add-address-modal")).close();
    document.getElementById("submitButton").focus();
  };

  //Runs on click from the add address modal, this is for the creation of a new address,
  //not edit.
  saveAddress = () => {
    let addresses = this.state.addresses;
    let newAddress = {
      party_id: this.state.party_id,
      country: this.state.newAddress.country,
      addressLabel: this.state.newAddress.addressLabel,
      address1: this.state.newAddress.address1,
      address2: this.state.newAddress.address2,
      city: this.state.newAddress.city,
      state: this.state.newAddress.state,
      zip: this.state.newAddress.zip,
      printable: this.state.newAddress.printable
    };

    addresses.push(newAddress);
    this.setState({ addresses: addresses });
    this.hideAddModal();
  };

  //Ran when the submit button is clicked, packages everything to send to node for a db.transaction
  //because of the three sets of forms the creation page has to send all at once
  submitCreate = () => {
    let connections = [];
    this.state.clientsAdded.forEach(e => {
      if (e.shipperId === "CURRENT PARTY") {
        let index = this.state.clients.map(i => {
          return i.name;
        }).indexOf(e.consigneeId);

        connections.push({
          shipper_id: "insertId",
          consignee_id: this.state.clients[index].id
        });

      } else if (e.consigneeId === "CURRENT PARTY") {
        let index = this.state.clients.map(i => {
          return i.name;
        }).indexOf(e.shipperId);

        connections.push({ shipper_id: this.state.clients[index].id, consignee_id: "insertId" });

      } else {
        connections = [];
      }
    });
    let contacts = this.state.contacts;
    let addresses = this.state.addresses;
    let address = {
      name: this.state.name.trim(),
      code: this.state.code ? this.state.code.trim() : this.state.code,
      main_phone: this.state.main_phone,
      main_fax: this.state.main_fax,
      main_email: this.state.email,
      website: this.state.website,
      point_of_contact: this.state.pointOfContact,
      use_alternate_subject_line: this.state.useAlternateSubjectLine,
      use_individual_voyage: this.state.useIndividualVoyage,
      company_code: this.state.companyCode,
      tax_id_number: this.state.taxIdNumber,
      role: this.state.role,
      requests: this.state.requestsEmployee,
      bookings: this.state.bookingsEmployee,
      submit_si: this.state.submitSiEmployee,
      proof: this.state.proofEmployee,
      closeout: this.state.closeoutEmployee,
      invoicing: this.state.invoicingEmployee,
      book_keeping: this.state.bookKeepingEmployee,
      contracts: this.state.contractsEmployee,
      addresses,
      connections,
      contacts
    };

    if (!address.name) {
      this.props.dispatch({
        type: "UPDATE_MSG",
        payload: { msg: "Missing Required Field(s)", type: "error" }
      });
    } else if (
      address.name.length > 120 && address.code.length > 20 && address.main_phone.length > 20 &&
      address.fax.length > 20 && address.email.length > 60 && address.website.length > 60
    ) {
      this.props.dispatch({
        type: "UPDATE_MSG",
        payload: { msg: "Field(s) Too Long", type: "error" }
      });
    } else if (!address.code.length) {
      this.props.dispatch({
        type: "UPDATE_MSG",
        payload: { msg: "Short Name Required", type: "error" }
      });
    } else {
      axios.post("/api/v1/party/new", { ...auth.getAuthData(), address }).then(result => {
        if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({
            type: "SCORCHED_EARTH"
          });
          return;
        } else if (result.data.fail) {
          this.props.dispatch({
            type: globalToastActions.UPDATE_MSG,
            payload: { msg: result.data.msg, type: "error" }
          });
        } else if (result.data.errno) {
          this.props.dispatch({
            type: globalToastActions.UPDATE_MSG,
            payload: { msg: "An Error Occurred", type: "error" }
          });
        } else {
          this.props.dispatch({
            type: "UPDATE_MSG", payload: { msg: "Address Added", type: "success" }
          });
          this.props.dispatch({
            type: "UPDATE_URL",
            payload: { url: "/party", state: {} }
          });
        }
      });
    }
  };

  //Drops the clicked address from the array in the state
  deleteAddress = (index, id) => {
    let list = this.state.addresses.filter((address, i) => {
      return this.state.addresses.indexOf(address) !== index;
    });
    this.setState({ addresses: list });
  };

  //does a query into Party to grab and group all parties available by their role, to be
  //applied to any associations the page and user would like to make
  //The population of the clients array is also piggybacked into this
  grabPartyRoles = () => {
    axios.get("/api/v1/party/relations", { params: { ...auth.getAuthData() } }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;

      } else {
        let shippers = this.state.shippers;
        let consignees = this.state.consignees;
        let clients = this.state.clients;
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].role === "SHIPPER") {
            shippers.push(result.data[i]);
            clients.push(result.data[i]);

          } else if (result.data[i].role === "CONSIGNEE") {
            consignees.push(result.data[i]);
            clients.push(result.data[i]);

          } else if (result.data[i].role === "BOTH") {
            shippers.push(result.data[i]);
            consignees.push(result.data[i]);
            clients.push(result.data[i]);
          }
        }
        this.setState({
          shippers,
          consignees,
          clients,
          consigneeChoice: consignees[0].name,
          shipperChoice: shippers[0].name
        });
      }
    });
  };

  addConnection = (role, id) => {
    let list = this.state.clientsAdded;
    if (list[0].shipperId === "No Current Connections") {
      list = list.slice(1);
      this.setState({ clientsAdded: list });
    }

    if (role === 1 && !this.state.conDisabled) {
      list.push({ shipperId: "CURRENT PARTY", consigneeId: id });
      this.setState({ clientsAdded: list });

    } else if (role === 2 && !this.state.shipDisabled) {
      list.push({ shipperId: id, consigneeId: "CURRENT PARTY" });

      this.setState({ clientsAdded: list });
    }
  };

  addContact = () => {

    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let contacts = this.state.contacts;
    let contact = { name: this.state.contactName.trim(), email: (this.state.contactEmail.trim()).toLowerCase(), defaultEmail: parseInt(this.state.contactDefault) ? true : false, field: parseInt(this.state.contactDefault) ? this.state.contactField : null, role: this.state.contactRole };


    if (!contact.name) {
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Name Field Is Required" }
      });
      return;
    };
    if (!contact.email) {
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Email Field Is Required" }
      });
      return;
    };
    if (!emailRegex.test(contact.email)) {
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Not A Valid Email" }
      });
      return;
    };
    if (contacts.filter(c => c.email === contact.email && c.role === contact.role).length) {
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Duplicate Contact" }
      });
      return;
    };
    M.Tabs.getInstance(document.getElementById("tabs")).select(this.state.contactRole)
    contacts.push(contact);
    this.setState({ contacts })
    this.props.dispatch({
      type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Contact Added" }
    });
    document.getElementById("contactName").focus();
  };

  removeContact = (id) => {
    let contacts = this.state.contacts;
    contacts.splice(contacts.indexOf(id), 1);
    this.setState({ contacts })
  }

  removeConnection = index => {
    let list = this.state.clientsAdded;
    list.splice(index, 1);
    this.setState({ clientsAdded: list });
  };

  initListener = () => {
    document.addEventListener("keydown", this.escListener);
    document.addEventListener("keydown", this.addContactListener);
  };

  removeListener = () => {
    document.removeEventListener("keydown", this.escListener);
    document.removeEventListener("keydown", this.addContactListener);
  };

  escListener = async e => {
    if (e.keyCode === 27) {
      if (await this.checkForOpenModals())
        return;
      else
        this.props.navigate("/party", { state: this.props.location?.state });
    }
  };

  checkForOpenModals = async () => {
    let modals = document.querySelectorAll('.modal');
    let openModal = false;
    await modals.forEach(modal => {
      if (M.Modal.getInstance(modal).isOpen) {
        M.Modal.getInstance(modal).close();
        return openModal = true;
      }
    });
    return openModal;
  }

  addContactListener = e => {
    if (e.keyCode === 13 && document.activeElement.id === "addIcon") {
      this.addContact();
    }
  }

  componentDidMount = () => {
    document.querySelector("#name").focus();
    this.initListener();
    this.initModal();
    this.initTabs();
    this.grabPartyRoles();
    this.chooseList();
  };

  componentWillUnmount = () => {
    this.removeListener();
    axios.get("/api/v1/party/populated/all", { params: auth.getAuthData() }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      } else if (Array.isArray(result.data)) {
        this.props.dispatch({
          type: init.INITIALIZE_STORE,
          payload: { listName: "party", list: result.data }
        });
      }
    });
    axios.get("/api/v1/redux/shippers", { params: auth.getAuthData() }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      } else if (Array.isArray(result.data)) {
        this.props.dispatch({
          type: init.INITIALIZE_STORE,
          payload: { listName: "shipper", list: result.data }
        });
      }
    })

  };

  render() {
    return (
      <div>
        <Form.Section label="Party Profile">
          <div className="row">
            <Form.TextInput id="name" name="name" label="Name" value={this.state.name} onChange={this.onChange} col="s6" flags={[flags.MAX_LENGTH + 120]} />
            <Form.TextInput name="code" label="Short Name" value={this.state.code} onChange={this.onChange} col="s3" maxLength="20" flags={[flags.MAX_LENGTH + 20, flags.ALLOW_EMPTY]} />
            <Form.Select name="role" label="Role" value={this.state.role} onChange={this.roleOnChange} onClick={this.chooseList} col="s3" list={[{ name: "SHIPPER" }, { name: "CONSIGNEE" }, { name: "BOTH" }]} filter={{ label: "name", value: "name" }} />
          </div>
          <div className="row">
            <Form.TextInput name="main_phone" label="Main Phone" value={this.state.main_phone} onChange={this.onChange} col="s2" flags={[flags.MAX_LENGTH + 20, flags.ALLOW_EMPTY]} />
            <Form.TextInput name="main_fax" label="Main Fax" value={this.state.main_fax} onChange={this.onChange} col="s2" flags={[flags.MAX_LENGTH + 20, flags.ALLOW_EMPTY]} />
            <Form.TextInput name="email" label="Main Email" onChange={this.onChange} value={this.state.email} col="s4" flags={[flags.MAX_LENGTH + 60, flags.ALLOW_EMPTY]} />
            <Form.TextInput name="website" label="Website" onChange={this.onChange} value={this.state.website} col="s4" flags={[flags.MAX_LENGTH + 60, flags.ALLOW_EMPTY]} />
            <p className={styles.notes} />
          </div>
          <div className="row">
            <Form.CheckBox focusStyle="useAlternateSubjectLine" name="useAlternateSubjectLine" label="Use Alt Subject Line" value={this.state.useAlternateSubjectLine} onChange={this.onChange} col="s2" flags={[flags.ALLOW_EMPTY]} />
            <Form.CheckBox focusStyle="useAlternateSubjectLine" name="useIndividualVoyage" label="Use Individual Voyage" value={this.state.useIndividualVoyage} onChange={this.onChange} col="s2" flags={[flags.ALLOW_EMPTY]} />
            <Form.TextInput name="pointOfContact" label="Main Point Of Contact" value={this.state.pointOfContact} onChange={this.onChange} col="s3" flags={[flags.ALLOW_EMPTY]} />
            <Form.TextInput name="companyCode" label="Foreign Company Code" value={this.state.companyCode} onChange={this.onChange} col="s3" flags={[flags.ALLOW_EMPTY]} />
            <Form.TextInput name="taxIdNumber" label="U.S. Tax ID Number" value={this.state.taxIdNumber} onChange={this.onChange} col="s2" flags={[flags.ALLOW_EMPTY]} />
          </div>
          <div className="row">
            {/* <Form.Select name="requestsEmployee" label="Requests" value={this.state.requestsEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " /> */}
            <Form.Select name="bookingsEmployee" label="Bookings" value={this.state.bookingsEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " />
            <Form.Select name="submitSiEmployee" label="Submit SI" value={this.state.submitSiEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " />
            <Form.Select name="proofEmployee" label="Proof" value={this.state.proofEmployee} onChange={this.onChange} col="s3 offset-s9" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " />
          </div>
          <div className="row">
            <Form.Select name="closeoutEmployee" label="Closeout" value={this.state.closeoutEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " />
            {/* <Form.Select name="invoicingEmployee" label="Invoicing" value={this.state.invoicingEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " /> */}
            {/* <Form.Select name="bookKeepingEmployee" label="Book Keeping" value={this.state.bookKeepingEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " /> */}
            {/* <Form.Select name="contractsEmployee" label="Contracts" value={this.state.contractsEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " /> */}
          </div>
        </Form.Section>
        <Form.Section label="Contacts">
          <div className="row">
            <Form.TextInput col="col s3" label="Name" onChange={this.onChange} value={this.state.contactName} name="contactName" maxLength="30" flags={[flags.ALLOW_EMPTY]} />
            <Form.TextInput col="col s3" label="Email" onChange={this.onChange} value={this.state.contactEmail} name="contactEmail" maxLength="50" flags={[flags.ALLOW_EMPTY]} />
            <Form.Select col={parseInt(this.state.contactDefault) ? 'col s1' : 'col s2'} label='Default' onChange={this.onChange} value={this.state.contactDefault} name='contactDefault' list={[{ label: 'YES', value: 1 }, { label: "NO", value: 0 }]} filter={{ label: 'label', value: 'value' }} />
            {parseInt(this.state.contactDefault) ?
              <Form.Select col="col s1" label="Field" onChange={this.onChange} value={this.state.contactField} name="contactField" list={this.state.contactFieldOptions} filter={{ label: "label", value: "label" }} />
              :
              null}
            <Form.Select col="col s3" label="Assigned To" onChange={this.contactOnChange} value={this.state.contactRole} name="contactRole" list={[{ label: "BOOKINGS" }, { label: "P/L REMINDERS" }, { label: "DOCS" }, { label: "INVOICES" }]} filter={{ label: "label", value: "label" }} />
            <span id="addIcon" tabIndex="0" onClick={this.addContact}><i className="material-icons">add</i></span>
          </div>

          <div className="row" id="contactsRow">
            <div className="col s12">
              <ul className={`tabs`} id="tabs">
                <li className={`tab col s2`}><a tabIndex="-1" href="#BOOKINGS" onClick={() => this.changeTabFocus('BOOKINGS')}>Bookings</a></li>
                <li className={'tab col s2'}><a tabIndex="-1" href="#PLREMINDERS" onClick={() => this.changeTabFocus('P/L REMINDERS')}>P/L Reminders</a></li>
                <li className="tab col s2 disabled"><a tabIndex="-1" href="#PROOFS">Proofs</a></li>
                <li className="tab col s2"><a tabIndex="-1" href="#DOCS" onClick={() => this.changeTabFocus('DOCS')}>Docs</a></li>
                <li className="tab col s2"><a tabIndex="-1" href="#INVOICES" onClick={() => this.changeTabFocus('INVOICES')}>Invoices</a></li>
                <li className="tab col s2 disabled"><a tabIndex="-1" href="#CONTRACTS">Contracts</a></li>
              </ul>
            </div>
            <div id="BOOKINGS" className="col s12">
              {this.state.contacts.filter(c => c.role === "BOOKINGS").length ?
                <table>
                  <thead>
                    <tr className="contactsTableHead">
                      <td><b>Name</b></td>
                      <td><b>Email</b></td>
                      <td><b>Default</b></td>
                      <td><b>Field</b></td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.contacts.filter(c => c.role === "BOOKINGS").map((contact, index) => (
                      <tr className="contactsTable" key={index}>
                        <td>{contact.name}</td>
                        <td>{contact.email}</td>
                        <td>{contact.defaultEmail ? <i className="material-icons">check</i> : '-'}</td>
                        <td>{contact.field}</td>
                        <td className={styles.deleteIcon} onClick={() => this.removeContact(contact.id)}><i className="material-icons">cancel</i></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                :
                <div className={styles.noContactsPlaceholder}>No Current Bookings Contacts</div>
              }
            </div>
            <div id="PLREMINDERS" className="col s12">
              {this.state.contacts.filter(c => c.role === "P/L REMINDERS").length ?
                <table>
                  <thead>
                    <tr className="contactsTableHead">
                      <td><b>Name</b></td>
                      <td><b>Email</b></td>
                      <td><b>Default</b></td>
                      <td><b>Field</b></td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.contacts.filter(c => c.role === "P/L REMINDERS").map((contact, index) => (
                      <tr className="contactsTable" key={index}>
                        <td>{contact.name}</td>
                        <td>{contact.email}</td>
                        <td>{contact.defaultEmail ? <i className="material-icons">check</i> : '-'}</td>
                        <td>{contact.field}</td>
                        <td className={styles.deleteIcon} onClick={() => this.removeContact(contact.id)}><i className="material-icons">cancel</i></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                :
                <div className={styles.noContactsPlaceholder}>No Current P/L Reminder Contacts</div>
              }
            </div>
            <div id="PROOFS" className="col s12">
              {this.state.contacts.filter(c => c.role === "PROOFS").length ?
                <table>
                  <thead>
                    <tr className="contactsTableHead">
                      <td><b>Name</b></td>
                      <td><b>Email</b></td>
                      <td><b>Default</b></td>
                      <td><b>Field</b></td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.contacts.filter(c => c.role === "PROOFS").map((contact, index) => (
                      <tr className="contactsTable" key={index}>
                        <td>{contact.name}</td>
                        <td>{contact.email}</td>
                        <td>{contact.defaultEmail ? <i className="material-icons">check</i> : '-'}</td>
                        <td>{contact.field}</td>
                        <td className={styles.deleteIcon} onClick={() => this.removeContact(contact.id)}><i className="material-icons">cancel</i></td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                :
                <div className={styles.noContactsPlaceholder}>No Current Proofs Contacts</div>
              }
            </div>
            <div id="DOCS" className="col s12">
              {this.state.contacts.filter(c => c.role === "DOCS").length ?
                <table>
                  <thead>
                    <tr className="contactsTableHead">
                      <td><b>Name</b></td>
                      <td><b>Email</b></td>
                      <td><b>Default</b></td>
                      <td><b>Field</b></td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.contacts.filter(c => c.role === "DOCS").map((contact, index) => (
                      <tr className="contactsTable" key={index}>
                        <td>{contact.name}</td>
                        <td>{contact.email}</td>
                        <td>{contact.defaultEmail ? <i className="material-icons">check</i> : '-'}</td>
                        <td>{contact.field}</td>
                        <td className={styles.deleteIcon} onClick={() => this.removeContact(contact.id)}><i className="material-icons">cancel</i></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                :
                <div className={styles.noContactsPlaceholder}>No Current Docs Contacts</div>
              }
            </div>
            <div id="INVOICES" className="col s12">
              {this.state.contacts.filter(c => c.role === "INVOICES").length ?
                <table>
                  <thead>
                    <tr className="contactsTableHead">
                      <td><b>Name</b></td>
                      <td><b>Email</b></td>
                      <td><b>Default</b></td>
                      <td><b>Field</b></td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.contacts.filter(c => c.role === "INVOICES").map((contact, index) => (
                      <tr className="contactsTable" key={index}>
                        <td>{contact.name}</td>
                        <td>{contact.email}</td>
                        <td>{contact.defaultEmail ? <i className="material-icons">check</i> : '-'}</td>
                        <td>{contact.field}</td>
                        <td className={styles.deleteIcon} onClick={() => this.removeContact(contact.id)}><i className="material-icons">cancel</i></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                :
                <div className={styles.noContactsPlaceholder}>No Current Invoices Contacts</div>
              }
            </div>
            <div id="CONTRACTS" className="col s12">
              {this.state.contacts.filter(c => c.role === "CONTRACTS").length ?
                <table>
                  <thead>
                    <tr className="contactsTableHead">
                      <td><b>Name</b></td>
                      <td><b>Email</b></td>
                      <td><b>Default</b></td>
                      <td><b>Field</b></td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.contacts.filter(c => c.role === "CONTRACTS").map((contact, index) => (
                      <tr className="contactsTable" key={index}>
                        <td>{contact.name}</td>
                        <td>{contact.email}</td>
                        <td>{contact.defaultEmail ? <i className="material-icons">check</i> : '-'}</td>
                        <td>{contact.field}</td>
                        <td className={styles.deleteIcon} onClick={() => this.removeContact(contact.id)}><i className="material-icons">cancel</i></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                :
                <div className={styles.noContactsPlaceholder}>No Current Contracts Contacts</div>
              }
            </div>
          </div>
        </Form.Section>

        <div className="row">
          <Form.Section
            col="s6 addConnection"
            label="Add Connection"
            style={{ minHeight: "155px", maxHeight: "155px" }} >
            <div className="row">
              <table className="centered addConnectionTable" style={{ "maxHeight": "200px" }}>
                <thead>
                  <tr>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h5 className="currentParty">{this.state.name}</h5>
                    </td>
                    <td>
                      <Form.Select
                        name="consigneeChoice"
                        label="Available Consignees"
                        value={this.state.consigneeChoice}
                        onChange={this.onChange}
                        list={this.state.consignees}
                        filter={{ label: "name", value: "name" }}
                        disabled={this.state.conDisabled} />
                    </td>
                    <td>
                      <div className="s1">
                        <i className="material-icons right"
                          style={{
                            borderRadius: 40 + "px",
                            color: "white",
                            backgroundColor: "green",
                            marginTop: 5 + "px"
                          }} onClick={() => this.addConnection(1, this.state.consigneeChoice)}  >  add</i>
                      </div>
                    </td>
                  </tr>
                  <tr className="bottomRow">
                    <td>
                      <Form.Select
                        label="Available Shippers"
                        name="shipperChoice"
                        value={this.state.shipperChoice}
                        onChange={this.onChange}
                        list={this.state.shippers}
                        filter={{ label: "name", value: "name" }}
                        disabled={this.state.shipDisabled} />
                    </td>
                    <td>
                      <div className="centered s5 offset-s5" style={{ textAlign: "center" }}>
                        <h5 className="currentParty">{this.state.name}</h5>
                      </div>
                    </td>
                    <td>
                      <div className="s1">
                        <i className="material-icons right"
                          style={{
                            borderRadius: 40 + "px",
                            color: "white",
                            backgroundColor: "green",
                            marginTop: 5 + "px"
                          }} onClick={() => this.addConnection(2, this.state.shipperChoice)}  >  add</i>{" "}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Form.Section>

          <Form.Section col="s6 clientConnections" label="Client Connections" style={{ "maxHeight": "175px", "minHeight": "175px", "overflow": "auto" }}>
            <table className="centered">
              <thead>
                <tr style={{ backgroundColor: "#f3f3f3" }}>
                  <th>Shipper</th>
                  <th>Consignee</th>
                  <th>
                    <span></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.clientsAdded.map((client, index) => (
                  <tr className="connection" key={index}>
                    <td>{client.shipperId}</td>
                    <td>{client.consigneeId}</td>
                    <td>
                      <span>
                        <i onClick={() => this.removeConnection(index)} className="material-icons connection" style={{ marginTop: 3 + "px" }} > cancel</i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Form.Section>
        </div>

        <div className="row">
          <Form.Section label="Addresses" col="">
            <div className="row" style={{ paddingLeft: "50px" }}>
              <Card addresses={this.state.addresses} className="cardStyle" onChange={this.onChange} col="s2" color="green" showEditModal={this.showEditModal} deleteAddress={this.deleteAddress} />
              <Form.Button id="addbutton" className={styles.button} address="address" col="s2 right" onClick={this.showAddModal} color="green" type="outline" label="New Address" icon="add" />
            </div>
          </Form.Section>
        </div>
        <div className="row">
          <Form.Button id="submitButton" col="s2" className={styles.button} type="outline" onClick={this.submitCreate} label="Submit" icon="save" color="blue" />

          <Form.Button col="s2" className="" type="outline" color="red" onClick={this.clearForms} label="Clear" icon="cancel" />
        </div>



        {/* EDIT ADDRESS MODAL */}

        <div className="modal white" id="edit-address-modal">
          <div className="modal-content">
            <div className="row">
              <div className="col s10">
                <h5>Edit Address</h5>
              </div>
              <div className="col s2">
                <i className="material-icons right" style={{ color: "grey" }} onClick={this.hideEditModal} > close </i>
              </div>
            </div>
            <div className="row">
              <Form.TextInput col="s12" name="editAddress1" label="Address" onChange={this.editAddOnChange} value={this.state.editAddress.editAddress1} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.TextInput col="s12" name="editAddress2" label="Address Line 2" onChange={this.editAddOnChange} value={this.state.editAddress.editAddress2} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.Select col="s5" name="editCountry" label="Country" onChange={this.editAddOnChange} value={this.state.editAddress.editCountry} list={this.props.country} filter={{ label: "name", value: "id" }} />
              <Form.TextInput col="s3" name="editCity" label="City" onChange={this.editAddOnChange} value={this.state.editAddress.editCity} flags={[flags.ALLOW_EMPTY]} />
              <Form.TextInput col="s2" name="editState" label="State" onChange={this.editAddOnChange} value={this.state.editAddress.editState} flags={[flags.ALLOW_EMPTY]} />
              <Form.TextInput col="s2" name="editZip" label="Zip" onChange={this.editAddOnChange} value={this.state.editAddress.editZip} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.TextInput col="s12" name="editAddressLabel" label="Address Label" value={this.state.editAddress.editAddressLabel} onChange={this.editAddOnChange} maxLength="40" flags={[flags.ALLOW_EMPTY]} />
            </div>

            <div className="row">
              <Form.TextArea name="editPrintable" label="Printable Address" value={this.state.editAddress.editPrintable} onChange={this.editAddOnChange} className={`${styles.textArea}`} flags={[flags.ALLOW_EMPTY]} />
            </div>
          </div>
          <div className={`row modal-action ${styles.modalaction}`}>
            <Form.Button col="s3" label="Save" type="outline" round color="blue" icon="save" value={this.state.editIndex} onClick={this.saveEdit} />
          </div>
        </div>

        {/* NEW ADDRESS MODAL */}

        <div className="modal white" id="add-address-modal">
          <div className="modal-content">
            <div className="row">
              <div className="col s10">
                <h5>New Address</h5>
              </div>
              <div className="col s2">
                <i className="material-icons right" style={{ color: "grey" }} onClick={this.hideAddModal}> close</i>
              </div>
            </div>
            <div className="row">
              <Form.TextInput col="s12" name="address1" label="Address" onChange={this.newAddOnChange} value={this.state.newAddress.address1} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.TextInput col="s12" name="address2" label="Address Line 2" onChange={this.newAddOnChange} value={this.state.newAddress.address2} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.Select col="s5" name="country" label="Country" onChange={this.newAddOnChange} value={this.state.newAddress.country} list={this.props.country} filter={{ label: "name", value: "id" }} />
              <Form.TextInput col="s3" name="city" label="City" onChange={this.newAddOnChange} value={this.state.newAddress.city} flags={[flags.ALLOW_EMPTY]} />
              <Form.TextInput col="s2" name="state" label="State" onChange={this.newAddOnChange} value={this.state.newAddress.state} flags={[flags.ALLOW_EMPTY]} />
              <Form.TextInput col="s2" name="zip" label="Zip" onChange={this.newAddOnChange} value={this.state.newAddress.zip} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.TextInput col="s12" name="addressLabel" label="Address Label" value={this.state.newAddress.addressLabel} onChange={this.newAddOnChange} flags={[flags.ALLOW_EMPTY]} />
            </div>

            <div className="row">
              <Form.TextArea name="printable" label="Printable Address" value={this.state.newAddress.printable} onChange={this.newAddOnChange} className={`${styles.textArea}`} maxLength="40" flags={[flags.ALLOW_EMPTY]} />
            </div>
          </div>
          <div className={`row modal-action ${styles.modalaction}`}>
            <Form.Button col="s3" label="Save" type="outline" round color="blue" icon="save" onClick={this.saveAddress} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { country, urls, index, portalUser } = state;
  const url = urls[index];
  return { country, url, index, portalUser };
};

export default connect(mapStateToProps)(withRouter(CreateParty));
