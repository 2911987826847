import Axios from "axios";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { auth, logout } from "../../../helperFunctions";
import { Modal } from "materialize-css";
import moment from "moment";
import { useDispatch } from "react-redux";
import { globalToastActions } from "../../../Redux/actions";
import md5 from "md5";
import { useNavigate } from "react-router-dom";
import VesselVoyage from './VesselVoyage';
import EmailModal from "../../../newComponents/EmailModal/EmailModal";
import BookingNumbersModal from './BookingNumbersModal';
import EventList from "./EventList";
const componentId = '_' + crypto.randomUUID();


const BookingTab = props => {
    const {
        getBooking,
        booking,
        carrierList,
        partyList,
        containerTypeList,
        locationList,
        employeeList,
    } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initValues = () => ({
        bookingNote: booking?.booking.bookingNote || '',
        isDummy: booking.booking.isDummy || false,
        carrierId: booking.booking?.vesselVoyage?.carrier?.id || 0,
        shipperId: booking.booking.shipper.id || 0,
        displayNameId: booking.booking.displayNameId || 0,
        bookingNumber: booking.booking.bookingNumber || '',
        shipperReferenceNumber: booking.booking.shipperReferenceNumber || '',
        containerCount: booking.booking.containerCount || '',
        actualContainerCount: booking.booking.actualContainerCount || '',
        containerTypeId: booking.booking.containerTypeId || 0,
        consigneeId: booking.booking.consigneeId || 0,
        productPackaging: booking.booking.productPackaging || '',
        internalNote: booking.booking.internalNote || '',
        assignedToId: booking.booking.assignedToId || 0,
        subscribeToNotifications: booking.booking.subscribeToNotifications,
        additionalNotes: booking.booking.additionalNotes || '',
        status: booking.booking.status,
    })

    const ref = useRef({ abortController: new AbortController(), vesselVoyage: null, carrierList, values })
    const [values, setValues] = useState({ ...initValues(), originalValues: initValues() });
    const [consigneeList, setConsigneeList] = useState(null);
    const [loadingConsignees, setLoadingConsignees] = useState(true);
    const [showAdditionalNotes, setShowAdditionalNotes] = useState(booking.booking.additionalNotes ? true : false);
    const [saving, setSaving] = useState(false);
    const [vesselVoyage, setVesselVoyage] = useState(null);
    const [showStatusMenu, setShowStatusMenu] = useState(false);
    const [recentEvents, setRecentEvents] = useState(null);


    useEffect(() => {
        return () => ref.current.abortController?.abort()
    }, [])

    useLayoutEffect(() => {
        const clickAwayListener = ({ target }) => {
            const statusMenu = document.querySelector(`#status-menu-${componentId}`);
            if (!statusMenu.contains(target))
                setShowStatusMenu(false);
        }

        const onKeyDown = e => {
            const { ctrlKey, keyCode } = e;
            const activeElement = document.activeElement;
            const { vesselVoyage: { vessel: { vesselId } } } = ref.current;

            if (keyCode === 9 && activeElement.id === 'vesselName') {
                e.preventDefault();

                if (!vesselId)
                    document.querySelector(`#add-vessel-button-on-booking-update-page`).focus();
                else
                    document.querySelector('#voyageNumber')?.focus();
            }




            if (ctrlKey && keyCode === 83) {
                e.preventDefault();
                saveBooking();
            }
        }


        document.addEventListener('click', clickAwayListener);
        document.addEventListener('keydown', onKeyDown);

        document.querySelector(`#bookingNote`)?.focus();

        return () => {
            document.removeEventListener('click', clickAwayListener);
            document.removeEventListener('keydown', onKeyDown);
        }
    }, [])

    const onChange = ({ target: { id, value, type } }) => setValues(p => ({ ...p, [id]: type === 'checkbox' ? !p[id] : typeof value === 'string' ? value.toUpperCase() : value }))


    const getConsigneesByShipper = () => {
        setLoadingConsignees(true);
        ref.current.abortController?.abort();
        ref.current.abortController = new AbortController();


        Axios.get('/api/v2/party/read/consignees/by/shipperid', {
            params: {
                ...auth.getAuthData(),
                shipperId: values.shipperId
            },
            signal: ref.current.abortController.signal
        })
            .then(result => setConsigneeList(result.data))
            .catch(logout)
            .finally(() => setLoadingConsignees(false))
    }

    useEffect(getConsigneesByShipper, [values.shipperId]);

    const showBookingNumbersModal = e => {
        e.preventDefault();
        window.alert('Please remember to update AES!');
        Modal.getInstance(document.querySelector(`#booking-numbers-modal-${componentId}`))?.open();
    }

    const preSubmissionChecks = () => {
        const {
            cargoCutDate,
            cargoCutTime,
            docCutDate,
            docCutTime,
            erd,
            eta,
            etd,
            portOfLoadId,
            portOfDischargeId,
            placeOfDeliveryId,
            vesselId,
            returnLocationId,
            originalValues: vvOriginalValues
        } = ref.current.vesselVoyage;

        const {
            shipperId,
            consigneeId,
            carrierId,
            productPackaging,
            originalValues: bOriginalValues
        } = values;

        const { shippingInstructions } = booking;

        const cargoDatetime = moment(cargoCutDate + cargoCutTime, 'MM/DD/YYHH:mm');
        const docDatetime = moment(docCutDate + docCutTime, 'MM/DD/YYHH:mm');
        const erdDate = erd ? moment(erd, 'MM/DD/YY') : null;
        const etaDate = eta ? moment(eta, 'MM/DD/YY') : null;
        const etdDate = etd ? moment(etd, 'MM/DD/YY') : null;


        if (Math.abs(docDatetime.diff(cargoDatetime, 'h')) > 240
            && !window.confirm('The time between the entered Doc Cut and the Cargo Cut is greater than 10 days. Please make sure the dates are correct and you have entered the correct year\n\nClick OK to continue and save, or click Cancel to go back')
        ) return false;

        if (erdDate?.diff(cargoDatetime, 'h') < -120
            && !window.confirm('The time between the entered ERD and the Cargo Cut is greater than 5 days. Please make sure the dates are correct, and you have entered the correct year\n\nClick OK to continue and save, or click Cancel to go back')
        ) return false;


        if (erdDate?.diff(cargoDatetime, 'h') > 0
            && !window.confirm('The entered ERD occurs after the entered Cargo Cut Date. Please make sure the dates are correct, and you hvae entered the correct year.\n\nClick OK to continue and save, or click Cancel to go back')
        ) return false

        if ((
            vvOriginalValues.cargoCutDate !== cargoCutDate
            || vvOriginalValues.cargoCutTime !== cargoCutTime
            || vvOriginalValues.docCutDate !== docCutDate
            || vvOriginalValues.docCutTime !== docCutTime
            || vvOriginalValues.eta !== eta
            || vvOriginalValues.etd !== etd
            || vvOriginalValues.returnLocationId !== returnLocationId
        ) && vvOriginalValues.erd === erd
            && erd
            && vvOriginalValues.erd
            && !window.confirm('PLEASE CONFIRM if ERD needs to be changed too!\n\nClick OK to continue and save, or click Cancel to go back')
        ) return false


        if (shippingInstructions && (
            bOriginalValues.shipperId !== shipperId
            || bOriginalValues.consigneeId !== consigneeId
            || bOriginalValues.carrierId !== carrierId
            || bOriginalValues.productPackaging !== productPackaging
            || vvOriginalValues.portOfLoadId !== portOfLoadId
            || vvOriginalValues.portOfDischargeId !== portOfDischargeId
            || vvOriginalValues.placeOfDeliveryId !== placeOfDeliveryId
            || vvOriginalValues.vesselId !== vesselId
            || (vvOriginalValues.eta && !eta)
            || (eta && !vvOriginalValues.eta)
            || Math.abs(moment(eta, 'MM/DD/YY').diff(moment(vvOriginalValues.eta, 'MM/DD/YY'), 'd') > 5)
        ))
            window.alert('Please remember to update AES');

        let outlyingDates = [];

        [
            { d: cargoDatetime, label: 'Cargo Cut' },
            { d: docDatetime, label: 'Doc Cut' },
            { d: etaDate, label: 'ETA' },
            { d: erdDate, label: 'ERD' },
            { d: etdDate, label: 'ETD' }
        ].forEach(row => {
            const diff = row.d ? row.d.diff(moment(), 'd') : 0;
            if (Math.abs(diff) > 1825)
                outlyingDates.push(`${row.label}: ${(diff / 365).toFixed(1)} yrs`)
        })

        if (outlyingDates.length && !window.confirm(`The Following Dates are more than 5 years from today:\n\n${outlyingDates.join('\n')}.\n\nClick OK to continue and save, or click Cancel to go back`))
            return false

        return true;
    }

    const saveBooking = e => {
        e?.preventDefault();

        if (!preSubmissionChecks()) { return }
        setSaving(true);

        const { vesselVoyage } = ref.current;

        if (!vesselVoyage.vessel.vesselId)
            return dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Vessel Selected', type: 'error' } })


        Axios.post('/api/v2/booking/update/one', {
            ...auth.getAuthData(),
            bookingId: booking.booking.id || 0,
            serviceRoute: vesselVoyage.serviceRoute || '',
            vesselId: vesselVoyage.vesselId || 0,
            voyageNumber: vesselVoyage.voyageNumber || '',
            carrierId: values.carrierId || 0,
            shipperId: values.shipperId || 0,
            placeOfReceiptId: vesselVoyage.placeOfReceiptId || 0,
            portOfLoadId: vesselVoyage.portOfLoadId || 0,
            portOfDischargeId: vesselVoyage.portOfDischargeId || 0,
            placeOfDeliveryId: vesselVoyage.placeOfDeliveryId || 0,
            cargoCutDate: vesselVoyage.cargoCutDate || '',
            cargoCutTime: vesselVoyage.cargoCutTime || '',
            docCutDate: vesselVoyage.docCutDate || '',
            docCutTime: vesselVoyage.docCutTime || '',
            etd: vesselVoyage.etd || '',
            eta: vesselVoyage.eta || '',
            erd: vesselVoyage.erd || '',
            displayNameId: values.displayNameId || null,
            supplierId: values.supplierId || null,
            consigneeId: values.consigneeId || null,
            pickupLocationId: vesselVoyage.pickupLocationId || null,
            returnLocationId: vesselVoyage.returnLocationId || null,
            shipperReferenceNumber: values.shipperReferenceNumber || '',
            bookingNote: values.bookingNote || '',
            isDummy: values.isDummy,
            internalNote: values.internalNote || '',
            containerCount: values.containerCount || 0,
            actualContainerCount: values.actualContainerCount || null,
            containerTypeId: values.containerTypeId || 0,
            productPackaging: values.productPackaging || '',
            subscribeToNotifications: values.subscribeToNotifications || false,
            assignedToId: values.assignedToId || 0,
            status: values.status,
            additionalNotes: values.additionalNotes || ''
        })
            .then(result => {
                dispatch(result.data);
                getBooking();
            })
            .catch(logout)
            .finally(() => setSaving(false))
    }

    const getStatusBadge = status => {
        switch (status) {
            case 'ACTIVE':
                return <span style={{ border: '1px solid #4CAF50 ' }} className="green-text badge">ACTV</span>
            case 'CANCELLED':
                return <span style={{ border: '1px solid #F44336 ' }} className="red-text badge">CNCL</span>
            case 'CLOSED':
                return <span style={{ border: '1px solid #757575' }} className="grey-text text-darken-1 badge">CLSD</span>
            case 'ARCHIVED':
                return <span style={{ border: '1px solid #000000' }} className="black-text badge">ACVD</span>
            default:
                return;
        }
    }

    const getStageIcon = booking => {
        if (booking.invoice?.id)
            return <span className={`material-icons ${values.isDummy ? 'purple-text' : 'green-text'}`}>attach_money</span>
        else if (booking.shippingInstructions?.datetimePrinted)
            if (booking.shippingInstructions.isDummy || !booking.containers?.filter(c => c.containerNumber).length)
                return <span className="material-icons">assignment_ind</span>
            else
                return <span className="material-icons">assignment_turned_in</span>
        else if (moment().isAfter(moment(booking.booking.vesselVoyage.docCutDate).local()))
            return <span className={`material-icons ${values.isDummy ? 'purple-text' : 'red-text'}`}>assignment_late</span>
        else if (moment().isSame(moment(booking.booking.vesselVoyage.docCutDate).local(), "d"))
            return <span className="material-icons">history</span>
        else
            return null
    }

    const changeStatus = e => {
        e?.preventDefault();
        setValues(p => ({ ...p, status: e.target.getAttribute('data-value') }))
        setShowStatusMenu(false);
    }

    useEffect(() => {
        if (parseInt(values.shipperId) !== parseInt(values.originalValues.shipperId))
            setValues(p => ({ ...p, shipperReferenceNumber: '', consigneeId: 0 }))
    }, [values.shipperId])

    useEffect(() => {
        ref.current.vesselVoyage = vesselVoyage;
        ref.current.values = values;
        ref.current.carrierList = carrierList;
    }, [vesselVoyage, values, carrierList])

    const goToVesselVoyageOverview = e => {
        e?.preventDefault();
        const { values, vesselVoyage, carrierList } = ref.current;

        const urlParams = {
            queueSearch: false,
            bookingNumber: '',
            shipperReferenceNumber: '',
            consigneeName: '',
            lNumber: '',
            billOfLadingNumber: '',
            pickupName: '',
            returnName: '',
            destinationCountryName: '',
            dateType: 'CUT',
            portOfLoad: '',
            startDate: '',
            portOfLoadName: '',
            vesselName: vesselVoyage.vessel.vesselName,
            voyageNumber: vesselVoyage.voyageNumber,
            serviceRoute: '',
            status: 'ACTIVE',
            dummyType: 'ALL',
            shipperName: '',
            carrierName: carrierList.find(c => c.id === parseInt(values.carrierId))?.code,
            originName: '',
            destinationName: '',
        }

        const data = JSON.stringify({ ...urlParams, selectedLNumber: booking.booking.lNumber });
        const md5Hash = md5(data)

        Axios.post('/api/v2/url/create/one/hashedurl', {
            ...auth.getAuthData(),
            data,
            md5Hash
        })
            .then(result => {
                navigate(`/new/shipmentoverview/${md5Hash}`)
            })
            .catch(logout)
    }

    return (
        <div>
            <div className="row" style={{ marginBottom: '0px' }}>
                <div className="col s9">
                    <div className="row" style={{ marginBottom: '4px' }}>
                        <div className="lf-input col s9 l10">
                            <input id="bookingNote" className="browser-default" type="text" onChange={onChange} value={values.bookingNote} />
                            <label htmlFor="bookingNote">Booking Note</label>
                        </div>
                        <div className="lf-input col s3 l2">
                            <div>
                                <p>
                                    <label>
                                        <input id="isDummy" type="checkbox" className={`checkbox filled-in ${values.isDummy ? 'dummy' : ''}`} onChange={onChange} checked={values.isDummy} />
                                        <span>Dummy</span>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginBottom: '4px' }}>
                        <div className="lf-input col s3">
                            <select id="carrierId" className="browser-default" value={values.carrierId} onChange={onChange}>
                                {carrierList?.map(c => <option key={c.id} value={c.id}>{c.code}</option>)}
                            </select>
                            <label htmlFor="carrierId">Carrier</label>
                        </div>
                        <div className={`lf-input col s5 ${values.isDummy ? 'dummy' : ''}`}>
                            <select id="shipperId" className="browser-default" value={values.shipperId} onChange={onChange}>
                                {partyList?.filter(p => p.role.toUpperCase() === 'SHIPPER' || p.role.toUpperCase() === 'BOTH').map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                            </select>
                            <label htmlFor="shipperId">Shipper</label>
                        </div>
                        <div className="lf-input col s4">
                            <select id="displayNameId" className="browser-default" value={values.displayNameId} onChange={onChange}>
                                <option value={0}>None</option>
                                {partyList?.filter(p => p.id !== parseInt(values.shipperId) & ['SHIPPER', 'BOTH'].indexOf(p.role?.toUpperCase()) !== -1).map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                            </select>
                            <label htmlFor="displayNameId">Display Name</label>
                        </div>
                    </div>
                    <div className="row" style={{ marginBottom: '4px' }}>
                        <div className="col s5" />
                        <div className="lf-input col s7">
                            <select id="supplierId" className="browser-default" value={values.supplierId} onChange={onChange}>
                                <option value={0}>Same As Shipper</option>
                                {partyList?.filter(p => p.id !== parseInt(values.shipperId)).map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                            </select>
                            <label htmlFor="supplierId">Supplier</label>
                        </div>
                    </div>
                    <div className="row" style={{ marginBottom: '4px' }}>
                        <div className="col s6">
                            <div className="col s10">
                                <div className="left" style={{ width: 'auto', position: 'relative', top: '3px' }}>{getStageIcon(booking)}</div>
                                <div
                                    className="left"
                                    style={{ marginRight: '8px', position: 'relative', top: '3px' }}
                                    onContextMenu={e => { e.preventDefault(); setShowStatusMenu(true) }}
                                >{getStatusBadge(values.status)}
                                    <ul
                                        id={`status-menu-${componentId}`}
                                        className="collection"
                                        style={{
                                            display: showStatusMenu ? 'block' : 'none',
                                            position: 'absolute',
                                            zIndex: '1000',
                                            minWidth: '120px',
                                            height: 'auto',
                                            top: '24px',
                                            left: '14px',
                                            border: '1px solid black',
                                            boxShadow: '2px 2px 3px #777'
                                        }}
                                    >
                                        <li style={{ cursor: 'pointer' }} className="lolight-on-hover collection-item green-text" data-value="ACTIVE" onClick={changeStatus}>ACTIVE</li>
                                        <li style={{ cursor: 'pointer' }} className="lolight-on-hover collection-item grey-text" data-value="CLOSED" onClick={changeStatus}>CLOSED</li>
                                        <li style={{ cursor: 'pointer' }} className="lolight-on-hover collection-item red-text" data-value="CANCELLED" onClick={changeStatus}>CANCELLED</li>
                                        <li style={{ cursor: 'pointer' }} className="lolight-on-hover collection-item black-text" data-value="ARCHIVED" onClick={changeStatus}>ARCHIVED</li>
                                    </ul>
                                </div>
                                {booking.booking.bookingNumbers?.filter(bn => bn.isDisplayed).map((bn, index) => (
                                    <b
                                        onClick={() => {
                                            navigator.clipboard.writeText(bn.bookingNumber);
                                            dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `${bn.bookingNumber} copied to clipboard`, class: 'info' } })
                                        }}
                                        key={bn.id}
                                        style={{ fontSize: '1.15em' }}
                                        className={values.isDummy ? 'purple-text' : ''}
                                    >{bn.bookingNumber} {index !== booking.booking.bookingNumbers.filter(bn => bn.isDisplayed).length - 1 && booking.booking.bookingNumbers.filter(bn => bn.isDisplayed).length > 1 ? '/' : ''}</b>
                                ))}
                            </div>
                            <a
                                style={{
                                    maxHeight: '20px',
                                    lineHeight: '18px',
                                    border: '1px solid #2196f3',
                                    padding: '0px 4px'
                                }}
                                className="btn-small col s2 white blue-text waves-effect waves-dark"
                                href="/"
                                onClick={showBookingNumbersModal}
                            >Edit</a>
                        </div>
                        <div className="lf-input col s6">
                            <input id="shipperReferenceNumber" className="browser-default" type="text" value={values.shipperReferenceNumber} onChange={onChange} />
                            <label htmlFor="shipperReferenceNumber">Shipper Ref #</label>
                        </div>
                    </div>
                    <div className="row" style={{ marginBottom: '4px' }}>
                        <div className="lf-input col s2">
                            <input id="containerCount" className="browser-default" type="number" value={values.containerCount} onChange={onChange} />
                            <label htmlFor="containerCount">Count</label>
                            <span style={{ fontSize: '.8em', position: 'relative', left: '2px', bottom: '4px' }}>Entered: {booking?.containers?.length || 0}</span>
                        </div>
                        <div className="lf-input col s2">
                            <input id="actualContainerCount" className="browser-default" type="number" value={values.actualContainerCount} onChange={onChange} />
                            <label htmlFor="containerCount">Actual Count</label>
                        </div>
                        <div className="lf-input col s2">
                            <select id="containerTypeId" className="browser-default" value={values.containerTypeId} onChange={onChange}>
                                <option value={0}></option>
                                {containerTypeList?.map(ct => <option key={ct.id} value={ct.id}>{ct.code}</option>)}
                            </select>
                            <label htmlFor="containerTypeId">Type</label>
                        </div>
                        <div className="lf-input col s3">
                            <select id="consigneeId" className="browser-default" value={values.consigneeId} onChange={onChange} disabled={loadingConsignees}>
                                {loadingConsignees ?
                                    <option>Loading</option>
                                    :
                                    <>
                                        <option value={0}></option>
                                        {consigneeList?.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                    </>
                                }
                            </select>
                            <label htmlFor="consigneeId">Consignee</label>
                        </div>
                        <div className="lf-input col s3">
                            <input id="productPackaging" className="browser-default" type="text" value={values.productPackaging} onChange={onChange} />
                            <label htmlFor="productPackaging">Prod/Pkg</label>
                        </div>
                    </div>
                    <VesselVoyage
                        booking={booking}
                        locationList={locationList}
                        carrierId={values.carrierId}
                        setVesselVoyage={setVesselVoyage}
                        shipperId={values.shipperId}

                    />
                </div>
                <div className="col s3">
                    <div className="row" style={{ marginBottom: '4px' }}>
                        <div className="lf-input col s12">
                            <textarea style={{ height: '240px' }} id="internalNote" className="browser-default" value={values.internalNote} onChange={onChange} />
                            <label htmlFor="internalNote">Internal Notes</label>
                        </div>
                    </div>
                    <EventList booking={booking.booking} />
                    <div className="row" style={{marginTop: '8px', fontWeight: '500'}}>
                        <p className="center">{booking.booking.vesselVoyage.bookingsCount} Booking{booking.booking.vesselVoyage.bookingsCount > 1 ? 's' : ''} on this VVID</p>
                    </div>
                    <div className="row">
                        <div className="lf-input col s12">
                            <select id="assignedToId" className="browser-default" value={values.assignedToId} onChange={onChange}>
                                <option value={0}>None</option>
                                {employeeList?.map(em => (<option key={em.id} value={em.id}>{em.firstName} {em.lastName}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div>
                            <p>
                                <label>
                                    <input id="subscribeToNotifications" type="checkbox" className="checkbox filled-in" onChange={onChange} checked={values.subscribeToNotifications} />
                                    <span>Subscribe To Notifications</span>
                                </label>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '0px' }}>
                    <div className="col s12">
                        <i
                            className="material-icons"
                            style={{
                                width: '20px',
                                marginRight: '2px',
                                position: 'relative',
                                top: '2px',
                                fontSize: '20px'
                            }}
                            onClick={() => setShowAdditionalNotes(p => !p)}
                        >
                            {`keyboard_arrow_${showAdditionalNotes ? 'up' : 'down'}_icon`}
                        </i>
                        <span>Additional Notes</span>
                    </div>
                    <div className="lf-input col s12"
                        style={showAdditionalNotes ? null : {
                            border: 'none',
                            height: '0px',
                            overflow: 'hidden',
                            margin: '0px',
                            padding: '0px',
                        }}
                    >
                        <textarea id="additionalNotes" value={values.additionalNotes} onChange={onChange} />
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '0px', marginTop: '20px' }}>
                    <a href="/" className="col s2 offset-s1" onClick={e => saveBooking(e, false, false)}>Save Booking</a>
                    <a href="/" className="col s2">Save & Email</a>
                    <a href="/" className="col s2">Save & Print</a>
                    <a href="/" className="col s2">Change Log</a>
                    <a href="/" className="col s2" onClick={goToVesselVoyageOverview}>Vessel Voyage Overview</a>
                </div>
            </div>
            <BookingNumbersModal
                componentId={componentId}
                booking={booking}
                getBooking={getBooking}
            />
            <EmailModal
                openOnCtrlM={true}
            />
        </div >
    )
}

export default BookingTab;