import React, { Component } from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import { auth } from "../../helperFunctions";
import Form from "../../Components/NewComponents";
import styles from "./Forecast.module.css";
import { globalToastActions } from '../../Redux/actions';
import { flags } from "../../constants";
import moment from "moment-timezone";

class StandingListModal extends Component {
    state = {
        assignedTo: this.props.user.id,
        shipperId: this.props.shipper[0].id,
        carrierId: this.props.carrier[0].id,
        containerCount: undefined,
        containerTypeId: 1,
        originId: 0,
        destId: 0,
        selectedItems: [],
        editRowIndex: undefined,
        editItem: {
        }
    }

    onChange = (name, value) => {
        return this.setState({ [name]: value });
    }

    editItemOnChange = (name, value) => {
        return this.setState({ editItem: { ...this.state.editItem, [name]: value } });
    }

    selectedItemOnChange = (id) => {
        let { selectedItems } = this.state;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter(i => i !== id);
            return this.setState({ selectedItems });
        } else {
            selectedItems.push(id);
            return this.setState({ selectedItems });
        }
    }

    add = () => {
        let standingListItem = {
            assignedTo: this.state.assignedTo,
            shipperId: this.state.shipperId,
            carrierId: this.state.carrierId,
            containerCount: this.state.containerCount,
            containerTypeId: this.state.containerTypeId,
            originId: this.state.originId,
            destId: this.state.destId,
            serviceRoute: this.state.serviceRoute
        }
        let field;

        if (!this.state.assignedTo || !this.state.shipperId || !this.state.carrierId || !this.state.containerCount || !this.state.containerTypeId || !this.state.originId || !this.state.destId) {
            switch (Object.entries(standingListItem).filter(([key, value]) => !value)[0][0]) {
                case "assignedTo":
                    field = "Assignee";
                    break;
                case "shipperId":
                    field = "Shipper";
                    break;
                case "carrierId":
                    field = "Carrier";
                    break;
                case "containerCount":
                    field = "Container Count";
                    break;
                case "containerTypeId":
                    field = "Container Type";
                    break;
                case "originId":
                    field = "Origin";
                    break;
                case "destId":
                    field = "Destination";
                    break;
                default:
                    field = "";
                    break;
            }
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Can't Add Without ${field}`, type: "error" } });
        }

        for (let i = 0; i < Object.entries(standingListItem).length; i++) {
            if (typeof Object.values(standingListItem)[i] === "string") {
                if (Object.values(standingListItem)[i].trim()) {
                    standingListItem[Object.keys(standingListItem)[i]] = Object.values(standingListItem)[i].toUpperCase();
                } else {
                    standingListItem[Object.keys(standingListItem)[i]] = null;
                }
            }
        }

        axios.post("/api/v1/forecast/standing", { ...auth.getAuthData(), standingListItem }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {

                this.props.getStandingList();
                let selectedItems = this.state.selectedItems;
                selectedItems.push(result.data.insertId);

                this.setState({ selectedItems })
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Item Added", type: "success" } });
            }
        });
    }

    generateRequests = () => {
        if (this.props.week < moment().isoWeek()) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Cannot Generate For Previous Weeks", type: "error" } });
        } else if (!this.props.standingList.length) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Cannot Generate Without List", type: "error" } });
        } else if (!this.state.selectedItems.length) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Items Selected To Generate", type: "error" } });
        } else {
            axios.post("/api/v1/forecast/generate", { ...auth.getAuthData(), standingListIds: this.state.selectedItems, week: this.props.filters.week }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    return this.props.dispatch({ type: "SCORCHED_EARTH" });
                } else {
                    this.props.closeModal();
                    this.props.getWeekListAndStandingList();
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `${this.state.selectedItems.length} Request(s) Created`, type: "success" } })
                }
            });
        }
    }

    selectAll = () => {
        return this.setState({ selectedItems: this.props.standingList.map(l => l.id) });
    }

    unselectAll = () => {
        return this.setState({ selectedItems: [] });
    }

    deleteStandingListItem = (id) => {
        if (typeof id !== "object") {
            let idList = [id];
            axios.get("/api/v1/forecast/standing/delete", { params: { ...auth.getAuthData(), idList } }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    return this.props.dispatch({ type: "SCORCHED_EARTH" });
                } else {
                    this.setState({ selectedItems: [] }, () => {
                        return this.props.getStandingList();
                    });
                }
            })
        } else {
            if (window.confirm("All selected items will be deleted. Are you sure you would like to continue?")) {
                let idList = this.state.selectedItems
                axios.get("/api/v1/forecast/standing/delete", { params: { ...auth.getAuthData(), idList } }).then(result => {
                    if (result.data === "NOT AUTHENTICATED") {
                        localStorage.clear();
                        return this.props.dispatch({ type: "SCORCHED_EARTH" });
                    } else {
                        this.setState({ selectedItems: [] }, () => {
                            return this.props.getStandingList();
                        });
                    }
                })
            } else {
                return;
            }
        }
    }

    makeActive = (index) => {
        let r = this.props.standingList[index];

        this.setState({
            editRowIndex: index, editItem: {
                ...this.state.editItem,
                ...r
            }
        }, () => {
            return document.querySelector(".standingListAssignedTo > select").focus();
        })
    }

    makeInactiveAndSave = () => {
        let { editItem } = this.state;

        axios.post("/api/v1/forecast/standing/edit", { ...auth.getAuthData(), editItem }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.setState({ editRowIndex: undefined }, () => {
                    this.props.getStandingList();
                })
            }
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.origins !== this.props.origins && this.props.origins.length) {
            this.setState({ originId: this.props.origins[0].id });
        }
        if (prevProps.destinations !== this.props.destinations && this.props.destinations.length) {
            this.setState({ destId: this.props.destinations[0].id });
        }
    }

    componentDidMount = () => {
        return;
    }


    render = () => {
        return (
            <div id="standingListModal" className={`modal ${styles.standingListModal}`}>
                <div className="modal-content">

                    <table className={styles.standingListTable}>
                        <thead>
                            <tr className="tableHeaderLeftAlign">
                                <th></th>
                                <th data-sort="assignedTo" onClick={(e) => this.props.standingListSort(e)}>Assn. &nbsp;
                                    <span>{this.props.standingListFilters.currentSort === "assignedTo" ? this.props.standingListFilters.reverse === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightblue" }}>{this.props.standingListFilters.currentSort2 === "assignedTo" ? this.props.standingListFilters.reverse2 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightcoral" }}>{this.props.standingListFilters.currentSort3 === "assignedTo" ? this.props.standingListFilters.reverse3 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="shipperName" onClick={(e) => this.props.standingListSort(e)}>Shipper &nbsp;
                                    <span>{this.props.standingListFilters.currentSort === "shipperName" ? this.props.standingListFilters.reverse === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightblue" }}>{this.props.standingListFilters.currentSort2 === "shipperName" ? this.props.standingListFilters.reverse2 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightcoral" }}>{this.props.standingListFilters.currentSort3 === "shipperName" ? this.props.standingListFilters.reverse3 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="carrierName" onClick={(e) => this.props.standingListSort(e)}>Carrier &nbsp;
                                    <span>{this.props.standingListFilters.currentSort === "carrierName" ? this.props.standingListFilters.reverse === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightblue" }}>{this.props.standingListFilters.currentSort2 === "carrierName" ? this.props.standingListFilters.reverse2 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightcoral" }}>{this.props.standingListFilters.currentSort3 === "carrierName" ? this.props.standingListFilters.reverse3 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="containerCount" onClick={(e) => this.props.standingListSort(e)}>Cont. &nbsp;
                                    <span>{this.props.standingListFilters.currentSort === "containerCount" ? this.props.standingListFilters.reverse === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightblue" }}>{this.props.standingListFilters.currentSort2 === "containerCount" ? this.props.standingListFilters.reverse2 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightcoral" }}>{this.props.standingListFilters.currentSort3 === "containerCount" ? this.props.standingListFilters.reverse3 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="originName" onClick={(e) => this.props.standingListSort(e)}>Origin &nbsp;
                                    <span>{this.props.standingListFilters.currentSort === "originName" ? this.props.standingListFilters.reverse === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightblue" }}>{this.props.standingListFilters.currentSort2 === "originName" ? this.props.standingListFilters.reverse2 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightcoral" }}>{this.props.standingListFilters.currentSort3 === "originName" ? this.props.standingListFilters.reverse3 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="destName" onClick={(e) => this.props.standingListSort(e)}>Dest &nbsp;
                                    <span>{this.props.standingListFilters.currentSort === "destName" ? this.props.standingListFilters.reverse === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightblue" }}>{this.props.standingListFilters.currentSort2 === "destName" ? this.props.standingListFilters.reverse2 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightcoral" }}>{this.props.standingListFilters.currentSort3 === "destName" ? this.props.standingListFilters.reverse3 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="serviceRoute" onClick={(e) => this.props.standingListSort(e)}>Service &nbsp;
                                    <span>{this.props.standingListFilters.currentSort === "serviceRoute" ? this.props.standingListFilters.reverse === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightblue" }}>{this.props.standingListFilters.currentSort2 === "serviceRoute" ? this.props.standingListFilters.reverse2 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightcoral" }}>{this.props.standingListFilters.currentSort3 === "serviceRoute" ? this.props.standingListFilters.reverse3 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th data-sort="lastGeneratedBy" onClick={(e) => this.props.standingListSort(e)}>Last Gen. &nbsp;
                                    <span>{this.props.standingListFilters.currentSort === "lastGeneratedBy" ? this.props.standingListFilters.reverse === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightblue" }}>{this.props.standingListFilters.currentSort2 === "lastGeneratedBy" ? this.props.standingListFilters.reverse2 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                    <span style={{ color: "lightcoral" }}>{this.props.standingListFilters.currentSort3 === "lastGeneratedBy" ? this.props.standingListFilters.reverse3 === 1 ? `⮝` : `⮟` : ""}</span><span />
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="freetime-tbody">
                            {this.props.standingList.map((sl, index) => (

                                this.state.editRowIndex === index ?

                                    <tr key={sl.id}>
                                        <td></td>
                                        <td>
                                            <Form.SelectSmall name="assignedTo" col="col s12 standingListAssignedTo" value={this.state.editItem.assignedTo} onChange={this.editItemOnChange} list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} />
                                        </td>
                                        <td>
                                            <Form.SelectSmall name={`shipperId`} col="col s2" value={this.state.editItem.shipperId} onChange={this.editItemOnChange} list={this.props.shipper} filter={{ label: "code", value: "id" }} />
                                        </td>
                                        <td>
                                            <Form.SelectSmall col="col s1" name={`carrierId`} value={this.state.editItem.carrierId} onChange={this.editItemOnChange} list={this.props.carrier} filter={{ label: "code", value: "id" }} />
                                        </td>
                                        <td className="row">
                                            <Form.TextInputSmall col="col s3" name={`containerCount`} value={this.state.editItem.containerCount} onChange={this.editItemOnChange} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.SelectSmall name={`containerTypeId`} col="col s6" value={this.state.editItem.containerTypeId} onChange={this.editItemOnChange} list={this.props.containerType} filter={{ label: "code", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                        </td>
                                        <td>
                                            <Form.SelectSmall name={`originId`} col="col s2" value={this.state.editItem.originId} onChange={this.editItemOnChange} list={this.props.origins} filter={{ label: "code", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                        </td>
                                        <td>
                                            <Form.SelectSmall name={`destId`} col="col s2" value={this.state.editItem.destId} onChange={this.editItemOnChange} list={this.props.destinations} filter={{ label: "code", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                        </td>
                                        <td>
                                            <Form.TextInputSmall col="col s12" name={`serviceRoute`} value={this.state.editItem.serviceRoute} onChange={this.editItemOnChange} flags={[flags.ALLOW_EMPTY]} />
                                        </td>
                                        <td>{sl.datetimeLastGenerated} {sl.lastGeneratedBy}</td>
                                        <td className={styles.iconColumn}>
                                            <div className="iconContainer">
                                                <i tabIndex="0" className={`col s1 material-icons saveIcon`} onClick={this.makeInactiveAndSave}>save</i>
                                            </div>
                                        </td>

                                    </tr>
                                    :
                                    <tr key={sl.id} id={`edit-standing-list-${index}`} onContextMenu={e => { return }}>
                                        <td>
                                            <Form.CheckBox name="selectedItem" value={this.state.selectedItems.includes(sl.id)} onChange={() => this.selectedItemOnChange(sl.id)} />
                                        </td>
                                        <td>{sl.userInitials}</td>
                                        <td>{sl.shipperCode}</td>
                                        <td>{sl.carrierCode}</td>
                                        <td>{sl.containerCount}x{sl.containerType}</td>
                                        <td>{sl.originName}</td>
                                        <td>{sl.destName}</td>
                                        <td>{sl.serviceRoute}</td>
                                        <td>{sl.datetimeLastGenerated} {sl.lastGeneratedBy}</td>

                                        <td className={styles.iconColumn}>
                                            <div className={styles.iconContainer}>
                                                <i tabIndex="0" className={`material-icons fr-text-${index} editIcons col s6`} onClick={() => this.makeActive(index)}>edit</i>&nbsp;
                                                <i tabIndex="0" style={{ marginTop: "5px" }} className={`material-icons sl-text-${index} editIcons col s5`} onClick={() => this.deleteStandingListItem(sl.id)}>cancel</i>
                                            </div>
                                        </td>
                                    </tr>

                            ))}

                        </tbody>
                    </table>
                    <div className="row">
                        <Form.Select label="Assigned To" name="assignedTo" col="col s2" value={this.state.assignedTo} onChange={this.onChange} list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} />
                        <Form.Select label="Shipper" name={`shipperId`} col="col s1" value={this.state.shipperId} onChange={this.onChange} list={this.props.shipper} filter={{ label: "code", value: "id" }} />
                        <Form.Select label="Carrier" col="col s1" name={`carrierId`} value={this.state.carrierId} onChange={this.onChange} list={this.props.carrier} filter={{ label: "code", value: "id" }} />
                        <Form.TextInput label="Cont. Count" col="col s1" name={`containerCount`} value={this.state.containerCount} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select label="Cont. Type" name={`containerTypeId`} col="col s1" value={this.state.containerTypeId} onChange={this.onChange} list={this.props.containerType} filter={{ label: "code", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select label="Origin" name={`originId`} col="col s2" value={this.state.originId} onChange={this.onChange} list={this.props.origins} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select label="Dest" name={`destId`} col="col s2" value={this.state.destId} onChange={this.onChange} list={this.props.destinations} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput label="Service" name={'serviceRoute'} col="col s1" value={this.state.serviceRoute} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <i tabIndex="0" className={`col s1 material-icons saveIcon`} onClick={this.add}>add</i>
                    </div>
                    <div className="row">
                        <Form.Button col="s2" label="Select All" type="outline" color="black" onClick={this.selectAll} />
                        <Form.Button col="s2" label="Unselect All" type="outline" color="black" onClick={this.unselectAll} />
                        <Form.Button col="s2" label="Delete" type="outline" color="red" onClick={this.deleteStandingListItem} />
                        <Form.Select col="s2" label="Week" name="week" value={this.props.filters.week} onChange={this.props.filterOnChangePlus} list={this.props.weeks} filter={{ label: "week", value: "week" }} />
                        <Form.Button col="s3" label={`Generate for Week ${this.props.filters.week}`} type="outline" color="blue" onClick={this.generateRequests} />
                    </div>
                    <div className="row">
                        <div className={`col s12 ${styles.standingListCountContainer}`}>
                            <b className={styles.standingListCount}>Total Results: {this.props.standingListCount.count}</b>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { urls, index, user, shipper, carrier, vessel, location, portalUser, containerType } = state;
    return { url: urls[index], user, shipper, carrier, vessel, location, portalUser, containerType };
};
export default connect(mapStateToProps)(StandingListModal);