import React from 'react';
import { connect } from 'react-redux';
import { flags } from '../../constants';
import { globalToastActions, init } from '../../Redux/actions';
import Form from '../../Components/NewComponents';
import Containers from './Containers';
import Axios from 'axios';
import moment from 'moment';
import M from 'materialize-css';
import styles from './shipments.module.css';
import TabsContainer from './DescriptionOfGoods/TabsContainer';
import EmailForm from './EmailForm/EmailForm';
import FileUpload from "../../Components/FileUpload/FileUpload";
import { fileUploader } from "../../helperFunctions";
import { auth, withRouter } from "../../helperFunctions";
import StickyNote from '../../Components/StickyNote/StickyNote';
import { Buffer } from 'buffer';

class ShipmentInstructions extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...this.initState(), ...this.props.location?.state };
        if (this.props.location?.state?.searchTerm)
            setTimeout(() => this.bookingLookup(), 100);
    }

    initState = () => ({
        searchTerm: '',
        exporterId: '',
        originalExporterName: '',
        exporterName: '',
        exporterAddress: '',
        exporterPhone: '',
        exporterFax: '',
        exporterEmail: '',
        originalSupplierName: '',
        supplierId: '',
        supplierName: '',
        supplierAddress: '',
        supplierPhone: '',
        supplierFax: '',
        supplierEmail: '',
        originalConsigneeName: '',
        consigneeId: '',
        consigneeName: '',
        consigneeAddress: '',
        consigneePhone: '',
        consigneeFax: '',
        consigneeEmail: '',
        originalNotifyName: '',
        notifyId: '',
        notifyName: '',
        notifyAddress: '',
        notifyPhone: '',
        notifyFax: '',
        notifyEmail: '',
        currentParty: '',
        currentRole: '',
        newAlias: '',
        placeOfReceiptName: '',
        portOfLoadName: '',
        portOfDischargeName: '',
        portOfDeliveryName: '',
        billOfLadingNumber: '',
        ladenOnBoardDate: '',
        aesNumber: '',
        submitSiUser: '',
        proofUser: '',
        closeoutUser: '',
        submittedFromWeb: false,
        submissionType: '',
        blType: '',
        freightTerms: '',
        exportInstructions: '',
        internalNotes: '',
        paymentTerms: '',
        lcNumber: '',
        dogObj: {
            shippingInstructions: {
                marksAndNumbers: '',
                numberOfPackages: '',
                description: '',
                weight: '',
                measurement: '',
                byContainerTotal: 0
            },
            commercialInvoice: {
                marksAndNumbers: '',
                numberOfPackages: '',
                description: '',
                weight: '',
                measurement: '',
                termsOfDelivery: "",
                termsOfPrice: "",
            },
            bse: {
                description: '',
                stateOfNotary: '',
                countyOfNotarty: '',
                cityOfNotary: '',
                product: '',
                grossWeight: ''
            },
            packingList: {
                marksAndNumbers: '',
                numberOfPackages: '',
                description: '',
                weight: '',
                measurement: '',
            },
            certificateOfOrigin: {
                marksAndNumbers: '',
                numberOfPackages: '',
                description: '',
                weight: '',
                measurement: '',
                countryOfOrigin: '',
                preferenceCode: ''
            }
        },
        dogPage: 'shippingInstructions',
        booking: {},
        vesselVoyage: {},
        supplierSameAsShipper: true,
        notifySameAsConsignee: true,
        docType: {
            shippingInstructions: true,
            packingList: false,
            commercialInvoice: false,
            koreaCert: false,
            bse: false,
        },
        shipperId: this.props.shipperId,
        shipper: this.props.shipper.filter(p => p.id === this.props.shipperId)[0],
        isDummy: false,
        expUseAlias: false,
        supUseAlias: false,
        conUseAlias: false,
        notUseAlias: false,
        allConsignees: [],
        allParties: [],
        badge: {
            type: "",
            color: "white",
            id: ""
        },
        stageIcon: "",
        stageColor: "",
        cargoCutDate: '',
        docCutDate: '',
        eta: '',
        etd: '',
        loading: true,
        shouldRecalculate: false,
        byContainerTotal: 0,
        customTotal: 0,
        numOfContainers: 0,
        showButtonContextMenu: false,
        aesCheckObject: {
            exporterId: '',
            exporterName: '',
            supplierId: '',
            supplierName: '',
            consigneeId: "",
            consigneeName: '',
            notifyId: "",
            notifyName: '',
            portOfLoadName: "",
            placeOfReceiptName: "",
            portOfDischargeName: "",
            portOfDeliveryName: ""
        },
        showLfAddresses: false,
        lfAddress: 0,
        lfAddresses: [],
        emailSubject: '',
        emails: [],
        docsEmails: [],
        generatePdf: false,
        fileUploadTabOpen: false,
        showStickyNote: false
    })

    ///INITIALIZING FUNCTIONS
    ////////////////////////////////////////////

    initMaterializeComponents = () => {
        M.Modal.init(document.querySelectorAll('.modal:not(#bookingAndSiStagesWindow)'));
        M.Collapsible.init(document.querySelector('.collapsible'));
        M.Modal.init(document.getElementById("bookingAndSiStagesWindow"), {
            inDuration: '100',
            outDuration: "100",
            onOpenEnd: () => this.setState({ showStickyNote: true }),
            onCloseEnd: () => this.setState({ showStickyNote: false })
        });
    }

    initListener = () => {
        document.addEventListener('keydown', this.chooseAddress);
        document.addEventListener('keydown', this.zoom);
        document.addEventListener('keydown', this.saveListener);
        document.addEventListener('keydown', this.printListener);
        document.addEventListener('keydown', this.escListener);
        document.addEventListener('keydown', this.searchListener);
        document.addEventListener('keydown', this.addNewAlias);
        document.addEventListener('keydown', this.emailListener);
    }

    removeListener = () => {
        document.removeEventListener('keydown', this.chooseAddress);
        document.removeEventListener('keydown', this.zoom);
        document.removeEventListener('keydown', this.saveListener);
        document.removeEventListener('keydown', this.printListener);
        document.removeEventListener('keydown', this.escListener);
        document.removeEventListener('keydown', this.searchListener);
        document.removeEventListener('keydown', this.addNewAlias);
        document.removeEventListener('keydown', this.emailListener);
    }

    openStickyNoteModal = () => {
        M.Modal.getInstance(document.getElementById("bookingAndSiStagesWindow")).open();
    }

    closeStickyNoteModal = () => {
        M.Modal.getInstance(document.getElementById("bookingAndSiStagesWindow")).close();
    }

    zoom = e => {
        if (this.state.loading) {
            return;
        } else if (e.keyCode === 116) {
            e.preventDefault();
            if (this.state.bookingNumber)
                this.props.navigate(`/shipment/create/${this.state.bookingNumber[0]}`, {
                    state: {
                        shipperId: this.state.shipperId,
                        searchTerm: this.state.bookingNumber[0],
                        directory: 'SHIPMENTS/',
                        ...this.props.location?.state
                    }
                })
        }
    }

    clickZoom = e => {
        e.preventDefault();

        if (this.state.loading) {
            return;
        } else if (this.state.bookingNumber) {
            this.props.navigate(`/shipment/create/${this.state.bookingNumber[0]}`, {
                state: {
                    shipperId: this.state.shipperId,
                    searchTerm: this.state.bookingNumber[0],
                    directory: 'SHIPMENTS/',
                    ...this.props.location?.state
                }
            })

        } else {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Booking Does Not Exist", type: "warning" } });
            return;
        }
    }

    saveListener = e => {
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault();
            this.save();
        }
    }

    printListener = e => {
        if (e.ctrlKey && e.keyCode === 80) {
            e.preventDefault();
            this.showModal();
        }
    }

    escListener = async e => {
        if (e.keyCode === 27) {
            if (this.state.showStickyNote) {
                return this.setState({ showStickyNote: false });
            } else if (await this.checkForOpenModals()) {
                return;
            } else {

                this.props.navigate(this.props.location?.state?.origin === "document" ? '/documentation' : '/shipment', {
                    state: {
                        ...this.props.location?.state,
                        filters: this.props.location?.state?.filters,
                        filterList: this.props.location?.state?.filterList,
                        origin: "shippingInstructions"
                    }
                })
            }
        }
    }
    searchListener = e => {
        const searchBox = document.querySelector('#searchTerm');
        if (document.activeElement === searchBox && e.keyCode === 13)
            this.bookingLookup();
    }

    clickListener = e => {
        this.setState({ showButtonContextMenu: false }, () => document.removeEventListener('click', this.clickListener))
    }

    emailListener = e => {
        if (e.keyCode === 77 && e.ctrlKey) {
            e.preventDefault();
            this.saveAndEmail();
        }
    }



    //ON EVENT ACTION FUNCTIONS
    //////////////////////////////////////////////////////////

    onChange = (name, value) => {
        if (name === "paymentTerms") {
            if (parseInt(value, 10) === 1 && !this.state.lcChecked) {
                this.setState({ [name]: value, lcChecked: true });
            } else if (parseInt(value, 10) !== 1 && this.state.lcChecked) {
                this.setState({ [name]: value, lcChecked: false });
            } else {
                this.setState({ [name]: value });
            }
        } else {
            this.setState({ [name]: value });
        }
    };

    printOnChange = (name, value) => { this.setState((prevState) => ({ docType: { ...prevState.docType, [name]: value } })) };

    resizeTextArea = (id) => {
        let textArea = document.getElementById(id);
        let heightLimit = 900;

        textArea.style.height = "";
        textArea.style.minHeight = "";
        textArea.style.maxHeight = "";
        textArea.style.height = Math.min(textArea.scrollHeight, heightLimit) + "px";
        textArea.style.maxHeight = Math.min(textArea.scrollHeight, heightLimit) + "px";
        textArea.style.minHeight = Math.min(textArea.scrollHeight, heightLimit) + "px";
    }

    submissionTypeOnChange = (name, value) => {
        if (value === "0" || value === "1") {
            this.setState({ submittedFromWeb: 0 })
        }
        this.setState({ [name]: value }, async () => {
            if (document.querySelector(".submittedCheckbox")) {
                document.getElementById("submissionType").focus();
            } else {
                document.getElementById("submissionType").focus();
            }
        })
    }


    dogOnChange = (name, value) => {
        const { dogObj } = this.state;
        dogObj[this.state.dogPage][name] = value;
        this.setState({ dogObj });
    };

    conParseChange = (name, value) => {
        const { dogObj } = this.state;
        dogObj[name] = value;
        this.setState({ dogObj })
    };


    arrayOnChange = (name, value) => {
        const arr = name.split(':');
        name = arr[0];
        const index = arr[1];
        const { dogObj } = this.state

        dogObj[this.state.dogPage][index][name] = value;
        this.setState({ dogObj })
    }

    partyOnChange = (name, value) => {
        let role = name.split("N")[0]
        const party = this.props.party.filter(p => p.id === parseInt(value))[0];
        let nameForOriginalSet = name.charAt(0).toUpperCase() + name.substring(1);
        if (party)
            this.setState({
                [`original${nameForOriginalSet}`]: party.name,
                [name]: party.name,
                [`${role}Id`]: party.partyId,
                [`${role}Address`]: party.printableAddress ? party.printableAddress.toString().toUpperCase() : '',
                [`${role}Phone`]: party.mainPhone ? party.mainPhone.toString().toUpperCase() : '',
                [`${role}Fax`]: party.mainFax ? party.mainFax.toString().toUpperCase() : '',
                [`${role}Email`]: party.mainEmail ? party.mainEmail.toString().toUpperCase() : ''
            })
    }

    sameAsOnChange = (name, value) => {
        let type = name.split("Same")[0];


        if (value) {
            if (type === "supplier") {
                this.setState({
                    [name]: value,
                    supUseAlias: this.state.expUseAlias,
                    originalSupplierName: this.state.expUseAlias ? this.state.originalExporterName : "",
                    supplierName: this.state.exporterName,
                    supplierId: this.state.exporterId,
                    supplierAddress: this.state.exporterAddress,
                    supplierPhone: this.state.exporterPhone,
                    supplierFax: this.state.exporterFax,
                    supplierEmail: this.state.exporterEmail
                })
            } else {
                this.setState({
                    [name]: value,
                    notUseAlias: this.state.conUseAlias,
                    originalNotifyName: this.state.conUseAlias ? this.state.originalConsigneeName : "",
                    notifyName: this.state.consigneeName,
                    notifyId: this.state.consigneeId,
                    notifyAddress: this.state.consigneeAddress,
                    notifyPhone: this.state.consigneePhone,
                    notifyFax: this.state.consigneeFax,
                    notifyEmail: this.state.consigneeEmail
                })
            }
        } else {
            if (type === "supplier") {
                this.setState({
                    [name]: value,
                    supUseAlias: false,
                    originalSupplierName: "",
                    supplierName: "",
                    supplierId: "",
                    supplierAddress: "",
                    supplierPhone: "",
                    supplierFax: "",
                    supplierEmail: ""
                })
            } else {
                this.setState({
                    [name]: value,
                    notUseAlias: false,
                    originalNotifyName: "",
                    notifyName: "",
                    notifyId: "",
                    notifyAddress: "",
                    notifyPhone: "",
                    notifyFax: "",
                    notifyEmail: ""
                })
            }
        }
    }

    changePrintableAddress = (newAddress) => {
        this.setState({
            [`${this.state.currentRole}Address`]: newAddress
        })
        this.hideAddModal();
        document.getElementById(this.state.currentRole + "Address").focus();
    }

    changeAlias = (newAlias) => {
        this.setState({
            [`${this.state.currentRole}Name`]: newAlias,
            newAlias: ''
        })
        this.hideAliasModal();
        document.getElementById(this.state.currentRole + "Address").focus();
    }
    chooseAddress = (e) => {
        if (e.keyCode === 13 &&
            M.Modal.getInstance(document.querySelector("#address-modal")).isOpen &&
            document.activeElement.classList.contains("addressOption")) {
            this.setState({
                [`${this.state.currentRole}Address`]: document.activeElement.innerHTML
            })
            this.hideAddModal();
            document.getElementById(this.state.currentRole + "Address").focus();
        } else if (e.keyCode === 13 &&
            M.Modal.getInstance(document.querySelector("#alias-modal")).isOpen &&
            document.activeElement.classList.contains("aliasOption")) {
            this.setState({
                [`${this.state.currentRole}Name`]: document.activeElement.innerHTML,
                newAlias: ''
            });
            this.hideAliasModal();
            document.getElementById(this.state.currentRole + "Address").focus();
        }
    }

    addNewAlias = (e) => {
        if (e.keyCode === 13 &&
            M.Modal.getInstance(document.querySelector("#alias-modal")).isOpen &&
            document.activeElement.classList.contains("addAlias")) {
            this.addAlias();
        } else if (e.keyCode === 13 &&
            M.Modal.getInstance(document.querySelector("#alias-modal")).isOpen &&
            document.activeElement.id === "newAlias") {
            this.addAlias();
        }
    }

    generateExportString = () => {
        if (parseInt(this.state.proofUser) === 0 || !this.state.proofUser) { return };
        let swb = "*SEA WAYBILL*";
        let obl = "*OBL*";
        let user = (this.props.portalUser.filter(e => e.id === parseInt(this.state.proofUser))[0].fullName).split(" ")[0];
        let exportInstructions;
        if (this.state.submissionType && this.state.blType && !this.state.exportInstructions) {
            if (parseInt(this.state.submissionType) === 1) {
                if (parseInt(this.state.blType) === 1) {
                    exportInstructions = `PLEASE EMAIL ${swb} PROOF TO ${user}@LINDSEYFORWARDERS.COM`
                } else {
                    exportInstructions = `PLEASE EMAIL ${obl} PROOF TO ${user}@LINDSEYFORWARDERS.COM`
                }
                this.setState({ exportInstructions: exportInstructions })

            } else {
                this.setState({ exportInstructions: "WEB" })
            }
        }
    }

    goToInvoice = () => {
        if (this.state.ladenOnBoardDate && !this.state.booking.isDummy && this.state.ladenOnBoardDate && this.state.billOfLadingNumber) {
            this.save(() => {
                this.props.navigate('/invoice/create', {
                    state: {
                        searchTerm: this.state.lNumber,
                        tabIndex: 0,
                        filters: this.props.location?.state?.filters,
                        origin: "shippingInstructions"
                    }
                })
            })
        } else if (this.state.booking.isDummy) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG,
                payload: {
                    type: "error", msg: "Cannot Invoice Dummies"
                }
            })
        } else if (!this.state.ladenOnBoardDate || !this.state.billOfLadingNumber) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG,
                payload: {
                    type: "error", msg: "Requires Laden On Board Date & B/L Number"
                }
            })
        }
    }

    setLoading = (bool) => {
        return new Promise((resolve, reject) => {
            this.setState({ loading: bool }, () => resolve());
        })
    }

    portCheck = () => {
        return new Promise((resolve, reject) => {
            if (window.confirm(`Port Mismatch: The port(s) on this page do not match those on the Bookings page. The ports shown in the override fields will be displayed on printed documents. Please confirm you would like to continue using the port(s) currently entered on this page.
            
        Click OK to continue and save, or click Cancel to go back`)) {
                resolve(false);
            } else {
                resolve(true);
            }
        })
    }

    containerWeightCheck = () => {
        return new Promise((resolve, reject) => {
            if (window.confirm(`The By 'Container Total Weight' and 'Total Container Weight' conversions do not match! This could cause documentation discrepancies.

        Click OK to continue and save, or click Cancel to go back`)) {
                resolve(false);
            } else {
                resolve(true);
            };
        });
    }

    showAddModal = (role) => {
        this.promiseForAliasList(role).then(() => {
            M.Modal.getInstance(document.querySelector("#address-modal")).open();
            if (document.querySelector("#card0")) {
                document.getElementById("card0").focus();
            }
        })
    }

    showAliasModal = (role) => {
        M.Tooltip.init(document.querySelectorAll(".tooltipped"))
        this.promiseForAliasList(role).then(() => {
            M.Modal.getInstance(document.querySelector("#alias-modal")).open();
            if (document.querySelector("#alias0")) {
                let roleName = this.state[`${role}Name`];
                let roleId = this.state[`${role}Id`];
                let aliasCheck = this.props.partyAlias.filter(pa => pa.alias === roleName && roleId === pa.partyId);
                if (aliasCheck.length) {
                    if (this.state[`original${role.charAt(0).toUpperCase()}${role.substring(1)}Name`] !== aliasCheck[0].alias) {
                        document.querySelector(`[name="${roleName}"]`).focus();
                    } else {
                        document.getElementById("alias0").focus();
                    }
                } else {
                    document.getElementById("alias0").focus();
                }
            }
        })
    }

    promiseForAliasList = async (role) => {
        return await new Promise(async (resolve, reject) => {
            this.setState({ currentRole: role });
            resolve()
            reject(new Error("Error"));
        }).catch((e) => console.log(e))
    }

    showModal = () => {
        M.Modal.getInstance(document.querySelector('#print-modal')).open();
        document.querySelector('.printy-dope-ass-button > button').focus();
    }

    showEmailForm = () => {
        this.setState({ pdfEmailFormIsOpen: true }, () => {
            let bookingNumber = this.state.bookingNumber;
            this.setState({ generatePdf: true, emailSubject: `SI: ${bookingNumber}` }, () => {
                M.Modal.getInstance(document.querySelector('#emailModal')).open();
                document.querySelector('#recipient').focus();
            })
        })
    }

    showExcelEmailForm = () => {
        this.save(() => {
            this.setState({ pdfEmailFormIsOpen: false }, () => {
                let bookingNumber = this.state.bookingNumber;
                this.setState({ emailSubject: `${bookingNumber}` }, () => {
                    M.Modal.getInstance(document.getElementById("excelEmailModal")).open();
                    document.querySelector("#recipient").focus();
                })
            })
        })
    }

    showCiLineItemModal = () => {
        M.Modal.getInstance(document.querySelector('#ci-line-item')).open();
    }

    hideModal = () => M.Modal.getInstance(document.querySelector('#print-modal')).close();
    hideEmailForm = () => M.Modal.getInstance(document.querySelector("#emailModal")).close();
    hideExcelEmailForm = () => M.Modal.getInstance(document.querySelector("#excelEmailModal")).close();
    hideAesModal = () => {
        M.Modal.getInstance(document.querySelector("#aes-modal")).close();
        document.querySelector("#aesNumber").focus();
    }
    hideAddModal = () => {
        M.Modal.getInstance(document.querySelector('#address-modal')).close();
        this.setState({ showAddModal: false });
        document.getElementById(this.state.currentRole + "Address").focus();
    }
    hideAliasModal = () => {
        M.Modal.getInstance(document.querySelector('#alias-modal')).close();
        document.getElementById(this.state.currentRole + "Address").focus();
    }

    handleButtonContextMenu = (e) => {
        e.preventDefault();
        if (!this.state.isDummy && !this.state.sentOrPrinted) {
            return;
        }
        document.addEventListener('click', this.clickListener);

        this.setState({ showButtonContextMenu: true, x: e.pageX, y: e.pageY })
    }

    showLfAddresses = () => {
        this.setState({ showLfAddresses: true });
    }

    checkForOpenModals = async () => {
        let modals = document.querySelectorAll('.modal');
        let openModal = false;
        await modals.forEach(modal => {
            if (M.Modal.getInstance(modal).isOpen) {
                M.Modal.getInstance(modal).close();
                return openModal = true;
            }
        });
        return openModal;
    }

    ////GETTING THE INFO FUNCTIONS
    ////////////////////////////////////////////////////////////////

    getPartyInfo = (id, role) => {
        Axios.get(`/api/v1/party/populated/${id}`, { params: auth.getAuthData() }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }

            const party = result.data[0]
            this.setState({
                [`${role}Address`]: party.printableAddress,
                [`${role}Phone`]: party.mainPhone,
                [`${role}Fax`]: party.mainFax,
                [`${role}Email`]: party.mainEmail
            })
        });
    }

    getConsignees = () => {

        Axios.get('/api/v1/party/consignees/byshipper', {
            params: {
                ...auth.getAuthData(),
                shipperId: this.state.exporterId
            }
        }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' });
                return;
            }

            let allConsignees = result.data;

            allConsignees.sort((a, b) => a.name.localeCompare(b.name));
            allConsignees.push(this.props.party.filter(p => p.name === "TO ORDER")[0]);


            this.setState({ allConsignees: result.data });
            this.getAllParties();
        })

    }

    getEmails = (carrierId) => {
        let partyId = parseInt(this.state.shipperId);
        Axios.get("/api/v1/contacts/all", { params: { ...auth.getAuthData(), partyId } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
            let emails = this.props.carrierContacts.filter(cc => cc.carrierId === carrierId);
            let docsEmails = result.data.filter(r => r.role === "DOCS");
            this.setState({ emails, docsEmails });
        })
    }

    getAllParties = () => {
        const arr = [];
        const names = [];
        if (this.props.party)
            this.props.party.forEach(shipper => {
                if (names.indexOf(shipper.name) === -1) {
                    if (shipper.id === 1) {
                        arr.unshift(shipper);
                        names.unshift(shipper.name);
                    }
                    else {
                        arr.push(shipper)
                        names.push(shipper.name);
                    }
                }
            });
        arr.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({ allParties: arr })
    };

    getContainerType = () => this.props.containerType.filter(c => c.id === this.state.booking.containerTypeId)[0].code
    getLocationName = id => this.getLocationCode(id);
    getLocationCode = id => {
        const loc = this.props.locations.filter(l => l.id === id)[0];
        const stateOrCountry = loc.state ? loc.state : this.props.country.filter(c => c.id === loc.countryId)[0].name;
        return `${loc.name}, ${stateOrCountry}`;
    }
    getShipper = id => {
        const shipper = this.props.party.filter(p => p.id === id)[0];
        this.setState({ shipper });
    }

    getPartyDetails = (id) => {
        if (typeof id === "string") {
            const party = this.props.party.filter(p => p.name === id)[0];
            return party
        } else if (!this.props.party.filter(p => p.id === id)[0]) {
            this.setState({ expUseAlias: true })
            return { name: "PARTY NO LONGER EXISTS" };
        }
        const party = this.props.party.filter(p => p.id === id)[0];
        return party
    }

    getOriginalPartyNames = async (exporterId, exporter, supplierId, supplier, consigneeId, consignee, notifyId, notify) => {
        let originalExp = await this.getOriginalName(exporterId);
        let originalSup = await this.getOriginalName(supplierId);
        let originalCon = await this.getOriginalName(consigneeId);
        let originalNot = await this.getOriginalName(notifyId);
        this.checkForAliasUse("exporter", exporter, exporterId);
        this.checkForAliasUse("supplier", supplier, supplierId);
        this.checkForAliasUse("consignee", consignee, consigneeId);
        this.checkForAliasUse("notify", notify, notifyId);

        this.setState({
            originalExporterName: originalExp,
            originalSupplierName: originalSup,
            originalConsigneeName: originalCon,
            originalNotifyName: originalNot
        });
    }

    getOriginalName = (id) => {
        if (!this.props.party.filter(p => p.id === id).length) {
            return undefined;
        } else {
            return this.props.party.filter(p => p.id === id)[0].name
        }
    }

    getLfAddresses = async () => {
        return new Promise(async (resolve, reject) => {
            let lfAddresses = await this.props.party.filter(p => p.partyId === 1).length ? this.props.party.filter(p => p.partyId === 1) : 0;
            if (lfAddresses) {
                lfAddresses = await lfAddresses.sort((a, b) => { return parseInt(a.partyAddressId, 10) - parseInt(b.partyAddressId, 10) });
                resolve(lfAddresses)
            }
        });
    }

    checkForAliasUse = (role, name, id) => {

        let currentPartyData = this.props.party.filter(p => p.id === id)[0];

        if (!id && !name) {
            return;
        } else if ((!currentPartyData && id) || (!id && name.trim().length)) {
            let prefix = role.slice(0, 3);
            this.setState({ [`${prefix}UseAlias`]: true });
        } else if ((currentPartyData.name !== name)) {
            let prefix = role.slice(0, 3);
            this.setState({ [`${prefix}UseAlias`]: true });
        } else {
            return;
        }
    }

    checkForArchivedParty = (expId, suppId, conId, notId) => {
        let expPartyData = this.props.party.filter(p => p.id === expId)[0];
        let suppPartyData = this.props.party.filter(p => p.id === suppId)[0];
        let conPartyData = this.props.party.filter(p => p.id === conId)[0];
        let notPartyData = this.props.party.filter(p => p.id === notId)[0];

        this.setState({
            expUseAlias: expPartyData ? true : false,
            supUseAlias: suppPartyData ? true : false,
            conUseAlias: conPartyData ? true : false,
            notUseAlias: notPartyData ? true : false
        });
    }

    getTimeZone = id => {
        if (typeof id === 'string') {
            id = parseInt(id, 10);
        }
        const tz = this.props.locations.filter(l => l.id === id)[0].timeZone;
        return tz ? tz : 'America/Los_Angeles';
    }

    getDates = () => {
        const { cargoCutDate, docCutDate, etd, eta } = this.state.vesselVoyage;
        let cargoMoment = moment.tz(new Date(cargoCutDate), this.getTimeZone(this.state.portPair.placeOfReceiptId));
        let docMoment = moment.tz(new Date(docCutDate), this.getTimeZone(this.state.portPair.placeOfReceiptId));
        let etdMoment = moment.tz(new Date(etd), this.getTimeZone(this.state.portPair.portOfLoadId));
        let etaMoment = moment.tz(new Date(eta), this.getTimeZone(this.state.portPair.portOfDeliveryId));

        const arr = [
            { name: 'Cut', date: cargoMoment.format('MM/DD HH:mm') },
            { name: 'Doc', date: docMoment.format('MM/DD HH:mm') },
            { name: 'ETD', date: etdMoment.format('MM/DD') },
            { name: 'ETA', date: etaMoment.format('MM/DD') },
        ]
        return arr;
    }

    getUserName = id => {
        if (!id)
            return '';
        else {
            const user = this.props.portalUser.filter(p => p.id === id)[0];
            return ` ${user.first} ${user.last}`;
        }
    }
    parseTime = time => {
        if (!time)
            return '';
        else {
            const newTime = moment(time).format('MM/DD/YY HH:mm')
            return newTime;
        }
    }

    setActive = (e, choice) => {
        e?.preventDefault();

        let activeBookingClass = this.state.sentOrPrinted ? 'activeSiTabPrinted' : "activeSiTab";
        let inactiveBookingClass = this.state.sentOrPrinted ? 'inactiveSiTabPrinted' : 'inactiveSiTab';
        let activeUploadClass = this.state.sentOrPrinted ? 'activeUploadTabPrinted' : 'activeUploadTab';
        let inactiveUploadClass = this.state.sentOrPrinted ? "inactiveUploadTabPrinted" : "inactiveUploadTab";

        if (!document.getElementById("shippingInstructions") || !document.getElementById("fileUpload")) {
            return;
        } else if (choice === "siTab") {

            this.setState({ fileUploadTabOpen: false });

            document.getElementById('fileUpload').classList.add('hideTab');
            document.getElementById('shippingInstructions').classList.remove('hideTab');

            document.getElementById("siTab").classList.remove(inactiveBookingClass);
            document.getElementById("siTab").classList.add(activeBookingClass);

            document.getElementById("fileUploadTab").classList.remove(activeUploadClass);
            document.getElementById("fileUploadTab").classList.add(inactiveUploadClass);



        } else {
            if (!this.state.bookingId) {
                return;
            }
            this.setState({ fileUploadTabOpen: true });

            document.getElementById('shippingInstructions').classList.add('hideTab');
            document.getElementById('fileUpload').classList.remove('hideTab');

            document.getElementById("fileUploadTab").classList.remove(inactiveUploadClass);
            document.getElementById("fileUploadTab").classList.add(activeUploadClass);

            document.getElementById("siTab").classList.remove(activeBookingClass);
            document.getElementById("siTab").classList.add(inactiveBookingClass);


        }

    }

    getShippingInstructionTabStyling = () => {
        if (this.state.loading) {
            return;
        }

        if (this.state.fileUploadTabOpen) {
            if (this.state.sentOrPrintedAndDummy) {
                return 'inactiveSiTabPrinted'
            } else {
                return 'inactiveSiTab';
            }
        } else {
            if (this.state.sentOrPrintedAndDummy) {
                return 'activeSiTabPrinted';
            } else {
                return 'activeSiTab';
            }
        }
    }


    getUploadTabStyling = () => {
        if (this.state.loading) {
            return;
        }

        if (this.state.fileUploadTabOpen) {
            if (this.state.sentOrPrintedAndDummy) {
                return 'activeUploadTabPrinted'
            } else {
                return 'activeUploadTab';
            }
        } else {
            if (this.state.sentOrPrintedAndDummy) {
                return 'inactiveUploadTabPrinted';
            } else {
                return 'inactiveUploadTab';
            }
        }
    }

    getBookingTabStyling = () => {
        if (this.state.loading) {
            return;
        }

        if (this.state.sentOrPrintedAndDummy) {
            return 'inactiveBookingTabPrinted'
        } else {
            return 'inactiveBookingTab';
        }

    }

    getStickyNoteTabStyling = () => {
        if (this.state.loading) {
            return;
        } else {
            if (this.state.showStickyNote) {
                if (this.state.useNotes) {
                    return 'stickyNoteButtonClickedYellow'
                } else {
                    return 'stickyNoteButtonClicked'
                }
            } else {
                if (this.state.useNotes) {
                    return 'stickyNoteButtonUnClickedYellow';
                } else {
                    return 'stickyNoteButtonUnClicked'
                }
            }
        }
    }

    displayBookingNumber = () => {
        let str = '';
        const { bookingNumber } = this.state.booking;
        if (bookingNumber) {
            for (let i = 0; i < bookingNumber.length; i++) {
                if (i === bookingNumber.length - 1)
                    str += ' ' + bookingNumber[i];
                else
                    str += bookingNumber[i] + ' /'
            }
        }
        return str;
    }
    displayBadge = () => {
        switch (this.props.location?.state?.status !== undefined ? this.props.location.state.status : this.state.booking.status) {
            case 'ACTIVE':
                this.setState({
                    badge: {
                        type: "ACTV",
                        color: "green",
                        id: "activeBadge"
                    }
                })
                break
            case 'CANCELLED':
                this.setState({
                    badge: {
                        type: "CNCL",
                        color: "red",
                        id: "cancelBadge"
                    }
                })
                break;
            case 'CLOSED':
                this.setState({
                    badge: {
                        type: "CLSD",
                        color: "grey",
                        id: "closedBadge"
                    }
                })
                break
            case 'ARCHIVED':
                this.setState({
                    badge: {
                        type: "ACVD",
                        color: "black",
                        id: "archiveBadge"
                    }
                })
                break;
            default:
                break;
        }
    }

    shippingInstructionsAesAlertCheck = (newObject, referenceObject) => {
        if (!this.state.sentOrPrinted || this.state.isDummy) {
            return;
        } else {
            for (let key of Object.keys(referenceObject)) {
                if (newObject[key] !== referenceObject[key]) {
                    alert("Please remember to update AES!");
                    break;
                } else {
                    continue;
                }
            }
            return;
        }
    }

    containersAesAlertCheck = (newObject, referenceObject) => {

        if (!this.state.sentOrPrinted || this.state.isDummy) {
            return;
        } else if (newObject.length !== referenceObject.length) {
            return alert("Please remember to update AES!");
        } else {
            for (let i = 0; i < newObject.length; i++) {
                if (newObject[i].hsCode !== referenceObject[i].hsCode ||
                    newObject[i].weight !== referenceObject[i].weight ||
                    newObject[i].description !== referenceObject[i].description) {
                    alert("Please Remember to update AES!");
                    break;
                } else {
                    continue;
                }
            }
            return;
        }
    }

    getStageIcon = () => {
        // vesselVoyage !== null ? vesselVoyage.docCutDate : "",
        // this.state.sentOrPrinted !== undefined ? this.state.sentOrPrinted : "",
        // this.state.invoice !== undefined ? this.state.invoice.invoiceStatus : ""
        const { vesselVoyage, sentOrPrinted, invoice } = this.state;
        let color = '';
        let icon = '';

        if (invoice?.invoiceStatus) {
            color = 'green';
            icon = 'attach_money'
        }
        else if (sentOrPrinted !== null && sentOrPrinted !== undefined && sentOrPrinted) {
            if (this.state.isDummy || !this.state.booking.numOfContainers)
                icon = 'assignment_ind'
            else
                icon = 'assignment_turned_in'
        }
        else if (moment().isAfter(moment(vesselVoyage?.docCutDate)) && vesselVoyage?.docCutDate !== undefined) {
            icon = 'assignment_late';
            color = 'red';
        }
        else if (moment().isSame(vesselVoyage?.docCutDate, 'D') && vesselVoyage?.docCutDate !== undefined)
            icon = 'history'

        return <span><i className="material-icons stage SIstage ic" style={{ color }} onContextMenu={this.handleButtonContextMenu}>{icon}</i></span>
    }

    generateStage = (docCutDate, SIPrinted, invoice) => {

        let date = moment().format("YYYY-MM-DD HH:mm:ss");

        if (invoice) {
            this.setState({
                stageIcon: "attach_money",
                stageColor: "green"
            })
        }
        else if (SIPrinted !== null && SIPrinted !== undefined && SIPrinted) {
            if (this.state.isDummy || !this.state.booking.numOfContainers) {
                this.setState({
                    stageIcon: "assignment_ind",
                    stagecolor: ""
                })
            } else {
                this.setState({
                    stageIcon: "assignment_turned_in",
                    stageColor: ""
                })
            }
        }
        else if (moment(date).isAfter(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss")) && docCutDate !== undefined) {
            this.setState({
                stageIcon: "assignment_late",
                stageColor: "red"
            })
        }
        else if (moment(date).isSame(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"), "D") && docCutDate !== undefined) {
            this.setState({
                stageIcon: "history",
                stageColor: ""
            })
        }
        else {
            this.setState({
                stageIcon: "",
                stageColor: ""
            })
        }
    }

    bookingLookup = () => {
        Axios.get(`/api/v1/instructions/${this.state.searchTerm.trim() || '!'}`, {
            params: auth.getAuthData()
        }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }

            if (!result.data) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Booking Found', type: 'warning' } })
                this.setState(p => ({ ...this.initState(), booking: {}, vesselVoyage: {}, containers: [], searchTerm: '', lNumber: '', loading: false }))
            }
            else {

                const { booking, vesselVoyage, shippingInstructions, portPair, vessel, invoice, dOGArray, shipper, allConsignees } = result.data;

                if (!shippingInstructions.exporterAddress)
                    this.getPartyInfo(!booking.displayNameId ? booking.shipperId : booking.displayNameId, 'exporter');

                if (!shippingInstructions.id) {
                    const placeOfReceiptName = this.getLocationName(portPair.placeOfReceiptId);
                    const portOfLoadName = this.getLocationName(portPair.portOfLoadId);
                    const portOfDischargeName = this.getLocationName(portPair.portOfDischargeId);
                    const portOfDeliveryName = this.getLocationName(portPair.portOfDeliveryId);
                    this.setState({
                        ...this.initState(), booking, numOfContainers: booking.numOfContainers, vesselVoyage, portPair, vessel, invoice,
                        placeOfReceiptName, portOfLoadName, portOfDischargeName, portOfDeliveryName, allConsignees,
                        submitSiUser: shipper.submitSi, proofUser: shipper.proof, closeoutUser: shipper.closeout,
                        searchTerm: booking.lNumber, lNumber: booking.lNumber, bookingNumber: booking.bookingNumber,
                        originalExporterName: !booking.displayNameId ? this.getPartyDetails(booking.shipperId).name : this.getPartyDetails(booking.displayNameId).name,
                        exporterId: !booking.displayNameId ? booking.shipperId : booking.displayNameId,
                        exporterName: !booking.displayNameId ? this.getPartyDetails(booking.shipperId).name : this.getPartyDetails(booking.displayNameId).name,
                        supplierId: !booking.displayNameId ? booking.shipperId : booking.displayNameId,
                        supplierName: !booking.displayNameId ? this.getPartyDetails(booking.shipperId).name : this.getPartyDetails(booking.displayNameId).name
                    },
                        () => {
                            this.getShipper(booking.shipperId);
                            this.checkForArchivedParty(this.state.exporterId, this.state.supplierId, this.state.consigneeId, this.state.notifyId);
                        })
                    this.displayBadge();

                    this.generateStage(
                        vesselVoyage !== null ? vesselVoyage.docCutDate : "",
                        this.state.sentOrPrinted !== undefined ? this.state.sentOrPrinted : "",
                        this.state.invoice !== undefined ? this.state.invoice.invoiceStatus : ""
                    );

                    // this.getConsignees();
                    this.getAllParties();
                    this.getEmails(vessel.carrierId);
                    const { cargoCutDate, docCutDate, etd, eta } = vesselVoyage;
                    let cargoMoment = moment.tz(new Date(cargoCutDate), this.getTimeZone(portPair.placeOfReceiptId));
                    let docMoment = moment.tz(new Date(docCutDate), this.getTimeZone(portPair.placeOfReceiptId));
                    let etdMoment = moment.tz(new Date(etd), this.getTimeZone(portPair.portOfLoadId));
                    let etaMoment = moment.tz(new Date(eta), this.getTimeZone(portPair.portOfDeliveryId));
                    let newCargoCutDate = cargoMoment.format('MM/DD/YY');
                    let newDocCutDate = docMoment.format('MM/DD HH:mm');
                    let newEtd = etdMoment.format('MM/DD/YY');
                    let newEta = etaMoment.format('MM/DD/YY');

                    this.setState({
                        cargoCutDate: newCargoCutDate,
                        docCutDate: newDocCutDate,
                        etd: newEtd,
                        eta: newEta,
                        loading: false
                    }, () => {
                        if (document.getElementById('emailModal')) {
                            if (!M.Modal.getInstance(document.getElementById('emailModal')).isOpen) {
                                document.getElementById('exporterName').focus();
                            }
                        } else if (document.getElementById("excelEmailModal")) {
                            if (!M.Modal.getInstance(document.getElementById('excelEmailModal')).isOpen) {
                                document.getElementById('exporterName').focus();
                            }
                        }
                    })
                }
                else {
                    shippingInstructions.ladenOnBoardDate = shippingInstructions.ladenOnBoardDate ? moment.utc(shippingInstructions.ladenOnBoardDate).format('MM/DD/YY') : '';
                    this.setState({
                        booking, numOfContainers: booking.numOfContainers, vesselVoyage, portPair, vessel, invoice, dogObj: dOGArray,
                        ...shippingInstructions,
                        searchTerm: booking.lNumber, lNumber: booking.lNumber, bookingNumber: booking.bookingNumber, allConsignees,
                        aesCheckObject: {
                            ...this.state.aesCheckObject,
                            exporterId: shippingInstructions.exporterId,
                            exporterName: shippingInstructions.exporterName,
                            supplierId: shippingInstructions.supplierId,
                            supplierName: shippingInstructions.supplierName,
                            consigneeId: shippingInstructions.consigneeId,
                            consigneeName: shippingInstructions.consigneeName,
                            notifyId: shippingInstructions.notifyId,
                            notifyName: shippingInstructions.notifyName,
                            portOfLoadName: shippingInstructions.portOfLoadName,
                            placeOfReceiptName: shippingInstructions.placeOfReceiptName,
                            portOfDeliveryName: shippingInstructions.portOfDeliveryName,
                            portOfDischargeName: shippingInstructions.portOfDischargeName
                        }

                    },
                        () => this.getShipper(booking.shipperId))
                    this.getOriginalPartyNames(
                        shippingInstructions.exporterId,
                        shippingInstructions.exporterName,
                        shippingInstructions.supplierId,
                        shippingInstructions.supplierName,
                        shippingInstructions.consigneeId,
                        shippingInstructions.consigneeName,
                        shippingInstructions.notifyId,
                        shippingInstructions.notifyName
                    );
                    this.displayBadge();

                    this.generateStage(
                        vesselVoyage !== null ? vesselVoyage.docCutDate : "",
                        this.state.sentOrPrinted !== undefined ? this.state.sentOrPrinted : "",
                        this.state.invoice !== undefined ? this.state.invoice.invoiceStatus : ""
                    )

                    this.getEmails(vessel.carrierId)
                    const { cargoCutDate, docCutDate, etd, eta } = vesselVoyage;
                    let cargoMoment = moment.tz(new Date(cargoCutDate), this.getTimeZone(portPair.placeOfReceiptId));
                    let docMoment = moment.tz(new Date(docCutDate), this.getTimeZone(portPair.placeOfReceiptId));
                    let etdMoment = moment.tz(new Date(etd), this.getTimeZone(portPair.portOfLoadId));
                    let etaMoment = moment.tz(new Date(eta), this.getTimeZone(portPair.portOfDeliveryId));
                    let newCargoCutDate = cargoMoment.format('MM/DD/YY');
                    let newDocCutDate = docMoment.format('MM/DD/YY');
                    let newEtd = etdMoment.format('MM/DD/YY');
                    let newEta = etaMoment.format('MM/DD/YY');

                    this.setState({
                        cargoCutDate: newCargoCutDate,
                        docCutDate: newDocCutDate,
                        etd: newEtd,
                        eta: newEta,
                        loading: false
                    }, () => {
                        if (document.getElementById('emailModal')) {
                            if (!M.Modal.getInstance(document.getElementById('emailModal')).isOpen) {
                                document.getElementById('exporterName').focus();
                            }
                        } else if (document.getElementById("excelEmailModal")) {
                            if (!M.Modal.getInstance(document.getElementById('excelEmailModal')).isOpen) {
                                document.getElementById('exporterName').focus();
                            }
                        }
                    })
                }
            }
        })
    }

    // POSTING THE DATA FUNCTIONS
    ////////////////////////////////

    resetPrintAndDummy = () => {

        Axios.post('/api/v1/instructions/dummyreset', { ...auth.getAuthData(), id: this.state.id }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
            this.bookingLookup();
        })
    }

    saveAndGenerateExcelFile = () => {
        this.save(() => {
            this.generateExcelFile(false);
        })
    }

    generateExcelFile = (state, fileInfo) => {

        let datetime = moment(new Date()).format('MMDDYY_HHmmss');
        let stateObj = { ...this.state };
        let useCustomEmailBody = this.state.pdfEmailFormIsOpen || !state ? false : true;
        let body = this.state.pdfEmailFormIsOpen || !state ? "" : state.body;
        delete stateObj.allParties;
        delete stateObj.allConsignees;
        delete stateObj.lfAddresses;
        delete stateObj.filters;
        delete stateObj.filterList;
        this.hideExcelEmailForm();

        Axios.post('/api/v1/excel', { ...auth.getAuthData(), ...stateObj, datetime, body, useCustomEmailBody }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.data === "NO LF ADDRESS") {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "LF ADDRESS HAS NOT LOADED: RESEND", type: "warning" } })
            } else if (result.data === "NO DOG") {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "DESCRIPTION OF GOODS REQUIRED", type: "error" } })
            } else if (result.data.messageSent) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "E-Mail Sent", type: "success" } });
                await fileUploader.getUploadURLBuffer('smLineTemplate', datetime, new Buffer.from(result.data.newFileBuffer, 'base64'), this.state.lNumber, this.state.booking.bookingNumber[0], this.state.bookingId, auth.getAuthData());
                this.bookingLookup();
            } else if (!result.data.messageSent) {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "E-Mail Not Set", type: "error" } });
            }
        })
    }

    promptDownloadExcelFile = () => {
        let datetime = moment(new Date()).format("MMDDYY_HHmmss");
        let { bookingNumber, etd, lfAddress } = this.state;

        Axios.get('/api/v1/excel/file', { responseType: "arraybuffer", params: { ...auth.getAuthData(), bookingNumber, etd, lfAddress, datetime } }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else {
                let file = new Blob([result.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                // let mailAnchor = document.createElement("a");
                // mailAnchor.href="mailto:e-biz@smlines.com"
                let fileURL = URL.createObjectURL(file);
                let anchorTag = document.createElement('a');
                anchorTag.href = fileURL; anchorTag.target = '_blank';
                anchorTag.download = `SI_${bookingNumber}_${datetime}.xlsx`;
                document.body.appendChild(anchorTag);
                // document.body.appendChild(mailAnchor);
                // mailAnchor.click();
                anchorTag.click();
                document.body.removeChild(anchorTag);
                // document.body.removeChild(mailAnchor);
            }
        })
    }

    save = async callback => {
        let cancelSave;
        if (document.querySelectorAll(".misMatchedPort").length) {
            cancelSave = await this.portCheck();
        }

        if (parseInt(this.state.customTotal, 10) !== parseInt(this.state.byContainerTotal, 10)) {
            cancelSave = await this.containerWeightCheck();
        }

        if (!cancelSave) {
            if (!this.state.submitSiUser) {
                await this.setState({ submitSiUser: this.props.party.filter(p => p.id === this.state.shipperId)[0].submitSi })
            }
            if (!this.state.closeoutUser) {
                await this.setState({ closeoutUser: this.props.party.filter(p => p.id === this.state.shipperId)[0].closeout })
            }
            if (!this.state.proofUser || parseInt(this.state.proofUser) === 0) {
                await this.setState({ proofUser: this.props.party.filter(p => p.id === this.state.shipperId)[0].proof })
            }
            if (!this.state.isDummy && !this.state.numOfContainers && this.state.sentOrPrinted !== undefined) {
                if (this.state.sentOrPrinted) {
                    this.setState({ isDummy: true })
                }
            }

            let stateObj = { ...this.state };
            delete stateObj.allParties;
            delete stateObj.allConsignees;
            if (parseInt(this.state.paymentTerms) !== 1) { this.setState({ lcNumber: "" }) }
            Axios.post('/api/v1/instructions', {
                ...stateObj,
                ...auth.getAuthData(),
                bookingNumber: Array.isArray(this.state.bookingNumber) ? this.state.bookingNumber[0] : this.state.bookingNumber
            }).then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return;
                }
                if (typeof (result.data.insertId) === 'number') {
                    this.shippingInstructionsAesAlertCheck(this.state, this.state.aesCheckObject)
                    this.bookingLookup();
                    this.setState({ generatePdf: false });
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Instructions saved to database id: ${result.data.insertId}`, type: 'success' } });
                    if (typeof (callback) === 'function')
                        callback();
                } else if (result.data.sqlMessage === `Duplicate entry '${this.state.aesNumber.trim()}' for key '__aes_number_unique'` || result.data.sqlMessage === `Duplicate entry '${this.state.aesNumber.trim()}' for key 'shipping_instructions.__aes_number_unique'`) {
                    M.Modal.getInstance(document.querySelector("#aes-modal")).open();
                } else if (result.data.sqlMessage === `Duplicate entry '${this.state.billOfLadingNumber.trim()}' for key '__bl_number_unique'` || result.data.sqlMessage === `Duplicate entry '${this.state.billOfLadingNumber.trim()}' for key 'shipping_instructions.__bl_number_unique'`) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'B/L Number is Already in Use', type: 'warning' } });
                } else {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `An error occurred. Record not saved: ${result.data.errno}`, type: 'error' } })
                }
            })
        } else {
            return
        }

    }

    saveAndGenerate = (page, containers, changeCheckContainers, bookingId) => {
        return new Promise((resolve, reject) => {
            Axios.post('/api/v1/container', { page, containers, bookingId, ...auth.getAuthData() }).then(result => {

                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return resolve();
                }

                if (result.data.errno)
                    resolve(M.toast({ html: `<span class="${styles.errorToast}"><i class="material-icons red-text">error</i>${result.data.sqlMessage}</span>`, classes: styles.toast }));
                else {

                    this.containersAesAlertCheck(containers, changeCheckContainers);

                    const regCont = result.data.cont;
                    const plCont = result.data.plCont;
                    this.setState({ regCont, plCont, shouldRecalculate: true });

                    M.toast({ html: `<span class="${styles.successToast}"><i class="material-icons green-text">cloud_done</i>Successfully Saved</span>`, classes: styles.toast })
                    resolve();
                }
            });

        })
    }

    saveAndEmail = () => {
        this.save(() => {
            if (!this.state.aesNumber) {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'EMPTY AES NUMBER', type: 'error' } })
            }
            this.showEmailForm();
        })
    }

    addAlias = () => {
        if (!this.state.newAlias.toUpperCase().trim().length) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG,
                payload: {
                    type: "warning", msg: "Invalid Alias"
                }
            })
            return
        }
        if (typeof this.state[`${this.state.currentRole}Id`] === "string") {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG,
                payload: {
                    type: "warning", msg: "Cannot attach an alias to a party that is not in the system"
                }
            })
        }
        else if (this.state[`${this.state.currentRole}Id`]) {
            let party_id = this.state[`${this.state.currentRole}Id`]
            Axios.post("/api/v1/alias", { ...auth.getAuthData(), alias: this.state.newAlias.toUpperCase().trim(), party_id: party_id }).then((result) => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({
                        type: "SCORCHED_EARTH"
                    });
                    return;
                } else if (result.data.code === "ER_DUP_ENTRY") {
                    this.props.dispatch({
                        type: globalToastActions.UPDATE_MSG,
                        payload: {
                            type: "warning", msg: "That Alias Already Exists"
                        }
                    })
                    document.getElementById("newAlias").focus();
                    return;
                }
                this.setState({ newAlias: '' })
                Axios.get("/api/v1/alias", { params: auth.getAuthData() })
                    .then(result => {
                        if (result.data === "NOT AUTHENTICATED") {
                            localStorage.clear();
                            this.props.dispatch({
                                type: "SCORCHED_EARTH"
                            });
                            return;
                        } else if (Array.isArray(result.data)) {
                            this.props.dispatch({
                                type: init.INITIALIZE_STORE,
                                payload: { listName: "partyAlias", list: result.data }
                            });
                        }
                        let length = (document.getElementById("alias-list").childNodes.length - 3)
                        let focusName = "alias" + length
                        document.getElementById(focusName).focus()
                    });
            })
        }
    }

    deleteAlias = (id) => {
        Axios.post("/api/v1/alias/delete", { ...auth.getAuthData(), id: id }).then(() => {
            Axios.get("/api/v1/alias", { params: auth.getAuthData() })
                .then(result => {
                    if (result.data === "NOT AUTHENTICATED") {
                        localStorage.clear();
                        this.props.dispatch({
                            type: "SCORCHED_EARTH"
                        });
                        return;
                    } else if (Array.isArray(result.data)) {
                        this.props.dispatch({
                            type: init.INITIALIZE_STORE,
                            payload: { listName: "partyAlias", list: result.data }
                        });
                    }
                });
        })
    }

    printDoc = () =>
        this.save(() => {

            let { shippingInstructions, packingList, commercialInvoice, koreaCert, bse } = this.state.docType;
            let docVals = [shippingInstructions, packingList, commercialInvoice, koreaCert, bse]
            let docs = [{ shippingInstructions: shippingInstructions },
            { packingList: packingList },
            { commercialInvoice: commercialInvoice },
            { koreaCert: koreaCert },
            { bse: bse }];
            let docsPicked = docVals.filter(d => d === true).length

            if (!this.state.consigneeId || !this.state.notifyId) {
                if (!window.confirm("No Consignee/Notify Party selected. Some Documents will not be complete, would you like to continue?")) {
                    return;
                }
            }

            if (docs[0].shippingInstructions) {
                if (!this.state.aesNumber) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'EMPTY AES NUMBER', type: 'error' } });
                    return;
                };
            } else {
                if (!this.state.billOfLadingNumber && this.state.ladenOnBoardDate) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'EMPTY B/L NUMBER', type: 'error' } });
                } else if (!this.state.ladenOnBoardDate && this.state.billOfLadingNumber) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'EMPTY LADEN ON BOARD DATE', type: 'error' } });
                } else if (!this.state.billOfLadingNumber && !this.state.ladenOnBoardDate) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'EMPTY B/L NUMBER', type: 'error' } });
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'EMPTY LADEN ON BOARD DATE', type: 'error' } });
                };
            }

            if (docsPicked > 1) {


                let toBePrinted = [];
                let choices = docs.filter(d => d[Object.keys(d)] === true);

                choices.forEach(c => toBePrinted.push(Object.keys(c)));

                Axios.post(`/api/v1/pages/multiDoc`, {
                    ...auth.getAuthData(),
                    lfAddress: this.state.lfAddress,
                    bookingNumber: this.state.bookingNumber,
                    descriptionOfGoods: this.state.dogObj[this.state.docType],
                    cargoCutDate: this.state.cargoCutDate,
                    docCutDate: this.state.docCutDate,
                    eta: this.state.eta,
                    etd: this.state.etd,
                    toBePrinted

                }).then(result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' })
                        return;
                    }
                    if (result.data.msg) {
                        this.props.dispatch({
                            type: globalToastActions.UPDATE_MSG,
                            payload: {
                                msg: result.data.msg, type: "error"
                            }
                        })
                        return
                    }

                    let win = window.open('', '', 'width=960 height=1080');
                    win.document.write(result.data);
                    this.hideModal();
                    Axios.post('/api/v1/instructions/updateprintedby', {
                        ...auth.getAuthData(),
                        bookingNumber: this.state.bookingNumber,
                        numOfContainers: this.state.numOfContainers
                    }).then(result => {
                        if (result.data === 'NOT AUTHENTICATED') {
                            localStorage.clear();
                            this.props.dispatch({ type: 'SCORCHED_EARTH' })
                            return;
                        }
                        this.bookingLookup();
                    })
                })

            } else {

                let singleDoc = Object.keys(docs[docVals.indexOf(true)])[0];
                let datetime = moment(new Date()).format('MMDDYY_HHmmss');

                Axios.post(`/api/v1/pages/${singleDoc}`, {
                    ...auth.getAuthData(),
                    user: this.props.user.id,
                    bookingId: this.state.booking.id,
                    lfAddress: this.state.lfAddress,
                    lNumber: this.state.lNumber,
                    bookingNumber: this.state.bookingNumber,
                    descriptionOfGoods: this.state.dogObj[this.state.docType],
                    cargoCutDate: this.state.cargoCutDate,
                    docCutDate: this.state.docCutDate,
                    eta: this.state.eta,
                    etd: this.state.etd,
                    datetime,
                    fileType: singleDoc
                }).then(result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' })
                        return;
                    }
                    if (result.data.msg) {
                        this.props.dispatch({
                            type: globalToastActions.UPDATE_MSG,
                            payload: {
                                msg: result.data.msg, type: "error"
                            }
                        })
                        return
                    }

                    let win = window.open('', '', 'width=960 height=1080');
                    win.document.write(result.data);
                    this.hideModal();
                    Axios.post('/api/v1/instructions/updateprintedby', {
                        ...auth.getAuthData(),
                        bookingNumber: this.state.bookingNumber,
                        numOfContainers: this.state.numOfContainers
                    }).then(result => {
                        if (result.data === 'NOT AUTHENTICATED') {
                            localStorage.clear();
                            this.props.dispatch({ type: 'SCORCHED_EARTH' })
                            return;
                        }
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Document Filed', type: "success" } })
                        this.bookingLookup();
                    })
                })

            }

        })

    sendEmail = (emailInfoFromState, fileInfo) => {

        let datetime = moment(new Date()).format('MMDDYY_HHmmss');
        let buffer = new Buffer.from(emailInfoFromState.pdf, 'base64');
        delete emailInfoFromState.pdf;
        this.hideEmailForm();

        let userName = `${this.props.user.first.charAt(0)}${this.props.user.first.substring(1).toLowerCase()} ${this.props.user.last.charAt(0)}${this.props.user.last.substring(1).toLowerCase()}`;

        Axios.post("/api/v1/email/", { ...auth.getAuthData(), buffer, fileInfo, email: emailInfoFromState, userName, datetime, usePdf: true }).then(async (result) => {

            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear()
                this.props.dispatch({
                    type: "SCORCHED_EARTH"
                })
                return;
            }
            if (result.data.messageSent) {
                this.setState({ generatePdf: false, emailSubject: "" });
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "success", msg: "E-Mail Sent"
                    }
                });
                await fileUploader.getUploadURLBuffer('shippingInstructions', datetime, buffer, this.state.lNumber, this.state.booking.bookingNumber[0], this.state.bookingId, auth.getAuthData());
                this.bookingLookup();
            } else {
                this.setState({ generatePdf: false });
                window.alert("Error: Email was not sent.");
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "error", msg: "E-Mail Was Not Sent"
                    }
                });
            }
        })

    }

    //LIFECYCLE FUNCTIONS
    ////////////////////////////////////////////////////

    componentDidMount = async () => {

        this.initListener(); this.getAllParties();
        //  this.getConsignees();
        this.initMaterializeComponents();
        setTimeout(async () => {
            let lfAddresses = await this.getLfAddresses();
            this.setState({ lfAddress: lfAddresses[0].partyAddressId, lfAddresses });

        }, 3000);

    }

    componentDidUpdate = (prevProps, prevState) => {

        if (this.state.fileUploadTabOpen !== prevState.fileUploadTabOpen) {
            this.initMaterializeComponents();
        }

        if (prevProps !== this.props) {
            this.setState({ shipperId: this.props.shipperId, shipper: this.props.party.filter(p => p.id === this.props.shipperId)[0] })
        }

        if (prevState.originalExporterName !== this.state.originalExporterName && prevState.originalExporterName) {
            this.getConsignees();
        }
        if (this.props.location?.state?.searchTerm !== prevProps.location?.state?.searchTerm) {
            this.setState({
                shipperId: this.props.shipperId
            })
            this.getAllParties();
            this.setState({
                searchTerm: this.props.location?.state?.searchTerm
            })
            if (this.props.location?.state?.searchTerm) { setTimeout(() => this.bookingLookup(), 100); }
            else { this.setState({ searchTerm: "" }); setTimeout(() => this.bookingLookup(), 100) }
        }

        //UPDATE LOGIC TO MAINTAIN ALIAS INFORMATION AND STILL ALLOW RETENTION OF ORIGINAL CONSIGNEE NAME

        //KEEPING PARTY INFO THE SAME IF SAME PARTY BOX IS CHECKED
        if (!this.state.loading) {

            //CHECKS WHETHER THE ALIAS BOX SHOULD BE CHECKED IF THE FIELDS ARE EMPTY 
            if (!this.state.supplierName && !this.state.originalSupplierName && this.state.supUseAlias) {
                this.setState({ supUseAlias: false })
            }
            if (!this.state.consigneeName && !this.state.originalSupplierName && this.state.conUseAlias) {
                this.setState({ conUseAlias: false })
            }
            if (!this.state.notifyName && !this.state.originalNotifyName && this.state.notUseAlias) {
                this.setState({ notUseAlias: false })
            }

            //UPDATES TO MAKE SURE THE SI SHIPPER IS ALWAYS THE SAME AS THE BOOKING SHIPPER
            // if (this.state.exporterId) {
            //     if (this.state.shipper.id !== this.state.exporterId) {
            //         this.setState({
            //             exporterId: this.state.shipper.id,
            //             exporterName: this.state.shipper.name,
            //             originalExporterName: this.state.shipper.name,
            //             exporterAddress: this.state.shipper.printableAddress,
            //             exporterPhone: this.state.shipper.mainPhone,
            //             exporterFax: this.state.shipper.mainFax,
            //             exporterEmail: this.state.shipper.mainEmail
            //         })
            //     }
            // }

            // SECTION FOR MONITORING AND UPDATING PARTY INFORMATION WHEN SAME AS CHECKBOXES ARE CHECKED
            if (this.state.supplierSameAsShipper) {
                if (prevState.exporterName !== this.state.exporterName) {
                    this.setState({
                        supplierName: this.state.exporterName,
                        supplierId: this.state.exporterId,
                        supplierAddress: this.state.exporterAddress,
                        supplierPhone: this.state.exporterPhone,
                        supplierFax: this.state.exporterFax,
                        supplierEmail: this.state.exporterEmail
                    })
                }
                if (prevState.exporterAddress !== this.state.exporterAddress) {
                    this.setState({
                        supplierAddress: this.state.exporterAddress
                    })
                }
                if (prevState.exporterPhone !== this.state.exporterPhone) {
                    this.setState({
                        supplierPhone: this.state.exporterPhone
                    })
                }
                if (prevState.exporterFax !== this.state.exporterFax) {
                    this.setState({
                        supplierFax: this.state.exporterFax
                    })
                }
                if (prevState.exporterEmail !== this.state.exporterEmail) {
                    this.setState({
                        supplierEmail: this.state.exporterEmail
                    })
                }
            }
            if (this.state.notifySameAsConsignee) {
                //CONSIGNEE NOTIFY CONNECTIONS
                if (prevState.consigneeName !== this.state.consigneeName) {
                    this.setState({
                        notifyName: this.state.consigneeName,
                        notifyId: this.state.consigneeId,
                        notifyAddress: this.state.consigneeAddress,
                        notifyPhone: this.state.consigneePhone,
                        notifyFax: this.state.consigneeFax,
                        notifyEmail: this.state.consigneeEmail
                    })
                }
                if (prevState.consigneeAddress !== this.state.consigneeAddress) {
                    this.setState({
                        notifyAddress: this.state.consigneeAddress
                    })
                }
                if (prevState.consigneePhone !== this.state.consigneePhone) {
                    this.setState({
                        notifyPhone: this.state.consigneePhone
                    })
                }
                if (prevState.consigneeFax !== this.state.consigneeFax) {
                    this.setState({
                        notifyFax: this.state.consigneeFax
                    })
                }
                if (prevState.consigneeEmail !== this.state.consigneeEmail) {
                    this.setState({
                        notifyEmail: this.state.consigneeEmail
                    })
                }
            }

            //LOGIC FOR RESETING THE NAME OF THE PARTY IF ALIAS IS UNCHECKED
            //AFTER IT IS OR HAS BEEN CHECKED ON LOAD
            if (!this.state.expUseAlias) {
                if (prevState.expUseAlias !== this.state.expUseAlias) {
                    if (this.props.partyAlias.filter(p => p.alias === this.state.exporterName).length) {
                        let alias = this.props.partyAlias.filter(p => p.alias === this.state.exporterName)[0].partyId
                        let party = this.props.party.filter(p => p.partyId === alias)[0].name
                        this.setState({ exporterName: party })
                    }

                }
            }
            if (!this.state.supUseAlias) {
                if (prevState.supUseAlias !== this.state.supUseAlias) {
                    if (this.props.partyAlias.filter(p => p.alias === this.state.supplierName).length) {
                        let alias = this.props.partyAlias.filter(p => p.alias === this.state.supplierName)[0].partyId
                        let party = this.props.party.filter(p => p.partyId === alias)[0].name
                        this.setState({ supplierName: party })
                    }

                }
            }
            if (!this.state.conUseAlias) {
                if (prevState.conUseAlias !== this.state.conUseAlias) {
                    if (this.props.partyAlias.filter(p => p.alias === this.state.consigneeName).length) {
                        let alias = this.props.partyAlias.filter(p => p.alias === this.state.consigneeName)[0].partyId
                        let party = this.props.party.filter(p => p.partyId === alias)[0].name
                        this.setState({ consigneeName: party })
                    }

                }
            }
            if (!this.state.notUseAlias) {
                if (prevState.notUseAlias !== this.state.notUseAlias) {
                    if (this.props.partyAlias.filter(p => p.alias === this.state.notifyName).length) {
                        let alias = this.props.partyAlias.filter(p => p.alias === this.state.notifyName)[0].partyId
                        let party = this.props.party.filter(p => p.partyId === alias)[0].name
                        this.setState({ notifyName: party })
                    }

                }
            }
        }
    }

    componentWillUnmount = () => { this.removeListener(); }

    getInfoHeader = () => {
        let shipper = this.props.shipper.filter(s => s.id === this.props.location?.state?.shipperId).length ? this.props.shipper.filter(s => s.id === this.props.location.state.shipperId)[0].name : null
        if (this.state.fileUploadTabOpen) {
            return <div className="col s12 l6 bookingHeaderInfo">

                <div className="row" style={{ marginBottom: "0px", fontWeight: 700 }}>
                    <div className="col s6">{this.displayBookingNumber()} / {this.state.lNumber}</div>
                    <div className="col s6">{shipper}</div>
                </div>
            </div>
        } else {
            return null
        }
    }

    render = () => (
        <div>
            <div className={`row siAndBookingTabs`}>
                <div className="col s12 l6" style={{ paddingLeft: "0px" }}>
                    <ul className={`tabs siTabs`} id="tabs" style={{ borderBottom: "0px" }}>
                        <li id="bookingTab" className={`tab col s3 ${this.getBookingTabStyling()}`}>
                            <a tabIndex="-1" href="#BOOKING" onClick={this.clickZoom}>Booking</a>
                        </li>
                        <li id="fileUploadTab" className={`tab col s3 ${this.getUploadTabStyling()}`}>
                            <a tabIndex="-1" href="#FILEUPLOAD" onClick={e => this.setActive(e, 'fileUploadTab')}>
                                Files{" "}
                                {this.state.loading && this.props.location?.state?.searchTerm ?
                                    <div className="preloader-wrapper small active fileCountSpinner">
                                        <div className="spinner-layer spinner-green-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div><div className="gap-patch">
                                                <div className="circle"></div>
                                            </div><div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    this.state.bookingFilesCount
                                }
                            </a></li>
                        <li id="siTab" className={`tab col s3 active ${this.getShippingInstructionTabStyling()}`}>
                            <a tabIndex="-1" href="#SHIPPINGINSTRUCTIONS" onClick={e => this.setActive(e, 'siTab')}>Shipping Instructions</a>
                        </li>
                        {this.state.sentOrPrinted && !this.state.isDummy ?
                            <li className={`tab col s1 stickyNoteButtonTab ${this.getStickyNoteTabStyling()} `} onClick={this.openStickyNoteModal}>
                                <i className="material-icons stickyNoteButtonIcon">speaker_notes</i>
                            </li>
                            :
                            null}
                    </ul>
                </div>
                {this.getInfoHeader()}
            </div>

            {this.state.loading && this.props.location?.state?.searchTerm ?
                <div className="preloader-wrapper big active bookingLoader">
                    <div className="spinner-layer spinner-blue-only">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div><div className="gap-patch">
                            <div className="circle"></div>
                        </div><div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div> :
                <div>
                    <div id="shippingInstructions" className="siWrapper">
                        <div style={{ position: 'relative' }}>
                            <div className="row" style={{ "marginBottom": "15px" }}>
                                <div className="col s3 l3 xl3">
                                    <b>{`${this.displayBookingNumber()} / ${this.state.lNumber}`}</b><br></br>
                                    <b>Shipper Ref #: {this.state.booking ? this.state.booking.shipperReferenceNumber : ''}</b>
                                </div>
                                <h5 className="col s2 l3 xl3 center" >Shipping Instructions</h5><br></br>
                            </div>
                            <div className="row" style={{ marginBottom: "0px" }}>
                                <div className="col s6 m6 l6 xl3 ">
                                    <div className="row">
                                        <Form.Section label={`Exporter`} col="partyCard">
                                            <div className="row rowMarginRemove">
                                                {this.state.expUseAlias ?
                                                    <div className="row">
                                                        <div className="col s12">
                                                            <Form.TextInput tabIndex="1" col="s12" name="originalExporterName" stylingClass="disabledRow" label="Name"
                                                                value={this.state.originalExporterName} onChange={this.partyOnChange} disabled={true} />
                                                        </div>
                                                    </div>
                                                    : null}

                                                {this.state.expUseAlias ?
                                                    <Form.TextInput tabIndex="1" col="s12" stylingClass={`aliasDisplay`} name="exporterName"
                                                        label="Alias" disabled={true} value={this.state.exporterName} />
                                                    :
                                                    <Form.Select tabIndex="1" col="s8" name="exporterName"
                                                        label="Name" value={this.state.exporterId} onChange={this.partyOnChange} list={this.props.shipper} filter={{ label: "name", value: "id" }} />
                                                }

                                                <Form.CheckBox tabIndex="1" col="s5 useAliasCheckbox" className="siCheckbox" name="expUseAlias" label="Use Alias" onChange={this.onChange} value={this.state.expUseAlias} />
                                                {this.state.expUseAlias ? <div className="row rowMarginRemove">


                                                    <Form.Button tabIndex="1" col="s3 siAliasButtonsWidth buttonHalfDrop onlyPartyButtons right" round label="Alias" color="blue" onClick={() => { this.showAliasModal("exporter") }} />

                                                </div> : null}
                                            </div>
                                            <Form.TextArea tabIndex="1" col="s12" id="exporterAddress" className="addressBox" name="exporterAddress" label="Address" onChange={this.onChange} value={this.state.exporterAddress} height="84px" />
                                            <Form.Button tabIndex="1" styling="" col={`s5 exporterAddressButton siAddressButtonsWidth ${this.props.partyAddress.filter((a) => a.partyId === this.state.exporterId && !a.isArchived).length > 1 ? "" : "hideButton"}`} round label="Swap Add." inlineStyle={{ float: "none" }} color="green" onClick={() => { this.showAddModal("exporter") }} />
                                            <Form.CheckBox tabIndex="1" col="s7 right exporterCheckBox" className="siCheckbox " name="supplierSameAsShipper" label="Shipper is Supplier" onChange={this.sameAsOnChange} value={this.state.supplierSameAsShipper} />
                                        </Form.Section>
                                        {
                                            this.state.supplierSameAsShipper ? null : (
                                                <Form.Section col="s12 partyCard " label="Supplier">
                                                    <div className="row rowMarginRemove">
                                                        {this.state.supUseAlias ?
                                                            <div className="row">
                                                                <div className="col s12">
                                                                    <Form.TextInput tabIndex="1" col="s12" name="originalSupplierName"
                                                                        stylingClass="disabledRow" label="Name" disabled={true}
                                                                        value={this.state.originalSupplierName} /></div></div> : null}
                                                        {this.state.supUseAlias ?
                                                            <Form.TextInput tabIndex="1" col="s12" name="supplierName" stylingClass="aliasDisplay" label="Alias"
                                                                value={this.state.supplierName} onChange={this.partyOnChange} disabled={true} />

                                                            : <Form.Select tabIndex="1" col="s8" name="supplierName" label="Name"
                                                                value={this.state.supplierId} onChange={this.partyOnChange} list={this.state.allParties} placeholder="Choose a Party" filter={{ label: "name", value: "id" }} />}

                                                        <Form.CheckBox tabIndex="1" col="s5 useAliasCheckbox" className="siCheckbox" name="supUseAlias" label="Use Alias" onChange={this.onChange} value={this.state.supUseAlias} />
                                                        {this.state.supUseAlias ? <div className="row rowMarginRemove">
                                                            <Form.Button tabIndex="1" col="s2 siAliasButtonsWidth buttonHalfDrop onlyPartyButtons right" round label="Alias" color="blue" onClick={() => { this.showAliasModal("supplier") }} />
                                                        </div> : null}
                                                    </div>
                                                    <Form.TextArea tabIndex="1" id="supplierAddress" className="addressBox" col="s10" name="supplierAddress" label="Address" onChange={this.onChange} value={this.state.supplierAddress} height="84px" />
                                                    <Form.Button tabIndex="1" col={`s5 siAddressButtonsWidth customMarginRemove supplierAddressButton onlyPartyButtons ${this.props.partyAddress.filter((a) => a.partyId === this.state.supplierId && !a.isArchived).length > 1 ? "" : "hideButton"}`} round label="Swap Add." color="green" onClick={() => { this.showAddModal("supplier") }} />
                                                </Form.Section>
                                            )}
                                    </div>
                                </div>
                                <div className="col s6 m6 l6 xl3" style={{ marginBottom: "20px" }}>
                                    <Form.Section label="Consignee" col="partyCard">
                                        <div className="row rowMarginRemove">
                                            {this.state.conUseAlias ? <div className="row">
                                                <div className="col s12">
                                                    <Form.TextInput tabIndex="1" col="s12"
                                                        name="originalConsigneeName" stylingClass="disabledRow"
                                                        label="Name" disabled={true}
                                                        value={this.state.originalConsigneeName} />
                                                </div>
                                            </div> : null}
                                            {this.state.conUseAlias ?
                                                <Form.TextInput tabIndex="1" col="s12" name="consigneeName" stylingClass="aliasDisplay" label="Alias"
                                                    value={this.state.consigneeName} onChange={this.partyOnChange} disabled={true} />

                                                : <Form.Select tabIndex="1" col="s8" name="consigneeName" label="Name"
                                                    value={this.state.consigneeId} onChange={this.partyOnChange}
                                                    list={this.state.allConsignees} placeholder="Choose a Party"
                                                    filter={{ label: "name", value: "id" }} />}

                                            <Form.CheckBox tabIndex="1" col="s5 useAliasCheckbox" className="siCheckbox" name="conUseAlias" label="Use Alias" onChange={this.onChange} value={this.state.conUseAlias} />
                                            {this.state.conUseAlias ? <div className="row rowMarginRemove">

                                                <Form.Button tabIndex="1" col="s3 buttonHalfDrop siAliasButtonsWidth onlyPartyButtons right" round label="Alias" color="blue" onClick={() => { this.showAliasModal("consignee") }} />

                                            </div> : null}
                                        </div>
                                        <Form.TextArea tabIndex="1" id="consigneeAddress" className="addressBox" col="s12" name="consigneeAddress" label="Address" onChange={this.onChange} value={this.state.consigneeAddress} height="84px" />
                                        <Form.Button tabIndex="1" col={`s5 siAddressButtonsWidth consigneeAddressButton ${this.props.partyAddress.filter((a) => a.partyId === this.state.consigneeId && !a.isArchived).length > 1 ? "" : "hideButton"}`} inlineStyle={{ float: "none" }} round label="Swap Add." color="green" onClick={() => { this.showAddModal("consignee") }} />
                                        <Form.CheckBox tabIndex="1" col="s7 right consigneeCheckBox" className="siCheckbox" name="notifySameAsConsignee" label="Consignee is Notify" onChange={this.sameAsOnChange} value={this.state.notifySameAsConsignee} />
                                    </Form.Section>
                                    {
                                        this.state.notifySameAsConsignee ? null : (
                                            <Form.Section col="s12 partyCard " label="Notify">
                                                <div className="row rowMarginRemove">
                                                    {this.state.notUseAlias ? <div className="row "><div className="col s12 "><Form.TextInput tabIndex="1" col="s12" stylingClass="disabledRow" name="originalNotifyName" label="Name" disabled={true} value={this.state.originalNotifyName} /></div></div> : null}
                                                    {this.state.notUseAlias ?
                                                        <Form.TextInput tabIndex="1" col="s12" stylingClass="aliasDisplay" name="notifyName" label="Alias"
                                                            onChange={this.partyOnChange} disabled={true} value={this.state.notifyName} />

                                                        : <Form.Select tabIndex="1" col="s8" name="notifyName" label="Name"
                                                            value={this.state.notifyId} onChange={this.partyOnChange} list={this.state.allConsignees} placeholder="Choose a Party" filter={{ label: "name", value: "id" }} />}
                                                    <Form.CheckBox tabIndex="1" col="s5 useAliasCheckbox" className="siCheckbox" name="notUseAlias" label="Use Alias" onChange={this.onChange} value={this.state.notUseAlias} />
                                                    {this.state.notUseAlias ? <div className="row rowMarginRemove">

                                                        <Form.Button tabIndex="1" col="s3 siAliasButtonsWidth buttonHalfDrop onlyPartyButtons right" round label="Alias" color="blue" onClick={() => { this.showAliasModal("notify") }} />

                                                    </div> :

                                                        null}
                                                </div>
                                                <div className="row noMargin">
                                                    <Form.TextArea tabIndex="1" col="s10" id="notifyAddress" className="addressBox" name="notifyAddress" label="Address" onChange={this.onChange} value={this.state.notifyAddress} height="84px" />
                                                    <Form.Button tabIndex="1" col={`s5 noMargin notifyAddressButton siAddressButtonsWidth onlyPartyButtons ${this.props.partyAddress.filter((a) => a.partyId === this.state.notifyId && !a.isArchived).length > 1 ? "" : "hideButton"}`} round label="Swap Add." color="green" onClick={() => { this.showAddModal("notify") }} />
                                                </div>
                                            </Form.Section>)
                                    }
                                </div>
                                <div className="col s12 m12 l12 xl6">
                                    <div className="row" style={{ marginBottom: "0px" }}>


                                        <Form.Section col=" bookingDetails">
                                            {this.state.booking.bookingNumber ?
                                                <div className="row" style={{ marginBottom: "5px" }}>
                                                    <div className="row" style={{ marginBottom: "0px" }}>
                                                        <div className="col s3 m6 l6 xl6   ">
                                                            {this.state.booking.bookingNumber ?
                                                                <div>
                                                                    <p className="col s12">Containers: {this.state.booking.containerCount} {this.getContainerType()}</p>
                                                                    <p className="col s12">Carrier: {this.state.vessel.carrierId ? this.props.carrier.filter(c => c.id === this.state.vessel.carrierId)[0].name : null}</p>
                                                                    <p className="col s12">Vessel: {this.state.vessel.name}</p>
                                                                    <p className="col s12">Voyage #: {this.state.vesselVoyage.voyageNumber}</p>
                                                                </div>
                                                                : null}
                                                        </div>
                                                        <div className="col s2 m6 l6 xl6">
                                                            {this.getDates().map((date, index) => <p className={`col s12 date-${index}`} key={index}>{date.name}: {date.date}</p>)}
                                                        </div>
                                                    </div>
                                                    <label className={`col s7 ${this.state.portPair ? this.getLocationCode(this.state.portPair.placeOfReceiptId) !== this.state.placeOfReceiptName ? 'misMatchedPort' : '' : ''}`}>
                                                        <b>PLR : </b>{`${this.state.portPair ? this.getLocationCode(this.state.portPair.placeOfReceiptId) : ''}`}</label>
                                                    <Form.TextInput col="s5" stylingClass="cutOffs" name="placeOfReceiptName" onChange={this.onChange} value={this.state.placeOfReceiptName} tabIndex="-1" />
                                                    <label className={`col s7 ${this.state.portPair ? this.getLocationCode(this.state.portPair.portOfLoadId) !== this.state.portOfLoadName ? 'misMatchedPort' : '' : ''}`}>
                                                        <b>POL : </b>{`${this.state.portPair ? this.getLocationCode(this.state.portPair.portOfLoadId) : ''}`}</label>
                                                    <Form.TextInput col="s5" stylingClass="cutOffs" name="portOfLoadName" onChange={this.onChange} value={this.state.portOfLoadName} tabIndex="-1" />
                                                    <label className={`col s7 ${this.state.portPair ? this.getLocationCode(this.state.portPair.portOfDischargeId) !== this.state.portOfDischargeName ? 'misMatchedPort' : '' : ''}`}>
                                                        <b>POD : </b>{`${this.state.portPair ? this.getLocationCode(this.state.portPair.portOfDischargeId) : ''}`}</label>
                                                    <Form.TextInput col="s5" stylingClass="cutOffs" name="portOfDischargeName" onChange={this.onChange} value={this.state.portOfDischargeName} tabIndex="-1" />
                                                    <label className={` col s7 ${this.state.portPair ? this.getLocationCode(this.state.portPair.portOfDeliveryId) !== this.state.portOfDeliveryName ? 'misMatchedPort' : '' : ''}`}>
                                                        <b>PLD : </b>{`${this.state.portPair ? this.getLocationCode(this.state.portPair.portOfDeliveryId) : ''}`}</label>
                                                    <Form.TextInput col="s5" stylingClass="cutOffs" name="portOfDeliveryName" onChange={this.onChange} value={this.state.portOfDeliveryName} tabIndex="-1" />
                                                </div>
                                                :
                                                <h5>No Booking Selected</h5>}
                                        </Form.Section>
                                    </div>

                                    <div className="row createdBy">
                                        <Form.Section col="createdBy">
                                            <div className="row">
                                                <div className="col s12 l12">
                                                    <p><b>Created By:</b>{this.getUserName(this.state.createdBy)} {this.parseTime(this.state.datetimeCreated)}</p>
                                                    <p><b>Modified By:</b> {this.getUserName(this.state.lastModifiedBy)} {this.parseTime(this.state.datetimeModified)}</p>
                                                    <p><b>Printed By:</b>{this.getUserName(this.state.lastPrintedBy)} {this.parseTime(this.state.datetimePrinted)}</p>
                                                    <p><b>Emailed By:</b>{this.getUserName(this.state.lastSentBy)} {this.parseTime(this.state.datetimeSent)}</p>
                                                </div>
                                            </div>
                                        </Form.Section>
                                    </div>

                                </div>
                            </div>
                            {this.state.paymentTerms === 1 || this.state.paymentTerms === "1" ?

                                <div className="row" style={{ marginBottom: "10px" }}>
                                    <Form.SelectSmall tabIndex="1" col="s2" name="paymentTerms" label="Payment Terms" onChange={this.onChange} placeholder=" " value={this.state.paymentTerms} list={this.props.paymentTerms} filter={{ label: "term", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInputSmall tabIndex="1" col="s2 lcNumber" name="lcNumber" label="LC #" onChange={this.onChange} value={this.state.lcNumber} maxLength="20" flags={[flags.ALLOW_EMPTY]} />
                                    <Form.SelectSmall tabIndex="1" col="s2 freightTerms" name="freightTerms" label="Freight Terms" onChange={this.onChange} placeholder=" " value={this.state.freightTerms} list={this.props.freightTerms} filter={{ label: "term", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                    {this.state.submissionType === "2" || this.state.submissionType === 2 ?
                                        <div>
                                            <Form.CheckBoxSmall tabIndex="1" col="col s1 m1 l1 submittedCheckbox" className="submittedCheckbox" name="submittedFromWeb" label="Submitted" onChange={this.onChange} value={this.state.submittedFromWeb} />
                                            <Form.SelectSmall tabIndex="1" col="s1 m1 l1" name="submissionType" label="Sub. Type" onChange={this.submissionTypeOnChange} placeholder=" " value={this.state.submissionType} list={this.props.submissionType} filter={{ label: "type", value: "id" }} flags={[flags.ALLOW_EMPTY]} />

                                            <Form.SelectSmall tabIndex="1" col="s2 m2 l2" name="blType" label="BL Type" onChange={this.onChange} placeholder=" " value={this.state.blType} list={this.props.blType} filter={{ label: "type", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.SelectSmall tabIndex="1" col="s2 m2 l2" name="proofUser" label="User" onChange={this.onChange} onBlur={this.generateExportString} placeholder=" " value={this.state.proofUser} list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />

                                        </div>
                                        :
                                        <div>
                                            <Form.SelectSmall tabIndex="1" col="s2" name="submissionType" label="Submission Type" onChange={this.submissionTypeOnChange} placeholder=" " value={this.state.submissionType} list={this.props.submissionType} filter={{ label: "type", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.SelectSmall tabIndex="1" col="s2" name="blType" label="BL Type" onChange={this.onChange} placeholder=" " value={this.state.blType} list={this.props.blType} filter={{ label: "type", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.SelectSmall tabIndex="1" col="s2" name="proofUser" label="User" onChange={this.onChange} onBlur={this.generateExportString} placeholder=" " value={this.state.proofUser} list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                        </div>
                                    }
                                </div>
                                :
                                <div className="row" style={{ marginBottom: "10px" }}>
                                    <Form.SelectSmall tabIndex="1" col="s2" name="paymentTerms" label="Payment Terms" onChange={this.onChange} placeholder=" " value={this.state.paymentTerms} list={this.props.paymentTerms} filter={{ label: "term", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.SelectSmall tabIndex="1" col="s2 freightTerms" name="freightTerms" label="Freight Terms" onChange={this.onChange} placeholder=" " value={this.state.freightTerms} list={this.props.freightTerms} filter={{ label: "term", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                    {this.state.submissionType === "2" || this.state.submissionType === 2 ?
                                        <div>
                                            <Form.CheckBoxSmall tabIndex="1" col="s1 m1 l1 submittedCheckbox" className="submittedCheckbox" name="submittedFromWeb" label="Submitted" onChange={this.onChange} value={this.state.submittedFromWeb} />
                                            <Form.SelectSmall tabIndex="1" col="s2 m2 l2" name="submissionType" label="Sub. Type" onChange={this.submissionTypeOnChange} placeholder=" " value={this.state.submissionType} list={this.props.submissionType} filter={{ label: "type", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.SelectSmall tabIndex="1" col="s2 m2 l2" name="blType" label="BL Type" onChange={this.onChange} placeholder=" " value={this.state.blType} list={this.props.blType} filter={{ label: "type", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.SelectSmall tabIndex="1" col="s3 m3 l3" name="proofUser" label="User" onChange={this.onChange} onBlur={this.generateExportString} placeholder=" " value={this.state.proofUser} list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                        </div>
                                        :
                                        <div>
                                            <Form.SelectSmall tabIndex="1" col="s2" name="submissionType" label="Submission Type" onChange={this.submissionTypeOnChange} placeholder=" " value={this.state.submissionType} list={this.props.submissionType} filter={{ label: "type", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.SelectSmall tabIndex="1" col="s2" name="blType" label="BL Type" onChange={this.onChange} placeholder=" " value={this.state.blType} list={this.props.blType} filter={{ label: "type", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.SelectSmall tabIndex="1" col="s4" name="proofUser" label="User" onChange={this.onChange} onBlur={this.generateExportString} placeholder=" " value={this.state.proofUser} list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                        </div>
                                    }
                                </div>
                            }
                            <div className="row">
                                <Form.TextArea tabIndex="2" id="internalNotes" col="col s6 exportAndInternal" name="internalNotes" labelId="internalNotesLabel" label="Internal Notes" onChange={this.onChange} value={this.state.internalNotes} onInput={() => this.resizeTextArea("internalNotes")} />

                                <Form.TextArea tabIndex="1" id="exportInstructions" col="col s6 exportAndInternal" name="exportInstructions" labelId="exportInstructionsLabel" label="Export Instructions" onChange={this.onChange} value={this.state.exportInstructions} onInput={() => this.resizeTextArea("exportInstructions")} />

                            </div>
                            <div className="row containersRow">
                                <div className="col s12">

                                    {this.state.badge.type === "CNCL" ? <div className="cancelStamp" unselectable="on">CANCELLED</div> : null}
                                    <Containers saveAndGenerate={this.saveAndGenerate} booking={this.state.booking} page={this.state.dogPage} setState={this.onChange} />

                                </div>
                            </div>
                            <div style={{ position: 'absolute', top: '0px', right: '0px', width: '25vw' }}>
                                <div className="row">
                                    <div className="s6" style={{ "marginLeft": "-250px" }}>
                                        <Form.CheckBox tabIndex="2" col="col s1 " className="siCheckbox" name="isDummy" label="Dummy" onChange={this.onChange} value={this.state.isDummy} />
                                        {/* <span><i className="material-icons stage SIstage ic" style={{ color: this.state.stageColor }} onContextMenu={this.handleButtonContextMenu}>{this.state.stageIcon}</i></span> */}
                                        {this.getStageIcon()}
                                        <span id={this.state.badge.id} className={`badge bookingBadge SIbadge ${this.state.badge.color} z-depth-0 offset-s4`}>{this.state.badge.type}</span>
                                    </div>
                                    <div className="s6" style={{ "top": "-23px" }}>
                                        <Form.DateInput tabIndex="2" col="s6" name="ladenOnBoardDate" label="Laden On Board" onChange={this.onChange} value={this.state.ladenOnBoardDate} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.TextInput tabIndex="2" col="s6" name="billOfLadingNumber" label="B/L Number" onChange={this.onChange} value={this.state.billOfLadingNumber} flags={[flags.ALLOW_EMPTY]} />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "-10px" }}>
                                    <Form.TextInput tabIndex="2" col="s12" name="aesNumber" label="AES #" onChange={this.onChange} value={this.state.aesNumber} />
                                </div>
                            </div>
                            <TabsContainer
                                id={this.state.id}
                                dogPage={this.state.dogPage}
                                onChange={this.onChange}
                                dogOnChange={this.dogOnChange}
                                arrayOnChange={this.arrayOnChange}
                                shipper={this.state.originalExporterName}
                                dogObj={{ ...this.state.dogObj }}
                                deliveryCity={this.state.portOfDeliveryName ? this.state.portOfDeliveryName.split(",")[0] : ""}
                                showCiLineItemModal={this.showCiLineItemModal}
                                shouldRecalculate={this.state.shouldRecalculate}
                                shippingId={this.state.id}
                                conParseChange={this.conParseChange}
                                regContainerData={this.state.regCont}
                                plContainerData={this.state.plCont}
                                customTotal={this.state.customTotal}
                                supplierSameAsShipper={this.state.supplierSameAsShipper}
                                originalExporterName={this.state.originalExporterName}
                                originalSupplierName={this.state.originalSupplierName}
                            />
                            <div style={{ marginTop: '18px' }} className="row">
                                <Form.Button col="col s3 m2" color="green" round type="outline" onClick={this.save} icon="save" label="Save Instructions" />
                                <Form.Button col="col s2" color="blue" round type="outline" onClick={this.saveAndEmail} icon="email" label="Save & Email" />
                                <Form.Button col="col s3 m2" color="blue" round type="outline" onClick={this.showModal} icon="print" label="Save & Print" />
                                <Form.Button col="col s3 m2" round type={(this.state.ladenOnBoardDate && !this.state.booking.isDummy && this.state.billOfLadingNumber) ? "outline" : ""} onClick={this.goToInvoice} icon="list" label="Save & Invoice" color={(this.state.ladenOnBoardDate && !this.state.booking.isDummy && this.state.billOfLadingNumber) ? "black" : "gray"} />
                                {this.state.vesselVoyage.carrierId === 13 ?
                                    <div>
                                        <Form.Button col="col s3 m2" color="filledGreen" type="outline" onClick={this.saveAndGenerateExcelFile} icon='send' label="Save & Send Excel" />
                                        {/* <Form.Button col="col s3 m2" color="filledGreen" type="outline" onClick={this.showExcelEmailForm} icon='email' label="Compose Excel" /> */}
                                        <Form.Button col="col s3 m2" color="filledGreen" type="outline" onClick={this.promptDownloadExcelFile} icon="download" label="Download Excel" />
                                    </div>
                                    :
                                    null}
                            </div>

                        </div >
                    </div>



                    <div id="fileUpload" className="hideTab">
                        {this.state.bookingId ?
                            <FileUpload docsEmails={this.state.docsEmails} fileUploadTabOpen={this.state.fileUploadTabOpen} directory="SHIPMENTS/" bookingNumber={this.state.bookingNumber} isDisplayed={this.state.isDisplayed} bookingId={this.state.bookingId} lNumber={this.state.lNumber} />
                            :
                            null}
                    </div>




                </div>
            }
            {this.state.fileUploadTabOpen ?
                null :
                this.state.pdfEmailFormIsOpen ?

                    <EmailForm
                        onChange={this.onChange}
                        generatePdf={this.state.generatePdf}
                        fileType='shippingInstructions'
                        fileInfo={{
                            id: this.state.id,
                            fileType: 'shippingInstructions',
                            submissionType: this.state.submissionType,
                            submittedFromWeb: this.state.submittedFromWeb,
                            datetimePrinted: this.state.datetimePrinted ? this.state.datetimePrinted : null,
                            lfAddress: this.state.lfAddress,
                            bookingNumber: this.state.bookingNumber,
                            descriptionOfGoods: this.state.dogObj['shippingInstructions'],
                            cargoCutDate: this.state.cargoCutDate,
                            docCutDate: this.state.docCutDate,
                            eta: this.state.eta,
                            etd: this.state.etd
                        }}
                        emails={this.state.emails}
                        subject={this.state.emailSubject}
                        showEmailModal={this.showEmailForm}
                        hideEmailModal={this.hideEmailForm}
                        send={this.sendEmail}
                    /> :
                    <EmailForm
                        id={'excelEmailModal'}
                        onChange={this.onChange}
                        generatePdf={false}
                        emails={this.state.emails}
                        showEmailModal={this.showExcelEmailForm}
                        hideEmailModal={this.hideExcelEmailForm}
                        subject={this.state.emailSubject}
                        send={this.generateExcelFile}
                        attachments={this.state.attachments}
                    />
            }
            {/* This right here is the Sticky Notes component :)  */}
            <StickyNote
                id="bookingAndSiStagesWindow"
                shipment={this.state}
                getBookingNumbers={this.displayBookingNumber}
                closeModal={this.closeStickyNoteModal}
                shipperName={this.props.shipper.filter(s => s.id === this.state.shipperId).length ? this.props.shipper.filter(s => s.id === this.state.shipperId)[0].code : "-"}
                carrierName={this.props.carrier.filter(c => c.id === this.state.carrierId).length ? this.props.carrier.filter(c => c.id === this.state.carrierId)[0].code : "-"}
            />
            {/*  This here is the print modal  */}
            <div className="modal" id="print-modal">
                <div className="modal-content">
                    <h4>Print</h4>
                    <div className="row">
                        <div className="col s6">
                            <div className="row docOptions">
                                <Form.CheckBox col="s3" className="printCheckbox" focusStyle="printSiCheckbox" name="shippingInstructions" value={this.state.docType.shippingInstructions} label="Shipping Instructions" onChange={this.printOnChange} />
                                <Form.CheckBox col="s3" className="siCheckbox" focusStyle="printSiCheckbox" name="isDummy" label="Dummy" onChange={this.onChange} value={this.state.isDummy} />
                                <div className="col s6" style={{ marginLeft: "500px" }}>

                                </div>
                            </div>
                            <div className="row docOptions">
                                <Form.CheckBox col="s3" className="printCheckbox" focusStyle="printSiCheckbox" name="packingList" value={this.state.docType.packingList} label="Packing List" onChange={this.printOnChange} />
                                <div className="col s6" style={{ marginLeft: "500px" }}>
                                </div>
                            </div>
                            <div className="row docOptions">
                                <Form.CheckBox col="s3" className="printCheckbox" focusStyle="printSiCheckbox" name="commercialInvoice" value={this.state.docType.commercialInvoice} label="Commercial Invoice" onChange={this.printOnChange} />
                                <div className="col s6" style={{ marginLeft: "500px" }}>
                                </div>
                            </div>
                            <div className="row docOptions">
                                <Form.CheckBox col="s3" className="printCheckbox" focusStyle="printSiCheckbox" name="koreaCert" value={this.state.docType.koreaCert} label="Certificate of Origin" onChange={this.printOnChange} />
                                <div className="col s6" style={{ marginLeft: "500px" }}>
                                </div>
                            </div>
                            <div className="row docOptions">
                                <Form.CheckBox col="s3" className="printCheckbox" focusStyle="printSiCheckbox" name="bse" value={this.state.docType.bse} label="BSE" onChange={this.printOnChange} />
                                <div className="col s6" style={{ marginLeft: "900px", marginBottom: "40px" }}>
                                </div>
                            </div>
                            <div className="row">
                                <Form.Button col="printy-dope-ass-button left" color="blue" type="outline" onClick={this.printDoc} label="Print" icon="print" />
                                <Form.Button col="left" color="black" type="outline" onClick={this.hideModal} label="Cancel" icon="cancel" />
                                {this.state.showLfAddresses ?
                                    null :
                                    <Form.Button col="left" color="black" type="outline" label="LF Address" onClick={this.showLfAddresses} />
                                }
                            </div>
                        </div>
                        <div className="col s6">
                            {this.state.showLfAddresses ?
                                this.state.lfAddress !== 0 ?
                                    this.state.lfAddresses.map((p, index) =>
                                        <div className={`card lf-address-list ${this.state.lfAddress === p.partyAddressId ? "lf-address-selected" : ""}`}
                                            onClick={() => this.setState({ lfAddress: p.partyAddressId })}
                                            col="s12"
                                            key={index}>
                                            <div className={`card-body`}>
                                                <Form.TextArea label={p.addressLabel} id={`card${index}`} className={`addressOption ${this.state.currentRole}Focus`} onChange={this.onChange} cursor="pointer" value={p.printableAddress} disabled={false} readonly />
                                            </div>
                                        </div>
                                    ) :
                                    "No LF Addresses Exist"
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>



            {/* This here is the party addresses Modal */}
            <div className="modal" id="address-modal">
                <div className="modal-content">
                    <h4 style={{ "paddingLeft": "30px" }}>Choose Alternative Address</h4>
                    <div className="row" style={{ "paddingLeft": "30px" }}>
                        {this.props.partyAddress.filter((a) => a.partyId === this.state[`${this.state.currentRole}Id`] && !a.isArchived).length ?
                            this.props.partyAddress.filter((addresses) => addresses.partyId === this.state[`${this.state.currentRole}Id`] && !addresses.isArchived).map((address, index) => (
                                <div className="card"
                                    onClick={() => this.changePrintableAddress(address.printableAddress)}
                                    col="s3"
                                    key={index}
                                    //temporary Inline styling, needs to be converted to the CARD component like parties page.
                                    style={{ "cursor": "pointer", "minHeight": "100px", "minWidth": "200px", "float": "left", "marginRight": "10px", "borderRadius": "10px", "paddingTop": "5px", "paddingLeft": "10px" }}>
                                    <div className={`card-body`}>
                                        <Form.TextArea label={address.addressLabel} id={`card${index}`} className={`addressOption ${this.state.currentRole}Focus`} cursor="pointer" value={address.printableAddress} onChange={this.onChange} disabled={false} readonly />
                                    </div>
                                </div>
                            )) :
                            <div className="card addressCardHolder">
                                <div className="card-body noAddressCard">
                                    There Are No Addresses Attached To This Party
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row" style={{ "float": "right" }}>
                        <Form.Button id="addressModalButton" round type="outline" label="Close" color="red" onClick={this.hideAddModal} />
                    </div>
                </div>
            </div>
            {/* This here is the alias Modal */}
            <div className="modal" id="alias-modal">
                <div className="modal-content">
                    <div className="row">
                        <span style={{ float: 'right', marginBottom: '10px' }}><i className="material-icons" onClick={this.hideAliasModal}>close</i></span>
                        <div className="col s12">
                            <h5 className="originalNameHeader">{this.state[`original${this.state.currentRole.charAt(0).toUpperCase() + this.state.currentRole.substring(1)}Name`]}
                                <i className="tiny material-icons infoIcon tooltipped " data-tooltip="Please go to the party page to edit Party names" data-position="bottom">information</i>
                            </h5>
                        </div>
                    </div>
                    <div className="row">
                        <h6>Choose An Alias</h6>
                    </div>
                    <div id="alias-list" className="row">
                        {this.props.partyAlias.filter((alias) => alias.partyId === this.state[`${this.state.currentRole}Id`] && !alias.isArchived).length ?
                            this.props.partyAlias.filter((alias) => alias.partyId === this.state[`${this.state.currentRole}Id`] && !alias.isArchived).map((alias, index) => (
                                <div className="card aliasCards"

                                    col="s3"
                                    key={index}>
                                    <div className="card-body">
                                        <div tabIndex="0" id={`alias${index}`} onClick={() => this.changeAlias(alias.alias)} className={`aliasOption ${this.state.currentRole}Focus`} cursor="pointer" name={alias.alias} onChange={this.onChange} readOnly>{alias.alias}
                                        </div><i id="deleteAliasIcon" className="material-icons" onClick={() => { this.deleteAlias(alias.id) }}>cancel</i>

                                    </div>
                                </div>
                            ))
                            :
                            <div className="card aliasCards noAliasPlaceholder">
                                <div className="card-body noAliasPlaceholderBody">
                                    There Are Currently No Aliases Names For This Party, Feel Free To Add One!
                                </div>
                            </div>
                        }
                        <Form.TextInput id="addAliasField" col="s11" name="newAlias" value={this.state.newAlias} maxLength="60" onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <label className="col s1" id="addAliasIcon"><i className="material-icons addAlias" tabIndex="0" onClick={this.addAlias}>add_circle_outline</i></label>
                    </div>
                </div>
            </div>
            <div className="modal" id="aes-modal">
                <div className="modal-content row">
                    <h4 className="blue-text text-darken-4" style={{ "textAlign": "center" }}>This AES Number Is Already In Use</h4>
                    <h5 style={{ "textAlign": "center" }}>Please either change the AES Number attached to this booking, or edit the corresponding shipping instructions currently using this AES Number</h5>
                    <Form.Button id="closeReminder" styling="modalFormat" icon="done" col="col s2 offset-s5" color="green" type="outline" label="Okay" onClick={this.hideAesModal} />
                </div>
            </div>
            <div style={{ position: 'fixed', top: this.state.y, left: this.state.x, zIndex: "100", cursor: "pointer" }} className="lf-shipment-context-menu">
                <ul className="collection">
                    {this.state.showButtonContextMenu && <li className="collection-item blue-text" onClick={this.resetPrintAndDummy}>Dummy Reset</li>}
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { navs, urls, index, party, partyAddress, shipper, partyAlias, carrier, location, containerType, serviceRoute, portalUser, user, country, paymentTerms, freightTerms, blType, submissionType, carrierContacts } = state;
    const shipperId = navs[index].shipperId;
    const url = urls[index];
    return { shipperId, party, partyAddress, shipper, partyAlias, carrier, locations: location, containerType, serviceRoute, url, portalUser, user, country, paymentTerms, freightTerms, blType, submissionType, carrierContacts };
}

export default connect(mapStateToProps)(withRouter(ShipmentInstructions));