import { Routes, Route } from 'react-router-dom';
import CreateLocation from "./createLocation";
import LocationOverview from "./locationOverview"
import UpdateLocation from "./updateLocation"

const Location = props => (
    <Routes>
        <Route path="/" element={<LocationOverview />} />
        <Route path="/create" element={<CreateLocation />} />
        <Route path="/update" element={<UpdateLocation />} />
    </Routes>
)



export default Location