import React from 'react';
import Form from "../../Components/NewComponents";
import styles from "./party.module.css";
import "./party.css";
import { flags } from "../../constants";

const ContactDisplay = (props) => {

    return (
        <div>
            {props.contacts.filter(c => c.role === props.sectionType || c[`${props.sectionType}Email`]).length ?
                <table>
                    <thead>
                        <tr className="contactsTableHead">
                            <td><b>Name</b></td>
                            <td><b>Email</b></td>
                            <td><b>Default</b></td>
                            <td><b>Field</b></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {props.contacts.filter(c => c.role === props.sectionType || c[`${props.sectionType}Email`]).map((contact, index) => (
                            props.editId === contact.id && props.editIndex === index ?
                                <tr>
                                    <td>
                                        <Form.TextInput label="Name" onChange={props.onChange} value={props.editName} name="editName" maxLength="30" flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.TextInput label="Email" onChange={props.onChange} value={props.editEmail} name="editEmail" maxLength="50" flags={[flags.ALLOW_EMPTY]} />
                                    </td>
                                    <td>
                                        <Form.Select col={parseInt(props.editDefault) ? 'col s12' : 'col s12'} label='Default' onChange={props.onChange} value={props.editDefault} name='editDefault' list={[{ label: 'YES', value: 1 }, { label: "NO", value: 0 }]} filter={{ label: 'label', value: 'value' }} />
                                    </td>
                                    <td>
                                        {parseInt(props.editDefault) ?
                                            <Form.Select label="Field" onChange={props.onChange} value={props.editField} name="editField" list={props.contactFieldOptions} filter={{ label: "label", value: "label" }} />
                                            :
                                            null}
                                    </td>
                                    <td></td>
                                    <td>
                                        <span tabIndex="0" id="addIcon" onClick={() => props.saveContact("edit")}><i className="material-icons">save</i></span>
                                    </td>
                                </tr>
                                :
                                <tr className="contactsTable" key={index}>
                                    <td>{contact.name}</td>
                                    <td>{contact.email}</td>
                                    <td>{contact.defaultEmail ? <i className="material-icons">check</i> : '-'}</td>
                                    <td>{contact.field}</td>
                                    <td className={styles.deleteIcon} onClick={() => props.editContact(contact.id, index, props.sectionType)}><i className="material-icons">edit</i></td>
                                    <td className={styles.deleteIcon} onClick={() => props.removeContact(contact.id)}><i className="material-icons">cancel</i></td>
                                </tr>
                        ))}
                    </tbody>
                </table>
                :
                <div className={styles.noContactsPlaceholder}>No Current {props.placeholderText} Contacts</div>
            }
        </div>
    )
}

export default ContactDisplay