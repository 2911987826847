import Axios from "axios";
import M from 'materialize-css';
import moment from 'moment';
import { types, flags } from './constants';
import store from './Redux/store';
import { useLocation, useNavigate, useParams } from "react-router-dom";

const getAuthData = () => ({ _user: localStorage.getItem('_user'), _token: localStorage.getItem('_token'), _session_id: localStorage.getItem('_session_id') });

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                location={location}
                navigate={navigate}
                params={params}
            />
        );
    }

    return ComponentWithRouterProp;
}

export const post = (endpoint, payload, success, fail = function () { }) => {
    Axios.post(`/api/v1/${endpoint}`, { ...getAuthData(), payload })
        .then(result => {
            if (result.data.errno) {
                fail(result.data)
            } else {
                success(result.data)
            }
        })
        .catch(err => fail(err));
}

export const get = (endpoint, payload, success, fail) => {
    Axios.get(`/api/v1/${endpoint}`, { params: { ...getAuthData(), payload } })
        .then(result => success(result.data))
        .catch(err => fail(err));
}


export const inputValidation = (arr, success, failure) => {
    let hasErr = false;
    const errors = {};
    arr.forEach(input => {
        switch (input.type) {
            case types.STRING:
                hasErr = verifyString(input, errors, hasErr);
                break;
            case types.PHONE:
                hasErr = verifyPhone(input, errors, hasErr);
                break;
            case types.EMAIL:
                hasErr = verifyEmail(input, errors, hasErr);
                break;
            case types.URL:
                hasErr = verifyURL(input, errors, hasErr);
                break;
            case types.ID:
                hasErr = verifyId(input, errors, hasErr);
                break;
            case types.INT:
                hasErr = verifyInt(input, errors, hasErr);
                break;
            case types.SIMPLE_DATE:
                hasErr = verifySimpleDate(input, errors, hasErr);
                break;
            case types.SIMPLE_TIME:
                hasErr = verifySimpleTime(input, errors, hasErr);
                break;
            default:
                return;
        }

        if (input.flags) {
            input.flags.forEach(flag => {
                switch (flag) {
                    case String(flag.match(/^MAX_LENGTH.*/)):
                        hasErr = verifyMaxLength(input, errors, hasErr);
                        break;
                    case String(flag.match(/^MIN_LENGTH.*/)):
                        hasErr = verifyMinLength(input, errors, hasErr);
                        break;
                    case String(flag.match(/^MIN_MAX_LENGTH.*/)):
                        hasErr = verifyMinMax(input, errors, hasErr);
                        break;
                    case String(flag.match(/^EXACT_LENGTH.*/)):
                        hasErr = verifyExactLength(input, errors, hasErr);
                        break;
                    case String(flag.match(/^MIN_MAX_VALUE.*/)):
                        hasErr = verifyMinMaxValue(input, errors, hasErr);
                        break;
                    case String(flag.match(/^MIN_VALUE.*/)):
                        hasErr = verifyMinValue(input, errors, hasErr);
                        break;
                    case String(flag.match(/^MAX_VALUE.*/)):
                        hasErr = verifyMaxValue(input, errors, hasErr);
                        break;
                    case flags.NO_SPACES:
                        hasErr = verifyNoSpaces(input, errors, hasErr);
                        break;
                    default:
                        return;
                }
            })
        }
    })

    if (hasErr) {
        failure(errors);
    }
    else
        success();

    return hasErr;
}

const verifyString = (input, errors, hasErr) => {
    const allowEmpty = input.flags ? input.flags.indexOf(flags.ALLOW_EMPTY) !== -1 : false;

    if (!input.value && !allowEmpty) {
        errors[input.name] = { err: true, msg: 'Must not be blank.' };
        return true;
    }


    errors[input.name] = { err: false, msg: '' }
    return hasErr;
}

const verifyPhone = (input, errors, hasErr) => {
    const allowEmpty = input.flags ? input.flags.indexOf(flags.ALLOW_EMPTY) !== -1 : false;

    if (!input.value && !allowEmpty) {
        errors[input.name] = { err: true, msg: 'Must not be blank.' }
        return true;
    }


    if (input.value.length < 10 && input.value) {
        errors[input.name] = { err: true, msg: 'Phone numbers must include at least 10 digits' }
        return true;
    }

    if (RegExp(/\D/).test(input.value) && input.value) {
        errors[input.name] = { err: true, msg: 'Phone numbers should be digits only. No "()-."' }
        return true;
    }

    errors[input.name] = { err: false, msg: '' }
    return hasErr;

}

const verifyEmail = (input, errors, hasErr) => {
    const allowEmpty = input.flags ? input.flags.indexOf(flags.ALLOW_EMPTY) !== -1 : false;

    if (!input.value && !allowEmpty) {
        errors[input.name] = { err: true, msg: 'Must not be blank' };
        return true;
    }
    // eslint-disable-next-line
    if (input.value && !RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(input.value)) {
        errors[input.name] = { err: true, msg: 'Invalid email address' }
        return true;
    }

    errors[input.name] = { err: false, msg: '' };
    return hasErr;
}

const verifyURL = (input, errors, hasErr) => {
    const allowEmpty = input.flags ? input.flags.indexOf(flags.ALLOW_EMPTY) !== -1 : false;

    if (!input.value && !allowEmpty) {
        errors[input.name] = { err: true, msg: 'Must not be blank' };
        return true;
    }


    if (input.value && !RegExp(/^\S*\.\D{3}$/).test(input.value)) {
        errors[input.name] = { err: true, msg: 'Invalid web address' }
        return true;
    }


    errors[input.name] = { err: false, msg: '' }
    return hasErr;

}

const verifyId = (input, errors, hasErr) => {
    if (!input.value) {
        if (input.value === 0)
            errors[input.name] = { err: true, msg: 'This value does not exist in the database, please add it' }
        else
            errors[input.name] = { err: true, msg: 'This field cannot be blank' };

        return true;
    }

    const id = parseInt(input.value);
    if (isNaN(id)) {
        errors[input.name] = { err: true, msg: 'This field must be an integer' };
        return true;
    }

    if (id === 0) {
        errors[input.name] = { err: true, msg: 'This field cannot be 0' }
        return true;
    }

    errors[input.name] = { err: false, msg: '' };
    return hasErr;
}

const verifyInt = (input, errors, hasErr) => {
    const allowEmpty = input.flags ? input.flags.indexOf(flags.ALLOW_EMPTY) !== -1 : false;

    if (!input.value && allowEmpty)
        return hasErr;

    if (!input.value) {
        errors[input.name] = { err: true, msg: 'This field cannot be blank' };
        return true;
    }

    const num = parseInt(input.value);
    if (isNaN(num)) {
        errors[input.name] = { err: true, msg: 'This field must contain a number. No decimals' };
        return true;
    }

    errors[input.name] = { err: false, msg: '' };
    return hasErr;
}

const verifySimpleDate = (input, errors, hasErr) => {
    const allowEmpty = input.flags ? input.flags.indexOf(flags.ALLOW_EMPTY) !== -1 : false;

    if (allowEmpty && input.value === '') {
        errors[input.name] = { err: false, msg: '' };
        return false;
    }

    if (!input.value) {
        errors[input.name] = { err: true, msg: 'Date must not be blank' };
        return true;
    }

    const date = moment.utc(input.value, 'MMDDYY');

    if (!date.isValid()) {
        errors[input.name] = { err: true, msg: 'Invalid Date, try: MM/DD/YY' }
        return true;
    }

    errors[input.name] = { err: false, msg: '' }
    return hasErr
}

const verifySimpleTime = (input, errors, hasErr) => {
    if (!input.value) {
        errors[input.name] = { err: true, msg: 'Time must not be blank' };
        return true;
    }

    const time = moment.utc('01-01-2000 ' + input.value, 'MM-DD-YYYY HH:mm');

    if (!time.isValid()) {
        errors[input.name] = { err: true, msg: 'Invalid Time, try: HH:MM' }
        return true;
    }

    errors[input.name] = { err: false, msg: '' }
    return hasErr;
}

const verifyMinLength = (input, errors, hasErr) => {
    const min = input.flags.find(flag => RegExp(/MIN_LENGTH/).test(flag)).split(':')[1]
    if (input.value.length < parseInt(min)) {
        errors[input.name] = { err: true, msg: `Min length: ${min}` };
        return true;
    }

    return hasErr;
}

const verifyMaxLength = (input, errors, hasErr) => {
    const max = input.flags.find(flag => RegExp(/MAX_LENGTH/).test(flag)).split(':')[1]
    if (input.value.length > parseInt(max)) {
        errors[input.name] = { err: true, msg: `Max length: ${max}` };
        return true;
    }

    return hasErr;
}

const verifyExactLength = (input, errors, hasErr) => {
    const allowEmpty = input.flags ? input.flags.indexOf(flags.ALLOW_EMPTY) !== -1 : false;

    if (!input.value && allowEmpty)
        return hasErr;

    const exact = input.flags.find(flag => RegExp(/EXACT_LENGTH/).test(flag)).split(':')[1]
    if (input.value.length !== parseInt(exact)) {
        errors[input.name] = { err: true, msg: `Exact length: ${exact}` };
        return true;
    }

    return hasErr;
}

const verifyMinMax = (input, errors, hasErr) => {
    const minMax = input.flags.find(flag => RegExp(/MIN_MAX_LENGTH/).test(flag)).split(':')[1]
    const min = parseInt(minMax.split('-')[0]);
    const max = parseInt(minMax.split('-')[1]);

    if (!input.value || input.value.length < min || input.value.length > max) {
        errors[input.name] = { err: true, msg: `Length min-max: ${min}-${max}` };
        return true;
    }

    return hasErr;
}

const verifyNoSpaces = (input, errors, hasErr) => {
    if (RegExp(/\s/).test(input.value.trim())) {

        errors[input.name] = { err: true, msg: 'No blank spaces.' };
        return true;
    }

    return hasErr;
}

const verifyMinMaxValue = (input, errors, hasErr) => {
    const allowEmpty = input.flags ? input.flags.indexOf(flags.ALLOW_EMPTY) !== -1 : false;

    if (!input.value && allowEmpty)
        return hasErr;

    const minMax = input.flags.find(flag => RegExp(/MIN_MAX_VALUE/).test(flag)).split(':')[1];
    const min = parseInt(minMax.split('-')[0]);
    const max = parseInt(minMax.split('-')[1]);
    const num = parseInt(input.value);

    if (num < min || num > max) {
        errors[input.name] = { err: true, msg: `Value min-max: ${min}-${max}` };
        return true;
    }

    return hasErr;
}

const verifyMinValue = (input, errors, hasErr) => {
    const allowEmpty = input.flags ? input.flags.indexOf(flags.ALLOW_EMPTY) !== -1 : false;

    if (!input.value && allowEmpty)
        return hasErr;

    const min = parseInt(input.flags.find(flag => RegExp(/MIN_VALUE/).test(flag)).split(':')[1]);
    const num = parseInt(input.value);

    if (num < min) {
        errors[input.name] = { err: true, msg: `Value min: ${min}` };
        return true;
    }

    return hasErr;
}

const verifyMaxValue = (input, errors, hasErr) => {
    const allowEmpty = input.flags ? input.flags.indexOf(flags.ALLOW_EMPTY) !== -1 : false;

    if (!input.value && allowEmpty)
        return hasErr;

    const max = parseInt(input.flags.find(flag => RegExp(/MAX_VALUE/).test(flag)).split(':')[1]);
    const num = parseInt(input.value);

    if (num > max) {
        errors[input.name] = { err: true, msg: `Value max: ${max}` };
        return true;
    }

    return hasErr;
}



export const changeHandlers = {

    input: (event, obj) => {
        if (obj) {
            const newObj = JSON.parse(JSON.stringify(obj));
            newObj[event.target.name] = event.target.value;
            return newObj;
        }
        else {
            return event.target.value;
        }
    },

    checkbox: (event, obj) => {
        if (obj) {
            const newObj = JSON.parse(JSON.stringify(obj));
            newObj[event.target.name] = !newObj[event.target.name];
            return newObj;
        }
        else
            return null;
    }
}

export const modal = {
    getInstance: id => M.Modal.getInstance(document.querySelector(`#${id}`)),

    init: id => M.Modal.init(document.querySelector(`#${id}`))
}


export const arrayHelper = {
    add: (obj, list, callback) => {
        const newObj = JSON.parse(JSON.stringify(obj));
        const newList = JSON.parse(JSON.stringify(list));

        newList.push(newObj);

        callback(newList);
    },

    remove: (index, list, callback) => {
        const newList = JSON.parse(JSON.stringify(list));

        newList.splice(parseInt(index), 1);
        callback(newList);
    }
}

export const fileUploader = {
    getUploadURL: async (files, lNumber, bookingId, auth) => {
        return new Promise(async (resolve, reject) => {
            let fileUploadPromises = [];
            await files.forEach(async file => {
                fileUploadPromises.push(new Promise(async (resolve, reject) => {
                    let fileNameSplit = file.name.split(".");
                    fileNameSplit.splice((fileNameSplit.length - 1), 1, fileNameSplit[fileNameSplit.length - 1].toLowerCase())
                    let newFileName = fileNameSplit.join(".");
                    if (newFileName.length > 120) {
                        reject('File Name Too Long (Limit 120)');
                    } else {
                        await Axios.post("/api/v1/upload/puturl", {
                            ...auth,
                            lNumber,
                            fileName: file.name.normalize("NFD").replace(/\p{Diacritic}/gu, ""),
                            contentType: file.type,
                            file
                        }).then(result => {
                            Axios.put(result.data, file, { headers: { 'Content-Type': `${file.type}` } }).then(result => {
                                if (result.status === 200) {
                                    Axios.post("/api/v1/upload/recordput", {
                                        ...auth,
                                        bookingId,
                                        fileName: file.name.normalize("NFD").replace(/\p{Diacritic}/gu, ""),
                                        contentType: file.type,
                                        fileSize: file.size,
                                        directory: "SHIPMENTS/"
                                    }).then(result => {
                                        if (result.data === "NOT AUTHENTICATED") {
                                            reject();;
                                        }
                                        resolve(result);
                                    })
                                } else {
                                    return;
                                }
                            })
                        });
                    }

                }).catch(err => { console.log(err); reject(err); }));
            });
            Promise.allSettled(fileUploadPromises).then((values) => {
                resolve();
            })
        })

    },
    getUploadURLBuffer: async (fileType, datetime, buffer, lNumber, bookingNumber, bookingId, auth) => {
        return new Promise(async (resolve, reject) => {
            let fileName;
            let headers;
            let contentType;
            switch (fileType) {
                case 'bookingAck':
                    fileName = `${bookingNumber}_${datetime}.pdf`;
                    headers = { 'Content-Type': `application/pdf` };
                    contentType = 'application/pdf';
                    break;
                case 'shippingInstructions':
                    fileName = `SI_${bookingNumber}_${datetime}.pdf`;
                    headers = { 'Content-Type': `application/pdf` };
                    contentType = 'application/pdf';
                    break;
                case 'singleInvoice':
                    fileName = `LF Invoice ${lNumber} ${datetime}.pdf`;
                    headers = { 'Content-Type': `application/pdf` };
                    contentType = 'application/pdf';
                    break;
                case 'smLineTemplate':
                    fileName = `SI_${bookingNumber}_${datetime}.xlsx`;
                    headers = { 'Content-Type': `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` };
                    contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    break
                default:
                    break;
            }
            await Axios.post("/api/v1/upload/puturl", {
                ...auth,
                lNumber,
                fileName,
                contentType,
                buffer
            }).then(result => {
                Axios.put(result.data, buffer, { headers }).then(result => {
                    if (result.status === 200) {
                        Axios.post("/api/v1/upload/recordput", {
                            ...auth,
                            bookingId,
                            fileName,
                            contentType,
                            fileSize: buffer.byteLength,
                            directory: "SHIPMENTS/"
                        }).then(result => {
                            if (result.data === "NOT AUTHENTICATED") {
                                reject();;
                            } else {
                                resolve();
                            }
                        })
                    } else {
                        return;
                    }
                })
            })
        })
    }

}
export const auth = {
    getAuthData: () => ({
        _user: localStorage.getItem("_user"),
        _token: localStorage.getItem("_token"),
        _session_id: localStorage.getItem("_session_id"),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
}

export const logout = (err, callback) => {
    if (Axios.isCancel(err))
        return;

    if (err.response) {
        let status = err.response.status;
        let msg = err.response.data.payload ? err.response.data.payload.msg : undefined;
        let data = err.response.data;

        if (status === 401 && msg === 'NOT AUTHENTICATED') {
            store.dispatch({ type: "SCORCHED_EARTH" })
            window.location.replace("/");
        }
        else {
            if (data && data.type && data.type === 'UPDATE_MSG')
                store.dispatch(data);
        }


        if (callback && typeof callback === 'function')
            return callback(err);
    }
    else {
        return console.log('UNCAUGHT ERROR: ', err);
    }
}