import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../helperFunctions';
import M from 'materialize-css';
import Form from '../../../Components/NewComponents';
import Changelog from "../Changelog/changeLog"
import { flags } from '../../../constants';
import { globalToastActions } from '../../../Redux/actions';
import Axios from 'axios';
import moment from 'moment-timezone';
import styles from '../shipments.module.css';
import EmailForm from '../EmailForm/EmailForm';
import FileUpload from '../../../Components/FileUpload/FileUpload';
import { auth } from "../../../helperFunctions";
import StickyNote from '../../../Components/StickyNote/StickyNote';

class CreateBooking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.initState(),
            shipperId: 0,
            shipper: this.props.redux.party[0],
            bookingNumber: this.props.params.bookingNumber,
            ...this.props.location?.state
        }
    };

    initState = () => ({

        bookingId: '',
        bookingNote: '',
        isDummy: false,
        shipperId: "0",
        displayNameId: "0",
        carrierId: 0,
        showCarrier: false,
        bookingNumber: '',
        shipperReferenceNumber: '',
        containerCount: '',
        actualContainerCount: '',
        enteredContainers: '',
        containerTypeId: 1,
        consigneeName: '',
        productPackaging: '',
        vesselName: '',
        vesselId: 0,
        voyageNumber: '',
        serviceRoute: '',
        placeOfReceiptId: 0,
        portOfLoadId: 0,
        portOfDischargeId: 0,
        portOfDeliveryId: 0,
        cargoCutDate: '',
        cargoCutTime: '12:00',
        docCutDate: '',
        docCutTime: '16:00',
        etd: '',
        eta: '',
        erd: '',
        pickupLocationId: 0,
        returnLocationId: 0,
        timeZone: '',
        internalNote: '',
        additionalNotes: '',
        showBookingNumberEditor: false,
        bookingNumbersEdits: null,
        bookingsAssignee: 0,
        subscribeToNotifications: 1,
        trueVesselVoyageBookingCount: 0,
        employees: [],
        vessels: [],
        consignees: [],
        containerYards: [],
        emails: [],
        docsEmails: [],
        portPair: null,
        vesselVoyage: null,
        msg: { color: 'blue', text: '' },
        vvNotification: null,
        datetimeCreated: null,
        timeCreated: null,
        badge: {
            type: "",
            color: "#f3f3f3",
            id: ""
        },
        stageColor: "#f3f3f3",
        stageIcon: "",
        loading: true,
        vvLoading: false,
        showPrint: true,
        useDisplayName: false,
        showAllYards: false,
        useAllChanges: false,
        useHtml: true,
        emailSubject: '',
        generatePdf: false,
        vvCheckObj: {
            vesselVoyageId: "",
            cutDate: "",
            cutTime: "",
            docDate: "",
            docTime: "",
            etd: "",
            eta: "",
            erd: "",
            returnLocationId: ""
        },
        aesCheckObject: {
            shipperId: "",
            consigneeName: "",
            portOfLoadId: "",
            carrierId: "",
            etd: "",
            portOfDeliveryId: "",
            portOfDischargeId: "",
            vesselId: "",
            productPackaging: ""
        },
        showAesAlert: false,
        serviceRouteCarriers: [
            10,
            15,
            7,
            3,
            11,
            5,
            6
        ],
        files: [],
        dragging: false,
        dragCounter: 0,
        fileUploadTabOpen: false,
        changeLogOpenSwitch: false,
        showStickyNote: false,
        newVesselIds: []
    });

    stateForDuplication = () => ({
        shipperReferenceNumber: '',
        bookingNumber: '',
        bookingId: null,
        bookingNumberId: null,
        bookingNumbersEdits: null,
        createdBy: '',
        datetimeCreated: '',
        lastModifiedBy: '',
        datetimeModified: '',
        lastPrintedBy: '',
        lastSentBy: '',
        datetimePrinted: '',
        datetimeSent: '',
        enteredContainers: '',
        flaggedForReview: 0,
        id: null,
        isDisplayed: null,
        lNumber: '',
        searchTerm: '',
        status: null,
        vvNotification: null,
        productPackaging: '',
        consigneeName: '',
        fileUploadTabOpen: false,
        aesCheckObject: {
            shipperId: "",
            consigneeName: "",
            portOfLoadId: "",
            carrierId: "",
            etd: "",
            portOfDeliveryId: "",
            portOfDischargeId: "",
            vesselId: "",
            productPackaging: ""
        },
        newVesselIds: []
    })


    initModal = () => {
        M.Modal.init(document.querySelectorAll('.modal:not(#bookingAndSiStagesWindow)'));
        M.Collapsible.init(document.querySelectorAll('.collapsible'))
        M.Modal.init(document.getElementById("bookingAndSiStagesWindow"), {
            inDuration: '100',
            outDuration: "100",
            onStartEnd: () => this.setState({ showStickyNote: true }),
            onCloseEnd: () => this.setState({ showStickyNote: false })
        });
    }

    printBkAck = async () => {
        let tz = moment.tz.guess();
        const saveResult = await this.saveBooking();
        if (saveResult.errno || saveResult === 'NOT AUTHENTICATED')
            return;


        if (this.state.bookingId && !this.state.isDummy) {

            Axios.post('/api/v1/pages/bookingacknowledgment', {
                bookingNumber: this.state.bookingNumber,
                isDisplayed: this.state.isDisplayed,
                docCutDate: this.state.docCutDate,
                docCutTime: this.state.docCutTime,
                cargoCutDate: this.state.cargoCutDate,
                cargoCutTime: this.state.cargoCutTime,
                eta: this.state.eta,
                etd: this.state.etd,
                erd: this.state.erd,
                tz,
                ...auth.getAuthData()
            }).then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return;
                }

                const win = window.open('', '', 'width=960 height=1080');
                win.document.write(result.data);
                this.searchBookings();
            })
        }
    }
    getLocationName = id => { const loc = this.props.redux.location.filter(l => l.id === id)[0]; return `${loc.name}, ${loc.state ? loc.state : this.getCountry(loc.countryId)}` };
    getCountry = id => this.props.redux.country.filter(c => c.id === id)[0].name;

    initListener = () => {
        document.addEventListener('keydown', this.zoom);
        document.addEventListener('keydown', this.saveListener);
        document.addEventListener('keydown', this.printListener);
        document.addEventListener('keydown', this.escListener);
        document.addEventListener('keydown', this.emailListener);
        document.addEventListener('keydown', this.duplicateBookingListener);
        document.addEventListener("keydown", this.portChangeListener);
        document.addEventListener("keydown", this.stopArrows);
        document.addEventListener("keydown", this.vesselSearchTabListener);
        document.addEventListener('keydown', this.railyardContextMenu);
    }

    removeListener = () => {
        document.removeEventListener('keydown', this.zoom);
        document.removeEventListener('keydown', this.saveListener);
        document.removeEventListener('keydown', this.printListener);
        document.removeEventListener('keydown', this.escListener);
        document.removeEventListener('keydown', this.emailListener);
        document.removeEventListener('keydown', this.duplicateBookingListener);
        document.removeEventListener('keydown', this.portChangeListener);
        document.removeEventListener("keydown", this.stopArrows);
        document.removeEventListener("keydown", this.vesselSearchTabListener);
        document.removeEventListener('keydown', this.railyardContextMenu);
    }


    zoom = e => {
        if (this.state.loading) {
            return;
        } else if (e.keyCode === 116) {
            e.preventDefault();
            if (this.state.bookingNumber)
                this.props.navigate('/shipment/instructions', {
                    state: {
                        searchTerm: this.state.bookingNumber[0],
                        shipperId: this.state.shipperId,
                        ...this.props.location?.state
                    }
                })
        }
    }

    clickZoom = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        } else if (this.state.bookingNumber) {
            this.props.navigate('/shipment/instructions', {
                state: {
                    searchTerm: this.state.bookingNumber[0],
                    shipperId: this.state.shipperId,
                    ...this.props.location?.state
                }
            })
        } else {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Please Save", type: 'warning' } });
            return;
        }

    }

    saveListener = async e => {
        if (e.ctrlKey && e.keyCode === 83 && !e.shiftKey) {
            e.preventDefault();
            await document.activeElement.blur();
            document.getElementById("serviceRoute").focus();
            this.saveBooking();
        }
    }

    printListener = async e => {
        if (e.ctrlKey && e.keyCode === 80 && this.state.showPrint) {
            e.preventDefault();
            await document.activeElement.blur();
            document.getElementById("serviceRoute").focus();
            this.printBkAck();
        }
    }

    escListener = async e => {
        if (e.keyCode === 27) {
            if (this.state.showStickyNote) {
                return this.setState({ showStickyNote: false });
            } else if (await this.checkForOpenModals()) {
                return;
            } else {
                let bookingId = this.props.location?.state?.bookingId ? this.props.location?.state?.bookingId : null;
                this.props.navigate(this.props.location?.state?.origin === "document" ? "/documentation" : '/shipment', {
                    state: {
                        filters: this.props.location?.state?.filters,
                        filterList: this.props.location?.state?.filterList,
                        origin: "bookingCreation",
                        bookingId
                    }
                });
            }
        }
    }

    emailListener = async e => {
        if (e.ctrlKey && e.key.toLowerCase() === "m" && this.state.showPrint) {
            e.preventDefault();
            await document.activeElement.blur();
            this.saveAndEmail();
        }
    }

    stopArrows = (e) => {
        if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
            if (document.activeElement.id === "shipperId")
                e.preventDefault();
            return false
        }
    }

    duplicateBookingListener = async e => {
        if (e.ctrlKey && e.shiftKey && e.keyCode === 83) {
            await document.activeElement.blur();
            document.getElementById("serviceRoute").focus();
            this.duplicateBooking();
        }
    }

    vesselSearchTabListener = async e => {
        if (e.keyCode === 9 && document.getElementById("portOfDeliveryId") === document.activeElement) {
            while (document.getElementById("cargoCutDate") === null) {
                e.preventDefault();
                document.getElementById("portOfDeliveryId").focus();
                await new Promise(resolve => setTimeout(resolve, 500));
            };
            return;
        }
    }

    railyardContextMenu = (e) => {
        if (this.state.showContextMenu && document.activeElement.tagName === "LI") {
            if (e.keyCode === 13 || e.keyCode === 32) {
                document.activeElement.click();
                document.getElementById('pickupLocationId').focus();
            }
        }
    }

    componentDidMount = () => {

        this.initListener();
        this.initModal();
        this.loadPortPairs();
        this.loadServiceRoutes();
        this.searchBookings();

    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((this.props.location?.state?.searchTerm !== prevProps.location?.state?.searchTerm ||
            this.props.params.bookingNumber !== prevProps.params.bookingNumber) &&
            !this.state.loading && (prevProps.location?.state?.searchTerm || prevProps.params.bookingNumber)) {

            this.setState({
                ...this.initState(),
                searchTerm: this.props.location?.state?.searchTerm,
                ...this.stateForDuplication(),
                bookingNumber: this.props.location?.state?.searchTerm ? this.props.location?.state?.searchTerm : ""
            },
                () => {
                    this.setState({ loading: true })
                    this.getContracts();
                    this.getVessels();
                    this.loadPortPairs();
                    this.loadServiceRoutes();
                    this.getConsignees();
                })
            setTimeout(() => this.searchBookings(), 100)
        }

        if (prevState.useDisplayName !== this.state.useDisplayName) {

            if (!this.state.useDisplayName) {
                this.setState({ displayNameId: "0" })
            }
        }
        if (prevState.carrierId !== this.state.carrierId && this.state.bookingNumber && this.state.isDisplayed) {
            let carrier = parseInt(this.state.carrierId) ?
                this.props.redux.carrier.filter(c => c.id === parseInt(this.state.carrierId))[0].code : "";

            let cargoDate = moment(this.state.cargoCutDate, "MM/DD/YY").format("M/D") === "Invalid date" ? "[PLEASE COMPLETE VESSEL VOYAGE]" :
                moment(this.state.cargoCutDate, "MM/DD/YY").format("M/D");

            let words = this.state.bookingNote.split(" ");
            let newWords = []
            words.forEach(w => {
                newWords.push((w.charAt(0) + w.slice(1).toLowerCase()));
            })
            let bookingNote = newWords.join(" ");
            // let shipperRef = this.state.shipperReferenceNumber ? " - " + this.state.shipperReferenceNumber : this.state.shipperReferenceNumber;

            this.setState({ emailSubject: `${bookingNote} ${carrier} ${cargoDate} c/o ${this.getBookingNumbers()}${this.getAlternateSubjectLine()}` })
        }
        if (prevState.bookingNumber !== this.state.bookingNumber && Array.isArray(prevState.bookingNumber) &&
            this.state.portPairList) {
            if (this.state.portPairList.length) {
                if (!this.state.portPairList.filter(p =>
                    p.portOfDeliveryId === this.state.portOfDeliveryId &&
                    p.portOfDischargeId === this.state.portOfDischargeId &&
                    p.portOfLoadId === this.state.portOfLoadId &&
                    p.placeOfReceiptId === this.state.placeOfReceiptId).length) {
                    this.loadPortPairs();
                }
            }
        }

        if (prevState.vesselVoyage !== this.state.vesselVoyage ||
            prevState.isDummy !== this.state.isDummy ||
            prevState.badge.type !== this.state.badge.type) {
            if (this.state.vesselVoyage && this.state.isDummy) {
                this.setState({ showPrint: false })
            }
            else { this.setState({ showPrint: true }) }
        }
        if (prevState !== this.state) { this.initModal() }
        if (prevState.additionalNotes !== this.state.additionalNotes)
            if (this.state.additionalNotes.length) { M.Collapsible.getInstance(document.querySelector(".collapsible")).open() }

    }

    componentWillUnmount = () => {
        this.removeListener();
    }

    openStickyNoteModal = () => {
        M.Modal.getInstance(document.getElementById("bookingAndSiStagesWindow")).open();
    }

    closeStickyNoteModal = () => {
        M.Modal.getInstance(document.getElementById("bookingAndSiStagesWindow")).close();
    }

    checkForOpenModals = async () => {
        let modals = document.querySelectorAll('.modal');
        let openModal = false;
        await modals.forEach(modal => {
            if (M.Modal.getInstance(modal).isOpen) {
                M.Modal.getInstance(modal).close();
                return openModal = true;
            }
        });
        return openModal;
    }

    getContainerYards = () => {

        let pickUpId = parseInt(this.state.pickupLocationId);
        let returnId = parseInt(this.state.returnLocationId);
        let plr = parseInt(this.state.placeOfReceiptId);
        let locations = this.props.redux.location;

        //If either the Pickup Location, Return Location, or Place of Reciept no longer exist in the db
        if (!locations.filter(l => l.id === pickUpId).length || !locations.filter(l => l.id === returnId).length ||
            !locations.filter(l => l.id === plr).length) {
            //If the Place of Reciept no longer exists in the db
            if (!locations.filter(l => l.id === plr).length) {
                this.setState({ containerYards: [], placeOfReceiptId: "0" })
                //If the Pick Up Location no longer exists in the db
            } else if (!locations.filter(l => l.id === pickUpId).length) {

                const state = this.props.redux.location.filter(loc => loc.id === parseInt(this.state.placeOfReceiptId))[0].state;
                let yards = this.props.redux.location.filter(loc => !this.state.showAllYards ? loc.typeId === 3 && loc.state === state : loc.typeId === 3);
                this.setState({ containerYards: yards, pickupLocationId: "0" })
                //If the Return Location no longer exists in the db
            } else if (!locations.filter(l => l.id === returnId).length) {
                const state = this.props.redux.location.filter(loc => loc.id === parseInt(this.state.placeOfReceiptId))[0].state;
                let yards = this.props.redux.location.filter(loc => !this.state.showAllYards ? loc.typeId === 3 && loc.state === state : loc.typeId === 3);
                this.setState({ containerYards: yards, returnLocationId: "0" })
            }
            return;
        }

        //If the Place of Reciept has been selected
        if (this.state.placeOfReceiptId && this.state.placeOfReceiptId !== "0") {
            //If the Pick Up and Return Locations have been selected and the user has not selected SHOW ALL YARDS option,
            // but the Pick Up Location is not in the same state as the Place of Reciept Location
            if (this.state.pickupLocationId !== "0" && this.state.returnLocationId !== "0" && !this.state.showAllYards &&
                this.props.redux.location.filter(loc => loc.id === parseInt(this.state.placeOfReceiptId))[0].state !==
                this.props.redux.location.filter(loc => loc.id === parseInt(this.state.pickupLocationId))[0].state &&
                !this.state.containerYards) {
                this.setState({ showAllYards: true }, this.getContainerYards)
            } else if (this.state.pickupLocationId !== "0" && this.state.returnLocationId !== "0" && !this.state.showAllYards &&
                this.props.redux.location.filter(loc => loc.id === parseInt(this.state.placeOfReceiptId))[0].state ===
                this.props.redux.location.filter(loc => loc.id === parseInt(this.state.pickupLocationId))[0].state &&
                !this.state.containerYards) {
                this.setState({ showAllYards: false }, this.getContainerYards)
            }
            //If the Pick Up Location no longer exists in the db
            if (this.props.redux.location.filter(loc => loc.id === parseInt(this.state.placeOfReceiptId))[0] === undefined) {
                this.setState({ containerYards: [] });
                //Get yards based on the Place of Reciept, and Get ALL or STATE locations depending on PLR state
            } else {
                const state = this.props.redux.location.filter(loc => loc.id === parseInt(this.state.placeOfReceiptId))[0].state;
                let yards = this.props.redux.location.filter(loc => !this.state.showAllYards ? loc.typeId === 3 && loc.state === state : loc.typeId === 3);
                this.setState({ containerYards: yards })
            }
        }
        else
            this.setState({ containerYards: [] });
    }

    //Adjusted for only US ports. Will need adjustment if need to add port for out of the country.
    getDomesticPorts = () => {
        if (!this.props.redux.location) {
            return;
        } else {
            return this.props.redux.location.filter(loc => ((loc.countryId === 1 || loc.countryId === 17) && loc.typeId !== 3));
        }
        // this.state.shipper ? this.props.redux.location.filter(loc => (loc.countryId === this.state.shipper.countryId && loc.typeId !== 3)) : null;
    }
    getForeignPorts = () => {
        if (!this.props.redux.location) {
            return;
        } else {
            return this.props.redux.location.filter(loc => ((loc.countryId !== 1 || loc.countryId !== 17) && loc.typeId !== 3));
        }
    }
    getContracts = () => Axios.get(`/api/v1/contract/party/${this.state.shipperId}`, { params: auth.getAuthData() }).then(result => {
        if (result.data === 'NOT AUTHENTICATED') {
            localStorage.clear();
            this.props.dispatch({ type: 'SCORCHED_EARTH' })
            return;
        }

        this.setState({ contracts: result.data }, () => this.setCurrentContract())
    });

    getVessels = () => {
        return new Promise((resolve, reject) => {
            Axios.get(`/api/v1/vessel/carrier/${this.state.carrierId}`, { params: auth.getAuthData() }).then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' });
                    reject();
                    return;
                }

                this.setState({ vessels: result.data }, () => resolve())
            });
        }).catch((err) => console.log(err))
    }


    setActive = (e, choice) => {
        e?.preventDefault();

        let activeBookingClass = this.state.siSentOrPrinted ? 'activeBookingTabPrinted' : "activeBookingTab";
        let inactiveBookingClass = this.state.siSentOrPrinted ? 'inactiveBookingTabPrinted' : 'inactiveBookingTab';
        let activeUploadClass = this.state.siSentOrPrinted ? 'activeUploadTabPrinted' : 'activeUploadTab';
        let inactiveUploadClass = this.state.siSentOrPrinted ? "inactiveUploadTabPrinted" : "inactiveUploadTab";
        if (!document.getElementById("booking") || !document.getElementById("fileUpload")) {
            return;
        } else if (choice === 'bookingTab') {

            this.setState({ fileUploadTabOpen: false });

            document.getElementById('fileUpload').classList.add('hideTab');
            document.getElementById('booking').classList.remove('hideTab');

            document.getElementById("bookingTab").classList.remove(inactiveBookingClass);
            document.getElementById("bookingTab").classList.add(activeBookingClass);

            document.getElementById("fileUploadTab").classList.remove(activeUploadClass);
            document.getElementById("fileUploadTab").classList.add(inactiveUploadClass);


        } else {
            if (!this.state.bookingId) {
                return;
            }

            this.setState({ fileUploadTabOpen: true })

            document.getElementById('booking').classList.add('hideTab');
            document.getElementById('fileUpload').classList.remove('hideTab');

            document.getElementById("fileUploadTab").classList.remove(inactiveUploadClass);
            document.getElementById("fileUploadTab").classList.add(activeUploadClass);

            document.getElementById("bookingTab").classList.remove(activeBookingClass);
            document.getElementById("bookingTab").classList.add(inactiveBookingClass);
        }

    }

    getBookingTabStyling = () => {
        if (this.state.loading) {
            return;
        }

        if (this.state.fileUploadTabOpen) {
            if (this.state.siSentOrPrintedAndDummy) {
                return 'inactiveBookingTabPrinted'
            } else {
                return 'inactiveBookingTab';
            }
        } else {
            if (this.state.siSentOrPrintedAndDummy) {
                return 'activeBookingTabPrinted';
            } else {
                return 'activeBookingTab';
            }
        }
    }

    getUploadTabStyling = () => {
        if (this.state.loading) {
            return;
        }

        if (this.state.fileUploadTabOpen) {
            if (this.state.siSentOrPrintedAndDummy) {
                return 'activeUploadTabPrinted'
            } else {
                return 'activeUploadTab';
            }
        } else {
            if (this.state.siSentOrPrintedAndDummy) {
                return 'inactiveUploadTabPrinted';
            } else {
                return 'inactiveUploadTab';
            }
        }
    }

    getShippingInstructionsTabStyling = () => {
        if (this.state.loading) {
            return;
        }

        if (this.state.siSentOrPrintedAndDummy) {
            return 'inactiveSiTabPrinted';
        } else {
            return 'inactiveSiTab'
        }
    }

    getStickyNoteTabStyling = () => {
        if (this.state.loading) {
            return;
        } else {
            if (this.state.showStickyNote) {
                if (this.state.useNotes) {
                    return 'stickyNoteButtonClickedYellow'
                } else {
                    return 'stickyNoteButtonClicked'
                }
            } else {
                if (this.state.useNotes) {
                    return 'stickyNoteButtonUnClickedYellow';
                } else {
                    return 'stickyNoteButtonUnClicked'
                }
            }
        }
    }

    convertToLowerCase = (name) => {
        if (typeof name === "string") {
            return name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
        }
    }

    setCurrentContract = () => {
        if (this.state.contracts) {
            const contract = this.state.contracts.filter(c => c.carrierId === parseInt(this.state.carrierId));
            this.setState({ contract: contract[0] });
        } else {
            return;
        }

    };

    onChange = (name, value) => this.setState({ [name]: value });

    shipperOnChange = (name, value) => {
        if (value !== "0") {
            this.setState({ [name]: value }, () => {
                this.getDefaultAssigneeAndVoyageStatus();
                this.getConsignees();
            })
        } else {
            this.setState({ [name]: value });
        }

    }

    portOnChange = (name, value) => {

        let plr = this.props.redux.location.filter(loc => loc.id === parseInt(this.state.placeOfReceiptId))[0]
        let returnLoc = this.props.redux.location.filter(loc => loc.id === parseInt(this.state.returnLocationId))[0];
        let pickupLoc = this.props.redux.location.filter(loc => loc.id === parseInt(this.state.pickupLocationId))[0];

        this.setState({ [name]: value }, () => {

            this.setState({ pickupLocationId: "0", returnLocationId: "0", vvLoading: true }, this.getContainerYards, this.portBlur);

            if (this.state.placeOfReceiptId && this.state.portOfDeliveryId && this.state.portOfDischargeId && this.state.portOfLoadId && !this.state.loading) {
                if (returnLoc !== undefined && pickupLoc !== undefined) {
                    if (plr.state !== pickupLoc.state || plr.state !== returnLoc.state) {

                    } else {
                        this.getContainerYards();
                    }
                } else {
                    this.getContainerYards();
                }

                this.setState({ vvLoading: true }, this.portBlur);

            } else {
                this.setState({ vvLoading: false })
            }
        })

    }

    bookingNumberChange = (name, value) => {
        let pattern = new RegExp(/^[a-zA-Z0-9_-]*$/)
        if (pattern.test(value.trim())) {
            this.setState({
                [name]: value.trim()
            })
        }
    }

    carrierInput = (name, value) => {
        this.setState({
            [name]: value,
            vesselName: '',
            vesselId: 0
        },
            () => { this.setCurrentContract(); this.getVessels(); value > 0 ? this.setState({ showCarrier: true }) : this.setState({ showCarrier: false }) })
    }


    vesselInput = (name, value, vessel) => { vessel ? this.setState({ vesselName: vessel.name, vesselId: vessel.id }) : this.setState({ vesselName: value, vesselId: 0 }) }

    logVvInfo = () => {
        this.setState({
            vvCheckObj: {
                ...this.state.vvCheckObj,
                vesselVoyageId: this.state.vesselVoyageId,
                docDate: this.state.docCutDate,
                docTime: this.state.docCutTime,
                cutDate: this.state.cargoCutDate,
                cutTime: this.state.cargoCutTime,
                etd: this.state.etd,
                eta: this.state.eta,
                erd: this.state.erd,
                returnLocationId: this.state.returnLocationId
            }
        });
    }

    searchBookings = async () => {
        return new Promise(async (resolve, reject) => {
            if (this.state.bookingNumber) {
                let tz = moment.tz.guess();
                await Axios.get(`/api/v1/booking/bookingnumber/${this.state.bookingNumber}`, { params: { ...auth.getAuthData(), tz } }).then(async result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        this.props.dispatch({ type: 'SCORCHED_EARTH' });
                        reject();
                        return;
                    }

                    if (!result.data.booking) {
                        this.setState({ loading: false });
                        resolve();
                        return;
                    }

                    const { badge, stage, booking, vesselVoyage, portPair, vessel, vvNotification, consignees, vessels, emails, docsEmails } = result.data;
                    const vesselName = vessel.name;
                    const vesselId = vessel.id;
                    const plrState = this.props.redux.location.filter(loc => loc.id === portPair.placeOfReceiptId)[0].state;
                    const returnState = this.props.redux.location.filter(loc => loc.id === booking.returnLocationId)[0].state;
                    const pickUpState = this.props.redux.location.filter(loc => loc.id === booking.pickupLocationId)[0].state;
                    const serviceRoute = booking.serviceRoute;
                    this.setState({
                        badge,
                        ...stage,
                        ...booking,
                        preparedBookingNumbers: this.prepareBookingNumbers(booking.bookingNumber, booking.isDisplayed),
                        useDisplayName: booking.displayNameId ? true : false,
                        ...portPair,
                        ...vesselVoyage,
                        serviceRoute,
                        vesselVoyage,
                        portPair,
                        vesselName,
                        vesselId,
                        vvNotification,
                        consignees,
                        vessels,
                        emails,
                        docsEmails,
                        loading: false,
                        showCarrier: true,
                        disableBookingNumber: true,
                        bookingNumbersEdits: booking.bookingNumber,
                        aesCheckObject: {
                            ...this.state.aesCheckObject,
                            shipperId: booking.shipperId,
                            consigneeName: booking.consigneeName,
                            portOfLoadId: portPair.portOfLoadId,
                            carrierId: vesselVoyage.carrierId,
                            etd: vesselVoyage.etd,
                            portOfDeliveryId: portPair.portOfDeliveryId,
                            portOfDischargeId: portPair.portOfDischargeId,
                            vesselId: vesselVoyage.vesselId,
                            bookingNumber: booking.bookingNumber,
                            productPackaging: booking.productPackaging

                        },
                    }, () => {
                        plrState !== undefined && plrState === returnState && plrState === pickUpState ? this.setState({ showAllYards: false }, this.getContainerYards) : this.setState({ showAllYards: true }, this.getContainerYards)
                        this.logVvInfo();
                        resolve();
                    });
                });
            }
        }).catch(err => console.log(err));

    }

    portBlur = () => {
        let tz = this.getTimeZone(this.state.placeOfReceiptId);
        this.setState({ timeZone: tz });
        this.getContainerYards();
        const { placeOfReceiptId, portOfLoadId, portOfDischargeId, portOfDeliveryId } = this.state;
        if (placeOfReceiptId && portOfLoadId && portOfDischargeId && portOfDeliveryId) {
            this.searchPortPairs({ placeOfReceiptId, portOfLoadId, portOfDischargeId, portOfDeliveryId });
        } else { this.setState({ vvLoading: false }); }
    }

    loadPortPairs = () => {
        Axios.get(`/api/v1/portpair/locations/`, { params: auth.getAuthData() }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }
            this.setState({ portPairList: result.data });
        });
    }

    loadServiceRoutes = () => {
        Axios.get("/api/v1/serviceroute/", { params: auth.getAuthData() }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCED_EARTH' });
                return;
            }
            this.setState({ serviceRouteList: result.data })
        })
    }

    searchPortPairs = pp => {
        let pair = this.state.portPairList.filter(p =>
            p.placeOfReceiptId === parseInt(pp.placeOfReceiptId) &&
            p.portOfLoadId === parseInt(pp.portOfLoadId) &&
            p.portOfDischargeId === parseInt(pp.portOfDischargeId) &&
            p.portOfDeliveryId === parseInt(pp.portOfDeliveryId)
        )[0];
        this.setState({ portPair: pair }, this.vesselVoyageBlur);
    }

    serviceRouteBlur = () => {
        let serviceRoute = this.state.serviceRouteList.filter(s =>
            s.carrierId === parseInt(this.state.carrierId) &&
            s.vesselId === parseInt(this.state.vesselId) &&
            s.voyageNumber === (this.state.voyageNumber).toUpperCase()
        )[0];

        serviceRoute = serviceRoute === undefined ? "" : serviceRoute.serviceRoute

        this.setState({ serviceRoute }, this.vesselVoyageBlur)
    }

    vesselVoyageBlur = () => {
        const { shipperId, carrierId, vesselId, voyageNumber, portPair } = this.state;
        this.setState({ vvLoading: true })

        if (!portPair || !vesselId) {
            this.setState({ vesselVoyageId: '', cargoCutDate: '', cargoCutTime: '12:00', docCutDate: '', docCutTime: '16:00', etd: '', eta: '', erd: '', vvLoading: false }, () => this.logVvInfo())
            return;
        }
        if (carrierId && vesselId && voyageNumber && portPair) {
            this.searchVesselVoyages({ shipperId: this.state.useIndividualVoyage ? shipperId : null, carrierId, vesselId, voyageNumber, portPairId: portPair.id });
        }

    }

    searchVesselVoyages = vv => {
        Axios.get(`/api/v1/vesselvoyage/details/${JSON.stringify(vv)}`, { params: auth.getAuthData() }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }


            if (!result.data) {
                return this.setState({ vesselVoyageId: '', cargoCutDate: '', cargoCutTime: '12:00', docCutDate: '', docCutTime: '16:00', etd: '', eta: '', erd: '', vvLoading: false }, () => this.logVvInfo())
            }

            let vesselVoyage = result.data;

            this.setState({ vesselVoyage, ...vesselVoyage, vesselVoyageId: vesselVoyage.id, vvLoading: false }, this.logVvInfo)
        });
    }

    showModal = () => M.Modal.getInstance(document.querySelector('#create-vessel')).open();
    showReminderModal = () => {
        M.Modal.getInstance(document.querySelector("#reminder-modal")).open();
        document.getElementById("closeReminder").focus();
    }


    showEmailFormModal = async () => {

        let carrier = parseInt(this.state.carrierId) ?
            this.props.redux.carrier.filter(c => c.id === parseInt(this.state.carrierId))[0].code : ""

        let cargoDate = moment(this.state.cargoCutDate, "MM/DD/YY").format("M/D") === "Invalid date" ? "[PLEASE COMPLETE VESSEL VOYAGE]" :
            moment(this.state.cargoCutDate, "MM/DD/YY").format("M/D");

        let words = this.state.bookingNote.split(" ");
        let newWords = []
        words.forEach(w => {
            newWords.push((w.charAt(0) + w.slice(1).toLowerCase()));
        })
        let bookingNote = newWords.join(" ");

        this.setState({ generatePdf: true, emailSubject: `${bookingNote} ${carrier} ${cargoDate} c/o ${this.getBookingNumbers()}${this.getAlternateSubjectLine()}` }, () => {

            setTimeout(() => {
                M.Modal.init(document.querySelector('#emailModal'));
                M.Modal.getInstance(document.querySelector("#emailModal")).open();
                document.querySelector("#recipient").focus();
            }, 400)
        });
    }

    sendEmail = (emailInfoFromState, fileInfo) => {

        let buffer = emailInfoFromState.usePdf ? emailInfoFromState.pdf : emailInfoFromState.html;
        let datetime = moment(new Date()).format('MMDDYY_HHmmss');
        delete emailInfoFromState.pdf;
        delete emailInfoFromState.html;
        this.hideEmailModal();

        Axios.post(`/api/v1/email/`, { ...auth.getAuthData(), buffer, fileInfo, datetime, email: emailInfoFromState, emailBodyDoc: emailInfoFromState.emailBodyDoc, usePdf: emailInfoFromState.usePdf }).then(async (result) => {

            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear()
                this.props.dispatch({
                    type: "SCORCHED EARTH"
                })
                return;
            }
            if (result.data.messageSent) {
                this.setState({ generatePdf: false, emailSubject: "" });
                this.searchBookings();
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "success", msg: "E-Mail Sent"
                    }
                })
            } else {
                this.setState({ generatePdf: false });
                window.alert("Error: Email was not sent.");
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "error", msg: "E-Mail Was Not Sent"
                    }
                });
            }
        })
    }

    saveAndEmail = async () => {
        this.saveBooking().then(saveResult => {
            if (saveResult.errno || saveResult === 'NOT AUTHENTICATED') {
                return;
            } else {
                this.showEmailFormModal();
            }
        });
    }

    hideModal = () => {
        M.Modal.getInstance(document.querySelector('#create-vessel')).close();
        setTimeout(() => document.querySelector('#voyageNumber').focus(), 200);
    }

    hideReminderModal = () => {
        M.Modal.getInstance(document.querySelector("#reminder-modal")).close()
        this.searchBookings();
        this.props.dispatch({
            type: globalToastActions.UPDATE_MSG,
            payload: {
                type: "success", msg: `Booking ${this.state.bookingNumber} Has Been Cancelled`
            }
        })
        this.searchBookings();
    }

    hideEmailModal = () => {
        M.Modal.getInstance(document.querySelector("#emailModal")).close();
    }

    showBookingNumberModal = () => M.Modal.getInstance(document.querySelector('#bk-modal')).open();
    hideBookingNumberModal = () => M.Modal.getInstance(document.querySelector('#bk-modal')).close();

    openChangeLog = () => {
        this.setState({ changeLogOpenSwitch: !this.state.changeLogOpenSwitch }, () => {
            M.Modal.getInstance(document.querySelector("#logModal")).open();
        })
    }

    closeChangeLog = () => {
        M.Modal.getInstance(document.querySelector("#logModal")).close();
    }

    createVessel = () => Axios.post('/api/v1/vessel', { vessel: { name: this.state.vesselName, carrierId: this.state.carrierId }, ...auth.getAuthData() }).then(result => {
        if (result.data === 'NOT AUTHENTICATED') {
            localStorage.clear();
            this.props.dispatch({ type: 'SCORCHED_EARTH' })
            return;
        }

        let newVesselIds = this.state.newVesselIds;
        newVesselIds.push(result.data.insertId)

        this.setState(p => ({ vesselId: result.data.insertId, vesselName: p.vesselName.toUpperCase(), newVesselIds }), () => { this.getVessels(); this.vesselVoyageBlur(); });
        document.querySelector('#voyageNumber').focus();
    })

    datesAreDifferent = name => {
        if (!this.state.vvNotification || !this.state.vvNotification.docCutDate)
            return false;

        return this.state[name] !== this.state.vvNotification[name];
    }

    getTimeZone = id => {
        if (id === 0) {
            return
        }
        if (typeof id === 'string') {
            id = parseInt(id, 10);
            if (id === 0) {
                return
            }
        }
        if (!this.props.redux.location.filter(l => l.id === id).length) {
            return
        }
        const tz = this.props.redux.location.filter(l => l.id === id)[0].timeZone;
        return tz ? tz : 'America/Los_Angeles';
    }

    dateCheck = () => {
        return new Promise((resolve, reject) => {
            if (window.confirm(`The time between the entered Doc Cut and the Cargo Cut is greater than 10 days. Please make sure the dates are correct, and you have entered the correct year.

Click OK to continue and save, or click Cancel to go back`)) {
                resolve(false)
            } else {
                resolve(true);
            }
        })
    };

    erdDateCheck = () => {
        return new Promise((resolve, reject) => {
            if (window.confirm(`The time between the entered ERD and the Cargo Cut is greater than 5 days. Please make sure the dates are correct, and you have entered the correct year.

Click OK to continue and save, or click Cancel to go back`)) {
                resolve(false)
            } else {
                resolve(true);
            }
        })
    }

    erdAheadOfCargoDateCheck = () => {
        return new Promise((resolve, reject) => {
            if (window.confirm(`The entered ERD appears after the entered Cargo Cut Date. Please make sure the dates are correct, and you have entered the correct year.

Click OK to continue and save, or click Cancel to go back`)) {
                resolve(false)
            } else {
                resolve(true);
            }
        })
    }

    erdChangeCheck = () => {
        return new Promise((resolve, reject) => {
            let { vvCheckObj } = this.state;
            if (vvCheckObj.cutDate && vvCheckObj.cutTime && vvCheckObj.docDate && vvCheckObj.docTime && vvCheckObj.eta && vvCheckObj.etd && vvCheckObj.erd && vvCheckObj.returnLocationId) {
                let { cargoCutTime, cargoCutDate, docCutTime, docCutDate, eta, etd, erd, returnLocationId } = this.state;

                if ((vvCheckObj.cutDate !== cargoCutDate ||
                    vvCheckObj.cutTime !== cargoCutTime ||
                    vvCheckObj.docDate !== docCutDate ||
                    vvCheckObj.docTime !== docCutTime ||
                    vvCheckObj.eta !== eta ||
                    vvCheckObj.etd !== etd ||
                    vvCheckObj.returnLocationId !== returnLocationId) && vvCheckObj.erd === erd) {
                    resolve(!window.confirm(`PLEASE CONFIRM if ERD needs to be changed too!
                   
        Click Ok to continue and save, or click Cancel to go back`))
                } else {
                    resolve(false)
                }
            } else {
                resolve(false);
            }
        })
    }

    changeCheck = (newObject, referenceObject) => {
        return new Promise((resolve, reject) => {
            if (!newObject.id || !newObject.siSentOrPrinted || !newObject.dummySI) {
                return;
            } else {
                for (let key of Object.keys(referenceObject)) {
                    if (key === 'etd') {
                        let startDateRange = moment(referenceObject[key], "MM/DD/YY").subtract(5, 'd').format("MM/DD/YY");
                        let endDateRange = moment(referenceObject[key], "MM/DD/YY").add(5, 'd').format("MM/DD/YY");
                        if (moment(newObject[key], "MM/DD/YY").isBefore(moment(startDateRange, "MM/DD/YY")) || moment(newObject[key], "MM/DD/YY").isAfter(moment(endDateRange, "MM/DD/YY"))) {
                            this.setState({ showAesAlert: true })
                            break;
                        }
                    } else if (newObject[key] !== referenceObject[key]) {
                        this.setState({ showAesAlert: true })
                        break;
                    } else {
                        continue;
                    }
                }
                return resolve();
            }
        })
    };

    aesAlertCheck = async () => {
        await this.changeCheck(this.state, this.state.aesCheckObject)
        if (this.state.showAesAlert) {
            alert("Please remember to update AES!");
            this.setState({ showAesAlert: false });
        }

        return;
    }

    sendSlackNotifications = () => {

        if (!this.state.vvUsers && this.state.vvBookingNumbersAndShippers) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "warning", msg: "No Users Assigned to Send Notification" } })
        } else if (!this.state.vvCheckObj.erd) {
            return;
        }

        let slackUpdateInfo = {
            oldErd: this.state.vvCheckObj.erd,
            newErd: this.state.erd,
            bookingNumber: this.state.bookingNumber[0]
        }

        Axios.post("/api/v1/slack", { ...auth.getAuthData(), slackUpdateInfo }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
        })
    }

    sendEtdChangeNotification = () => {

        if (!this.state.siSentOrPrinted || this.state.dummySI) {
            return;
        }

        let { bookingNumber } = this.state;
        let timezone = moment.tz.guess();
        let newEtd = this.state.etd;
        let oldEtd = this.state.vvCheckObj.etd;
        let user = this.props.redux.user.emailAddress;

        Axios.get("/api/v1/notification", { params: { ...auth.getAuthData(), bookingNumber: bookingNumber[0], timezone, newEtd, oldEtd, user } }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.data) {
                let failedNotification = await result.data.filter(r => r.error);
                if (failedNotification.length) {
                    let successNotification = await result.data.filter(r => r.notificationSent);
                    window.alert(`Notification email for ETD change failed for the following user(s):
                    ${failedNotification.map(f => f.user)}\n\n Notification email for ETD change succeeded for the following user(s):
                    ${successNotification.map(f => f.user)}\n`);
                }
            }
        })
    }

    saveBooking = () => new Promise(async (resolve, reject) => {

        if (this.state.loading || this.state.vvLoading) {
            return resolve({ errno: "WAIT" });;
        }
        let cancelSave;
        let erdCancelSave;
        let earlyErdCancelSave;
        let erdAheadOfCargoCancelSave;
        if (!this.state.bookingNumber)
            return;
        let { cargoCutDate, cargoCutTime, docCutDate, docCutTime, etd, eta, erd } = this.state;

        if (moment(docCutDate, 'MM-DD-YY').day() === 6 || moment(docCutDate, 'MM-DD-YY').day() === 0) {
            alert("A doc cutoff on the weekend is not allowed.");
            return;
        }

        cargoCutDate = moment.tz(cargoCutDate + cargoCutTime, 'MM/DD/YYHH:mm +00:00', this.getTimeZone(this.state.placeOfReceiptId)).utc().format('YYYY-MM-DD HH:mm:ss');
        docCutDate = moment.tz(docCutDate + docCutTime, 'MM/DD/YYHH:mm +00:00', this.getTimeZone(this.state.placeOfReceiptId)).utc().format('YYYY-MM-DD HH:mm:ss');
        etd = moment.tz(etd, 'MM/DD/YY 12:00:00', this.getTimeZone(this.state.portOfLoadId)).utc().format('YYYY-MM-DD HH:mm:ss');
        eta = moment.tz(eta, 'MM/DD/YY 12:00:00', this.getTimeZone(this.state.portOfDeliveryId)).utc().format('YYYY-MM-DD HH:mm:ss');
        erd = erd ? moment.tz(erd, 'MM/DD/YY 12:00:00', this.getTimeZone(this.state.placeOfReceiptId)).utc().format('YYYY-MM-DD HH:mm:ss') : null;

        if (moment(docCutDate, 'YYYY-MM-DD HH:mm:ss').diff(moment(cargoCutDate, 'YYYY-MM-DD HH:mm:ss'), "days") > 10 || moment(docCutDate, 'YYYY-MM-DD HH:mm:ss').diff(moment(cargoCutDate, 'YYYY-MM-DD HH:mm:ss'), "days") < -10) {
            cancelSave = await this.dateCheck();
        };

        if (!!erd && moment(erd, "YYYY-MM-DD HH:mm:ss").diff(moment(cargoCutDate, "YYYY-MM-DD HH:mm:ss"), "days") < -6) {
            earlyErdCancelSave = await this.erdDateCheck();
        }

        if (!!erd && moment(erd, "YYYY-MM-DD HH:mm:ss").diff(moment(cargoCutDate, "YYYY-MM-DD HH:mm:ss"), "days") > 0) {
            erdAheadOfCargoCancelSave = await this.erdAheadOfCargoDateCheck();
        }


        erdCancelSave = await this.erdChangeCheck();

        let dateCheckArray = [cargoCutDate, docCutDate, etd, eta];
        for (let i = 0; i < dateCheckArray.length; i++) {
            if (dateCheckArray[i] === "Invalid date") {
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG,
                    payload: {
                        type: "error",
                        msg: "Invalid Date Field(s)"
                    }
                })
                return;
            }
        }

        let booking = { ...this.state, };

        booking.cargoCutDate = cargoCutDate;
        booking.docCutDate = docCutDate;
        booking.etd = etd;
        booking.eta = eta;
        booking.erd = erd;
        booking.vessels = booking.containerYards = booking.portPairList = booking.serviceRouteList = [];

        if (!cancelSave && !erdCancelSave && !earlyErdCancelSave && !erdAheadOfCargoCancelSave) {
            Axios.post('/api/v1/booking', { booking, ...auth.getAuthData() })
                .then(async result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' })
                        return resolve();
                    }

                    if (result.data.errno) {

                        if (result.data.code === "ER_NO_REFERENCED_ROW_2") {
                            let errorArray = result.data.sqlMessage.split('`');
                            let fieldIndex = errorArray.indexOf(" FOREIGN KEY (") + 1;
                            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Missing Field: ${errorArray[fieldIndex]}`, type: 'error' } });
                        } else {
                            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: result.data.errno.code, type: 'error' } });
                        }

                        reject(result.data);
                    }
                    else {
                        if (result.data.insert) {
                            this.setState({ bookingId: result.data.insertId })
                        }
                        //INSERT SWITCH HERE AT A POINT WHEN ITS NEEDED
                        if (this.state.vvCheckObj.erd !== this.state.erd) {
                            this.sendSlackNotifications();
                        }
                        if (this.state.vvCheckObj.etd !== this.state.etd) {
                            this.sendEtdChangeNotification();
                        }

                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Booking saved to database.ID: ${result.data.bookingId} `, type: 'success' } })
                        this.props.navigate(`/shipment/create/${this.state.bookingNumber}`, true);
                        this.setState({ generatePdf: false, newVesselIds: [] });

                        this.aesAlertCheck();
                        this.searchBookings().then(() => {
                            resolve(result.data);
                        });
                    }
                })
        } else { return resolve(); }
    })

    duplicateBooking = () => {

        if (this.state.loading || this.state.vvLoading) {
            return { errno: "WAIT" }
        }

        if (!this.state.bookingNumber)
            return;

        let { cargoCutDate, cargoCutTime, docCutDate, docCutTime, etd, eta, erd } = this.state;
        cargoCutDate = moment.tz(cargoCutDate + cargoCutTime, 'MM/DD/YYHH:mm +00:00', this.getTimeZone(this.state.placeOfReceiptId)).utc().format('YYYY-MM-DD HH:mm:ss');
        docCutDate = moment.tz(docCutDate + docCutTime, 'MM/DD/YYHH:mm', this.getTimeZone(this.state.placeOfReceiptId)).utc().format('YYYY-MM-DD HH:mm:ss');
        etd = moment.tz(etd, 'MM/DD/YY 12:01:00', this.getTimeZone(this.state.portOfLoadId)).utc().format('YYYY-MM-DD HH:mm:ss');
        eta = moment.tz(eta, 'MM/DD/YY 12:01:00', this.getTimeZone(this.state.portOfDeliveryId)).utc().format('YYYY-MM-DD HH:mm:ss');
        erd = erd ? moment.tz(erd, 'MM/DD/YY 12:01:00', this.getTimeZone(this.state.placeOfReceiptId)).utc().format('YYYY-MM-DD HH:mm:ss') : null;

        // const booking = { ...this.state };
        let booking = { ...this.state, cargoCutDate, docCutDate, etd, eta, erd };
        // const portPair = { ...this.state };
        booking.vessels = booking.containerYards = booking.portPairList = [];
        Axios.post('/api/v1/booking', { booking, ...auth.getAuthData() })
            .then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return;
                }

                if (result.data.errno)
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'An error ocurred. Record not saved.', type: 'error' } });
                else {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Booking saved to database.ID: ${result.data.insertId} `, type: 'success' } })

                    return this.setState({ ...this.stateForDuplication(), disableBookingNumber: false }, () => {
                        document.querySelector('#bookingNumber').focus()
                    });
                }
            })
    }

    goToOverviewVesselVoyage = () => {
        if (this.state.vesselName && (this.state.vesselVoyage || this.state.voyageNumber)) {
            const voyageNumber = this.state.vesselVoyage ? this.state.vesselVoyage.voyageNumber : this.state.voyageNumber;
            const carrier = parseInt(this.state.carrierId) ? this.props.redux.carrier.filter(c => c.id === parseInt(this.state.carrierId))[0].code : "";
            this.props.navigate('/shipment', {
                state: {
                    bookingId: this.state.bookingId,
                    filterList: [
                        { searchTerm: "ACTIVE", type: "status", id: 1 },
                        { searchTerm: this.state.vesselName, type: "vesselName", id: 2 },
                        { searchTerm: voyageNumber, type: "voyageNumber", id: 3 },
                        { searchTerm: carrier, type: "carrierName", id: 4 }
                    ]
                }
            })
        }
        else
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'NO VESSEL VOYAGE SELECTED', type: 'warning' } })
    }

    gotToBatchOverview = () => {
        this.saveBooking().then(() => {
            if (this.state.vesselName && (this.state.vesselVoyage || this.state.voyageNumber)) {
                const voyageNumber = this.state.vesselVoyage ? this.state.vesselVoyage.voyageNumber : this.state.voyageNumber;
                const vesselName = this.state.vesselName ? this.state.vesselName : '';
                const carrier = parseInt(this.state.carrierId, 10) ? this.props.redux.carrier.filter(c => c.id === parseInt(this.state.carrierId, 10))[0].code : "";
                const shipper = parseInt(this.state.shipperId, 10) ? this.props.redux.shipper.filter(s => s.id === parseInt(this.state.shipperId, 10))[0].name : "";
                const origin = parseInt(this.state.portPair.placeOfReceiptId, 10) ? this.props.redux.location.filter(l => l.id === parseInt(this.state.portPair.placeOfReceiptId, 10))[0].code : '';
                const pol = parseInt(this.state.portPair.portOfLoadId, 10) ? this.props.redux.location.filter(l => l.id === parseInt(this.state.portPair.portOfLoadId, 10))[0].code : '';
                const returnLoc = parseInt(this.state.returnLocationId, 10) ? this.props.redux.location.filter(l => l.id === parseInt(this.state.returnLocationId, 10))[0].code : '';
                this.props.navigate('/shipment', {
                    state: {
                        bookingId: this.state.bookingId,
                        filters: {
                            isDummy: "NO BKG DUMMIES"
                        },
                        filterList: [
                            { searchTerm: this.state.status === "ACTIVE" ? "ACTIVE" : "CANCELLED", type: "status", id: 1 },
                            { searchTerm: voyageNumber, type: 'voyageNumber', id: 2 },
                            { searchTerm: vesselName, type: 'vesselName', id: 3 },
                            { searchTerm: carrier, type: 'carrierName', id: 4 },
                            { searchTerm: origin, type: 'originCode', id: 5 },
                            { searchTerm: shipper, type: "shipperName", id: 6 },
                            { searchTerm: pol, type: 'portOfLoad', id: 7 },
                            { searchTerm: returnLoc, type: "returnLocation", id: 8 }
                        ]
                    }
                })
            }
        })
    }

    goToBookingNumberEditor = () => {
        if (!this.state.bookingId)
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'NO BOOKING SELECTED', type: 'warning' } })
        else {
            if (this.state.siSentOrPrinted && !this.state.dummySI) {
                alert("Please remember to update AES!");
            }
            this.props.navigate('/shipment/bookingnumber/edit', {
                state: {
                    ...this.props.location?.state,
                    bookingId: this.state.bookingId
                }
            });
        }
    }

    getBookingNumbers = () => {
        let arr = [];
        const { bookingNumber, isDisplayed } = this.state;
        for (let i = 0; i < bookingNumber.length; i++) {
            if (parseInt(isDisplayed[i])) {
                arr.push(bookingNumber[i])
            }
        }
        return arr.join(" / ");
    }

    getAlternateSubjectLine = () => {
        let alternateSubjectLine;
        let consigneeShortName;

        if (this.props.redux.party.filter(p => p.id === parseInt(this.state.shipperId))[0].useAlternateSubjectLine) {
            if (this.props.redux.party.filter(p => p.name === this.state.consigneeName).length) {
                consigneeShortName = this.props.redux.party.filter(p => p.name === this.state.consigneeName)[0].code;
                consigneeShortName = consigneeShortName ? " - " + consigneeShortName : "";
            } else {
                consigneeShortName = "";
            }
            let shipperRefNum = this.state.shipperReferenceNumber ? " - " + this.state.shipperReferenceNumber : "";
            alternateSubjectLine = `${consigneeShortName}${shipperRefNum}`
        } else {
            alternateSubjectLine = '';
        }
        return alternateSubjectLine;
    }

    getDefaultAssigneeAndVoyageStatus = () => {
        let defaultUser = this.props.redux.shipper.filter(p => p.id === parseInt(this.state.shipperId))[0] ?
            this.props.redux.shipper.filter(p => p.id === parseInt(this.state.shipperId))[0].bookings : "0";
        let useIndividualVoyage = this.props.redux.shipper.filter(p => p.id === parseInt(this.state.shipperId))[0] ?
            this.props.redux.shipper.filter(p => p.id === parseInt(this.state.shipperId))[0].useIndividualVoyage : false

        this.setState({ bookingsAssignee: defaultUser, useIndividualVoyage })
    }

    prepareBookingNumbers = (bookingNumber, isDisplayed) => {
        let arr = [];
        if (Array.isArray(bookingNumber)) {
            for (let i = 0; i < bookingNumber.length; i++) {
                if (parseInt(isDisplayed[i], 10)) {
                    arr.push(bookingNumber[i]);
                }
            }
        }
        return arr.join(" /");
    }

    copyToClipboard = e => {
        const text = e.target.textContent;
        navigator.clipboard.writeText(text.trim())
        document.getSelection().removeAllRanges();
        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `${text} Copied to Clipboard.` } })
    }

    getConsignees = id => {

        Axios.get('/api/v1/party/consignees/byshipper', {
            params: {
                ...auth.getAuthData(),
                shipperId: this.state.shipperId ? this.state.shipperId : this.props.redux.shipperId
            }
        }).then(result => {

            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' });
                return;
            }
            this.setState({ consignees: result.data })
        }

        )
    }


    handleContextMenu = (e, status) => {
        e.preventDefault();
        if (status) {
            this.setState({ showStatusMenu: true, showContextMenu: false, x: e.pageX, y: e.pageY })
        } else {
            this.setState({ showContextMenu: true, x: e.pageX, y: e.pageY }, () => {
                document.getElementById('contextMenuFirstOption').focus();
            })
        }

        document.addEventListener('click', this.clickListener);

        //COMMENTING THESE OUT IN THE CASE IT IS LINKED TO SOMETHING UNSEEN/ BUT CURRENTLY APPEARS COMPLETELY UNNEEDED
        // document.querySelector(`.bookingBadge`).classList.add('menu-selected');
        // document.querySelector(`.yardDropdowns`).classList.add('menu-selected');
    };

    handleStatusMenu = (e, status) => {
        this.handleContextMenu(e, status);
    }

    clickListener = e => {
        //COMMENTING THESE OUT IN THE CASE IT IS LINKED TO SOMETHING UNSEEN/ BUT CURRENTLY APPEARS COMPLETELY UNNEEDED
        // document.querySelector('.bookingBadge').classList.remove('menu-selected');
        // document.querySelector('.yardDropdowns').classList.remove('menu-selected');
        this.setState({ showContextMenu: false, showStatusMenu: false }, () => document.removeEventListener('click', this.clickListener))
    }


    handleDelete = () => {
        const bkg = JSON.parse(JSON.stringify(this.state));
        if (window.confirm(`ARCHIVE BOOKING: ${bkg.bookingNumber}?`)) {
            Axios.post('/api/v1/booking/updatestatus',
                {
                    ...auth.getAuthData(),
                    status: 'ARCHIVED',
                    id: bkg.bookingId,
                }).then(result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' })
                        return;
                    }
                    this.searchBookings();
                });
        }
        else {
            this.setState({ editBkg: null });
        }
    }

    changeStatus = status => {
        let id = this.state.bookingId;
        Axios.post('/api/v1/booking/updatestatus', { id, status, ...auth.getAuthData() }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }
            if (status === "CANCELLED" &&
                this.state.aesNumber) {
                this.showReminderModal();
            } else {
                this.searchBookings();
            }
        });
    }

    generateStage = (docCutDate, SIPrinted, invoice, dummySI, enteredContainers) => {

        let date = moment().format("YYYY-MM-DD HH:mm:ss");
        if (invoice) {
            this.setState({
                stageColor: "green",
                stageIcon: "attach_money"
            });
        }
        else if (SIPrinted !== null && SIPrinted !== undefined && SIPrinted) {
            if (dummySI || !enteredContainers) {
                this.setState({
                    stageColor: "",
                    stageIcon: "assignment_ind"
                });
            } else {
                this.setState({
                    stageColor: "",
                    stageIcon: "assignment_turned_in"
                });
            }

        }
        else if (moment(date).isAfter(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss")) && docCutDate !== undefined && docCutDate) {
            this.setState({
                stageColor: "red",
                stageIcon: "assignment_late"
            });
        }
        else if (moment(date).isSame(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"), "d") && docCutDate !== undefined && docCutDate) {
            this.setState({
                stageColor: "",
                stageIcon: "history"
            });
        }

        else {
            this.setState({
                stageColor: "#f3f3f3",
                stageIcon: "lens"
            });
        }
    }

    getInfoHeader = () => {
        let shipper = this.props.redux.shipper.filter(s => s.id === this.props.location?.state?.shipperId).length ? this.props.redux.shipper.filter(s => s.id === this.props.location?.state?.shipperId)[0].name : null
        if (this.state.fileUploadTabOpen) {
            return <div className="col s12 l6 bookingHeaderInfo">

                <div className="row" style={{ marginBottom: "0px", fontWeight: 700 }}>
                    <div className="col s6">{this.getBookingNumbers()} / {this.state.lNumber}</div>
                    <div className="col s6">{shipper}</div>
                </div>
            </div>
        } else {
            return null
        }
    }

    render = () => {

        return (
            <div>
                <div className="row bookingAndSiTabs">
                    <div className="col s12 l6" style={{ paddingLeft: "0px" }}>
                        <ul className={`tabs bookingTabs`} id="tabs" style={{ borderBottom: "0px" }}>
                            <li id='bookingTab' className={`tab col s3 shipmentTabs ${this.getBookingTabStyling()}`}>
                                <a tabIndex="-1" href="#BOOKING" onClick={e => this.setActive(e, 'bookingTab')}>Booking</a>
                            </li>
                            {this.state.bookingId ?
                                <li id='fileUploadTab' className={`tab col s3 shipmentTabs ${this.getUploadTabStyling()}`}>
                                    <a tabIndex="-1" href="#FILEUPLOAD" onClick={e => this.setActive(e, 'fileUploadTab')}>
                                        Files{" "}
                                        {this.state.loading && this.props.location?.state?.searchTerm ?
                                            <div className="preloader-wrapper small active fileCountSpinner">
                                                <div className="spinner-layer spinner-green-only">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div><div className="gap-patch">
                                                        <div className="circle"></div>
                                                    </div><div className="circle-clipper right">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            this.state.bookingFilesCount
                                        }
                                    </a></li> : null}
                            <li id='shippingInstructionsTab' className={`tab col s3 shipmentTabs ${this.getShippingInstructionsTabStyling()}`}>
                                <a tabIndex="-1" href="#SHIPPINGINSTRUCTIONS" onClick={this.clickZoom}>Shipping Instructions</a>
                            </li>
                            {this.state.siSentOrPrinted && !this.state.dummySI ?
                                <li className={`tab col s1 stickyNoteButtonTab ${this.getStickyNoteTabStyling()} `} onClick={this.openStickyNoteModal}>
                                    <i className="material-icons stickyNoteButtonIcon">speaker_notes</i>
                                </li>
                                :
                                null}
                        </ul>
                    </div>
                    {this.getInfoHeader()}
                </div>
                {this.state.loading && this.props.location?.state?.searchTerm ?
                    <div className="preloader-wrapper big active bookingLoader">
                        <div className="spinner-layer spinner-blue-only">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div><div className="gap-patch">
                                <div className="circle"></div>
                            </div><div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div> :
                    <div>
                        <div id="booking" className="createBookingWrapper">
                            <div>
                                {this.state.badge.type === "CNCL" ? <div className="cancelStamp" unselectable="on">CANCELLED</div> : null}
                                <div className="row">
                                    <div className="col s9">
                                        <div className="row">
                                        </div>
                                        <div className="row">
                                            <Form.TextInput autoFocus={true} col="s9 l10" name="bookingNote" label="Booking Note" onChange={this.onChange} value={this.state.bookingNote} maxLength="90" flags={[flags.ALLOW_EMPTY]} />
                                            <Form.CheckBox col="s3 l2 dummyCheckbox" className="dummyCheckbox" name="isDummy" label="Dummy" onChange={this.onChange} value={this.state.isDummy} />
                                        </div>
                                        <div className="row">
                                            <Form.Select col="s3" placeholder='Choose a Carrier' name="carrierId" label="Carrier" onChange={this.carrierInput} onBlur={this.vesselVoyageBlur} value={this.state.carrierId} list={this.props.redux.carrier} filter={{ label: 'displayName', value: 'id' }} />
                                            <Form.Select col={`col s4`} className={`bookingShipperDropdown ${this.state.shipperId === "0" ? "noValue" : ""} ${this.state.isDummy ? 'dummy' : ""} `} name='shipperId' onChange={this.shipperOnChange} label="Shipper" list={this.props.redux.shipper} value={this.state.shipperId} filter={{ label: 'name', value: "id" }} placeholder="Select a Shipper" />
                                            <Form.CheckBox col="s5 l1 useDisplayNameCheckbox" name='useDisplayName' className="useDisplayNameCheckbox" onChange={this.onChange} value={this.state.useDisplayName} />
                                            <Form.Select col="s3" disabled={this.state.useDisplayName ? false : true} tabIndex={this.state.useDisplayName ? 0 : -1} className={`${this.state.useDisplayName ? "" : 'hideDisplayName'} ${this.state.isDummy ? 'dummy' : ""} `} name="displayNameId" onChange={this.onChange} label="Display Name" list={this.props.redux.shipper} value={this.state.displayNameId} filter={{ label: 'name', value: 'id' }} />
                                            {/* <h6 className="col s2">{this.state.contract ? this.state.contract.contractNumber : ''}</h6> */}
                                        </div>
                                        <div className="row">
                                            {/* Ideally the contract number would then go here, or  perhaps now, we'll see hehe */}
                                            <Form.Select col="col s7 offset-s5" placeholder="Same As Shipper" name="supplierId" label="Supplier" onChange={this.onChange} value={this.state.supplierId} list={this.props.redux.shipper} filter={{ label: 'name', value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                        </div>
                                        <div className="row">
                                            {this.state.datetimeCreated ?
                                                <div style={{ margin: '0px', padding: '0px', maxHeight: '36px' }} className="col s6">
                                                    <div className="col s1 left badgeHolder">
                                                        {this.state.badge.type ? <span onContextMenu={e => this.handleStatusMenu(e, true)} id={this.state.badge.id} className={`badge bookingBadge ${this.state.badge.color} z - depth - 0`}>{this.state.badge.type}</span> : null}
                                                        <span><i
                                                            className="material-icons badgeHolderStageIcon"
                                                            style={{
                                                                color: !this.state.isDummy && this.state.stageIcon !== "lens"
                                                                    ? `${this.state.stageColor} ` : this.state.isDummy && this.state.stageIcon === "lens"
                                                                        ? `${this.state.stageColor} ` : !this.state.isDummy && this.state.stageIcon === "lens"
                                                                            ? `${this.state.stageColor} ` : "#9c27b0"
                                                            }}>{this.state.stageIcon}</i></span>
                                                    </div>
                                                    <b className={`${styles.bookingNumber} ${this.state.isDummy ? 'dummyPurple' : ""} `} id="bookingNumber" onClick={this.copyToClipboard}> {this.getBookingNumbers()}</b>
                                                    <Form.Button col="s2 right" type="outline" label="Edit" onClick={this.goToBookingNumberEditor} />
                                                </div>
                                                :
                                                <Form.TextInput col="s6" name="bookingNumber" label="Booking #" onChange={this.bookingNumberChange} onBlur={this.searchBookings} value={this.state.bookingNumber} maxLength="20" />
                                            }
                                            <Form.TextInput col="s6" name="shipperReferenceNumber" label="Shipper Ref #" onChange={this.onChange} value={this.state.shipperReferenceNumber} flags={[flags.ALLOW_EMPTY]} />
                                        </div>
                                        <div className="row">
                                            <Form.NumberInput col="s2" name="containerCount" label="Count" onChange={this.onChange} value={this.state.containerCount} maxLength="2" flags={[flags.MAX_VALUE + '99']} />
                                            <Form.NumberInput col="s2" name="actualContainerCount" label="Actual Count" onChange={this.onChange} value={this.state.actualContainerCount} maxLength="2" flags={[flags.MAX_VALUE + "99", flags.ALLOW_EMPTY]} />
                                            <Form.Select col="s2" name="containerTypeId" label="type" onChange={this.onChange} list={this.props.redux.containerType} filter={{ label: 'code', value: 'id' }} value={this.state.containerTypeId} />
                                            <Form.AutoComplete col="s3" name="consigneeName" label="consignee" onChange={this.onChange} value={this.state.consigneeName} data={this.state.consignees} filterValue="name" flags={[flags.ALLOW_EMPTY]} />
                                            <Form.TextInput col="s3" name="productPackaging" label="Prod/Pkg" onChange={this.onChange} value={this.state.productPackaging} flags={[flags.ALLOW_EMPTY]} />
                                        </div>
                                        <div style={{ position: 'relative', bottom: '22px', right: '10px' }}>
                                            <small>{this.state.enteredContainers ? `Entered: ${this.state.enteredContainers} ` : null}</small>
                                        </div>
                                        <div className="row">
                                            <Form.AutoComplete id="vessel-add-autocomplete" placeholder={this.state.showCarrier ? "" : "Please choose a carrier"} disabled={this.state.showCarrier ? false : true} add={this.state.showCarrier ? this.showModal : ""} col="s6" name="vesselName" label="Vessel" onChange={this.vesselInput} onBlur={this.vesselVoyageBlur} value={this.state.vesselName} filterValue="name" data={this.state.vessels} />
                                            <Form.TextInput col="s3" name="voyageNumber" label="Voyage" onChange={this.onChange} onBlur={this.serviceRouteBlur} value={this.state.voyageNumber} />
                                            <Form.TextInput col="s3" name="serviceRoute" label="Service" onChange={this.onChange} value={this.state.serviceRoute} flags={this.state.serviceRouteCarriers.includes(this.state.carrierId) ? null : [flags.ALLOW_EMPTY]} />
                                        </div>
                                        <div className="row">
                                            <div className="col s5 offset-s1">
                                                <div className="row">
                                                    <Form.Select col="s12" name="placeOfReceiptId" placeholder="Place of Receipt" label="PLR" value={this.state.placeOfReceiptId} onChange={this.portOnChange} list={this.getDomesticPorts()} filter={{ label: 'name', value: 'id' }} />
                                                </div>
                                                <div className="row">
                                                    <Form.Select col="s12" name="portOfLoadId" placeholder="Port of Load" label="POL" value={this.state.portOfLoadId} onChange={this.portOnChange} list={this.getDomesticPorts()} filter={{ label: 'name', value: 'id' }} />
                                                </div>
                                                <div className="row">
                                                    <Form.Select col="s12" name="portOfDischargeId" placeholder="Port of Discharge" label="POD" value={this.state.portOfDischargeId} onChange={this.portOnChange} list={this.getForeignPorts()} filter={{ label: 'name', value: 'id' }} />
                                                </div>
                                                <div className="row">
                                                    <Form.Select col="s12" name="portOfDeliveryId" label="PLD" placeholder="Place of Delivery" value={this.state.portOfDeliveryId} onChange={this.portOnChange} list={this.getForeignPorts()} filter={{ label: 'name', value: 'id' }} />
                                                </div>
                                            </div>
                                            {this.state.vvLoading ?
                                                <div className="col s6">
                                                    <div className="vvSpinner">
                                                        <div className="preloader-wrapper small active">
                                                            <div className="spinner-layer spinner-blue-only">
                                                                <div className="circle-clipper left">
                                                                    <div className="circle">
                                                                    </div>
                                                                </div>
                                                                <div className="gap-patch">
                                                                    <div className="circle">
                                                                    </div>
                                                                </div>
                                                                <div className="circle-clipper right">
                                                                    <div className="circle">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br></br>
                                                        <p>Searching</p>
                                                        <p>V/V</p>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col s6">
                                                    <div className="row">
                                                        <div className="col s2 flagIconHolder">
                                                            <i className={`material-icons ${!this.state.datetimeSent && !this.state.datetimePrinted ? "red-text" : ""}`}>{(this.state.flaggedForReview && !this.state.isDummy) ? 'date_range' : ''}</i>
                                                        </div>
                                                        <Form.DateInput id="cargoCutDate" col="s4" name="cargoCutDate" label="Cut" disabled={this.state.vvLoading ? true : false} onChange={this.onChange} value={this.state.cargoCutDate} highlight={this.datesAreDifferent('cargoCutDate')} tooltip={this.datesAreDifferent('cargoCutDate') ? this.state.vvNotification.cargoCutDate : null} />
                                                        <Form.TimeInput col="s4" name="cargoCutTime" label="Time" onChange={this.onChange} value={this.state.cargoCutTime} highlight={this.datesAreDifferent('cargoCutTime')} tooltip={this.datesAreDifferent('cargoCutTime') ? this.state.vvNotification.cargoCutTime : null} />
                                                        {/* <div className="col s2 timeZoneDisplay"><b>{this.state.timeZone ? this.state.timeZone : " " }</b></div> */}
                                                    </div>
                                                    <div className="row">
                                                        <Form.DateInput col="s4 offset-s2" name="docCutDate" label="Doc" onChange={this.onChange} value={this.state.docCutDate} highlight={this.datesAreDifferent('docCutDate')} tooltip={this.datesAreDifferent('docCutDate') ? this.state.vvNotification.docCutDate : null} />
                                                        <Form.TimeInput col="s4" name="docCutTime" label="Time" onChange={this.onChange} value={this.state.docCutTime} highlight={this.datesAreDifferent('docCutTime')} tooltip={this.datesAreDifferent('docCutTime') ? this.state.vvNotification.docCutTime : null} />
                                                        {/* <div className="col s2 timeZoneDisplay"><b>{this.state.timeZone ? this.state.timeZone : " " }</b></div> */}
                                                    </div>
                                                    <div className="row">
                                                        <Form.DateInput col="s4 offset-s2" name="etd" label="ETD" onChange={this.onChange} value={this.state.etd} highlight={this.datesAreDifferent('etd')} tooltip={this.datesAreDifferent('etd') ? this.state.vvNotification.etd : null} />
                                                    </div>
                                                    <div className="row">
                                                        <Form.DateInput col="s4 offset-s2" name="eta" label="ETA" onChange={this.onChange} value={this.state.eta} highlight={this.datesAreDifferent('eta')} tooltip={this.datesAreDifferent('eta') ? this.state.vvNotification.eta : null} />
                                                        <Form.DateInput col="s4" name="erd" label="ERD" onChange={this.onChange} value={this.state.erd} flags={[flags.ALLOW_EMPTY]} highlight={this.datesAreDifferent('erd')} tooltip={this.datesAreDifferent('erd') ? this.state.vvNotification.erd : null} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <Form.Select col="s6" name="pickupLocationId" className="yardDropdowns" onContextMenu={e => this.handleStatusMenu(e, false)} placeholder="Pickup Location" label="Pickup" onChange={this.onChange} value={this.state.pickupLocationId} list={this.state.containerYards} filter={{ label: 'name', value: 'id' }} />
                                            <Form.Select col="s6" name="returnLocationId" className="yardDropdowns" onContextMenu={this.handleContextMenu} label="Return" placeholder="Return Location" onChange={this.onChange} value={this.state.returnLocationId} list={this.state.containerYards} filter={{ label: 'name', value: 'id' }} />
                                        </div>
                                    </div>
                                    <div className="col s3">
                                        <div className="row">
                                            <div className='col s12' style={{ height: '20px' }}>
                                            </div>
                                            <div className="col s12" >
                                                <Form.TextArea className="internalNotes" name="internalNote" label="Internal Notes" onChange={this.onChange} value={this.state.internalNote} height="240px" />
                                                <Form.Section col="userInfo" label="">
                                                    <div className="floatContainer"><b className="classTitle">C</b><b className="colon">: </b><span className="nameTitle">{this.state.createdBy}</span><span className="names">{this.state.datetimeCreated}</span></div>
                                                    <div className="floatContainer"><b className="classTitle">M</b><b className="colon">: </b><span className="nameTitle">{this.state.lastModifiedBy}</span><span className="names">{this.state.datetimeModified}</span></div>
                                                    <div className="floatContainer"><b className="classTitle">P</b><b className="colon">: </b><span className="nameTitle">{this.state.lastPrintedBy}</span><span className="names">{this.state.datetimePrinted}</span></div>
                                                    <div className="floatContainer"><b className="classTitle">E</b><b className="colon">: </b><span className="nameTitle">{this.state.lastSentBy}</span><span className="names">{this.state.datetimeSent}</span></div>
                                                </Form.Section>
                                            </div>
                                            <div className="col s12">
                                                <Form.Select name="bookingsAssignee" col="s12 bookingsEmployee" placeholder=" " label="Assigned User" onChange={this.onChange} value={this.state.bookingsAssignee} filter={{ label: "fullName", value: "id" }} list={this.props.redux.portalUser} />
                                            </div>
                                            <Form.CheckBox name="subscribeToNotifications" col="s12 subscribeCheckbox" label="Subscribe To Notifications" onChange={this.onChange} value={this.state.subscribeToNotifications} />
                                        </div>
                                        <div className="row vesselVoyageBookingCountRow">
                                            <div className="col s12">
                                                {this.state.bookingsOnVesselVoyage ? `${this.state.bookingsOnVesselVoyage} Booking(s) On VVID` : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <ul className="collapsible z-depth-0 cBar">
                                    <li>
                                        <div className="collapsible-header addNotesHeader"><i className="material-icons additionalNotesIcon">expand_more</i>Additional Notes</div>
                                        <div className="collapsible-body additionalNotes"><Form.TextArea name="additionalNotes" value={this.state.additionalNotes} onChange={this.onChange} /></div>
                                    </li>
                                </ul>
                            </div>
                            <div className="row buttonsRow">
                                <Form.Button col="col s2 offset-s10" disabled={!this.state.trueVesselVoyageBookingCount || parseInt(this.state.trueVesselVoyageBookingCount, 10) < 2 ? true : false} type="outline" label={`Save & Batch (${this.state.trueVesselVoyageBookingCount})`} color="green" icon="forward" onClick={this.gotToBatchOverview} />
                            </div>
                            <div className="row buttonsRow">
                                <Form.Button col="col s2" color="green" type="outline" label="Save Booking" icon="save" round onClick={this.saveBooking} />
                                {this.state.showPrint ?
                                    <Form.Button col="col s2" id="saveAndEmailButton" label="Save & Email" type="outline" icon="email" onClick={this.saveAndEmail} />
                                    : null}
                                <Form.Button col="col s2" color="red" type="outline" label="Clear Form" icon="cancel_round" round onClick={() => this.setState(this.initState())} />
                                <Form.Button col="col s2 right" color="blue" type="outline" label="Vessel Voyage Overview" onClick={this.goToOverviewVesselVoyage} />
                                {this.state.showPrint ? <Form.Button col="s2 right" color="blue" type="outline" label="Save & Print" icon="print" round onClick={this.printBkAck} /> : null}
                                <Form.Button col="s2 right" icon="change_history" color="black" type="outline" label="Change Log" onClick={this.openChangeLog} />
                            </div>
                        </div>
                        <div id="fileUpload" className='hideTab'>
                            {this.state.bookingId ?
                                <FileUpload
                                    docsEmails={this.state.docsEmails}
                                    fileUploadTabOpen={this.state.fileUploadTabOpen}
                                    directory="SHIPMENTS/"
                                    bookingId={this.state.bookingId}
                                    lNumber={this.state.lNumber}
                                    bookingNumber={this.state.preparedBookingNumbers}
                                    isDisplayed={this.state.isDisplayed}
                                />
                                :
                                null
                            }
                        </div>
                        <div className="modal" id="create-vessel">
                            <div className="modal-content row" style={{ marginBottom: "0px" }}>
                                <h4 className="blue-text text-darken-4">New Vessel</h4>
                                <Form.Button col="s2" color="green" type="outline" label="Confirm" onClick={this.createVessel} />
                                <Form.Button col="s2" color="red" type="outline" label="Cancel" onClick={this.hideModal} />
                            </div>
                        </div>
                        {this.state.fileUploadTabOpen ?
                            null :
                            <EmailForm
                                onChange={this.onChange}
                                generatePdf={this.state.generatePdf}
                                saveAndEmail={this.saveAndEmail}
                                emails={this.state.emails}
                                shipperId={this.state.shipperId}
                                party={this.props.redux.party}
                                fileType='bookingAck'
                                fileInfo={{
                                    id: this.state.bookingId,
                                    fileType: 'bookingAck',
                                    bookingNumber: this.state.bookingNumber,
                                    isDisplayed: this.state.isDisplayed,
                                    docCutDate: this.state.docCutDate,
                                    docCutTime: this.state.docCutTime,
                                    cargoCutDate: this.state.cargoCutDate,
                                    cargoCutTime: this.state.cargoCutTime,
                                    eta: this.state.eta,
                                    etd: this.state.etd,
                                    erd: this.state.erd
                                }}
                                showEmailModal={this.showEmailFormModal}
                                hideEmailModal={this.hideEmailModal}
                                subject={this.state.emailSubject}
                                send={this.sendEmail}
                            />
                        }
                        <StickyNote
                            id="bookingAndSiStagesWindow"
                            shipment={this.state}
                            getBookingNumbers={this.getBookingNumbers}
                            closeModal={this.closeStickyNoteModal}
                        />
                        <div className="modal" id="reminder-modal">
                            <div className="modal-content row">
                                <h4 className="blue-text text-darken-4" style={{ "textAlign": "center" }}>This Booking Has An AES Number</h4>
                                <h5 style={{ "textAlign": "center" }}>You will no longer be able to use this AES number. Please remember to cancel the AES number that is attatched to this booking. </h5>
                                <Form.Button id="closeReminder" styling="modalFormat" icon="done" col="col s2 offset-s5" color="green" type="outline" label="Okay" onClick={this.hideReminderModal} />
                            </div>
                        </div>
                        <Changelog
                            bookingNumber={this.state.bookingNumber}
                            subject={this.state.emailSubject}
                            changeLogOpenSwitch={this.state.changeLogOpenSwitch}
                        />
                        <div style={{ position: 'fixed', top: this.state.y, left: this.state.x }} className="lf-shipment-context-menu">
                            <ul id="contextMenuCollection" className="collection">
                                {this.state.showContextMenu && <li id="contextMenuFirstOption" className="collection-item blue-text" onClick={() => this.setState({ showAllYards: false }, this.getContainerYards)} tabIndex='1'>FILTER BY STATE</li>}
                                {this.state.showContextMenu && <li className="collection-item blue-text" onClick={() => this.setState({ showAllYards: true }, this.getContainerYards)} tabIndex='1'>SHOW ALL RAILYARDS</li>}
                                {this.state.showStatusMenu && <li onClick={() => this.changeStatus('ACTIVE')} className="collection-item green-text">ACTIVE</li>}
                                {this.state.showStatusMenu && <li onClick={() => this.changeStatus('CLOSED')} className="collection-item grey-text">CLOSED</li>}
                                {this.state.showStatusMenu && <li onClick={() => this.changeStatus('CANCELLED')} className="collection-item red-text">CANCELLED</li>}
                                {this.state.showStatusMenu && <li onClick={this.handleDelete} className="collection-item black-text" >ARCHIVE</li>}
                            </ul>
                        </div>
                    </div>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { navs, urls, index, party, shipper, carrier, location, containerType, serviceRoute, country, portalUser, user } = state;
    const shipperId = navs[index].shipperId;
    const url = urls[index];
    return { redux: { shipperId, party, shipper, carrier, location, containerType, serviceRoute, country, portalUser, url, user } };
}

export default connect(mapStateToProps)(withRouter(CreateBooking));