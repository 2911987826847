import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../helperFunctions';
import axios from 'axios';
import { auth } from '../../helperFunctions';
import Form from '../../Components/NewComponents';
import moment from 'moment';
import { globalToastActions } from '../../Redux/actions';
import { flags } from "../../constants";
import styles from "./Reports.module.css";


const PendingRequestsReport = (props) => {

    const [filters, setValues] = useState({
        user: 0,
        startDate: moment(new Date()).subtract(30, "day").format("MM/DD/YY"),
        endDate: moment(new Date()).add(30, "day").format("MM/DD/YY"),
        currentSort: "total",
        reverse: -1
    });
    const [reportItems, setReportItems] = useState([]);

    const filterOnChange = (name, value) => {
        setValues({ ...filters, [name]: value })
    }

    useEffect(() => {
        document.querySelectorAll(".search-field").forEach(el => { el.addEventListener("keydown", searchListener) })
        return () => {
            document.querySelectorAll(".search-field").forEach(el => { el.removeEventListener("keydown", searchListener) })
        }
    }, []);

    useEffect(() => {
        getPendingRequests(filters);
    }, [filters.user, filters.currentSort, filters.reverse])

    const searchListener = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            document.getElementById("searchButton").click();
        }
    }

    const getPendingRequests = (filters) => {
        axios.get("/api/v1/report/pendingrequests", { params: { ...auth.getAuthData(), filters } }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispach({ type: "SCORCHED_EARTH" });
            } else if (result.data.errno) {
                setReportItems([]);
                return props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Error: ${result.data.code}`, type: "error" } })
            } else {
                if (!result.data.length) {
                    props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Results" } });
                }
                setReportItems(result.data)
            }
        })
    }

    const sort = (sortName) => {
        if (!sortName) {
            return;
        } else {
            const reverse = filters.currentSort === sortName ? (-1 * filters.reverse) : 1;
            setValues({ ...filters, currentSort: sortName, reverse })
        }
    }

    return (
        <div>
            <div className="row" style={{ marginTop: "15px" }}>
                <Form.Select col="col s3" label="User" placeholder="ALL" name="user" value={filters.user} onChange={filterOnChange} list={props.portalUser} filter={{ label: "fullName", value: "id" }} />
                <Form.DateInput col="col s3 search-field" label="Start Date" id="startDate" name="startDate" value={filters.startDate} onChange={filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.DateInput col="col s3 search-field" label="End Date" name="endDate" value={filters.endDate} onChange={filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.Button id="searchButton" col="col s3" type="outline" color="green" label="Search" onClick={() => getPendingRequests(filters)} />
            </div>
            <div className="row">
                <table className={styles.reportTable}>
                    <thead>
                        <tr>
                            <th onClick={() => sort("user")}>User&nbsp;{filters.currentSort === "user" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort("new")}>New &nbsp;{filters.currentSort === "new" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort("pending")}>Pending &nbsp;{filters.currentSort === "pending" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort("requested")}>Requested &nbsp;{filters.currentSort === "requested" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort("updated")}>Updated &nbsp;{filters.currentSort === "updated" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort("declined")}>Declined &nbsp;{filters.currentSort === "declined" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort("completed")}>Completed &nbsp;{filters.currentSort === "completed" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort("closed")}>Closed &nbsp;{filters.currentSort === "closed" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort("total")}>Total Req.&nbsp;{filters.currentSort === "total" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportItems.map((item, index) => (
                            <tr key={index} >
                                <td>{item.requestsUser}</td>
                                <td>{item.newCount}</td>
                                <td>{item.pendingCount}</td>
                                <td>{item.requestedCount}</td>
                                <td>{item.updatingCount}</td>
                                <td>{item.declinedCount}</td>
                                <td>{item.completedCount}</td>
                                <td>{item.closedCount}</td>
                                <td>{item.totalCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const { urls, index, user, portalUser } = state;
    const url = urls[index];
    return { url, user, portalUser }
}

export default connect(mapStateToProps)(withRouter(PendingRequestsReport));